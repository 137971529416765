import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

export const saveCorporateAnouncement = async (email:string, obj: any) => {
    const url = `${Config.baseUrl}/transaction/update-ledger-and-txn-object`;
    try {
      const result: any = await axios.post(
        url,
        {
          email,
           ...obj
        },
        {
          headers: {
            Authorization: sessionStorage.getItem('token') || '',
          },
        }
      );
      return result;
    } catch (err: any) {
      if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await saveCorporateAnouncement(email,obj);
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
    }
  };