import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const addFinancialPeriod = async (email: string, amc_code: string, start_date: string, end_date: string,
    description: string, period: string
  ) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/financialperiod`;
    try{
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        start_date,
        end_date,
        description,
        period
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
    }catch(err:any) {
      if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await addFinancialPeriod(
        email,
        amc_code,
        start_date,
        end_date,
        description,
        period)
      }else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
    }
  };

  const updateFinancialPeriod = async (email: string, amc_code: string, financial_id: string, start_date: string, end_date: string,
    description: string, period: string, status:string
  ) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/financialperiod/update`;
    try{
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        financial_id,
        start_date,
        end_date,
        description,
        period,
        status
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
    }catch(err:any) {
      if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateFinancialPeriod(
        email,
        amc_code,
        financial_id,
        start_date,
        end_date,
        description,
        period,
        status)
      }else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
    }
  };


  const getFinancialPeriods = async (
    email: string,
    amc_code:string
  ) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/financialperiod?email=${email}&amc_code=${amc_code}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getFinancialPeriods(email, amc_code)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
        }
  }
  };

  const updateFinancialPeriodStatus = async (
    email: string,
    financial_id: string,
    status: string,
    amc_code:string
  ) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/financialperiod/update-status`;
    try{
    const result: any = await axios.post(
      url,
      { email, financial_id , status,amc_code},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
    }catch(err:any) {
      if(err.response.data.status == 401) { 
        let responseToHandle = err.response.data
        let result = await RefreshTokenHandler.handleIt(responseToHandle)
        if(result.status) {
         return await updateFinancialPeriodStatus(email,financial_id, status,amc_code)
        }else {
          throw err;
          // return result
        }
      }else {
            throw err
      }
    }
  };

  const getFinancialPeriodsByAmc = async (email: string, amc_code) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/financialperiod/amc-active-period?email=${email}&amc_code=${amc_code}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getFinancialPeriodsByAmc(email, amc_code)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
        }
  }
  };
  

  export {getFinancialPeriods, addFinancialPeriod, updateFinancialPeriod, updateFinancialPeriodStatus,
    getFinancialPeriodsByAmc}