
import React, { useEffect, useState } from 'react';
// import Header from './../components/Header';	
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { searchTransactions } from './../../stores/services/transactions.service';
import { getFundByAmc } from './../../stores/services/funds.service';
import ViewTransaction from './../view-beta-txn';
import { toast } from 'react-toastify';
import { BsTable } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AdjustmentTransactions = () => {
  const email = sessionStorage.getItem('email') || '';
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const history = useHistory();
  let [transactions, setTransactions] = useState<any>([]);
  let [status, setStatus] = useState('');
  const [trx_history, setTrxHistory] = useState([]);
  const [viewChecklist, setViewCheckList] = useState(false);
  const [trx_checklist, setTrxCheckList] = useState([]);
  // let [lastPageNum, setLastPageNum] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);
  let [currentPage, setCurrentPage] = useState('');
  let [activePage, setActivePage] = useState('');

  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [resLoading, setResLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState('')

  const [togglePagination, setTogglePagination] = useState(false);

  React.useEffect(() => {
    //     async function getTransactions(){
    //       if(togglePagination!=true){
    //       setResLoading(true);
    //     await getAllPendingTransactions(	
    //       sessionStorage.getItem('email') || '',		
    //       status,	
    //       searchValue,	
    //       '',	
    //       activePage	
    //     )	
    //       .then((response) => {	
    //         let pageArr = paginationArr;	
    //         let existingPageIndex = paginationArr.findIndex(	
    //           (x) => x === response.data.page_info.next_page_number	
    //         );	
    //         if (	
    //           existingPageIndex === -1 &&	
    //           response.data.data.length === 10 
    //           // &&	
    //           // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
    //         ) {	
    //           pageArr.push(response.data.page_info.next_page_number);	
    //         }	
    //         setPaginationArr(pageArr);
    //         setTransactions(response.data.data);
    //         setResLoading(false);	
    //       })	
    //       .catch((err) => {	
    //         setResLoading(false);
    //       });	
    //       setResLoading(false);
    //     }else{
    //       if(searchValue!==''){
    //         searchValue='TXN_'+searchValue;
    //       }
    //       setResLoading(true);
    //       await searchTransactions(	
    //         sessionStorage.getItem('email') || '',
    //           amc_code,
    //           fund,
    //           status === '-1' ? '' : status,
    //           searchValue,
    //           activePage	
    //       )	
    //         .then((response) => {	

    //           if(response.data.data.length>0){
    //             let pageArr = paginationArr
    //             let existingPageIndex = paginationArr.findIndex(	
    //               (x) => x === response.data.page_info.next_page_number	
    //             );
    //             if (	
    //               existingPageIndex === -1 &&	
    //               response.data.data.length === 10 
    //               // &&	
    //               // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
    //             ) {	
    //               pageArr.push(response.data.page_info.next_page_number);	
    //             }	
    //             setPaginationArr(pageArr);
    //             setTransactions(response.data.data);
    //           }else{
    //             setTransactions([]);
    //           }  
    //           setResLoading(false);	

    //         })	
    //         .catch((err) => {	
    //           setResLoading(false);		
    //         });	
    //         setResLoading(false);
    //     }
    //   }
    activePage ? searchTransactionsByAMCAndFund('', '', '', '', '') : searchTransactionsByAMCAndFund('', '', '', '', 'true');
  }, [activePage]);


  useEffect(() => {
    const getFunds = async () => {
      try {
        allFunds.length = 0;
        setAllFunds(allFunds);
        const response = await getFundByAmc(email, amc_code, fund_code?.length > 0 ? fund_code : "");
        setAllFunds(response.data.data);
      } catch (error) {

      }
    }

    getFunds();
  }, []);

  // Fund Type Drop Down
  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    })
  }

  const searchTransactionsByAMCAndFund = async (statusVar = '', searchVar = '', amcCodeVar = '', fundCodeVar = '', resetData = "") => {
    setTogglePagination(true);
    return new Promise(async (resolve, reject) => {
      if (searchVar !== '') {
        searchVar = 'TXN_' + searchVar;
      }
      setResLoading(true);
      setSearchLoading(true);
      await searchTransactions(
        sessionStorage.getItem('email') || '',
        amc_code,
        fundCodeVar ? fundCodeVar : fund_code?.length > 0 ? fund_code : "",
        'COMPLETED',
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            // let pageArr = paginationArr

            //     paginationArr = paginationArr.filter(function(item) {
            //       return item == ''
            //   })
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            // setTransactions(response.data.data);
            setTransactions(resetData == "true" ? [...response.data.data] : [...transactions, ...response.data.data]);

          } else {
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);

          resolve('Success');

        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };

  const clearFilters = async (statusVar = undefined as any, searchVar = undefined as any, pageNumber: string = '') => {
    return new Promise(async (resolve, reject) => {
      // setLoading(true);
      setResLoading(true);
      setClearLoading(true);
      await searchTransactions(
        sessionStorage.getItem('email') || '',
        amc_code,
        fund_code?.length > 0 ? fund_code : "", // fund code
        'COMPLETED',
        '', // txn id
        activePage
      )
        .then((response) => {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10
            // &&	
            // 10 * pageArr.length !==	parseInt(response.data.page_info.total_count)	
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          setTransactions(response.data.data);
          setResLoading(false);
          setClearLoading(false);
          resolve('Success');
          setTogglePagination(false); // false search pagination.
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!")
          }
          reject('Error');
        });
      setResLoading(false);
      setClearLoading(false);
    });
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // const [Loading, setLoading] = useState(false);	
  const [view, setView] = useState(false);
  const [data, setData] = useState<any>([]);

  const [note, setNote] = useState('');

  let [searchValue, setSearchValue] = useState('');

  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  let txnTypes = useSelector((state: any) => state.txnTypes);
  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const [viewTxnModal, setViewTxnModal] = useState(false);
  const renderViewTxnModalPopup = () => {
    switch (viewTxnModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewTxnModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transaction  </h6>
            </div><div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"></h6>
            </div>
            <div className="modal-body">
              <div className="">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-2 pointer"
                    onClick={() => {
                      setView(false);
                    }}></i>
                  View Transaction
                </h4>
                <ViewTransaction data={data} />

                <div className="line"></div>

                <Row className=" pb-5">
                  {trx_history.map((hist: any, index: number) => {
                    return (
                      <Col
                        md="3"
                        onClick={() => {
                          if (hist.action === 'REJECTED-AMC') {
                            setNote(hist.note);
                          } else {
                            setNote('');
                          }
                          if (hist.action === 'CREATED') {
                            return;
                          }
                          if (!hist.check_list) return;
                          setTrxCheckList(hist.check_list);
                          setViewCheckList(true);
                        }}
                      >
                        <div className="card process-card pointer">
                          <div className="card-header">
                            <h4 className="card-title">
                              <i className="tim-icons icon-single-02 mr-1"></i> <span className="card-number">
                                {index + 1}
                              </span>
                              {hist.updated_by_role} </h4>
                          </div>
                          <div className="card-body text-center">

                            <p className="small mt-2">Status:</p>
                            <h3 className="accepted text-primary">  {hist.action}    </h3>
                            {hist.updated_at !== '' ? (
                              <>
                                <p className="small mt-2">by</p>
                                <h4 className="comment text-primary">{hist.updated_by}</h4>
                                <p className="small mt-2">at</p>
                                <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>
                              </>
                            ) : (
                              ''
                            )}
                            {hist.note !== '' ? (
                              <>
                                <p className="small mt-2">Comment</p>
                                <small className="comment dot-text" title={hist.note}>{hist.note}</small>
                              </>
                            ) : (
                              ''
                            )}
                            {
                              hist.created_by !== '' ? (
                                <>
                                  <p className="small mt-2">by</p>
                                  <h4 className="text-primary">{hist.created_by}</h4>
                                  <p className="small mt-2"> at</p>
                                  <h4 className="comment text-primary"> {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")} </h4>
                                </>
                              ) : (
                                ''
                              )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="card ">
                <div className="card-header">
                  <h4 className="card-title text-primary">Adjustment</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <ReactTooltip
                        textColor="white"
                        backgroundColor="black"
                        effect="float"
                      />
                      <div className="form-group">
                        <label>Transaction ID</label>
                        <input
                          type="search"
                          id="myInput"
                          placeholder="Transaction Id"
                          data-tip="Search Transaction"
                          className="form-control"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className='col-md-2'>
                      <div className="form-group ml-2 mr-2">
                        <label >Select Fund</label>
                        <select
                          className={"form-control"}
                          value={fund}
                          onChange={(e) => {
                            setFund(e.target.value);
                          }}>
                          <option value="" defaultChecked hidden>
                            Select Fund
                          </option>
                          {renderFundsDropDown()}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className=" inline-box">
                        <button
                          className="btn btn-primary mt-4 ml-2"
                          disabled={Boolean(searchLoading)}
                          onClick={() => {
                            if ((searchValue || status !== "-1" || fund) !== '') {
                              searchTransactionsByAMCAndFund(status, searchValue, '', fund, '').then(() => {
                                setActivePage('');
                              });
                            }
                          }}
                        >
                          {searchLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Searching...</span>
                            </>
                          ) : (
                            'Search'
                          )}
                        </button>

                        <button
                          className="btn btn-default ml-2 mt-4 ml-2"
                          disabled={Boolean(clearLoading)}
                          style={{
                            width: '148px',
                          }}
                          onClick={() => {
                            clearFilters('', '').then(() => {
                              setStatus('-1');
                              setSearchValue('');
                              setActivePage('');
                              setFund('');
                            });
                          }}
                        >
                          {clearLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Clear</span>
                            </>
                          ) : (
                            'Clear'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {resLoading ? (
                      <div className="d-flex justify-content-center my-5">
                        <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                      </div>
                    ) : (
                      transactions.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table mt-2">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Transaction</th>
                                <th>Fund</th>
                                <th>Amount</th>
                                <th>Bank</th>
                                <th>Counter Acc</th>
                                <th>Instrument</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Act</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((transaction: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br />
                                      <span className="sub-content">Exe: {moment(transaction.execution_date).format('DD-MM-YYYY')}</span>
                                    </td>

                                    <td>{transaction.txnr_type
                                      ? `${CapTxnType(transaction.txnr_type)}	${transaction.detail?.includes("Reversed") ? " (Reversed)" : ""}`
                                      : 'N/A'}<br /> <span className="sub-content">{transaction.txn_id.replace('TXN_', '')}</span></td>

                                    <td>{transaction.fund_code}	<br />
                                      <span className="sub-content">{transaction.amc_code
                                        ? transaction.amc_code
                                        : 'N/A'}	</span>
                                    </td>

                                    <td className="text-left pr-3">{numberWithCommas(transaction.net_amount)} <br />
                                      <span className="sub-content">{numberWithCommas(transaction.gross_amount)}</span>
                                    </td>

                                    <td>{transaction.bank}<br />
                                      <span className="sub-content">{transaction.account_number}</span></td>

                                    <td>{transaction.counter_bank} <br />
                                      <span className="sub-content">{transaction.counter_account_number}</span></td>

                                    <td>{transaction.mode_of_payment} <br />
                                      <span className="sub-content">{transaction.instrument_type} {transaction.instrument_no !== '' ? transaction.instrument_no + ',' : ''} {moment(transaction.instrument_date).format('DD-MM-YYYY')}</span></td>

                                    <td>{moment(transaction.created_at).format('DD-MM-YYYY')} <br />
                                      <span className="sub-content">{transaction.created_by.split('@')[0]}</span>
                                    </td>
                                    <td>{transaction.txn_status}</td>
                                    <td className=" ">
                                      <div className="multi-icons pointer">
                                        <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                                          sessionStorage.setItem('adjTxn', JSON.stringify(transaction))
                                          history.replace(`/admin/adjustment-txn`)
                                        }}>
                                          <i className="fa fa-eye">
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )
                    )}

                    {/* {togglePagination===true ? ( */}
                    <div
                      className="row justify-content-center pointer">
                      {/* PREVIOUS BUTTON */}
                      {/* {activePage !== paginationArr[0] ? (	
                  <i	
                    className="fa fa-arrow-left"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	 */}
                      {/* NEXT BUTTON */}
                      {/* {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i	
                    className="fa ml-3 fa-arrow-right"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	 */}
                      <button
                        aria-hidden={true}
                        className="btn btn-primary btn-sm mt-2 py-2 px-3"
                        disabled={activePage ==
                          paginationArr[paginationArr.length - 1] || resLoading}
                        type="button"
                        onClick={() => {
                          let pageArr = paginationArr;
                          let index = pageArr.findIndex(
                            (x) => x === activePage
                          );
                          if (index !== -1) {
                            setActivePage(paginationArr[index + 1]);
                          }
                        }}
                      >
                        {resLoading ? <span>Loading... <i className="fa fa-spinner fa-spin  "
                          aria-hidden="true"
                        ></i></span> : "Show More"}
                      </button>
                    </div>
                    {/* ):(
                  ''
                )}	 */}
                  </div>
                </div>
              </div>
              {renderViewTxnModalPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AdjustmentTransactions;	
