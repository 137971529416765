import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param bank_name
 * @param branch_name
 * @param city
 * @param area
 * @param branch_code
 * @param branch_address
 *
 */
const addBranch = async (
  email: string,
  bank_name: string,
  branch_name: string,
  city: string,
  area: string,
  branch_code: string,
  branch_address: string
) => {
  const url = `${Config.baseUrl}/branch/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        bank_name,
        branch_name,
        city,
        area,
        branch_code,
        branch_address,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addBranch(email, bank_name, branch_name, city, area, branch_code, branch_address)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param bankName
 *
 */
const getOfficer = async (email: string, rolname: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}&role_name=${rolname}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getOfficer(email, rolname)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param role
 * @param description
 * @param user_email
 */
const addUser = async (
  email: string,
  additional_access: string = '',
  amc_code: string = "",
  fund_code: string[] = [],
  denied_access: string = "",
  name: string,
  role: string,
  role_status: string,
  signature_specimen: string = "",
  denied_transaction_access: string = "",
  features: string = "",
  department: string = "",
  department_role: string = "",
  user_email:string=""

) => {
  const url = `${Config.baseUrl}/user/add`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        additional_access,
        amc_code, fund_code, denied_access,
        name,
        role,
        role_status,
        signature_specimen,
        denied_transaction_access,
        features,
        department,
        department_role,
        user_email,
        user_type: 'AMC'


      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addUser(email, 
          additional_access,
          amc_code,
          fund_code, 
          denied_access,
          name,
          role,
          role_status,
          signature_specimen,
          denied_transaction_access,
          features,
          department,
          department_role,
          user_email
          )
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param role
 */
const editUser = async ( email: string,
  additional_access: string = '',
  amc_code: string = "",
  fund_code: string[] = [],
  denied_access: string = "",
  name: string,
  role: string,
  role_status: string,
  signature_specimen: string = "",
  denied_transaction_access: string = "",
  features: string = "",
  department: string = "",
  department_role: string = "",
  user_email:string="") => {
  const url = `${Config.baseUrl}/user/update-info`;
  try {
    const result: any = await axios.post(
      url,
      { email,
        additional_access,
        amc_code, fund_code, denied_access,
        name,
        role,
        role_status,
        signature_specimen,
        denied_transaction_access,
        features,
        department,
        department_role,
        user_email,
        user_type: 'AMC'
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await editUser(email, 
          additional_access,
          amc_code,
          fund_code, 
          denied_access,
          name,
          role,
          role_status,
          signature_specimen,
          denied_transaction_access,
          features,
          department,
          department_role,
          user_email)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getAllUsers = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUsers(email)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param old_password
 * @param new_password
 */
const changePassword = async (
  email: string,
  old_password: string,
  new_password: string
) => {
  const url = `${Config.baseUrl}/user/change-password`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        old_password,
        new_password,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await changePassword(email, old_password, new_password)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const updateUserStatus = async (
  email: string,
  user_email: string,
  status: string
) => {
  const url = `${Config.baseUrl}/user/update-status`;
  try {
    const result: any = await axios.post(
      url,
      { email, user_email, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateUserStatus(email, user_email, status)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};


const enableDisableTwoFaAdmin = async (
  email: string,
  user_email: string,
  two_fa_enabled: string
) => {
  const url = `${Config.baseUrl}/user/enable-disable-2fa/admin`;
  try {
    let result: any = await axios.post(
      url,
      { email, user_email, two_fa_enabled },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await enableDisableTwoFaAdmin(email, user_email, two_fa_enabled)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};


const getAllUsersPaginate = async (email: string, page_number: string, page_size: string, amc_code: string, fund_code: string, department: string, department_role: string, name: string, role: string, user_email: string) => {
  const url = `${Config.baseUrl}/user/paginate?email=${email}&page_number=${page_number}&page_size=${page_size}&amc_code=${amc_code}&fund_code=${fund_code}&department=${department}&department_role=${department_role}&name=${name}&role=${role}&user_email=${user_email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUsersPaginate(email, page_number, page_size, amc_code, fund_code, department, department_role, name, role, user_email)
      } else {
        throw err;
        // return result
      }
    } else {
      throw err
    }
  }
};

export {
  addBranch,
  updateUserStatus,
  getOfficer,
  addUser,
  getAllUsers,
  changePassword,
  editUser,
  getAllUsersPaginate,
  enableDisableTwoFaAdmin
};
