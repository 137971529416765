
import React, { useState, useEffect, useContext } from 'react';
// import Header from './../components/Header';	
import { Container, Row, Col, Input, Label, FormGroup } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { getAllTransactions } from './../stores/services/transactions.service';
import ReactTooltip from 'react-tooltip';
import ViewTransaction from './view-beta-txn';
import { BsTable } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { addMegaTransaction } from '../stores/services/beta-transaction.service';
import { toast, ToastContainer } from "react-toastify";
import { getCounterAccount } from './field';
import {

  RiCheckboxMultipleLine,

} from "react-icons/ri";
import { getTransactionsLedgers } from '../stores/services/chatofaccounts.service';
import { getAllLedgers, getLedgerAccounts, getLedgersByTxn } from '../utils/customFunction';
import { saveCorporateAnouncement } from '../stores/services/corporate-announcement.service';
import { ThemeContext } from '../contexts/ThemeContext';
const TransactionListing = () => {
  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  const email = sessionStorage.getItem("email") || "";
  const context = useContext(ThemeContext);
  let txnTypes = useSelector((state: any) => state.txnTypes);
  let [transactions, setTransactions] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  const [trx_history, setTrxHistory] = useState([]);
  const [viewChecklist, setViewCheckList] = useState(false);
  const [trx_checklist, setTrxCheckList] = useState([]);
  let [paginationArr, setPaginationArr] = useState(['']);
  const userEmail = sessionStorage.getItem('email') || '';
  const [transaction, setTransaction] = useState<any>([]);
  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [LoadingReverseButton, setLoadingReverseButton] = useState(false);
  const [reverseTransactionConfirmationModal, setReverseTransactionConfirmationModal] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  const [view, setView] = useState(false);
  const [data, setData] = useState<any>([]);
  const [viewTxnModal, setViewTxnModal] = useState(false);
  const [bankBalance, setBankBalance] = useState("");
  const [ledgerData, setLedgerData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  let counterAccounter

  const [txn_id, setTxnId] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const [actionModal, setActionModal] = useState(false);
  const [transactionLedgers, setTransactionLedgers] = useState<any>([]);
  const [totalDebit, setTotalDebit] = useState("0");
  const [totalCredit, setTotalCredit] = useState("0");
  const [balance, setBalance] = useState("0");
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [gl_code, setGlCode] = useState("");
  const [bankGL, setBankGL] = useState("");
  const [percentage, setPercentage] = useState("");
  const [dividendTaxAmount, setDividendTaxAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const onLoadData = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      // if(response.data.status==200){
      let ledgersacc = await getLedgerAccounts(
        chartOfAccounts[0].family[3].sub_family
      );
      setLegderAccounts(ledgersacc);
      let allLedgers = await getAllLedgers(chartOfAccounts);
      setAllLedgers(allLedgers);
      // }else{
      //   toast.error(response.data.message);
      // }
    } catch (error) { }

    // get transactions ledgers
    try {
      const response = await getTransactionsLedgers(email);
      if (response.data.status == 200) {
        let investmentTxn = response.data.data.txn_catagory[2].investmentTxn;
        let txnLedgers = await getLedgersByTxn(
          investmentTxn,
          "corporate_announcements"
        );
        let firstLegLedgers = txnLedgers.filter((items: any) => {
          return (
            (items.gl_name == "DIVIDEND RECEIVABLE ON EQUITY SCRIPTS" &&
              items.type == "Credit") ||
            (items.gl_name == "WHT ON DIVIDEND INCOME" &&
              items.type == "Debit") ||
            (items.family_name == "BANK BALANCES" && items.type == "Debit")
          );
        });
        setTransactionLedgers(firstLegLedgers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) { }
  };

  useEffect(() => {
    chartOfAccounts?.length > 0 && onLoadData();
  }, [chartOfAccounts]);

  useEffect(() => {
    activePage ? getTransactions("") : getTransactions("true");
  }, [activePage]);

  async function getTransactions(resetData: string = "") {
    setResLoading(true);
    await getAllTransactions(
      sessionStorage.getItem('email') || '',
      "",
      resetData == "true" ? "" : searchValue,
      userEmail,
      activePage,
      userEmail
    )
      .then((response) => {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10

        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        // setTransactions(response.data.data);
        setTransactions(resetData == "true" ? [...response.data.data] : [...transactions, ...response.data.data]);
        setResLoading(false);
      })
      .catch((err) => {
        setResLoading(false);
      });
    setResLoading(false);
  }


  useEffect(() => {
    if (viewTxnModal) {
      counterAccounter = getCounterAccount(data.txnr_type);
      // setFields(data);

      if (data.ledgers == undefined || data.ledgers == "" || data.ledgers == "[]") {
        setLedgerData([]);
      } else {
        setLedgerData(reverseLedgers(JSON.parse(data.ledgers)));
      }
    }
  }, [viewTxnModal]);


  const searchFilters = async (statusVar = undefined as any, searchVar = undefined as any, pageNumb: string = '') => {
    return new Promise(async (resolve, reject) => {
      let search = false;
      let clear = false;
      if (searchVar === '') { // if click on clear button
        search = false;
        clear = true;
      } else { // if click on search button
        search = true;
        clear = false;
      }
      setResLoading(true);
      setSearchLoading(search);
      setClearLoading(clear);
      await getAllTransactions(
        sessionStorage.getItem('email') || '',
        '',
        searchVar === undefined ? searchValue : searchVar,
        '',
        // userEmail,	
        '',
        // userEmail
        ''
      )
        // await searchTransactions(	
        //   sessionStorage.getItem('email') || '',
        //   sessionStorage.getItem('amc_code') || '',
        //   '',
        //   '',
        //   'TXN_'+searchValue,
        //   activePage	
        // )	
        .then((response) => {
          // let pageArr = [''];
          if (response.data.data.length > 0) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !==	
              //   parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);
          setClearLoading(false);
          resolve('Success');
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          setClearLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
      setClearLoading(false);
    });
  };

  const viewCheckListPopup = () => {
    switch (viewChecklist) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
          >
            <div className="">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewCheckList(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approval Check List  </h6>
            </div>
            <div className="modal-body">
              <div>
                {trx_checklist.map((val: any, ind: any) => {
                  return (
                    <Row key={ind} className="pt-3">

                      <Col md="4">
                        <text className="text-primary">{val.serial_no}.</text> <text>{val.message}</text>
                      </Col>
                      <Col md="8">
                        <div className="row">
                          <div className="col-sm-3">

                            <FormGroup check>
                              <Label check>
                                <Input
                                  checked={val.value == 'compliant'}
                                  type="checkbox"
                                  value="compliant"
                                  readOnly />
                                <span className="form-check-sign" />
                                Compliant
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="col-sm-3">

                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"
                                  id="unlisted"
                                  checked={val.value == 'na'}
                                  value="na"
                                  readOnly />
                                <span className="form-check-sign" />
                                N /A
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Comments"
                                value={val.comment}
                                className="form-control"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  );
                })}

                <Row>
                  <Col>
                    <div className=" ">
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => {
                          setViewCheckList(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const titleCase = (value: string) => {
    let sentence = value.toLowerCase().split('_');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };
  const renderData = () => {
    return data.map((value: any, index: number) => {
      return (
        <>
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (
            <Col md="6" key={index}>
              <div className="input-holder left">
                <p className="label">{titleCase(value[0])}</p>
                <div className="input-1 hide-overflow">
                  <p
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {typeof value[1] === 'string'
                      ? value[0] === 'file'
                        ? 'N/A'
                        : value[1]
                      : value[0] === 'file'
                        ? 'N/A'
                        : ''}
                  </p>
                </div>
              </div>
            </Col>
          ) : (
            ''
          )}
        </>
      );
    });
  };
  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };
  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }


  const renderViewTxnModalPopup = () => {
    switch (viewTxnModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewTxnModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transaction  </h6>
            </div><div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"></h6>
            </div>
            <div className="modal-body">
              <div className="">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-2 pointer"
                    onClick={() => {
                      setView(false);
                      setViewTxnModal(false);
                    }}></i>
                  View Transaction
                </h4>
                <ViewTransaction data={data} />

                {(data.txn_status == "COMPLETED" || data.txn_status == "REJECTED-AMC") &&
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setReverseTransactionConfirmationModal(true);

                        }}
                        disabled={Boolean(LoadingReverseButton)}
                      >
                        {LoadingReverseButton ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Reverse</span>
                        )}
                      </button>
                    </div>
                  </div>}

                <div className="line"></div>

                {/* <Row className=" pb-5">	
              {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col
                  md="3"
                  onClick={() => {
                    if (hist.action === 'REJECTED-AMC') {
                      setNote(hist.note);
                    } else {
                      setNote('');
                    }
                    if (hist.action === 'CREATED') {
                      return;
                    }
                    if (!hist.check_list) return;
                    setTrxCheckList(hist.check_list);
                    setViewCheckList(true);
                  }}
                >
                    <div className="card process-card pointer">	
                      <div className="card-header">	
                        <h4 className="card-title">
                        <i className="tim-icons icon-single-02 mr-1"></i> <span className="card-number">
                          {index+1}
                          </span>
                             {hist.updated_by_role} </h4>	
                      </div>	
                      <div className="card-body text-center">	 
                          
                          <p className="small mt-2">Status:</p>	
                          <h3 className="accepted text-primary">  {hist.action}    </h3>	 	
                          {hist.updated_at !== '' ? (	
                            <>	
                              <p className="small mt-2">by</p>	
                              <h4 className="comment text-primary">{hist.updated_by}</h4>	 
                              <p className="small mt-2">at</p>	
                              <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {hist.note !== '' ? (	
                            <>	
                              <p className="small mt-2">Comment</p>	
                            <small className="comment dot-text" title={hist.note}>{hist.note}</small>		
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {	
                            hist.created_by !== '' ? (	
                              <>	
                                <p className="small mt-2">by</p>	
                                <h4 className="text-primary">{hist.created_by}</h4>	
                                <p className="small mt-2"> at</p>	
                                <h4 className="comment text-primary"> {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")} </h4>	
                              </>	
                            ) : (	
                                ''	
                              )}	
                        </div>		
                    </div>	
                  </Col>	
                );	
              })}	
            </Row>	 */}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const reverseTransactionModal = () => {
    switch (reverseTransactionConfirmationModal) {
      case true:
        return (
          <Modal
            backdrop={true}
            className="" dialogClassName="modal90w" show={true} size="lg">
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setReverseTransactionConfirmationModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Confirmation Reverse Tranaction </h6>
            </div>
            <div className="modal-body">
              <div>Are you sure do you want reverse this transaction?</div>

              <button

                type="button"
                className="btn btn-primary"
                disabled={Boolean(LoadingReverseButton)}
                onClick={() => {
                  createTransaction();
                }}
              >
                Proceed
              </button>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };


  const createTransaction = async () => {
    let id = fields.txn_id;
    fields.detail = fields.detail.replaceAll("(Reversed)", "") + " (Reversed)";
    fields.associated_transaction = id;
    fields.ledgers = JSON.stringify(ledgerData);
    fields.txn_id = "";
    fields["account_no"] = fields["account_number"]
      ? fields["account_number"]
      : "";
    fields["email"] = sessionStorage.getItem("email") || "";
    fields["credit_date"] = fields["credit_date"] == "Invalid date" ? "" : fields["credit_date"];
    fields["dividend_date"] = fields["dividend_date"] == "Invalid date" ? "" : fields["dividend_date"];
    fields["return_date"] = fields["return_date"] == "Invalid date" ? "" : fields["return_date"];
    fields["right_share_credit_date"] = fields["right_share_credit_date"] == "Invalid date" ? "" : fields["right_share_credit_date"];
    fields["announcement_date"] = fields["announcement_date"] == "Invalid date" ? "" : fields["announcement_date"];
    fields["bonus_credit_date"] = fields["bonus_credit_date"] == "Invalid date" ? "" : fields["bonus_credit_date"];


    delete fields["_id"];
    delete fields["_rev"];
    delete fields["~version"];
    delete fields["doc_type"];
    delete fields["created_at"];
    delete fields["created_by"];
    delete fields["assigned_to"];
    delete fields["assign_time"];
    delete fields["txn_status"];
    delete fields["account_number"];

    try {
      setLoadingReverseButton(true);
      Object.keys(fields).forEach((key) => {
        if (fields[key] === null) {
          fields[key] = "";
        }
        if (typeof fields[key] !== "string") {
          fields[key] = fields[key].toString();
        }
      });
      fields["face_value"] = fields["face_value"]
        ? parseFloat(fields["face_value"])
        : 0;
      // console.log("FIELDS DATA => ", fields);

      const response = await addMegaTransaction(fields); // for add new transaction
      if (response.data.status === 400) {
        setViewBankBalanceModal(true);
        toast.warning(response.data.message);
      } else if (response.data.status === 417) {
        toast.warning(response.data.message);
      } else {
        setUnderStand(false);
        setViewBankBalanceModal(false);
        setReverseTransactionConfirmationModal(false);
        sessionStorage.setItem("last_id", response.data.txn_id);
        toast.success(`${response.data.message} - ${response.data.txn_id}`);
        // toast.success(response.data.message);
        setLoadingReverseButton(false);
        setViewTxnModal(false)
        setActivePage(activePage ? "" : "1")
      }
    } catch (error: any) {
      setLoadingReverseButton(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.messgae);
      }
    }
    setLoadingReverseButton(false);
  };

  const reverseLedgers = (ledgers) => {
    const ledgersData = ledgers.map((item: any, index: number) => {
      if (item.type == "Debit") {
        // debit ledger will be credit
        item.type = "Credit";
        item.credit_amount = item.debit_amount;
        item.debit_amount = "0";
      } else {
        // credit ledger will be debit
        item.type = "Debit";
        item.debit_amount = item.credit_amount;
        item.credit_amount = "0";
      }
      return item;
    });

    return ledgersData;
  };

  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields["detail"]}
                      className={"form-control w-100 "}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(LoadingReverseButton)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: "Yes",
                          });
                          createTransaction();
                        }}
                      >
                        {LoadingReverseButton ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const setDataFunction = (transaction) => {
    let totaldebit = 0,
      totalcredit = 0;
    transactionLedgers.map((itm: any) => {
      if (
        itm.gl_name == "DIVIDEND RECEIVABLE ON EQUITY SCRIPTS" &&
        itm.type == "Credit" &&
        itm.amount_type == "Gross Amount"
      ) {
        itm.debit_amount = "0";
        itm.credit_amount = transaction.gross_amount;
      }
      if (
        itm.family_name == "BANK BALANCES" &&
        itm.type == "Debit" &&
        itm.amount_type == "Net Amount"
      ) {
        itm.debit_amount = transaction.net_amount;
        itm.credit_amount = "0";
      }
      if (itm.type == "Debit") {
        totaldebit += +itm.debit_amount;
      }
      if (itm.type == "Credit") {
        totalcredit += +itm.credit_amount;
      }
    });

    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
  };

  const clearFields = () => {
    setBankGL("");
    setGlCode("");
    setPercentage("");
    setDividendTaxAmount("");
    setTxnId("");
    setDividendTaxAmount("");
    setSelectedTransaction([]);
    transactionLedgers.map((items: any) => {
      if (
        items.amount_type == "Net Amount" &&
        items.family_name == "BANK BALANCES"
      ) {
        items.gl_code = 0;
        items.gl_name = "";
        items.sub_family_code = 0;
        items.sub_family_name = "";
        items.fund_account = "";
        items.account_title = "";
        items.account_number = "";
        items.bank_code = "";
        items.branch = "";
        //  items.debit_amount='0';
      }
    });
  };

  const setFundAccountFieldsData = (selected) => {
    ledgerAccounts.map((item, index) => {
      if (item.code.toString() === selected.toString()) {
        transactionLedgers.map((items: any) => {
          if (
            items.amount_type == "Net Amount" &&
            items.family_name == "BANK BALANCES"
          ) {
            setBankGL(item.code.toString());
            items.gl_code = +item.code;
            items.gl_name = item.bank.toString();
            items.sub_family_code = +item.plsCode;
            items.sub_family_name = item.plsName.toString();
            items.fund_account = selected;
            items.account_title = item.account_title;
            items.account_number = item.account_no;
            items.bank_code = item.bank.toString();
            items.branch = item.branch;
          }
          items.fund_code = selectedTransaction.fund_code;
          items.amc_code = selectedTransaction.amc_code;
          items.description =
            items.amount_type +
            " " +
            selectedTransaction.symbol +
            " " +
            selectedTransaction.detail;

          items.instruction_date = selectedTransaction.instruction_date;
          items.instrument_by = "";
          items.instrument_date = selectedTransaction.instrument_date;
          items.instrument_no = selectedTransaction.instrument_no;
          items.instrument_type = selectedTransaction.instrument_type;
          items.period = "2021-2022";
        });
      }
    });
  };

  const renderFundAccounts = (type) => {
    return (
      ledgerAccounts &&
      ledgerAccounts.filter(item => item.plsName == type).map((item: any, index: number) => {
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  const updateValue = (value) => {
    value = value == "" ? "0" : value;
    let totaldebit = 0,
      totalcredit = 0;
    let grossAmount = selectedTransaction.gross_amount;
    let debitAmount = "0";
    transactionLedgers.map((itm: any) => {
      if (itm.gl_name == "WHT ON DIVIDEND INCOME" && itm.type == "Debit") {
        debitAmount = ((parseFloat(value) / 100) * parseFloat(grossAmount))
          .toFixed(2)
          .toString();
        setDividendTaxAmount(debitAmount);
        itm.debit_amount = debitAmount;
        itm.credit_amount = "0";
      }
      if (
        itm.family_name == "BANK BALANCES" &&
        itm.type == "Debit" &&
        itm.amount_type == "Net Amount"
      ) {
        itm.debit_amount = (parseFloat(grossAmount) - parseFloat(debitAmount))
          .toFixed(2)
          .toString();
        itm.credit_amount = "0";
      }
      if (itm.type == "Debit") {
        totaldebit += +itm.debit_amount;
      }
      if (itm.type == "Credit") {
        totalcredit += +itm.credit_amount;
      }
    });

    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
  };

  const saveLedgers = async () => {
    if (percentage == "") {
      return toast.error(`Wht on Dividend Income % is Empty`);
    }
    if (+balance != 0) {
      return toast.error(`Balance is not {0}, you can't save`);
    }
    if (bankGL == "") {
      return toast.error(`Bank Account Ledger is not Selected!`);
    }
    try {
      transactionLedgers.map((item: any) => {
        return (item.period = sessionStorage.getItem("active_period") || "");
      });
      setLoading(true);
      let obj = {
        email: email,
        txn_id: txn_id.replace("TXN_", ""),
        tax_on_dvidend: percentage,
        tax_wht_it: dividendTaxAmount,
        ledgers: transactionLedgers,
      };
      const response = await saveCorporateAnouncement(email, obj);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          setActionModal(false);
          getTransactions("true");
        }, 3000);
      } else {
        toast.error(response.data.message);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const renderAnnouncementDividend = () => {
    switch (actionModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  clearFields();
                  setActionModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Corporate Action </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">
                          Corporate Action
                        </h4>
                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Bank Account*
                              <select
                                className="form-control"
                                value={gl_code}
                                onChange={(e) => {
                                  setGlCode(e.target.value);
                                  setFundAccountFieldsData(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select Bank Account
                                </option>
                                <optgroup style={{ color: "black" }} label="PLS Account"></optgroup>
                                {renderFundAccounts("PLS ACCOUNTS")}
                                <optgroup style={{ color: "black" }} label="Current Account"></optgroup>
                                {renderFundAccounts("CURRENT ACCOUNTS")}
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Ledger</th>
                                  <th></th>
                                  <th className="text-right">Debit Amount</th>
                                  <th className="text-right">Credit Amount</th>
                                  <th>Type</th>
                                  <th>Ledger Family</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionLedgers.map((item: any) => {
                                  if (
                                    item.gl_name == "WHT ON DIVIDEND INCOME"
                                  ) {
                                    return (
                                      <tr>
                                        <td>
                                          <label>
                                            {item.gl_name} <br />
                                            {item.gl_code == "" ? (
                                              ""
                                            ) : (
                                              <small className="text-muted">
                                                ({item.gl_code})
                                              </small>
                                            )}
                                          </label>
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="%"
                                            value={percentage}
                                            className="form-control w-50"
                                            onChange={(e) => {
                                              setPercentage(e.target.value);
                                              updateValue(e.target.value);
                                            }}
                                          />{" "}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.debit_amount)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.credit_amount)}
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.main_family_name}</td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr>
                                        <td>
                                          <label>
                                            {item.gl_name} <br />
                                            {item.gl_code == "" ? (
                                              ""
                                            ) : (
                                              <small className="text-muted">
                                                ({item.gl_code})
                                              </small>
                                            )}
                                          </label>
                                        </td>
                                        <td></td>
                                        <td className="text-right">
                                          {numberWithCommas(item.debit_amount)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.credit_amount)}
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.main_family_name}</td>
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className={"text-right " + (context.theme ? " text-dark " : " text-secondary")}>

                                    Total Debit:
                                    <div className="text-primary ">
                                      {totalDebit}
                                    </div>
                                  </td>

                                  <td colSpan={2} className={' text-right ' + (context.theme ? " text-dark " : " text-secondary")} style={{ color: context.theme ? "" : "#000" }}>

                                    Total Credit:{" "}
                                    <div className="text-primary">
                                      {totalCredit}
                                    </div>
                                  </td>
                                  <td></td>
                                  <td className={"text-right " + (context.theme ? " text-dark " : " text-secondary")}>

                                    Difference:{" "}
                                    <div className="text-primary ">
                                      {balance}
                                    </div>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      saveLedgers();
                    }}
                    disabled={Boolean(loading)}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header />	 */}
              {!view ? (
                <div className="card ">
                  <div className="card-header">
                    <h4 className="card-title">Transaction Listing</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          <input
                            type="search"
                            id="myInput"
                            placeholder="Transaction Id"
                            data-tip="Search Transaction"
                            className="form-control"
                            value={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">

                        <button
                          className="btn btn-primary ml-2 mt-a"
                          disabled={Boolean(searchLoading)}
                          onClick={() => {
                            if (searchValue.trim() !== '') {
                              searchFilters().then(() => {
                                setActivePage('');
                              });
                            }
                          }}
                        >
                          {searchLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Searching...</span>
                            </>
                          ) : (
                            'Search'
                          )}
                          {/* <span>	
                      <i className="fa fa-search"></i>&nbsp;Search	
                    </span>	 */}
                        </button>
                        <button
                          className="btn btn-default ml-2 mt-a"
                          disabled={Boolean(clearLoading)}
                          onClick={() => {
                            // paginationArr.length = 0;	
                            // setPaginationArr(paginationArr);	
                            searchFilters('', '', '').then(() => {
                              setActivePage('');
                              setSearchValue('');
                            });
                          }}
                        >
                          {clearLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Clear</span>
                            </>
                          ) : (
                            'Clear'
                          )}
                          {/* <span>	
                      <i className="fa fa-trash"></i>&nbsp;Clear	
                    </span>	 */}
                        </button>

                      </div>
                    </div>

                    <div>
                      {resLoading ? (
                        <div className="d-flex justify-content-center my-5">
                          <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                        </div>
                      ) : (
                        transactions.length > 0 ? (
                          <div className='table-responsive'>
                            <table className="table mt-1">
                              <thead>
                                <tr>
                                  <th>Transaction Id</th>
                                  <th>Transaction Type</th>
                                  <th>Net Amount</th>
                                  <th>Date Created</th>
                                  <th>Actions</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactions.map((transaction: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>{transaction.txn_id.replace('TXN_', '')}</td>
                                      <td>
                                        {transaction.txnr_type
                                          ? `${CapTxnType(transaction.txnr_type)}	${transaction.detail?.includes(" (Reversed)") ? " (Reversed)" : ""}`
                                          : 'N/A'}
                                      </td>
                                      <td> {numberWithCommas(transaction.net_amount)} </td>
                                      <td>
                                        {transaction.txn_history
                                          ? transaction.txn_history[0]?.created_at
                                            ? moment(
                                              transaction.txn_history[0].created_at
                                            ).format('DD-MM-YYYY')
                                            : 'N/A'
                                          : 'N/A'}
                                      </td>
                                      <td className=" ">
                                        <div className="multi-icons pointer">

                                          {/* <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                                  setView(true);	
                                  setData(transaction);	
                                }}	>
                                    <i className="fa fa-eye">
                                </i>
                              </button>  */}

                                          <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                                            setData({ ...transaction });
                                            setFields({ ...transaction })
                                            setViewTxnModal(true);
                                          }}	>
                                            <i className="fa fa-eye">
                                            </i>
                                          </button>
                                          <ReactTooltip
                                            textColor="white"
                                            backgroundColor="black"
                                            effect="float"
                                          />
                                          {transaction.txn_status == "COMPLETED" &&
                                            (transaction.txnr_type == "debtmarketinvestment" || transaction.txnr_type == "equityinvestment") &&
                                            <RiCheckboxMultipleLine
                                              data-tip={"Realized In Bank"}
                                              className={"ml-1 text-primary mt-2"}
                                              onClick={() => {

                                                setDataFunction(transaction);
                                                setTxnId(transaction.txn_id);
                                                setSelectedTransaction(transaction);

                                                setActionModal(true);
                                              }}
                                            />}

                                        </div>
                                      </td>
                                      <td>{transaction.txn_status}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )
                      )}
                      <div
                        className="row justify-content-center pointer"		>
                        {/* PREVIOUS BUTTON */}
                        {/* {activePage !== paginationArr[0] ? (
                          <i
                            className="fa fa-arrow-left"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index - 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )} */}
                        {/* NEXT BUTTON */}
                        {/* {activePage !== paginationArr[paginationArr.length - 1] ? (
                          <i
                            className="fa ml-3 fa-arrow-right"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index + 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )} */}
                        <button
                          aria-hidden={true}
                          className="btn btn-primary btn-sm mt-2 py-2 px-3"
                          disabled={activePage ==
                            paginationArr[paginationArr.length - 1] || resLoading}
                          type="button"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex(
                              (x) => x === activePage
                            );
                            if (index !== -1) {
                              setActivePage(paginationArr[index + 1]);
                            }
                          }}
                        >
                          {resLoading ? <span>Loading... <i className="fa fa-spinner fa-spin  "
                            aria-hidden="true"
                          ></i></span> : "Show More"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <h4 className="card-title">

                    <i className="fa fa-angle-left mr-2 pointer"
                      onClick={() => {
                        setView(false);
                      }}></i>
                    View - Transaction
                  </h4>
                  {/* <div className="form-holder">	
                {data ? <Row>{renderData()}</Row> : ''}	
                <Row className="pt-3 pb-5">	
                  {trx_history.map((hist: any, index: number) => {	
                    return hist.action !== 'CREATED' ? (	
                      <Col	
                        md="3"	
                        onClick={() => {	
                          if (!hist.check_list) {	
                            return	
                          }	
                          setTrxCheckList(hist.check_list);	
                          setViewCheckList(true);	
                        }}	
                      >	
                        <p className="number-text">{index}</p>	
                        <div className="auth-box">	
                          <p className="title">{hist.updated_by_role}</p>	
                          <p className="small mt-4">Status:</p>	
                          <p className="accepted">{hist.action}</p>	
                          {hist.updated_at !== '' ? (	
                            <>	
                              <p className="small mt-4">Last update</p>	
                              <p className="comment">	
                                {moment(hist.updated_at).format('DD-MMM-YYYY')}	
                              </p>	
                              <p className="comment">	
                                {moment(hist.updated_at).format('h:mm a')}	
                              </p>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {hist.note !== '' ? (	
                            <>	
                              <p className="small mt-4">Comment</p>	
                              <p className="comment">{hist.note}</p>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                        </div>	
                      </Col>	
                    ) : (	
                        ''	
                      );	
                  })}	
                </Row>	
                {viewCheckListPopup()}	
              </div> */}
                  <ViewTransaction data={data} />
                  <div className="line"></div>
                  <Row>
                    {trx_history.map((hist: any, index: number) => {
                      return (
                        <Col
                          md="3"
                          onClick={() => {
                            if (hist.action === 'CREATED') {
                              return;
                            }
                            if (!hist.check_list) return;
                            setTrxCheckList(hist.check_list);
                            setViewCheckList(true);
                          }}
                        >
                          <div className="card process-card pointer ">
                            <div className="card-header">
                              <h4 className="card-title">
                                <span className="card-number">
                                  {index + 1}
                                </span>
                                <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                              </h4>

                            </div>
                            <div className="card-body text-center">
                              <p className="small mt-2">Status:</p>
                              <p className="accepted">{hist.action}</p>
                              {hist.updated_at !== '' ? (
                                <>
                                  <p className="small mt-2">Last update</p>
                                  <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>
                                </>
                              ) : (
                                ''
                              )}
                              {hist.note !== '' && (
                                <>
                                  <p className="small mt-2">Comment</p>
                                  <p className="comment">{hist.note}</p>
                                </>
                              )}
                              {hist.created_by !== '' ? (
                                <>
                                  <p className="small mt-2">Created By</p>
                                  <p className="comment">{hist.created_by}</p>
                                  <p className="small mt-2">Created At</p>
                                  <h4 className="comment">  {moment(hist.created_at).format("DD-MM-YYYY")}</h4>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              {renderViewTxnModalPopup()}
              {renderModalForBankBalance()}
              {reverseTransactionModal()}
              {renderAnnouncementDividend()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default TransactionListing;