import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ToggleButton from 'react-toggle-button';
import { SectorCodes } from "../../../utils/customFunction";
import { addSalesCompany, updateSalesCompany } from "../../../stores/services/sales.service";
import { useHistory } from "react-router-dom";
import moment from "moment";

const EditSales = () => {
    const amcCode = sessionStorage.getItem('amc_code') || '';
    const email = sessionStorage.getItem('email') || ''
    const data = JSON.parse(sessionStorage.getItem("salesCompanyObj") || '{}')
    const history = useHistory();
    const [salesInHouse, setSalesInHouse] = useState(false);
    const [psxListed, setPSXListed] = useState(false)
    const [companyCode, setCompanyCode] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [logo, setLogo] = useState<any | null>("")
    const [logoFileName, setLogoFileName] = useState("")
    const logoRef = useRef<HTMLInputElement>(null)
    const [symbol, setSymbol] = useState("")
    const [type, setType] = useState("")
    const [isin, setISIN] = useState("")
    const [ntn, setNTN] = useState("")
    const [incorporationNumber, setIncorporationNumber] = useState("")
    const [sectorCode, setSectorCode] = useState("")
    const [website, setWebsite] = useState("")
    const [parentCompany, setParentCompany] = useState("")
    const [teams, setTeams] = useState<any>([])
    const [persons, setPersons] = useState<any>([])
    const [bankAccounts, setBankAccounts] = useState<any>([])
    const [teamFields, setTeamFields] = useState({
        team_id: "",
        team_name: "",
        team_members: [],
        status: true,
        team_manager: ""
    })
    const [personFields, setPersonFields] = useState({
        id: "",
        name: "",
        email: "",
        team_id: "",
        company_id: "",
        mobile: "",
        status:true,
        leaving_date:"",
        joining_date:""
    })
    const [bankAccountFields, setBankAccountFields] = useState({
        account_no: "",
        iban: "",
        account_title: "",
        bank_name: "",
        bank_code: "",
        branch_code: "",
        branch_name: "",
        default: false

    })
    const [addTeamModal, setAddTeamModal] = useState(false)
    const [teamEditFlag,setTeamEditFlag] = useState(false)
    const [addPersonModal, setAddPersonModal] = useState(false)
    const [personEditIndex,setPersonEditIndex] = useState(-1)
    const [addBankAccountModal, setAddBankAccountModal] = useState(false)
    const [bankEditIndex,setBankEditIndex] = useState(-1)
    const borderRadiusStyle = { borderRadius: 2 };

    const [teamValidateFields, setTeamValidateFields] = useState({
        team_id: "",
        team_name: ""
    })
    const [personValidateFields, setPersonValidateFields] = useState({
        email: "",
        name: ""
    })
    const [companyValidateFields, setCompanyValidateFields] = useState({
        company_code: "",
        company_name: ""
    })

    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        const obj = JSON.parse(sessionStorage.getItem("salesCompanyObj") || '{}')
        setSalesInHouse(obj.in_house)
        setCompanyCode(obj.company_code)
        setCompanyName(obj.company_name)
        setSymbol(obj.symbol)
        setIncorporationNumber(obj.incorporation_number)
        setISIN(obj.isin)
        setLogo(obj.logo)
        setNTN(obj.ntn)
        setParentCompany(obj.parent_company)
        setPersons(obj.persons)
        setPSXListed(obj.psx_listed)
        setSectorCode(obj.sector_code)
        setTeams(obj.teams)
        setType(obj.type)
        setWebsite(obj.website)
        setBankAccounts(obj.bank_accounts)


    },[])

    const updateSales = async () => {
        try {
            const isValid = CompanyValidateData()
            if (isValid) {
                setLoading(true)
                const response = await updateSalesCompany(email, amcCode, companyCode?.trim(), companyName?.trim(), logo, symbol, type, psxListed, isin, ntn, incorporationNumber, sectorCode, website, parentCompany, true, teams, persons, bankAccounts, salesInHouse)
                if (response.data.status === 200) {
                    // setLoading(false)
                    toast.success(response.data.message);
                    setTimeout(()=>{
                        history.replace('/admin/sales');
                    },2000)
                   
                }
                else {
                    setLoading(false)
                    toast.error(response.data.message);
                }
            } else {
                return toast.error("Kindly Fill All Required Fields")
            }

        } catch (error: any) {
            setLoading(false)
            toast.error(error.response.data.message?.toString());

        }
    }

    const renderTeamModal = () => {
        switch (addTeamModal) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => {
                            setAddTeamModal(false);
                            setTeamEditFlag(false)
                        }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setAddTeamModal(false);
                                    setTeamEditFlag(false)
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> {teamEditFlag ? "Edit Team" : "Add Team"} </h6>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Team ID*</div>
                                        <input
                                            type="text"
                                            readOnly={teamEditFlag}
                                            className={`form-control w-100 ${teamValidateFields.team_id ? "required-border" : ""}`}
                                            value={teamFields["team_id"]}
                                            onChange={(e) => {
                                                setTeamFields({
                                                    ...teamFields,
                                                    team_id: e.target.value,
                                                });
                                                setTeamValidateFields({
                                                    ...teamValidateFields,
                                                    team_id: ""
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Team Name*</div>
                                        <input
                                            type="text"
                                            className={`form-control w-100 ${teamValidateFields.team_name ? "required-border" : ""}`}
                                            value={teamFields["team_name"]}
                                            onChange={(e) => {
                                                setTeamFields({
                                                    ...teamFields,
                                                    team_name: e.target.value,
                                                });
                                                setTeamValidateFields({
                                                    ...teamValidateFields,
                                                    team_name: ""
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Team Manager</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={teamFields["team_manager"]}
                                            onChange={(e) => {
                                                setTeamFields({
                                                    ...teamFields,
                                                    team_manager: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div>
                                        <button className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                                            onClick={() => {
                                                let checkDuplicateID = teams.find(item => item.team_id == teamFields.team_id)
                                                const isValid = TeamValidateData()
                                                if (isValid) {
                                                    if (checkDuplicateID && !teamEditFlag) {
                                                        return toast.error("Team ID Already Exist")
                                                    }

                                                    if(teamEditFlag)
                                                    {
                                                        let findID = teams.findIndex(item=>item.team_id == teamFields.team_id) 

                                                        setTeams([...teams.slice(0,findID),teamFields,...teams.slice(findID+1)])

                                                    }else{
                                                        setTeams([...teams, teamFields])

                                                    }


                                                   
                                                    setAddTeamModal(false)
                                                    setTeamEditFlag(false)
                                                } else {
                                                    return toast.error("Please Fill All Required Fields")
                                                }

                                            }}
                                        >
                                            {teamEditFlag ? "Update" : "Add"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>)
            default:
                return ''
        }
    }

    const renderTeamsData = () => {
        return teams.map((item: any, index: number) => {
            return (
                <tr key={index}>
                    <td>{item.team_id}</td>
                    <td>{item.team_name}</td>
                    <td>{item.team_members?.length}</td>
                    <td>{item.team_manager}</td>
                    <td><ToggleButton
                        value={item.status}
                        inactiveLabel="Inactive"
                        activeLabel="Active"
                        thumbStyle={borderRadiusStyle}
                        trackStyle={borderRadiusStyle}

                        onToggle={() => {
                            if (item.status) {
                                setTeams([...teams.slice(0, index), { ...teams[index], status: false }, ...teams.slice(index + 1)])
                            } else {
                                setTeams([...teams.slice(0, index), { ...teams[index], status: true }, ...teams.slice(index + 1)])

                            }
                        }}
                    /></td>
                    <td>
                        <div >
                        <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" 
                            onClick={() => {	
                        
                                setTeamFields({team_id:item.team_id,team_name:item.team_name,team_manager:item.team_manager,team_members:item.team_members, status:item.status})
                                setTeamEditFlag(true)
                                setAddTeamModal(true)
                        }}>
                <i className="tim-icons icon-pencil">
                  </i></button>

                        <button type="button"
                         className="btn-icon btn-link like btn btn-danger btn-sm"
                         disabled={data?.teams.find(check=>item.team_id == check.team_id)}
                         onClick={() => {
                            setTeams([...teams.filter(data => data.team_id != item.team_id)])
                        }}>
                        <i className="tim-icons icon-simple-remove">
                 </i></button>
                 
                        </div>
                    </td>

                </tr>
            )
        })

    }

    const renderPersonModal = () => {
        switch (addPersonModal) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => {
                            setAddPersonModal(false);
                            setPersonEditIndex(-1)
                        }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setAddPersonModal(false);
                                    setPersonEditIndex(-1)
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> {personEditIndex > -1 ? "Edit Person" : "Add Person"} </h6>
                        </div>
                        <div className="modal-body">
                            <Row>

                                <Col md="6">
                                    <div className="form-group">
                                        <div>Name*</div>
                                        <input
                                            type="text"
                                            className={`form-control w-100 ${personValidateFields.name ? "required-border" : ""}`}
                                            value={personFields["name"]}
                                            onChange={(e) => {
                                                setPersonFields({
                                                    ...personFields,
                                                    name: e.target.value,
                                                });
                                                setPersonValidateFields({
                                                    ...personValidateFields,
                                                    name: ""
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Email*</div>
                                        <input
                                            type="text"
                                            className={`form-control w-100 ${personValidateFields.email ? "required-border" : ""}`}
                                            value={personFields["email"]}
                                            readOnly={personEditIndex > -1}
                                            onChange={(e) => {
                                                setPersonFields({
                                                    ...personFields,
                                                    email: e.target.value,
                                                    id: e.target.value,
                                                    company_id: companyCode || ""
                                                });
                                                setPersonValidateFields({
                                                    ...personValidateFields,
                                                    email: ""
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Mobile</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={personFields["mobile"]}
                                            onChange={(e) => {
                                                setPersonFields({
                                                    ...personFields,
                                                    mobile: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Team ID</div>

                                        <select
                                            className="form-control"
                                            value={personFields["team_id"]}
                                            onChange={(e) => {

                                                setPersonFields({
                                                    ...personFields,
                                                    team_id: e.target.value
                                                })

                                            }}

                                        >
                                            <option value="" hidden> Select Team</option>
                                            {teams?.filter(item => item.status == true)?.map(item => {
                                                return <option value={item.team_id} key={item.team_id}>{`${item.team_name} (${item.team_id})`}</option>
                                            })}

                                        </select>

                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Joining Date</div>
                                        <input
                                            type="date"
                                            className={`form-control w-100 `}
                                            value={personFields["joining_date"]}
                                            onChange={(e) => {
                                                setPersonFields({
                                                    ...personFields,
                                                    joining_date: e.target.value,
                                                });
                                                
                                            }}
                                        />

                                       

                                    </div>
                                </Col>

                                <Col md="12">
                                    <div>
                                        <button className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                                            onClick={() => {
                                                const isValid = PersonValidateData()
                                                if (isValid) {
                                                    let checkDuplicateID = persons.find((item, index) => item.email == personFields.email && index != personEditIndex)
                                                    if (checkDuplicateID) {
                                                        return toast.error("Email Already Exist")
                                                    }
                                                    if(personEditIndex > -1)
                                                    {
                                                        if(personFields.team_id != persons[personEditIndex].team_id)
                                                        {
                                                            let temp:any = []
                                                            let findIndex = teams.findIndex(item => item.team_id == personFields.team_id)
                                                            let removePersonTeam = teams.findIndex(item => item.team_id == persons[personEditIndex].team_id)
                                                            temp= [...teams.slice(0, findIndex), { ...teams[findIndex], team_members: [...teams[findIndex].team_members, personFields.id] }, ...teams.slice(findIndex + 1)]
                                                            setTeams( [...temp.slice(0, removePersonTeam), { ...temp[removePersonTeam], team_members: [...temp[removePersonTeam].team_members.filter(item=>item != persons[personEditIndex].id)] }, ...temp.slice(removePersonTeam + 1)])

                                                        }

                                                        setPersons([...persons.slice(0,personEditIndex),personFields,...persons.slice(personEditIndex+1)])


                                                    }else{
                                                        setPersons([...persons, personFields])
                                                    if (personFields.team_id) {
                                                        let findIndex = teams.findIndex(item => item.team_id == personFields.team_id)
                                                        setTeams([...teams.slice(0, findIndex), { ...teams[findIndex], team_members: [...teams[findIndex].team_members, personFields.id] }, ...teams.slice(findIndex + 1)])
                                                    }

                                                    }

                                                    

                                                    setAddPersonModal(false)
                                                    setPersonEditIndex(-1)
                                                } else {
                                                    return toast.error("Please Fill All Required Fields")
                                                }

                                            }}
                                        >
                                           {personEditIndex > -1 ? "Update" : "Add"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>)
            default:
                return ''
        }
    }

    const renderPersonsData = () => {

        return persons.map((item: any, index: number) => {
            return (
                <tr key={index}>

                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{teams.find(data => data.team_id == item.team_id)?.team_name || ""}</td>
                    <td>{item.mobile}</td>
                    <td>{item.joining_date ? moment(item.joining_date).format("YYYY-MM-DD") : ""}</td>
                    <td>{item.leaving_date ? moment(item.leaving_date).format("YYYY-MM-DD") : ""}</td>
                    <td><ToggleButton
                        value={item.status}
                        inactiveLabel="Inactive"
                        activeLabel="Active"
                        thumbStyle={borderRadiusStyle}
                        trackStyle={borderRadiusStyle}
                        onToggle={() => {
                            if (item.status) {
                                setPersons([...persons.slice(0, index), { ...persons[index], status: false,leaving_date:moment().format("YYYY-MM-DD") }, ...persons.slice(index + 1)])
                            } else {
                                setPersons([...persons.slice(0, index), { ...persons[index], status: true,leaving_date:"" }, ...persons.slice(index + 1)])

                            }
                        }}
                    /></td>

                    <td>
                    <div >
                        <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" 
                            onClick={() => {	
                                setPersonEditIndex(index)
                                setPersonFields(item)
                                setAddPersonModal(true)
                        
                                
                        }}>
                <i className="tim-icons icon-pencil">
                  </i></button>

                        <button type="button"
                         className="btn-icon btn-link like btn btn-danger btn-sm"
                         disabled={data?.persons.find(check=>item.email == check.email)}
                         onClick={() => {
                            if (item.team_id) {

                                let findIndex = teams.findIndex(data => data.team_id == item.team_id)
                                setTeams([...teams.slice(0, findIndex), { ...teams[findIndex], team_members: [...teams[findIndex]?.team_members?.filter(data => data != item.id)] }, ...teams.slice(findIndex + 1)])
                            }
                            setPersons([...persons.filter(data => data.id != item.id)])
                        }}>
               <i className="tim-icons icon-simple-remove">
                 </i></button>
                 
                        </div>
                        
                    </td>

                </tr>
            )
        })

    }
    const renderBankAccountModal = () => {
        switch (addBankAccountModal) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => {
                            setAddBankAccountModal(false);
                            setBankEditIndex(-1)
                        }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setAddBankAccountModal(false);
                                    setBankEditIndex(-1)
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> Add Bank Account </h6>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Account No</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["account_no"]}
                                            readOnly={bankEditIndex > -1}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    account_no: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>IBAN</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["iban"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    iban: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Account Title</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["account_title"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    account_title: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col md="6">
                                    <div className="form-group">
                                        <div>Bank Name</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["bank_name"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    bank_name: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Bank Code</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["bank_code"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    bank_code: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Branch Code</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["branch_code"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    branch_code: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <div>Branch Name</div>
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            value={bankAccountFields["branch_name"]}
                                            onChange={(e) => {
                                                setBankAccountFields({
                                                    ...bankAccountFields,
                                                    branch_name: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group d-flex mt-4 align-items-center">
                                        <ToggleButton
                                            value={bankAccountFields["default"]}
                                            thumbStyle={borderRadiusStyle}
                                            trackStyle={borderRadiusStyle}
                                            inactiveLabel="NO"
                                            activeLabel="YES"
                                            onToggle={() => {
                                                if (bankAccountFields["default"]) {
                                                    setBankAccountFields({
                                                        ...bankAccountFields,
                                                        default: false
                                                    })
                                                } else {
                                                    setBankAccountFields({
                                                        ...bankAccountFields,
                                                        default: true
                                                    })
                                                }
                                            }}
                                        />
                                        <label className="mt-2 ml-1">Default</label>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div>
                                        <button className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                                            onClick={() => {
                                                let checkDuplicateID = bankAccounts.find((item,index) => item.account_no == bankAccountFields.account_no && index != bankEditIndex)
                                                if (checkDuplicateID) {
                                                    return toast.error("Account No Already Exist")
                                                }
                                                if(bankEditIndex > -1)
                                                {

                                                    setBankAccounts([...bankAccounts.slice(0,bankEditIndex),bankAccountFields,...bankAccounts.slice(bankEditIndex+1)])
                                                }else{
                                                    setBankAccounts([...bankAccounts, bankAccountFields])

                                                }

                                                
                                                setAddBankAccountModal(false)
                                                setBankEditIndex(-1)

                                            }}
                                        >
                                           {bankEditIndex > -1 ? "Update" : "Add"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>)
            default:
                return ''
        }
    }
    const renderBankData = () => {

        return bankAccounts.map((item: any, index: number) => {
            return (
                <tr key={index}>

                    <td>{item.account_no}</td>
                    <td>{item.account_title}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.branch_name}</td>
                    <td>{item.default == true ? "YES" : "NO"}</td>

                    <td>
                        <div>
                        <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" 
                            onClick={() => {	
                                setBankAccountFields(item)
                                setBankEditIndex(index)
                                setAddBankAccountModal(true)
                        }}>
                <i className="tim-icons icon-pencil">
                  </i></button>

                    <button type="button"
                         className="btn-icon btn-link like btn btn-danger btn-sm"
                       disabled={data?.bank_accounts.find(check=>item.account_no == check.account_no)}
                         onClick={() => {
                            setBankAccounts([...bankAccounts.filter(data => data.account_no != item.account_no)])
                        }}>
               <i className="tim-icons icon-simple-remove">
                 </i></button>

                 </div>
                       
                    </td>

                </tr>
            )
        })

    }

    const CompanyValidateData = () => {
        let companyCodeError, companyNameError = ""

        companyCode?.trim() == "" ? companyCodeError = "Required" : companyCodeError = ""
        companyName?.trim() == "" ? companyNameError = "Required" : companyNameError = ""

        if (companyCodeError || companyNameError) {
            setCompanyValidateFields({
                company_code: companyCodeError,
                company_name: companyNameError
            })

            return false

        } else {
            return true
        }

    }

    const TeamValidateData = () => {
        let teamIdError, teamNameError = ""

        teamFields.team_id?.trim() == "" ? teamIdError = "Required" : teamIdError = ""
        teamFields.team_name?.trim() == "" ? teamNameError = "Required" : teamNameError = ""

        if (teamIdError || teamNameError) {
            setTeamValidateFields({
                team_id: teamIdError,
                team_name: teamNameError
            })

            return false

        } else {
            return true
        }

    }

    const PersonValidateData = () => {
        let emailError, NameError = ""

        personFields.email?.trim() == "" ? emailError = "Required" : emailError = ""
        personFields.name?.trim() == "" ? NameError = "Required" : NameError = ""

        if (emailError || NameError) {
            setPersonValidateFields({
                email: emailError,
                name: NameError
            })

            return false

        } else {
            return true
        }

    }

    const upload = (e) => {
        let file = e?.target.files[0];
       
        if(file)
        {
            let filesize = parseInt(file.size);

        let type = file.name.substring(file.name.lastIndexOf(".") + 1);

        if (type === "png" || type === "jpg" || type === "jpeg") {
            //code here

            setLogoFileName(file.name);
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
                setLogo(reader.result);
            };
        } else {
            toast.error("Invalid Format");
        }
    }

    };


    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Container fluid>
                        <ToastContainer limit={1} />
                        <div className="input-holder">
                            <h4 className="card-title">Edit - Sales</h4>
                            <Link
                                to="/admin/sales"
                                className="t-3 ml-auto btn btn-primary btn-sm"
                                replace
                            >
                                <i className="fa fa-eye mr-2"></i> View All
                            </Link>
                        </div>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Sales Company</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="form-group">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={salesInHouse}
                                                            onChange={(e) => {
                                                                setSalesInHouse(!salesInHouse);
                                                            }}
                                                        />
                                                        <span className="form-check-sign" />
                                                        In house sale
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            <div className="form-group">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={psxListed}
                                                            onChange={(e) => {
                                                                setPSXListed(!psxListed);
                                                            }}
                                                        />
                                                        <span className="form-check-sign" />
                                                        PSX Listed
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Code*</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 ${companyValidateFields.company_code ? "required-border" : ""}`}
                                                    value={companyCode}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Name*</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 ${companyValidateFields.company_name ? "required-border" : ""}`}
                                                    value={companyName}
                                                    onChange={(e) => {
                                                        setCompanyName(e.target.value);
                                                        setCompanyValidateFields({
                                                            ...companyValidateFields,
                                                            company_name: ""
                                                        })

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Symbol</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={symbol}
                                                    onChange={(e) => {
                                                        setSymbol(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Type</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={type}
                                                    onChange={(e) => {
                                                        setType(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>ISIN</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={isin}
                                                    onChange={(e) => {
                                                        setISIN(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>NTN</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={ntn}
                                                    onChange={(e) => {
                                                        setNTN(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Incorporation Number</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={incorporationNumber}
                                                    onChange={(e) => {
                                                        setIncorporationNumber(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Sector Code</label>
                                            <div  >
                                                <select
                                                    className="form-control"
                                                    value={sectorCode}
                                                    onChange={(e) => {
                                                        setSectorCode(e.target.value)

                                                    }}

                                                >
                                                    <option value="" hidden> Select Sector Code</option>
                                                    {SectorCodes?.map(item => {
                                                        return <option value={item.sector_code} key={item.sector_code}>{`${item.sector_name} (${item.sector_code})`}</option>
                                                    })}

                                                </select>

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Website</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={website}
                                                    onChange={(e) => {
                                                        setWebsite(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Parent Company</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={parentCompany}
                                                    onChange={(e) => {
                                                        setParentCompany(e.target.value);

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <div className="multi-input pointer"
                                                onClick={() => logoRef?.current?.click()}>
                                                <div className="form-group">
                                                    <div className="form-control">
                                                        {logoFileName === "" ? "Upload File" : logoFileName}
                                                    </div>
                                                    <input
                                                        type="file"
                                                        ref={logoRef}
                                                        style={{ display: "none" }}
                                                        multiple={false}
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => {
                                                            upload(e);
                                                        }}
                                                    />
                                                    {logo &&
                                                    <img
                                                    src={logo}
                                                    className="rounded mt-1"
                                                    
                                                    alt="logo"
                                                    // width="100px"
                                                    // height="100px"
                                                    style={{
                                                        maxWidth:'100px',
                                                        maxHeight:'100px',
                                                        width:'auto',
                                                        height:'auto'
                                                    }}
                                                  />}
                                                </div>

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="d-flex justify-content-between"
                                        >
                                            <div>Sales Team</div>

                                            <div>
                                                <ReactTooltip
                                                    textColor="white"
                                                    backgroundColor="black"
                                                    effect="float"
                                                />
                                                <button
                                                    data-tip="Add Sales Team"
                                                    className="btn-round btn-icon btn btn-primary"
                                                    onClick={() => {
                                                        setTeamFields({ team_id: "", team_name: "", team_members: [], status: true, team_manager: "" })
                                                        setAddTeamModal(true)
                                                    }}
                                                >
                                                    <i className="fa fa-plus"></i>{" "}
                                                </button>
                                            </div>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            {teams?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Name</th>
                                                                <th>Members</th>
                                                                <th>Manager</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderTeamsData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="d-flex justify-content-between"
                                        >
                                            <div>Sales Person</div>
                                            <div>
                                                <ReactTooltip
                                                    textColor="white"
                                                    backgroundColor="black"
                                                    effect="float"
                                                />
                                                <button
                                                    data-tip="Add Sales Person"
                                                    className="btn-round btn-icon btn btn-primary"
                                                    onClick={() => {
                                                        if (teams?.length == 0) {
                                                            return toast.error("Please Enter Team First")
                                                        }
                                                        setPersonFields({ id: "", name: "", email: "", team_id: "", company_id: "", mobile: "", status:true, leaving_date:"", joining_date:"" })
                                                        setAddPersonModal(true)

                                                    }}
                                                >

                                                    <i className="fa fa-plus"></i>{" "}
                                                </button>
                                            </div>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            {persons?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>

                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Team</th>
                                                                <th>Mobile</th>
                                                                <th>Joining Date</th>
                                                                <th>Leaving Date</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderPersonsData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4" className="d-flex justify-content-between">
                                            <div>Bank Account</div>
                                            <div>
                                                <ReactTooltip
                                                    textColor="white"
                                                    backgroundColor="black"
                                                    effect="float"
                                                />
                                                <button
                                                    data-tip="Add Bank Account"
                                                    className="btn-round btn-icon btn btn-primary"
                                                    onClick={() => {
                                                        setBankAccountFields({ account_no: "", iban: "", account_title: "", bank_name: "", bank_code: "", branch_code: "", branch_name: "", default: false })
                                                        setAddBankAccountModal(true)
                                                    }}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <div className="table-responsive">
                                            {bankAccounts?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>

                                                                <th>Account No</th>
                                                                <th>Account Title</th>
                                                                <th>Bank Name</th>
                                                                <th>Branch Name</th>
                                                                <th>Default</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderBankData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <div className="mt-2">
                            <button
                                className="btn btn-primary"
                                onClick={() => updateSales()}
                                disabled={Boolean(loading)}
                            >
                                {loading ? (
                                    <>
                                        <span
                                            className="spinner-border login-txt spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="login-txt"> Loading...</span>
                                    </>
                                ) : (
                                    <span>Update</span>
                                )}
                            </button>
                        </div>

                        {renderTeamModal()}
                        {renderPersonModal()}
                        {renderBankAccountModal()}
                    </Container>
                </Col>
            </Row>
        </div>
    );
};

export default EditSales;
