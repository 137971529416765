import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param name
 * @param code
 * @param companies
 */
const addSecurity = async (
  email: string,
  name: string,
  code: string,
  security_type: string,
  issue_date: string,
  maturity_date: string,
  face_value: string,
  price: string,
  coupon_payment_per_year:string,
  no_of_coupons:string,
  face_value_per_unit:string,
  kibor_rate_benchmark:string,
  system_execution_date:string,
  coupons:string,
  yield_percentage: string,
    kibor_rate: string,
    spread_rate: string,
    amc_code: string
  // companies: any
) => {
  const url = `${Config.baseUrl}/security/`;
  try{
  // var fd = new FormData();
  // fd.set('name', name);
  // fd.set('email', email);
  // fd.set('code', code);
  // fd.set('security_type', security_type);
  // fd.set('issue_date', issue_date);
  // fd.set('maturity_date', maturity_date);
  // fd.set('face_value', face_value);
  // fd.set('price', price);
  //   // fd.set('companies', companies);
   let obj= {
    'name': name,
    'email': email,
    'code': code,
    'security_type': security_type,
    'issue_date': issue_date,
    'maturity_date': maturity_date,
    'face_value': face_value,
    'price': price,
    'coupon_payment_per_year':coupon_payment_per_year,
    'no_of_coupons':no_of_coupons,
    'face_value_per_unit':face_value_per_unit,
    'kibor_rate_benchmark':kibor_rate_benchmark,
    'system_execution_date': system_execution_date,
    'coupons':coupons,
    yield_percentage,
    kibor_rate,
    spread_rate,
    amc_code
   }
  const result: any = await axios.post(url, obj, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addSecurity(email,name,code,security_type, issue_date, maturity_date, face_value, price, coupon_payment_per_year,
      no_of_coupons, face_value_per_unit, kibor_rate_benchmark,system_execution_date,coupons,yield_percentage,
      kibor_rate,
      spread_rate,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};


const editSecurity = async (
  email: string,
  name: string,
  code: string,
  security_type: string,
  issue_date: string,
  maturity_date: string,
  face_value: string,
  price: string,
  coupon_payment_per_year:string,
  no_of_coupons:string,
  face_value_per_unit:string,
  kibor_rate_benchmark:string,
  system_execution_date:string,
  coupons:string,
  yield_percentage: string,
    kibor_rate: string,
    spread_rate: string,
    amc_code: string
  // companies: an
) => {
  const url = `${Config.baseUrl}/security/update`;
  try{
  // const fd = new FormData();
  // fd.set('name', name);
  // fd.set('email', email);
  // fd.set('code', code);
  // fd.set('security_type', security_type);
  // fd.set('issue_date', issue_date);
  // fd.set('maturity_date', maturity_date);
  // fd.set('face_value', face_value);
  // fd.set('price', price);
  // // fd.set('companies', companies);
  let obj= {
    'name': name,
    'email': email,
    'code': code,
    'security_type': security_type,
    'issue_date': issue_date,
    'maturity_date': maturity_date,
    'face_value': face_value,
    'price': price,
    'coupon_payment_per_year':coupon_payment_per_year,
    'no_of_coupons':no_of_coupons,
    'face_value_per_unit':face_value_per_unit,
    'kibor_rate_benchmark':kibor_rate_benchmark,
    'system_execution_date': system_execution_date,
    'coupons':coupons,
    yield_percentage,
    kibor_rate,
    spread_rate,
    amc_code
   }
  const result: any = await axios.post(url, obj, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await editSecurity(email,name, code, security_type, issue_date, maturity_date, face_value, price, coupon_payment_per_year,
      no_of_coupons, face_value_per_unit, kibor_rate_benchmark,system_execution_date,coupons,yield_percentage,
      kibor_rate,
      spread_rate,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};


/**
 *
 * @param email
 */
const getAllSecurities = async (
  email: string,
  page_number: string = '',
  page_size: string = '',
  amc_code:string 
) => {
  const url = `${Config.baseUrl}/security?email=${email}&page_number=${page_number}&page_size=${page_size}&amc_code=${amc_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllSecurities(email,page_number, page_size,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param file
 *
 */

/**
 *
 *
 * @param search_value
 */
const searchSecurityAPI = async (email: string, search_value: string, amc_code:string='') => {
  const url = `${Config.baseUrl}/security?email=${email}&search_value=${search_value}&amc_code=${amc_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchSecurityAPI(email,search_value,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const uploadPsxFile = async (email: string, file: any, amc_code:string='') => {
  var bodyFormData = new FormData();
  bodyFormData.append('email', email);
  bodyFormData.append('file', file);
  bodyFormData.append("amc_code",amc_code)
  const url = `${Config.baseUrl}/security/psx/`;
  try{
  const result: any = await axios.post(url, bodyFormData, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await uploadPsxFile(email,file,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */
const getAllPsx = async (email: string,amc_code:string) => {
  const url = `${Config.baseUrl}/security/psx?email=${email}&amc_code=${amc_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllPsx(email,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */
const getPsxById = async (email: string, id: string) => {
  const url = `${Config.baseUrl}/security/psx/psx-by-code?email=${email}&id=${id}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getPsxById(email,id)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */
const getAllTransactionFilter = async (
  email: string,
  amc_name: string,
  type_of_security: string,
  amc_code:string = ''
) => {
  const url = `${Config.baseUrl}/security/query-data?email=${email}&amc_name=${amc_name}&type_of_security=${type_of_security}&amc_code=${amc_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllTransactionFilter(email,amc_name, type_of_security,amc_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const addEquitySecurity = async (
  email:string,
  symbol: string,
  date: string,
  sector_code: string,
  company_name: string,
  open_rate: string,
  high_rate: string,
  low_rate: string,
  close_rate: string,
  volume: string,
  ldcp: string,
  paid_up_capital: string,
  face_value: string,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/psxsecurity/`;
  try{
   let obj= {
    'email':email,
    'symbol':symbol,
    'date':date,
    'sector_code':sector_code,
    'company_name':company_name,
    'open_rate':open_rate,
    'high_rate':high_rate,
    'low_rate':low_rate,
    'close_rate':close_rate,
    'volume':volume,
    'ldcp':ldcp,
    'paid_up_capital':paid_up_capital,
    'face_value':face_value,
      amc_code
   }
  const result: any = await axios.post(url, obj, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addEquitySecurity(email,
      symbol,
      date,
      sector_code,
      company_name,
      open_rate,
      high_rate,
      low_rate,
      close_rate,
      volume,
      ldcp,
      paid_up_capital,
      face_value,
      amc_code
    )
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const editEquitySecurity = async (
  email:string,
  symbol: string,
  date: string,
  sector_code: string,
  company_name: string,
  open_rate: string,
  high_rate: string,
  low_rate: string,
  close_rate: string,
  volume: string,
  ldcp: string,
  paid_up_capital: string,
  face_value: string,
  amc_code:string
) => {
  const url = `${Config.baseUrl}/psxsecurity/update`;
  try{
  let obj= {
    'email':email,
    'symbol':symbol,
    'date':date,
    'sector_code':sector_code,
    'company_name':company_name,
    'open_rate':open_rate,
    'high_rate':high_rate,
    'low_rate':low_rate,
    'close_rate':close_rate,
    'volume':volume,
    'ldcp':ldcp,
    'paid_up_capital':paid_up_capital,
    'face_value':face_value,
    amc_code
   }
  const result: any = await axios.post(url, obj, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await editEquitySecurity(email,
      symbol,
      date,
      sector_code,
      company_name,
      open_rate,
      high_rate,
      low_rate,
      close_rate,
      volume,
      ldcp,
      paid_up_capital,
      face_value,
      amc_code
    )
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const updateEquitySecurityStatus = async (
  email: string,
  symbol: string,
  status: string,
  amc_code:string
) => {
  const url = `${Config.baseUrl}/psxsecurity/update-status`;
  try{
  const result: any = await axios.post(
    url,
    { email, symbol, status ,amc_code},
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateEquitySecurityStatus(email,symbol,status,amc_code)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

export {
  getAllTransactionFilter,
  getPsxById,
  getAllPsx,
  uploadPsxFile,
  searchSecurityAPI,
  addSecurity,
  editSecurity,
  getAllSecurities,
  addEquitySecurity,
  editEquitySecurity,
  updateEquitySecurityStatus
};
