import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../stores/services/funds.service";
import {
  getChartOfAccounts,
  getTransactionsLedgers,
} from "../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
  getLedgersByTxn,
} from "../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { numberWithCommas } from "./../utils/customFunction";
import { getPortfolioByDate } from "../stores/services/daily-unrealized-portfolio.service";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyPortfolioAccrual = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCheck, setFundCheck] = useState<any>(false);
  const [fund, setFund] = useState("");
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [tableData, setTableData] = useState<any>([]);
  let [todayData, setTodayData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [marketSelection, setMarketSelection] = useState<any>("");
  const [marketSelectionOption, setMarketSelectionOption] =
    useState<any>("Select Market Type");
  const [marketSelectionError, setMarketSelectionError] = useState<any>(false);

  let [todayPIBTotal, setTodayPIBTotal] = useState<any>(0);
  let [todayTBILLTotal, setTodayTBILLTotal] = useState<any>(0);
  let [todayTFCTotal, setTodayTFCTotal] = useState<any>(0);
  let [todaySUKUKTotal, setTodaySUKUKTotal] = useState<any>(0);

  let [finalPIB, setFinalPIB] = useState<any>(0);
  let [finalTBILL, setFinalTBILL] = useState<any>(0);
  let [finalTFC, setFinalTFC] = useState<any>(0);
  let [finalSUKUK, setFinalSUKUK] = useState<any>(0);

  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [todayDate, setTodayDate] = useState<any>(today);
  const [date, setDate] = useState<any>(today);

  const [disabled, setDisabled] = useState<any>(false);
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [transactionLedgers, setTransactionLedgers] = useState<any>("");

  const [pibLedgerPayment, setPibLedgerPayment] = useState<any>();
  const [pibLedgerRecipt, setPibLedgerRecipt] = useState<any>();

  let [affectedLedgerData, setAffectedLedgerData] = useState<any>([]);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "dailyportfolioaccrual",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: 0,
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  useEffect(() => {
    fetchTransactionLedgers();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code : "");
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {
      }
    };

    getFundByAMcCode(amc_code);
  }, []);

  const fetchTransactionLedgers = async () => {
    try {
      const response = await getTransactionsLedgers(email);
      if (response.data.status === 200) {
        let unitManagement = response.data.data.txn_catagory[4].portfolioTxn;
        // let txnLegders = await getLedgersByTxn(
        //   unitManagement,
        //   "dailyunrealizedportfolio"
        // );
        let txnLegders = unitManagement.filter((item, index) => {
          return item.api_name === "dailyportfolioaccrual";
        });
        setTransactionLedgers(txnLegders);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const handleMarketChange = async (e) => {
    setLoading(true);
    setTodayData([]);
    setTableData([]);
    setAffectedLedgerData([]);
    setSendingLedgers([]);
    setTodayPIBTotal(0);
    setTodayTBILLTotal(0);
    setTodayTFCTotal(0);
    setTodaySUKUKTotal(0);
    setFinalPIB(0);
    setFinalTBILL(0);
    setFinalTFC(0);
    setFinalSUKUK(0);
    setLoading(false);
  };

  const handleGet = async () => {
    try{
    affectedLedgerData = [];
    sendingLedgers = [];
    todayData = [];
    tableData = [];
    setLoading(true);
    setSendingLedgers(sendingLedgers);
    setTodayData(todayData);
    setTableData(tableData);
    setAffectedLedgerData(affectedLedgerData);
    setTodayPIBTotal(0);
    setTodayTBILLTotal(0);
    setTodayTFCTotal(0);
    setTodaySUKUKTotal(0);
    setFinalPIB(0);
    setFinalTBILL(0);
    setFinalTFC(0);
    setFinalSUKUK(0);

    let selectedFund = allFunds.filter((item) => {
      return item.symbol_code === fund;
    });

    const todayResponse = await getPortfolioByDate(
      email,
      amc_code,
      fund,
      todayDate
    );

    if (todayResponse.data.status === 404) {
      setLoading(false);
      return toast.error("No record found today for selected fund!");
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    //get ledgers for all security types to debit/credit

    let calculationPIB: any = 0;
    let calculationTBILL: any = 0;
    let calculationTFC: any = 0;
    let calculationSUKUK: any = 0;
    let calculationTDR: any = 0;
    let calculationLOP: any = 0;
    let calculationCP: any = 0;
    let calculationCDR: any = 0;

    selectedFund.map((item, index) => {
      if (JSON.parse(item.money_market).length === 0) {
        // setLoading(false);
        // return toast.error(
        //   "No record available for money market in selected fund"
        // );
        throw "No record available for money market in selected fund"
      } else {
        if (todayResponse.data.status === 404) {
          // setLoading(false);

          // return toast.error("No record found for Today");
          throw "No record found for Today"
        } else {
          JSON.parse(todayResponse?.data?.data[0]?.money_market).map(
            (item, index) => {
              
              if (item.security_type === "PIB") {
                calculationPIB = (
                  (parseFloat(item.total_face_value || "0") *
                    (parseFloat(item.coupon_rate || "0") /
                      100 /
                      parseFloat(item.coupon || "0"))) /
                  parseFloat(item.coupon_days || "0")
                );

                calculationPIB = isFinite(calculationPIB) ? calculationPIB.toFixed(2) : 0;

              }

              if (item.security_type === "TBILL") {
                calculationTBILL = (
                  (parseFloat(item.total_face_value || "0") -
                    parseFloat(item.total_amount || "0")) /
                  parseFloat(item.days_of_maturity || "0")
                )

                calculationTBILL = isFinite(calculationTBILL) ? calculationTBILL.toFixed(2) : 0;

              }

              if (item.security_type === "TFC") {
                calculationTFC = (
                  (parseFloat(item.unredeem_value || "0") *
                    (parseFloat(item.coupon_rate || "0") /
                      100 /
                      parseFloat(item.coupon_payment || "0"))) /
                  (parseFloat(item.coupon_rate || "0") / 100)
                )

                calculationTFC = isFinite(calculationTFC) ? calculationTFC.toFixed(2) : 0;

              }

              if (item.security_type === "SUKUK") {
                calculationSUKUK = (
                  (parseFloat(item.unredeem_value || "0") *
                    (parseFloat(item.coupon_rate || "0") /
                      100 /
                      parseFloat(item.coupon_payment || "0"))) /
                  (parseFloat(item.coupon_rate || "0") / 100)
                );

                calculationSUKUK = isFinite(calculationSUKUK) ? calculationSUKUK.toFixed(2) : 0;

              }

              if (item.security_type === "TDR") {
                calculationTDR = (
                  parseFloat(item.principal_amount || "0") *
                  (parseFloat(item.interest_rate || "0") / 100)
                );

                
                calculationTDR = isFinite(calculationTDR) ? calculationTDR.toFixed(2) : 0;

              }

              if (item.security_type === "LOP") {
                calculationLOP = (
                  parseFloat(item.principal_amount || "0") *
                  (parseFloat(item.interest_rate || "0") / 100)
                );
                calculationLOP = isFinite(calculationLOP) ? calculationLOP.toFixed(2) : 0;

              }

              if (item.security_type === "CP") {
                calculationCP = (
                  parseFloat(item.principal_amount || "0") *
                  (parseFloat(item.interest_rate || "0") / 100)
                )

                calculationCP = isFinite(calculationCP) ? calculationCP.toFixed(2) : 0;


              }

              if (item.security_type === "CDR") {
                calculationCDR = (
                  parseFloat(item.principal_amount || "0") *
                  (parseFloat(item.interest_rate || "0") / 100)
                )


                calculationCDR = isFinite(calculationCDR) ? calculationCDR.toFixed(2) : 0;


              }
            }
          );
          todayData = JSON.parse(todayResponse?.data?.data[0]?.money_market);
          setTodayData(todayData);
        }
      }
      tableData = JSON.parse(item.money_market);
      setTableData(tableData);

      return tableData;
    });

    if (tableData.length === 0) {
      toast.error("ERROR");
    }

    //PIB ledgers
    if (
      calculationPIB !== 0 &&
      !isNaN(calculationPIB) &&
      calculationPIB !== null &&
      calculationPIB !== undefined &&
      calculationPIB !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "PIB" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "PIB Debit",
          debit: calculationPIB,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "PIB Credit",
          debit: 0,
          credit: calculationPIB,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationPIB,
            description: `PIB DEBIT ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationPIB,
            debit_amount: "0",
            description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );

        setSendingLedgers(sendingLedgers);

        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationPIB)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //tbill ledgers
    if (
      calculationTBILL !== 0 &&
      !isNaN(calculationTBILL) &&
      calculationTBILL !== null &&
      calculationTBILL !== undefined &&
      calculationTBILL !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TBILL" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TBILL Debit",
          debit: calculationTBILL,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TBILL Credit",
          debit: 0,
          credit: calculationTBILL,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationTBILL,
            description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationTBILL,
            debit_amount: "0",
            description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );

        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationTBILL)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //tfc ledgers
    if (
      calculationTFC !== 0 &&
      !isNaN(calculationTFC) &&
      calculationTFC !== null &&
      calculationTFC !== undefined &&
      calculationTFC !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TFC" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TFC Debit",
          debit: calculationTFC,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TFC Credit",
          debit: 0,
          credit: calculationTFC,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationTFC,
            description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationTFC,
            debit_amount: "0",
            description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );

        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationTFC)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //sukuk ledgers
    if (
      calculationSUKUK !== 0 &&
      !isNaN(calculationSUKUK) &&
      calculationSUKUK !== null &&
      calculationSUKUK !== undefined &&
      calculationSUKUK !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "SUKUK" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "SUKUK Debit",
          debit: calculationSUKUK,
          credit: 0,
        },
        //credit
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "SUKUK Credit",
          debit: 0,
          credit: calculationSUKUK,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationSUKUK,
            description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationSUKUK,
            debit_amount: "0",
            description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );

        setSendingLedgers(sendingLedgers);

        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationSUKUK)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //tdr ledgers
    if (
      calculationTDR !== 0 &&
      !isNaN(calculationTDR) &&
      calculationTDR !== null &&
      calculationTDR !== undefined &&
      calculationTDR !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TDR" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TDR Debit",
          debit: calculationTDR,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TDR Credit",
          debit: 0,
          credit: calculationTDR,
        }
      );
      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationTDR,
            description: `TDR Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationTDR,
            debit_amount: "0",
            description: `TDR Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );
        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationTDR)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //lop ledgers
    if (
      calculationLOP !== 0 &&
      !isNaN(calculationLOP) &&
      calculationLOP !== null &&
      calculationLOP !== undefined &&
      calculationLOP !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "LOP" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "LOP Debit",
          debit: calculationLOP,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "LOP Credit",
          debit: 0,
          credit: calculationLOP,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationLOP,
            description: `LOP Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationLOP,
            debit_amount: "0",
            description: `LOP Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );
        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationLOP)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //cp ledgers
    if (
      calculationCP !== 0 &&
      !isNaN(calculationCP) &&
      calculationCP !== null &&
      calculationCP !== undefined &&
      calculationCP !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "CP" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "CP Debit",
          debit: calculationCP,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "CP Credit",
          debit: 0,
          credit: calculationCP,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationCP,
            description: `CP Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationCP,
            debit_amount: "0",
            description: `CP Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );
        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationCP)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    //cdr ledgers
    if (
      calculationCDR !== 0 &&
      !isNaN(calculationCDR) &&
      calculationCDR !== null &&
      calculationCDR !== undefined &&
      calculationCDR !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "CDR" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "CDR Debit",
          debit: calculationCDR,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "CDR Credit",
          debit: 0,
          credit: calculationCDR,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      await new Promise((resolve) => {
        sendingLedgers.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: debitLedger.ledger_root[0].child_family_code,
            child_family_name: debitLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: "0",
            debit_amount: calculationCDR,
            description: `CDR Debit ${debitLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: debitLedger.ledger_root[0].family_code,
            family_name: debitLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: debitLedger.ledger_root[0].gl_code,
            gl_name: debitLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: debitLedger.ledger_root[0].main_family_code,
            main_family_name: debitLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: debitLedger.ledger_root[0].sub_family_code,
            sub_family_name: debitLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: creditLedger.ledger_root[0].child_family_code,
            child_family_name: creditLedger.ledger_root[0].child_family_name,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            credit_amount: calculationCDR,
            debit_amount: "0",
            description: `CDR Credit ${creditLedger.ledger_root[0].gl_name}`,
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: creditLedger.ledger_root[0].family_code,
            family_name: creditLedger.ledger_root[0].family_name,
            fund_code: fund,
            gl_code: creditLedger.ledger_root[0].gl_code,
            gl_name: creditLedger.ledger_root[0].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: creditLedger.ledger_root[0].main_family_code,
            main_family_name: creditLedger.ledger_root[0].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: creditLedger.ledger_root[0].sub_family_code,
            sub_family_name: creditLedger.ledger_root[0].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
          }
        );
        setSendingLedgers(sendingLedgers);
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(calculationCDR)
        ).toFixed(2);

        setTimeout(() => {
          resolve("resolved");
        }, 500);
      });
    }

    setLoading(false);
  }catch(err :any)
  {
    console.log("err", err.message)
    setLoading(false);
    toast.error(err.response?.data?.message?.toString() || err.message || err || "Something Went Wrong")
  }
  };

  const handleRealized = async () => {
    setLoading(true);
    // if(todayData.length === 0) {
    //   return toast.error("No data for today")
    // }
    // sendingLedgers = [];
    // finalPIB = 0;
    // finalTBILL = 0;
    // finalTFC = 0;
    // finalSUKUK = 0;

    // if (finalPIB > 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "PIB" && item.payment_type === "Receipt";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalPIB,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalPIB,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalPIB,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalPIB,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalPIB < 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "PIB" && item.payment_type === "Payment";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalPIB),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalPIB),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalPIB),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalPIB),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalTBILL > 0) {

    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "TBILL" && item.payment_type === "Receipt";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalTBILL,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalTBILL,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalTBILL,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalTBILL,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalTBILL < 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "TBILL" && item.payment_type === "Payment";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalTBILL),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalTBILL),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalTBILL),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalTBILL),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalTFC > 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "TFC" && item.payment_type === "Receipt";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalTFC,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalTFC,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalTFC,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalTFC,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalTFC < 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "TFC" && item.payment_type === "Payment";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalTFC),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalTFC),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalTFC),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalTFC),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalSUKUK > 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "SUKUK" && item.payment_type === "Receipt";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalSUKUK,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalSUKUK,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: finalSUKUK,
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: finalSUKUK,
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }
    // if (finalSUKUK < 0) {
    //   let affectedLedger = transactionLedgers.filter((item, index) => {
    //     return item.security === "SUKUK" && item.payment_type === "Payment";
    //   });

    //   let debitLedger: any = [];
    //   let creditLedger: any = [];
    //   affectedLedger[0].ledgers.map((ledger, index) => {
    //     if (ledger.type === "Debit") {
    //       debitLedger = ledger;
    //     }
    //     if (ledger.type === "Credit") {
    //       creditLedger = ledger;
    //     }
    //   });

    //   if (sendingLedgers.length > 0) {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         ...sendingLedgers,
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalSUKUK),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalSUKUK),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   } else {
    //     await new Promise((resolve) => {
    //       sendingLedgers = [
    //         //debit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: debitLedger.ledger_root[0].child_family_code,
    //           child_family_name: debitLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: Math.abs(finalSUKUK),
    //           description: debitLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: debitLedger.ledger_root[0].family_code,
    //           family_name: debitLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: debitLedger.ledger_root[0].gl_code,
    //           gl_name: debitLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: debitLedger.ledger_root[0].main_family_code,
    //           main_family_name: debitLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: debitLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: debitLedger.ledger_root[0].sub_family_name,
    //         },
    //         //credit
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: creditLedger.ledger_root[0].child_family_code,
    //           child_family_name: creditLedger.ledger_root[0].child_family_name,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: Math.abs(finalSUKUK),
    //           debit_amount: "0",
    //           description: creditLedger.desc,
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: creditLedger.ledger_root[0].family_code,
    //           family_name: creditLedger.ledger_root[0].family_name,
    //           fund_code: fund,
    //           gl_code: creditLedger.ledger_root[0].gl_code,
    //           gl_name: creditLedger.ledger_root[0].gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: creditLedger.ledger_root[0].main_family_code,
    //           main_family_name: creditLedger.ledger_root[0].main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: creditLedger.ledger_root[0].sub_family_code,
    //           sub_family_name: creditLedger.ledger_root[0].sub_family_name,
    //         },
    //       ];

    //       setTimeout(() => {
    //         resolve("resolved");
    //       }, 2000);
    //     });
    //   }
    // }

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "dailyportfolioaccrual";
      fields.net_amount = fields.net_amount.toString();

      if (fields.ledgers.length > 0) {
        try {
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message?.toString());
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message?.toString());
            setSendingLedgers([]);
          } else {
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`Data added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              toast.success("");
              // history.push("/admin/create-transaction");
              //history.replace(`/admin/transactions-funds-management`);
              history.goBack()
            }, 1500);
            // toast.success(response.data.message);
          }
        } catch (error:any) {
          toast.error(error.response ? error.response.data.message?.toString() : error.message ? error.message : "Something went wrong")
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      return toast.error("No value to add to ledgers!");
    }

    // setLoading(false);
  };

  return (
    <>
      {/* <ToastContainer limit={1} /> */}
      <ToastContainer />

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        // history.replace("/admin/create-transaction");
                        //history.replace(`/admin/transactions-funds-management`);
                        history.goBack()
                      }}
                    ></i>
                    Daily Portfolio Accrual
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Daily Portfolio Accrual</h4>
                    </div>
                    <div className="card-body d-flex align-items-center row">
                      {/* <div className="row"> */}
                      <div
                        className="form-group col-md-4"
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <select
                            className={"form-control w-100 "}
                            value={fund}
                            disabled={false}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setFund(e.target.value);
                              setLoading(false);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {fundCheck ? "No Fund Found" : "Select Fund"}
                            </option>
                            {renderFundsDropdown()}
                          </select>
                        )}
                      </div>
                      {/* </div> */}

                      {/* <Row> */}
                      <div className="col-md-6 mt-2">
                        <button
                          className="btn btn-primary"
                          disabled={Boolean(Loading) || !fund}
                          onClick={() => {
                            handleGet();
                          }}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Get Portfolio</span>
                          )}
                        </button>
                      </div>
                      {/* </Row> */}
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : todayData.length !== 0 ? (
                <>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title text-primary">
                            Today {todayDate}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <td colSpan={4}>
                                    Fund Code: &nbsp; &nbsp;
                                    {fund}
                                  </td>
                                  <td colSpan={4}>
                                    AMC Code: &nbsp; &nbsp; {amc_code}
                                  </td>
                                  <td colSpan={4}>
                                    Market: &nbsp; &nbsp;{" "}
                                    {marketSelectionOption}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Symbol</th>
                                  <th>Security Type</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th align="right">Coupon Rate %</th>
                                  <th align="right">Yield %</th>
                                  <th align="right">
                                    Money market face value / unit
                                  </th>
                                  <th align="right">Total Face Value</th>
                                  <th align="right">Units</th>
                                  <th align="right">Avg cost / unit</th>
                                  <th align="right">Total cost / unit</th>
                                  <th align="right">Market rate / unit</th>
                                  <th align="right">Market Value</th>
                                  <th align="right">
                                    Unrealized Gain / (Loss)
                                  </th>
                                  <th align="right">
                                    Investment as % of market value
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {todayData.map((data, index) => {
                                  if (data.length !== 0) {
                                    return (
                                      <tr key={index}>
                                        <td>{data.code}</td>
                                        <td>{data.security_type}</td>
                                        <td>{data.name}</td>
                                        <td>{data.issue_date}</td>
                                        <td>{data.maturity_date}</td>
                                        <td align="right">
                                          {numberWithCommas(data.coupon_rate)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(data.yield)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(data.mmface_value)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.total_face_value
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(data.units)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.average_cost_per_unit
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(data.total_amount)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.market_rate_per_unit
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(data.market_value)}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.unrealized_gain_loss
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.investment_percentage_market_value
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </tbody>
                              {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* affected ledger table */}
                  {affectedLedgerData.length > 0 ? (
                    <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title text-primary">
                              Affected Ledgers
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Ledger Code</th>
                                    <th>Ledger Name</th>
                                    <th>Family Name</th>
                                    <th>Description</th>
                                    <th align="right">Debit</th>
                                    <th align="right">Credit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {affectedLedgerData.map((data, index) => {
                                    if (data.length !== 0) {
                                      return (
                                        <tr key={index}>
                                          <td>{data.glCode}</td>
                                          <td>{data.glName}</td>
                                          <td>{data.familyName}</td>

                                          <td>{data.description}</td>
                                          <td align="right">
                                            {/* {numberWithCommas(data.debit)} */}
                                            {data.debit}
                                          </td>
                                          <td align="right">
                                            {/* {numberWithCommas(data.credit)} */}
                                            {data.credit}
                                          </td>
                                        </tr>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                </tbody>
                                {/* <tfoot>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total Amount</th>
                      <th>{totalBalance}</th>
                    </tr>
                  </tfoot> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col>
                      <div className="row">
                        <div
                          className="col-md-6"
                          data-tip={
                            todayData.length !== 0
                              ? "Realize"
                              : "No today record found!"
                          }
                        >
                          <button
                            className="btn btn-primary"
                            disabled={
                              Boolean(Loading) ||
                              disabled ||
                              todayData.length === 0
                            }
                            // onClick={() => handleCreate()}
                            onClick={() => handleRealized()}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Calculate accrual</span>
                            )}
                          </button>
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyPortfolioAccrual;
