const PageTemplate = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          right: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    );
  };

  export default PageTemplate;