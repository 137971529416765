import React, { useEffect, useState } from "react";
// import Header from './../components/Header';
import { Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import Menu from "@material-ui/core/Menu";
import { useSelector } from "react-redux";
import {
  getCaptype,
  getTxnCatagory,
} from "./../stores/services/transactions.service";

const CreateTxnType = () => {
  const history = useHistory();
  const clearTransactiionHistory = () => {
    sessionStorage.removeItem("rejectedTxObj");
    sessionStorage.removeItem("rejectedTxName");
    sessionStorage.removeItem("rejectedFlag");
    sessionStorage.removeItem("rejectedAmc");
  };
  const PATH=window.location.href?.split("/")[window.location.href?.split("/")?.length -1]
  const [loading,setLoading] = useState(false)
  // let txnTypeCatagory = JSON.parse(sessionStorage.getItem("txnTypeCatagory") || "");
  let txnTypeCatagory:any = [];
  txnTypeCatagory = useSelector((state:any)=>state.txnTypeCategories);

  // useEffect(()=>{
  //   if(txnTypeCatagory.length==0){
  //     // fetchTxnTypeCategory();
  //   }
    
  // },[]);

  useEffect(()=>{
     setLoading(true)
    
     setTimeout(()=>{
       setLoading(false)
      
     },500)
    },[PATH])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

   
  const fetchTxnTypeCategory = async() => {
    try {
      const response = await getTxnCatagory("");
      txnTypeCatagory = response.data.txn_catagory;
      // dispatch(GetTxnTypeCategories(response.data.txn_catagory));
    } catch (error) {}
   }

  let last_id = sessionStorage.getItem("last_id") || "";
  const renderMenuPopup = () => {
    return (
      <Menu
        className=" shadow"
        id="simple-menu"
        anchorEl={anchorEl}
        // PaperProps={{
        //   style: {
        //     width: '70',
        //     outline: 'none',
        //     background: '#5ad2d2',
        //   },
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="  p-2 ">{last_id}</div>
      </Menu>
    );
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              {/* <Header /> */}
              
              <Row>
                <Col md="12 input-holder">
                  <h4 className="card-title">Transaction</h4>
                  {/* <Link
                    to="/admin/bulk-upload"
                    className="btn btn-primary btn-sm"
                    replace
                  >
                    <i className="fa fa-upload mr-2"></i> Bulk Upload
                  </Link> */}
                </Col>
              </Row>
              {loading ? <div style={{display:"flex", justifyContent:"center", alignItems:'center', height:'80vh'}}>
          <i className="fa fa-spinner fa-spin fa-4x "
                                      aria-hidden="true"
                                    ></i>
          </div> :

             txnTypeCatagory?.length>0 ? (
                PATH == "create-transactions-unit-management" ?
                <Row>
                
                   <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          {" "}
                          <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                          Unit Management
                        </h4>
                      </div>
                      <div className="card-body">
                        {txnTypeCatagory[0].unitManagementTxn?.filter(item=>item.dis_name != "Conversion In" && item.dis_name != "Conversion Out").map(
                          (item: any, index: number) => {
                            return (
                              <MenuItem
                                style={{
                                  minHeight: "0px", // mobile view
                                }}
                                key={index}
                                className="box-element"
                                onClick={() => {
                                  if (item.api_name === "unitconversion") {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      "Payment"
                                    );
                                  } else {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                  }
                                  clearTransactiionHistory();
                                  history.push(
                                    `/admin/unit-management-transaction/${item.api_name}`
                                  );
                                }}
                              >
                                <span className="text-hover text-silver">
                                  <span className={item.api_name + " ico"}></span>
                                  <i className="fa fa-angle-right"></i>{" "}
                                  {item.dis_name}
                                </span>
                              </MenuItem>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </Col>
                  </Row>
                  :
                  PATH == "create-transactions-accounts"
                  ?
                  <Row>
                    
                      <Col>
                        <div className="card" >
                          <div className="card-header">
                            <h4 className="card-title">
                              <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                              Accounting
                            </h4>
                          </div>
                          <div className="card-body">
                            {txnTypeCatagory[4].vouchersTxn.map(
                              (item: any, index: number) => {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    className="box-element"
                                    key={index}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/vouchers/${item.api_name}`
                                      );
                                      
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </Col>
                   
                  </Row>
                  :
                  PATH == "create-transactions-trade-settlement" ?
                  <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {" "}
                              <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                              Investments
                            </h4>
                          </div>
                          <div className="card-body">
                            {txnTypeCatagory[2].investmentTxn.map(
                              (item: any, index: number) => {
                                if (item.api_name == "right_share_subscription") {
                                  return (
                                    <MenuItem
                                      style={{
                                        minHeight: "0px", // mobile view
                                      }}
                                      key={index}
                                      className="box-element"
                                      onClick={() => {
                                       
                                        sessionStorage.setItem(
                                          "payment_type",
                                          `${item.payment_type}`
                                        );
                                        clearTransactiionHistory();
                                        history.push(
                                          `/admin/right-share-subscription/${item.api_name}`
                                        );
                                      }}
                                    >
                                      <span className="text-hover text-silver">
                                        <span
                                          className={item.api_name + " ico"}
                                        ></span>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        {item.dis_name}
                                      </span>
                                    </MenuItem>
                                  );
                                } else {
                                  return (
                                    <MenuItem
                                      style={{
                                        minHeight: "0px", // mobile view
                                      }}
                                      key={index}
                                      className="box-element"
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "payment_type",
                                          `${item.payment_type}`
                                        );
                                        clearTransactiionHistory();
                                        history.push(
                                          `/admin/investment-transaction/${item.api_name}`
                                        );
                                      }}
                                    >
                                      <span className="text-hover text-silver">
                                        <span
                                          className={item.api_name + " ico"}
                                        ></span>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        {item.dis_name}
                                      </span>
                                    </MenuItem>
                                  );
                                }
                              }
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
  
                  :
                  PATH == "create-transactions-funds-management" ?
                  <Row>
                    <Col>
                    <Row>
                    <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {" "}
                              <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                              Fund Management
                            </h4>
                          </div>
                          <div className="card-body">
                            {txnTypeCatagory[1].fundManagementTxn.map(
                              (item: any, index: number) => {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    className="box-element"
                                    key={index}
                                    onClick={() => {
                                    
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/fund-management-transaction/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              }
                            )}
                            {/* <MenuItem
                          style={{
                            minHeight: "0px", // mobile view
                          }}
                          className="box-element"
                          key="4"
                          onClick={() => {
                            history.push(`/admin/fund-dividend-payout`);
                            // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                            // clearTransactiionHistory();
                          }}
                        >
                          <span className="text-hover text-silver">
                            <span className={" ico"}></span>
                            <i className="fa fa-angle-right"></i>{" "}
                            {"Fund Dividend Payout"}
                          </span>
                        </MenuItem> */}
                          </div>
                        </div>
                      </Col>
                      </Row>
                      <Row>
                      <Col>
                        <div className="card" >
                          <div className="card-header">
                            <h4 className="card-title">
                              <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                              Portfolio
                            </h4>
                          </div>
                          <div className="card-body">
                            {txnTypeCatagory[5].portfolio.map(
                              (item: any, index: number) => {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    className="box-element"
                                    key={index}
                                    onClick={() => {
                                    
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/portfolio/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                      </Col>
                      <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          {" "}
                          <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                          Fee Payments
                        </h4>
                      </div>
                      <div className="card-body">
                        {txnTypeCatagory[3].feePaymentTxn.map(
                          (item: any, index: number) => {
                            return (
                              <MenuItem
                                style={{
                                  minHeight: "0px", // mobile view
                                }}
                                className="box-element"
                                key={index}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "payment_type",
                                    `${item.payment_type}`
                                  );
                                  clearTransactiionHistory();
                                  history.push(
                                    `/admin/fee-payment-transaction/${item.api_name}`
                                  );
                                }}
                              >
                                <span className="text-hover text-silver">
                                  <span className={item.api_name + " ico"}></span>
                                  <i className="fa fa-angle-right"></i>{" "}
                                  {item.dis_name}
                                </span>
                              </MenuItem>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </Col>
  
                  </Row>:

                PATH == "create-transactions-transactions" ?
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                        Unit Management
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[0].unitManagementTxn?.filter(item=>item.dis_name != "Conversion In" && item.dis_name != "Conversion Out").map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              key={index}
                              className="box-element"
                              onClick={() => {
                                if (item.api_name === "unitconversion") {
                                  sessionStorage.setItem(
                                    "payment_type",
                                    "Payment"
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "payment_type",
                                    `${item.payment_type}`
                                  );
                                }
                                clearTransactiionHistory();
                                history.push(
                                  `/admin/unit-management-transaction/${item.api_name}`
                                );
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>

                <Col>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                            Investments
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[2].investmentTxn.map(
                            (item: any, index: number) => {
                              if (item.api_name == "right_share_subscription") {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    key={index}
                                    className="box-element"
                                    onClick={() => {
                                     
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/right-share-subscription/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              } else {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    key={index}
                                    className="box-element"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/investment-transaction/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                            Fund Management
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[1].fundManagementTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                  
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/fund-management-transaction/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                          {/* <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.push(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                        Fee Payments
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[3].feePaymentTxn.map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              className="box-element"
                              key={index}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "payment_type",
                                  `${item.payment_type}`
                                );
                                clearTransactiionHistory();
                                history.push(
                                  `/admin/fee-payment-transaction/${item.api_name}`
                                );
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>

                <Col>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                            Accounting
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[4].vouchersTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/vouchers/${item.api_name}`
                                    );
                                    
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card" >
                        <div className="card-header">
                          <h4 className="card-title">
                            <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                            Portfolio
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[5].portfolio.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                  
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/portfolio/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              : <Row></Row>
                ):(
                  ''
                )}
              <Row></Row>
              {last_id !== "" && (
                <>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />
                  <AiOutlineInfoCircle
                    data-tip="Last Transaction"
                    className={"float-right mt-3"}
                    onClick={(e) => {
                      handleClick(e);
                    }}
                  />
                </>
              )}
              {renderMenuPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateTxnType;
