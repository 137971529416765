import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { updateFinancialPeriod } from "../../../stores/services/financial-period.service";
import moment from "moment";

const EditFinancialPeriod = () => {
  const existingItem: any = JSON.parse(
    sessionStorage.getItem("financialPeriod") || ""
  );
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  let date = new Date();

  const [startDate, setStartDate] = useState(
    moment(
      existingItem.start_date[0] ? existingItem.start_date[0] : date
    ).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(existingItem.end_date[0] ? existingItem.end_date[0] : date).format(
      "YYYY-MM-DD"
    )
  );
  const [description, setDescription] = useState(
    existingItem.description ? existingItem.description : ""
  );
  const [period, setPeriod] = useState(
    existingItem.period ? existingItem.period : ""
  );
  const [financial_id, setFinancialID] = useState(
    existingItem.financial_id ? existingItem.financial_id : ""
  );
  const [amcCode, setAmcCode] = useState(
    existingItem.amc_code ? existingItem.amc_code : ""
  );
  const [status, setStatus] = useState(
    existingItem.status ? existingItem.status : ""
  );

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [periodError, setPriodError] = useState("");

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const obj = JSON.parse(sessionStorage.getItem("financialPeriod") || "");
  //   setStartDate(obj.start_date);
  //   setEndDate(obj.end_date);
  //   setDescription(obj.description);
  //   setPeriod(obj.period);
  //   setFinancialID(obj.financial_id);
  //   setAmcCode(obj.amc_code);
  //   setStatus(obj.status);
  // }, []);

  const validate = () => {
    let startDateErr = "";
    let endDateErr = "";
    let descriptionErr = "";
    let periodErr = "";
    startDate.trim() === ""
      ? (startDateErr = "required-border")
      : (startDateErr = "");
    endDate.trim() === ""
      ? (endDateErr = "required-border")
      : (endDateErr = "");
    description.trim() === ""
      ? (descriptionErr = "required-border")
      : (descriptionErr = "");
    period.trim() === "" ? (periodErr = "required-border") : (periodErr = "");
    if (startDateErr || endDateErr || descriptionErr || periodErr) {
      setStartDateError(startDateErr);
      setEndDateError(endDateErr);
      setDescriptionError(descriptionErr);
      setPriodError(periodErr);
      return false;
    } else {
      return true;
    }
  };

  const saveFinancialPeriod = async () => {
    if (validate()) {
      try {
        setLoading(true);
        const response = await updateFinancialPeriod(
          email,
          amcCode ? amcCode : amc_code,
          financial_id,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          description,
          period,
          status
        );
        if (response.data.status == 200) {
          toast.success(response.data.message);
          sessionStorage.removeItem("financialPeriod");
          setTimeout(() => {
            history.replace("/admin/financial-periods");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response !== undefined) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      }
      setLoading(false);
    } // end of validate()
  };

  const calculatePeriod = (dateValue, valueType) => {
    if (valueType == "Start Date") {
      if (endDate !== "") {
        let endD = new Date(endDate);
        let findPeriod =
          new Date(dateValue).getFullYear() + "-" + endD.getFullYear();
        setPeriod(findPeriod);
      }
    }

    if (valueType == "End Date") {
      if (startDate !== "") {
        let startD = new Date(startDate);
        let findPeriod =
          startD.getFullYear() + "-" + new Date(dateValue).getFullYear();
        setPeriod(findPeriod);
      }
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title text-primary">
                    {" "}
                    <i
                      className="fa fa-angle-left mr-1 pointer"
                      onClick={() => {
                        sessionStorage.removeItem("financialPeriod");
                        history.replace("/admin/financial-periods");
                      }}
                    ></i>
                    Edit - Financial Period
                  </h4>

                  <Link
                    to="/admin/financial-periods"
                    className="btn btn-primary btn-sm ml-auto"
                    replace
                  >
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Start Date <span className="color-amber">*</span>
                        </label>
                        <input
                          type="date"
                          className={`form-control  w-100 ${startDateError}`}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                            setStartDateError("");
                            calculatePeriod(e.target.value, "Start Date");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          End Date<span className="color-amber">*</span>
                        </label>
                        <input
                          type="date"
                          className={`form-control  w-100 ${endDateError}`}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            setEndDateError("");
                            calculatePeriod(e.target.value, "End Date");
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Descirption<span className="color-amber">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Description"
                          className={`form-control  w-100 ${descriptionError}`}
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setDescriptionError(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Period<span className="color-amber">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Period"
                          readOnly
                          className={`form-control  w-100 ${periodError}`}
                          value={period}
                          onChange={(e) => {
                            setPeriod(e.target.value);
                            setPriodError("");
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={saveFinancialPeriod}
                    disabled={Boolean(loading)}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditFinancialPeriod;
