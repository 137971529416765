import React from "react";
// import Header from './../../components/Header';
import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { Container, Col, Row } from "reactstrap";
import {
  updateUnitHolderStatus,
  getUnitHoldersByAmcAndFolioNo,
  getAllUnitHolderByFolioNo,
} from "../../../stores/services/unit-holder.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { BsTable } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getAllInvestor, getPaginatedInvestor, updateInvestorStatus } from "../../../stores/services/potential-investor.service";
const csv = require("csvtojson/v2");

const PotentialInvestorList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const [isSearchActive, setSearchActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  React.useEffect(() => {
    
    searchPotentialInvestorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    sessionStorage.removeItem("investorObj")
    sessionStorage.removeItem("backUnitHolderPath");
    sessionStorage.removeItem("clientObj");
  }, []);
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [investorStatus, setInvestorStatus] = useState(true);
  const [code, setCode] = useState("");
  const [investorUpdateStatusLoading, setInvestorUpdateStatusLoading] = useState(false);


  const updateStatus = async () => {
    setInvestorUpdateStatusLoading(true);
   
    try {
      const response = await updateInvestorStatus(email,amcCode, code, !investorStatus);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      searchPotentialInvestorData();
    } catch (error) {}
    setInvestorUpdateStatusLoading(false);
  };
;
  const [searchValue, setSearchValue] = useState("");

  const searchPotentialInvestorData = async () => {    
    setLoading(true);
    try {
      const response = await getAllInvestor(
        email,
        amcCode,
        searchValue,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      if (response.data.status === 200) {
        setData(response.data.data);
        setLoading(false);
      
        setSearchActive(true);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message?.toString());
      } else {
        toast.error(error.message || "Request Failed!");
      }
    }
  
  };

  const renderUpdateBankStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal className="" dialogClassName="modal60w" show={true}>
            <div className="modal-header justify-content-center">
              <button
                aria-hidden="true"
                className="close"
                onClick={() => {
                  setStatusSetPopup(false);
                }}
                data-dismiss="modal"
                type="button"
              >
                <i className="tim-icons icon-simple-remove"></i>
              </button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {investorStatus ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{" "}
                    {investorStatus  ? "Deactivate" : "Activate"} this
                    Client?
                  </div>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary "
                        disabled={Boolean(investorUpdateStatusLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {investorUpdateStatusLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderData = () => {
    return data.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.investor_id}</td>
          <td>{items.name}</td>
          <td>{items.cnic}</td>
          <td>{items.city}</td>
          <td>{items.email}</td>
          <td>{moment(items.created_at).format("DD-MM-YYYY")}</td>
          <td className="captilize">{items.status == true ? "Active" : "Deactive"}</td>
          <td className="pointer text-center" >
            <div
              // className="mr-3"
              
            >
              <ReactTooltip		
                    textColor="white"		
                    backgroundColor="black"		
                    effect="float"		
                  />
              {items.status  ? (	
               <button onClick={() => {
                setStatusSetPopup(true);
                setInvestorStatus(items.status);
                setCode(items.investor_id);
              }}
               data-tip="Deactivate Investor" type="button" className="btn-icon btn-link like btn btn-success  btn-sm">
                <i className="fa fa-check"></i> 
              </button>
              ) : (	
                <button 
                onClick={() => {
                  setStatusSetPopup(true);
                  setInvestorStatus(items.status);
                  setCode(items.investor_id);
                }}
                 data-tip="Activate Investor" type="button" className="btn-icon btn-link like btn btn-danger  btn-sm">
                    <i className="tim-icons icon-simple-remove"> </i>
                </button>
              )} 

              <button
                type="button"
                data-tip="View Potential Investor" 	
                className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={() => {
                  sessionStorage.setItem("investorObj", JSON.stringify(items));
                  history.replace(`/admin/view-potential-investor`);
                }}
              >
                <i className="fa fa-eye"></i>
              </button>

              <button
                type="button"
                data-tip="Edit Potential Investor" 	
                className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem("investorObj", JSON.stringify(items));
                  history.replace(`/admin/edit-potential-investor`);
                }}
              >
                <i className="tim-icons icon-pencil"></i>
              </button>
              {items.folio_no  ? 
              <button
                type="button"
                data-tip="View UnitHolder" 	
                className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={async() => {
                  sessionStorage.setItem("investorObj", JSON.stringify(items));
                  const response = await getAllUnitHolderByFolioNo(email,items.folio_no);
                  sessionStorage.setItem("clientObj", JSON.stringify(response.data.data));
                  history.replace(`/admin/view-client`);
                }}
              >
                <i className="fa fa-eye"/>
              </button> :

              <button
              type="button"
              data-tip="Add UnitHolder" 	
              className="btn-icon btn-link like btn btn-primary btn-sm"
              onClick={() => {
                if(items.application_status == "VERIFIED")
                  {
                sessionStorage.setItem("investorObj", JSON.stringify(items));
                history.replace(`/admin/setup-clients`);
                  }else {
                    return toast.error("Documents not verified")
                  }
              }}
            >
              <i className="tim-icons icon-single-02"/>
            </button>}
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header /> */}
              <div className="card">
                <div className="d-flex align-items-center"></div>
                <div className="">
                  <div className="card-header">
                    <h4 className="card-title">
                     Potential Investor Listing
                      <button
                        className="btn btn-primary btn-sm mt-a float-right"
                        onClick={() => {
                          history.replace("/admin/setup-potential-investor");
                        }}
                      >
                        <i className="fa fa-plus mr-2"></i> Add New
                      </button>
                    </h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="4">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="form-group">
                          <input
                            type="search"
                            id="myInput"
                            placeholder="Enter Investor ID"
                            data-tip="Folio No"
                            className="form-control"
                            value={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                              // myFunction(e);
                              // handleSearchValue(e);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="">
                          <button
                            className="btn btn-primary mr-4"
                            disabled={Boolean(loading)}
                            onClick={searchPotentialInvestorData}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Searching...</span>
                              </>
                            ) : (
                              <span>
                                {" "}
                                <i className="fa fa-search"></i>Search
                              </span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    {loading ? (
                      <div className="d-flex justify-content-center my-5">
                        <i
                          className="fa fa-spinner fa-spin fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : data.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Investor ID</th>
                              <th>Name</th>
                              <th>CNIC</th>
                              <th>City</th>
                              <th>Email</th>
                              <th>Registration Date</th>
                              <th>Status</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>{renderData()}</tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">
                          No record found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {renderUpdateBankStatusPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PotentialInvestorList;
