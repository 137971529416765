
import React, { useState, useRef, useEffect }  from 'react';
// import Header from './../../components/Header';	
import { getAllBanks, getCities } from '../../../stores/services/bank.service';	
import { getBranchByBankName } from '../../../stores/services/branch.service';		
import ReactTooltip from 'react-tooltip';	
import { getFundByAmc } from '../../../stores/services/funds.service';	
import { BsPlusCircleFill } from 'react-icons/bs';	
import { Modal } from 'react-bootstrap';	
import { IoIosClose } from 'react-icons/io';	
import { Container, Row, Col, FormGroup, Label, Input, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';	
import moment from 'moment';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';	
import ToggleButton from 'react-toggle-button';
import { replace } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import {accountTitle_AccountNumber_Regex, numberWithCommas} from '../../../utils/customFunction';
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router-dom';
import { getSalesCompanyByAMCCode } from '../../../stores/services/sales.service';
import { CITIES, COUNTRIES, E_WALLET_PROVIDERS, FilesByInvestorType, GENDER, MARITAL_STATUS, OCCUPATION, RELATION, SALUTATIONS, ImagesUploadType, PROVINCES } from './potential-custom-functions';
import {  updateInvestor } from '../../../stores/services/potential-investor.service';
import Select from 'react-select';

const ViewPotentialInvestor = () => {	
  //hooks for getting all inputs from user	

  const data = JSON.parse(sessionStorage.getItem('investorObj') || '{}');
  const email = sessionStorage.getItem('email') || '';	
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const history = useHistory();	
   const [name, setName] = useState(data.name || '');	
  const [cnic, setCnic] = useState(data.cnic || '');	
  const [cnicExpiry, setCnicExpiry] = useState(data.cnic_expiry ?data.cnic_expiry?.split("T")[0] :'');	
  const [passport, setPassport] = useState(data.passport_no || '');	
  const [passportExpiry, setPassportExpiry] = useState(data.passport_expiry ?data.passport_expiry?.split("T")[0] :'');	
  const [ntn, setNtn] = useState(data.ntn || '');	
  const [taxStatus, setTaxStatus] = useState(data.tax_status || '');
  //	
  const [clientEmail, setClientEmail] = useState(data.email || '');	
  const [mobile, setMobile] = useState(data.mobile ||'');	
  const [phone, setPhone] = useState(data.phone || '');	
  const [address, setAddress] = useState(data.address || '');	
  const [city, setCity] = useState( '');
  const [province, setProvince] = useState( '');
  //	
  const [accTitle, setAccTitle] = useState(data.account_title || '');	
  const [accNo, setAccNo] = useState(data.account_no || '');	
  const [bankname, setBankName] = useState(data.bank_name || '');	
  const [branchName, setBranchName] = useState(data.branch_name || '');	
  useEffect(()=>{
    if(data.city)
    {
      setCity(data.city?.slice(data.city.indexOf(" ") + 1) )
    }
    if(data.province)
      {
        setProvince(PROVINCES.findIndex(item=>item.value == data.province) == -1 ? "Other" : data.province.slice(data.province.indexOf(" ") + 1))
      }
  },[])


  //	
  const [reinvestCheck, setReinvestCheck] = useState(data.reinvest == true ? true :false);	
    const [fundLoading, setFundLoading] = useState<boolean>(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  // guardian
  const [guardian_name, setGuardian_name] = useState(data.guardian_name || '');	
  const [guardian_relation, setGuardian_relation] = useState(data.guardian_relation || '');	
  const [guardian_cnic, setGuardian_cnic] = useState(data.guardian_cnic || '');	
  // nominee
  const [nominee_name, setNomineeName] = useState(data.nominee_name || '');	
  const [nominee_relation, setNomineeRelation] = useState(data.nominee_relation || '');	
  const [nominee_cnic, setNomineeCnic] = useState(data.nominee_cnic || '');	
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState(data.joint_h_1_name || '');	
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState(data.joint_h_1_father_spouse || '');	
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState(data.joint_h_1_cnic || '');	
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState(data.joint_h1_tax_status || '');
  const [joint_h_1_ntn, setJointHolderOneNTN] = useState(data.joint_h_1_ntn || '');
  const [joint_h_1_address_bill, setJointHolderOneAddressBill] = useState(data.joint_h_1_address_bill || '');
  const [joint_h_1_address_bill_type, setJointHolderOneAddressBillType] = useState(data.joint_h_1_address_bill_type || '');

  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState(data.joint_h_2_name || '');	
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState(data.joint_h_2_father_spouse || '');	
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState(data.joint_h_2_cnic || '');	
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState(data.joint_h2_tax_status || '');
  const [joint_h_2_ntn, setJointHolderTwoNTN] = useState(data.joint_h_2_ntn || '');
  const [joint_h_2_address_bill, setJointHolderTwoAddressBill] = useState(data.joint_h_2_address_bill || '');
  const [joint_h_2_address_bill_type, setJointHolderTwoAddressBillType] = useState(data.joint_h_2_address_bill_type || '');

  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState(data.joint_h_3_name || '');	
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState(data.joint_h_3_father_spouse || '');	
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState(data.joint_h_3_cnic || '');	
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState(data.joint_h3_tax_status || '');
  const [joint_h_3_ntn, setJointHolderThreeNTN] = useState(data.joint_h_3_ntn || '');
  const [joint_h_3_address_bill, setJointHolderThreeAddressBill] = useState(data.joint_h_3_address_bill || '');
  const [joint_h_3_address_bill_type, setJointHolderThreeAddressBillType] = useState(data.joint_h_3_address_bill_type || '');

  const [portfolioManagement, setPortfolioManagement] = useState(data.portfolio_management || '');	

  const [cdc_IAS, setCDCIAS] = useState(data.cdc_ias || '');
  const [cdc_subAccount, setCDCSubAccount] = useState(data.cdc_sub_account || '');
  const [cdc_participantID, setCDCParticipantID] = useState(data.cdc_participant_id || '');

  const [authorized_person,setAuthorizedPerson] = useState(data.authorized_person || '');
  const [filer,setFiler]=useState(data.filer == true ? true :false);
  const [registered_business_address,setRegisteredBusinessAddress]=useState(data.registered_business_address || '');
  const [partnership_registered,setPartnershipRegistered]=useState(data.partnership_registered == true ? true :false);

  const [allSalesCompany,setAllSalesCompany] = useState<any>([]);
  const [ type , setType ] = useState(data.type || '');
  const [totalFunds, setTotalFunds] = useState<any>([]);	
  const [fundEntity, setFundEntity] = useState<any>({	
    fund_code: '',	
    fund_name: '',	
    nav: '',	
    fund_unit: '',	
  });	
  const [father, setFather] = useState(data.father_spouse || '');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(data.zakat_exempt == true ? true : false);

  const [modalView, setModalView] = useState(false);	
  const [salesCompany,setSalesCompany] = useState(data.sales_company || "")
  const [salesPerson,setSalesPerson] = useState(data.sales_person || "")
  const [salesInHouse,setSalesInHouse] = useState(data.in_house_sale == true ? true : false)

  const filesRef = useRef({})
  const [files, setFiles] = useState<any>({})
  const [filesLink, setFilesLink] = useState<any>({})
  const borderRadiusStyle = { borderRadius: 2 };

  const [accountType,setAccountType] = useState(data.account_type || "")
  const [jointOneRelation,setJointOneRelation] = useState(data.joint_h1_relation || "")
  const [jointTwoRelation,setJointTwoRelation] = useState(data.joint_h2_relation || "")
  const [jointThreeRelation,setJointThreeRelation] = useState(data.joint_h3_relation || "")
  const [cnicLifeTime,setCNICLifetime] = useState(data.lifetime_cnic == true ? true : false)
  const [guardianCNICLifeTime,setGuadianCNICLifeTime] = useState(data.guardian_lifetime_cnic == true ? true : false)
  const [nomineeCNICLifeTime,setNomineeCNICLifeTime] = useState(data.nominee_lifetime_cnic == true ? true : false)
  const [jointOneCNICLifeTime,setJointOneCNICLifeTime] = useState(data.joint_h1_lifetime_cnic == true ? true : false)
  const [jointTwoCNICLifeTime,setJointTwoCNICLifeTime] = useState(data.joint_h2_lifetime_cnic == true ? true : false)
  const [jointThreeCNICLifeTime,setJointThreeCNICLifeTime] = useState(data.joint_h3_lifetime_cnic == true ? true : false)
  const [guardianCNICExpiry,setGuardianCNICExpiry] = useState(data.guardian_cnic_expiry ?data.guardian_cnic_expiry?.split("T")[0] :'')
  const [nomineeCNICExpiry,setNomineeCNICExpiry] = useState(data.nominee_cnic_expiry ?data.nominee_cnic_expiry?.split("T")[0] :'')
  const [jointOneCNICExpiry,setJointOneCNICExpiry] = useState(data.joint_h1_cnic_expiry ?data.joint_h1_cnic_expiry?.split("T")[0] :'')
  const [jointTwoCNICExpiry,setJointTwoCNICExpiry] = useState(data.joint_h2_cnic_expiry ?data.joint_h2_cnic_expiry?.split("T")[0] :'')
  const [jointThreeCNICExpiry,setJointThreeCNICExpiry] = useState(data.joint_h3_cnic_expiry ?data.joint_h3_cnic_expiry?.split("T")[0] :'')
  const [country,setCountry] = useState({value:data.country || "", label:data.country?.slice(data.country.indexOf(" ") + 1) || ""})
  const [occupation,setOccupation] = useState(data.occupation || "")
  const [guardianOccupation,setGuardianOccupation] = useState(data.guardian_occupation || "")
  const [nomineeOccupation,setNomineeOccupation] = useState(data.nominee_occupation || "")
  const [jointOneOccupation,setJointOneOccupation] = useState(data.joint_h1_occupation || "")
  const [jointTwoOccupation,setJointTwoOccupation] = useState(data.joint_h2_occupation || "")
  const [jointThreeOccupation,setJointThreeOccupation] = useState(data.joint_h3_occupation || "")
  const [annualIncome,setAnnualIncome] = useState(data.annual_income || "")
  const [guardianAnnualIncome,setGuardianAnnualIncome] = useState(data.guardian_annual_income || "")
  const [nomineeAnnualIncome,setNomineeAnnualIncome] = useState(data.nominee_annual_income || "")
  const [jointOneAnnualIncome,setJointOneAnnualIncome] = useState(data.joint_h1_annual_income || "")
  const [jointTwoAnnualIncome,setJointTwoAnnualIncome] = useState(data.joint_h2_annual_income || "")
  const [jointThreeAnnualIncome,setJointThreeAnnualIncome] = useState(data.joint_h3_annual_income || "")
  const [motherMaidenName,setMotherMaidenname] = useState(data.mother_maiden_name || "")
  const [guardianMotherMaidenName,setGuardianMotherMaidenname] = useState(data.guardian_mother_maiden_name || "")
  const [nomineeMotherMaidenName,setNomineeMotherMaidenname] = useState(data.nominee_mother_maiden_name || "")
  const [jointOneMotherMaidenName,setJointOneMotherMaidenname] = useState(data.joint_h1_mother_maiden_name || "")
  const [jointTwoMotherMaidenName,setJointTwoMotherMaidenname] = useState(data.joint_h2_mother_maiden_name || "")
  const [jointThreeMotherMaidenName,setJointThreeMotherMaidenname] = useState(data.joint_h3_mother_maiden_name || "")
  const [placeOfBith,setPlaceOfBirth] = useState(data.place_of_birth || "")
  const [guardianPlaceOfBith,setGuardianPlaceOfBirth] = useState(data.guardian_place_of_birth || "")
  const [nomineePlaceOfBith,setNomineePlaceOfBirth] = useState(data.nominee_place_of_birth || "")
  const [jointOnePlaceOfBith,setJointOnePlaceOfBirth] = useState(data.joint_h1_place_of_birth || "")
  const [jointTwoPlaceOfBith,setJointTwoPlaceOfBirth] = useState(data.joint_h2_place_of_birth || "")
  const [jointThreePlaceOfBith,setJointThreePlaceOfBirth] = useState(data.joint_h3_place_of_birth || "")
  const [gender,setGender] =useState(data.gender || "")
  const [guardianGender,setGuardianGender] =useState(data.guardian_gender || '')
  const [nomineeGender,setNomineeGender] =useState(data.nominee_gender || '')
  const [jointOneGender,setJointOneGender] =useState(data.joint_h1_gender || "")
  const [jointTwoGender,setJointTwoGender] =useState(data.joint_h2_gender ||"")
  const [jointThreeGender,setJointThreeGender] =useState(data.joint_h3_gender ||"")
  const [maritalStatus,setMaritalStatus] = useState(data.marital_status || "")
  const [guardianMaritalStatus,setGuardianMaritalStatus] = useState(data.guardian_marital_status || "")
  const [nomineeMaritalStatus,setNomineeMaritalStatus] = useState(data.nominee_marital_status || "")
  const [jointOneMaritalStatus,setJointOneMaritalStatus] = useState(data.joint_h1_marital_status || "")
  const [jointTwoMaritalStatus,setJointTwoMaritalStatus] = useState(data.joint_h2_marital_status || "")
  const [jointThreeMaritalStatus,setJointThreeMaritalStatus] = useState(data.joint_h3_marital_status || "")
  const [eWalletProvider,setEWalletProvider] = useState(data.e_wallet_provider || "")
  const [eWalletProviderNumber,setEWalletProviderNumber] = useState(data.e_wallet_provider_number || "")
  
 
  const [openCloseCards,setOpenCloseCards] = useState({
    registration:true,
    personal_details:false,
    contact:false,
    bank_account_details:false,
    guardian_details:false,
    nominee_details:false,
    joint_one:false,
    joint_two:false,
    joint_three:false,
    cdc:false,
    sales_details:false,
    documents:false,

  })
  
  //error getting hooks	
  const [accTitleError, setAccTitleError] = useState('');	
  const [accNoError, setAccNoError] = useState('');	
  const [bankNameError, setBankNameError] = useState('');	
  	
  const [phoneError, setPhoneError] = useState('');	
  const [clientEmailError, setClientEmailError] = useState('');	
  const [cnicExpiryError, setCnicExpiryError] = useState('');	
  const [cnicError, setCnicError] = useState('');	
  const [passportExpiryError, setPassportExpiryError] = useState('');	
  const [passportError, setPassportError] = useState('');	
  const [ntnError, setNtnError] = useState('');	
  const [mobileError, setMobileError] = useState('');	
  const [addressError, setAddressError] = useState('');	
  
  //for dropdown data	
  const [bankNameData, setBankNameData] = useState<any>([]);	
  
 
  
  const getAllSalesCompany = async (code: string) => {	
   
    try {	
      const response = await getSalesCompanyByAMCCode(email, code);	
      if(response.data.status == 200)
        {
          setAllSalesCompany(response.data.data)
        }else{
          setAllSalesCompany([])
        }
     
    } catch (error) {
      setAllSalesCompany([])
    }	
  };	

 	
  useEffect(()=>{
   
    getAllSalesCompany(amc_code)
  },[])


  
  React.useEffect(() => {	
    const fetchAmc = async () => {		
      bankNameData.length = 0;	
      setBankNameData(bankNameData);	
      setBankNameData(bankNameData);	

      //get all banks list for dropdown	
      try {	
        const response = await getAllBanks(email,"","",amc_code);	
        setBankNameData(response.data.data);	
      } catch (error) {}	
      
    };	
    fetchAmc();	
  }, []);	

 
 
	
  //render Fund table data	
  const renderFundDataInTable = () => {	
    return totalFunds.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.fund_code}</td>	
          <td>{items.fund_name}</td>	
          <td>{items.nav}</td>	
          <td>{items.fund_unit}</td>	
          	
          
        </tr>	
      );	
    });	
  };	
 	
  

  return (		
    <>		
     <div className="content">
     <Row>
      <Col md="12">
      <Container fluid>		
        <ToastContainer limit={1} />		
        {/* <Header />		 */}

        <div className="input-holder">		
              <h4 className="card-title"><i className="fa fa-angle-left mr-1 pointer" 
              onClick={() => {
              history.replace('/admin/potential-investors')
          }}></i>View - Potential Investor</h4>		
              <Link		
                to={JSON.parse(sessionStorage.getItem('clientObj') || '{}')?.folio_no ? "/admin/unit-holders" :"/admin/potential-investors"	}	
                className="t-3 ml-auto btn btn-primary btn-sm"		
                replace		
              >		
               <i className="fa fa-eye mr-2"></i> View All		
              </Link>		
            </div>	

            <Row>
            <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                      Registration
                      <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,registration:!openCloseCards.registration})}>
                        <i className={"tim-icons " + (openCloseCards.registration ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.registration &&
                  <CardBody>

                  <div className="form-group  ">
                      <label>Folio No</label>
                      <div className=" ">
                        <input
                          type="text"
                          className={`form-control  w-100 `}
                          value={data.folio_no}
                        />
                      </div>
                    </div>

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Investor Type</label>
                        <div>
                        <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={type}		
                       	
                      />
                       </div>
                    </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Account Type</label>
                         <div>
                         <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={accountType}		
                       	
                      />
                          
                      </div>
                </div>

                    </Col>
                  </Row>

      
                  <Row>
                    <Col md="6">
                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={taxStatus}		
                       	
                      />
                     
                 </div>
                 </Col>
                 <Col md="6">
                      <div className="form-group">
                        <label>Portfolio Management</label>
                         <div>
                         <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={portfolioManagement}		
                       	
                      />
                          
                      </div>
                </div>

                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between allign-flex"  >
                        
                        <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={zakat_exempt}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                            //   onToggle={() => {
                            //     if (zakat_exempt) {
                            //       setZakat_exempt(false);
                            //     } else {
                            //       setZakat_exempt(true);
                            //     }
                            //   }}
                            />
                            <label className="mt-2 ml-1">Zakat Exempt</label> 
                      </div> 
                      <div className="mt-3">
                      <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={reinvestCheck}		
                            //   onChange={(e) => {		
                            //     setReinvestCheck(!reinvestCheck);		
                            //   }} 
                              />		
                              <span className="form-check-sign" />		
                              Fund Dividend Reinvest		
                            </Label>		
                          </FormGroup>	
                      </div>

                     
                  </div>
                  <div className="d-flex justify-content-between allign-flex"  >

                  
                  <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={filer}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                            //   onToggle={() => {
                            //     if (filer) {
                            //       setFiler(false);
                            //     } else {
                            //       setFiler(true);
                            //     }
                            //   }}
                            />
                            <label className="mt-2 ml-1">Filer</label> 
                      </div> 

                      {type == "Partnership" &&
                       <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={partnership_registered}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                            //   onToggle={() => {
                            //     if (partnership_registered) {
                            //       setPartnershipRegistered(false);
                            //     } else {
                            //       setPartnershipRegistered(true);
                            //     }
                            //   }}
                            />
                            <label className="mt-2 ml-1">Partnership Registered</label> 
                      </div> }

                      </div>
 
                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                     <CardTitle className='d-flex justify-content-between' tag="h4">
                     Personal Details

                     <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,personal_details:!openCloseCards.personal_details})}>
                        <i className={"tim-icons " + (openCloseCards.personal_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.personal_details &&
                  <CardBody>
                    <Row>
                     <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		

                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={name.split("–")[0]}		
                       	
                      />

                    </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Client Name <span className="color-amber">*</span> </label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={name.includes("–") ?name?.split("–")[1] : name}		
                        	
                      />		
                    </div>		
                  </div> 
                  </Col>
                  </Row>  

                  <Row>
                     <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={father.split("–")[0]}		
                       	
                      />	
                    
                    </div>		
                       </div> 

                      </Col>

                      <Col>
                 
                      <div className="form-group ">
                        <label>Father/Spouse Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={father.includes("–") ?father?.split("–")[1] : father}
                           
                          />
                        </div>
                      </div>
                      </Col>

                    </Row>

                    <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={motherMaidenName.split("–")[0]}		
                       	
                      />
                    
                     
                            </div>		
                       </div> 

                      </Col>

                      <Col>
                      <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={motherMaidenName.includes("–") ?motherMaidenName?.split("–")[1] : motherMaidenName}
                            
                          />
                        </div>
                      </div>
                      </Col>
                      </Row>

                      <div className="form-group ">
                        <label>Authroized Person</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={authorized_person}
                           
                          />
                        </div>
                      </div>  

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Gender</label>		
                            <div  >	
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={gender}		
                       	
                      />	
                             
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>Martial Status</label>		
                            <div  >		
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={maritalStatus}		
                       	
                      />
                              
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Occupation</label>		
                            <div  >		
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={occupation}		
                       	
                      />
                             
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>Annual Income</label>		
                            <div  >		
                            <input		
                        type="number"		
                        className={`form-control  w-100`}		
                        value={numberWithCommas(annualIncome)}		
                        
                      />			

                       
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">		
                        <label>Place Of Birth</label>		
                        <div  >		
                          <input		
                            type="number"		
                            className={`form-control  w-100 `}		
                            value={placeOfBith}		
                          	
                          />		
                          
                        </div>		
                      </div>
                  


                    
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={cnicLifeTime}		
                              
                              />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >	
                              <InputMask
                                className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                  }`}
                                mask="99999-9999999-9"
                                value={cnic} 
                                
                                
                                >

                                </InputMask>

                              {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}		
                            </div>		
                          </div>
                          
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                readOnly={cnicLifeTime}
                                className={`form-control  w-100 ${		
                                  cnicExpiryError ? ' required-border' : ''		
                                }`}		
                                value={cnicExpiry}		
                                	
                              />		
                              {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Passport</label>		
                            <div  >		
                              <input		
                                type="text"		
                                className={`form-control  w-100 ${		
                                  passportError ? ' required-border' : ''		
                                }`}		
                                value={passport}		
                                
                              />		

                       
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Passport Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 ${		
                                  passportExpiryError ? 'required-border' : ''		
                                }`}		
                                value={passportExpiry}		
                                		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>
                        

                      
                      <div className="form-group  ">		
                        <label>NTN</label>		
                        <div  >		
                          <input		
                            type="number"		
                            className={`form-control  w-100 ${		
                              ntnError ? 'required-border' : ''		
                            }`}		
                            value={ntn}		
                            
                          />		
                          {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}		
                        </div>		
                      </div>
                     

                 

                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Contact

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,contact:!openCloseCards.contact})}>
                        <i className={"tim-icons " + (openCloseCards.contact ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.contact &&
                   <CardBody>
                  <div className="form-group  ">		
                    <label>Email</label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          clientEmailError ? 'required-border' : ''		
                        }`}		
                        value={clientEmail}		
                        	
                      />		
                    </div>		
                  </div>

                  <Row>
                    <Col md="12">
                    <div className="form-group  ">		
                    <label>Mobile</label>		
                    <div  >		
                     
                      <PhoneInput
                      specialLabel=""
                       inputClass={`form-control  w-100 ${		
                        mobileError ? 'required-border' : ''		
                      }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={mobile}
                        		
                      />

                      {/* {mobileError ? <p className="error-labels error-message">{mobileError}</p> : ''} */}		
                    </div>		
                  </div>
                    </Col>
                   
                  </Row>
                  <div className="form-group  ">		
                    <label>Phone</label>		
                    <div  >		
                      
                      <PhoneInput
                      specialLabel=""
                      inputClass={`form-control  w-100 ${		
                        phoneError ? 'required-border' : ''		
                        }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={phone}
                        		
                      />

                      {/* {phoneError ? <p className="error-labels error-message2">{phoneError}</p> : ''} */}		
                    </div>		
                  </div>
                   
                  <div className="form-group  ">		
                    <label>Address</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          addressError ? 'required-border' : ''		
                        }`}		
                        value={address}		
                        	
                      />		
                      {/* {addressError ? <p className="error-labels error-message2">{addressError}</p> : ''} */}		
                    </div>		
                  </div>
                  <div className="form-group  ">		
                    <label>Registered Business Address</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={registered_business_address}		
                       	
                      />		
                    </div>		
                  </div>
                  <div className="form-group  ">
                    <label>Country</label>
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={country?.label || ""}		
                       	
                      />
                    
                  </div>

                <Row>

                <Col>
                  <div className="form-group">		
                    <label>Province</label>		
                    <div  >		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={province}		
                       	
                      />
                        
                          </div>		
                  </div>
                  </Col>
                  <Col>
                  <div className="form-group  ">		
                    <label>City</label>		
                    <div>		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={city}		
                       	
                      />
                     </div>		
                  </div>
                  </Col>

                 
                  </Row>
                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Bank Account Detail

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,bank_account_details:!openCloseCards.bank_account_details})}>
                        <i className={"tim-icons " + (openCloseCards.bank_account_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.bank_account_details &&
                   <CardBody>
                  <div className="form-group  ">		
                    <label>Account Title <span className="color-amber">*</span></label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accTitleError ? 'required-border' : ''		
                        }`}		
                        value={accTitle}		
                        	
                      />		
                     
                    </div>		
                  </div>

                  <div className="form-group  ">		
                    <label>Account No <span className="color-amber">*</span></label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accNoError ? 'required-border' : ''		
                        }`}		
                        value={accNo}		
                       	
                      />		
                     		
                    </div>		
                  </div>

                  <Row>
                  <Col md="6">
                    <div className="form-group  ">		
                      <label>Bank Name <span className="color-amber">*</span></label>		
                      <div  >	

                         <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={bankname}		
                       	
                      />	
                       	
                      </div>		
                    </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group  ">		
                    <label>Branch Name <span className="color-amber">*</span></label>		
                    <input type="text" className={`form-control  w-100 `}		
                          value={branchName}		
                          
                    />
                    
                  </div>
                  </Col>
                  </Row>

                  <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>E Wallet Provider</label>		
                            <div  >		
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={eWalletProvider}		
                       	
                      />
                            
                            </div>		
                          </div>
                        </Col>
                        {eWalletProvider &&
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>E Wallet Provider Number</label>		
                            <div  >		
                            <input		
                        type="number"		
                        className={`form-control  w-100`}		
                        value={eWalletProviderNumber}		
                        		
                      />			

                       
                            </div>		
                          </div>
                        </Col>}
                      </Row>

 
                  </CardBody>}
                </Card>
              </Col> 

              {/*add new cards  */} 
              
              <Col md="4"  >
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Guardian Details

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,guardian_details:!openCloseCards.guardian_details})}>
                        <i className={"tim-icons " + (openCloseCards.guardian_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.guardian_details &&
                  <CardBody>

                    <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardian_name.split("–")[0]}		
                       	
                      />
                   
                            </div>		
                       </div> 

                      </Col>

                      <Col>

                  <div className="form-group">		
                    <label>Guardian  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardian_name.includes("–") ?guardian_name?.split("–")[1] : guardian_name}		
                        	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <div className="form-group">		
                    <label>Guardian Relation   </label>		
                    <div  >	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardian_relation}		
                       	
                      />	
                      
                     
                    </div>		
                  </div>

                      <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={guardianMotherMaidenName}
                            
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                            <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={guardianGender}		
                                
                            />	
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardianMaritalStatus}		
                       	
                      />	
                              
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardianOccupation}		
                       	
                      />	
                             
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={numberWithCommas(guardianAnnualIncome)}
                                
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="number"
                            className={`form-control  w-100 `}
                            value={guardianPlaceOfBith}
                            
                          />
                        </div>
                      </div>
                 
                  <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={guardianCNICLifeTime}		
                               />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={guardian_cnic}
                        ></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={guardianCNICLifeTime}	
                                value={guardianCNICExpiry}		
                                	
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                  </CardBody>}
                </Card>
              </Col>
                
            
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                      Nominee Details

                      <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,nominee_details:!openCloseCards.nominee_details})}>
                        <i className={"tim-icons " + (openCloseCards.nominee_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.nominee_details &&
                  <CardBody>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	

                         <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nominee_name.split("–")[0]}				
                       	
                      />		
                   
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Nominee Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nominee_name.includes("–") ?nominee_name?.split("–")[1] : nominee_name}		
                        	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <div className="form-group">		
                    <label>Nominee  Relation   </label>		
                    <div  >		

                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nominee_relation}	
                       	
                      />	
                      	
                    </div>		
                  </div>

                  <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={nomineeMotherMaidenName}
                            
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nomineeGender}	
                       	
                      />	
                             
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nomineeMaritalStatus}	
                       	
                      />	
                              
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nomineeOccupation}	
                       	
                      />	
                              
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={numberWithCommas(nomineeAnnualIncome)}
                                
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="number"
                            className={`form-control  w-100 `}
                            value={nomineePlaceOfBith}
                            
                          />
                        </div>
                      </div>
                  
                  <Row>
                  <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={nomineeCNICLifeTime}		
                               />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={nominee_cnic}
                        ></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={nomineeCNICLifeTime}	
                                value={nomineeCNICExpiry}		
                                	
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                  </CardBody>}
                </Card>
              </Col>
              
              
              
              {type == "Joint Account" &&
               <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 1

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_one:!openCloseCards.joint_one})}>
                        <i className={"tim-icons " + (openCloseCards.joint_one ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.joint_one && 
                  <CardBody>

                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	

                        <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_name.split("–")[0]}
                       	
                      />		
                  
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_name.includes("–") ?joint_h_1_name?.split("–")[1] : joint_h_1_name}		
                        	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_father_spouse.split("–")[0]}		
                       	
                      />	
                   
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label> Father/Spouse Name </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_father_spouse.includes("–") ?joint_h_1_father_spouse?.split("–")[1] : joint_h_1_father_spouse}		
                       	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
   
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneMotherMaidenName.split("–")[0]}		
                       	
                      />		
                   
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneMotherMaidenName.includes("–") ?jointOneMotherMaidenName?.split("–")[1] : jointOneMotherMaidenName}		
                        
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  <div className="form-group">		
                    <label>Relation </label>		
                    <div  >		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneRelation}	
                       	
                      />
                       
                    </div>		
                  </div>
                 

                  
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneGender}	
                       	
                      />
                              
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneMaritalStatus}	
                       	
                      />
                              
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneOccupation}	
                       	
                      />
                              

                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={numberWithCommas(jointOneAnnualIncome)}
                                
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="number"
                            className={`form-control  w-100 `}
                            value={jointOnePlaceOfBith}
                            
                          />
                        </div>
                      </div>
                 

                   <Row>
                  <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={jointOneCNICLifeTime}		
                               />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={joint_h_1_cnic}
                        ></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={jointOneCNICLifeTime}	
                                value={jointOneCNICExpiry}		
                               		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>  
                              

                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_ntn}		
                        	
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_address_bill}		
                        		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>

                     <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_address_bill_type}	
                       	
                      />
                     
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_taxStatus}	
                       	
                      />
                     
                 </div>

                  </CardBody>}
                </Card>
              </Col>}

              {type == "Joint Account" &&
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 2

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_two:!openCloseCards.joint_two})}>
                        <i className={"tim-icons " + (openCloseCards.joint_two ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.joint_two &&
                  <CardBody>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_name.split("–")[0]}	
                       	
                      />	
                    
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_name.includes("–") ?joint_h_2_name?.split("–")[1] : joint_h_2_name}		
                        		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_father_spouse.split("–")[0]}
                       	
                      />	
                   
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Father/Spouse Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_father_spouse.includes("–") ?joint_h_2_father_spouse?.split("–")[1] : joint_h_2_father_spouse}		
                        	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	

                        <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointTwoMotherMaidenName.split("–")[0]}
                       	
                      />		
                    
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointTwoMotherMaidenName.includes("–") ?jointTwoMotherMaidenName?.split("–")[1] : jointTwoMotherMaidenName}		
                        		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  		
                 
                  

                  <div className="form-group">		
                    <label> Relation   </label>		
                    <div  >		
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointTwoRelation}		
                       	
                      />	
                       	
                    </div>		
                  </div>
                  
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointTwoGender}		
                       	
                      />	
                              
                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >

                            <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={jointTwoMaritalStatus}		
                                
                            />	
                             

                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >

                            <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={jointTwoOccupation}		
                                
                            />	
                             
                            

                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={numberWithCommas(jointTwoAnnualIncome)}
                                
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="number"
                            className={`form-control  w-100 `}
                            value={jointTwoPlaceOfBith}
                            
                          />
                        </div>
                      </div>
                  

                  <Row>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <div className='d-flex justify-content-between '>
                          <label>CNIC</label>
                          <FormGroup check className='mt-0'>
                            <Label check>
                              <Input type="checkbox"
                                checked={jointTwoCNICLifeTime}
                                
                                />
                              <span className="form-check-sign" />
                              Life Time
                            </Label>
                          </FormGroup>
                        </div>
                        <div  >


                          <InputMask
                            className={`form-control  w-100 `}
                            mask="99999-9999999-9"
                            value={joint_h_2_cnic}
                           ></InputMask>

                        </div>
                      </div>
                    </Col>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <label>CNIC Expiry</label>
                        <div  >
                          <input
                            type="date"
                            className={`form-control  w-100 `}
                            readOnly={jointTwoCNICLifeTime}
                            value={jointTwoCNICExpiry}
                           
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>  
                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_ntn}		
                        	
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_address_bill}		
                        		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>
                     <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={joint_h_2_address_bill_type}		
                                
                            />	
                             
                    
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={joint_h_2_taxStatus}		
                                
                            />	
                             
                    
                 </div>

                  </CardBody>}
                </Card>
              </Col>}

              {type == "Joint Account" &&
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 3

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_three:!openCloseCards.joint_three})}>
                        <i className={"tim-icons " + (openCloseCards.joint_three ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.joint_three && 
                  <CardBody>

                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <input		
                                type="text"		
                                className={`form-control  w-100 `}		
                                value={joint_h_3_name.split("–")[0]}			
                                
                            />	
                             
                    
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_name.includes("–") ?joint_h_3_name?.split("–")[1] : joint_h_3_name}		
                        		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_father_spouse.split("–")[0]}			
                                
                        />	
                    
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Father/Spouse Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_father_spouse.includes("–") ?joint_h_3_father_spouse?.split("–")[1] : joint_h_3_father_spouse}		
                        		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                   <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>	
                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeMotherMaidenName.split("–")[0]}		
                                
                        />		

                        
                  
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeMotherMaidenName.includes("–") ?jointThreeMotherMaidenName?.split("–")[1] : jointThreeMotherMaidenName}		
                        	
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                 
                  <div className="form-group">		
                    <label>Relation   </label>		
                    <div  >	

                        <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeRelation}		
                                
                        />		
                      
                    </div>		
                  </div>

                 
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeGender}		
                                
                        />	
                             

                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeMaritalStatus}		
                                
                        />	
                             

                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >

                            <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeOccupation}		
                                
                        />	
                             

                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={numberWithCommas(jointThreeAnnualIncome)}
                                
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={jointThreePlaceOfBith}
                            
                          />
                        </div>
                      </div>
                 

                  <Row>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <div className='d-flex justify-content-between '>
                          <label>CNIC</label>
                          <FormGroup check className='mt-0'>
                            <Label check>
                              <Input type="checkbox"
                                checked={jointThreeCNICLifeTime}
                                />
                              <span className="form-check-sign" />
                              Life Time
                            </Label>
                          </FormGroup>
                        </div>
                        <div  >


                          <InputMask
                            className={`form-control  w-100 `}
                            mask="99999-9999999-9"
                            value={joint_h_3_cnic}
                            ></InputMask>

                        </div>
                      </div>
                    </Col>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <label>CNIC Expiry</label>
                        <div  >
                          <input
                            type="date"
                            className={`form-control  w-100 `}
                            readOnly={jointThreeCNICLifeTime}
                            value={jointThreeCNICExpiry}
                           
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>  

                  

                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_ntn}		
                        	
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_address_bill}		
                        	
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>

                     <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_address_bill_type}		
                                
                        />	
                    
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>

                     <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_taxStatus}		
                                
                        />	
                     
                 </div>

                  </CardBody>}
                </Card>
              </Col>}



              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       CDC

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,cdc:!openCloseCards.cdc})}>
                        <i className={"tim-icons " + (openCloseCards.cdc ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.cdc &&
                  <CardBody>

                  <div className="form-group">		
                    <label>Investment Account Service</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_IAS}		
                        	
                      />		 	
                    </div>		
                  </div>
                  <div className="form-group">		
                    <label> CDC Subaccount </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_subAccount}		
                        	
                      />	

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>CDC participant ID</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_participantID}		
                        	
                      />
                
                    </div>		
                  </div>


                  </CardBody>}
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Sales Details

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,sales_details:!openCloseCards.sales_details})}>
                        <i className={"tim-icons " + (openCloseCards.sales_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.sales_details &&
                  <CardBody>
                  <div className="form-group">
                    <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox" 	
                           checked={salesInHouse}	
                           />	
                          <span className="form-check-sign" />	
                          In house sale
                        </Label>	
                      </FormGroup>
                    </div>

                    <div className="form-group">		
                    <label>Sales Company</label>		
                    
                    <div  >		

                    <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={salesCompany}		
                                
                        />	
                    
                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Sales Person</label>		

                    
                    <div  >	

                         <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={salesPerson}		
                                
                        />	
                   

                    </div>		
                  </div>
                 


                  </CardBody>}
                </Card>
              </Col>
               
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                    Funds Portfolio
                    </CardTitle>
                  </CardHeader>
                  <CardBody> 
                      <ReactTooltip		
                        textColor="white"		
                        backgroundColor="black"		
                        effect="float"		
                      />		
                    	
                    

                    {totalFunds.length > 0 && (		
                    <div className="input-size-pos mb-3 mx-3">		
                      <table className="table">		
                        <thead>		
                        <tr>
                            <th>Fund code</th>
                            <th>Fund Name</th>
                            <th>NAV</th>
                            <th>Fund Unit</th>
                            
                          </tr>	
                        </thead>		
                        <tbody>{renderFundDataInTable()}</tbody>		
                      </table>		
                    </div>		
                  )}	

                  </CardBody>

                  
                </Card>


                
              </Col>
              <Col md="12">

                <Card>
                  <CardHeader>
                  <CardTitle className='d-flex justify-content-between' tag="h4">
                    Document

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,documents:!openCloseCards.documents})}>
                        <i className={"tim-icons " + (openCloseCards.documents ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>

                  </CardHeader>


                 {openCloseCards.documents &&
                  <CardBody className='row'>
                    {type && FilesByInvestorType[type]?.map((item, index) => {
                      return(
                        <div className="form-group col-md-6" key={item.value}>
                        <label style={{textTransform:"capitalize"}}>{item.label} &nbsp; &nbsp;
                        <small className="color-amber">
                        (PNG, JPG, JPEG, PDF, ZIP)
                         </small></label>
                         <div className='row'>
                        
                      <div className="col-md-3"
                      
                      >
                         <input		
                        type="text"		
                        className={`form-control w-100 `}		
                        
                        value={files[item.value]?.name ? files[item.value]?.name :"Upload File"  }	
                        		
                      />
                      </div>
                      <div className='col-md-1 text-center'>OR</div>

                      <div className='col-md-6'>

                      <input		
                        type="text"		
                        className={`form-control w-100 `}		
                        placeholder='Enter Link'
                        value={filesLink[item.value] || data[item.value]?.file || ""}		
                        		
                      />

                      </div>
                        </div>
                        {data[item.value]?.file && 
                        <div className='d-flex '>
                            <div className='mr-3'>
                                <a href={data[item.value]?.file}  target="_blank" >Download</a>
                            </div>
                            {!files[item.value]?.name && !filesLink[item.value] && ImagesUploadType.includes(data[item.value]?.file?.substring(data[item.value]?.file.lastIndexOf(".") + 1)?.toLowerCase()) &&
                            <div className='mt-2' ><img src={data[item.value]?.file}  style={{maxWidth:'200px', width:'auto', height:'auto', maxHeight:'100px'}}/> </div> }

                        </div>}
                      </div>
                      )
                    })}
                  </CardBody>}
                </Card>
                </Col>
            </Row>

            <div className='mb-2' >
                      <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={true}		
                               />		
                              <span className="form-check-sign" />		
                              Terms and Conditions agreed by investor		
                            </Label>		
                          </FormGroup>	
                      </div>

           
             	 
           	
            	 	
       		 
      </Container>		
    </Col>
    </Row>
      </div>
    </>		
  );		
};		
export default ViewPotentialInvestor;