import axios from "axios";
import Config from "../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getAllSecuritiesByType = async (email: string, security: string,amc_code:string) => {
  const url = `${Config.baseUrl}/security/by-type?email=${email}&security_type=${security}&amc_code=${amc_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllSecuritiesByType(email, security,amc_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const postToDailyMoneyMarketSecurities = async (
  email: string,
  date: any,
  security: any
) => {
  const url = `${Config.baseUrl}/dailymoneymarketsecurities/update`;
  const token = sessionStorage.getItem("token");
  await Promise.all(
    security.map(async (item, index) => {
      let code = item.code;
      let market_rate_per_unit = item.price;
      let created_at = item.created_at;
      let last_updated = date;
      let doc_type = item.doc_type;
      let face_value = item.face_value;
      let issue_date = item.issue_date;
      let maturity_date = item.maturity_date;
      let name = item.name;
      let price = item.price;
      let security_type = item.security_type;
      try {
        const result: any = await axios.post(
          url,
          {
            email,
            code,
            market_rate_per_unit,
            created_at,
            last_updated,
            doc_type,
            face_value,
            issue_date,
            maturity_date,
            name,
            price,
            security_type,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        return result;
      } catch (err: any) {
        if (err.response.data.status == 401) {
          let responseToHandle = err.response.data;
          let result = await RefreshTokenHandler.handleIt(responseToHandle);
          if (result.status) {
            return await postToDailyMoneyMarketSecurities(email, date, security);
          } else {
            throw err;
            // return result
          }
        } else {
          throw err;
        }
      }
    })
  );
};

const bulkUploadSecurities = async (email: string, data: any) => {
  const token = sessionStorage.getItem("token");
  const url = `${Config.baseUrl}/dailymoneymarketsecurities/bulk-upload`;

  try {
    const result: any = await axios.post(
      url,
      { email, data },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await bulkUploadSecurities(email, data);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getSecuritiesByDate = async (email: string, date: string) => {
  const url = `${Config.baseUrl}/dailymoneymarketsecurities/by-created-date?email=${email}&created_at=${date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getSecuritiesByDate(email, date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {
  getAllSecuritiesByType,
  postToDailyMoneyMarketSecurities,
  bulkUploadSecurities,
  getSecuritiesByDate,
};
