
import React, { useState, useRef }  from 'react';
// import Header from './../../components/Header';	
import { getAllBanks, getCities } from '../../../stores/services/bank.service';	
import { getBranchByBankName } from '../../../stores/services/branch.service';	
import { getNatureOfTx } from '../../../stores/services/transactions.service';	
import { addUnitHolder } from '../../../stores/services/unit-holder.service';	
import ReactTooltip from 'react-tooltip';	
import { getFundByAmc } from '../../../stores/services/funds.service';	
import { BsPlusCircleFill } from 'react-icons/bs';	
import { Modal } from 'react-bootstrap';	
import { IoIosClose } from 'react-icons/io';	
import { Container, Row, Col, FormGroup, Label, Input, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';	
import moment from 'moment';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';	
import ToggleButton from 'react-toggle-button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import {accountTitle_AccountNumber_Regex, numberWithCommas} from '../../../utils/customFunction';
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router-dom';
import { getSalesCompanyByAMCCode } from '../../../stores/services/sales.service';
import { CITIES, COUNTRIES, E_WALLET_PROVIDERS, FilesByInvestorType, GENDER, MARITAL_STATUS, OCCUPATION, PROVINCES, RELATION, SALUTATIONS } from './potential-custom-functions';
import { addInvestor } from '../../../stores/services/potential-investor.service';
import Select from 'react-select';
import { RiArrowGoForwardFill } from "react-icons/ri";


const SetupPotentialInvestor = () => {	
  //hooks for getting all inputs from user	

  const history = useHistory();	
  const [amcName, setAmcName] = useState('');	
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const [name, setName] = useState('');	
  const [folioNo, setFolioNo] = useState('');	
  const [cnic, setCnic] = useState('');	
  const [cnicExpiry, setCnicExpiry] = useState('');	
  const [passport, setPassport] = useState('');	
  const [passportExpiry, setPassportExpiry] = useState('');	
  const [ntn, setNtn] = useState('');	
  const [mainFolioNo, setMainFolioNo] = useState('');	
  const [taxStatus, setTaxStatus] = useState('');
  //	
  const [clientEmail, setClientEmail] = useState('');	
  const [mobile, setMobile] = useState('');	
  const [phone, setPhone] = useState('');	
  const [address, setAddress] = useState('');	
  const [city, setCity] = useState({label:"", value:""});
  // const [filer, setFiler] = useState(false)	;
  // const updateFiler = () => setFiler(!filer)
  const [province, setProvince] = useState('');
  //	
  const [accTitle, setAccTitle] = useState('');	
  const [accNo, setAccNo] = useState('');	
  const [bankname, setBankName] = useState('');	
  const [branchName, setBranchName] = useState('');	
  //	
  const [blnUpdate, setBlnUpdate] = useState<any>();	
  const [reinvestCheck, setReinvestCheck] = useState(false);	
  const email = sessionStorage.getItem('email') || '';	
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [fundLoading, setFundLoading] = useState<boolean>(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  const folioRegex = new RegExp('^[A-Z]{1,5}[A-Z]{1,5}-[0-9]{1,8}$');	
  // guardian
  const [guardian_name, setGuardian_name] = useState('');	
  const [guardian_relation, setGuardian_relation] = useState('');	
  const [guardian_cnic, setGuardian_cnic] = useState('');	
  // nominee
  const [nominee_name, setNomineeName] = useState('');	
  const [nominee_relation, setNomineeRelation] = useState('');	
  const [nominee_cnic, setNomineeCnic] = useState('');	
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState('');	
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState('');	
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState('');	
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState('');
  const [joint_h_1_ntn, setJointHolderOneNTN] = useState('');
  const [joint_h_1_address_bill, setJointHolderOneAddressBill] = useState('');
  const [joint_h_1_address_bill_type, setJointHolderOneAddressBillType] = useState('');

  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState('');	
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState('');	
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState('');	
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState('');
  const [joint_h_2_ntn, setJointHolderTwoNTN] = useState('');
  const [joint_h_2_address_bill, setJointHolderTwoAddressBill] = useState('');
  const [joint_h_2_address_bill_type, setJointHolderTwoAddressBillType] = useState('');

  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState('');	
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState('');	
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState('');	
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState('');
  const [joint_h_3_ntn, setJointHolderThreeNTN] = useState('');
  const [joint_h_3_address_bill, setJointHolderThreeAddressBill] = useState('');
  const [joint_h_3_address_bill_type, setJointHolderThreeAddressBillType] = useState('');

  const [portfolioManagement, setPortfolioManagement] = useState('');	

  const [cdc_IAS, setCDCIAS] = useState('');
  const [cdc_subAccount, setCDCSubAccount] = useState('');
  const [cdc_participantID, setCDCParticipantID] = useState('');

  const [authorized_person,setAuthorizedPerson] = useState('');
  const [filer,setFiler]=useState(false);
  const [registered_business_address,setRegisteredBusinessAddress]=useState('');
  const [partnership_registered,setPartnershipRegistered]=useState(false);

  const [allSalesCompany,setAllSalesCompany] = useState<any>([]);

  const [agreedTerms,setAgreedTerms] = useState(false)
  const [accountType,setAccountType] = useState("")
  const [jointOneRelation,setJointOneRelation] = useState("")
  const [jointTwoRelation,setJointTwoRelation] = useState("")
  const [jointThreeRelation,setJointThreeRelation] = useState("")
  const [cnicLifeTime,setCNICLifetime] = useState(false)
  const [guardianCNICLifeTime,setGuadianCNICLifeTime] = useState(false)
  const [nomineeCNICLifeTime,setNomineeCNICLifeTime] = useState(false)
  const [jointOneCNICLifeTime,setJointOneCNICLifeTime] = useState(false)
  const [jointTwoCNICLifeTime,setJointTwoCNICLifeTime] = useState(false)
  const [jointThreeCNICLifeTime,setJointThreeCNICLifeTime] = useState(false)
  const [guardianCNICExpiry,setGuardianCNICExpiry] = useState("")
  const [nomineeCNICExpiry,setNomineeCNICExpiry] = useState("")
  const [jointOneCNICExpiry,setJointOneCNICExpiry] = useState("")
  const [jointTwoCNICExpiry,setJointTwoCNICExpiry] = useState("")
  const [jointThreeCNICExpiry,setJointThreeCNICExpiry] = useState("")
  const [country,setCountry] = useState({value:"", label:""})
  const [occupation,setOccupation] = useState("")
  const [guardianOccupation,setGuardianOccupation] = useState("")
  const [nomineeOccupation,setNomineeOccupation] = useState("")
  const [jointOneOccupation,setJointOneOccupation] = useState("")
  const [jointTwoOccupation,setJointTwoOccupation] = useState("")
  const [jointThreeOccupation,setJointThreeOccupation] = useState("")
  const [annualIncome,setAnnualIncome] = useState("")
  const [guardianAnnualIncome,setGuardianAnnualIncome] = useState("")
  const [nomineeAnnualIncome,setNomineeAnnualIncome] = useState("")
  const [jointOneAnnualIncome,setJointOneAnnualIncome] = useState("")
  const [jointTwoAnnualIncome,setJointTwoAnnualIncome] = useState("")
  const [jointThreeAnnualIncome,setJointThreeAnnualIncome] = useState("")
  const [motherMaidenName,setMotherMaidenname] = useState("")
  const [guardianMotherMaidenName,setGuardianMotherMaidenname] = useState("")
  const [nomineeMotherMaidenName,setNomineeMotherMaidenname] = useState("")
  const [jointOneMotherMaidenName,setJointOneMotherMaidenname] = useState("")
  const [jointTwoMotherMaidenName,setJointTwoMotherMaidenname] = useState("")
  const [jointThreeMotherMaidenName,setJointThreeMotherMaidenname] = useState("")
  const [placeOfBith,setPlaceOfBirth] = useState("")
  const [guardianPlaceOfBith,setGuardianPlaceOfBirth] = useState("")
  const [nomineePlaceOfBith,setNomineePlaceOfBirth] = useState("")
  const [jointOnePlaceOfBith,setJointOnePlaceOfBirth] = useState("")
  const [jointTwoPlaceOfBith,setJointTwoPlaceOfBirth] = useState("")
  const [jointThreePlaceOfBith,setJointThreePlaceOfBirth] = useState("")
  const [gender,setGender] =useState("")
  const [guardianGender,setGuardianGender] =useState("")
  const [nomineeGender,setNomineeGender] =useState("")
  const [jointOneGender,setJointOneGender] =useState("")
  const [jointTwoGender,setJointTwoGender] =useState("")
  const [jointThreeGender,setJointThreeGender] =useState("")
  const [maritalStatus,setMaritalStatus] = useState("")
  const [guardianMaritalStatus,setGuardianMaritalStatus] = useState("")
  const [nomineeMaritalStatus,setNomineeMaritalStatus] = useState("")
  const [jointOneMaritalStatus,setJointOneMaritalStatus] = useState("")
  const [jointTwoMaritalStatus,setJointTwoMaritalStatus] = useState("")
  const [jointThreeMaritalStatus,setJointThreeMaritalStatus] = useState("")


  const [eWalletProvider,setEWalletProvider] = useState("")
  const [eWalletProviderNumber,setEWalletProviderNumber] = useState("")

  const [openCloseCards,setOpenCloseCards] = useState({
    registration:true,
    personal_details:false,
    contact:false,
    bank_account_details:false,
    guardian_details:false,
    nominee_details:false,
    joint_one:false,
    joint_two:false,
    joint_three:false,
    cdc:false,
    sales_details:false,
    documents:false,

  })


  const getAllSalesCompany = async (code: string) => {	
   
    try {	
      const response = await getSalesCompanyByAMCCode(email, code);	
      if(response.data.status == 200)
        {
          setAllSalesCompany(response.data.data)
        }else{
          setAllSalesCompany([])
        }
     
    } catch (error) {
      setAllSalesCompany([])
    }	
  };	

  const getfundByAmcCode = async (code: string) => {	
    // allFunds.length = 0;	
    // setAllFunds(allFunds);	
    //get funds by amc for dropdown	
    try {	
      const response = await getFundByAmc(email, code,fund_code?.length > 0 ? fund_code:"");	
      setAllFunds(response.data.data);	
      if (response.data.data.length > 0) {	
        setFundLoading(false);	
      } else {	
        setFundLoading(true);	
      }	
    } catch (error) {}	
  };	
  React.useEffect(()=>{
    getfundByAmcCode(amcCode);
    getAllSalesCompany(amcCode)
  },[])
  //error getting hooks	
  const [accTitleError, setAccTitleError] = useState('');	
  const [accNoError, setAccNoError] = useState('');	
  const [bankNameError, setBankNameError] = useState('');	
  const [branchNameError, setBranchNameError] = useState('');	
  
  const [folioNoError, setFolioNoError] = useState('');	
  const [phoneError, setPhoneError] = useState('');	
  const [clientEmailError, setClientEmailError] = useState('');	
  const [cnicExpiryError, setCnicExpiryError] = useState('');	
  const [cnicError, setCnicError] = useState('');	
  const [passportExpiryError, setPassportExpiryError] = useState('');	
  const [passportError, setPassportError] = useState('');	
  const [ntnError, setNtnError] = useState('');	
  const [mobileError, setMobileError] = useState('');	
  const [addressError, setAddressError] = useState('');	
  const [cityError, setCityError] = useState('');	
  const [provinceError, setProvinceError] = useState('');	
  const [nameError, setNameError] = useState('');	
  const [Loading, setLoading] = useState(false);	
  const [fatherError,setFatherError] = useState("")
  const [occupationError,setOccupationError] = useState("")
  const [annualIncomeError,setAnnualIncomeError] = useState("")
  const [genderError,setGenderError] = useState("")
  //for dropdown data	
  const [amcdata, setAmcdata] = useState<any>([]);	
  const [bankNameData, setBankNameData] = useState<any>([]);	
  const [allbranchesData, setAllBranchesData] = useState<any>([]);	
  const [allFunds, setAllFunds] = useState<any>([]);	
  const [allTxData, setAllTxData] = useState<any>([]);	
  const [branchLoading, setBranchLoading] = useState<boolean>(false);	
  const [allCitiesData, setAllCitiesData] = useState<any>([]);	
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);	
  // const [amcCode, setAmcCode] = useState('');	
  const [nav, setNav] = useState('');	
  const getBranchByName = async (name: string) => {	
    setBranchLoading(true);	
    allbranchesData.length = 0;	
    setAllBranchesData(allbranchesData);	
    //get all branches for selected bank for dropdown	
    try {	
      const response = await getBranchByBankName(email, name,amcCode);	
      setAllBranchesData(response.data.data);	
      if (response.data.data.length > 0) {	
        setBranchCheckFlag(false);	
      } else {	
        setBranchCheckFlag(true);	
      }	
    } catch (error) {}	
    setBranchLoading(false);	
  };	


  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const [father, setFather] = useState('');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(false);

  const [modalView, setModalView] = useState(false);	
  const [salesCompany,setSalesCompany] = useState("")
  const [salesPerson,setSalesPerson] = useState("")
  const [salesInHouse,setSalesInHouse] = useState(false)

  const filesRef = useRef({})
  const [files, setFiles] = useState<any>({})
  const [filesLink, setFilesLink] = useState<any>({})
  const [bankInputData,setBankInputData] = useState("");
  const [provinceInputData,setProvinceInputData] = useState("");
  const [cityInputData,setCityInputData] = useState("");
  React.useEffect(() => {	
    setFolioNo(`${amcCode}-`);
    const fetchAmc = async () => {		
      bankNameData.length = 0;	
      setBankNameData(bankNameData);	
      setBankNameData(bankNameData);	

      //get all banks list for dropdown	
      try {	
        const response = await getAllBanks(email,"","",amc_code);	
        setBankNameData(response.data.data);	
      } catch (error) {}	
      try {	
        const response = await getCities();	
        setAllCitiesData(response.data.cities);	
      } catch (error) {}	
    };	
    fetchAmc();	
  }, []);	

  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {	
    return bankNameData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.bank_code.replace('BANK_','')}>	
          {item.bank_name} ({item.bank_code.replace('BANK_','')})	
        </option>	
      );	
    });	
  };	
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {	
    return allbranchesData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.branch_code}>	
          {item.branch_name} ({item.branch_code})	
        </option>	
      );	
    });	
  };	
  const renderFundsDropdown = () => {	
    return allFunds.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.symbol_code}>	
          {item.fund_name} ({item.symbol_code})	
        </option>	
      );	
    });	
  };	
  const renderCitiesDropdown = () => {	
    return allCitiesData.map((item: any) => {	
      return (	
        <option key={item} value={item}>	
          {item}	
        </option>	
      );	
    });	
  };	
  const validate = () => {	
    let accTitleErr,	
      nameErr,	
      accNoErr,	
      banknameErr,	
      branchNameErr,	
      cnicExpErr,
      fatherErr,
      occupationErr,
      genderErr,
      annualIncomeErr,
      addressErr,	
      phoneErr = '';      	
    let
      cnicErr,	
      ntnErr,	
      mobileErr,	
      passportErr='',
      passportExpiryErr='',
      clientEmailErr = '';
    
    accTitle.trim() === '' ? (accTitleErr = 'Required') : (accTitleErr = '');	
    accNo.trim() === '' ? (accNoErr = 'Required') : (accNoErr = '');	
    (bankname.trim() === '' ||  (bankname == "Other" && bankInputData?.trim() == "")) ? (banknameErr = 'Required') : (banknameErr = '');	
    branchName.trim() === ''	
      ? (branchNameErr = 'Required')	
      : (branchNameErr = '');	
   
    name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');	
    father?.split("–")?.[1] == "" || father.trim() === ''  ? (fatherErr = 'Required') : (fatherErr = '');	
    occupation.trim() === '' ? (occupationErr = 'Required') : (occupationErr = '');	
    gender.trim() === '' ? (genderErr = 'Required') : (genderErr = '');	
    annualIncome.trim() === '' ? (annualIncomeErr = 'Required') : (annualIncomeErr = '');	
    address.trim() === '' ? (addressErr = 'Required') : (addressErr = '');	

    (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')	
      ? (cnicErr = 'Required')	
      :  (cnicErr = '');
      (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')	
      ? (ntnErr = 'Required')	
      :  (ntnErr = '');
      (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')	
      ? (passportErr = 'Required')	
      :  (passportErr = '');
      ((cnic.trim() && cnicExpiry.trim() === '' && !cnicLifeTime) || (!passport.trim() && !ntn?.trim() && !cnicExpiry.trim() && !cnicLifeTime))	
      ? (cnicExpErr = 'Required')	
      :  (cnicExpErr = '');
      ((passport.trim() && passportExpiry.trim() === '') || (!cnic.trim() && !ntn?.trim() && !passportExpiry.trim()))	
      ? (passportExpiryErr = 'Required')	
      :  (passportExpiryErr = '');
      clientEmail.trim() === '' ? (clientEmailErr = 'Required') : (clientEmailErr = '');	
      mobile.trim() === '' ? (mobileErr = 'Required') : (mobileErr = '');	



    if (	
      accTitleErr ||	
      cnicExpErr ||	
      phoneErr ||	
      accNoErr ||	
      nameErr ||	
      branchNameErr ||	
      cnicErr ||	
      ntnErr ||	
      mobileErr ||	
      passportErr ||
      passportExpiryErr ||
      clientEmailErr ||
      fatherErr ||
      occupationErr ||
      genderErr ||
      annualIncomeErr ||
      addressErr
    ) {	
      setClientEmailError(clientEmailErr);
      setMobileError(mobileErr)
      setAccTitleError(accTitleErr);	
      setPhoneError(phoneErr);	
      setCnicExpiryError(cnicExpErr);	
      setAccNoError(accNoErr);	
      setBankNameError(banknameErr);	
      setBranchNameError(branchNameErr);	
      setCnicError(cnicErr);	
      setNtnError(ntnErr);	
      setMobileError(mobileErr);		
      setPassportError(passportErr)
      setPassportExpiryError(passportExpiryErr)
      setNameError(nameErr);
      setFatherError(fatherErr);
      setOccupationError(occupationErr);
      setGenderError(genderErr);
      setAnnualIncomeError(annualIncomeErr);
      setAddressError(addressErr);
      
      return false;	
    } else {	
      return true;	
    }	
  };	

  const makeDocumentStructure=(fileName, file="")=>{
    return(
      file && files[fileName]?.name?
      files[fileName]
      :file && !files[fileName]?.name 
      ?
      ""
      : filesLink[fileName] 
            ? 
            {
              file:filesLink[fileName],
              verified:false,
              verified_by:"",
              verified_at_time:0
            }
            : {
              file:"",
              verified:false,
              verified_by:"",
              verified_at_time:0
            }
          )

  }

  const [ type , setType ] = useState('');
  const addInvestorData = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        let data = {
          email,
          amc_code,
          investor_id: cnic?.trim() || ntn?.trim() || passport?.trim(),
          investor_email: clientEmail,
          investor_type: type,
          name,
          cnic,
          cnic_expiry:cnicLifeTime ? "" : cnicExpiry,
          ntn,
          passport_no: passport,
          passport_expiry: passportExpiry,
          mobile,
          phone,
          address,
          city:city?.value == "Other" ? cityInputData : city?.value ? city.value : "",
          province : province == "Other" ? provinceInputData : province ? province : "",
          account_title: accTitle,
          account_no: accNo,
          bank_name: bankname == "Other" ? bankInputData : bankname,
          branch_name: branchName,
          balance_unit: JSON.stringify(totalFunds),
          status: true,
          reinvest: reinvestCheck,
          father_spouse: father,
          tax_exempt: tax_exempt.toString(),
          zakat_exempt: zakat_exempt,
          type,
          guardian_name,
          guardian_relation,
          guardian_cnic,
          nominee_name,
          nominee_relation,
          nominee_cnic,
          joint_h_1_name:type == "Joint Account" ? joint_h_1_name : "",
          joint_h_1_father_spouse:type == "Joint Account" ? joint_h_1_father_spouse : "",
          joint_h_1_cnic:type == "Joint Account" ? joint_h_1_cnic : "",
          joint_h_1_ntn:type == "Joint Account" ? joint_h_1_ntn : "",
          joint_h_1_address_bill:type == "Joint Account" ? joint_h_1_address_bill : "",
          joint_h_1_address_bill_type:type == "Joint Account" ? joint_h_1_address_bill_type : "",
          joint_h1_relation:type == "Joint Account" ? jointOneRelation : "",
          joint_h1_cnic_expiry:type == "Joint Account" || jointOneCNICLifeTime ? "" :jointOneCNICExpiry,
           joint_h1_lifetime_cnic:type == "Joint Account" ? jointOneCNICLifeTime : false,
          joint_h1_occupation:type == "Joint Account" ? jointOneOccupation : "",
          joint_h1_annual_income:type == "Joint Account" ? jointOneAnnualIncome : "",
           joint_h1_mother_maiden_name:type == "Joint Account" ? jointOneMotherMaidenName : "",
          joint_h1_place_of_birth:type == "Joint Account" ? jointOnePlaceOfBith : "",
          joint_h1_gender:type == "Joint Account" ? jointOneGender : "",
           joint_h1_marital_status:type == "Joint Account" ? jointOneMaritalStatus : "",
          joint_h_2_name:type == "Joint Account" ? joint_h_2_name : "",
          joint_h_2_father_spouse:type == "Joint Account" ? joint_h_2_father_spouse : "",
          joint_h_2_cnic:type == "Joint Account" ? joint_h_2_cnic : "",
          joint_h_2_ntn:type == "Joint Account" ? joint_h_2_ntn : "",
          joint_h_2_address_bill:type == "Joint Account" ? joint_h_2_address_bill : "",
          joint_h_2_address_bill_type:type == "Joint Account" ? joint_h_2_address_bill_type : "",
          joint_h2_relation:type == "Joint Account" ? jointTwoRelation : "",
          joint_h2_cnic_expiry:type == "Joint Account" || jointTwoCNICLifeTime ? "" :jointTwoCNICExpiry,
           joint_h2_lifetime_cnic:type == "Joint Account" ? jointTwoCNICLifeTime : false,
          joint_h2_occupation:type == "Joint Account" ? jointTwoOccupation : "",
          joint_h2_annual_income:type == "Joint Account" ? jointTwoAnnualIncome : "",
          joint_h2_mother_maiden_name:type == "Joint Account" ? jointTwoMotherMaidenName : "",
          joint_h2_place_of_birth:type == "Joint Account" ? jointTwoPlaceOfBith : "",
          joint_h2_gender:type ==  "Joint Account" ? jointTwoGender : "",
          joint_h2_marital_status:type == "Joint Account" ? jointTwoMaritalStatus : "",
          joint_h_3_name:type == "Joint Account" ? joint_h_3_name : "",
          joint_h_3_father_spouse:type == "Joint Account" ? joint_h_3_father_spouse : "",
          joint_h_3_cnic:type == "Joint Account" ? joint_h_3_cnic : "",
          joint_h_3_ntn:type == "Joint Account" ? joint_h_3_ntn : "",
          joint_h_3_address_bill:type == "Joint Account" ? joint_h_3_address_bill : "",
          joint_h_3_address_bill_type:type == "Joint Account" ? joint_h_3_address_bill_type : "",
          joint_h3_relation:type == "Joint Account" ? jointThreeRelation : "",
          joint_h3_cnic_expiry:type == "Joint Account" || jointThreeCNICLifeTime ? "": jointThreeCNICExpiry,
          joint_h3_lifetime_cnic:type == "Joint Account" ? jointThreeCNICLifeTime : false,
          joint_h3_occupation:type == "Joint Account" ? jointThreeOccupation : "",
          joint_h3_annual_income:type == "Joint Account" ? jointThreeAnnualIncome : "",
          joint_h3_mother_maiden_name:type == "Joint Account" ? jointThreeMotherMaidenName : "",
          joint_h3_place_of_birth:type == "Joint Account" ? jointThreePlaceOfBith : "",
          joint_h3_gender:type == "Joint Account" ? jointThreeGender : "",
          joint_h3_marital_status:type == "Joint Account" ? jointThreeMaritalStatus : "",
          portfolio_management: portfolioManagement,
          filer,
          joint_h1_tax_status: type == "Joint Account" ? joint_h_1_taxStatus : "",
          joint_h2_tax_status: type == "Joint Account" ? joint_h_2_taxStatus : "",
          joint_h3_tax_status: type == "Joint Account" ? joint_h_3_taxStatus : "",
          cdc_ias: cdc_IAS,
          cdc_sub_account: cdc_subAccount,
          cdc_participant_id: cdc_participantID,
          tax_status: taxStatus,
          sales_company: salesCompany,
          sales_person: salesPerson,
          in_house_sale: salesInHouse,
          registered_business_address,
          partnership_registered,
          authorized_person,
          verified: "",
          folio_no: "",
          country : country?.value ? country?.value : "",
          application_status:"DRAFT",
          account_type:accountType,
          nominee_cnic_expiry:nomineeCNICLifeTime ? "" :nomineeCNICExpiry,
          guardian_cnic_expiry:guardianCNICLifeTime ? "" :guardianCNICExpiry,
          lifetime_cnic:cnicLifeTime,
          nominee_lifetime_cnic:nomineeCNICLifeTime,
          guardian_lifetime_cnic:guardianCNICLifeTime,
          occupation:occupation,
          guardian_occupation:guardianOccupation,
          nominee_occupation:nomineeOccupation,
          annual_income:annualIncome,
          guardian_annual_income:guardianAnnualIncome,
          nominee_annual_income:nomineeAnnualIncome,
          mother_maiden_name:motherMaidenName,
          guardian_mother_maiden_name:guardianMotherMaidenName,
          nominee_mother_maiden_name:nomineeMotherMaidenName,
          place_of_birth:placeOfBith,
          guardian_place_of_birth:guardianPlaceOfBith,
          nominee_place_of_birth:nomineePlaceOfBith,
          gender:gender,
          nominee_gender:nomineeGender,
          guardian_gender:guardianGender,
          marital_status:maritalStatus,
          guardian_marital_status:guardianMaritalStatus,
          nominee_marital_status:nomineeMaritalStatus,
          e_wallet_provider:eWalletProvider,
          e_wallet_provider_number:eWalletProviderNumber,
          zakat_exempt_document: makeDocumentStructure("zakat_exempt_document"),
          joint_h_1_cnic_copy: makeDocumentStructure("joint_h_1_cnic_copy"),
          joint_h_1_cnic_back_copy: makeDocumentStructure("joint_h_1_cnic_back_copy"),
          joint_h_1_ntn_document: makeDocumentStructure("joint_h_1_ntn_document"),
          joint_h_1_passport_copy: makeDocumentStructure("joint_h_1_passport_copy"),
          joint_h_1_address_bill_copy: makeDocumentStructure("joint_h_1_address_bill_copy"),
          joint_h_1_pay_slip: makeDocumentStructure("joint_h_1_pay_slip"),
          joint_h_1_employment_letter:makeDocumentStructure("joint_h_1_employment_letter"),
          joint_h_2_cnic_copy: makeDocumentStructure("joint_h_2_cnic_copy"),
          joint_h_2_cnic_back_copy: makeDocumentStructure("joint_h_2_cnic_back_copy"),
          joint_h_2_ntn_document: makeDocumentStructure("joint_h_2_ntn_document"),
          joint_h_2_passport_copy:makeDocumentStructure("joint_h_2_passport_copy"),
          joint_h_2_address_bill_copy: makeDocumentStructure("joint_h_2_address_bill_copy"),
          joint_h_2_pay_slip: makeDocumentStructure("joint_h_2_pay_slip"),
          joint_h_2_employment_letter: makeDocumentStructure("joint_h_2_employment_letter"),
          joint_h_3_cnic_copy: makeDocumentStructure("joint_h_3_cnic_copy"),
          joint_h_3_cnic_back_copy: makeDocumentStructure("joint_h_3_cnic_back_copy"),
          joint_h_3_ntn_document: makeDocumentStructure("joint_h_3_ntn_document"),
          joint_h_3_passport_copy: makeDocumentStructure("joint_h_3_passport_copy"),
          joint_h_3_address_bill_copy: makeDocumentStructure("joint_h_3_address_bill_copy"),
          joint_h_3_pay_slip: makeDocumentStructure("joint_h_3_pay_slip"),
          joint_h_3_employment_letter: makeDocumentStructure("joint_h_3_employment_letter"),
          cnic_copy: makeDocumentStructure("cnic_copy"),
          cnic_back_copy: makeDocumentStructure("cnic_back_copy"),
          ntn_verification: makeDocumentStructure("ntn_verification"),
          passport_copy: makeDocumentStructure("passport_copy"),
          address_bill: makeDocumentStructure("address_bill"),
            pay_slip: makeDocumentStructure("pay_slip"),
            employment_letter: makeDocumentStructure("employment_letter"),
            registration_certificate: makeDocumentStructure("registration_certificate"),
            account_opening_request: makeDocumentStructure("account_opening_request"),
            registered_business_address_evidence: makeDocumentStructure("registered_business_address_evidence"),
            all_partners_documents:makeDocumentStructure("all_partners_documents"),
            partnership_deed: makeDocumentStructure("partnership_deed"),
            partnership_registeration_document: makeDocumentStructure("partnership_registeration_document"),
            partnership_authority_letter: makeDocumentStructure("partnership_authority_letter"),
            certified_llp_agreement: makeDocumentStructure("certified_llp_agreement"),
            llp_form_5: makeDocumentStructure("llp_form_5"),
            llp_form_3: makeDocumentStructure("llp_form_3"),
            llp_authority_letter: makeDocumentStructure("llp_authority_letter"),
            board_resolution_copy: makeDocumentStructure("board_resolution_copy"),
            memorendum_articles: makeDocumentStructure("memorendum_articles"),
            certified_form_a: makeDocumentStructure("certified_form_a"),
            certified_form_b: makeDocumentStructure("certified_form_b"),
            certified_form_2: makeDocumentStructure("certified_form_2"),
            certified_form_c: makeDocumentStructure("certified_form_c"),
            certified_form_29:makeDocumentStructure("certified_form_29"),
            all_director_documents: makeDocumentStructure("all_director_documents"),
            all_beneficial_documents: makeDocumentStructure("all_beneficial_documents"),
            board_of_investment_letter: makeDocumentStructure("board_of_investment_letter"),
            signatories_passport_documents: makeDocumentStructure("signatories_passport_documents"),
            list_of_directors: makeDocumentStructure("list_of_directors"),
            certified_form_3: makeDocumentStructure("certified_form_3"),
            authorization_letter: makeDocumentStructure("authorization_letter"),
            certificate_of_registration:makeDocumentStructure("certificate_of_registration"),
            by_laws_regulations: makeDocumentStructure("by_laws_regulations"),
            resolutions: makeDocumentStructure("resolutions"),
            annual_accounts_documents: makeDocumentStructure("annual_accounts_documents"),
            power_of_attorney: makeDocumentStructure("power_of_attorney"),
            letter_administration_probate: makeDocumentStructure("letter_administration_probate"),
            guardian_documents: makeDocumentStructure("guardian_documents"),
            undertaking: makeDocumentStructure("undertaking"),
            signature_specimen: makeDocumentStructure("signature_specimen"),
            email_verification:{otp: "",
              verified: false,
              verified_at: "",
              verified_at_time: 0,
              verified_by: ""},
              mobile_verification:{otp: "",
              verified: false,
              verified_at: "",
              verified_at_time: 0,
              verified_by: ""},
            zakat_exempt_document_file: makeDocumentStructure("zakat_exempt_document","file"),
          joint_h_1_cnic_file: makeDocumentStructure("joint_h_1_cnic_copy","file"),
          joint_h_1_cnic_back_copy_file: makeDocumentStructure("joint_h_1_cnic_back_copy","file"),
          joint_h_1_ntn_document_file: makeDocumentStructure("joint_h_1_ntn_document","file"),
          joint_h_1_passport_copy_file: makeDocumentStructure("joint_h_1_passport_copy","file"),
          joint_h_1_address_bill_copy_file: makeDocumentStructure("joint_h_1_address_bill_copy","file"),
          joint_h_1_pay_slip_file: makeDocumentStructure("joint_h_1_pay_slip","file"),
          joint_h_1_employment_letter_file:makeDocumentStructure("joint_h_1_employment_letter","file"),
          joint_h_2_cnic_file: makeDocumentStructure("joint_h_2_cnic_copy","file"),
          joint_h_2_cnic_back_copy_file: makeDocumentStructure("joint_h_2_cnic_back_copy","file"),
          joint_h_2_ntn_document_file: makeDocumentStructure("joint_h_2_ntn_document","file"),
          joint_h_2_passport_copy_file:makeDocumentStructure("joint_h_2_passport_copy","file"),
          joint_h_2_address_bill_copy_file: makeDocumentStructure("joint_h_2_address_bill_copy","file"),
          joint_h_2_pay_slip_file: makeDocumentStructure("joint_h_2_pay_slip","file"),
          joint_h_2_employment_letter_file: makeDocumentStructure("joint_h_2_employment_letter","file"),
          joint_h_3_cnic_file: makeDocumentStructure("joint_h_3_cnic_copy","file"),
          joint_h_3_cnic_back_copy_file: makeDocumentStructure("joint_h_3_cnic_back_copy","file"),
          joint_h_3_ntn_document_file: makeDocumentStructure("joint_h_3_ntn_document","file"),
          joint_h_3_passport_copy_file: makeDocumentStructure("joint_h_3_passport_copy","file"),
          joint_h_3_address_bill_copy_file: makeDocumentStructure("joint_h_3_address_bill_copy","file"),
          joint_h_3_pay_slip_file: makeDocumentStructure("joint_h_3_pay_slip","file"),
          joint_h_3_employment_letter_file: makeDocumentStructure("joint_h_3_employment_letter","file"),
          cnic_copy_file: makeDocumentStructure("cnic_front_copy","file"),
          cnic_back_copy_file: makeDocumentStructure("cnic_back_copy","file"),
          ntn_verification_file: makeDocumentStructure("ntn_verification","file"),
          passport_copy_file: makeDocumentStructure("passport_copy","file"),
          address_bill_file: makeDocumentStructure("address_bill","file"),
            pay_slip_file: makeDocumentStructure("pay_slip","file"),
            employment_letter_file: makeDocumentStructure("employment_letter","file"),
            registration_certificate_file: makeDocumentStructure("registration_certificate","file"),
            account_opening_request_file: makeDocumentStructure("account_opening_request","file"),
            registered_business_address_evidence_file: makeDocumentStructure("registered_business_address_evidence","file"),
            all_partners_documents_file:makeDocumentStructure("all_partners_documents","file"),
            partnership_deed_file: makeDocumentStructure("partnership_deed","file"),
            partnership_registeration_document_file: makeDocumentStructure("partnership_registeration_document","file"),
            partnership_authority_letter_file: makeDocumentStructure("partnership_authority_letter","file"),
            certified_llp_agreement_file: makeDocumentStructure("certified_llp_agreement","file"),
            llp_form_5_file: makeDocumentStructure("llp_form_5","file"),
            llp_form_3_file: makeDocumentStructure("llp_form_3","file"),
            llp_authority_letter_file: makeDocumentStructure("llp_authority_letter","file"),
            board_resolution_copy_file: makeDocumentStructure("board_resolution_copy","file"),
            memorendum_articles_file: makeDocumentStructure("memorendum_articles","file"),
            certified_form_a_file: makeDocumentStructure("certified_form_a","file"),
            certified_form_b_file: makeDocumentStructure("certified_form_b","file"),
            certified_form_2_file: makeDocumentStructure("certified_form_2","file"),
            certified_form_c_file: makeDocumentStructure("certified_form_c","file"),
            certified_form_29_file:makeDocumentStructure("certified_form_29","file"),
            all_director_documents_file: makeDocumentStructure("all_director_documents","file"),
            all_beneficial_documents_file: makeDocumentStructure("all_beneficial_documents","file"),
            board_of_investment_letter_file: makeDocumentStructure("board_of_investment_letter","file"),
            signatories_passport_documents_file: makeDocumentStructure("signatories_passport_documents","file"),
            list_of_directors_file: makeDocumentStructure("list_of_directors","file"),
            certified_form_3_file: makeDocumentStructure("certified_form_3","file"),
            authorization_letter_file: makeDocumentStructure("authorization_letter","file"),
            certificate_of_registration_file:makeDocumentStructure("certificate_of_registration","file"),
            by_laws_regulations_file: makeDocumentStructure("by_laws_regulations","file"),
            resolutions_file: makeDocumentStructure("resolutions","file"),
            annual_accounts_documents_file: makeDocumentStructure("annual_accounts_documents","file"),
            power_of_attorney_file: makeDocumentStructure("power_of_attorney","file"),
            letter_administration_probate_file: makeDocumentStructure("letter_administration_probate","file"),
            guardian_documents_file: makeDocumentStructure("guardian_documents","file"),
            undertaking_file: makeDocumentStructure("undertaking","file"),
            signature_specimen_file: makeDocumentStructure("signature_specimen","file"),
            
             



            

        };
        const response = await addInvestor(data)
        if(response.data.status == 200)
          {
        
        toast.success(response.data.message);
        setTimeout(() => {	
          history.replace(`/admin/potential-investors`);	
        }, 2000);
          }
          else{
            setLoading(false);
            toast.error(response.data.message);
          }
      } catch (error:any) {
        setLoading(false);
        if(error.response)
          {
        toast.error(error.response.data.message?.toString());
          }else{
            toast.error("Request Failed")
          }
      }
      
    }else{
      toast.error("Kindly Fill All Required Fields")
    }
  };
  const [totalFunds, setTotalFunds] = useState<any>([]);	
  const [fundEntity, setFundEntity] = useState<any>({	
    fund_code: '',	
    fund_name: '',	
    nav: '',	
    fund_unit: '',	
  });	
	
  // ADD TAX Entity inside tax Array	
  const addFundInArray = async () => {	
    setDisableAddBtn(true);	
    let count = 0;	
    //push data inside array	
    for (let i in fundEntity) {	
      if (fundEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableAddBtn(false);	
    } else {	
      setModalView(false);	
      const check = totalFunds.filter((item) => {	
        return item.symbol_code === fundEntity['fund_code'];	
      });	
      if (check.length > 0) {	
        totalFunds.map((item, index) => {	
          if (item.symbol_code === fundEntity['fund_code']) {	
            item.fund_unit = (	
              +item.fund_unit + +fundEntity['fund_unit']	
            ).toString();	
            fundEntity['fund_name'] = item.fund_name;	
          }	
        });	
      } else {	
        totalFunds.push(fundEntity);	
        setTotalFunds(totalFunds);	
      }	
      const filedEntirty = {	
        fund_code: '',	
        fund_name: '',	
        nav: '',	
        fund_unit: '',	
      };	
      setFundEntity(filedEntirty);	
      setDisableAddBtn(false);	
    }	
  };	
  //render Fund table data	
  const renderFundDataInTable = () => {	
    return totalFunds.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.fund_code}</td>	
          <td>{items.fund_name}</td>	
          <td>{numberWithCommas(items.nav || "0")}</td>	
          <td>{numberWithCommas(items.fund_unit || "0")}</td>	
          {/* <td>{items.calculatedAmount}</td> */}	
          <td>	
            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...totalFunds];	
                array.splice(index, 1);	
                setTotalFunds(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	
  const changeCodeValue = (value: string) => {	
    setFolioNo(value);	
    if (value.trim() === '') {	
      setFolioNoError('Required');	
    } else {	
      folioRegex.test(value) !== true	
        ? setFolioNoError('Invalid')	
        : setFolioNoError('');	
    }	
  };	
  // render Model for adding Fund		
  const renderModalPopup = () => {		
    switch (modalView) {		
      case true:		
        return (		
          <Modal		
            className=""		
            dialogClassName="modal90w"		
            show={true}		
            size="lg"		
            backdrop={true}		
            onHide={() => {		
              setModalView(false);		
            }}		
          >	 	
            <div className="modal-header">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {		
                  setModalView(false);		
                  // clearTaxobject();		
                }}		
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">Add Fund</h6>	
            </div>	
            <div className="modal-body">		
              <div className="row">		
                <div className="col-sm-12">		
                <div className="form-group">		
                  <label>Fund Selection</label>		
                  <ReactTooltip		
                    textColor="white"		
                    backgroundColor="black"		
                    effect="float"		
                  />		
                  <select		
                    className="form-control"		
                    value={fundEntity['fund_code']}		
                    onChange={(e) => {		
                      let value = allFunds.filter((item: any) => {		
                          return item.symbol_code === e.target.value;		
                      })			
                      setFundEntity({		
                        ...fundEntity,		
                        fund_code: e.target.value,		
                        fund_name: value[0].fund_name,		
                        nav: value[0].nav,
                      });		
                    }}		
                  >		
                    <option value="" defaultChecked hidden>		
                      {fundLoading ? 'No Amc Fund Found' : 'Select Fund'}		
                    </option>		
                    {renderFundsDropdown()}		
                  </select>		
                </div>		
                </div>	
                <div className="col-md-12">
                <div className="form-group">
                  <div>NAV</div>
                  <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"	
                    value={fundEntity['nav']}
                    // value={nav}		
                    onValueChange={(e) => {		 
                      //setNav(e.target.value);
                      setFundEntity({		
                        ...fundEntity,		
                        nav: e.value,		
                      });	
                    }}	
                     
                  />
                </div>
                </div>
                <div className="col-sm-12">		
                  <div className="form-group">		
                    <div>Units</div>		
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalScale={4}
                      className="form-control w-100 text-right"	
                      value={fundEntity['fund_unit']}		
                      onValueChange={(e) => {
                        let value=decimalValue(e.value,4);		
                        setFundEntity({		
                          ...fundEntity,		
                          fund_unit: value,		
                        });		
                      }}		
                    />		
                  </div>		
                </div>		
                <div className="col-sm-12">		
                  <button		
                    className="btn btn-primary btn-block mt-2"		
                    onClick={() => {		
                      addFundInArray();		
                    }}		
                    disabled={Boolean(disableAddBtn)}		
                  >		
                    {disableAddBtn ? (		
                      <>		
                        <span		
                          className="spinner-border login-txt spinner-border-sm"		
                          role="status"		
                          aria-hidden="true"		
                        ></span>		
                        <span className="login-txt"> Loading...</span>		
                      </>		
                    ) : (		
                      <span>Add</span>		
                    )}		
                  </button>		
                </div>		
              </div>		
            </div>		
          </Modal>		
        );		
      default:		
        return '';		
    }		
  };		
  const borderRadiusStyle = { borderRadius: 2 };


  const upload = (e, item) => {
    let file = e?.target.files[0];
    if (file) {
        
       
            let type = file.name.substring(file.name.lastIndexOf(".") + 1)?.toLowerCase();
            if (type === "png" || type === "jpg" || type === "jpeg" || type === "pdf" || type == "zip") {
                //code here
               setFiles({...files, [item]: file})
                
            } else {
                toast.error("Invalid Format");
            }
        
    }
};

const updateSalutationFields = (value, setField, fieldValue,type) => {
  if(type == "salutation")
    {

  if(value)
    {
      setField(value + "–" + (fieldValue?.includes("–")  ? fieldValue.split("–")[1] : fieldValue))


    }else{
      setField(fieldValue?.includes("–")  ? fieldValue.split("–")[1] : fieldValue)
    }
  }else{
  
      setField(fieldValue?.includes("–") ?fieldValue?.split("–")[0] + "–"+value : value)
  }
   

}


  

  return (		
    <>		
     <div className="content">
     <Row>
      <Col md="12">
      <Container fluid>		
        <ToastContainer limit={1} />		
        {/* <Header />		 */}

        <div className="input-holder">		
              <h4 className="card-title"><i className="fa fa-angle-left mr-1 pointer" 
              onClick={() => {
              history.replace('/admin/potential-investors')
          }}></i>Setup - Potential Investor</h4>		
              <Link		
                to="/admin/potential-investors"		
                className="t-3 ml-auto btn btn-primary btn-sm"		
                replace		
              >		
               <i className="fa fa-eye mr-2"></i> View All		
              </Link>		
            </div>	

            <Row>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                      Registration
                      <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,registration:!openCloseCards.registration})}>
                        <i className={"tim-icons " + (openCloseCards.registration ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.registration &&
                   <CardBody>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Investor Type</label>
                        <div>
                          <select
                            className={`form-control  w-100`}
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                              setFilesLink({})
                              setFiles({})
                              setPartnershipRegistered(false)
                              filesRef.current = {}


                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Type
                            </option>
                            <option value="Individuals">Individuals</option>
                            <option value="Joint Account">Joint Account</option>
                            <option value="Sole Proprietorship">Sole Proprietorship</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
                            <option value="Limited Companies / Corporations">Limited Companies / Corporations</option>
                            <option value="Branch Office or Liaison Office of Foreign Companies">Branch Office or Liaison Office of Foreign Companies</option>
                            <option value="Trust, Clubs, Societies & Associations etc">Trust, Clubs, Societies & Associations etc</option>
                            <option value="NGOs/NPOs/Charities">NGOs/NPOs/Charities</option>
                            <option value="Agents">Agents</option>
                            <option value="Executors & Administrators">Executors & Administrators</option>
                            <option value="Minor Accounts">Minor Accounts</option>

                          </select>
                       </div>
                    </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Account Type</label>
                         <div>
                          <select
                            className={`form-control  w-100`}
                            value={accountType}
                            onChange={(e) => {
                              setAccountType(e.target.value);
                            }}
                            
                          >
                            <option value="" defaultChecked hidden>
                            Select Account Type
                            </option>
                            <option value="Normal">Normal</option>
                            <option value="Sahulat">Sahulat</option>
                          </select>
                      </div>
                </div>

                    </Col>
                  </Row>

      
                  <Row>
                    <Col md="6">
                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <select
                     className="form-control"
                     value={taxStatus}
                     onChange={(e)=>{
                       setTaxStatus(e.target.value)
                     }}>
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                       <option value="Exempted">Exempted</option>
                     </select>
                 </div>
                 </Col>
                 <Col md="6">
                      <div className="form-group">
                        <label>Portfolio Management</label>
                         <div>
                          <select
                            className={`form-control  w-100`}
                            value={portfolioManagement}
                            onChange={(e) => {
                              setPortfolioManagement(e.target.value);
                            }}
                            
                          >
                            <option value="" defaultChecked hidden>
                              Fund
                            </option>
                            <option value="fund">Fund</option>
                            <option value="sma">SMA</option>
                          </select>
                      </div>
                </div>

                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between allign-flex"  >
                        
                        <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={zakat_exempt}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (zakat_exempt) {
                                  setZakat_exempt(false);
                                } else {
                                  setZakat_exempt(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Zakat Exempt</label> 
                      </div> 
                      <div className="mt-3">
                      <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={reinvestCheck}		
                              onChange={(e) => {		
                                setReinvestCheck(!reinvestCheck);		
                              }} />		
                              <span className="form-check-sign" />		
                              Fund Dividend Reinvest		
                            </Label>		
                          </FormGroup>	
                      </div>

                     
                  </div>
                  <div className="d-flex justify-content-between allign-flex"  >

                  
                  <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={filer}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (filer) {
                                  setFiler(false);
                                } else {
                                  setFiler(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Filer</label> 
                      </div> 

                      {type == "Partnership" &&
                       <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={partnership_registered}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (partnership_registered) {
                                  setPartnershipRegistered(false);
                                } else {
                                  setPartnershipRegistered(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Partnership Registered</label> 
                      </div> }

                      </div>
 
                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                     <CardTitle className='d-flex justify-content-between' tag="h4">
                     Personal Details

                     <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,personal_details:!openCloseCards.personal_details})}>
                        <i className={"tim-icons " + (openCloseCards.personal_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.personal_details &&
                  <CardBody>
                    <Row>
                    <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                      
                      className={`form-control  w-100 `}		
                      value={name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setName, name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                         	
                         Select Salutation	
                         </option>		
                         {SALUTATIONS.map(item=>
                         <option value={item} key={item}>		
                         {item}
                         </option>		
                        )}
                               </select>	
                     
                    </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Client Name <span className="color-amber">*</span> </label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={name.includes("–") ?name?.split("–")[1] : name}		
                        onChange={(e) => {		
                          // setName(name?.includes("–") ?name?.split("–")[0] + "–"+e.target.value : e.target.value);	
                          updateSalutationFields(e.target.value, setName, name,"value")	
                          setNameError('');		
                        }}		
                      />		
                    </div>		
                  </div> 
                  </Col>
                  </Row>  

                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                               className={`form-control  w-100 `}		
                               value={father.split("–")[0]}		
                               onChange={(e) => {
                                updateSalutationFields(e.target.value, setFather, father,"salutation")
                                
                               }}	
                             >
                                 <option value="" defaultChecked hidden>		
                         	
                         Select Salutation	
                         </option>		
                         {SALUTATIONS.map(item=>
                         <option value={item} key={item}>		
                         {item}
                         </option>		
                        )}
                               </select>	
                     
                    </div>		
                       </div> 

                      </Col>

                      <Col>
                 
                      <div className="form-group ">
                        <label>Father/Spouse Name <span className="color-amber">*</span></label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${		
                              fatherError ? 'required-border' : ''		
                            }`}
                            value={father.includes("–") ?father?.split("–")[1] : father}
                            onChange={(e) => {
                              updateSalutationFields(e.target.value, setFather, father,"value")
                              setFatherError("")
                            }}
                          />
                        </div>
                      </div>
                      </Col>

                    </Row>

                    <Row>
                    <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={motherMaidenName.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setMotherMaidenname, motherMaidenName,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>

                      <Col>
                      <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={motherMaidenName.includes("–") ?motherMaidenName?.split("–")[1] : motherMaidenName}
                            onChange={(e) => {
                              updateSalutationFields(e.target.value, setMotherMaidenname, motherMaidenName,"value")
                            }}
                          />
                        </div>
                      </div>
                      </Col>
                      </Row>

                      <div className="form-group ">
                        <label>Authroized Person</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={authorized_person}
                            onChange={(e) => {
                              setAuthorizedPerson(e.target.value);
                            }}
                          />
                        </div>
                      </div>  

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Gender <span className="color-amber">*</span></label>		
                            <div  >		
                              <select		
                               
                               className={`form-control  w-100 ${		
                                genderError ? 'required-border' : ''		
                              }`}
                                value={gender}		
                                onChange={(e) => {
                                 setGender(e.target.value)
                                 setGenderError("")
                                }}	
                              >
                                  <option value="" defaultChecked hidden>		
                            {' '}		
                          Select Gender	
                          </option>		
                          {GENDER.map(item=>
                          <option value={item} key={item}>		
                          {item}
                          </option>		)}
                                </select>	

                       
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>Martial Status</label>		
                            <div  >		
                              <select		
                               
                                className={`form-control  w-100 `}		
                                value={maritalStatus}		
                                onChange={(e) => {
                                 setMaritalStatus(e.target.value)
                                }}	
                              >
                                  <option value="" defaultChecked hidden>		
                            {' '}		
                          Select Marital Status
                          </option>		
                          {MARITAL_STATUS.map(item=>
                          <option value={item} key={item}>		
                          {item}
                          </option>		)}
                                </select>	

                       
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Occupation <span className="color-amber">*</span></label>		
                            <div  >		
                              <select		
                               
                               className={`form-control  w-100 ${		
                                occupationError ? 'required-border' : ''		
                              }`}	
                                value={occupation}		
                                onChange={(e) => {
                                 setOccupation(e.target.value)
                                 setOccupationError("")
                                }}	
                              >
                                  <option value="" defaultChecked hidden>		
                            {' '}		
                          Select Occupation
                          </option>		
                          {OCCUPATION.map(item=>
                          <option value={item} key={item}>		
                          {item.slice(item.indexOf(" ")+1)}
                          </option>		)}
                                </select>	

                       
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>Annual Income <span className="color-amber">*</span></label>		
                            <div  >		
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}		
                        className={`form-control  w-100 ${		
                          annualIncomeError ? 'required-border' : ''		
                        }`}
                        value={annualIncome}		
                        onValueChange={(e) => {		
                          setAnnualIncome(e.value);
                          setAnnualIncomeError("")		
                         
                        }}		
                      />			

                       
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">		
                        <label>Place Of Birth</label>		
                        <div  >		
                          <input		
                            type="text"		
                            className={`form-control  w-100 `}		
                            value={placeOfBith}		
                            onChange={(e) => {		
                              setPlaceOfBirth(e.target.value);		
                             
                            }}		
                          />		
                          {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}		
                        </div>		
                      </div>
                  


                    
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={cnicLifeTime}		
                              onChange={(e) => {		
                                setCNICLifetime(!cnicLifeTime);		
                                setCnicExpiryError("")
                                setPassportExpiryError("")
                              }} />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >	
                              <InputMask
                                className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                  }`}
                                mask="99999-9999999-9"
                                value={cnic} 
                                
                                onChange={(e) => {
                                  setCnic(e.target.value.toUpperCase());
                                  setCnicError('');
                                  setPassportError('');
                                  setNtnError("")
                                }}></InputMask>

                              {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}		
                            </div>		
                          </div>
                          
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                readOnly={cnicLifeTime}
                                className={`form-control  w-100 ${		
                                  cnicExpiryError ? ' required-border' : ''		
                                }`}		
                                value={cnicExpiry}		
                                onChange={(e) => {		
                                  setCnicExpiry(e.target.value);		
                                  setCnicExpiryError('');
                                  setPassportExpiryError('');	
                                }}		
                              />		
                              {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Passport</label>		
                            <div  >		
                              <input		
                                type="text"		
                                className={`form-control  w-100 ${		
                                  passportError ? ' required-border' : ''		
                                }`}		
                                value={passport}		
                                onChange={(e) => {
                                  setPassport(e.target.value.toUpperCase());		
                                  setCnicError('');	
                                  setPassportError('');
                                  setNtnError("")	
                                }}	
                              />		

                       
                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>Passport Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 ${		
                                  passportExpiryError ? 'required-border' : ''		
                                }`}		
                                value={passportExpiry}		
                                onChange={(e) => {		
                                  setPassportExpiry(e.target.value);		
                                  setCnicExpiryError('');		
                                  setPassportExpiryError('');
                                }}		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>
                        

                      
                      <div className="form-group  ">		
                        <label>NTN</label>		
                        <div  >		
                          <input		
                            type="number"		
                            className={`form-control  w-100 ${		
                              ntnError ? 'required-border' : ''		
                            }`}		
                            value={ntn}		
                            onChange={(e) => {		
                              setNtn(e.target.value);		
                              setNtnError('');	
                              setPassportError('');
                              setCnicError("")		
                              setPassportExpiryError("")
                              setCnicExpiryError("")
                            }}		
                          />		
                          {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}		
                        </div>		
                      </div>
                     

                 

                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Contact
                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,contact:!openCloseCards.contact})}>
                        <i className={"tim-icons " + (openCloseCards.contact ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.contact &&
                  <CardBody>
                  <div className="form-group  ">		
                    <label>Email <span className="color-amber">*</span></label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          clientEmailError ? 'required-border' : ''		
                        }`}		
                        value={clientEmail}		
                        onChange={(e) => {		
                          setClientEmail(e.target.value);		
                          setClientEmailError('');		
                        }}		
                      />		
                    </div>		
                  </div>

                  <Row>
                    <Col md="12">
                    <div className="form-group  ">		
                    <label>Mobile <span className="color-amber">*</span></label>		
                    <div  >		
                     
                      <PhoneInput
                      specialLabel=""
                       inputClass={`form-control  w-100 ${		
                        mobileError ? 'required-border' : ''		
                      }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={mobile}
                        onChange={(e) => {		
                          setMobile(e);
                          setMobileError('');		
                        }}		
                      />

                      {/* {mobileError ? <p className="error-labels error-message">{mobileError}</p> : ''} */}		
                    </div>		
                  </div>
                    </Col>
                   
                  </Row>
                  <div className="form-group  ">		
                    <label>Phone</label>		
                    <div  >		
                      
                      <PhoneInput
                      specialLabel=""
                      inputClass={`form-control  w-100 ${		
                        phoneError ? 'required-border' : ''		
                        }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={phone}
                        onChange={(e) => {		
                          setPhone(e);		
                          setPhoneError('');		
                        }}		
                      />

                      {/* {phoneError ? <p className="error-labels error-message2">{phoneError}</p> : ''} */}		
                    </div>		
                  </div>
                   
                  <div className="form-group  ">		
                    <label>Address <span className="color-amber">*</span></label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          addressError ? 'required-border' : ''		
                        }`}		
                        value={address}		
                        onChange={(e) => {		
                          setAddress(e.target.value);		
                          setAddressError('');		
                        }}		
                      />		
                      {/* {addressError ? <p className="error-labels error-message2">{addressError}</p> : ''} */}		
                    </div>		
                  </div>
                  <div className="form-group  ">		
                    <label>Registered Business Address</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={registered_business_address}		
                        onChange={(e) => {		
                          setRegisteredBusinessAddress(e.target.value);		
                          
                        }}		
                      />		
                    </div>		
                  </div>
                  <div className="form-group  ">
                    <label>Country</label>
                    <Select
                      className="react-select info "
                      classNamePrefix="react-select"
                      label={'Select'}
                      isMulti={false}

                      onChange={(e) => {
                        //e.preventDefault();

                        setCountry(e?.value ? e : {label:"",value:""});
                        setProvince("")
                        setCity({label:"", value:""})

                      }}
                      value={country?.value ? [country] : []}
                      isClearable={true}
                      isSearchable={true}
                      name="color"
                      options={COUNTRIES.map(item => {
                        return { value: item, label: item.slice(item.indexOf(" ") + 1) }
                      })}
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                    />
                  </div>

                <Row>

                <Col>
                  <div className="form-group">		
                    <label>Province</label>		
                   
                   {province == "Other" &&
                    <div className="d-flex align-items-center ">
                   <input
                     type="text"
                     value={provinceInputData}
                     className={`form-control  w-100 ${provinceError}`}
                     onChange={(e) => {
                       setProvinceInputData(e.target.value);
                       setProvinceError("")
                       setCity({label:"",value:""})
                       
                     }}
                   />
                   <RiArrowGoForwardFill
                     className="btn-primary pointer ml-1"
                     onClick={() => {
                       setProvince("")
                       setProvinceInputData("")
                     }}
                   />
                   </div>} 
                   
                   {province != "Other" && <div>		
                        <select
                            className={`form-control w-100 ${provinceError}`}		
                            value={province}
                            onChange={(e) => {
                              setProvince(e.target.value);
                              setCity({label:"",value:""})
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select
                            </option>
                            { country.value == "PAK Pakistan" ?[ ...PROVINCES , {value:"Other",label : "Other"}].map(item => {
                              return <option value={item.value} key={item.value}>{item.label}</option>
                            }) :
                            [  {value:"Other",label : "Other"}].map(item => {
                              return <option value={item.value} key={item.value}>{item.label}</option>
                            })}
                          </select>
                          </div>		}
                          
                  </div>
                  </Col>
                  <Col>
                  <div className="form-group  ">		
                    <label>City</label>		

                    {city.value == "Other" && 
                    <div className="d-flex align-items-center ">
                   <input
                     type="text"
                     value={cityInputData}
                     className={`form-control  w-100 `}
                     onChange={(e) => {
                       setCityInputData(e.target.value);
                       
                       
                       
                     }}
                   />
                   <RiArrowGoForwardFill
                     className="btn-primary pointer ml-1"
                     onClick={() => {
                      setCity({label:"",value:""})
                       setCityInputData("")
                     }}
                   />
                   </div>} 

                    {city.value != "Other" && <div>		
                    <Select
                    className="react-select info "
                    classNamePrefix="react-select"
                    label={'Select'}
                    isMulti={false}

                    onChange={(e) => {
                      //e.preventDefault();

                      setCityError('');		
                      setCity(e?.value ? e : {label:"",value:""});		

                    }}
                    value={city?.value ? [city] : []}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={[...CITIES,{name:"Other",province : "province"}].filter(item => item.province === province?.split(" ")[0] || item.province == "province").map(item => {
                      return { value: item.name, label: item.name.slice(item.name.indexOf(" ") + 1) }
                    })}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}

                    />
                    
                    </div>}		
                  </div>
                  </Col>

                 
                  </Row>
                  </CardBody>}
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Bank Account Detail
                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,bank_account_details:!openCloseCards.bank_account_details})}>
                        <i className={"tim-icons " + (openCloseCards.bank_account_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.bank_account_details &&
                  <CardBody>
                  <div className="form-group  ">		
                    <label>Account Title <span className="color-amber">*</span></label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accTitleError ? 'required-border' : ''		
                        }`}		
                        value={accTitle}		
                        onChange={(e) => {	
                          if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                          {		
                          setAccTitle(e.target.value);		
                          setAccTitleError('');
                          }		
                        }}		
                      />		
                      {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}		
                    </div>		
                  </div>

                  <div className="form-group  ">		
                    <label>Account No <span className="color-amber">*</span></label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accNoError ? 'required-border' : ''		
                        }`}		
                        value={accNo}		
                        onChange={(e) => {
                          if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                          {			
                          setAccNo(e.target.value);		
                          setAccNoError('');
                          }		
                        }}		
                      />		
                      {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}		
                    </div>		
                  </div>

                  <Row>
                  <Col md="6">
                    <div className="form-group  ">		
                      <label>Bank Name <span className="color-amber">*</span></label>		
                      {bankname == "Other"? 
                       <div className="d-flex align-items-center ">
                        <input
                          type="text"
                          value={bankInputData}
                          className={`form-control  w-100 ${bankNameError}`}
                          onChange={(e) => {
                            setBankInputData(e.target.value);
                            setBankNameError("")
                            
                          }}
                        />
                        <RiArrowGoForwardFill
                          className="btn-primary pointer ml-1"
                          onClick={() => {
                            setBankName("")
                            setBankInputData("")
                          }}
                        />
                      </div> 
                      :
                      <div >		
                        <select		
                          className={`form-control  w-100 ${bankNameError }`}		
                          value={bankname}		
                          onChange={(e) => {		
                            setBankNameError('');		
                            setBankName(e.target.value);		
                            getBranchByName(e.target.value);		
                          }}		
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Bank		
                          </option>		
                          {renderBankNameDropdown()}
                          <option value="Other">Other</option>		
                        </select>		
                      </div>	}	
                    </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group  ">		
                    <label>Branch Name <span className="color-amber">*</span></label>		
                    <input type="text" className={`form-control  w-100 ${		
                            branchNameError ? 'required-border' : ''		
                          }`}		
                          value={branchName}		
                          onChange={(e) => {		
                            setBranchNameError('');		
                            setBranchName(e.target.value);		
                          }}
                    />
                    
                  </div>
                  </Col>
                  </Row>

                  <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>E Wallet Provider</label>		
                            <div  >		
                              <select		
                               
                                className={`form-control  w-100 `}		
                                value={eWalletProvider}		
                                onChange={(e) => {
                                 setEWalletProvider(e.target.value)
                                 setEWalletProviderNumber("")
                                }}	
                              >
                                  <option value="" defaultChecked hidden>		
                            {' '}		
                          Select E Wallet Provider
                          </option>		
                          {E_WALLET_PROVIDERS.map(item=>
                          <option value={item} key={item}>		
                          {item}
                          </option>		)}
                                </select>	

                       
                            </div>		
                          </div>
                        </Col>
                        {eWalletProvider &&
                        <Col md="12" xl="6">
                        <div className="form-group  ">		
                            <label>E Wallet Provider Number</label>		
                            <div  >		
                            <input		
                        type="number"		
                        className={`form-control  w-100`}		
                        value={eWalletProviderNumber}		
                        onChange={(e) => {		
                          setEWalletProviderNumber(e.target.value);		
                         
                        }}		
                      />			

                       
                            </div>		
                          </div>
                        </Col>}
                      </Row>

 
                  </CardBody>}
                </Card>
              </Col> 

              {/*add new cards  */} 
              
              <Col md="4"  >
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Guardian Details
                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,guardian_details:!openCloseCards.guardian_details})}>
                        <i className={"tim-icons " + (openCloseCards.guardian_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.guardian_details &&
                  <CardBody>

                    <Row>
                    <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={guardian_name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setGuardian_name, guardian_name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>

                      <Col>

                  <div className="form-group">		
                    <label>Guardian  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={guardian_name.includes("–") ?guardian_name?.split("–")[1] : guardian_name}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setGuardian_name, guardian_name,"value")	
                          
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <div className="form-group">		
                    <label>Guardian Relation   </label>		
                    <div  >		
                      
                      <select		
                           className={`form-control  w-100`}		
                          value={guardian_relation}		
                        onChange={(e) => {		
                          setGuardian_relation(e.target.value);		
                        		
                        }}			
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Relation		
                          </option>		
                       {RELATION.map((item, index) => {
                        return(
                          <option value={item} key={item}>{item}</option>		
                        )

                       })}
                          
                        </select> 	
                    </div>		
                  </div>

                      <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={guardianMotherMaidenName}
                            onChange={(e) => {
                              setGuardianMotherMaidenname(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={guardianGender}
                                onChange={(e) => {
                                  setGuardianGender(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Gender
                                </option>
                                {GENDER.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={guardianMaritalStatus}
                                onChange={(e) => {
                                  setGuardianMaritalStatus(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Marital Status
                                </option>
                                {MARITAL_STATUS.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={guardianOccupation}
                                onChange={(e) => {
                                  setGuardianOccupation(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Occupation
                                </option>
                                {OCCUPATION.map(item =>
                                  <option value={item} key={item}>
                                    {item.slice(item.indexOf(" ") + 1)}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}	
                                className={`form-control  w-100`}
                                value={guardianAnnualIncome}
                                onValueChange={(e) => {
                                  setGuardianAnnualIncome(e.value);

                                }}
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={guardianPlaceOfBith}
                            onChange={(e) => {
                              setGuardianPlaceOfBirth(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                 
                  <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={guardianCNICLifeTime}		
                              onChange={(e) => {		
                                setGuadianCNICLifeTime(!cnicLifeTime);		
                              }} />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={guardian_cnic}
                        onChange={(e) => {
                          setGuardian_cnic(e.target.value);			
                                }}></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={guardianCNICLifeTime}	
                                value={guardianCNICExpiry}		
                                onChange={(e) => {		
                                  setGuardianCNICExpiry(e.target.value);		
                                
                                }}		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                  </CardBody>}
                </Card>
              </Col>
                
            
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                      Nominee Details
                      <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,nominee_details:!openCloseCards.nominee_details})}>
                        <i className={"tim-icons " + (openCloseCards.nominee_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.nominee_details &&
                    <CardBody>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={nominee_name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setNomineeName, nominee_name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Nominee   Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={nominee_name.includes("–") ?nominee_name?.split("–")[1] : nominee_name}		
                        onChange={(e) => {		
                        updateSalutationFields(e.target.value, setNomineeName, nominee_name,"value")	
                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <div className="form-group">		
                    <label>Nominee  Relation   </label>		
                    <div  >		
                       <select		
                           className={`form-control  w-100`}		
                           value={nominee_relation}		
                           onChange={(e) => {		
                             setNomineeRelation(e.target.value);		
                            
                           }}			
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Relation		
                          </option>		
                       {RELATION.map((item, index) => {
                        return(
                          <option value={item} key={item}>{item}</option>		
                        )

                       })}
                          
                        </select>  	
                    </div>		
                  </div>

                  <div className="form-group ">
                        <label>Mother Maiden Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={nomineeMotherMaidenName}
                            onChange={(e) => {
                              setNomineeMotherMaidenname(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={nomineeGender}
                                onChange={(e) => {
                                  setNomineeGender(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Gender
                                </option>
                                {GENDER.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={nomineeMaritalStatus}
                                onChange={(e) => {
                                  setNomineeMaritalStatus(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Marital Status
                                </option>
                                {MARITAL_STATUS.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={nomineeOccupation}
                                onChange={(e) => {
                                  setNomineeOccupation(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Occupation
                                </option>
                                {OCCUPATION.map(item =>
                                  <option value={item} key={item}>
                                    {item.slice(item.indexOf(" ") + 1)}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}	
                                className={`form-control  w-100`}
                                value={nomineeAnnualIncome}
                                onValueChange={(e) => {
                                  setNomineeAnnualIncome(e.value);

                                }}
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={nomineePlaceOfBith}
                            onChange={(e) => {
                              setNomineePlaceOfBirth(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                  
                  <Row>
                  <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={nomineeCNICLifeTime}		
                              onChange={(e) => {		
                                setNomineeCNICLifeTime(!nomineeCNICLifeTime);		
                              }} />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={nominee_cnic}
                        onChange={(e) => {
                          setNomineeCnic(e.target.value);			
                                }}></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={nomineeCNICLifeTime}	
                                value={nomineeCNICExpiry}		
                                onChange={(e) => {		
                                  setNomineeCNICExpiry(e.target.value);		
                                
                                }}		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>

                  </CardBody>}
                </Card>
              </Col>
              
              
              {type == "Joint Account" &&
               <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 1

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_one:!openCloseCards.joint_one})}>
                        <i className={"tim-icons " + (openCloseCards.joint_one ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.joint_one && 
                 <CardBody>

                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_1_name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderOneName, joint_h_1_name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_name.includes("–") ?joint_h_1_name?.split("–")[1] : joint_h_1_name}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderOneName, joint_h_1_name,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_1_father_spouse.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderOneFather, joint_h_1_father_spouse,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label> Father/Spouse Name </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_father_spouse.includes("–") ?joint_h_1_father_spouse?.split("–")[1] : joint_h_1_father_spouse}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderOneFather, joint_h_1_father_spouse,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
   
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={jointOneMotherMaidenName.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointOneMotherMaidenname, jointOneMotherMaidenName,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointOneMotherMaidenName.includes("–") ?jointOneMotherMaidenName?.split("–")[1] : jointOneMotherMaidenName}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointOneMotherMaidenname, jointOneMotherMaidenName,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  <div className="form-group">		
                    <label>Relation </label>		
                    <div  >		
                       <select		
                           className={`form-control  w-100`}		
                           value={jointOneRelation}		
                           onChange={(e) => {		
                             setJointOneRelation(e.target.value);		
                            
                           }}			
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Relation		
                          </option>		
                       {RELATION.map((item, index) => {
                        return(
                          <option value={item} key={item}>{item}</option>		
                        )

                       })}
                          
                        </select>  	
                    </div>		
                  </div>
                 

                  
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointOneGender}
                                onChange={(e) => {
                                  setJointOneGender(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Gender
                                </option>
                                {GENDER.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointOneMaritalStatus}
                                onChange={(e) => {
                                  setJointOneMaritalStatus(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Marital Status
                                </option>
                                {MARITAL_STATUS.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointOneOccupation}
                                onChange={(e) => {
                                  setJointOneOccupation(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Occupation
                                </option>
                                {OCCUPATION.map(item =>
                                  <option value={item} key={item}>
                                    {item.slice(item.indexOf(" ") + 1)}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}	
                                className={`form-control  w-100`}
                                value={jointOneAnnualIncome}
                                onValueChange={(e) => {
                                  setJointOneAnnualIncome(e.value);

                                }}
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                           type="text"
                            className={`form-control  w-100 `}
                            value={jointOnePlaceOfBith}
                            onChange={(e) => {
                              setJointOnePlaceOfBirth(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                 

                   <Row>
                  <Col md="12" xl="6">
                          <div className="form-group  ">		
                          <div className='d-flex justify-content-between '>
                            <label>CNIC</label>		
                            <FormGroup check className='mt-0'>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={jointOneCNICLifeTime}		
                              onChange={(e) => {		
                                setJointOneCNICLifeTime(!jointOneCNICLifeTime);		
                              }} />		
                              <span className="form-check-sign" />		
                              Life Time	
                            </Label>		
                          </FormGroup>	
                            </div>
                            <div  >		
                              

                            <InputMask  
                        className={`form-control  w-100 `}
                        mask="99999-9999999-9"
                        value={joint_h_1_cnic}
                        onChange={(e) => {
                          setJointHolderOneCnic(e.target.value);			
                                }}></InputMask>	 

                            </div>		
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 `}	
                                readOnly={jointOneCNICLifeTime}	
                                value={jointOneCNICExpiry}		
                                onChange={(e) => {		
                                  setJointOneCNICExpiry(e.target.value);		
                                
                                }}		
                              />		
                            </div>		
                          </div>
                        </Col>
                      </Row>  
                              

                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_ntn}		
                        onChange={(e) => {		
                          setJointHolderOneNTN(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_1_address_bill}		
                        onChange={(e) => {		
                          setJointHolderOneAddressBill(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>
                     <select
                     className="form-control"
                     value={joint_h_1_address_bill_type}
                     onChange={(e)=>{
                      setJointHolderOneAddressBillType(e.target.value)
                    }}
                     >
                       <option value="" hidden> Select Address Bill Type</option>
                       <option value="Electric">Electric</option>
                       <option value="Gas">Gas</option>
                       <option value="Water">Water</option>
                       <option value="Internet">Internet</option>
                       <option value="Phone">Phone</option>
                     </select>
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <select
                     className="form-control"
                     value={joint_h_1_taxStatus}
                     onChange={(e)=>{
                      setJointHolderOneTaxStatus(e.target.value)
                    }}
                     >
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                     </select>
                 </div>

                  </CardBody>}
                </Card>
              </Col>}

              {type == "Joint Account" &&
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 2

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_two:!openCloseCards.joint_two})}>
                        <i className={"tim-icons " + (openCloseCards.joint_two ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.joint_two &&
                    <CardBody>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_2_name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderTwoName, joint_h_2_name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_name.includes("–") ?joint_h_2_name?.split("–")[1] : joint_h_2_name}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderTwoName, joint_h_2_name,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_2_father_spouse.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderTwoFather, joint_h_2_father_spouse,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Father/Spouse Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_father_spouse.includes("–") ?joint_h_2_father_spouse?.split("–")[1] : joint_h_2_father_spouse}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderTwoFather, joint_h_2_father_spouse,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={jointTwoMotherMaidenName.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointTwoMotherMaidenname, jointTwoMotherMaidenName,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointTwoMotherMaidenName.includes("–") ?jointTwoMotherMaidenName?.split("–")[1] : jointTwoMotherMaidenName}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointTwoMotherMaidenname, jointTwoMotherMaidenName,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                  		
                 
                  

                  <div className="form-group">		
                    <label> Relation   </label>		
                    <div  >		
                       <select		
                           className={`form-control  w-100`}		
                           value={jointTwoRelation}		
                           onChange={(e) => {		
                             setJointTwoRelation(e.target.value);		
                            
                           }}			
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Relation		
                          </option>		
                       {RELATION.map((item, index) => {
                        return(
                          <option value={item} key={item}>{item}</option>		
                        )

                       })}
                          
                        </select>  	
                    </div>		
                  </div>
                  
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointTwoGender}
                                onChange={(e) => {
                                  setJointTwoGender(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Gender
                                </option>
                                {GENDER.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointTwoMaritalStatus}
                                onChange={(e) => {
                                  setJointTwoMaritalStatus(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Marital Status
                                </option>
                                {MARITAL_STATUS.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointTwoOccupation}
                                onChange={(e) => {
                                  setJointTwoOccupation(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Occupation
                                </option>
                                {OCCUPATION.map(item =>
                                  <option value={item} key={item}>
                                    {item.slice(item.indexOf(" ") + 1)}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}	
                                className={`form-control  w-100`}
                                value={jointTwoAnnualIncome}
                                onValueChange={(e) => {
                                  setJointTwoAnnualIncome(e.value);

                                }}
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={jointTwoPlaceOfBith}
                            onChange={(e) => {
                              setJointTwoPlaceOfBirth(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                  

                  <Row>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <div className='d-flex justify-content-between '>
                          <label>CNIC</label>
                          <FormGroup check className='mt-0'>
                            <Label check>
                              <Input type="checkbox"
                                checked={jointTwoCNICLifeTime}
                                onChange={(e) => {
                                  setJointTwoCNICLifeTime(!jointTwoCNICLifeTime);
                                }} />
                              <span className="form-check-sign" />
                              Life Time
                            </Label>
                          </FormGroup>
                        </div>
                        <div  >


                          <InputMask
                            className={`form-control  w-100 `}
                            mask="99999-9999999-9"
                            value={joint_h_2_cnic}
                            onChange={(e) => {
                              setJointHolderTwoCnic(e.target.value);
                            }}></InputMask>

                        </div>
                      </div>
                    </Col>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <label>CNIC Expiry</label>
                        <div  >
                          <input
                            type="date"
                            className={`form-control  w-100 `}
                            readOnly={jointTwoCNICLifeTime}
                            value={jointTwoCNICExpiry}
                            onChange={(e) => {
                              setJointTwoCNICExpiry(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>  
                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_ntn}		
                        onChange={(e) => {		
                          setJointHolderTwoNTN(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_2_address_bill}		
                        onChange={(e) => {		
                          setJointHolderTwoAddressBill(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>
                     <select
                     className="form-control"
                     value={joint_h_2_address_bill_type}
                     onChange={(e)=>{
                      setJointHolderTwoAddressBillType(e.target.value)
                    }}
                     >
                       <option value="" hidden> Select Address Bill Type</option>
                       <option value="Electric">Electric</option>
                       <option value="Gas">Gas</option>
                       <option value="Water">Water</option>
                       <option value="Internet">Internet</option>
                       <option value="Phone">Phone</option>
                     </select>
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <select
                     className="form-control"
                     value={joint_h_2_taxStatus}
                     onChange={(e)=>{
                      setJointHolderTwoTaxStatus(e.target.value)
                    }}>
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                     </select>
                 </div>

                  </CardBody>}
                </Card>
              </Col>}

              {type == "Joint Account" &&
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       Joint Holder 3

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,joint_three:!openCloseCards.joint_three})}>
                        <i className={"tim-icons " + (openCloseCards.joint_three ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.joint_three &&
                  <CardBody>

                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_3_name.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderThreeName, joint_h_3_name,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>  Name   </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_name.includes("–") ?joint_h_3_name?.split("–")[1] : joint_h_3_name}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderThreeName, joint_h_3_name,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={joint_h_3_father_spouse.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointHolderThreeFather, joint_h_3_father_spouse,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Father/Spouse Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_father_spouse.includes("–") ?joint_h_3_father_spouse?.split("–")[1] : joint_h_3_father_spouse}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointHolderThreeFather, joint_h_3_father_spouse,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12" lg="6" xl="4" >
                      <div className="form-group">		
                    <label>Salutation</label>		
                    <div>		
                    <select		
                               
                      className={`form-control  w-100 `}		
                      value={jointThreeMotherMaidenName.split("–")[0]}		
                      onChange={(e) => {
                      updateSalutationFields(e.target.value, setJointThreeMotherMaidenname, jointThreeMotherMaidenName,"salutation")
                      
                      }}	
                    >
                        <option value="" defaultChecked hidden>		
                        Select Salutation	
                        </option>		
                        {SALUTATIONS.map(item=>
                        <option value={item} key={item}>		
                        {item}
                        </option>		
                        )}
                      </select>	
                     
                            </div>		
                       </div> 

                      </Col>
                      <Col>
                  <div className="form-group">		
                    <label>Mother Maiden Name</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={jointThreeMotherMaidenName.includes("–") ?jointThreeMotherMaidenName?.split("–")[1] : jointThreeMotherMaidenName}		
                        onChange={(e) => {		
                          updateSalutationFields(e.target.value, setJointThreeMotherMaidenname, jointThreeMotherMaidenName,"value")

                        
                        }}		
                      />		 	
                    </div>		
                  </div>
                  </Col>
                  </Row>

                 
                  <div className="form-group">		
                    <label>Relation   </label>		
                    <div  >		
                       <select		
                           className={`form-control  w-100`}		
                           value={jointThreeRelation}		
                           onChange={(e) => {		
                             setJointThreeRelation(e.target.value);		
                            
                           }}			
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Relation		
                          </option>		
                       {RELATION.map((item, index) => {
                        return(
                          <option value={item} key={item}>{item}</option>		
                        )

                       })}
                          
                        </select>  	
                    </div>		
                  </div>

                 
                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Gender</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointThreeGender}
                                onChange={(e) => {
                                  setJointThreeGender(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Gender
                                </option>
                                {GENDER.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Martial Status</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointThreeMaritalStatus}
                                onChange={(e) => {
                                  setJointThreeMaritalStatus(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Marital Status
                                </option>
                                {MARITAL_STATUS.map(item =>
                                  <option value={item} key={item}>
                                    {item}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Occupation</label>
                            <div  >
                              <select

                                className={`form-control  w-100 `}
                                value={jointThreeOccupation}
                                onChange={(e) => {
                                  setJointThreeOccupation(e.target.value)
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Occupation
                                </option>
                                {OCCUPATION.map(item =>
                                  <option value={item} key={item}>
                                    {item.slice(item.indexOf(" ") + 1)}
                                  </option>)}
                              </select>


                            </div>
                          </div>
                        </Col>
                        <Col md="12" xl="6">
                          <div className="form-group  ">
                            <label>Annual Income</label>
                            <div  >
                            <NumberFormat
                            thousandSeparator={true}	
                            style={{textAlign:'right'}}	
                                className={`form-control  w-100`}
                                value={jointThreeAnnualIncome}
                                onValueChange={(e) => {
                                  setJointThreeAnnualIncome(e.value);

                                }}
                              />


                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Place Of Birth</label>
                        <div  >
                          <input
                           type="text"
                            className={`form-control  w-100 `}
                            value={jointThreePlaceOfBith}
                            onChange={(e) => {
                              setJointThreePlaceOfBirth(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                 

                  <Row>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <div className='d-flex justify-content-between '>
                          <label>CNIC</label>
                          <FormGroup check className='mt-0'>
                            <Label check>
                              <Input type="checkbox"
                                checked={jointThreeCNICLifeTime}
                                onChange={(e) => {
                                  setJointThreeCNICLifeTime(!jointThreeCNICLifeTime);
                                }} />
                              <span className="form-check-sign" />
                              Life Time
                            </Label>
                          </FormGroup>
                        </div>
                        <div  >


                          <InputMask
                            className={`form-control  w-100 `}
                            mask="99999-9999999-9"
                            value={joint_h_3_cnic}
                            onChange={(e) => {
                              setJointHolderThreeCnic(e.target.value);
                            }}></InputMask>

                        </div>
                      </div>
                    </Col>
                    <Col md="12" xl="6">
                      <div className="form-group  ">
                        <label>CNIC Expiry</label>
                        <div  >
                          <input
                            type="date"
                            className={`form-control  w-100 `}
                            readOnly={jointThreeCNICLifeTime}
                            value={jointThreeCNICExpiry}
                            onChange={(e) => {
                              setJointThreeCNICExpiry(e.target.value);

                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>  

                  

                  <div className="form-group">		
                    <label> NTN   </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_ntn}		
                        onChange={(e) => {		
                          setJointHolderThreeNTN(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Address Bill </label>		
                    <div  >		
                      <input		
                        type="number"		
                        className={`form-control  w-100 `}		
                        value={joint_h_3_address_bill}		
                        onChange={(e) => {		
                          setJointHolderThreeAddressBill(e.target.value);		
                        }}		
                      />	

                        

                    </div>		
                  </div>
                  <div className="form-group">		
                     
                     <label>Address Bill Type</label>
                     <select
                     className="form-control"
                     value={joint_h_3_address_bill_type}
                     onChange={(e)=>{
                      setJointHolderThreeAddressBillType(e.target.value)
                    }}
                     >
                       <option value="" hidden> Select Address Bill Type</option>
                       <option value="Electric">Electric</option>
                       <option value="Gas">Gas</option>
                       <option value="Water">Water</option>
                       <option value="Internet">Internet</option>
                       <option value="Phone">Phone</option>
                     </select>
                 </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <select
                     className="form-control"
                     value={joint_h_3_taxStatus}
                     onChange={(e)=>{
                      setJointHolderThreeTaxStatus(e.target.value)
                    }}>
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                     </select>
                 </div>

                  </CardBody>}
                </Card>
              </Col>}


              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                       CDC

                       <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,cdc:!openCloseCards.cdc})}>
                        <i className={"tim-icons " + (openCloseCards.cdc ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                  {openCloseCards.cdc &&
                   <CardBody>

                  <div className="form-group">		
                    <label>Investment Account Service</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_IAS}		
                        onChange={(e) => {		
                          setCDCIAS(e.target.value);				
                        }}		
                      />		 	
                    </div>		
                  </div>
                  <div className="form-group">		
                    <label> CDC Subaccount </label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_subAccount}		
                        onChange={(e) => {		
                          setCDCSubAccount(e.target.value);		
                        }}		
                      />	

                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>CDC participant ID</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 `}		
                        value={cdc_participantID}		
                        onChange={(e) => {		
                          setCDCParticipantID(e.target.value);			
                        }}		
                      />
                
                    </div>		
                  </div>


                  </CardBody>}
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle className='d-flex justify-content-between' tag="h4">
                    Sales Details

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,sales_details:!openCloseCards.sales_details})}>
                        <i className={"tim-icons " + (openCloseCards.sales_details ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>
                  </CardHeader>
                 {openCloseCards.sales_details &&
                  <CardBody>
                  <div className="form-group">
                    <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox" 	
                           checked={salesInHouse}	
                           onChange={(e) => {	
                             setSalesInHouse(!salesInHouse);	
                           }}/>	
                          <span className="form-check-sign" />	
                          In house sale
                        </Label>	
                      </FormGroup>
                    </div>

                    <div className="form-group">		
                    <label>Sales Company</label>		
                    
                    <div  >		
                    <select
                     className="form-control"
                     value={salesCompany}		
                     onChange={(e) => {		
                       setSalesCompany(e.target.value)	
                       setSalesInHouse(allSalesCompany.find(item => item.company_code === e.target.value).in_house == true ? true : false)
                       setSalesPerson('')
                     }}	

                     
                    >
                       <option value="" hidden> Select Sales Company</option>
                       {allSalesCompany?.map((item, index) => {
                        return (
                          <option key={item.company_code} value={item.company_code}>
                            {item.company_name}
                          </option>
                        );
                       })
                       }
                      
                      
                     </select>
                    </div>		
                  </div>
                  <div className="form-group">		
                    <label>Sales Person</label>		
                    <div  >		
                    <select
                     className="form-control"
                     value={salesPerson}
                     onChange={(e) => {		
                      setSalesPerson(e.target.value)
                    }}	
                     
                    >
                       <option value="" hidden> Select Sales Person</option>
                       {allSalesCompany.find(item=>item.company_code == salesCompany)?.persons?.map((item, index) => {
                        return (
                          <option key={item.email} value={item.id}>
                            {`${item.name}(${item.email})`}
                          </option>
                        );
                       })
                       }
                       
                     </select>

                    </div>		
                  </div>
                 


                  </CardBody>}
                </Card>
              </Col>
               
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                    Funds Portfolio
                    </CardTitle>
                  </CardHeader>
                  <CardBody> 
                      <ReactTooltip		
                        textColor="white"		
                        backgroundColor="black"		
                        effect="float"		
                      />		
                      <button		
                        data-tip="Add Fund" 		
                        className="btn-round btn-icon btn btn-primary"		
                        onClick={() => {		
                          setModalView(!modalView);		
                          setDisableAddBtn(false);		
                        }}		
                      > <i className="fa fa-plus"></i> </button>		
                    

                    {totalFunds.length > 0 && (		
                    <div className="input-size-pos mb-3 mx-3">		
                      <table className="table">		
                        <thead>		
                        <tr>
                            <th>Fund code</th>
                            <th>Fund Name</th>
                            <th>NAV</th>
                            <th>Fund Unit</th>
                            <th>Action</th>
                          </tr>	
                        </thead>		
                        <tbody>{renderFundDataInTable()}</tbody>		
                      </table>		
                    </div>		
                  )}	

                  </CardBody>

                  
                </Card>


                
              </Col>
              <Col md="12">

                <Card>
                  <CardHeader>
                  <CardTitle className='d-flex justify-content-between' tag="h4">
                    Document

                    <button
                      className='btn-round btn-icon btn-sm btn btn-primary'
                      onClick={()=>setOpenCloseCards({...openCloseCards,documents:!openCloseCards.documents})}>
                        <i className={"tim-icons " + (openCloseCards.documents ? "icon-minimal-up" : "icon-minimal-down")}   />
                      </button>
                    </CardTitle>

                  </CardHeader>


                  {openCloseCards.documents &&
                   <CardBody className='row'>
                    {type && FilesByInvestorType[type]?.map((item, index) => {
                      return(
                        <div className="form-group col-md-6" key={item.value}>
                        <label >{item.label} &nbsp; &nbsp;
                        <small className="color-amber">
                        (PNG, JPG, JPEG, PDF, ZIP)
                         </small></label>
                         <div className='row'>
                        <input
                          type="file"
                          ref={el=>filesRef.current[item.value] = el}
                          style={{ display: "none" }}
                          multiple={false}
                          //accept="image/png, image/jpeg"
                          accept=".jpg,.jpeg,.png,.pdf,.zip"
                          onChange={(e) => {
                              upload(e, item.value);
                          }}
                      />
                      <div className="col-md-3"
                      onClick={() => filesRef.current[item.value]?.click()}
                      >
                         <input		
                        type="text"		
                        className={`form-control w-100 multi-input pointer`}		
                        
                        value={files[item.value]?.name ? files[item.value]?.name :"Upload File"  }	
                        		
                      />
                      </div>
                      <div className='col-md-1 text-center'>OR</div>

                      <div className='col-md-6'>

                      <input		
                        type="text"		
                        className={`form-control w-100 `}		
                        placeholder='Enter Link'
                        value={filesLink[item.value] || ""}		
                        onChange={(e) => {		
                          setFilesLink({...filesLink, [item.value]: e.target.value});			
                        }}		
                      />

                      </div>
                      </div>
                      </div>
                      )
                    })}
                  </CardBody>}
                </Card>
                </Col>
            </Row>

            <div className='mb-2' >
                      <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={agreedTerms}		
                              onChange={(e) => {		
                                setAgreedTerms(!agreedTerms);		
                              }} />		
                              <span className="form-check-sign" />		
                              Terms and Conditions agreed by investor		
                            </Label>		
                          </FormGroup>	
                      </div>

            <div className=" ">		
              <button		
                className="btn btn-primary "		
                onClick={addInvestorData}		
                disabled={Boolean(Loading) || !agreedTerms}		
              >		
                {Loading ? (		
                  <>		
                    <span		
                      className="spinner-border login-txt spinner-border-sm"		
                      role="status"		
                      aria-hidden="true"		
                    ></span>		
                    <span className="login-txt"> Loading...</span>		
                  </>		
                ) : (		
                  <span>Create</span>		
                )}		
              </button>		
            </div>	
             	 
           	
            	 	
          {renderModalPopup()}		 
      </Container>		
    </Col>
    </Row>
      </div>
    </>		
  );		
};		
export default SetupPotentialInvestor;