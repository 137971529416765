declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
// type EnvType = {
//   // for Dev
//   REACT_APP_TITLE: "Back-Office Dev",
//   REACT_APP_VERSION: "v0.1",
//   REACT_APP_BASE_URL: "http://182.191.93.242:3000/api",
//   REACT_APP_SOCKET_URL: "http://182.191.93.242:3000/",
//   REACT_APP_IMAGE_URL: "http://182.191.93.242:3000/",
//   CAPTCHA_ENABLED: "true", // true or false
// }

type EnvType = {
  // for Dev
  REACT_APP_TITLE: "MACS-360 Dev";
  REACT_APP_VERSION: "v0.1";
  REACT_APP_BASE_URL: "https://backoffice-dev.dccl.com.pk:3000/api";
  REACT_APP_SOCKET_URL: "https://backoffice-dev.dccl.com.pk:3000/";
  REACT_APP_IMAGE_URL: "https://backoffice-dev.dccl.com.pk:3000/";
  CAPTCHA_ENABLED: "false"; // true or false
};

// type EnvType = {
//   // for Dev old
//   REACT_APP_TITLE: "Back-Office Dev";
//   REACT_APP_VERSION: "v0.1";
//   REACT_APP_BASE_URL: "http://203.128.8.2:5232/api";
//   REACT_APP_SOCKET_URL: "http://203.128.8.2:5232/api";
//   REACT_APP_IMAGE_URL: "http://203.128.8.2:5232/api";
//   CAPTCHA_ENABLED: "false"; // true or false
// };

export const env: EnvType = { ...process.env, ...window.env };
