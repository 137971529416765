import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getChartOfAccounts = async (email: string) => {
  const url = `${Config.baseUrl}/chartofaccounts/config?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getChartOfAccounts(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getTransactionsLedgers = async (email: string) => {
  const url = `${Config.baseUrl}/chartofaccounts/ledger/config?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getTransactionsLedgers(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getLedgerInfoByCode = async (email: string, ledger_code: any) => {
  const url = `${Config.baseUrl}/chartofaccounts/get-cof-by-ledger-code?email=${email}&ledger_code=${ledger_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getLedgerInfoByCode(email, ledger_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

//api for adding newLedger and getting its parents
const getLedgerDataByCode = async (email: string, ledger_code: any) => {
  const url = `${Config.baseUrl}/chartofaccounts/get-cof-card-by-ledger-code?email=${email}&ledger_code=${ledger_code}`;

  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getLedgerInfoByCode(email, ledger_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

//api for getting current level info
const getFamilyInfoForLedgerCode = async (
  email: string,
  ledger_code: any,
  family: any
) => {
  const url = `${Config.baseUrl}/chartofaccounts/get-cof-card-by-ledger-code?email=${email}&ledger_code=${ledger_code}&type=${family}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getLedgerInfoByCode(email, ledger_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

// api for replacing complete chart of accounts
const replaceChartOfAccountsFile = async (email: any, jsonObject: any, amc_code:string) => {
  const url = `${Config.baseUrl}/chartofaccounts`;
  const token = sessionStorage.getItem("token");
  try {
    const result: any = await axios.post(
      url,
      {
        email: email,
        data: jsonObject,
        amc_code
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};

const getNewChartsOfAccounts = async (email: any, amc_code:string) => {
  const url = `${Config.baseUrl}/chartofaccounts?email=${email}&amc_code=${amc_code}`;
  const token = sessionStorage.getItem("token");
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    return result;
  } catch (error: any) {
    return error;
  }
};

export {
  getChartOfAccounts,
  getTransactionsLedgers,
  getLedgerInfoByCode,
  getLedgerDataByCode,
  getFamilyInfoForLedgerCode,
  replaceChartOfAccountsFile,
  getNewChartsOfAccounts,
};
