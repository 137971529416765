import React, { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { Container, Row, Col } from 'reactstrap';
import { getFeatures, editRole } from '../../stores/services/role.service';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaLess } from 'react-icons/fa';
import Select from 'react-select';

const EditRole = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const amc_code = sessionStorage.getItem('amc_code') || '';
  let [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [checked, setChecked] = useState<any>([]);
  let [expanded, setExpanded] = useState<any>([-1]);
  let [nodes, setNodes] = useState<any>([]);
  let [nameError, setNameError] = useState(false);
  let [descriptionError, setDescriptionError] = useState(false);
  let [featruesError, setFeaturesError] = useState(false);
  let [hideBtn, setHideBtn] = useState(false);
  let [labelValue, setLabelValue] = useState('');
  const [features, setFeatures] = useState<any>([]);
  const [checkboxSelected, setCheckBoxSelected] = useState(false);
  const [checkboxTreeEnable, setCheckboxTreeEnable] = useState(false);
  const allDefaultDepartments = JSON.parse(sessionStorage.getItem("defaultDepartments") || "[]") || []
  const allDefaultRoles = JSON.parse(sessionStorage.getItem("defaultRoles") || "[]") || []
  const [selectDepartment, setSelectDepartment] = useState<any>({})
  const [selectDepartmentRole, setSelectDepartmentRole] = useState<any>({})

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('roleObj') || '');
    if (sessionStorage.getItem('rolePageType') === 'view') {

      setCheckboxTreeEnable(true);
      setLabelValue('View');

      setHideBtn(true);
    } else {

      setLabelValue('Edit');

      if (obj.role_type) {
        setSelectDepartment({ value: obj.role_type?.replace("_"," "), label: obj.role_type?.replace("_"," "), departmentRoles: allDefaultDepartments.find(item => item.department == obj.role_type?.replace("_"," "))?.departmentRoles || [] })
      }

    }
    setName(obj.role_name);
    setDescription(obj.description);
    const features = JSON.parse(obj.features);
    const result = features.map((a) => {
      return a.id;
    });
    setChecked(result);
    getFeatures(sessionStorage.getItem('email') || '')
      .then((response) => {
        setFeatures(response.data.features);
        var data = response.data.features
       
        response.data.features.unshift({
          id: -1,
          feature:  "Assign All",
          parent_id: null,
        });
        for (let index = 0; index < response.data.features.length; index++) {
          if (response.data.features[index].parent_id === 0) {
            response.data.features[index].parent_id = -1;
          }
          response.data.features[index].value =
            response.data.features[index].id;
          response.data.features[index].label =
            response.data.features[index].feature;
        }
        var data = response.data.features;
        var root: any;
        const idMapping = data.reduce((acc: any, el: any, i: any) => {
          acc[el.id] = i;
          return acc;
        }, {});
        data.forEach((el: any) => {
          // Handle the root element
          if (el.parent_id === null) {
            root = el;
            return;
          }
          // Use our mapping to locate the parent element in our data array
          const parentEl = data[idMapping[el.parent_id]];
          // Add our current el to its parent's `children` array
          parentEl.children = [...(parentEl.children || []), el];
        });
       

        if(result.length == data?.filter(item => (item.parent_id && item.parent_id != -1) || !item.children)?.length )
        {
          setNodes([{...root,features:"Unassign All", label:"Unassign All"}]);
        }else{
          setNodes([root]);
        }
      })
      .catch((err) => {
      });
  }, []);
  const addRoleBtn = () => {
    setNameError(false);
    setDescriptionError(false);
    setFeaturesError(false);
    setLoading(true);
    if (!name) {
      setNameError(true);
      setLoading(false);
      return;
    }
    if (!description) {
      setDescriptionError(true);
      setLoading(false);
      return;
    }
    if (!selectDepartment?.value) {
      toast.error('Department is Required');
      setLoading(false);
      return;
    }
    if (checked.length === 0) {
      setFeaturesError(true);
      setLoading(false);
      return;
    }
    const selected_features: any = [];
    for (let index = 0; index < checked.length; index++) {
      const feature = features.find((x: any) => x.id === parseInt(checked[index]));
      if (feature) {
        selected_features.push(feature);
      }
    }
    editRole(
      sessionStorage.getItem('email') || '',
      name,
      description,
      JSON.stringify(selected_features),
      selectDepartment?.value?.replace(" ", "_") || "",
      amc_code
    )
      .then((response) => {
        if (response.data.status === 200) {
          setCheckBoxSelected(true);
          // setLoading(false);
          toast.success(response.data.message);
          setTimeout(function () {
            history.replace('/admin/role-management');
          }, 3000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data.message);
      });
  };

  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"> {labelValue} Role </h4>{" "}
                </div>
                <div className="card-body">
                  <div className="title-row">
                    <h3 className="mb-1"> Role Info </h3>
                    <Link to="/admin/role-management" className="t-3 btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>
                  </div>{" "}
                  {
                    hideBtn === true ? '' :
                      <p className="t-3 mb-2">
                        Please enter the following information to edit a new Role{" "}
                      </p>
                  }

                  <div className="">
                    <Row>
                      <Col md="6">
                        <div className="form-group ">
                          <label> Name* </label>{" "}
                          <input readOnly className="form-control" defaultValue={name}
                          />

                        </div>{" "}
                        {
                          nameError === true ?
                            <p className="error-labels">Name is Required.</p>
                            : ''
                        }
                      </Col>
                      <Col md="6">
                        <div className="form-group  align-items-start">
                          <label> Description* </label>{" "}
                          <textarea defaultValue={description} className="form-control description-box" onChange={(e) => {
                            setDescription(e.target.value)
                          }} />

                        </div>{" "}
                        {
                          descriptionError === true ?
                            <p className="error-labels">Description is Required.</p>
                            : ''
                        }
                      </Col>

                    </Row>
                    <Row>
                      {labelValue == "Edit" && <Col md="6">
                        <div className="form-group  ">
                          <label> Department* </label>{" "}
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={'Select'}

                            // isDisabled={false}
                            onChange={(e) => {
                              //e.preventDefault();
                              setSelectDepartment(e?.value ? e : {})
                              setSelectDepartmentRole({})
                              setChecked([])
                            }}
                            value={selectDepartment?.value ? [selectDepartment] : []}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={allDefaultDepartments.map(item => {
                              return { value: item.department, label: item.department, departmentRoles: item.departmentRoles }
                            })}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />


                        </div>

                      </Col>}

                    </Row>
                    <Row>

                      {labelValue == "Edit" && <Col md="6">
                        <div className="form-group  ">
                          <label> Department Role </label>{" "}
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            placeholder={selectDepartment?.value ? 'Select' : "First Select Department"}
                            //  isDisabled={!selectDepartment?.value}

                            onChange={(e) => {
                              //e.preventDefault();

                              setSelectDepartmentRole(e?.value ? e : {})
                              if (e?.value) {
                                let selectedChecked: string[] = []
                                let SelectedFeatures = allDefaultRoles.find(item => item.department == selectDepartment?.value)?.departmentRoles?.find(item => item.name == e?.value)?.defaultFeatures || []

                                nodes?.[0]?.children?.map(item => {
                                  let findFeature = SelectedFeatures.find(role => role.label == item.label)
                                  if (findFeature) {

                                    if (!item?.children) {
                                      selectedChecked.push(item.id)
                                    } else {
                                      item.children.map(item2 => {
                                        if (findFeature?.submenu.find(menu => menu.label == item2.label)) {
                                          selectedChecked.push(item2.id)
                                        }

                                      })
                                    }

                                  }

                                })

                                setChecked(selectedChecked)

                              }
                            }}
                            value={selectDepartmentRole?.value ? [selectDepartmentRole] : []}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={selectDepartment?.value ? selectDepartment?.departmentRoles?.map(item => {
                              return { value: item, label: item }
                            }) : []}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />


                        </div>

                      </Col>}
                    </Row>
                    <div className="line"> </div>{" "}
                    <h3 className="mb-1"> Assign Features </h3>{" "}
                    <p className="t-3 mb-2"> Features </p>
                    <Row className="mt-3 ml-2">
                      <CheckboxTree
                        nodes={nodes}
                        checked={checked}
                        expanded={expanded}
                        disabled={checkboxTreeEnable}
                        onCheck={(checked) => {
                          setChecked(checked);
                          if (features.filter(item => (item.parent_id && item.parent_id != -1) || !item.children)?.length == checked.length) {
                            setNodes([{ ...nodes[0], feature: "Unassign All", label: "Unassign All", }])
                          } else if (nodes[0].feature != "Assign All") {
                            setNodes([{ ...nodes[0], feature: "Assign All", label: "Assign All" }])
                          }
                        }}
                        onExpand={(expanded) => setExpanded(expanded)}
                        iconsClass="fa5"
                      />
                      {
                        featruesError === true ?
                          <p className="error-labels">Select Features.</p>
                          : ''
                      }
                    </Row>
                  </div>
                  {
                    hideBtn === false ? (
                      <div className="d-flex mt-4">
                        <div className="">
                          <Link className="btn btn-default bg-negative" to="/admin/role-management" style={{
                            pointerEvents: Boolean(Loading) ? "none" : "auto"
                          }}>Cancel</Link>
                        </div>
                        <div className="">
                          <button className="btn btn-primary ml-4" onClick={addRoleBtn} disabled={Boolean(Loading)}>
                            {Loading ? (<><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span className="login-txt"> Loading...</span></>) : (<span>Update Role</span>)}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                </div>
              </div>{" "}
            </Container>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditRole;