import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from '../../../utils/customFunction';

const ViewSecurity = () => {
  const history = useHistory();	
  const [securityType, setSecurityType] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('')
  const [issueDate, setIssueDate] = useState('');
  const [maturityDate, setMaturityDate] = useState('')
  const [faceValue, setFaceValue] = useState('');
  const [price, setPrice] = useState('');
  const [couponPaymentPerYear, setCouponPaymentPerYear] = useState('');
  const [noOfCoupon, setNoOfCoupon] = useState('');
  const [faceValuePerUnit, setFaceValuePerUnit] = useState('');
  const [kiborRateBenchmark, setKiborRateBenchmark] = useState('');
  const [systemExecutionDate, setSystemExecutionDate] = useState('');

  const [yieldText,setYieldText] = useState('');
  const [kiborRate, setKiborRate] = useState('') ;
  const [spreadRate, setSpreadRate] = useState('') ;
  
  const [coupons, setCoupons] = useState<any>([]);

  const [file, setFile] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('securityObj') || '');
    setSecurityType(obj.security_type);
    setCode(obj.code.replace('SECURITY_',''));
    setName(obj.name);
    setIssueDate(obj.issue_date ? moment(obj.issue_date).format('YYYY-MM-DD') : '' );
    setMaturityDate(obj.maturity_date ? moment(obj.maturity_date).format('YYYY-MM-DD') : '' );
    setFaceValue(obj.face_value);
    setPrice(obj.price);
    setFile(obj.companies);
    setCouponPaymentPerYear(obj.coupon_payment_per_year);
    setNoOfCoupon(obj.no_of_coupons);
    setFaceValuePerUnit(obj.face_value_per_unit);
    setKiborRateBenchmark(obj.kibor_rate_benchmark);
    setSystemExecutionDate(obj.system_execution_date ? moment(obj.system_execution_date).format('DD-MM-YYYY') : '');
    setCoupons(JSON.parse(obj.coupons || "[]"));
    setKiborRate(obj.kibor_rate || "");
    setSpreadRate(obj.spread_rate || "");
    setYieldText(obj.yield_percentage || "");
  }, []);
  return (
    <>
    <div className="content">
    <Row>
      <Col md="12 input-holder">
          <h4 className="card-title ml-3">
          <i className="fa fa-angle-left pointer" onClick={() => {
                history.replace('/admin/securities')
            }}></i>{' '}View - Investment Securities
            </h4>
            <Link to="/admin/securities" className="btn btn-primary btn-sm mr-3" replace> <i className="fa fa-eye mr-2"></i> View All</Link>	
          </Col>
        </Row>
        <Row>
          <Col md="6">
      <Container fluid>
        <div className="card">
          <div className="">
            <div className="card-header">
            <h4 className="card-title text-primary">Security</h4>
            <div className="title-row">
            
            </div>
            </div>
            <div className="card-body row">
             
                <Col md="6">
                  <div className="form-group">
                    <label>Security Type</label>
                    <div className="form-control">{securityType}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Symbol</label>
                    <div className="form-control">{code}</div>
                  </div>
                </Col>
           
                <Col md="6">
                  <div className="form-group">
                    <label>Name</label>
                    <div className="form-control">{name}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Issue Date</label>
                    <div className="form-control">{issueDate}</div>
                  </div>
                </Col>
             
                <Col md="6">
                  <div className="form-group">
                    <label>Maturity Date</label>
                    <div className="form-control">{maturityDate}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Face Value</label>
                    <div className="form-control">{numberWithCommas(faceValue)}</div>
                  </div>
                </Col>
              
                <Col md="6">
                  <div className="form-group">
                    <label>Price</label>
                    <div className="form-control">{numberWithCommas(price)}</div>
                  </div>
                </Col>

                <Col md="6">	
                <div className="form-group">	
                  <label>System Execution Date</label>
                  <div className="form-control">{systemExecutionDate}</div>	
                </div>	
              </Col>

              {(securityType == "TBILL" || securityType == "PIB")
                && 
                <Col md="6">
                  <div className="form-group">
                    <label>Yield %</label>
                    <div className="form-control">{yieldText}</div>
                  </div>
                </Col>	}
              
            </div>
          
          </div>
        </div>
      </Container>
    
  </Col>
  {(securityType =="PIB" || securityType == "SUKUK" || securityType == "TFC") &&
  <Col md="6">
      <Container fluid>	
        {/* <ToastContainer limit={1} />	 */}
        <div className="card ">	
          <div className="">	
            <div className="card-header title-row">	
            <h4 className="card-title text-primary">Coupon Detail</h4>
            </div>	
            <div className="card-body row">	
            
               	
            <Col md="6">
                  <div className="form-group">
                    <label>Coupon Payment/Year</label>
                    <div className="form-control">{couponPaymentPerYear}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>No of Coupons</label>
                    <div className="form-control">{noOfCoupon}</div>
                  </div>
                </Col>	
            
              <Col md="6">	
                <div className="form-group">	
                  <label>Face Value/Unit</label>	
                  <div className="form-control">{faceValuePerUnit}</div>	
                </div>	
              </Col>
              <Col md="6">
                  <div className="form-group">
                    <label>Kibor Rate Benchmark</label>
                    <div className="form-control">{kiborRateBenchmark}</div>
                  </div>
                </Col>	

                <Col md="6">
                  <div className="form-group">
                    <label>Kibor Rate</label>
                    <div className="form-control">{kiborRate}</div>
                  </div>
                </Col>	

                <Col md="6">
                  <div className="form-group">
                    <label>Spread Rate</label>
                    <div className="form-control">{spreadRate}</div>
                  </div>
                </Col>	

               
            
            </div>	
            	
          </div>	
        </div>	
      </Container>	
      </Col>}
        </Row>
        <Row>
          <Col md="12">
          <Container fluid>	
            {(securityType =="PIB" || securityType == "SUKUK" || securityType == "TFC") &&
              <div className='card'>
            <div className="card-header title-row">	
            <h4 className="card-title text-primary">Coupons</h4>
            </div>	
            <div className="card-body">
              {coupons.length>0 && (
              <div className='table-responsive'>
            <table className='table'>
              <thead>
              <tr>
                <th>Coupon no.</th>
                <th>Coupon Date</th>
                <th className='text-right'>Coupon Payment</th>
                <th className='text-right'>Kibor Rate%</th>
                <th className='text-right'>Spread Rate%</th>
                <th className='text-right'>Coupon Rate%</th>
                <th className='text-right'>Principal Redemption</th>
                <th className='text-right'>Remaining Face Value</th>
                <th>Paid</th>
              </tr>
              </thead>
              <tbody>
              {coupons.map((item:any, index:number)=>{
              return(
               <tr>
                 <td>{item.coupon_no}</td>
                 <td>{item.coupon_date}</td>
                 <td className='text-right'>{numberWithCommas(item.coupon_payment || 0)}</td>
                 <td className='text-right'>{numberWithCommas(item.kibor_rate || 0)}</td>
                 <td className='text-right'>{numberWithCommas(item.spread_rate || 0)}</td>
                 <td className='text-right'>{numberWithCommas(item.coupon_rate || 0)}</td>
                 <td className='text-right'>{numberWithCommas(item.principal_redemption || 0)}</td>
                 <td className='text-right'>{numberWithCommas(item.remaining_face_value || 0)}</td>
                 <td>{item.paid}</td>
               </tr>
              );
            })}
              </tbody>
            </table>
            </div>
              )}

            </div>
            </div>}
            </Container>	
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewSecurity;
