import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../../../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";

const BrokerList = () => {
  const history = useHistory();
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const email = sessionStorage.getItem("email") || "";
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isSearchActive, setSearchActive] = useState(false);
  let [activePage, setActivePage] = useState("");
  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  React.useEffect(() => {
    activePage ? getBrokerList("") : getBrokerList("true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const getBrokerList = async (resetData: string = "") => {
    setLoading(true);
    try {
      const response = await getAllBrokers(email, activePage, pageSize, amc_code);
      if (response?.data?.status == 200) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 &&
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        // setData(response.data.data);
        setData(resetData == "true" ? [...response.data.data] : [...data, ...response.data.data]);

      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (err: any) {
      // toast.error(error.response.data.message[0]);
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error("Request Failed!");
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      const newText = data[i].account_no;
      td = newText.split(/\s/).join("");
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const searchBrokerData = async () => {
    setLoading(true);
    try {
      const response = await searchBrokerAPI(email, searchValue, amc_code);
      setData(response.data.data);
      setSearchActive(true);
      setPageSize("100");
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message?.toString());
      } else {
        toast.error("Request Failed!");
      }
    }
    setLoading(false);
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      getBrokerList();
      setSearchActive(false);
      setPageSize("10");
    } else {
      setSearchValue(e.target.value);
    }
  };

  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.company_name}</td>
          <td>{items.company_type}</td>
          <td>{items.company_code}</td>
          <td>{items.account_title}</td>
          <td>{items.iban}</td>
          <td>{items.bank_name}</td>
          <td>{items.branch}</td>
          <td className="pointer">
            <button
              type="button"
              className="btn-icon btn-link like btn btn-primary btn-sm"
              onClick={() => {
                sessionStorage.setItem("brokerObj", JSON.stringify(items));
                history.replace(`/admin/view-broker`);
              }}
            >
              <i className="fa fa-eye"></i>
            </button>

            <button
              type="button"
              className="btn-icon btn-link like btn btn-info btn-sm"
              onClick={() => {
                sessionStorage.setItem("brokerObj", JSON.stringify(items));
                history.replace(`/admin/edit-broker`);
              }}
            >
              <i className="tim-icons icon-pencil"></i>
            </button>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card mt-4">
                <div className="d-flex align-items-center"></div>
                {!Loading ? (
                  <div className="">
                    <div className="card-header">
                      <h4 className="card-title">
                        Associated Beneficiary Listing
                        <div
                          className="btn btn-primary btn-sm float-right"
                          onClick={() => {
                            history.replace("/admin/setup-brokers");
                          }}
                        >
                          <i className="fa fa-plus mr-2"></i> Add New
                        </div>
                      </h4>
                    </div>

                    <div className="card-body">
                      <Row>
                        <Col md="4">
                          <div className="form-group">
                            <ReactTooltip
                              textColor="white"
                              backgroundColor="black"
                              effect="float"
                            />
                            <input
                              type="search"
                              id="myInput"
                              className="form-control"
                              data-tip="Enter Account No"
                              placeholder="Enter Account No"
                              value={searchValue}
                              onChange={(e) => {
                                // setSearchValue(e.target.value);
                                // myFunction(e);
                                handleSearchValue(e);
                              }}
                              onKeyPress={(e) => {
                                let key_board_keycode = e.which || e.keyCode;
                                if (key_board_keycode == 13) {
                                  e.preventDefault();
                                  searchBrokerData();
                                }
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="4">
                          <button
                            className="btn btn-primary mt-a"
                            onClick={searchBrokerData}
                          >
                            <i className="fa fa-search"></i> Search
                          </button>
                        </Col>
                      </Row>

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Company Type</th>
                              <th>Code</th>
                              <th>Account Title</th>
                              <th>Account No</th>
                              <th>Bank Name</th>
                              <th>Branch Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{renderData()}</tbody>
                        </table>

                        <div
                          className="row justify-content-center p-3 pointer">
                          {/* PREVIOUS BUTTON */}
                          {/* {activePage !== paginationArr[0] ? (
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                                onClick={() => {
                                  let pageArr = paginationArr;
                                  let index = pageArr.findIndex(
                                    (x) => x === activePage
                                  );
                                  if (index !== -1) {
                                    setActivePage(paginationArr[index - 1]);
                                  }
                                }}
                              ></i>
                            ) : (
                              ""
                            )} */}
                          {/* NEXT BUTTON */}
                          {/* {activePage !==
                            paginationArr[paginationArr.length - 1] ? (
                              <i
                                className="fa ml-3 fa-arrow-right"
                                aria-hidden="true"
                                onClick={() => {
                                  let pageArr = paginationArr;
                                  let index = pageArr.findIndex(
                                    (x) => x === activePage
                                  );
                                  if (index !== -1) {
                                    setActivePage(paginationArr[index + 1]);
                                  }
                                }}
                              ></i>
                            ) : (
                              ""
                            )} */}
                          <button
                            aria-hidden={true}
                            className="btn btn-primary btn-sm mt-2 py-2 px-3"
                            disabled={activePage ==
                              paginationArr[paginationArr.length - 1] || Loading}
                            type="button"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex(
                                (x) => x === activePage
                              );
                              if (index !== -1) {
                                setActivePage(paginationArr[index + 1]);
                              }
                            }}
                          >
                            {Loading ? <span>Loading... <i className="fa fa-spinner fa-spin  "
                              aria-hidden="true"
                            ></i></span> : "Show More"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center my-5">
                    <i className="fa fa-spinner fa-spin fa-3x"></i>
                  </div>
                )}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BrokerList;
