import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {addFinancialPeriod} from '../../../stores/services/financial-period.service';
import moment from 'moment';

const SetupFinancialPeriod =()=>{
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const amc_code =  sessionStorage.getItem('amc_code') || '';
    let date = new Date();

    const [startDate, setStartDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [description, setDescription] = useState('');
    const [period, setPeriod] = useState('');

    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [periodError, setPriodError] = useState('');

    const [loading, setLoading] = useState(false);

    const validate=()=>{
        let startDateErr = '';	
        let endDateErr = '';
        let descriptionErr = '';
        let periodErr = '';
        startDate.trim() === '' ? (startDateErr = 'required-border') : (startDateErr = '');
        endDate.trim() === '' ? (endDateErr = 'required-border') : (endDateErr = '');
        description.trim() === '' ? (descriptionErr = 'required-border') : (descriptionErr = '');
        period.trim() === '' ? (periodErr = 'required-border') : (periodErr = '');	
        if (startDateErr || endDateErr || descriptionErr || periodErr) {
        setStartDateError(startDateErr);
        setEndDateError(endDateErr);
        setDescriptionError(descriptionErr);
        setPriodError(periodErr);
          return false;	
        } else {	
          return true;	
        }	
    }

    const saveFinancialPeriod=async()=>{
        if(validate()){
            try{
                setLoading(true);
                const response = await addFinancialPeriod(email, amc_code, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), description, period);
                if(response.data.status==200){
                    toast.success(response.data.message);
                    setTimeout(()=>{
                        history.replace('/admin/financial-periods');
                    },3000)
                }else{
                    toast.error(response.data.message);
                }

            }catch(error:any){
                setLoading(false);
                if(error.response!==undefined){
                    toast.error(error.response.data.message);
                }else{
                    toast.error('Something went wrong!');
                }
            }
            setLoading(false);

        } // end of validate()
    }

    const calculatePeriod = (dateValue, valueType) =>{
        if(valueType=='Start Date'){
         if(endDate!==''){
            let endD = new Date(endDate);
            let findPeriod = new Date(dateValue).getFullYear()+'-'+endD.getFullYear();
            setPeriod(findPeriod);
        }
    }

    if(valueType=='End Date'){
        if(startDate!==''){
           let startD = new Date(startDate);
           let findPeriod = startD.getFullYear()+'-'+new Date(dateValue).getFullYear();
           setPeriod(findPeriod);
       }
   }

    }

    return (
        <>
        <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header title-row">	
            <h4 className="card-title text-primary"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              history.replace('/admin/financial-periods')
          }}></i>Setup - Financial Period</h4>	

            <Link to="/admin/financial-periods" className="btn btn-primary btn-sm ml-auto" replace>	
            <i className="fa fa-eye mr-2"></i>View All	
              </Link>	
          </div>	
         
          <div className="card-body">	
            <Row>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Start Date <span className="color-amber">*</span></label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 ${startDateError}`}	
                    value={startDate}
                    onChange={(e) => {	
                     setStartDate(e.target.value);
                     setStartDateError('');
                     calculatePeriod(e.target.value, 'Start Date');
                    }}	
                  />
                </div>	
              </Col>	
              <Col md="6">	
                <div className="form-group">	
                  <label>End Date<span className="color-amber">*</span></label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 ${endDateError}`}	
                    value={endDate}
                    onChange={(e) => {	
                     setEndDate(e.target.value);
                     setEndDateError('');
                     calculatePeriod(e.target.value, 'End Date');
                    }}	
                  />	
                </div>	
              </Col>	
            </Row>

            <Row>
                <Col md='6'>
                <div className="form-group">	
                  <label>Descirption<span className="color-amber">*</span></label>	
                  <input	
                    type="text"	
                    placeholder="Description"	
                    className={`form-control  w-100 ${descriptionError}`}	
                    value={description}
                    onChange={(e) => {	
                     setDescription(e.target.value);
                     setDescriptionError(e.target.value);
                    }}	
                  />	
                </div>	
                </Col>
                <Col md='6'>
                <div className="form-group">	
                  <label>Period<span className="color-amber">*</span></label>	
                  <input
                    type="text"
                    placeholder="Period"
                    readOnly
                    className={`form-control  w-100 ${periodError}`}	
                    value={period}
                    onChange={(e) => {	
                        setPeriod(e.target.value);
                        setPriodError('');
                    }}	
                  />	
                </div>	
                </Col>
            </Row>
          </div>	
          <div className="form-group">	
            <button	
              className="btn btn-primary ml-3"	
              onClick={saveFinancialPeriod}	
              disabled={Boolean(loading)}	
            >	
              {loading ? (	
                <>	
                  <span	
                    className="spinner-border login-txt spinner-border-sm"	
                    role="status"	
                    aria-hidden="true"	
                  ></span>	
                  <span className="login-txt"> Loading...</span>	
                </>	
              ) : (	
                <span>Create</span>	
              )}	
            </button>	
          </div>	
        </div>	
      </Container >	
      
 </Col>
        </Row>
      </div>
        </>
    );
}

export default SetupFinancialPeriod;