import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { getFundByAmc } from "../../stores/services/funds.service";
import { getNavReport } from "../../stores/services/nav.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import {
  numberWithCommas,
  getReveresedValue,
  getNegativeValue,
} from "../../utils/customFunction";
import PageTemplate from "./pdf-page-numbers";
import { BsTable } from "react-icons/bs";

const NavReport = () => {
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const [loading, setLoading] = useState(false);
  const [fundsLoading, setFundsLoading] = useState(false);
  const [funds, setFunds] = useState<any>([]);
  const [fundName, setFundName] = useState("");
  const [fundCode, setFundCode] = useState("");
  const [resFundName, setResFundName] = useState("");
  const [resFundCode, setResFundCode] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [resDate, setResDate] = useState("");
  const [reportData, setReportData] = useState<any>([]);

  const [fundCodeError, setFundCodeError] = useState("");
  const pdfExportComponent = React.useRef<PDFExport>(null);

  const getFundsByAmcCode = async () => {
    try {
      setFundsLoading(true);
      const response = await getFundByAmc(email, amc_code,fund_code?.length > 0 ? fund_code:"");

      if (response.data.status == 200) {
        if (response.data.data.length > 0) {
          setFunds(response.data.data);
        } else {
          funds.length = 0;
          setFunds(funds);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setFundsLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setFundsLoading(false);
  };

  useEffect(() => {
    getFundsByAmcCode();
  }, []);

  const renderFundsDropDown = () => {
    return funds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  };

  const validate = () => {
    let flag = true;
    if (fundCode == "") {
      setFundCodeError("required-border");
      flag = false;
    }
    return flag;
  };

  const searchNav = async () => {
    try {
      if (validate()) {
        setLoading(true);
        const response = await getNavReport(email, fundCode, date);
        console.log("RESPONSE => ", response);

        if (response.data.status === 200) {
          if (
            response.data.data &&
            response.data.data.calculated_nav_data !== ""
          ) {
            // let records = JSON.parse(response.data.data[0].Record.calculated_nav_data);
            let records = JSON.parse(response.data.data.calculated_nav_data);
            console.log("RECORDS => ", records);
            setReportData(records);
            setResFundName(fundName);
            setResFundCode(fundCode);
            setResDate(date);
          } else {
            reportData.length = 0;
            setReportData(reportData);
            setResFundName("");
            setResFundCode("");
            setResDate("");
            toast.error("No Records Found!");
          }
        } else {
          toast.error(response.data.message);
        }

        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title text-primary">NAV Report</h4>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="3">
                      <div className="form-group">
                        <label>Select Fund</label>
                        {fundsLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <select
                            className={`form-control  ${fundCodeError}`}
                            value={fundCode}
                            onChange={(e) => {
                              setFundName(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                      .text
                              );
                              setFundCode(e.target.value);
                              setFundCodeError("");
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="form-group">
                        <label>Select Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => {
                            setDate(
                              moment(e.target.value).format("YYYY-MM-DD")
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <button
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          searchNav();
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <>
                            <i className="fa fa-search"></i> {"Search"}
                            {/* <span>Search</span> */}
                          </>
                        )}
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>

              {reportData && reportData.length > 0 && (
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        pageTemplate={PageTemplate}
                        repeatHeaders={true}
                        fileName={
                          "NAV-Report-" +
                          resFundName.split("-")[0] +
                          "-" +
                          date +
                          ".pdf"
                        }
                        ref={pdfExportComponent}
                      >
                        <ReportHeader title="NAV Report" />
                        <Row>
                          <Col md="4" style={{ marginLeft: "70px" }}>
                            <Row>
                              <Col md="5">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Fund:
                                </span>
                              </Col>
                              <Col md="6">
                                <span className="text-nowrap">
                                  {resFundName}
                                </span>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="4" style={{ marginLeft: "70px" }}>
                            <Row>
                              <Col md="5">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  NAV Date:
                                </span>
                              </Col>
                              <Col md="6">
                                <span className="text-nowrap">
                                  {moment(resDate).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="4" style={{ marginLeft: "70px" }}>
                            <Row>
                              <Col md="5">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Report Date:
                                </span>
                              </Col>
                              <Col md="6">
                                <span className="text-nowrap">
                                  {moment(new Date()).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />

                        <Row>
                          <Col md="3"></Col>
                          <Col md="3">
                            <span>Date </span>
                          </Col>
                          <Col md="3">
                            <span>{moment(resDate).format("DD-MMM-YYYY")}</span>
                          </Col>
                          {/* <Col md="3">
                      <span>7-July-2022</span>
                  </Col> */}
                        </Row>
                        <Row></Row>

                        {reportData[2] != undefined && (
                          <>
                            <Row>
                              <Col md="6">
                                <span>Opening Net Assets</span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {numberWithCommas(
                                    reportData[2].opening_net_assets
                                  )}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> 84,354,310.76</span>
                  </Col> */}
                            </Row>

                            <Row></Row>
                            <Row>
                              <Col md="6">
                                <span>Opening Units</span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {numberWithCommas(
                                    reportData[2].opening_units
                                  )}
                                </span>
                              </Col>
                              <Col md="3">
                                <span></span>
                              </Col>
                            </Row>
                          </>
                        )}
                        <br />
                        <Row
                          style={{
                            backgroundColor: "#cfdbdd",
                            fontWeight: "bold",
                          }}
                        >
                          <Col>Income Code 5 </Col>
                        </Row>
                        {reportData[0] != undefined &&
                          reportData[0].data.map((item: any) => {
                            if (
                              item.name ==
                                "Unrealized Gain / (Loss) on Equity Investment" ||
                              item.name ==
                                "Unrealized Gain / (Loss) on Money Market" ||
                              item.name == "Mark-up on Government Securities" ||
                              item.name == "Mark-up on Debt Securities" ||
                              item.name == "Mark-up on TDR" ||
                              item.name == "Mark-up on Commercial Paper" ||
                              item.name == "Mark-up on Call Deposits" ||
                              item.name == "Mark-up on Letter of Placements" ||
                              item.name == "Mark-up on Bank Deposits" ||
                              item.name == "Mark-up on MTS Transaction" ||
                              item.name == "Capital Gains / (Loss) Equity" ||
                              item.name == "Capital Gains / (Loss) Equity" ||
                              item.name == "Capital Gains / (Loss) Futures" ||
                              item.name ==
                                "Capital Gains / (Loss) Money Market" ||
                              item.name == "Dividend Income on Equity" ||
                              item.name == "Dividend Income on Futures"
                              // || item.name=='Income Others'
                            ) {
                              return (
                                <Row>
                                  <Col md="6">
                                    <span>{item.name}</span>
                                  </Col>
                                  <Col md="3">
                                    <span>
                                      {" "}
                                      {getNegativeValue(item.total)}{" "}
                                    </span>
                                  </Col>
                                </Row>
                              );
                            }
                          })}

                        {reportData[0] != undefined &&
                          reportData[0].data.map((item: any) => {
                            if (item.name == "Income Others") {
                              return (
                                <Row>
                                  <Col md="6">
                                    <span>{item.name}</span>
                                  </Col>
                                  <Col md="3">
                                    <span>
                                      {" "}
                                      {getNegativeValue(item.total)}{" "}
                                    </span>
                                  </Col>
                                </Row>
                              );
                            }
                          })}

                        {/* <Row>
                  <Col md="6">
                      <span>Unrealized Gain / (Loss) on Investment</span>
                  </Col>
                  <Col md="3">
                      <span> 54</span>
                  </Col>
                  <Col md="3">
                      <span>(4)</span>
                  </Col>
              </Row> */}
                        {/* <Row>
                  <Col md="6">
                      <span>Mark-up on Government Securities</span>
                  </Col>
                  <Col md="3">
                      <span> -</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Mark-up on Debt Securities</span>
                  </Col>
                  <Col md="3">
                      <span> 2,000</span>
                  </Col>
                  <Col md="3">
                      <span>2,000</span>
                  </Col>
              </Row>
              <Row>
                  <Col md="6">
                      <span>Mark-up on TDR</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Mark-up on Commercial Paper</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Mark-up on Call Deposits</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Mark-up on Lettter of Placements</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Mark-up on Bank Deposits</span>
                  </Col>
                  <Col md="3">
                      <span>18,495</span>
                  </Col>
                  <Col md="3">
                      <span>23,566</span>
                  </Col>
              </Row>
              <Row>
                  <Col md="6">
                      <span>Capital Gains / (Loss) Equity</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Capital Gains / (Loss) Money Market</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>
              <Row>
                  <Col md="6">
                      <span>Dividend Income </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>Others</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row> */}

                        {reportData[2] != undefined && (
                          <>
                            <br />
                            <Row>
                              <Col md="6">
                                <span style={{ fontWeight: "bold" }}>
                                  Total Gross Income
                                </span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {getNegativeValue(
                                    reportData[2].total_gross_income
                                  )}
                                </span>
                              </Col>
                            </Row>
                          </>
                        )}

                        <br />
                        <Row
                          style={{
                            backgroundColor: "#cfdbdd",
                            fontWeight: "bold",
                          }}
                        >
                          <Col>Fixed Expense Code 4 </Col>
                        </Row>
                        {reportData[0] != undefined &&
                          reportData[0].data.map((item: any) => {
                            if (
                              item.name == "Audit Fee" ||
                              item.name == "PSX Listing Fee" ||
                              item.name == "Custody Fee" ||
                              item.name == "Printing Charges" ||
                              item.name == "PACRA Rating Fee" ||
                              item.name == "Bank Charges" ||
                              item.name == "Brokerage Expenses Equity" ||
                              item.name == "Brokerage Expenses Money Market" ||
                              item.name ==
                                "Amortization of Discount / Premium" ||
                              item.name == "Impairment of asset"
                              // || item.name=='Expense Others'
                            ) {
                              return (
                                <Row>
                                  <Col md="6">
                                    <span>{item.name}</span>
                                  </Col>
                                  <Col md="3">
                                    <span>
                                      {" "}
                                      {getReveresedValue(item.total)}{" "}
                                    </span>
                                  </Col>
                                </Row>
                              );
                            }
                          })}

                        {reportData[0] != undefined &&
                          reportData[0].data.map((item: any) => {
                            if (item.name == "Expense Others") {
                              return (
                                <Row>
                                  <Col md="6">
                                    <span>{item.name}</span>
                                  </Col>
                                  <Col md="3">
                                    <span>
                                      {" "}
                                      {getReveresedValue(item.total)}{" "}
                                    </span>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        {/* <Row>
                  <Col md="6">
                      <span>Audit Fee</span>
                  </Col>
                  <Col md="3">
                      <span> (621)</span>
                  </Col>
                  <Col md="3">
                      <span> (619)</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span>PSX Listing Fee</span>
                  </Col>
                  <Col md="3">
                      <span> (71)</span>
                  </Col>
                  <Col md="3">
                      <span> (68)</span>
                  </Col>
              </Row>
              <Row>
                  <Col md="6">
                      <span> SECP Supervisory Fee </span>
                  </Col>
                  <Col md="3">
                      <span> (6)</span>
                  </Col>
                  <Col md="3">
                      <span> (7)</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Formation Cost </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              
              <Row>
                  <Col md="6">
                      <span> Custody Fee </span>
                  </Col>
                  <Col md="3">
                      <span> (205)</span>
                  </Col>
                  <Col md="3">
                      <span> (488)</span>
                  </Col>
              </Row>
             
              <Row>
                  <Col md="6">
                      <span>  Printing Charges </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> PACRA Rating Fee </span>
                  </Col>
                  <Col md="3">
                      <span> (847)</span>
                  </Col>
                  <Col md="3">
                      <span> (845)</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Bank Charges </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Brokerage Expenses Equity </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Brokerage Expenses Money Market </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Amortization of Discount </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Others </span>
                  </Col>
                  <Col md="3">
                      <span> (1,577)</span>
                  </Col>
                  <Col md="3">
                      <span> (1,718)</span>
                  </Col>
        </Row> */}

                        {reportData[2] != undefined && (
                          <>
                            <br />
                            <Row>
                              <Col md="6">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Expenses Before charging{" "}
                                </span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {getReveresedValue(
                                    reportData[2].expenses_before_charging
                                  )}
                                </span>
                              </Col>
                              {/* <Col md="3">
                    <span style={{fontWeight:"bold"}}> (3,779)</span>
                </Col> */}
                            </Row>
                          </>
                        )}
                        <br />

                        <Row
                          style={{
                            backgroundColor: "#cfdbdd",
                            fontWeight: "bold",
                          }}
                        >
                          <Col md="6">
                            <span>Unit Holding</span>
                          </Col>
                          <Col md="3">
                            <span></span>
                          </Col>
                          <Col md="3">
                            <span></span>
                          </Col>
                        </Row>

                        {reportData[0] != undefined &&
                          reportData[0].data.map((item: any) => {
                            if (
                              (item.name == "Sale of Units" &&
                                item.type == "Ledger") ||
                              (item.name == "Redemption of Units" &&
                                item.type == "Ledger")
                            ) {
                              return (
                                <Row>
                                  <Col md="6">
                                    <span>{item.name}</span>
                                  </Col>
                                  <Col md="3">
                                    <span>
                                      {" "}
                                      {getNegativeValue(item.total)}{" "}
                                    </span>
                                  </Col>
                                </Row>
                              );
                            } else if (
                              (item.name == "Sale of Units" &&
                                item.type == "Transaction") ||
                              (item.name == "Redemption of Units" &&
                                item.type == "Transaction")
                            ) {
                              if (
                                item.name == "Sale of Units" &&
                                item.type == "Transaction"
                              ) {
                                return (
                                  <>
                                    <br />
                                    <Row>
                                      <Col md="6">
                                        <span>{item.name}</span>
                                      </Col>
                                      <Col md="3">
                                        <span>
                                          {" "}
                                          {getNegativeValue(item.total)}{" "}
                                        </span>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <Row>
                                      <Col md="6">
                                        <span>{item.name}</span>
                                      </Col>
                                      <Col md="3">
                                        <span>
                                          {" "}
                                          {getReveresedValue(item.total)}{" "}
                                        </span>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              }
                            }
                          })}

                        {/* <Row>
                  <Col md="6">
                      <span>Sale of Units</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span> 29,391,102 </span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Redemption of Units </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>
              <br/>

              <Row>
                  <Col md="6">
                      <span> Sale of Units </span>
                  </Col>
                  <Col md="3">
                      <span> 1,000 </span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row>

              <Row>
                  <Col md="6">
                      <span> Redemption of Units </span>
                  </Col>
                  <Col md="3">
                      <span> (500)</span>
                  </Col>
                  <Col md="3">
                      <span>-</span>
                  </Col>
              </Row> */}
                        <br />

                        {reportData[2] != undefined && (
                          <>
                            <Row style={{ backgroundColor: "#e8eff0" }}>
                              <Col md="6">
                                <span>Net Assets before Charging</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {" "}
                                  {reportData[2].net_assets_before_charging}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> 113,767,196 </span>
                  </Col> */}
                            </Row>

                            <br />

                            <Row>
                              <Col md="6">
                                <span>Management Fee</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {getReveresedValue(
                                    reportData[2].management_fee
                                  )}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> (1,558)</span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span>Sale Tax on Mgt. Fee</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {" "}
                                  {getReveresedValue(
                                    reportData[2].sst_on_management_fee
                                  )}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> (203)</span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span>Trustee Fee</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {getReveresedValue(reportData[2].trustee_fee)}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span>(499)</span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span>Sale Tax on Trustee Fee</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {getReveresedValue(
                                    reportData[2].sst_on_trustee_fee
                                  )}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span>(65)</span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span>SECP</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {" "}
                                  {getReveresedValue(reportData[2].secp_fee)}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> (62)</span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span></span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {getReveresedValue(reportData[2].total_fee)}{" "}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span style={{fontWeight: "bold"}}>(2,387)</span>
                  </Col> */}
                            </Row>
                            <br />

                            <Row style={{ backgroundColor: "#e8eff0" }}>
                              <Col md="6">
                                <span>Net Income/(loss) Per Day</span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {reportData[2].net_income_loss_per_day}{" "}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span> 19,397 </span>
                  </Col> */}
                            </Row>
                            <br />

                            <Row style={{ backgroundColor: "#e8eff0" }}>
                              <Col md="6">
                                <span>Closing Net Asset</span>
                              </Col>
                              <Col md="3">
                                <span>{reportData[2].closing_net_assets}</span>
                              </Col>
                              {/* <Col md="3">
                      <span> 113,764,421.01 </span>
                  </Col> */}
                            </Row>
                            <br />

                            <Row style={{ backgroundColor: "#e8eff0" }}>
                              <Col md="6">
                                <span> Closing Units </span>
                              </Col>
                              <Col md="3">
                                <span>{reportData[2].closing_units}</span>
                              </Col>
                              {/* <Col md="3">
                      <span> 13,756,068.8614 </span>
                  </Col> */}
                            </Row>
                            <br />

                            <Row>
                              <Col md="6">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  NAV per Unit{" "}
                                </span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {reportData[2].nav_per_units}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span style={{fontWeight: "bold"}}> 8.2702 </span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Offer per Unit{" "}
                                </span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {reportData[2].offer_per_unit}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span style={{fontWeight: "bold"}}> 8.3529 </span>
                  </Col> */}
                            </Row>

                            <Row>
                              <Col md="6">
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Redemption per Unit{" "}
                                </span>
                              </Col>
                              <Col md="3">
                                <span style={{ fontWeight: "bold" }}>
                                  {reportData[2].redemption_per_unit}
                                </span>
                              </Col>
                              {/* <Col md="3">
                      <span style={{fontWeight: "bold"}}> 8.2702 </span>
                  </Col> */}
                            </Row>
                          </>
                        )}

                        <br />
                        {reportData[2] != undefined && (
                          <Row
                            style={{
                              backgroundColor: "#aed4f1",
                            }}
                          >
                            <Col md="6">
                              <span>1 Day Return</span>
                            </Col>
                            <Col md="3">
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {reportData[2].one_day_return} %
                              </span>
                            </Col>
                            {/* <Col md="3">
                      <span>8.39%</span>
                  </Col> */}
                          </Row>
                        )}

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Row>
                          <Col md="6">
                            <div
                              className="float-left w-50"
                              style={{
                                borderTop: "1px solid",
                                textAlign: "center",
                              }}
                            >
                              Prepared By
                            </div>{" "}
                          </Col>
                          <Col md="6">
                            <div
                              className="float-right w-50"
                              style={{
                                borderTop: "1px solid",
                                textAlign: "center",
                              }}
                            >
                              Checked By
                            </div>
                          </Col>
                        </Row>
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              )}

              {(!reportData || reportData.length === 0) && (
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        pageTemplate={PageTemplate}
                        repeatHeaders={true}
                        fileName={
                          "NAV-Report-" +
                          resFundName.split("-")[0] +
                          "-" +
                          date +
                          ".pdf"
                        }
                        ref={pdfExportComponent}
                      >
                        <div className="table-empty">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">
                            No record found
                          </p>
                        </div>
                      </PDFExport>
                    </div>
                  </div>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NavReport;
