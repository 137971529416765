import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { addTaxRate } from "../../../stores/services/tax-rates.service";

const AddTaxRate =()=>{
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const amc_code =  sessionStorage.getItem('amc_code') || '';

    const [individual_filer, setIndividualFiler] = useState('0');
    const [individual_non_filer, setIndividualNonFiler] = useState('0');
    const [individual_exempted, setIndividualExempted] = useState('0');
    const [corporate_filer, setCorporateFiler] = useState('0');
    const [corporate_non_filer, setCorporateNonFiler] = useState('0');
    const [corporate_exempted, setCorporateExempted] = useState('0');

    const [individual_filerError, setIndividualFilerError] = useState('');
    const [individual_non_filerError, setIndividualNonFilerError] = useState('');
    const [individual_exemptedError, setIndividualExemptedError] = useState('');
    const [corporate_filerError, setCorporateFilerError] = useState('');
    const [corporate_non_filerError, setCorporateNonFilerError] = useState('');
    const [corporate_exemptedError, setCorporateExemptedError] = useState('');

    const [loading, setLoading] = useState(false);

    const [category,setCategory]=useState('');
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [period, setPeriod] = useState(new Date().getFullYear()+'-' +new Date().getFullYear());
    const [cgtExempAfterYears, setCgtExempAfterYears] = useState("0");
    const saveTaxRates=async()=>{
        if(validate()){
            try{
                setLoading(true);
                const response = await addTaxRate(
                  amc_code,
                  email,
                  individual_filer,
                  individual_non_filer,
                  individual_exempted,
                  corporate_filer,
                  corporate_non_filer,
                  corporate_exempted,
                  period,
                  startDate,
                  endDate,
                  category,
                  +cgtExempAfterYears || 0
                );
                if(response.data.status==200){
                    toast.success(response.data.message);
                    setTimeout(()=>{
                        history.replace('/admin/tax-rates');
                    },3000)
                }else{
                  setLoading(false);
                    toast.error(response.data.message);
                }

            }catch(error:any){
                setLoading(false);
                if(error.response!==undefined){
                    toast.error(error.response.data.message?.toString());
                }else{
                    toast.error('Something went wrong!');
                }
            }
           
    }
    };

    const validate=()=>{
        let individualFilerErr = '';	
        let individualNonFilerErr = '';
        let individualExemptedErr = '';
        let corporateFilerErr = '';	
        let corporateNonFilerErr = '';
        let corporateExemptedErr = '';
        individual_filer.trim() === '' ? (individualFilerErr = 'required-border') : (individualFilerErr = '');
        individual_non_filer.trim() === '' ? (individualNonFilerErr = 'required-border') : (individualNonFilerErr = '');
        individual_exempted.trim() === '' ? (individualExemptedErr = 'required-border') : (individualExemptedErr = '');
        corporate_filer.trim() === '' ? (corporateFilerErr = 'required-border') : (corporateFilerErr = '');
        corporate_non_filer.trim() === '' ? (corporateNonFilerErr = 'required-border') : (corporateNonFilerErr = '');
        corporate_exempted.trim() === '' ? (corporateExemptedErr = 'required-border') : (corporateExemptedErr = '');
        if (individualFilerErr || individualNonFilerErr || individualExemptedErr || corporateFilerErr || corporateNonFilerErr
         || corporateExemptedErr) {
        setIndividualFilerError(individualFilerErr);
        setIndividualNonFilerError(individualNonFilerErr);
        setIndividualExemptedError(individualExemptedErr);
        setCorporateFilerError(corporateFilerErr);
        setCorporateNonFilerError(corporateNonFilerErr);
        setCorporateExemptedError(corporateExemptedErr);
          return false;	
        } else {	
          return true;	
        }	
    }

    const calculatePeriod = (dateValue, valueType) =>{
      if(valueType=='Start Date'){
       if(endDate!==''){
          let endD = new Date(endDate);
          let findPeriod = new Date(dateValue).getFullYear()+'-'+endD.getFullYear();
          setPeriod(findPeriod);
      }
  }

  if(valueType=='End Date'){
      if(startDate!==''){
         let startD = new Date(startDate);
         let findPeriod = startD.getFullYear()+'-'+new Date(dateValue).getFullYear();
         setPeriod(findPeriod);
     }
 }

  }

    return (
        <>
        <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header title-row">	
            <h4 className="card-title text-primary"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              history.replace('/admin/tax-rates')
          }}></i>Setup - Tax Rates</h4>	

            <Link to="/admin/tax-rates" className="btn btn-primary btn-sm ml-auto" replace>	
            <i className="fa fa-eye mr-2"></i>View All	
              </Link>	
          </div>	
         
          <div className="card-body">
          <Row>	

              <Col md="6">
                  <div className="form-group">
                    <label>Category</label>
                  <div>
                  <div className="">
                      <select 
                      className={"form-control  w-100 "}
                      value={category}
                      onChange={(e)=>{
                      setCategory(e.target.value);
                     

                      }}>
                      <option value='' hidden>Select Category</option>
                      <option value='Stock Funds'>Stock Funds</option>
                      <option value='Other Funds'>Other Funds</option>

                      
                      </select>
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>
                <Col md="6">	
                <div className="form-group">	
                  <label> Financial Year Start Date </label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 `}	
                    value={startDate}
                    onChange={(e) => {	
                     setStartDate(e.target.value);
                     
                     calculatePeriod(e.target.value, 'Start Date');
                    }}	
                  />
                </div>	
              </Col>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Financial Year End Date</label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 `}	
                    value={endDate}
                    onChange={(e) => {	
                     setEndDate(e.target.value);
                    
                     calculatePeriod(e.target.value, 'End Date');
                    }}	
                  />	
                </div>	
              </Col>	

              <Col md='6'>
                <div className="form-group">	
                  <label>Financial Year Period</label>	
                  <input
                    type="text"
                    placeholder="Period"
                    readOnly
                    className={`form-control  w-100 `}	
                    value={period}
                    onChange={(e) => {	
                        setPeriod(e.target.value);
                       
                    }}	
                  />	
                </div>	
                </Col>


              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_filerError}`}	
                    value={individual_filer}
                    onChange={(e) => {
                     setIndividualFiler(e.target.value);
                     setIndividualFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Non-Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_non_filerError}`}	
                    value={individual_non_filer}
                    onChange={(e) => {
                     setIndividualNonFiler(e.target.value);
                     setIndividualNonFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              	
              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Exempted <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_exemptedError}`}	
                    value={individual_exempted}
                    onChange={(e) => {
                     setIndividualExempted(e.target.value);
                     setIndividualExemptedError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_filerError}`}
                    value={corporate_filer}
                    onChange={(e) => {
                     setCorporateFiler(e.target.value);
                     setCorporateFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Non-Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_non_filerError}`}	
                    value={corporate_non_filer}
                    onChange={(e) => {
                    setCorporateNonFiler(e.target.value);
                    setCorporateNonFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Exempted <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_exemptedError}`}
                    value={corporate_exempted}
                    onChange={(e) => {
                     setCorporateExempted(e.target.value);
                     setCorporateExemptedError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>CGT Exempt After Years </label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right `}
                    value={cgtExempAfterYears}
                    onChange={(e) => {
                     if(e.target.value && +e.target.value > 0 && +e.target.value < 11){
                      setCgtExempAfterYears(e.target.value);
                     }else if(!e.target.value ){
                      setCgtExempAfterYears('0');
                     }
                    }}	
                  />
                </div>	
              </Col>
              </Row>
               
          </div>	
          <div className="form-group">	
            <button	
              className="btn btn-primary ml-3"	
              onClick={saveTaxRates}	
              disabled={Boolean(loading)}	
            >	
              {loading ? (	
                <>	
                  <span	
                    className="spinner-border login-txt spinner-border-sm"	
                    role="status"	
                    aria-hidden="true"	
                  ></span>	
                  <span className="login-txt"> Loading...</span>	
                </>	
              ) : (	
                <span>Save</span>	
              )}	
            </button>	
          </div>	
        </div>	
      </Container >	
      
 </Col>
        </Row>
      </div>
        </>
    );
}

export default AddTaxRate;