import React from "react";
import {
  updateBroker,
  getBroker,
  getBrokerType,
} from "../../../stores/services/broker.service";
import { getAllBanks } from "../../../stores/services/bank.service";
import { getBranchByBankName } from "../../../stores/services/branch.service";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { accountTitle_AccountNumber_Regex } from "../../../utils/customFunction";
import ToggleButton from 'react-toggle-button';
const EditBroker = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [brokerType, setBrokerType] = useState("");
  const [revenueAuthority, setRevenueAuthority] = useState("");
  const [iban, setIban] = useState("");
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const obj = JSON.parse(sessionStorage.getItem("brokerObj") || "");
  const [acc_title, setAccTitle] = useState("");
  const [code, setCode] = useState("");
  const [acc_titleError, setAccTitleError] = useState("");
  const [codeError, setCodeError] = useState("");
  //error getting hooks
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  const [active, setActive] = useState("");

  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name,amc_code);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };
  const [brokerTypeData, setBrokerTypeData] = useState<any>([]);
  const [taxStatus, setTaxStatus] = useState('');
  React.useEffect(() => {
    const getAccountList = async () => {
      setLoading(true);
      try {
        const response = await getBroker(email, obj.company_code, amc_code);
        setCompanyName(response.data.data.company_name);
        setCode(response.data.data.company_code);
        setAccTitle(response.data.data.account_title);
        setBankName(response.data.data.bank_name);
        setBranchName(response.data.data.branch);
        setBrokerType(response.data.data.company_type);
        setRevenueAuthority(response.data.data.tax_collected);
        setIban(response.data.data.iban);
        setActive(response.data.data.active == "true" ? "active" : "deactive");
        setTaxStatus(response.data.data.income_tax_status || "")
        getBranchByName(response.data.data.bank_name);
      } catch (err: any) {
        // toast.error(error.response.data.message[0]);
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
      setLoading(false);
    };
    getAccountList();
  }, []);

  React.useEffect(() => {
    const fetchBank = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email,"","",amc_code);
        setBankNameData(response.data.data);
      } catch (error) {}
      try {
        const res = await getBrokerType(email);
        setBrokerTypeData(res.data.company_names);
      } catch (error) {}
      //get tx nature data for dropdown
    };
    fetchBank();
  }, []);

  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace("BANK_", "")}>
          {item.bank_code.replace("BANK_", "")}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };

  //render dropdown for bank name data
  const renderBrokerTypeDropdown = () => {
    return brokerTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data
  const validate = () => {
    let ntnNumErr,
      brokerErr = "";
    companyName.trim() === "" ? (brokerErr = "Required") : (brokerErr = "");
    if (brokerErr) {
      setCompanyNameError(brokerErr);
      return false;
    } else {
      return true;
    }
  };

  const updateBrokerProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await updateBroker(
          email,
          brokerType,
          bankName,
          branchName,
          iban,
          code,
          acc_title,
          companyName,
          revenueAuthority,
          active,
          amc_code,
          taxStatus
        );
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/associated-beneficiaries`);
          }, 3000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message[0]);
        } else {
          toast.error(error.response.data.message);
        }
      }
      
    }
  };

  const borderRadiusStyle = { borderRadius: 2 };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      history.replace("/admin/associated-beneficiaries");
                    }}
                  ></i>
                  Edit - Associated Beneficiary
                </h4>
                <Link
                  to="/admin/associated-beneficiaries"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Associated Beneficiary</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Code:</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              codeError ? "required-border" : ""
                            }`}
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              setCodeError("");
                            }}
                            disabled
                          />
                          {/* {codeError ? <p className="error-labels error-message2">{codeError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Company Name:</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              companyNameError ? "required-border" : ""
                            }`}
                            value={companyName}
                            onChange={(e) => {
                              // eslint-disable-next-line
                              setCompanyName(e.target.value),
                                setCompanyNameError("");
                            }}
                          />
                          {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Company Type:</label>
                        <div className="">
                          <select
                            className="form-control"
                            value={brokerType}
                            onChange={(e) => {
                              setBrokerType(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Broker Type
                            </option>
                            {renderBrokerTypeDropdown()}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Revenue Authority:</label>
                        <div className="">
                          <select
                            className="form-control"
                            value={revenueAuthority}
                            onChange={(e) => {
                              setRevenueAuthority(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Revenue Authority
                            </option>
                            <option value="FBR">
                              FBR - Federal Board Of Revenue
                            </option>
                            <option value="SRB">
                              SRB - Sindh Revenue Board
                            </option>
                            <option value="PRA">
                              PRA - Punjab Revenue Authority
                            </option>
                            <option value="BRA">
                              BRA - Balochistan Revenue Authority
                            </option>
                            <option value="KPRA">
                              KPRA - Khyber Pakhtunkhwa Revenue Authority
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">		
                     
                     <label>Income Tax Status</label>
                     <select
                     className="form-control"
                     value={taxStatus}
                     onChange={(e)=>{
                       setTaxStatus(e.target.value)
                     }}>
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                     </select>
                 </div>

                 <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={active == "active" ? true : false}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (active == "active") {
                                  setActive("deactive");
                                } else {
                                  setActive("active");
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Status</label> 
                      </div> 
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Bank Account Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Account Title:</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              acc_titleError ? "required-border" : ""
                            }`}
                            value={acc_title}
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setAccTitle(e.target.value);
                                setAccTitleError("");
                              }
                            }}
                          />
                          {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>IBAN / Account No:</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={iban}
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setIban(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Bank Name:</label>
                        <div className="">
                          <select
                            className="form-control"
                            value={bankName}
                            onChange={(e) => {
                              setBankName(e.target.value);
                              getBranchByName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {" "}
                              Select Bank
                            </option>
                            {renderBankNameDropdown()}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Branch Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={branchName}
                          onChange={(e) => {
                            setBranchName(e.target.value);
                          }}
                        />
                        {/* <div className="">
                      <select
                        className="form-control"
                        value={branchName}
                        onChange={(e) => {
                          setBranchName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {branchCheckFlag
                            ? 'No Branch Found'
                            : 'Select Branch Name'}
                        </option>
                        {renderBranchsDropdown()}
                      </select>
                    </div> */}
                      </div>

                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <button
                className="btn btn-primary mr-3"
                onClick={updateBrokerProcess}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span className=" " role="status" aria-hidden="true"></span>
                    <span className="login-txt">
                    <i className="fa fa-spin fa-spinner mr-1"></i>Loading...
                    </span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>

              <button
                className="btn btn-default"
                onClick={() => {
                  history.replace("/admin/associated-beneficiaries");
                }}
              >
                Cancel
              </button>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditBroker;
