import { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
// import { addSecurity } from "../../../stores/services/security.service";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import NumberFormat from "react-number-format";
import {
  getChartOfAccounts,
  replaceChartOfAccountsFile,
  getNewChartsOfAccounts,
  // getFamilyInfoForLedgerCode,
} from "../../../stores/services/chatofaccounts.service";
// import axios from "axios";
// import {
//   IoIosAddCircleOutline,
//   IoIosRemoveCircleOutline,
// } from "react-icons/io";
import RowComponent from "./components/row-component";
import { Modal } from "react-bootstrap";
import {
  AiOutlineWarning,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
} from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ReactTooltip from "react-tooltip";
import { numberWithCommas } from "../../../utils/customFunction";
import NumberFormat from "react-number-format";
import { getFundByAmc } from "../../../stores/services/funds.service";
import { getAllLedgers } from "../../../utils/customFunction";
import { useDispatch, useSelector } from "react-redux";
import { GetChartOfAccount } from "../../../redux/actions/chartOfAccount-Actions";
import { ThemeContext } from "../../../contexts/ThemeContext";
import "./COA.css";
import { HiRefresh } from "react-icons/hi";

const ChartsOfAccountsConfig = () => {
  const email = sessionStorage.getItem("email") || "";
  const token = sessionStorage.getItem("token") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const context = useContext(ThemeContext);
  const updated_ChartOfAccounts = useSelector(
    (state: any) => state.chartofAccounts
  );
  const dispatch = useDispatch();

  let [successfull, setSuccessfull] = useState<boolean>(false);
  let [chartOfAccounts, setChartOfAccounts] = useState<any>();
  let [mainFamily, setMainFamily] = useState<any>();
  let [ledgerOption, setLedgerOption] = useState<any>([]);
  let [allLedgerData, setAllLedgerData] = useState<any>([]);

  const [isAbleToRevert, setIsAbleToRevert] = useState(false);
  // const [mainFamilyData, setMainFamilyData] = useState<any>([]);

  // const [mainFamilyError, setMainFamilyError] = useState("");
  // const [getDataLoading, setGetDataLoading] = useState(false);

  // const [chartOfAccounts, setChartsOfAccount] = useState<any>([]);

  const [Loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [currentLedgerStatus, setCurrentLedgerStatus] = useState<any>("");
  const [changingStatus, setChangingStatus] = useState<boolean>(false);
  const [changeStatusLedgerCode, setChangeStatusLedgerCode] = useState<any>("");
  const [editLedgerCode, setEditLedgerCode] = useState<any>("");

  const [addModalParentLedgerCode, setAddModalParentLedgerCode] =
    useState<any>("");
  const [addModalParentLedgerName, setAddModalParentLedgerName] =
    useState<any>("");

  const [disableSaveButton, setDisableSaveButton] = useState(true);

  let plusIcon: boolean = true;

  let parentName: string = "";
  let [parentsInfo, setParentsInfo] = useState<any>([]);

  //states for ledger form (alot of states)

  let [mainFamilyCode, setMainFamilyCode] = useState<any>("");
  let [mainFamilyName, setMainFamilyName] = useState<any>("");

  let [familyCode, setFamilyCode] = useState<any>("");
  let [familyName, setFamilyName] = useState<any>("");

  let [subFamilyCode, setSubFamilyCode] = useState<any>("");
  let [subFamilyName, setSubFamilyName] = useState<any>("");

  let [childFamilyCode, setChildFamilyCode] = useState<any>("");
  let [childFamilyName, setChildFamilyName] = useState<any>("");

  let [glCode, setGlCode] = useState<any>("");
  let [glName, setGlName] = useState<any>("");

  const [addingParent, setAddingParent] = useState<boolean>(false);

  let [mainFamilyFlag, setMainFamilyFlag] = useState<boolean>(false);
  let [familyFlag, setFamilyFlag] = useState<boolean>(false);
  let [subFamilyFlag, setSubFamilyFlag] = useState<boolean>(false);
  let [childFamilyFlag, setChildFamilyFlag] = useState<boolean>(false);
  let [glFlag, setGlFlag] = useState<boolean>(false);

  //states for extra fields in ledger form
  let [currentBalance, setCurrentBalance] = useState<any>();
  let [associatedAccounts, setAssociatedAccounts] = useState<any>();
  let [relatedLedger, setRelatedLedger] = useState<any>();
  let [slabFrom, setSlabFrom] = useState<any>();
  let [slabTo, setSlabTo] = useState<any>();
  let [slabRate, setSlabRate] = useState<any>();
  let [slabFixedRate, setSlabFixedRate] = useState<any>();
  let [bankAccountNumber, setBankAccountNumber] = useState<any>();
  let [bankAccountTitle, setBankAccountTitle] = useState<any>("");
  let [bankName, setBankName] = useState<any>("");
  let [bankAccountBranch, setBankAccountBranch] = useState<any>("");
  let [bankAccountFund, setBankAccountFund] = useState<any>("");
  let [interestRate, setInterestRate] = useState<any>("");
  let [annualPayment, setAnnualPayment] = useState<any>();
  let [dailyExpense, setDailyExpense] = useState<any>(false);
  let [halfYearlyFee, setHalfYearlyFee] = useState<any>();
  let [quarterlyFee, setQuarterlyFee] = useState<any>();
  let [yearlyFee, setYearlyFee] = useState<any>();

  let [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);

  //fields for associated Ledgers

  let [debitBankProfitLedger, setDebitBankProfitLedger] = useState<any>();
  let [creditBankProfitLedger, setCreditBankProfitLedger] = useState<any>();
  let [associatedLedgerExpense, setAssociatedLedgerExpense] = useState<any>();

  //error states for form fields
  const [mainFamilyCodeError, setMainFamilyCodeError] =
    useState<boolean>(false);
  const [mainFamilyNameError, setMainFamilyNameError] =
    useState<boolean>(false);

  const [familyCodeError, setFamilyCodeError] = useState<boolean>(false);
  const [familyNameError, setFamilyNameError] = useState<boolean>(false);

  const [subFamilyCodeError, setSubFamilyCodeError] = useState<boolean>(false);
  const [subFamilyNameError, setSubFamilyNameError] = useState<boolean>(false);

  const [childFamilyCodeError, setChildFamilyCodeError] =
    useState<boolean>(false);
  const [childFamilyNameError, setChildFamilyNameError] =
    useState<boolean>(false);

  const [glCodeError, setGlCodeError] = useState<boolean>(false);
  const [glNameError, setGlNameError] = useState<boolean>(false);

  const [currentBalanceError, setCurrentBalanceError] =
    useState<boolean>(false);
  const [relatedLedgerError, setRelatedLedgerError] = useState<boolean>(false);
  const [slabFromError, setSlabFromError] = useState<boolean>(false);
  const [slabToError, setSlabToError] = useState<boolean>(false);
  const [slabRateError, setSlabRateError] = useState<boolean>(false);
  const [slabFixedRateError, setSlabFixedRateError] = useState<boolean>(false);
  const [bankAccountNumberError, setBankAccountNumberError] =
    useState<boolean>(false);
  const [bankAccountTitleError, setBankAccountTitleError] =
    useState<boolean>(false);
  const [bankNameError, setBankNameError] = useState<boolean>(false);
  const [bankAccountBranchError, setBankAccountBranchError] =
    useState<boolean>(false);
  const [interestRateError, setInterestRateError] = useState<boolean>(false);
  const [halfYearlyFeeError, setHalfYearlyFeeError] = useState<boolean>(false);
  const [quarterlyFeeError, setQuarterlyFeeError] = useState<boolean>(false);
  const [yearlyFeeError, setYearlyFeeError] = useState<boolean>(false);

  const [debitBankProfitLedgerError, setDebitBankProfitLedgerError] =
    useState<boolean>(false);
  const [creditBankProfitLedgerError, setCreditBankProfitLedgerError] =
    useState<boolean>(false);
  const [associatedLedgerExpenseError, setAssociatedLedgerExpenseError] =
    useState<boolean>(false);

  //states to manage different card expansion in modals
  const [bankAccountCardExpanded, setBankAccountCardExpanded] =
    useState<boolean>(false);
  const [bankProfitCardExpanded, setBankProfitCardExpanded] =
    useState<boolean>(false);
  const [expenseCardExpanded, setExpenseCardExpanded] =
    useState<boolean>(false);
  const [feeSlabsCardExpanded, setFeeSlabsCardExpanded] =
    useState<boolean>(false);

  //state to store multiple slabs and display slab table
  let [slabData, setSlabData] = useState<any>();

  let [fundsData, setFundsData] = useState<any>();

  let [parentsItem, setParentsItem] = useState<any>({});

  //states to check matched at the time of adding/editing
  let [matched, setMatched] = useState<any>(false);
  let [mainFamilyMatched, setMainFamilyMatched] = useState<any>(false);
  let [familyMatched, setFamilyMatched] = useState<any>(false);
  let [subFamilyMatched, setSubFamilyMatched] = useState<any>(false);
  let [childFamilyMatched, setChildFamilyMatched] = useState<any>(false);
  let [glMatched, setGlMatched] = useState<any>(false);

  //loading states to disable buttons in add and edit modals
  let [addingLedgerLoading, setAddingLedgerLoading] = useState<boolean>(false);
  let [editingLedgerLoading, setEditingLedgerLoading] =
    useState<boolean>(false);

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    getChartOfAccountsFromBackend();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    getFundByAMcCode(amc_code);
  }, []);

  useEffect(() => {
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
              value: item.code,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
              value: item.code,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
            value: item.code,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
            value: item.code,
          },
        ]);
      }
    });
  }, [allLedgerData]);

  const getChartOfAccountsFromBackend = async () => {
    try {
      let response = await getNewChartsOfAccounts(email, amc_code);
      if (response.data.status === 200) {
        setIsAbleToRevert(true);
        // const parsed = JSON.parse(response.data.data);
        setChartOfAccounts(response.data.data);
        setMainFamily(response.data.data.main_family);
        dispatch(GetChartOfAccount(response.data.data.main_family));
        setAllLedgerData(getAllLedgers(response.data.data.main_family));
        setDisableSaveButton(true);
      } else {
        response = await getChartOfAccounts(email);
        if (response.data.status === 200) {
          setIsAbleToRevert(false);
          // const parsed = JSON.parse(response.data.data);
          setChartOfAccounts(response.data.data);
          setMainFamily(response.data.data.main_family);
          dispatch(GetChartOfAccount(response.data.data.main_family));
          setAllLedgerData(getAllLedgers(response.data.data.main_family));
          setDisableSaveButton(false);
        }
      }
    } catch (err: any) {
      setIsAbleToRevert(false);
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  const handleResetChartOfAccount = async () => {
    try {
      let response = await getChartOfAccounts(email);
      if (response.data.status === 200) {
        setIsAbleToRevert(false);
        setChartOfAccounts(response.data.data);
        setMainFamily(response.data.data.main_family);
        // dispatch(GetChartOfAccount(response.data.data.main_family));
        setAllLedgerData(getAllLedgers(response.data.data.main_family));
        setDisableSaveButton(false);
      }
    } catch (err: any) {
      setIsAbleToRevert(false);
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const saveChartsOfAccounts = async () => {
    setLoading(true);
    chartOfAccounts.main_family = mainFamily;
    let jsonObject = JSON.stringify(chartOfAccounts);
    //convert mainFamily to json and send to backend using replaceChartOfAccounts function
    const email = sessionStorage.getItem("email");
    const response = await replaceChartOfAccountsFile(
      email,
      jsonObject,
      amc_code
    );
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    await getChartOfAccountsFromBackend();
    //add code here to send complete tree to backend

    setLoading(false);
  };

  const changeActiveStatus = async () => {
    setChangingStatus(true);
    if (mainFamilyCode === changeStatusLedgerCode) {
      mainFamily.map((item, index) => {
        if (
          item.code === mainFamilyCode &&
          item.code === changeStatusLedgerCode
        ) {
          item.active = !item.active;
          if (item.family) {
            item.family.map((familyItem, familyIndex) => {
              familyItem.active = item.active;
              if (familyItem.sub_family) {
                familyItem.sub_family.map((subItem, subIndex) => {
                  subItem.active = item.active;
                  if (subItem.child_family) {
                    subItem.child_family.map((childItem, childIndex) => {
                      childItem.active = item.active;
                      if (childItem.gl) {
                        childItem.gl.map((glItem, glIndex) => {
                          glItem.active = item.active;
                        });
                      }
                    });
                  } else if (subItem.gl) {
                    subItem.gl.map((glItem, glIndex) => {
                      glItem.active = item.active;
                    });
                  }
                });
              }
            });
          }
        }
      });
    } else if (familyCode === changeStatusLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          if (
            familyItem.code === familyCode &&
            familyItem.code === changeStatusLedgerCode
          ) {
            familyItem.active = !familyItem.active;
            if (familyItem.sub_family) {
              familyItem.sub_family.map((subItem, subIndex) => {
                subItem.active = familyItem.active;
                if (subItem.child_family) {
                  subItem.child_family.map((childItem, childIndex) => {
                    childItem.active = familyItem.active;
                    if (childItem.gl) {
                      childItem.gl.map((glItem, glIndex) => {
                        glItem.active = familyItem.active;
                      });
                    }
                  });
                } else if (subItem.gl) {
                  subItem.gl.map((glItem, glIndex) => {
                    glItem.active = familyItem.active;
                  });
                }
              });
            }
          }
        });
      });
    } else if (subFamilyCode === changeStatusLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (
              subItem.code === subFamilyCode &&
              subItem.code === changeStatusLedgerCode
            ) {
              subItem.active = !subItem.active;
              if (subItem.child_family) {
                subItem.child_family.map((childItem, childIndex) => {
                  childItem.active = subItem.active;
                  if (childItem.gl) {
                    childItem.gl.map((glItem, glIndex) => {
                      glItem.active = subItem.active;
                    });
                  }
                });
              } else if (subItem.gl) {
                subItem.gl.map((glItem, glIndex) => {
                  glItem.active = subItem.active;
                });
              }
            }
          });
        });
      });
    } else if (childFamilyCode === changeStatusLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (subItem.child_family) {
              subItem.child_family.map((childItem, childIndex) => {
                if (
                  childItem.code === childFamilyCode &&
                  childItem.code === changeStatusLedgerCode
                ) {
                  childItem.active = !childItem.active;
                  if (childItem.gl) {
                    childItem.gl.map((glItem, glIndex) => {
                      glItem.active = childItem.active;
                    });
                  }
                }
              });
            }
          });
        });
      });
    } else if (glCode === changeStatusLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (subItem.child_family) {
              subItem.child_family.map((childItem, childIndex) => {
                childItem.gl.map((glItem, glIndex) => {
                  if (
                    glItem.code === glCode &&
                    glItem.code === changeStatusLedgerCode
                  ) {
                    glItem.active = !glItem.active;
                  }
                });
              });
            } else if (subItem.gl) {
              subItem.gl.map((glItem, glIndex) => {
                if (
                  glItem.code === glCode &&
                  glItem.code === changeStatusLedgerCode
                ) {
                  glItem.active = !glItem.active;
                }
              });
            }
          });
        });
      });
    }
    // toast.info("Ledger status changed sucessfully!!")
    setDisableSaveButton(false);
    setChangeStatusLedgerCode("");
    setStatusModal(false);
    setChangingStatus(false);
  };

  const handleAddingSlab = async () => {
    if (!slabFrom) {
      setSlabFromError(true);
      return toast.error("Slab From value is required!");
    }
    if (!slabTo) {
      setSlabToError(true);
      return toast.error("Slab To value is required!");
    }
    if (!slabRate) {
      setSlabRateError(true);
      return toast.error("Slab Rate is required!");
    }
    if (!slabFixedRate) {
      setSlabFixedRateError(true);
      return toast.error("Slab Fixed Amount is required!");
    }
    if (!relatedLedger) {
      setRelatedLedgerError(true);
      return toast.error("Related ledger is required!");
    }

    if (slabData) {
      if (slabData?.length > 0) {
        setSlabData((prevState) => [
          ...prevState,
          {
            amountFrom: slabFrom,
            amountTo: slabTo,
            rate: slabRate,
            fixedAmmount: slabFixedRate,
          },
        ]);
      }
    } else {
      setSlabData([
        {
          amountFrom: slabFrom,
          amountTo: slabTo,
          rate: slabRate,
          fixedAmmount: slabFixedRate,
        },
      ]);
    }
  };

  const handleFundDelete = (fundFromTable) => {
    setFundsData(
      fundsData.filter((item, index) => {
        return item.fund !== fundFromTable;
      })
    );
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if (value === true) {
      return "required-border";
    } else {
      return "";
    }
  };

  //add ledger function

  const isCodeExist = (id) => {
    try {
      const code = parseInt(id);
      for (let i = 0; i < mainFamily.length; i++) {
        if (mainFamily[i].code === code) {
          return true;
        }

        for (let j = 0; j < mainFamily[i].family.length; j++) {
          if (mainFamily[i].family[j].code === code) {
            return true;
          }

          for (let k = 0; k < mainFamily[i].family[j].sub_family.length; k++) {
            if (mainFamily[i].family[j].sub_family[k].code === code) {
              return true;
            }

            if (mainFamily[i].family[j].sub_family[k]?.gl?.length > 0) {
              for (
                let l = 0;
                l < mainFamily[i].family[j].sub_family[k].gl.length;
                l++
              ) {
                if (mainFamily[i].family[j].sub_family[k].gl[l].code === code) {
                  return true;
                }
              }
            } else {
              for (
                let l = 0;
                l < mainFamily[i].family[j].sub_family[k].child_family.length;
                l++
              ) {
                if (
                  mainFamily[i].family[j].sub_family[k].child_family[l].code ===
                  code
                ) {
                  return true;
                }

                for (
                  let m = 0;
                  m <
                  mainFamily[i].family[j].sub_family[k].child_family[l].gl
                    .length;
                  m++
                ) {
                  if (
                    mainFamily[i].family[j].sub_family[k].child_family[l].gl[m]
                      .code === code
                  ) {
                    return true;
                  }
                }
              }
            }
          }
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  const handleAddLedger = async () => {
    try {
      setAddingLedgerLoading(true);

      if (debitBankProfitLedger) {
        debitBankProfitLedger = debitBankProfitLedger.value;
      }
      if (creditBankProfitLedger) {
        creditBankProfitLedger = creditBankProfitLedger.value;
      }

      if (associatedLedgerExpense) {
        associatedLedgerExpense = associatedLedgerExpense.value;
      }

      if (relatedLedger) {
        relatedLedger = relatedLedger.value;
      }
      if ((mainFamilyFlag && !mainFamilyCode) || mainFamilyCode === "") {
        setMainFamilyCodeError(true);
        setAddingLedgerLoading(false);

        return toast.error("Main Family Code is required!");
      }
      if ((mainFamilyFlag && !mainFamilyName) || mainFamilyName === "") {
        setMainFamilyNameError(true);
        setAddingLedgerLoading(false);

        return toast.error("Main Family Name is required!");
      }

      if ((familyFlag && !familyCode) || familyCode === "") {
        setFamilyCodeError(true);
        setAddingLedgerLoading(false);

        return toast.error("Family Code is required!");
      }
      if ((familyFlag && !familyName) || familyName === "") {
        setFamilyNameError(true);
        setAddingLedgerLoading(false);

        return toast.error("Family Name is required!");
      }

      if ((subFamilyFlag && !subFamilyCode) || subFamilyCode === "") {
        setSubFamilyCodeError(true);
        setAddingLedgerLoading(false);

        return toast.error("Sub Family Code is required!");
      }
      if ((subFamilyFlag && !subFamilyName) || subFamilyName === "") {
        setSubFamilyNameError(true);
        setAddingLedgerLoading(false);

        return toast.error("Sub Family Name is required!");
      }

      if ((glFlag && !glCode) || glCode === "") {
        setGlCodeError(true);
        setAddingLedgerLoading(false);

        return toast.error("GL Code is required!");
      }
      if ((glFlag && !glName) || glName === "") {
        setGlNameError(true);
        setAddingLedgerLoading(false);

        return toast.error("GL Name is required!");
      }

      //code here to  add data first to table.
      mainFamily.map((mainItem, mainIndex) => {
        if (mainItem.code === mainFamilyCode && mainFamilyFlag) {
          if (mainItem?.family && !familyFlag) {
            mainItem.family.map((familyItem, familyIndex) => {
              if (familyItem.code === parseInt(familyCode)) {
                successfull = false;
                setAddingLedgerLoading(false);

                return toast.error("Family Already Exists!");
              } else if (mainItem.family.length === familyIndex + 1) {
                if (parseInt(familyCode) !== 0) {
                  if (childFamilyCode) {
                    if (isCodeExist(familyCode)) {
                      throw new Error("Code already exists");
                    }

                    if (isCodeExist(subFamilyCode)) {
                      throw new Error("Code already exists");
                    }

                    if (isCodeExist(childFamilyCode)) {
                      throw new Error("Code already exists");
                    }

                    if (isCodeExist(glCode)) {
                      throw new Error("Code already exists");
                    }
                    successfull = true;
                    setAddingLedgerLoading(false);
                    return mainItem.family.push({
                      code: parseInt(familyCode),
                      name: familyName,
                      active: true,
                      sub_family: [
                        {
                          code: parseInt(subFamilyCode),
                          name: subFamilyName,
                          active: true,

                          child_family: [
                            {
                              code: parseInt(childFamilyCode),
                              name: childFamilyName,
                              active: true,

                              gl: [
                                {
                                  code: parseInt(glCode),
                                  name: glName,
                                  active: true,
                                  ...(currentBalance && {
                                    balance: currentBalance,
                                  }),
                                  ...(bankAccountNumber && {
                                    account_no: bankAccountNumber,
                                  }),
                                  ...(bankAccountTitle && {
                                    account_title: bankAccountTitle,
                                  }),
                                  ...(bankName && { bank: bankName }),
                                  ...(bankAccountBranch && {
                                    branch: bankAccountBranch,
                                  }),
                                  ...(fundsData && { funds: fundsData }),
                                  ...(interestRate && {
                                    interest_rate: interestRate,
                                  }),
                                  ...(debitBankProfitLedger &&
                                    creditBankProfitLedger && {
                                      related_ledgers: [
                                        parseInt(debitBankProfitLedger),
                                        parseInt(creditBankProfitLedger),
                                      ],
                                    }),
                                  ...(dailyExpense && {
                                    daily_expense: dailyExpense,
                                  }),
                                  ...(associatedLedgerExpense && {
                                    associated_ledgers: [
                                      parseInt(associatedLedgerExpense),
                                    ],
                                  }),
                                  ...(quarterlyFee && {
                                    quarterly_fee: parseInt(quarterlyFee),
                                  }),
                                  ...(halfYearlyFee && {
                                    half_yearly_fee: parseInt(halfYearlyFee),
                                  }),
                                  ...(yearlyFee && {
                                    yearly_fee: parseInt(yearlyFee),
                                  }),
                                  ...(slabData?.length > 0 && {
                                    slabs: slabData,
                                  }),
                                  ...(relatedLedger && {
                                    related_ledgers_slabs: [
                                      parseInt(relatedLedger),
                                    ],
                                  }),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    });
                  } else {
                    if (isCodeExist(familyCode)) {
                      throw new Error("Code already exists");
                    }

                    if (isCodeExist(subFamilyCode)) {
                      throw new Error("Code already exists");
                    }

                    if (isCodeExist(glCode)) {
                      throw new Error("Code already exists");
                    }

                    successfull = true;
                    setAddingLedgerLoading(false);

                    return mainItem.family.push({
                      code: parseInt(familyCode),
                      name: familyName,
                      active: true,
                      sub_family: [
                        {
                          code: parseInt(subFamilyCode),
                          name: subFamilyName,
                          active: true,
                          gl: [
                            {
                              code: parseInt(glCode),
                              name: glName,
                              active: true,
                              ...(currentBalance && {
                                balance: currentBalance,
                              }),
                              ...(bankAccountNumber && {
                                account_no: bankAccountNumber,
                              }),
                              ...(bankAccountTitle && {
                                account_title: bankAccountTitle,
                              }),
                              ...(bankName && { bank: bankName }),
                              ...(bankAccountBranch && {
                                branch: bankAccountBranch,
                              }),
                              ...(fundsData && { funds: fundsData }),
                              ...(interestRate && {
                                interest_rate: interestRate,
                              }),
                              ...(debitBankProfitLedger &&
                                creditBankProfitLedger && {
                                  related_ledgers: [
                                    parseInt(debitBankProfitLedger),
                                    parseInt(creditBankProfitLedger),
                                  ],
                                }),
                              ...(dailyExpense && {
                                daily_expense: dailyExpense,
                              }),
                              ...(associatedLedgerExpense && {
                                associated_ledgers: [
                                  parseInt(associatedLedgerExpense),
                                ],
                              }),
                              ...(quarterlyFee && {
                                quarterly_fee: parseInt(quarterlyFee),
                              }),
                              ...(halfYearlyFee && {
                                half_yearly_fee: parseInt(halfYearlyFee),
                              }),
                              ...(yearlyFee && {
                                yearly_fee: parseInt(yearlyFee),
                              }),
                              ...(slabData?.length > 0 && {
                                slabs: slabData,
                              }),
                              ...(relatedLedger && {
                                related_ledgers_slabs: [
                                  parseInt(relatedLedger),
                                ],
                              }),
                            },
                          ],
                        },
                      ],
                    });
                  }
                }
              }
            });
          } else if (mainItem?.family && familyFlag && !subFamilyFlag) {
            // console.log('HIT Previous')
            mainItem?.family.map((familyItem, familyIndex) => {
              if (parseInt(familyCode) === familyItem.code) {
                if (familyItem?.sub_family) {
                  familyItem.sub_family.map((subItem, subIndex) => {
                    if (subItem.code === parseInt(subFamilyCode)) {
                      successfull = false;
                      setAddingLedgerLoading(false);

                      return toast.error("Sub Family Already Exists!");
                    } else if (familyItem.sub_family.length === subIndex + 1) {
                      if (parseInt(subFamilyCode) !== 0) {
                        if (childFamilyCode) {
                          if (isCodeExist(subFamilyCode)) {
                            throw new Error("Code already exists");
                          }

                          if (isCodeExist(childFamilyCode)) {
                            throw new Error("Code already exists");
                          }

                          if (isCodeExist(glCode)) {
                            throw new Error("Code already exists");
                          }

                          successfull = true;
                          setAddingLedgerLoading(false);

                          return familyItem.sub_family.push({
                            code: parseInt(subFamilyCode),
                            name: subFamilyName,
                            active: true,

                            child_family: [
                              {
                                code: parseInt(childFamilyCode),
                                name: childFamilyName,
                                active: true,

                                gl: [
                                  {
                                    code: parseInt(glCode),
                                    name: glName,
                                    active: true,
                                    ...(currentBalance && {
                                      balance: currentBalance,
                                    }),
                                    ...(bankAccountNumber && {
                                      account_no: bankAccountNumber,
                                    }),
                                    ...(bankAccountTitle && {
                                      account_title: bankAccountTitle,
                                    }),
                                    ...(bankName && { bank: bankName }),
                                    ...(bankAccountBranch && {
                                      branch: bankAccountBranch,
                                    }),
                                    ...(fundsData && { funds: fundsData }),
                                    ...(interestRate && {
                                      interest_rate: interestRate,
                                    }),
                                    ...(debitBankProfitLedger &&
                                      creditBankProfitLedger && {
                                        related_ledgers: [
                                          parseInt(debitBankProfitLedger),
                                          parseInt(creditBankProfitLedger),
                                        ],
                                      }),
                                    ...(dailyExpense && {
                                      daily_expense: dailyExpense,
                                    }),
                                    ...(associatedLedgerExpense && {
                                      associated_ledgers: [
                                        parseInt(associatedLedgerExpense),
                                      ],
                                    }),
                                    ...(quarterlyFee && {
                                      quarterly_fee: parseInt(quarterlyFee),
                                    }),
                                    ...(halfYearlyFee && {
                                      half_yearly_fee: parseInt(halfYearlyFee),
                                    }),
                                    ...(yearlyFee && {
                                      yearly_fee: parseInt(yearlyFee),
                                    }),
                                    ...(slabData?.length > 0 && {
                                      slabs: slabData,
                                    }),
                                    ...(relatedLedger && {
                                      related_ledgers_slabs: [
                                        parseInt(relatedLedger),
                                      ],
                                    }),
                                  },
                                ],
                              },
                            ],
                          });
                        } else {
                          if (isCodeExist(subFamilyCode)) {
                            throw new Error("Code already exists");
                          }

                          if (isCodeExist(glCode)) {
                            throw new Error("Code already exists");
                          }

                          successfull = true;
                          setAddingLedgerLoading(false);

                          return familyItem.sub_family.push({
                            code: parseInt(subFamilyCode),
                            name: subFamilyName,
                            active: true,
                            gl: [
                              {
                                code: parseInt(glCode),
                                name: glName,
                                active: true,
                                ...(currentBalance && {
                                  balance: currentBalance,
                                }),
                                ...(bankAccountNumber && {
                                  account_no: bankAccountNumber,
                                }),
                                ...(bankAccountTitle && {
                                  account_title: bankAccountTitle,
                                }),
                                ...(bankName && { bank: bankName }),
                                ...(bankAccountBranch && {
                                  branch: bankAccountBranch,
                                }),
                                ...(fundsData && { funds: fundsData }),
                                ...(interestRate && {
                                  interest_rate: interestRate,
                                }),
                                ...(debitBankProfitLedger &&
                                  creditBankProfitLedger && {
                                    related_ledgers: [
                                      parseInt(debitBankProfitLedger),
                                      parseInt(creditBankProfitLedger),
                                    ],
                                  }),
                                ...(dailyExpense && {
                                  daily_expense: dailyExpense,
                                }),
                                ...(associatedLedgerExpense && {
                                  associated_ledgers: [
                                    parseInt(associatedLedgerExpense),
                                  ],
                                }),
                                ...(quarterlyFee && {
                                  quarterly_fee: parseInt(quarterlyFee),
                                }),
                                ...(halfYearlyFee && {
                                  half_yearly_fee: parseInt(halfYearlyFee),
                                }),
                                ...(yearlyFee && {
                                  yearly_fee: parseInt(yearlyFee),
                                }),
                                ...(slabData?.length > 0 && {
                                  slabs: slabData,
                                }),
                                ...(relatedLedger && {
                                  related_ledgers_slabs: [
                                    parseInt(relatedLedger),
                                  ],
                                }),
                              },
                            ],
                          });
                        }
                      }
                    }
                  });
                }
              }
            });
          } else if (
            mainItem?.family &&
            familyFlag &&
            subFamilyFlag &&
            !childFamilyFlag
          ) {
            // console.log('HIT Updated')
            mainItem?.family.map((familyItem, familyIndex) => {
              if (parseInt(familyCode) === familyItem.code) {
                if (familyItem?.sub_family) {
                  familyItem.sub_family.map((subItem, subIndex) => {
                    if (subItem.code === parseInt(subFamilyCode)) {
                      if (subItem?.child_family) {
                        subItem.child_family.map((childItem, childIndex) => {
                          if (childItem.code === parseInt(childFamilyCode)) {
                            successfull = false;
                            setAddingLedgerLoading(false);

                            return toast.error("Child Family Already Exists!");
                          } else if (
                            subItem.child_family.length ===
                            childIndex + 1
                          ) {
                            if (parseInt(childFamilyCode) !== 0) {
                              if (childFamilyCode) {
                                if (isCodeExist(childFamilyCode)) {
                                  throw new Error("Code already exists");
                                }

                                if (isCodeExist(glCode)) {
                                  throw new Error("Code already exists");
                                }

                                successfull = true;
                                setAddingLedgerLoading(false);

                                return subItem.child_family.push({
                                  code: parseInt(childFamilyCode),
                                  name: childFamilyName,
                                  active: true,

                                  gl: [
                                    {
                                      code: parseInt(glCode),
                                      name: glName,
                                      active: true,
                                      ...(currentBalance && {
                                        balance: currentBalance,
                                      }),
                                      ...(bankAccountNumber && {
                                        account_no: bankAccountNumber,
                                      }),
                                      ...(bankAccountTitle && {
                                        account_title: bankAccountTitle,
                                      }),
                                      ...(bankName && { bank: bankName }),
                                      ...(bankAccountBranch && {
                                        branch: bankAccountBranch,
                                      }),
                                      ...(fundsData && { funds: fundsData }),
                                      ...(interestRate && {
                                        interest_rate: interestRate,
                                      }),
                                      ...(debitBankProfitLedger &&
                                        creditBankProfitLedger && {
                                          related_ledgers: [
                                            parseInt(debitBankProfitLedger),
                                            parseInt(creditBankProfitLedger),
                                          ],
                                        }),
                                      ...(dailyExpense && {
                                        daily_expense: dailyExpense,
                                      }),
                                      ...(associatedLedgerExpense && {
                                        associated_ledgers: [
                                          parseInt(associatedLedgerExpense),
                                        ],
                                      }),
                                      ...(quarterlyFee && {
                                        quarterly_fee: parseInt(quarterlyFee),
                                      }),
                                      ...(halfYearlyFee && {
                                        half_yearly_fee:
                                          parseInt(halfYearlyFee),
                                      }),
                                      ...(yearlyFee && {
                                        yearly_fee: parseInt(yearlyFee),
                                      }),
                                      ...(slabData?.length > 0 && {
                                        slabs: slabData,
                                      }),
                                      ...(relatedLedger && {
                                        related_ledgers_slabs: [
                                          parseInt(relatedLedger),
                                        ],
                                      }),
                                    },
                                  ],
                                });
                              } else {
                                if (isCodeExist(glCode)) {
                                  throw new Error("Code already exists");
                                }

                                successfull = true;
                                setAddingLedgerLoading(false);

                                if (!subItem?.gl) {
                                  subItem.gl = [];
                                }
                                return subItem.gl.push({
                                  code: parseInt(glCode),
                                  name: glName,
                                  active: true,
                                  ...(currentBalance && {
                                    balance: currentBalance,
                                  }),
                                  ...(bankAccountNumber && {
                                    account_no: bankAccountNumber,
                                  }),
                                  ...(bankAccountTitle && {
                                    account_title: bankAccountTitle,
                                  }),
                                  ...(bankName && { bank: bankName }),
                                  ...(bankAccountBranch && {
                                    branch: bankAccountBranch,
                                  }),
                                  ...(fundsData && { funds: fundsData }),
                                  ...(interestRate && {
                                    interest_rate: interestRate,
                                  }),
                                  ...(debitBankProfitLedger &&
                                    creditBankProfitLedger && {
                                      related_ledgers: [
                                        parseInt(debitBankProfitLedger),
                                        parseInt(creditBankProfitLedger),
                                      ],
                                    }),
                                  ...(dailyExpense && {
                                    daily_expense: dailyExpense,
                                  }),
                                  ...(associatedLedgerExpense && {
                                    associated_ledgers: [
                                      parseInt(associatedLedgerExpense),
                                    ],
                                  }),
                                  ...(quarterlyFee && {
                                    quarterly_fee: parseInt(quarterlyFee),
                                  }),
                                  ...(halfYearlyFee && {
                                    half_yearly_fee: parseInt(halfYearlyFee),
                                  }),
                                  ...(yearlyFee && {
                                    yearly_fee: parseInt(yearlyFee),
                                  }),
                                  ...(slabData?.length > 0 && {
                                    slabs: slabData,
                                  }),
                                  ...(relatedLedger && {
                                    related_ledgers_slabs: [
                                      parseInt(relatedLedger),
                                    ],
                                  }),
                                });
                              }
                            }
                          }
                        });
                      } else if (
                        parseInt(childFamilyCode) !== 0 &&
                        !isNaN(parseInt(childFamilyCode))
                      ) {
                        if (isCodeExist(childFamilyCode || "")) {
                          throw new Error("Code already exists");
                        }

                        if (isCodeExist(glCode || "")) {
                          throw new Error("Code already exists");
                        }
                        successfull = true;
                        setAddingLedgerLoading(false);

                        return (subItem.child_family = [
                          {
                            code: parseInt(childFamilyCode),
                            name: childFamilyName,
                            active: true,

                            gl: [
                              {
                                code: parseInt(glCode),
                                name: glName,
                                active: true,
                                ...(currentBalance && {
                                  balance: currentBalance,
                                }),
                                ...(bankAccountNumber && {
                                  account_no: bankAccountNumber,
                                }),
                                ...(bankAccountTitle && {
                                  account_title: bankAccountTitle,
                                }),
                                ...(bankName && { bank: bankName }),
                                ...(bankAccountBranch && {
                                  branch: bankAccountBranch,
                                }),
                                ...(fundsData && { funds: fundsData }),
                                ...(interestRate && {
                                  interest_rate: interestRate,
                                }),
                                ...(debitBankProfitLedger &&
                                  creditBankProfitLedger && {
                                    related_ledgers: [
                                      parseInt(debitBankProfitLedger),
                                      parseInt(creditBankProfitLedger),
                                    ],
                                  }),
                                ...(dailyExpense && {
                                  daily_expense: dailyExpense,
                                }),
                                ...(associatedLedgerExpense && {
                                  associated_ledgers: [
                                    parseInt(associatedLedgerExpense),
                                  ],
                                }),
                                ...(quarterlyFee && {
                                  quarterly_fee: parseInt(quarterlyFee),
                                }),
                                ...(halfYearlyFee && {
                                  half_yearly_fee: parseInt(halfYearlyFee),
                                }),
                                ...(yearlyFee && {
                                  yearly_fee: parseInt(yearlyFee),
                                }),
                                ...(slabData?.length > 0 && {
                                  slabs: slabData,
                                }),
                                ...(relatedLedger && {
                                  related_ledgers_slabs: [
                                    parseInt(relatedLedger),
                                  ],
                                }),
                              },
                            ],
                          },
                        ]);
                      } else if (
                        (parseInt(childFamilyCode) === 0 ||
                          isNaN(parseInt(childFamilyCode))) &&
                        parseInt(glCode) !== 0
                      ) {
                        subItem.gl.map((glItem, glIndex) => {
                          if (glItem.code === glCode) {
                            successfull = false;
                            setAddingLedgerLoading(false);

                            return toast.error(
                              "This GL Code already exists!!!!"
                            );
                          } else if (subItem.gl.length === glIndex + 1) {
                            if (isCodeExist(glCode)) {
                              throw new Error("Code already exists");
                            }
                            successfull = true;
                            setAddingLedgerLoading(false);

                            return subItem.gl.push({
                              code: parseInt(glCode),
                              name: glName,
                              active: true,
                              ...(currentBalance && {
                                balance: currentBalance,
                              }),
                              ...(bankAccountNumber && {
                                account_no: bankAccountNumber,
                              }),
                              ...(bankAccountTitle && {
                                account_title: bankAccountTitle,
                              }),
                              ...(bankName && { bank: bankName }),
                              ...(bankAccountBranch && {
                                branch: bankAccountBranch,
                              }),
                              ...(fundsData && { funds: fundsData }),
                              ...(interestRate && {
                                interest_rate: interestRate,
                              }),
                              ...(debitBankProfitLedger &&
                                creditBankProfitLedger && {
                                  related_ledgers: [
                                    parseInt(debitBankProfitLedger),
                                    parseInt(creditBankProfitLedger),
                                  ],
                                }),
                              ...(dailyExpense && {
                                daily_expense: dailyExpense,
                              }),
                              ...(associatedLedgerExpense && {
                                associated_ledgers: [
                                  parseInt(associatedLedgerExpense),
                                ],
                              }),
                              ...(quarterlyFee && {
                                quarterly_fee: parseInt(quarterlyFee),
                              }),
                              ...(halfYearlyFee && {
                                half_yearly_fee: parseInt(halfYearlyFee),
                              }),
                              ...(yearlyFee && {
                                yearly_fee: parseInt(yearlyFee),
                              }),
                              ...(slabData?.length > 0 && {
                                slabs: slabData,
                              }),
                              ...(relatedLedger && {
                                related_ledgers_slabs: [
                                  parseInt(relatedLedger),
                                ],
                              }),
                            });
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          } else if (
            mainItem?.family &&
            familyFlag &&
            subFamilyFlag &&
            childFamilyFlag
          ) {
            //Temp: added by dev-2
            mainItem?.family.map((familyItem, familyIndex) => {
              if (parseInt(familyCode) === familyItem.code) {
                if (familyItem?.sub_family) {
                  familyItem.sub_family.map((subItem, subIndex) => {
                    if (subItem.code === parseInt(subFamilyCode)) {
                      if (subItem?.child_family) {
                        subItem.child_family.map((childItem, childIndex) => {
                          if (
                            childItem?.gl
                              .map((item) => item.code)
                              .includes(parseInt(glCode))
                          ) {
                            return toast.error("Code already exists");
                          }

                          if (childItem.code === parseInt(childFamilyCode)) {
                            if (isCodeExist(glCode)) {
                              throw new Error("Code already exists");
                            }

                            successfull = true;
                            setAddingLedgerLoading(false);

                            return childItem?.gl.push({
                              code: parseInt(glCode),
                              name: glName,
                              active: true,
                            });
                          }
                        });
                      } else if (
                        parseInt(childFamilyCode) !== 0 &&
                        !isNaN(parseInt(childFamilyCode))
                      ) {
                        if (isCodeExist(childFamilyCode)) {
                          throw new Error("Code already exists");
                        }

                        if (isCodeExist(glCode)) {
                          throw new Error("Code already exists");
                        }
                        successfull = true;
                        setAddingLedgerLoading(false);
                        return (subItem.child_family = [
                          {
                            code: parseInt(childFamilyCode),
                            name: childFamilyName,
                            active: true,

                            gl: [
                              {
                                code: parseInt(glCode),
                                name: glName,
                                active: true,
                                ...(currentBalance && {
                                  balance: currentBalance,
                                }),
                                ...(bankAccountNumber && {
                                  account_no: bankAccountNumber,
                                }),
                                ...(bankAccountTitle && {
                                  account_title: bankAccountTitle,
                                }),
                                ...(bankName && { bank: bankName }),
                                ...(bankAccountBranch && {
                                  branch: bankAccountBranch,
                                }),
                                ...(fundsData && { funds: fundsData }),
                                ...(interestRate && {
                                  interest_rate: interestRate,
                                }),
                                ...(debitBankProfitLedger &&
                                  creditBankProfitLedger && {
                                    related_ledgers: [
                                      parseInt(debitBankProfitLedger),
                                      parseInt(creditBankProfitLedger),
                                    ],
                                  }),
                                ...(dailyExpense && {
                                  daily_expense: dailyExpense,
                                }),
                                ...(associatedLedgerExpense && {
                                  associated_ledgers: [
                                    parseInt(associatedLedgerExpense),
                                  ],
                                }),
                                ...(quarterlyFee && {
                                  quarterly_fee: parseInt(quarterlyFee),
                                }),
                                ...(halfYearlyFee && {
                                  half_yearly_fee: parseInt(halfYearlyFee),
                                }),
                                ...(yearlyFee && {
                                  yearly_fee: parseInt(yearlyFee),
                                }),
                                ...(slabData?.length > 0 && {
                                  slabs: slabData,
                                }),
                                ...(relatedLedger && {
                                  related_ledgers_slabs: [
                                    parseInt(relatedLedger),
                                  ],
                                }),
                              },
                            ],
                          },
                        ]);
                      } else if (
                        (parseInt(childFamilyCode) === 0 ||
                          isNaN(parseInt(childFamilyCode))) &&
                        parseInt(glCode) !== 0
                      ) {
                        subItem.gl.map((glItem, glIndex) => {
                          if (glItem.code === glCode) {
                            successfull = false;
                            setAddingLedgerLoading(false);

                            return toast.error(
                              "This GL Code already exists!!!!"
                            );
                          } else if (subItem.gl.length === glIndex + 1) {
                            if (isCodeExist(glCode)) {
                              throw new Error("Code already exists");
                            }

                            successfull = true;
                            setAddingLedgerLoading(false);

                            return subItem.gl.push({
                              code: parseInt(glCode),
                              name: glName,
                              active: true,
                              ...(currentBalance && {
                                balance: currentBalance,
                              }),
                              ...(bankAccountNumber && {
                                account_no: bankAccountNumber,
                              }),
                              ...(bankAccountTitle && {
                                account_title: bankAccountTitle,
                              }),
                              ...(bankName && { bank: bankName }),
                              ...(bankAccountBranch && {
                                branch: bankAccountBranch,
                              }),
                              ...(fundsData && { funds: fundsData }),
                              ...(interestRate && {
                                interest_rate: interestRate,
                              }),
                              ...(debitBankProfitLedger &&
                                creditBankProfitLedger && {
                                  related_ledgers: [
                                    parseInt(debitBankProfitLedger),
                                    parseInt(creditBankProfitLedger),
                                  ],
                                }),
                              ...(dailyExpense && {
                                daily_expense: dailyExpense,
                              }),
                              ...(associatedLedgerExpense && {
                                associated_ledgers: [
                                  parseInt(associatedLedgerExpense),
                                ],
                              }),
                              ...(quarterlyFee && {
                                quarterly_fee: parseInt(quarterlyFee),
                              }),
                              ...(halfYearlyFee && {
                                half_yearly_fee: parseInt(halfYearlyFee),
                              }),
                              ...(yearlyFee && {
                                yearly_fee: parseInt(yearlyFee),
                              }),
                              ...(slabData?.length > 0 && {
                                slabs: slabData,
                              }),
                              ...(relatedLedger && {
                                related_ledgers_slabs: [
                                  parseInt(relatedLedger),
                                ],
                              }),
                            });
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
        } else if (mainItem.code === mainFamilyCode && !mainFamilyFlag) {
          setAddingLedgerLoading(false);

          return toast.error("Main Family by this code already exists!");
        } else if (!mainFamilyFlag) {
          if (mainItem.code === parseInt(mainFamilyCode)) {
            successfull = false;
            setAddingLedgerLoading(false);

            throw new Error("Main Family Already Exists!");
          } else if (mainFamily.length === mainIndex + 1) {
            if (parseInt(familyCode) !== 0) {
              if (isCodeExist(mainFamilyCode)) {
                throw new Error("Code already exists");
              }

              if (isCodeExist(familyCode)) {
                throw new Error("Code already exists");
              }

              if (isCodeExist(subFamilyCode)) {
                throw new Error("Code already exists");
              }

              if (isCodeExist(childFamilyCode)) {
                throw new Error("Code already exists");
              }

              if (isCodeExist(glCode)) {
                throw new Error("Code already exists");
              }

              successfull = true;
              setAddingLedgerLoading(false);

              return mainFamily.push({
                code: parseInt(mainFamilyCode),
                name: mainFamilyName,
                active: true,

                family: [
                  {
                    code: parseInt(familyCode),
                    name: familyName,
                    active: true,

                    sub_family: [
                      {
                        code: parseInt(subFamilyCode),
                        name: subFamilyName,
                        active: true,

                        child_family: [
                          {
                            code: parseInt(childFamilyCode),
                            name: childFamilyName,
                            active: true,

                            gl: [
                              {
                                code: parseInt(glCode),
                                name: glName,
                                active: true,
                                ...(currentBalance && {
                                  balance: currentBalance,
                                }),
                                ...(bankAccountNumber && {
                                  account_no: bankAccountNumber,
                                }),
                                ...(bankAccountTitle && {
                                  account_title: bankAccountTitle,
                                }),
                                ...(bankName && { bank: bankName }),
                                ...(bankAccountBranch && {
                                  branch: bankAccountBranch,
                                }),
                                ...(fundsData && { funds: fundsData }),
                                ...(interestRate && {
                                  interest_rate: interestRate,
                                }),
                                ...(debitBankProfitLedger &&
                                  creditBankProfitLedger && {
                                    related_ledgers: [
                                      parseInt(debitBankProfitLedger),
                                      parseInt(creditBankProfitLedger),
                                    ],
                                  }),
                                ...(dailyExpense && {
                                  daily_expense: dailyExpense,
                                }),
                                ...(associatedLedgerExpense && {
                                  associated_ledgers: [
                                    parseInt(associatedLedgerExpense),
                                  ],
                                }),
                                ...(quarterlyFee && {
                                  quarterly_fee: parseInt(quarterlyFee),
                                }),
                                ...(halfYearlyFee && {
                                  half_yearly_fee: parseInt(halfYearlyFee),
                                }),
                                ...(yearlyFee && {
                                  yearly_fee: parseInt(yearlyFee),
                                }),
                                ...(slabData?.length > 0 && {
                                  slabs: slabData,
                                }),
                                ...(relatedLedger && {
                                  related_ledgers_slabs: [
                                    parseInt(relatedLedger),
                                  ],
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              });
            }
          }
        }
      });

      //show success or error toasts
      if (successfull) {
        //  toast.success("Ledger added successfully!");
        setDisableSaveButton(false);
        setAddingParent(false);

        setBankAccountCardExpanded(false);
        setExpenseCardExpanded(false);
        setBankProfitCardExpanded(false);
        setFeeSlabsCardExpanded(false);

        setMainFamilyCode("");
        setMainFamilyCodeError(false);

        setMainFamilyName("");
        setMainFamilyNameError(false);

        setMainFamilyFlag(false);

        setFamilyCode("");
        setFamilyCodeError(false);

        setFamilyName("");
        setFamilyNameError(false);

        setFamilyFlag(false);

        setSubFamilyCode("");
        setSubFamilyCodeError(false);

        setSubFamilyFlag(false);

        setSubFamilyName("");
        setSubFamilyNameError(false);

        setChildFamilyCode("");
        setChildFamilyCodeError(false);

        setChildFamilyName("");
        setChildFamilyNameError(false);

        setChildFamilyFlag(false);

        setGlCode("");
        setGlCodeError(false);
        setGlName("");
        setGlNameError(false);

        setGlFlag(false);

        setCurrentBalance("");
        setAssociatedAccounts("");
        setRelatedLedger("");
        setSlabFrom("");
        setSlabTo("");
        setSlabRate("");
        setSlabFixedRate("");
        setBankAccountNumber("");
        setBankAccountTitle("");
        setBankName("");
        setBankAccountBranch("");
        setBankAccountFund("");
        setInterestRate("");
        setAnnualPayment("");
        setDailyExpense(false);
        setHalfYearlyFee("");
        setQuarterlyFee("");
        setYearlyFee("");
        setDebitBankProfitLedger("");
        setCreditBankProfitLedger("");
        setAssociatedLedgerExpense("");
        setSlabData("");
        setFundsData("");

        setParentsItem({});

        setViewAddModal(false);
        setAddingLedgerLoading(false);
      } else {
        toast.error("There was a problem adding ledger");
        setAddingLedgerLoading(false);
      }
    } catch (error: any) {
      successfull = false;
      setAddingLedgerLoading(false);
      toast.error(error.message || "There was a problem adding ledger");
    }
  };

  // edit Ledger functions
  const handleEditLedger = async () => {
    setEditingLedgerLoading(true);

    if (mainFamilyCode === editLedgerCode) {
      mainFamily.map((mainItem, index) => {
        if (
          mainItem.code === mainFamilyCode &&
          mainItem.code === editLedgerCode
        ) {
          mainItem.name = mainFamilyName;
        }
      });
    } else if (familyCode === editLedgerCode) {
      mainFamily.map((mainItem, index) => {
        mainItem.family.map((familyItem, familyIndex) => {
          if (
            familyItem.code === familyCode &&
            familyItem.code === editLedgerCode
          ) {
            familyItem.name = familyName;
          }
        });
      });
    } else if (subFamilyCode === editLedgerCode) {
      mainFamily.map((mainItem, index) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (
              subItem.code === subFamilyCode &&
              subItem.code === editLedgerCode
            ) {
              subItem.name = subFamilyName;
            }
          });
        });
      });
    } else if (childFamilyCode === editLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (subItem.child_family) {
              subItem.child_family.map((childItem, childIndex) => {
                if (
                  childItem.code === childFamilyCode &&
                  childItem.code === editLedgerCode
                ) {
                  childItem.name = childFamilyName;
                }
              });
            }
          });
        });
      });
    } else if (glCode === editLedgerCode) {
      mainFamily.map((mainItem, mainIndex) => {
        mainItem.family.map((familyItem, familyIndex) => {
          familyItem.sub_family.map((subItem, subIndex) => {
            if (subItem.child_family) {
              subItem.child_family.map((childItem, childIndex) => {
                childItem.gl.map((glItem, glIndex) => {
                  if (
                    glItem.code === glCode &&
                    glItem.code === editLedgerCode
                  ) {
                    glItem.name = glName;
                    if (currentBalance && currentBalance !== "") {
                      glItem.balance = currentBalance;
                    }
                    if (bankAccountNumber && bankAccountNumber !== "") {
                      glItem.account_no = bankAccountNumber;
                    }
                    if (bankAccountTitle && bankAccountTitle !== "") {
                      glItem.account_title = bankAccountTitle;
                    }
                    if (bankName && bankName !== "") {
                      glItem.bank = bankName;
                    }
                    if (bankAccountBranch && bankAccountBranch !== "") {
                      glItem.branch = bankAccountBranch;
                    }
                    if (bankAccountFund && bankAccountFund !== "") {
                      glItem.funds = bankAccountFund;
                    }
                    if (interestRate && interestRate !== "") {
                      glItem.interest_rate = interestRate;
                    }
                    if (
                      debitBankProfitLedger &&
                      debitBankProfitLedger !== "" &&
                      creditBankProfitLedger &&
                      creditBankProfitLedger !== ""
                    ) {
                      glItem.related_ledgers = [
                        parseInt(debitBankProfitLedger),
                        parseInt(creditBankProfitLedger),
                      ];
                    }
                    if (dailyExpense && dailyExpense !== "") {
                      glItem.daily_expense = dailyExpense;
                    }
                    if (
                      associatedLedgerExpense &&
                      associatedLedgerExpense !== ""
                    ) {
                      glItem.associated_ledgers = [
                        parseInt(associatedLedgerExpense),
                      ];
                    }
                    if (quarterlyFee && quarterlyFee !== "") {
                      glItem.quarterly_fee = parseInt(quarterlyFee);
                    }
                    if (halfYearlyFee && halfYearlyFee !== "") {
                      glItem.half_yearly_fee = parseInt(halfYearlyFee);
                    }
                    if (yearlyFee && yearlyFee !== "") {
                      glItem.yearly_fee = parseInt(yearlyFee);
                    }
                    if (slabData && slabData.length > 0) {
                      glItem.slabs = slabData;
                    }
                    if (fundsData && fundsData.length > 0) {
                      glItem.funds = fundsData;
                    }
                    if (relatedLedger && relatedLedger !== "") {
                      glItem.related_ledgers_slabs = [parseInt(relatedLedger)];
                    }
                  }
                });
              });
            } else if (subItem.gl) {
              subItem.gl.map((glItem, glIndex) => {
                if (glItem.code === glCode && glItem.code === editLedgerCode) {
                  glItem.name = glName;
                  if (currentBalance && currentBalance !== "") {
                    glItem.balance = currentBalance;
                  }
                  if (bankAccountNumber && bankAccountNumber !== "") {
                    glItem.account_no = bankAccountNumber;
                  }
                  if (bankAccountTitle && bankAccountTitle !== "") {
                    glItem.account_title = bankAccountTitle;
                  }
                  if (bankName && bankName !== "") {
                    glItem.bank = bankName;
                  }
                  if (bankAccountBranch && bankAccountBranch !== "") {
                    glItem.branch = bankAccountBranch;
                  }
                  if (interestRate && interestRate !== "") {
                    glItem.interest_rate = interestRate;
                  }
                  if (
                    debitBankProfitLedger &&
                    debitBankProfitLedger !== "" &&
                    creditBankProfitLedger &&
                    creditBankProfitLedger !== ""
                  ) {
                    glItem.related_ledgers = [
                      parseInt(debitBankProfitLedger),
                      parseInt(creditBankProfitLedger),
                    ];
                  }
                  if (dailyExpense && dailyExpense !== "") {
                    glItem.daily_expense = dailyExpense;
                  }
                  if (
                    associatedLedgerExpense &&
                    associatedLedgerExpense !== ""
                  ) {
                    glItem.associated_ledgers = [
                      parseInt(associatedLedgerExpense),
                    ];
                  }
                  if (quarterlyFee && quarterlyFee !== "") {
                    glItem.quarterly_fee = parseInt(quarterlyFee);
                  }
                  if (halfYearlyFee && halfYearlyFee !== "") {
                    glItem.half_yearly_fee = parseInt(halfYearlyFee);
                  }
                  if (yearlyFee && yearlyFee !== "") {
                    glItem.yearly_fee = parseInt(yearlyFee);
                  }
                  if (slabData && slabData.length > 0) {
                    glItem.slabs = slabData;
                  }
                  if (fundsData && fundsData.length > 0) {
                    glItem.funds = fundsData;
                  }
                  if (relatedLedger && relatedLedger !== "") {
                    glItem.related_ledgers_slabs = [parseInt(relatedLedger)];
                  }
                }
              });
            }
          });
        });
      });
    }
    //toast.success("Ledger Updated Successfuly!");
    setViewEditModal(false);
    setViewOnly(false);
    setEditingLedgerLoading(false);
    setDisableSaveButton(false);
  };

  const [viewAddModal, setViewAddModal] = useState(false);

  const renderAddModal = () => {
    if (viewAddModal) {
      return (
        <Modal
          className=""
          dialogClassName="announcementModal"
          show={true}
          size="xl"
          backdrop={true}
          onHide={() => {
            setAddingParent(false);
            setBankAccountCardExpanded(false);
            setExpenseCardExpanded(false);
            setBankProfitCardExpanded(false);
            setFeeSlabsCardExpanded(false);

            setMainFamilyCode("");
            setMainFamilyCodeError(false);

            setMainFamilyName("");
            setMainFamilyNameError(false);

            setMainFamilyFlag(false);

            setFamilyCode("");
            setFamilyCodeError(false);

            setFamilyName("");
            setFamilyNameError(false);

            setFamilyFlag(false);

            setSubFamilyCode("");
            setSubFamilyCodeError(false);

            setSubFamilyFlag(false);

            setSubFamilyName("");
            setSubFamilyNameError(false);

            setChildFamilyCode("");
            setChildFamilyCodeError(false);

            setChildFamilyName("");
            setChildFamilyNameError(false);

            setChildFamilyFlag(false);

            setGlCode("");
            setGlCodeError(false);
            setGlName("");
            setGlNameError(false);

            setGlFlag(false);
            setCurrentBalance("");
            setAssociatedAccounts("");
            setRelatedLedger("");
            setSlabFrom("");
            setSlabTo("");
            setSlabRate("");
            setSlabFixedRate("");
            setBankAccountNumber("");
            setBankAccountTitle("");
            setBankName("");
            setBankAccountBranch("");
            setBankAccountFund("");
            setInterestRate("");
            setAnnualPayment("");
            setDailyExpense(false);
            setHalfYearlyFee("");
            setQuarterlyFee("");
            setYearlyFee("");
            setDebitBankProfitLedger("");
            setCreditBankProfitLedger("");
            setAssociatedLedgerExpense("");
            setSlabData("");
            setFundsData("");

            setParentsItem({});
            setViewAddModal(false);
          }}
        >
          <ToastContainer />

          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setAddingParent(false);
                setBankAccountCardExpanded(false);
                setExpenseCardExpanded(false);
                setBankProfitCardExpanded(false);
                setFeeSlabsCardExpanded(false);

                setMainFamilyCode("");
                setMainFamilyCodeError(false);

                setMainFamilyName("");
                setMainFamilyNameError(false);

                setMainFamilyFlag(false);

                setFamilyCode("");
                setFamilyCodeError(false);

                setFamilyName("");
                setFamilyNameError(false);

                setFamilyFlag(false);

                setSubFamilyCode("");
                setSubFamilyCodeError(false);

                setSubFamilyFlag(false);

                setSubFamilyName("");
                setSubFamilyNameError(false);

                setChildFamilyCode("");
                setChildFamilyCodeError(false);

                setChildFamilyName("");
                setChildFamilyNameError(false);

                setChildFamilyFlag(false);

                setGlCode("");
                setGlCodeError(false);
                setGlName("");
                setGlNameError(false);

                setGlFlag(false);

                setCurrentBalance("");
                setAssociatedAccounts("");
                setRelatedLedger("");
                setSlabFrom("");
                setSlabTo("");
                setSlabRate("");
                setSlabFixedRate("");
                setBankAccountNumber("");
                setBankAccountTitle("");
                setBankName("");
                setBankAccountBranch("");
                setBankAccountFund("");
                setInterestRate("");
                setAnnualPayment("");
                setDailyExpense(false);
                setHalfYearlyFee("");
                setQuarterlyFee("");
                setYearlyFee("");
                setDebitBankProfitLedger("");
                setCreditBankProfitLedger("");
                setAssociatedLedgerExpense("");
                setSlabData("");
                setFundsData("");

                setParentsItem({});
                setViewAddModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up d-flex align-items-center mt-2">
              {/* Replace this with family logic tree */}
              Add {addingParent ? null : "Ledger After "}
              {addingParent ? (
                "Main Ledger"
              ) : (
                // <>
                <nav aria-label="breadcrumb" style={{ marginLeft: "8px" }}>
                  <div className="hidden">Adding Ledger After</div>
                  <ol className="breadcrumb">
                    {mainFamilyCode > 0 && mainFamilyFlag && (
                      <li className="breadcrumb-item text-primary">
                        <span>{mainFamilyCode}</span>&nbsp;-&nbsp;
                        {mainFamilyName}
                      </li>
                    )}
                    {familyCode > 0 && familyFlag && (
                      <li className="breadcrumb-item text-primary">
                        <span>{familyCode}</span>&nbsp;-&nbsp;{familyName}
                      </li>
                    )}

                    {subFamilyCode > 0 && subFamilyFlag && (
                      <li className="breadcrumb-item text-primary">
                        <span>{subFamilyCode}</span>&nbsp;-&nbsp;{subFamilyName}
                      </li>
                    )}
                    {childFamilyCode > 0 && childFamilyFlag && (
                      <li className="breadcrumb-item text-primary">
                        <span>{childFamilyCode}</span>&nbsp;-&nbsp;
                        {childFamilyName}
                      </li>
                    )}
                    {glCode > 0 && glFlag && (
                      <li className="breadcrumb-item active">
                        <span>{glCode}</span>&nbsp;-&nbsp;{glName}
                      </li>
                    )}
                    <li className="breadcrumb-item text-primary"></li>
                  </ol>
                </nav>
                /* <span
                    className="text-primary"
                    style={{ fontSize: "1.25rem" }}
                  >
                    &nbsp;
                    <b>{addModalParentLedgerCode}</b>
                  </span>
                  <span
                    className="text-primary"
                    style={{ fontSize: "1.25rem" }}
                  >
                    &nbsp;
                    <b>{addModalParentLedgerName}</b>
                  </span>
                 </> */
              )}
            </h6>
          </div>

          <div className="modal-body">
            {/* Required Fields to create ledger */}
            <ReactTooltip
              textColor="white"
              backgroundColor="black"
              effect="float"
            />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title text-primary">Ledger Fields</h4>
              </div>
              <div className="card-body">
                <Row>
                  <Col>
                    {!mainFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger main family code"
                      >
                        <div>Main Family Code*</div>

                        <input
                          type="number"
                          placeholder="Main Family Code"
                          value={mainFamilyCode}
                          className={`form-control ${ErrorBorder(
                            mainFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setMainFamilyCodeError(false);
                            setMainFamilyCode(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {!familyFlag && (
                      <div className="form-group" data-tip="Ledger family code">
                        Family Code*
                        <input
                          type="number"
                          placeholder="Family Code"
                          value={familyCode}
                          className={`form-control ${ErrorBorder(
                            familyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setFamilyCodeError(false);
                            setFamilyCode(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!subFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger Sub Family Code"
                      >
                        <div>Sub Family Code*</div>

                        <input
                          type="number"
                          placeholder="Sub Family Code"
                          value={subFamilyCode}
                          className={`form-control ${ErrorBorder(
                            subFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setSubFamilyCodeError(false);
                            setSubFamilyCode(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {!childFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger Child Family Code or GL Code"
                      >
                        <div>Child Family Code</div>

                        <input
                          type="number"
                          placeholder="Child Family Code"
                          value={childFamilyCode}
                          className={`form-control ${ErrorBorder(
                            childFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setChildFamilyCodeError(false);
                            setChildFamilyCode(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!glFlag && (
                      <div className="form-group" data-tip="GL Code">
                        GL Code*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <input
                          type="number"
                          placeholder="GL Code"
                          value={glCode}
                          className={`form-control ${ErrorBorder(glCodeError)}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setGlCodeError(false);
                            setGlCode(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </Col>

                  <Col>
                    {!mainFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger main family name"
                      >
                        <div>Main Family Name*</div>
                        <input
                          type="text"
                          placeholder="Main family name"
                          value={mainFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            mainFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setMainFamilyNameError(false);
                            setMainFamilyName(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!familyFlag && (
                      <div className="form-group" data-tip="Ledger family name">
                        <div>Family Name*</div>
                        <input
                          type="text"
                          placeholder="Family Name"
                          value={familyName}
                          className={`form-control w-100 ${ErrorBorder(
                            familyNameError
                          )}`}
                          onChange={(e) => {
                            setFamilyNameError(false);
                            setFamilyName(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!subFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger sub family name"
                      >
                        <div>Sub Family Name*</div>
                        <input
                          type="text"
                          placeholder="Ledger sub family name"
                          value={subFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            subFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setSubFamilyNameError(false);
                            setSubFamilyName(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!childFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger child family name"
                      >
                        <div>Child Family Name</div>
                        <input
                          type="text"
                          placeholder="Ledger child family code"
                          value={childFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            childFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setChildFamilyNameError(false);
                            setChildFamilyName(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {!glFlag && (
                      <div className="form-group" data-tip="GL name">
                        <div>GL name</div>
                        <input
                          type="text"
                          placeholder="GL Name"
                          value={glName}
                          className={`form-control w-100 ${ErrorBorder(
                            glNameError
                          )}`}
                          onChange={(e) => {
                            setGlNameError(false);
                            setGlName(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <Row>{/* Optional Fields */}</Row>
            <div>
              <div>
                <h4 className="title text-primary">Optional Fields</h4>
              </div>
              <div>
                <Row>
                  <Col>
                    {/* Card for bank account
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title">Bank Account</div>
                        {bankAccountCardExpanded ? (
                          <div>
                            <AiOutlineArrowUp
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              data-tip="Expand"
                              onClick={() => setBankAccountCardExpanded(false)}
                            />
                          </div>
                        ) : (
                          <div>
                            <AiOutlineArrowDown
                              className="text-primary"
                              data-tip="Collapse"
                              style={{ cursor: "pointer" }}
                              onClick={() => setBankAccountCardExpanded(true)}
                            />{" "}
                          </div>
                        )}
                      </div>
                      {bankAccountCardExpanded ? (
                        <div className="card-body">
                          <div
                            className="form-group"
                            data-tip="Current balance of this ledger"
                          >
                            <div>Current Balance</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Current Balance for this ledger"
                              value={currentBalance}
                              className={`form-control w-100 ${ErrorBorder(
                                currentBalanceError
                              )}`}
                              style={{ textAlign: "left" }}
                              onValueChange={(e) => {
                                setCurrentBalanceError(false);
                                setCurrentBalance(e.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Bank Account # for this ledger"
                          >
                            <div>Bank Account #</div>
                            <input
                              type="text"
                              placeholder="Bank Account # for this ledger"
                              value={bankAccountNumber}
                              className={`form-control w-100 ${ErrorBorder(
                                bankAccountNumberError
                              )}`}
                              onChange={(e) => {
                                setBankAccountNumberError(false);
                                setBankAccountNumber(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Account title for entered bank account title"
                          >
                            <div>Bank Account Title</div>
                            <input
                              type="text"
                              placeholder="Bank Account title e.g John Doe"
                              value={bankAccountTitle}
                              className={`form-control w-100 ${ErrorBorder(
                                bankAccountTitleError
                              )}`}
                              onChange={(e) => {
                                setBankAccountTitleError(false);
                                setBankAccountTitle(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Name of the bank the account is in"
                          >
                            <div>Bank Name</div>
                            <input
                              type="text"
                              placeholder="Bank Name e.g. UBL, HBL, SONERI"
                              value={bankName}
                              className={`form-control w-100 ${ErrorBorder(
                                bankNameError
                              )}`}
                              onChange={(e) => {
                                setBankNameError(false);
                                setBankName(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Branch Name for entered bank"
                          >
                            <div>Branch Name</div>
                            <input
                              type="text"
                              placeholder="Branch Name of Bank e.g ISE Tower"
                              value={bankAccountBranch}
                              className={`form-control w-100 ${ErrorBorder(
                                bankAccountBranchError
                              )}`}
                              onChange={(e) => {
                                setBankAccountBranchError(false);
                                setBankAccountBranch(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Select Fund for this bank"
                          >
                            Segment
                            {accFundLoading ? (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            ) : (
                              <span>
                                <select
                                  className={"form-control w-100"}
                                  value={bankAccountFund}
                                  disabled={false}
                                  onChange={(e) => {
                                    setBankAccountFund(e.target.value);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck
                                      ? "No Fund Found"
                                      : "Select Fund"}
                                  </option>
                                  <option value="ALL">ALL</option>
                                  {renderFundsDropdown()}
                                </select>
                              </span>
                            )}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="form-group hidden"></div>
                            <div className="form-group" data-tip="Add Fund">
                              <button
                                className="btn"
                                onClick={() => {
                                  if (fundsData?.length > 0) {
                                    let test = fundsData.filter(
                                      (item, index) => {
                                        return (
                                          item.fund === bankAccountFund ||
                                          item.fund === "ALL"
                                        );
                                      }
                                    );
                                    if (test.length > 0) {
                                      return toast.error(
                                        "Fund already exists in segment!"
                                      );
                                    }
                                    if (bankAccountFund === "ALL") {
                                      setFundsData([{ fund: bankAccountFund }]);
                                    } else {
                                      setFundsData((prevState) => [
                                        ...prevState,
                                        { fund: bankAccountFund },
                                      ]);
                                    }
                                  } else {
                                    setFundsData([{ fund: bankAccountFund }]);
                                  }
                                }}
                              >
                                Add Fund
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div> */}

                    {/* Card for daily expense */}
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title">Expenses</div>
                        {expenseCardExpanded ? (
                          <div>
                            <AiOutlineArrowUp
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              data-tip="Expand"
                              onClick={() => setExpenseCardExpanded(false)}
                            />
                          </div>
                        ) : (
                          <div>
                            <AiOutlineArrowDown
                              className="text-primary"
                              data-tip="Collapse"
                              style={{ cursor: "pointer" }}
                              onClick={() => setExpenseCardExpanded(true)}
                            />{" "}
                          </div>
                        )}
                      </div>
                      {expenseCardExpanded ? (
                        <div className="card-body">
                          <div
                            className="form-group"
                            data-tip="Mark as yes if this ledger is related to daily expense"
                          >
                            <div>Daily Expense</div>
                            <select
                              className={"form-control w-100 "}
                              value={dailyExpense}
                              disabled={false}
                              onChange={(e) => {
                                if (e.target.value === "false") {
                                  setDailyExpense(false);
                                } else if (e.target.value === "true") {
                                  setDailyExpense(true);
                                }
                              }}
                            >
                              <option value="false" defaultChecked hidden>
                                No
                              </option>

                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                          </div>

                          <div
                            className="form-group"
                            data-tip="Ledger Code to associate"
                          >
                            <div>Associated Ledger Code</div>
                            {/* <input
                              type="number"
                              placeholder="Ledger Code to associate"
                              value={associatedLedgerExpense}
                              className={`form-control w-100 mt-2 ${ErrorBorder(
                                associatedLedgerExpenseError
                              )}`}
                              style={{ textAlign: "left" }}
                      data-tip="Ledger Code to associate in case of Expense"
                              
                              onChange={(e) => {
                                setAssociatedLedgerExpenseError(false);
                                setAssociatedLedgerExpense(e.target.value);
                              }}
                            /> */}
                            {ledgerOption.length > 1 ? (
                              <Autocomplete
                                className="mt-2 autocomplete"
                                data-tip="Ledger Code to associate in case of Expense"
                                disablePortal
                                id="combo-box-demo"
                                options={ledgerOption}
                                style={{ width: "100%" }}
                                PaperComponent={({ children }) => (
                                  <Paper
                                    style={{
                                      background: context.theme
                                        ? ""
                                        : "#1E1E2F",
                                      color: "green",
                                    }}
                                  >
                                    {children}
                                  </Paper>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-input": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                }}
                                value={associatedLedgerExpense}
                                onChange={(
                                  event: any,
                                  newValue: string | null
                                ) => {
                                  setAssociatedLedgerExpense(newValue);
                                  setAssociatedLedgerExpenseError(false);
                                }}
                                inputValue={associatedLedgerExpense}
                                onInputChange={(event, newInputValue) => {
                                  setAssociatedLedgerExpenseError(false);
                                  setAssociatedLedgerExpense(newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Ledger for debit"
                                    variant="standard"
                                  />
                                )}
                              />
                            ) : (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            )}
                          </div>

                          <div
                            className="form-group"
                            data-tip="Quarterly Fee for Defined Expense"
                          >
                            <div>Quarterly Fee</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Quarterly Fee for defined Expense"
                              value={quarterlyFee}
                              className={`form-control w-100 ${ErrorBorder(
                                quarterlyFeeError
                              )}`}
                              style={{ textAlign: "left" }}
                              onValueChange={(e) => {
                                setQuarterlyFeeError(false);
                                setQuarterlyFee(e.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Half Yearly Fee for define Expense"
                          >
                            <div>Half Yearly Fee</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Half Yearly Fee for defined Expense"
                              value={halfYearlyFee}
                              className={`form-control w-100 ${ErrorBorder(
                                halfYearlyFeeError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Half Yearly Fee for defined Expense"
                              onValueChange={(e) => {
                                setHalfYearlyFeeError(false);
                                setHalfYearlyFee(e.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Yearly Fee for defined Expense"
                          >
                            <div>Yearly Fee</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Yearly Fee for defined Expense"
                              value={yearlyFee}
                              className={`form-control w-100 ${ErrorBorder(
                                yearlyFeeError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Yearly Fee for defined Expense"
                              onValueChange={(e) => {
                                setYearlyFeeError(false);
                                setYearlyFee(e.value);
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  {/* Card for bank profit */}
                  <Col>
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title">Bank Profit</div>
                        {bankProfitCardExpanded ? (
                          <div>
                            <AiOutlineArrowUp
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              data-tip="Expand"
                              onClick={() => setBankProfitCardExpanded(false)}
                            />
                          </div>
                        ) : (
                          <div>
                            <AiOutlineArrowDown
                              className="text-primary"
                              data-tip="Collapse"
                              style={{ cursor: "pointer" }}
                              onClick={() => setBankProfitCardExpanded(true)}
                            />{" "}
                          </div>
                        )}
                      </div>
                      {bankProfitCardExpanded ? (
                        <div className="card-body">
                          <div
                            className="form-group"
                            data-tip="Interest rate for Bank Profit"
                          >
                            <div>Interest Rate %</div>
                            <input
                              type="number"
                              placeholder="Interest rate to calculate bank profit"
                              value={interestRate}
                              className={`form-control w-100 ${ErrorBorder(
                                interestRateError
                              )}`}
                              style={{ textAlign: "left" }}
                              onChange={(e) => {
                                setInterestRateError(false);
                                setInterestRate(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Ledger Codes to Debit/Credit in case of bank profit"
                          >
                            <div>Associated Ledger Codes</div>
                            {/* <input
                              type="number"
                              placeholder="Ledger Code for debit"
                              value={debitBankProfitLedger}
                              className={`form-control w-100 mt-2 ${ErrorBorder(
                                debitBankProfitLedgerError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Ledger Code to Debit in case of Bank Profit"
                              onChange={(e) => {
                                setDebitBankProfitLedgerError(false);
                                setDebitBankProfitLedger(e.target.value);
                              }}
                            /> */}
                            {ledgerOption.length > 1 ? (
                              <Autocomplete
                                className="mt-2 autocomplete"
                                data-tip="Ledger Code to associate in case of Expense"
                                disablePortal
                                id="combo-box-demo"
                                options={ledgerOption}
                                style={{ width: "100%" }}
                                PaperComponent={({ children }) => (
                                  <Paper
                                    style={{
                                      background: context.theme
                                        ? ""
                                        : "#1E1E2F",
                                      color: "white",
                                    }}
                                  >
                                    {children}
                                  </Paper>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-input": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                }}
                                value={debitBankProfitLedger}
                                onChange={(
                                  event: any,
                                  newValue: string | null
                                ) => {
                                  setDebitBankProfitLedger(newValue);
                                  setDebitBankProfitLedgerError(false);
                                }}
                                inputValue={debitBankProfitLedger}
                                onInputChange={(event, newInputValue) => {
                                  setDebitBankProfitLedgerError(false);
                                  setDebitBankProfitLedger(newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Ledger for debit"
                                    variant="standard"
                                  />
                                )}
                              />
                            ) : (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            )}

                            {/* <input
                              type="number"
                              placeholder="Ledger Code for credit"
                              value={creditBankProfitLedger}
                              className={`form-control w-100 mt-2 ${ErrorBorder(
                                creditBankProfitLedgerError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Ledger Code to Credit in case of Bank Profit"
                              onChange={(e) => {
                                setCreditBankProfitLedgerError(false);
                                setCreditBankProfitLedger(e.target.value);
                              }}
                            /> */}

                            {ledgerOption.length > 1 ? (
                              <Autocomplete
                                className="mt-2 autocomplete"
                                data-tip="Ledger Code to associate in case of Expense"
                                disablePortal
                                id="combo-box-demo"
                                options={ledgerOption}
                                style={{ width: "100%" }}
                                PaperComponent={({ children }) => (
                                  <Paper
                                    style={{
                                      background: context.theme
                                        ? ""
                                        : "#1E1E2F",
                                      color: "white",
                                    }}
                                  >
                                    {children}
                                  </Paper>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-input": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                }}
                                value={creditBankProfitLedger}
                                onChange={(
                                  event: any,
                                  newValue: string | null
                                ) => {
                                  setCreditBankProfitLedger(newValue);
                                  setCreditBankProfitLedgerError(false);
                                }}
                                inputValue={creditBankProfitLedger}
                                onInputChange={(event, newInputValue) => {
                                  setCreditBankProfitLedgerError(false);
                                  setCreditBankProfitLedger(newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Ledger for credit"
                                    variant="standard"
                                  />
                                )}
                              />
                            ) : (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* Card for fee slabs */}
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title">Fee Slabs</div>
                        {feeSlabsCardExpanded ? (
                          <div>
                            <AiOutlineArrowUp
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              data-tip="Expand"
                              onClick={() => setFeeSlabsCardExpanded(false)}
                            />
                          </div>
                        ) : (
                          <div>
                            <AiOutlineArrowDown
                              className="text-primary"
                              data-tip="Collapse"
                              style={{ cursor: "pointer" }}
                              onClick={() => setFeeSlabsCardExpanded(true)}
                            />{" "}
                          </div>
                        )}
                      </div>
                      {feeSlabsCardExpanded ? (
                        <div className="card-body">
                          <div
                            className="form-group"
                            data-tip="Slab Range start"
                          >
                            <div>Slab From</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Slab From"
                              value={slabFrom}
                              className={`form-control w-100 ${ErrorBorder(
                                slabFromError
                              )}`}
                              style={{ textAlign: "left" }}
                              onValueChange={(e) => {
                                setSlabFromError(false);
                                setSlabFrom(e.value);
                              }}
                            />
                          </div>
                          <div className="form-group" data-tip="Slab Range end">
                            <div>Slab To</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Slab To"
                              value={slabTo}
                              className={`form-control w-100 ${ErrorBorder(
                                slabToError
                              )}`}
                              style={{ textAlign: "left" }}
                              onValueChange={(e) => {
                                setSlabToError(false);
                                setSlabTo(e.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Rate for trustee fee calculation"
                          >
                            <div>Slab Rate</div>
                            <input
                              type="number"
                              placeholder="Slab Rate"
                              value={slabRate}
                              className={`form-control w-100 ${ErrorBorder(
                                slabRateError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Slab rate"
                              onChange={(e) => {
                                setSlabRateError(false);
                                setSlabRate(e.target.value);
                              }}
                            />
                          </div>

                          <div
                            className="form-group"
                            data-tip="Fixed amount to charge in case of this slab range"
                          >
                            <div>Slab Fixed Amount</div>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              placeholder="Amount to charge in case of this slab range"
                              value={slabFixedRate}
                              className={`form-control w-100 ${ErrorBorder(
                                slabFixedRateError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Fixed amount to charge in case of this slab range"
                              onValueChange={(e) => {
                                setSlabFixedRateError(false);
                                setSlabFixedRate(e.value);
                              }}
                            />
                          </div>
                          <div className="form-group" data-tip="Add Slab">
                            <button
                              className="btn"
                              onClick={() => handleAddingSlab()}
                            >
                              Add Slab
                            </button>
                          </div>

                          <div
                            className="form-group"
                            data-tip="Ledger Code to relate in case of Trustee Fee"
                          >
                            <div>Related Ledger Code</div>
                            {/* <input
                              type="number"
                              placeholder="Ledger Code to relate in case of Trustee Fee"
                              value={relatedLedger}
                              className={`form-control w-100 ${ErrorBorder(
                                relatedLedgerError
                              )}`}
                              style={{ textAlign: "left" }}
                              data-tip="Ledger Code to relate in case of TrusteeFee"
                              onChange={(e) => {
                                setRelatedLedgerError(false);
                                setRelatedLedger(e.target.value);
                              }}
                            /> */}

                            {ledgerOption.length > 1 ? (
                              <Autocomplete
                                className="mt-2 autocomplete"
                                data-tip="Ledger Code to associate in case of Expense"
                                disablePortal
                                id="combo-box-demo"
                                options={ledgerOption}
                                style={{ width: "100%" }}
                                PaperComponent={({ children }) => (
                                  <Paper
                                    style={{
                                      background: context.theme
                                        ? ""
                                        : "#1E1E2F",
                                      color: "white",
                                    }}
                                  >
                                    {children}
                                  </Paper>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-input": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: context.theme
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                  },
                                }}
                                value={relatedLedger}
                                onChange={(
                                  event: any,
                                  newValue: string | null
                                ) => {
                                  setRelatedLedger(newValue);
                                  setRelatedLedgerError(false);
                                }}
                                inputValue={relatedLedger}
                                onInputChange={(event, newInputValue) => {
                                  setRelatedLedgerError(false);
                                  setRelatedLedger(newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Ledger to relate slab"
                                    variant="standard"
                                  />
                                )}
                              />
                            ) : (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {slabData?.length > 0 ? (
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title text-primary">Slab Data</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Slab From</th>
                              <th>Slab To</th>
                              <th>Slab Rate</th>
                              <th>Fixed Ammount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {slabData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{numberWithCommas(data.amountFrom)}</td>
                                  <td>{numberWithCommas(data.amountTo)}</td>
                                  <td>{numberWithCommas(data.rate)}</td>
                                  <td>{numberWithCommas(data.fixedAmmount)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                          {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}

            {fundsData?.length > 0 ? (
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title text-primary">Segment Data</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Fund</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fundsData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{numberWithCommas(data.fund)}</td>
                                  <td>
                                    <i
                                      className="tim-icons icon-simple-remove text-primary"
                                      data-tip={`Remove ${data.fund} from segment`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleFundDelete(data.fund);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <div className="form-group d-flex justify-content-between">
                  <div className="hidden"></div>
                  <button
                    className="btn"
                    onClick={() => {
                      handleAddLedger();
                    }}
                    disabled={addingLedgerLoading}
                  >
                    {addingLedgerLoading ? "Adding..." : "Add Ledger"}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  const [viewEditModal, setViewEditModal] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const renderEditModal = () => {
    if (viewEditModal) {
      return (
        <Modal
          className=""
          dialogClassName="editChartsModal"
          show={true}
          size="xl"
          backdrop={true}
          onHide={() => {
            setBankAccountCardExpanded(false);
            setExpenseCardExpanded(false);
            setBankProfitCardExpanded(false);
            setFeeSlabsCardExpanded(false);

            setMainFamilyCode("");
            setMainFamilyCodeError(false);

            setMainFamilyName("");
            setMainFamilyNameError(false);

            setMainFamilyFlag(false);

            setFamilyCode("");
            setFamilyCodeError(false);

            setFamilyName("");
            setFamilyNameError(false);

            setFamilyFlag(false);

            setSubFamilyCode("");
            setSubFamilyCodeError(false);

            setSubFamilyFlag(false);

            setSubFamilyName("");
            setSubFamilyNameError(false);

            setChildFamilyCode("");
            setChildFamilyCodeError(false);

            setChildFamilyName("");
            setChildFamilyNameError(false);

            setChildFamilyFlag(false);

            setGlCode("");
            setGlCodeError(false);
            setGlName("");
            setGlNameError(false);

            setGlFlag(false);
            setCurrentBalance("");
            setAssociatedAccounts("");
            setRelatedLedger("");
            setSlabFrom("");
            setSlabTo("");
            setSlabRate("");
            setSlabFixedRate("");
            setBankAccountNumber("");
            setBankAccountTitle("");
            setBankName("");
            setBankAccountBranch("");
            setBankAccountFund("");
            setInterestRate("");
            setAnnualPayment("");
            setDailyExpense(false);
            setHalfYearlyFee("");
            setQuarterlyFee("");
            setYearlyFee("");
            setDebitBankProfitLedger("");
            setCreditBankProfitLedger("");
            setAssociatedLedgerExpense("");
            setSlabData("");
            setFundsData("");

            setParentsItem({});
            setViewEditModal(false);
            setViewOnly(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setBankAccountCardExpanded(false);
                setExpenseCardExpanded(false);
                setBankProfitCardExpanded(false);
                setFeeSlabsCardExpanded(false);

                setMainFamilyCode("");
                setMainFamilyCodeError(false);

                setMainFamilyName("");
                setMainFamilyNameError(false);

                setMainFamilyFlag(false);

                setFamilyCode("");
                setFamilyCodeError(false);

                setFamilyName("");
                setFamilyNameError(false);

                setFamilyFlag(false);

                setSubFamilyCode("");
                setSubFamilyCodeError(false);

                setSubFamilyFlag(false);

                setSubFamilyName("");
                setSubFamilyNameError(false);

                setChildFamilyCode("");
                setChildFamilyCodeError(false);

                setChildFamilyName("");
                setChildFamilyNameError(false);

                setChildFamilyFlag(false);

                setGlCode("");
                setGlCodeError(false);
                setGlName("");
                setGlNameError(false);

                setGlFlag(false);

                setCurrentBalance("");
                setAssociatedAccounts("");
                setRelatedLedger("");
                setSlabFrom("");
                setSlabTo("");
                setSlabRate("");
                setSlabFixedRate("");
                setBankAccountNumber("");
                setBankAccountTitle("");
                setBankName("");
                setBankAccountBranch("");
                setBankAccountFund("");
                setInterestRate("");
                setAnnualPayment("");
                setDailyExpense(false);
                setHalfYearlyFee("");
                setQuarterlyFee("");
                setYearlyFee("");
                setDebitBankProfitLedger("");
                setCreditBankProfitLedger("");
                setAssociatedLedgerExpense("");
                setSlabData("");
                setFundsData("");

                setParentsItem({});
                setViewEditModal(false);
                setViewOnly(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up d-flex align-items-center mt-2">
              {/* Replace this with family logic tree */}
              Editing Ledger
              <nav aria-label="breadcrumb" style={{ marginLeft: "8px" }}>
                <div className="hidden">Updating Ledger After</div>
                <ol className="breadcrumb">
                  {mainFamilyCode > 0 && mainFamilyFlag && (
                    <li className="breadcrumb-item text-primary">
                      <span>{mainFamilyCode}</span>&nbsp;-&nbsp;{mainFamilyName}
                    </li>
                  )}
                  {familyCode > 0 && familyFlag && (
                    <li className="breadcrumb-item text-primary">
                      <span>{familyCode}</span>&nbsp;-&nbsp;{familyName}
                    </li>
                  )}

                  {subFamilyCode > 0 && subFamilyFlag && (
                    <li className="breadcrumb-item text-primary">
                      <span>{subFamilyCode}</span>&nbsp;-&nbsp;{subFamilyName}
                    </li>
                  )}
                  {childFamilyCode > 0 && childFamilyFlag && (
                    <li className="breadcrumb-item text-primary">
                      <span>{childFamilyCode}</span>&nbsp;-&nbsp;
                      {childFamilyName}
                    </li>
                  )}
                  {glCode > 0 && glFlag && (
                    <li className="breadcrumb-item text-primary">
                      <span>{glCode}</span>&nbsp;-&nbsp;{glName}
                    </li>
                  )}
                  <li className="breadcrumb-item text-primary"></li>
                </ol>
              </nav>
            </h6>
          </div>

          <div className="modal-body">
            {/* Required Fields to create ledger */}
            <div className="card">
              <div className="card-header">
                <h4 className="card-title text-primary">Ledger Fields</h4>
              </div>
              <div className="card-body">
                <Row>
                  <Col>
                    {mainFamilyFlag && !familyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger main family code can't be changed"
                      >
                        <div>Main Family Code*</div>

                        <input
                          type="number"
                          placeholder="Main Family Code"
                          value={mainFamilyCode}
                          className={`form-control ${ErrorBorder(
                            mainFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setMainFamilyCodeError(false);
                            setMainFamilyCode(e.target.value);
                          }}
                          disabled
                        />
                      </div>
                    )}

                    {familyFlag && !subFamilyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger family code can't be changed"
                      >
                        Family Code*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <input
                          type="number"
                          placeholder="Family Code"
                          value={familyCode}
                          className={`form-control ${ErrorBorder(
                            familyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setFamilyCodeError(false);
                            setFamilyCode(e.target.value);
                          }}
                          disabled
                        />
                      </div>
                    )}

                    {subFamilyFlag && !childFamilyFlag && !glFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger Sub Family Code can't be changed"
                      >
                        <div>Sub Family Code*</div>

                        <input
                          type="number"
                          placeholder="Sub Family Code"
                          value={subFamilyCode}
                          className={`form-control ${ErrorBorder(
                            subFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setSubFamilyCodeError(false);
                            setSubFamilyCode(e.target.value);
                          }}
                          disabled
                        />
                      </div>
                    )}

                    {childFamilyFlag && !glFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger Child Family Code can't be changed"
                      >
                        <div>Child Family Code</div>

                        <input
                          type="number"
                          placeholder="Child Family Code"
                          value={childFamilyCode}
                          className={`form-control ${ErrorBorder(
                            childFamilyCodeError
                          )}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setChildFamilyCodeError(false);
                            setChildFamilyCode(e.target.value);
                          }}
                          disabled
                        />
                      </div>
                    )}

                    {glFlag && (
                      <div
                        className="form-group"
                        data-tip="GL Code can't be changed"
                      >
                        GL Code*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <input
                          type="number"
                          placeholder="GL Code"
                          value={glCode}
                          className={`form-control ${ErrorBorder(glCodeError)}`}
                          style={{ textAlign: "left" }}
                          onChange={(e) => {
                            setGlCodeError(false);
                            setGlCode(e.target.value);
                          }}
                          disabled
                        />
                      </div>
                    )}
                  </Col>

                  <Col>
                    {mainFamilyFlag && !familyFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger main family name"
                      >
                        <div>Main Family Name*</div>
                        <input
                          type="text"
                          placeholder="Main family name"
                          value={mainFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            mainFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setMainFamilyNameError(false);
                            setMainFamilyName(e.target.value);
                          }}
                          disabled={viewOnly}
                        />
                      </div>
                    )}

                    {familyFlag && !subFamilyFlag && (
                      <div className="form-group" data-tip="Ledger family name">
                        <div>Family Name*</div>
                        <input
                          type="text"
                          placeholder="Family Name"
                          value={familyName}
                          className={`form-control w-100 ${ErrorBorder(
                            familyNameError
                          )}`}
                          onChange={(e) => {
                            setFamilyNameError(false);
                            setFamilyName(e.target.value);
                          }}
                          disabled={viewOnly}
                        />
                      </div>
                    )}

                    {subFamilyFlag && !childFamilyFlag && !glFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger sub family name"
                      >
                        <div>Sub Family Name*</div>
                        <input
                          type="text"
                          placeholder="Ledger sub family name"
                          value={subFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            subFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setSubFamilyNameError(false);
                            setSubFamilyName(e.target.value);
                          }}
                          disabled={viewOnly}
                        />
                      </div>
                    )}

                    {childFamilyFlag && !glFlag && (
                      <div
                        className="form-group"
                        data-tip="Ledger child family name"
                      >
                        <div>Child Family Name</div>
                        <input
                          type="text"
                          placeholder="Ledger child family code"
                          value={childFamilyName}
                          className={`form-control w-100 ${ErrorBorder(
                            childFamilyNameError
                          )}`}
                          onChange={(e) => {
                            setChildFamilyNameError(false);
                            setChildFamilyName(e.target.value);
                          }}
                          disabled={viewOnly}
                        />
                      </div>
                    )}

                    {glFlag && (
                      <div className="form-group" data-tip="GL name">
                        <div>GL name</div>
                        <input
                          type="text"
                          placeholder="GL Name"
                          value={glName}
                          className={`form-control w-100 ${ErrorBorder(
                            glNameError
                          )}`}
                          onChange={(e) => {
                            setGlNameError(false);
                            setGlName(e.target.value);
                          }}
                          disabled={viewOnly}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <Row>{/* Optional Fields */}</Row>
            {glFlag && (
              <div>
                <div>
                  <h4 className="title text-primary">Optional Fields</h4>
                </div>
                <div>
                  <Row>
                    <Col>
                      {/* Card for bank account */}
                      {/* <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div className="card-title">Bank Account</div>
                          {bankAccountCardExpanded ? (
                            <div>
                              <AiOutlineArrowUp
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                data-tip="Expand"
                                onClick={() =>
                                  setBankAccountCardExpanded(false)
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <AiOutlineArrowDown
                                className="text-primary"
                                data-tip="Collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setBankAccountCardExpanded(true)}
                              />{" "}
                            </div>
                          )}
                        </div>
                        {bankAccountCardExpanded ? (
                          <div className="card-body">
                            <div
                              className="form-group"
                              data-tip="Current balance of this ledger"
                            >
                              <div>Current Balance</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                placeholder="Current Balance for this ledger"
                                value={currentBalance}
                                className={`form-control w-100 ${ErrorBorder(
                                  currentBalanceError
                                )}`}
                                style={{ textAlign: "left" }}
                                onValueChange={(e) => {
                                  setCurrentBalanceError(false);
                                  setCurrentBalance(e.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Bank Account # for this ledger"
                            >
                              <div>Bank Account #</div>
                              <input
                                type="text"
                                placeholder="Bank Account # for this ledger"
                                value={bankAccountNumber}
                                className={`form-control w-100 ${ErrorBorder(
                                  bankAccountNumberError
                                )}`}
                                onChange={(e) => {
                                  setBankAccountNumberError(false);
                                  setBankAccountNumber(e.target.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Account title for entered bank account title"
                            >
                              <div>Bank Account Title</div>
                              <input
                                type="text"
                                placeholder="Bank Account title e.g John Doe"
                                value={bankAccountTitle}
                                className={`form-control w-100 ${ErrorBorder(
                                  bankAccountTitleError
                                )}`}
                                onChange={(e) => {
                                  setBankAccountTitleError(false);
                                  setBankAccountTitle(e.target.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Name of bank in which bank account is in"
                            >
                              <div>Bank Name</div>
                              <input
                                type="text"
                                placeholder="Bank Name e.g. UBL, HBL, SONERI"
                                value={bankName}
                                className={`form-control w-100 ${ErrorBorder(
                                  bankNameError
                                )}`}
                                onChange={(e) => {
                                  setBankNameError(false);
                                  setBankName(e.target.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Branch Name for entered bank"
                            >
                              <div>Branch Name</div>
                              <input
                                type="text"
                                placeholder="Branch Name of Bank e.g ISE Tower"
                                value={bankAccountBranch}
                                className={`form-control w-100 ${ErrorBorder(
                                  bankAccountBranchError
                                )}`}
                                onChange={(e) => {
                                  setBankAccountBranchError(false);
                                  setBankAccountBranch(e.target.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Select Fund for this bank"
                            >
                              Segment
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : (
                                <span>
                                  <select
                                    className={"form-control w-100"}
                                    value={bankAccountFund}
                                    disabled={viewOnly}
                                    onChange={(e) => {
                                      setBankAccountFund(e.target.value);
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {fundCheck
                                        ? "No Fund Found"
                                        : "Select Fund"}
                                    </option>
                                    <option value="ALL">ALL</option>
                                    {renderFundsDropdown()}
                                  </select>
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="form-group hidden"></div>
                              {viewOnly && (
                                <div className="form-group" data-tip="Add Fund">
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      if (fundsData?.length > 0) {
                                        let test = fundsData.filter(
                                          (item, index) => {
                                            return (
                                              item.fund === bankAccountFund ||
                                              item.fund === "ALL"
                                            );
                                          }
                                        );
                                        if (test.length > 0) {
                                          return toast.error(
                                            "Fund already exists in segment!"
                                          );
                                        }
                                        if (bankAccountFund === "ALL") {
                                          setFundsData([
                                            { fund: bankAccountFund },
                                          ]);
                                        } else {
                                          setFundsData((prevState) => [
                                            ...prevState,
                                            { fund: bankAccountFund },
                                          ]);
                                        }
                                      } else {
                                        setFundsData([
                                          { fund: bankAccountFund },
                                        ]);
                                      }
                                    }}
                                  >
                                    Add Fund
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div> */}

                      {/* Card for daily expense */}
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div className="card-title">Expenses</div>
                          {expenseCardExpanded ? (
                            <div>
                              <AiOutlineArrowUp
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                data-tip="Expand"
                                onClick={() => setExpenseCardExpanded(false)}
                              />
                            </div>
                          ) : (
                            <div>
                              <AiOutlineArrowDown
                                className="text-primary"
                                data-tip="Collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setExpenseCardExpanded(true)}
                              />{" "}
                            </div>
                          )}
                        </div>
                        {expenseCardExpanded ? (
                          <div className="card-body">
                            <div
                              className="form-group"
                              data-tip="Mark as yes if this ledger is related to daily expense"
                            >
                              <div>Daily Expense</div>
                              <select
                                className={"form-control w-100 "}
                                value={dailyExpense}
                                disabled={viewOnly}
                                onChange={(e) => {
                                  if (e.target.value === "false") {
                                    setDailyExpense(false);
                                  } else if (e.target.value === "true") {
                                    setDailyExpense(true);
                                  }
                                }}
                              >
                                <option value="false" defaultChecked hidden>
                                  No
                                </option>

                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                            </div>

                            <div
                              className="form-group"
                              data-tip="Ledger Code to associate"
                            >
                              <div>Associated Ledger Code</div>
                              {/* <input
                                type="number"
                                placeholder="Ledger Code to associate"
                                value={associatedLedgerExpense}
                                className={`form-control w-100 mt-2 ${ErrorBorder(
                                  associatedLedgerExpenseError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Ledger Code to associate in case of Expense"
                                onChange={(e) => {
                                  setAssociatedLedgerExpenseError(false);
                                  setAssociatedLedgerExpense(e.target.value);
                                }}
                              /> */}

                              {ledgerOption.length > 1 ? (
                                <Autocomplete
                                  className="mt-2 autocomplete"
                                  data-tip="Ledger Code to associate in case of Expense"
                                  disablePortal
                                  readOnly={viewOnly}
                                  id="combo-box-demo"
                                  options={ledgerOption}
                                  style={{ width: "100%" }}
                                  PaperComponent={({ children }) => (
                                    <Paper
                                      style={{
                                        background: context.theme
                                          ? ""
                                          : "#1E1E2F",
                                        color: "white",
                                      }}
                                    >
                                      {children}
                                    </Paper>
                                  )}
                                  sx={{
                                    "& .MuiAutocomplete-input": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },

                                    "& .MuiIconButton-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                  }}
                                  value={associatedLedgerExpense}
                                  onChange={(
                                    event: any,
                                    newValue: string | null
                                  ) => {
                                    setAssociatedLedgerExpense(newValue);
                                    setAssociatedLedgerExpenseError(false);
                                  }}
                                  inputValue={associatedLedgerExpense}
                                  onInputChange={(event, newInputValue) => {
                                    setAssociatedLedgerExpenseError(false);
                                    setAssociatedLedgerExpense(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Ledger for debit"
                                      variant="standard"
                                    />
                                  )}
                                />
                              ) : (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              )}
                            </div>

                            <div
                              className="form-group"
                              data-tip="Quarterly Fee for Defined Expense"
                            >
                              <div>Quarterly Fee</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                placeholder="Quarterly Fee for defined Expense"
                                value={quarterlyFee}
                                className={`form-control w-100 ${ErrorBorder(
                                  quarterlyFeeError
                                )}`}
                                style={{ textAlign: "left" }}
                                onValueChange={(e) => {
                                  setQuarterlyFeeError(false);
                                  setQuarterlyFee(e.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Half Yearly Fee for define Expense"
                            >
                              <div>Half Yearly Fee</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                placeholder="Half Yearly Fee for defined Expense"
                                value={halfYearlyFee}
                                className={`form-control w-100 ${ErrorBorder(
                                  halfYearlyFeeError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Half Yearly Fee for defined Expense"
                                onValueChange={(e) => {
                                  setHalfYearlyFeeError(false);
                                  setHalfYearlyFee(e.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Yearly Fee for defined Expense"
                            >
                              <div>Yearly Fee</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                placeholder="Yearly Fee for defined Expense"
                                value={yearlyFee}
                                className={`form-control w-100 ${ErrorBorder(
                                  yearlyFeeError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Yearly Fee for defined Expense"
                                onValueChange={(e) => {
                                  setYearlyFeeError(false);
                                  setYearlyFee(e.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    {/* Card for bank profit */}
                    <Col>
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div className="card-title">Bank Profit</div>
                          {bankProfitCardExpanded ? (
                            <div>
                              <AiOutlineArrowUp
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                data-tip="Expand"
                                onClick={() => setBankProfitCardExpanded(false)}
                              />
                            </div>
                          ) : (
                            <div>
                              <AiOutlineArrowDown
                                className="text-primary"
                                data-tip="Collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setBankProfitCardExpanded(true)}
                              />{" "}
                            </div>
                          )}
                        </div>
                        {bankProfitCardExpanded ? (
                          <div className="card-body">
                            <div
                              className="form-group"
                              data-tip="Interest rate for Bank Profit"
                            >
                              <div>Interest Rate %</div>
                              <input
                                type="number"
                                placeholder="Interest rate to calculate bank profit"
                                value={interestRate}
                                className={`form-control w-100 ${ErrorBorder(
                                  interestRateError
                                )}`}
                                style={{ textAlign: "left" }}
                                onChange={(e) => {
                                  setInterestRateError(false);
                                  setInterestRate(e.target.value);
                                }}
                                disabled={viewOnly}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Ledger Codes to Debit/Credit in case of bank profit"
                            >
                              <div>Associated Ledger Codes</div>
                              {/* <input
                                type="number"
                                placeholder="Ledger Code for debit"
                                value={debitBankProfitLedger}
                                className={`form-control w-100 mt-2 ${ErrorBorder(
                                  debitBankProfitLedgerError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Ledger Code to Debit in case of Bank Profit"
                                onChange={(e) => {
                                  setDebitBankProfitLedgerError(false);
                                  setDebitBankProfitLedger(e.target.value);
                                }}
                              /> */}

                              {ledgerOption.length > 1 ? (
                                <Autocomplete
                                  className="mt-2 autocomplete"
                                  data-tip="Ledger Code to associate in case of Expense"
                                  disablePortal
                                  readOnly={viewOnly}
                                  id="combo-box-demo"
                                  options={ledgerOption}
                                  style={{ width: "100%" }}
                                  PaperComponent={({ children }) => (
                                    <Paper
                                      style={{
                                        background: context.theme
                                          ? ""
                                          : "#1E1E2F",
                                        color: "white",
                                      }}
                                    >
                                      {children}
                                    </Paper>
                                  )}
                                  sx={{
                                    "& .MuiAutocomplete-input": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },

                                    "& .MuiIconButton-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                  }}
                                  value={debitBankProfitLedger}
                                  onChange={(
                                    event: any,
                                    newValue: string | null
                                  ) => {
                                    setDebitBankProfitLedger(newValue);
                                    setDebitBankProfitLedgerError(false);
                                  }}
                                  inputValue={debitBankProfitLedger}
                                  onInputChange={(event, newInputValue) => {
                                    setDebitBankProfitLedgerError(false);
                                    setDebitBankProfitLedger(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Ledger for debit"
                                      variant="standard"
                                    />
                                  )}
                                />
                              ) : (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              )}

                              {/* <input
                                type="number"
                                placeholder="Ledger Code for credit"
                                value={creditBankProfitLedger}
                                className={`form-control w-100 mt-2 ${ErrorBorder(
                                  creditBankProfitLedgerError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Ledger Code to Credit in case of Bank Profit"
                                onChange={(e) => {
                                  setCreditBankProfitLedgerError(false);
                                  setCreditBankProfitLedger(e.target.value);
                                }}
                              /> */}

                              {ledgerOption.length > 1 ? (
                                <Autocomplete
                                  className="mt-2 autocomplete"
                                  data-tip="Ledger Code to associate in case of Expense"
                                  disablePortal
                                  readOnly={viewOnly}
                                  id="combo-box-demo"
                                  options={ledgerOption}
                                  style={{ width: "100%" }}
                                  PaperComponent={({ children }) => (
                                    <Paper
                                      style={{
                                        background: context.theme
                                          ? ""
                                          : "#1E1E2F",
                                        color: "white",
                                      }}
                                    >
                                      {children}
                                    </Paper>
                                  )}
                                  sx={{
                                    "& .MuiAutocomplete-input": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },

                                    "& .MuiIconButton-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                  }}
                                  value={creditBankProfitLedger}
                                  onChange={(
                                    event: any,
                                    newValue: string | null
                                  ) => {
                                    setCreditBankProfitLedger(newValue);
                                    setCreditBankProfitLedgerError(false);
                                  }}
                                  inputValue={creditBankProfitLedger}
                                  onInputChange={(event, newInputValue) => {
                                    setCreditBankProfitLedgerError(false);
                                    setCreditBankProfitLedger(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Ledger for credit"
                                      variant="standard"
                                    />
                                  )}
                                />
                              ) : (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* Card for fee slabs */}
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div className="card-title">Fee Slabs</div>
                          {feeSlabsCardExpanded ? (
                            <div>
                              <AiOutlineArrowUp
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                data-tip="Expand"
                                onClick={() => setFeeSlabsCardExpanded(false)}
                              />
                            </div>
                          ) : (
                            <div>
                              <AiOutlineArrowDown
                                className="text-primary"
                                data-tip="Collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setFeeSlabsCardExpanded(true)}
                              />{" "}
                            </div>
                          )}
                        </div>
                        {feeSlabsCardExpanded ? (
                          <div className="card-body">
                            <div
                              className="form-group"
                              data-tip="Slab Range start"
                            >
                              <div>Slab From</div>
                              <NumberFormat
                                disabled={viewOnly}
                                thousandSeparator={true}
                                inputMode="numeric"
                                placeholder="Slab From"
                                value={slabFrom}
                                className={`form-control w-100 ${ErrorBorder(
                                  slabFromError
                                )}`}
                                style={{ textAlign: "left" }}
                                onValueChange={(e) => {
                                  setSlabFromError(false);
                                  setSlabFrom(e.value);
                                }}
                              />
                            </div>
                            <div
                              className="form-group"
                              data-tip="Slab Range end"
                            >
                              <div>Slab To</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                disabled={viewOnly}
                                placeholder="Slab To"
                                value={slabTo}
                                className={`form-control w-100 ${ErrorBorder(
                                  slabToError
                                )}`}
                                style={{ textAlign: "left" }}
                                onValueChange={(e) => {
                                  setSlabToError(false);
                                  setSlabTo(e.value);
                                }}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Rate for trustee fee calculation"
                            >
                              <div>Slab Rate</div>
                              <input
                                type="number"
                                disabled={viewOnly}
                                placeholder="Slab Rate"
                                value={slabRate}
                                className={`form-control w-100 ${ErrorBorder(
                                  slabRateError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Slab rate"
                                onChange={(e) => {
                                  setSlabRateError(false);
                                  setSlabRate(e.target.value);
                                }}
                              />
                            </div>

                            <div
                              className="form-group"
                              data-tip="Fixed amount to charge in case of this slab range"
                            >
                              <div>Slab Fixed Amount</div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                disabled={viewOnly}
                                placeholder="Amount to charge in case of this slab range"
                                value={slabFixedRate}
                                className={`form-control w-100 ${ErrorBorder(
                                  slabFixedRateError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Fixed amount to charge in case of this slab range"
                                onValueChange={(e) => {
                                  setSlabFixedRateError(false);
                                  setSlabFixedRate(e.value);
                                }}
                              />
                            </div>

                            {viewOnly && (
                              <div className="form-group" data-tip="Add Slab">
                                <button
                                  className="btn"
                                  onClick={() => handleAddingSlab()}
                                >
                                  Add Slab
                                </button>
                              </div>
                            )}

                            <div
                              className="form-group"
                              data-tip="Ledger Code to relate in case of Trustee Fee"
                            >
                              <div>Related Ledger Code</div>
                              {/* <input
                                type="number"
                                placeholder="Ledger Code to relate in case of Trustee Fee"
                                value={relatedLedger}
                                className={`form-control w-100 ${ErrorBorder(
                                  relatedLedgerError
                                )}`}
                                style={{ textAlign: "left" }}
                                data-tip="Ledger Code to relate in case of TrusteeFee"
                                onChange={(e) => {
                                  setRelatedLedgerError(false);
                                  setRelatedLedger(e.target.value);
                                }}
                              /> */}

                              {ledgerOption.length > 1 ? (
                                <Autocomplete
                                  className="mt-2 autocomplete"
                                  data-tip="Ledger Code to associate in case of Expense"
                                  disablePortal
                                  readOnly={viewOnly}
                                  id="combo-box-demo"
                                  options={ledgerOption}
                                  style={{ width: "100%" }}
                                  PaperComponent={({ children }) => (
                                    <Paper
                                      style={{
                                        background: context.theme
                                          ? ""
                                          : "#1E1E2F",
                                        color: "white",
                                      }}
                                    >
                                      {children}
                                    </Paper>
                                  )}
                                  sx={{
                                    "& .MuiAutocomplete-input": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },

                                    "& .MuiIconButton-root": {
                                      color: context.theme
                                        ? "black !important"
                                        : "rgba(255, 255, 255, 0.5) !important",
                                    },
                                  }}
                                  value={relatedLedger}
                                  onChange={(
                                    event: any,
                                    newValue: string | null
                                  ) => {
                                    setRelatedLedger(newValue);
                                    setRelatedLedgerError(false);
                                  }}
                                  inputValue={relatedLedger}
                                  onInputChange={(event, newInputValue) => {
                                    setRelatedLedgerError(false);
                                    setRelatedLedger(newInputValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Ledger to relate slab"
                                      variant="standard"
                                    />
                                  )}
                                />
                              ) : (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {glFlag && slabData?.length > 0 ? (
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title text-primary">Slab Data</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Slab From</th>
                              <th>Slab To</th>
                              <th>Slab Rate</th>
                              <th>Fixed Ammount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {slabData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <NumberFormat
                                      className="form-control w-50"
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      value={data.amountFrom}
                                      disabled={viewOnly}
                                      onValueChange={(e) => {
                                        setSlabFromError(false);

                                        data.amountFrom = e.value;
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      className="form-control w-50"
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      value={data.amountTo}
                                      disabled={viewOnly}
                                      onValueChange={(e) => {
                                        setSlabFromError(false);

                                        data.amountTo = e.value;
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      className="form-control w-50"
                                      thousandSeparator={false}
                                      inputMode="numeric"
                                      value={data.rate}
                                      disabled={viewOnly}
                                      onValueChange={(e) => {
                                        setSlabFromError(false);

                                        data.rate = e.value;
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      className="form-control w-50"
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      value={data.fixedAmmount}
                                      disabled={viewOnly}
                                      onValueChange={(e) => {
                                        setSlabFromError(false);

                                        data.fixedAmmount = e.value;
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}

            {glFlag && fundsData?.length > 0 ? (
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title text-primary">Segment Data</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Fund</th>
                              {!viewOnly && <th>Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {fundsData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td> {data.fund}</td>
                                  {!viewOnly && (
                                    <td>
                                      <i
                                        className="tim-icons icon-simple-remove text-primary"
                                        data-tip={`Remove ${data.fund} from segment`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleFundDelete(data.fund);
                                        }}
                                      ></i>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                          {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              {!viewOnly && (
                <Col>
                  <div className="form-group d-flex justify-content-between">
                    <div className="hidden"></div>
                    <button
                      className="btn"
                      onClick={() => {
                        handleEditLedger();
                      }}
                      disabled={editingLedgerLoading}
                    >
                      {editingLedgerLoading ? "Updating..." : "Edit Ledger"}
                    </button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Modal>
      );
    }
  };

  const renderStatusModal = () => {
    switch (statusModal) {
      case true:
        return (
          <Modal className="" dialogClassName="modal60w" show={true}>
            <div className="modal-header justify-content-center">
              <button
                aria-hidden="true"
                className="close"
                onClick={() => {
                  setStatusModal(false);
                }}
                data-dismiss="modal"
                type="button"
              >
                <i className="tim-icons icon-simple-remove"></i>
              </button>
              <h1 className="title title-up" style={{ fontSize: "5rem" }}>
                <AiOutlineWarning style={{ color: "yellow" }} />
              </h1>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentLedgerStatus === "active" ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{" "}
                    {currentLedgerStatus === "active"
                      ? "Deactivate"
                      : "Activate"}{" "}
                    this Ledger ({changeStatusLedgerCode})?
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary "
                        disabled={Boolean(!mainFamily || changingStatus)}
                        onClick={() => {
                          changeActiveStatus();
                        }}
                      >
                        {!mainFamily ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : changingStatus ? (
                          "Updating..."
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className=" ">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusModal(false);
                        }}
                        disabled={Boolean(!mainFamily || changingStatus)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const filterChartAccountsData = () => {
    let filteredData = mainFamily.map((item) => {
      if (
        item.code == search ||
        item.name?.toLowerCase()?.includes(search?.toLowerCase())
      ) {
        return item;
      } else if (item.family?.length > 0) {
        let fam = item.family
          .map((item2) => {
            let subFam = [];
            if (item2.sub_family?.length > 0) {
              subFam = item2.sub_family
                .map((item3) => {
                  let subChild = [],
                    gl = [];

                  if (item3.child_family?.length > 0) {
                    subChild = item3.child_family
                      .map((item4) => {
                        if (item4.gl?.length > 0) {
                          gl = item4.gl
                            .map((item5) => {
                              if (
                                item5.code?.toString()?.startsWith(search) ||
                                item5.name
                                  ?.toLowerCase()
                                  ?.includes(search?.toLowerCase())
                              ) {
                                return {
                                  ...item5,
                                };
                              }
                            })
                            .filter((item5) => item5);

                          if (
                            item4.code?.toString()?.startsWith(search) ||
                            item4.name
                              ?.toLowerCase()
                              ?.includes(search?.toLowerCase())
                          ) {
                            return {
                              ...item4,
                            };
                          } else if (gl.length > 0) {
                            return {
                              ...item4,
                              gl: gl,
                            };
                          }
                        }
                      })
                      ?.filter((item4) => item4);
                  } else if (item3.gl?.length > 0) {
                    gl = item3.gl
                      .map((item4) => {
                        if (
                          item4.code?.toString()?.startsWith(search) ||
                          item4.name
                            ?.toLowerCase()
                            ?.includes(search?.toLowerCase())
                        ) {
                          return {
                            ...item4,
                          };
                        }
                      })
                      ?.filter((item4) => item4);
                  }

                  if (
                    item3.code?.toString()?.startsWith(search) ||
                    item3.name?.toLowerCase()?.includes(search?.toLowerCase())
                  ) {
                    return {
                      ...item3,
                    };
                  } else if (subChild.length > 0) {
                    return {
                      ...item3,
                      child_family: subChild,
                    };
                  } else if (gl.length > 0) {
                    return {
                      ...item3,
                      gl: gl,
                    };
                  }
                })
                ?.filter((item3) => item3);
            }

            if (
              item2.code?.toString()?.startsWith(search) ||
              item2.name?.toLowerCase()?.includes(search?.toLowerCase())
            ) {
              return {
                ...item2,
              };
            } else {
              if (subFam.length > 0) {
                return {
                  ...item2,
                  sub_family: subFam,
                };
              }
            }
          })
          ?.filter((item2) => item2);

        if (fam.length > 0) {
          return {
            ...item,
            family: fam,
          };
        }
      }
    });

    return filteredData.filter((item) => item) || [];
  };

  const renderChartAccounts = (data) => {
    return data.map((item, index) => {
      return (
        <>
          <RowComponent
            gap="0rem"
            item={item}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            statusModal={statusModal}
            setStatusModal={setStatusModal}
            changeStatusLedgerCode={changeStatusLedgerCode}
            setChangeStatusLedgerCode={setChangeStatusLedgerCode}
            plusIcon={plusIcon}
            viewAddModal={setViewAddModal}
            setViewAddModal={setViewAddModal}
            addModalParentLedgerCode={addModalParentLedgerCode}
            setAddModalParentLedgerCode={setAddModalParentLedgerCode}
            addModalParentLedgerName={addModalParentLedgerName}
            setAddModalParentLedgerName={setAddModalParentLedgerName}
            setBankAccountCardExpanded={setBankAccountCardExpanded}
            setExpenseCardExpanded={setExpenseCardExpanded}
            setBankProfitCardExpanded={setBankProfitCardExpanded}
            setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
            parentName={parentName}
            parentsInfo={parentsInfo}
            setParentsInfo={setParentsInfo}
            parentsItem={parentsItem}
            setParentsItem={setParentsItem}
            mainFamilyCode={mainFamilyCode}
            setMainFamilyCode={setMainFamilyCode}
            mainFamilyName={mainFamilyName}
            setMainFamilyName={setMainFamilyName}
            familyCode={familyCode}
            setFamilyCode={setFamilyCode}
            familyName={familyName}
            setFamilyName={setFamilyName}
            subFamilyCode={subFamilyCode}
            setSubFamilyCode={setSubFamilyCode}
            subFamilyName={subFamilyName}
            setSubFamilyName={setSubFamilyName}
            childFamilyCode={childFamilyCode}
            setChildFamilyCode={setChildFamilyCode}
            childFamilyName={childFamilyName}
            setChildFamilyName={setChildFamilyName}
            mainFamilyFlag={mainFamilyFlag}
            setMainFamilyFlag={setMainFamilyFlag}
            familyFlag={familyFlag}
            setFamilyFlag={setFamilyFlag}
            subFamilyFlag={subFamilyFlag}
            setSubFamilyFlag={setSubFamilyFlag}
            childFamilyFlag={childFamilyFlag}
            setChildFamilyFlag={setChildFamilyFlag}
            glFlag={glFlag}
            setGlFlag={setGlFlag}
            viewEditModal={viewEditModal}
            setViewEditModal={setViewEditModal}
            glCode={glCode}
            setGlCode={setGlCode}
            glName={glName}
            setGlName={setGlName}
            currentBalance={currentBalance}
            setCurrentBalance={setCurrentBalance}
            associatedAccounts={associatedAccounts}
            setAssociatedAccounts={setAssociatedAccounts}
            relatedLedger={relatedLedger}
            setRelatedLedger={setRelatedLedger}
            slabData={slabData}
            setSlabData={setSlabData}
            fundsData={fundsData}
            setFundsData={setFundsData}
            bankAccountNumber={bankAccountNumber}
            setBankAccountNumber={setBankAccountNumber}
            bankAccountTitle={bankAccountTitle}
            setBankAccountTitle={setBankAccountTitle}
            bankName={bankName}
            setBankName={setBankName}
            bankAccountBranch={bankAccountBranch}
            setBankAccountBranch={setBankAccountBranch}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            annualPayment={annualPayment}
            setAnnualPayment={setAnnualPayment}
            dailyExpense={dailyExpense}
            setDailyExpense={setDailyExpense}
            halfYearlyFee={halfYearlyFee}
            setHalfYearlyFee={setHalfYearlyFee}
            quarterlyFee={quarterlyFee}
            setQuarterlyFee={setQuarterlyFee}
            yearlyFee={yearlyFee}
            setYearlyFee={setYearlyFee}
            debitBankProfitLedger={debitBankProfitLedger}
            setDebitBankProfitLedger={setDebitBankProfitLedger}
            creditBankProfitLedger={creditBankProfitLedger}
            setCreditBankProfitLedger={setCreditBankProfitLedger}
            associatedLedgerExpense={associatedLedgerExpense}
            setAssociatedLedgerExpense={setAssociatedLedgerExpense}
            editLedgerCode={editLedgerCode}
            setEditLedgerCode={setEditLedgerCode}
            bankAccountFund={bankAccountFund}
            setBankAccountFund={setBankAccountFund}
            setViewOnly={setViewOnly}
          />
        </>
      );
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="card-title">Chart of account</div>
                  <div className="d-flex justify-content-center align-items-center">
                    {isAbleToRevert && (
                      <div
                        data-tip="Reset Chart Of Accounts"
                        className="mr-4 pointer"
                      >
                        <button
                          className=" btn-round btn-icon btn btn-success"
                          onClick={handleResetChartOfAccount}
                        >
                          <HiRefresh size={18} />
                        </button>
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                      </div>
                    )}
                    {/* <div data-tip="Add main ledger">
                      <button
                        className=" btn-round btn-icon btn btn-success"
                        onClick={() => {
                          setAddingParent(true);
                          setBankAccountCardExpanded(false);
                          setExpenseCardExpanded(false);
                          setBankProfitCardExpanded(false);
                          setFeeSlabsCardExpanded(false);

                          setMainFamilyCode("");
                          setMainFamilyCodeError(false);

                          setMainFamilyName("");
                          setMainFamilyNameError(false);

                          setMainFamilyFlag(false);

                          setFamilyCode("");
                          setFamilyCodeError(false);

                          setFamilyName("");
                          setFamilyNameError(false);

                          setFamilyFlag(false);

                          setSubFamilyCode("");
                          setSubFamilyCodeError(false);

                          setSubFamilyFlag(false);

                          setSubFamilyName("");
                          setSubFamilyNameError(false);

                          setChildFamilyCode("");
                          setChildFamilyCodeError(false);

                          setChildFamilyName("");
                          setChildFamilyNameError(false);

                          setChildFamilyFlag(false);

                          setGlCode("");
                          setGlCodeError(false);
                          setGlName("");
                          setGlNameError(false);

                          setGlFlag(false);
                          setViewAddModal(true);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <ReactTooltip
                        textColor="white"
                        backgroundColor="black"
                        effect="float"
                      />
                    </div> */}
                  </div>

                  {/* <div className="mx-2">
                    {isExpanded ? (
                      <button
                        className="btn"
                        onClick={() => setIsExpanded(false)}
                      >
                        Collapse All
                      </button>
                    ) 
                    : (
                      <button
                        className="btn"
                        onClick={() => setIsExpanded(true)}
                      >
                        Expand All
                      </button>
                    )
                    }
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="form-group  ">
                    <input
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  {mainFamily ? (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}>
                              {isExpanded && (
                                <i
                                  className="tim-icons icon-simple-delete mx-1"
                                  onClick={() => setIsExpanded(false)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {search
                            ? renderChartAccounts(filterChartAccountsData())
                            : renderChartAccounts(mainFamily)}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-center my-5">
                          <i
                            className="fa fa-spinner fa-spin fa-3x"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {renderStatusModal()}
                  {renderAddModal()}
                  {renderEditModal()}
                </div>
              </div>
            </Container>
          </Col>
        </Row>

        {mainFamily && (
          <div className="">
            <button
              className="btn btn-primary ml-3"
              onClick={() => saveChartsOfAccounts()}
              disabled={Boolean(!mainFamily) || Loading || disableSaveButton}
            >
              {Loading ? (
                <>
                  <span
                    className="spinner-border login-txt spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="login-txt"> Saving...</span>
                </>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default ChartsOfAccountsConfig;
