import {combineReducers} from "redux";
import { chartOfAccountsReducer } from "./chartOfAccountsReducers";
import { fundsReducer } from "./getFundsReducers";
import { txnTypeCategoryReducer } from "./getTxnCategoryReducers";
import { txnTypesReducer } from "./getTxnTypesReducers";

const reducers = combineReducers({
    chartofAccounts: chartOfAccountsReducer,
    funds:fundsReducer,
    txnTypeCategories: txnTypeCategoryReducer,
    txnTypes: txnTypesReducer,
})

export default reducers;