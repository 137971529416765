import React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getAllTransactionFilter } from '../../../stores/services/security.service';
import { getAmc } from '../../../stores/services/amc.service';
const io = require('socket.io-client')

const SecurityTransactionFilter = () => {
    const history = useHistory();
    const amc_code = sessionStorage.getItem('amc_code') || "";
    const email = sessionStorage.getItem('email') || '';
    const [Loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [amcdata, setAmcdata] = useState<any>([]);
    const [amcName, setAmcName] = useState('');
    let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
    let securityName = sessionStorage.getItem('securityName') || '';

    React.useEffect(() => {
        const fetchAmc = async () => {
            amcdata.length = 0;
            setAmcdata(amcdata);
            //get all Amc for dropdown
            try {
                const amcResponse = await getAmc(email);
                setAmcdata(amcResponse.data.data);
            } catch (err:any) {
                if(err.response !== undefined ) {
                    toast.error(err.response.data.message);	
                  }else {
                    toast.error("Request Failed!")
                  }
            }
        };
        fetchAmc();
    }, []);


    const GetTxnFilterData = async (amc: string) => {
        setLoading(true);
        try {
            const response = await getAllTransactionFilter(email, amc, securityName,amc_code);
            setData(response.data.data)
        } catch (error:any) {
            toast.error(error.response.data.message[0]);
        }
        setLoading(false);
    }
    const CapTxnType = (type: any) => {
        let i, txType;
        for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
        }
        return txType;
    };

    const renderTxnData = () => {
        return data.map((transaction: any, index: any) => {
            return (
                <tr key={index}>
                    <td>{transaction.txn_id}</td>
                    <td>
                        {transaction.txnr_type
                            ? `${CapTxnType(transaction.txnr_type)}	${transaction.detail?.includes("Reversed") ? " (Reversed)" : ""}`
                            : 'N/A'}
                    </td>
                    <td>
                        {moment(transaction.txn_history[0].created_at).format(
                            'DD-MM-YYYY'
                        )}
                    </td>
                    <td>{transaction.txn_status}</td>
                    <td className=" ">
                        <div className="multi-icons">
                            <img
                                src="assets/view.svg"
                                alt=""
                                width="16"
                                onClick={() => {
                                    let objArr = Object.entries(transaction);
                                    setDataSelected(objArr);
                                    setView(true);
                                }}
                            />
                        </div>
                    </td>
                </tr>
            );
        })
    }
    //render dropdown for amc data
    const renderAmcDropdown = () => {
        return amcdata.map((item: any, index: number) => {
            return (
                <option key={index} value={item.name}>{item.name}</option>
            );
        });
    }

    const [view, setView] = useState(false);
    const [dataSelected, setDataSelected] = useState<any>([]);
    const titleCase = (value: string) => {
        let sentence = value.toLowerCase().split('_');
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence.join(' ');
    };
    const renderData = () => {
        return dataSelected.map((value: any, index: number) => {
            return (
                <>
                    {value[0] !== 'txn_history' && value[0] !== 'file' ? (
                        <Col md="6" key={index}>
                            <div className="input-holder left">
                                <p className="label">{titleCase(value[0])}</p>
                                <div className="input-1 hide-overflow">
                                    <p
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        {typeof value[1] === 'string'
                                            ? value[0] === 'file'
                                                ? 'N/A'
                                                : value[1]
                                            : value[0] === 'file'
                                                ? 'N/A'
                                                : ''}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    ) : (
                            ''
                        )}
                </>
            );
        });
    };
    return (
        <>
        <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
                <ToastContainer limit={1} />
                {!view ? (
                    <div className="card mt-4">
                        <div className="card-header">
                            <h4 className="card-title" >
                                 
                                <i className="fa fa-angle-left mr-2 pointer"  onClick={() => {
                                        history.replace('/admin/securities')
                                    }}></i>
                            Related Transactions</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select className="form-control" value={amcName} onChange={(e) => {
                                            setAmcName(e.target.value);
                                            GetTxnFilterData(e.target.value);
                                        }}>
                                            <option value="" defaultChecked hidden> Select An AMC</option>
                                            {renderAmcDropdown()}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="">
                            <table className="table " data-pagination={true} data-page-size={5}>
                                <thead>
                                    <tr>
                                        <th>Transaction Id</th>
                                        <th>Transaction Type</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {(data.length === 0) ?
                                        <tr>
                                            <td>No Transaction</td>
                                        </tr>
                                        :
                                        <>
                                            {renderTxnData()}
                                        </>
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="body-pad">
                            <h1>
                                <img
                                    src="assets/arrow-left.svg"
                                    onClick={() => {
                                        setView(false);
                                    }}
                                    className="mr-3"
                                    alt=""
                                    width="24"
                                />
                      View - Transaction
                    </h1>
                            <div className="form-holder">
                                {data ? <Row>{renderData()}</Row> : ''}
                            </div>
                        </div>
                    )}

            </Container>
            </Col>
        </Row>
      </div>
        </>
    )
};

export default SecurityTransactionFilter;