/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/Admin/Admin.js";
import RTLLayout from "./layouts/RTL/RTL.js";
import Login from "./components/Login/login";
import ForgetPassword from "./containers/ForgotPasswordPage";
import ViewTxnFields from "./containers/view-beta-txn";
// import TransactionTxnFields from './containers/single-beta-txn';

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// import './css/app.global.scss';
// // import './css/app.scss';
// import '@fortawesome/fontawesome-free/css/fontawesome.css';

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import {Provider} from  'react-redux';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
          {/* <Route		
            path="/admin/beta-transaction/:type"	
            component={TransactionTxnFields}	
          />	 */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route
            exact
            path="/admin/beta-transaction/:type"
            component={ViewTxnFields}
          />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
  </Provider>,
  document.getElementById("root")
);
