import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { updateTaxRate } from "../../../stores/services/tax-rates.service";

const EditTaxRate =()=>{
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const amc_code =  sessionStorage.getItem('amc_code') || '';
     
    const [individual_filer, setIndividualFiler] = useState('0');
    const [individual_non_filer, setIndividualNonFiler] = useState('0');
    const [individual_exempted, setIndividualExempted] = useState('0');
    const [corporate_filer, setCorporateFiler] = useState('0');
    const [corporate_non_filer, setCorporateNonFiler] = useState('0');
    const [corporate_exempted, setCorporateExempted] = useState('0');

    const [individual_filerError, setIndividualFilerError] = useState('');
    const [individual_non_filerError, setIndividualNonFilerError] = useState('');
    const [individual_exemptedError, setIndividualExemptedError] = useState('');
    const [corporate_filerError, setCorporateFilerError] = useState('');
    const [corporate_non_filerError, setCorporateNonFilerError] = useState('');
    const [corporate_exemptedError, setCorporateExemptedError] = useState('');

    const [category,setCategory]=useState('');

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [period, setPeriod] = useState("");
    const [cgtExempAfterYears, setCgtExempAfterYears] = useState("0");
    useEffect(()=>{
      const obj = JSON.parse(sessionStorage.getItem('selected_tax_rate') || '');
      setIndividualFiler(obj.individual_filer);
      setIndividualNonFiler(obj.individual_non_filer);
      setIndividualExempted(obj.individual_exempted);
      setCorporateFiler(obj.corporate_filer);
      setCorporateNonFiler(obj.corporate_non_filer);
      setCorporateExempted(obj.corporate_exempted);
      setPeriod(obj.period);
      setStartDate(obj.start_date ? moment(obj.start_date).format('YYYY-MM-DD') : "");
      setEndDate(obj.end_date ? moment(obj.end_date).format('YYYY-MM-DD') : "");
      setCategory(obj.category);
      setCgtExempAfterYears(obj.cgt_exempt_after || "0")

    },[]);

    const [loading, setLoading] = useState(false);

    const updateTaxRates=async()=>{
      if(validate()){
            try{
                setLoading(true);
                const response = await updateTaxRate(
                  amc_code,
                  email,
                  individual_filer,
                  individual_non_filer,
                  individual_exempted,
                  corporate_filer,
                  corporate_non_filer,
                  corporate_exempted,
                  period,
                  startDate,
                  endDate,
                  category,
                  +cgtExempAfterYears || 0
                );
                if(response.data.status==200){
                    toast.success(response.data.message);
                    sessionStorage.removeItem('selected_tax_rate');
                    setTimeout(()=>{
                        history.replace('/admin/tax-rates');
                    },3000)
                   
                }else{
                  setLoading(false);
                    toast.error(response.data.message);
                }

            }catch(error:any){
                setLoading(false);
                if(error.response!==undefined){
                    toast.error(error.response.data.message?.toString());
                }else{
                    toast.error('Something went wrong!');
                }
            }
            
          }
    };

    const validate=()=>{
      let individualFilerErr = '';	
      let individualNonFilerErr = '';
      let individualExemptedErr = '';
      let corporateFilerErr = '';	
      let corporateNonFilerErr = '';
      let corporateExemptedErr = '';
      individual_filer.trim() === '' ? (individualFilerErr = 'required-border') : (individualFilerErr = '');
      individual_non_filer.trim() === '' ? (individualNonFilerErr = 'required-border') : (individualNonFilerErr = '');
      individual_exempted.trim() === '' ? (individualExemptedErr = 'required-border') : (individualExemptedErr = '');
      corporate_filer.trim() === '' ? (corporateFilerErr = 'required-border') : (corporateFilerErr = '');
      corporate_non_filer.trim() === '' ? (corporateNonFilerErr = 'required-border') : (corporateNonFilerErr = '');
      corporate_exempted.trim() === '' ? (corporateExemptedErr = 'required-border') : (corporateExemptedErr = '');
      if (individualFilerErr || individualNonFilerErr || individualExemptedErr || corporateFilerErr || corporateNonFilerErr
       || corporateExemptedErr) {
      setIndividualFilerError(individualFilerErr);
      setIndividualNonFilerError(individualNonFilerErr);
      setIndividualExemptedError(individualExemptedErr);
      setCorporateFilerError(corporateFilerErr);
      setCorporateNonFilerError(corporateNonFilerErr);
      setCorporateExemptedError(corporateExemptedErr);
        return false;	
      } else {	
        return true;	
      }	
   }




    return (
        <>
        <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header title-row">	
            <h4 className="card-title text-primary"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              sessionStorage.removeItem('selected_tax_rate');
              history.replace('/admin/tax-rates')
          }}></i>Edit - Tax Rates</h4>	

            <Link to="/admin/tax-rates" className="btn btn-primary btn-sm ml-auto" replace>	
            <i className="fa fa-eye mr-2"></i>View All	
              </Link>	
          </div>	
         
          <div className="card-body">
          <Row>	
          <Col md="6">
                  <div className="form-group">
                    <label>Category</label>
                  <div>
                  <div className="">
                      <select 
                      className={"form-control  w-100 "}
                      value={category}
                      onChange={(e)=>{
                      setCategory(e.target.value);
                     

                      }}>
                      <option value='' hidden>Select Category</option>
                      <option value='Stock Funds'>Stock Funds</option>
                      <option value='Other Funds'>Other Funds</option>

                      
                      </select>
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>

                <Col md="6">	
                <div className="form-group">	
                  <label>Financial Year Start Date </label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 `}	
                    value={startDate}
                    readOnly
                  />
                </div>	
              </Col>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Financial Year End Date</label>	
                  <input	
                    type="date"
                    className={`form-control  w-100 `}	
                    value={endDate}
                    readOnly	
                  />	
                </div>	
              </Col>	

              <Col md='6'>
                <div className="form-group">	
                  <label>Financial Year Period</label>	
                  <input
                    type="text"
                    placeholder="Period"
                    readOnly
                    className={`form-control  w-100 `}	
                    value={period}
                  />	
                </div>	
                </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_filerError}`}	
                    value={individual_filer}
                    onChange={(e) => {
                     setIndividualFiler(e.target.value);
                     setIndividualFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Non-Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_non_filerError}`}	
                    value={individual_non_filer}
                    onChange={(e) => {
                     setIndividualNonFiler(e.target.value);
                     setIndividualNonFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              	
              <Col md="6">	
                <div className="form-group">	
                  <label>Individual Exempted <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control  w-100 text-right ${individual_exemptedError}`}	
                    value={individual_exempted}
                    onChange={(e) => {
                     setIndividualExempted(e.target.value);
                     setIndividualExemptedError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_filerError}`}
                    value={corporate_filer}
                    onChange={(e) => {
                     setCorporateFiler(e.target.value);
                     setCorporateFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Non-Filer <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_non_filerError}`}	
                    value={corporate_non_filer}
                    onChange={(e) => {
                    setCorporateNonFiler(e.target.value);
                    setCorporateNonFilerError('');
                    }}	
                  />
                </div>	
              </Col>
              <Col md="6">	
                <div className="form-group">	
                  <label>Corporate Exempted <span className="color-amber">*</span></label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right ${corporate_exemptedError}`}
                    value={corporate_exempted}
                    onChange={(e) => {
                     setCorporateExempted(e.target.value);
                     setCorporateExemptedError('');
                    }}	
                  />
                </div>	
              </Col>

              <Col md="6">	
                <div className="form-group">	
                  <label>CGT Exempt After Years </label>	
                  <input	
                    type="number"
                    className={`form-control w-100 text-right `}
                    value={cgtExempAfterYears}
                    onChange={(e) => {
                     if(e.target.value && +e.target.value > 0 && +e.target.value < 11){
                      setCgtExempAfterYears(e.target.value);
                     }else if(!e.target.value ){
                      setCgtExempAfterYears('0');
                     }
                    }}	
                  />
                </div>	
              </Col>
              </Row>
               
          </div>	
          <div className="form-group">	
            <button	
              className="btn btn-primary ml-3"	
              onClick={updateTaxRates}	
              disabled={Boolean(loading)}	
            >	
              {loading ? (	
                <>	
                  <span	
                    className="spinner-border login-txt spinner-border-sm"	
                    role="status"	
                    aria-hidden="true"	
                  ></span>	
                  <span className="login-txt"> Updating...</span>	
                </>	
              ) : (	
                <span>Update</span>	
              )}	
            </button>	
          </div>	
        </div>	
      </Container >	
      
 </Col>
        </Row>
      </div>
        </>
    );
}

export default EditTaxRate;