import Config from "../../config";
import axios from "axios";
import RefreshTokenHandler from './refresh-token';

export const getNetAssets = async (fund_code, date) => {
  const email = sessionStorage.getItem("email");
  const token = sessionStorage.getItem("token");
  const amc_code = sessionStorage.getItem("amc_code");

  //   if (!date) {
  //   let today = new Date();
  //   let dd = String(today.getDate()).padStart(2, "0");
  //   let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   let yyyy = today.getFullYear();
  //   let date = yyyy + "-" + mm + "-" + dd;
  //   }

  const url = `${Config.baseUrl}/transaction/get-daily-net-asset-by-fund?email=${email}&fund_code=${fund_code}&date=${date}`;

  try {
    const result: any = await axios.get(url, {
      headers: { Authorization: token },
    });

    return result;
  } catch (err:any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getNetAssets(fund_code, date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
