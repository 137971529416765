import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { BsTable } from 'react-icons/bs';
import { getAllTaxRates } from "../../../stores/services/tax-rates.service";
import { Modal } from 'react-bootstrap';

const TaxRates =()=>{
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const amc_code = sessionStorage.getItem('amc_code') || '';

    const [taxRates, setTaxRates] = useState<any>([]);

    const [financial_id, setFinancialID] = useState('');
    const [status, setStatus] = useState('');

    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] =  useState(false);
    // const [resLoading, setResLoading] = useState(false);

    const getAllTaxRatesByAMC = async() =>{
     try{
         setLoading(true);
         const response = await getAllTaxRates(email, amc_code);
         if(response.data.status==200){
           setTaxRates(response.data.data);
         }else{
             toast.error(response.data.message);
         }

     }catch(error:any){
        setLoading(false);
        if(error.response!=undefined){
            toast.error(error.response.data.message);
        }else{
            toast.error(error.message);
        }
     }
     setLoading(false);
    }

    useEffect(()=>{
        getAllTaxRatesByAMC();
    },[])

    const [statusModal, setStatusModal] = useState(false);

    const renderStatusModal = () => {
      switch (statusModal) {
        case true:
          return (
            <Modal
              className=" "
              dialogClassName="modal60w"
              show={true}
            >
              <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                  <h6 className="title title-up">Action</h6>
                </div>
  
              <div className="modal-body">
                <div className="text-center">
                  <Row>
                    <div className="mx-auto">
                      <img src="assets/ban.svg" alt="" width="70" />
                      <h1 className="pb-3">Are You Sure?</h1>
                      Are you sure you want to {status=='active' ?
                      'Deactive' : 'Active'} this Financial Period?
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div>
                        <button
                          className="btn btn-primary"
                          disabled={Boolean(statusLoading)}
                          onClick={() => {
                            updateStatus();
                          }}
                        >
                          {statusLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            'Yes'
                          )}
                        </button>
                      </div>
                    </Col>
                    <Col>
                      <div className=" ">
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            setStatusModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal>
          );
        default:
          return '';
      }
    };


    const updateStatus = async () => {
      setStatusLoading(true);
      let FPStatus = '';
      if (status === 'active') {
        FPStatus = 'deactive';
      } else {
        FPStatus = 'active';
      }
      try {
        //   const response = await updateFinancialPeriodStatus(email, financial_id,FPStatus);
          const response:any = [];
          if(response.data.status===200){
            setStatusModal(false);
          toast.success(response.data.message);
          setTimeout(()=>{
            getAllTaxRatesByAMC();
          },3000)
          
          }else{
              toast.error(response.data.message);
          }
      } catch (error:any) {
          if(error.response.data.status===500){
              toast.error(error.response.data.message);
          }else{
              toast.error(error.response.data.message);
          }
       }
      setStatusLoading(false);
  };

    return (
        <>
        <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header title-row">	
            <h4 className="card-title text-primary">Tax Rates</h4>	

            <Link to="/admin/add-tax-rate" className="btn btn-primary btn-sm ml-auto" replace>	
            <i className="fa fa-plus mr-2"></i>Add New	
              </Link>	
          </div>	
         
          <div className="card-body">
          {loading ? (
              <div className="d-flex justify-content-center my-5">	
              <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
            </div>
            ):(
              <div>
            {taxRates.length>0 ? (
              <div className="table-responsive">
               <table className="table">
                   <thead>
                       <tr>
                           <th>Financial Year Period</th>
                           <th>Individual Filer</th>
                           <th>Individual Non Filer</th>
                           <th>Individual Exempted</th>
                           <th>Corporate Filer</th>
                           <th>Corporate Non-Filer</th>
                           <th>Corporate Exempted</th>
                           {/* <th>Status</th> */}
                           <th>Action</th>
                       </tr>
                   </thead>
                   <tbody>
                       {taxRates.map((item:any)=>{
                       return(
                        <tr>
                           <td>{item.period}</td>
                           <td>{item.individual_filer}</td>
                           <td>{item.individual_non_filer}</td>
                           <td>{item.individual_exempted}</td>
                           <td>{item.corporate_filer}</td>
                           <td>{item.corporate_non_filer}</td>
                           <td>{item.corporate_exempted}</td>
                           {/* <td>{item.status=='active' ?
                           (
                            <span className="text-success">{item.status.toUpperCase()}</span>
                           ):(
                            <span className="text-danger">{item.status.toUpperCase()}</span>
                           )}
                           </td> */}
                           <td className="pointer">
                           {/* <button type="button" className="btn-icon btn-link like btn-sm"
                          onClick={() => {
                            setStatus(item.status);
                            setFinancialID(item.financial_id);
                            setStatusModal(true);
                          }}>
                        
                        {item.status=='active' ? 
                        (
                          <i className="fas fa-check text-success"></i>
                        ):(
                          <i className="fas fa-ban text-danger"></i>
                        )}
                        </button> */}

                        <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
                          onClick={() => {
                            sessionStorage.setItem('selected_tax_rate', JSON.stringify(item));
                            history.replace(`/admin/view-tax-rate`);
                          }}
                          >
                        
                          <i className="fa fa-eye">
                        </i>
                        </button>
 
                            <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
                              onClick={() => {
                                sessionStorage.setItem('selected_tax_rate', JSON.stringify(item));
                                history.replace(`/admin/edit-tax-rate`);
                              }}
                              >
                            
                              <i className="tim-icons icon-pencil">
                            </i>
                            </button>
                           </td>
                       </tr>
                           );
                       })}
                       
                   </tbody>
               </table>
               </div>
            ) : (
                <div className="table-empty">	
                <BsTable className="icon" />	
                <p className="text-center empty-text">No record found</p>	
              </div>
            )}
            </div>
            )}
          </div>	
          
        </div>	
      </Container >	
      
 </Col>
        </Row>
        {renderStatusModal()}
      </div>
        </>
    );
}

export default TaxRates;