import axios from "axios";
import moment from "moment";
import Config from "./../../config/index";
import RefreshTokenHandler from './refresh-token';

const getAllDividendWorkingForCurrentDate = async (
  email:any,
  fund_code: any,
  date: any
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    if (!token) {
      return "Error Token Invalid";
    }
    const email = sessionStorage.getItem("email") || "";
    if (!email) {
      return "Error fetching email! Try logging in again";
    }
    const amc_code = sessionStorage.getItem("amc_code") || "";
    if (!amc_code) {
      return "Invalid AMC| Try logging in again";
    }

    const response = await axios.get(
      `${Config.baseUrl}/dividendworking/?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&date=${date}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );

    return response;
  } catch (error: any) {

    if(error.response.data.status == 401) { 
      let responseToHandle = error.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllDividendWorkingForCurrentDate(email,fund_code, date)
      } else {
        throw error;
        // return result
      }
    }else {
      throw error
    }
   // return error?.message;
  }
};

const updateDividendWorkingAfterPayout = async (data) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    if (!token) {
      return "Error invalid token";
    }
    const email = sessionStorage.getItem("email") || "";
    if (!email) {
      return "Error fetching email! Try logging in again";
    }
    const amc_code = sessionStorage.getItem("amc_code") || "";
    if (!amc_code) {
      return "Invalid AMC! Try logging in again";
    }

    const response = await axios.post(
      `${Config.baseUrl}/dividendworking/update/`,
      {
        email: email,
        _id: data._id.split("_")[1],
        amc_code: amc_code,
        folio_no: data.folio_no,
        fund_code: data.fund_code,
        description: data.description,
        dividend_table: data.dividend_table,
        dividend_period: data.dividend_period,
        last_dividend_date: data.last_dividend_date,
        created_at: data.created_at,
        updated_at: moment().format("YYYY-MM-DD"), //created_at
        opening_units: data.opening_units,
        opening_nav: data.opening_nav,
        closing_units: data.closing_units,
        closing_nav: data.closing_nav,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error: any) {
    return error?.message;
  }
};

export {
  getAllDividendWorkingForCurrentDate,
  updateDividendWorkingAfterPayout,
};
