import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ViewFinancialPeriod = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [period, setPeriod] = useState("");
  const [financial_id, setFinancialID] = useState("");
  const [amcCode, setAmcCode] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("financialPeriod") || "");
    setStartDate(obj.start_date);
    setEndDate(obj.end_date);
    setDescription(obj.description);
    setPeriod(obj.period);
    setFinancialID(obj.financial_id);
    setAmcCode(obj.amc_code);
    setStatus(obj.status);
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title text-primary">
                    {" "}
                    <i
                      className="fa fa-angle-left mr-1 pointer"
                      onClick={() => {
                        sessionStorage.removeItem("financialPeriod");
                        history.replace("/admin/financial-periods");
                      }}
                    ></i>
                    View - Financial Period
                  </h4>

                  <Link
                    to="/admin/financial-periods"
                    className="btn btn-primary btn-sm ml-auto"
                    replace
                  >
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Start Date</label>
                        <div className="form-control">
                          {moment(startDate).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>End Date</label>
                        <div className="form-control">
                          {moment(endDate).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Descirption</label>
                        <div className="form-control">{description}</div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Period</label>
                        <div className="form-control">{period}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={() => {
                      sessionStorage.removeItem("financialPeriod");
                      history.replace("/admin/financial-periods");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewFinancialPeriod;
