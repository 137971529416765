import React,{useState,useRef} from "react";
import { Modal } from "react-bootstrap";
import { Container, Col, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { uploadPsxFile } from "../../../stores/services/security.service";

const DailyMarketSummary = () => {
    // Function body goes here
    const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState("");
  const myRef1 = useRef<HTMLInputElement>(null);
  const [psxList, setPsxList] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const amc_code = sessionStorage.getItem("amc_code") || ""
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);

    

    const upload = (e: any) => {
            
            let file = e?.target.files[0];
            let type = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (type === "lis") {
                setFile(file);
                setFileName(file.name);
                processLineByLine(e?.target.files[0]);
            } else {
                    setFileName("");
                    setFile(null);
                    setPsxList([])
                toast.error("Please Upload Correct Format of File");
            }
        };

      async function processLineByLine(fileObj:any) {
        var reader = new FileReader();
        reader.readAsText(fileObj);
        let content:any=[];
        reader.onload = function(fileObj) {
         content = reader.result;
         content = content.split('\n');
         let headings = content[0].split(':')
         let line1 = headings[0].replace(/"/g, '').split(',');
         let code =line1[1];
        
       
         let column = [] as any;
         var data = [] as any;
         var i = 0;
         let TotalLine= content.length-1;
         for (i=0;i<TotalLine; i++){
         content[i] = content[i].replaceAll('"','').trim();
        //  let str = content[i].split(':');
         let str = content[i].split('|');
        if(i>=1){
            let obj = {
                date: str[0],
                symbol: str[1],
                amc_code: amc_code || "",
                sector_code: str[2],
                company_name: str[3],
                open_rate: str[4],
                high_rate: str[5],
                low_rate: str[6],
                close_rate: str[7],
                volume: str[8],
                ldcp: str[9],
              }
          data.push(obj);
      }
    }
        setPsxList(data);
    
    }
    }

    const renderPsxData = () => {
        return psxList.map((items: any, index: number) => {
          return (
            <tr key={index}>
              <td>{items.company_name}</td>
              <td>{items.symbol}</td>
              <td>{items.sector_code}</td>
              <td>{items.open_rate}</td>
              <td>{items.high_rate}</td>
              <td>{items.low_rate}</td>
              <td>{items.close_rate}</td>
              <td>{items.volume}</td>
              <td>{items.ldcp}</td>
             
            </tr>
          );
        });
      };

      const uplaodFilePsx = async (fileDate: any) => {
        setFileUploadingFlag(true);
        await uploadPsxFile(sessionStorage.getItem("email") || "", fileDate,amc_code)
          .then((response) => {
            if (response.data.status === 200) {
              toast.success(response.data.message);
              
              setFileName("");
              setFile(null);
              setPsxList([]);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((err) => {});
        setFileUploadingFlag(false);
      };
    
    return (
        <div className="content" >
          <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">

              <div className="card-header">
                    <h4 className="card-title">
                    Daily Markey Summary (Closing)
                     
                    </h4>
                  </div>

                  <div className="card-body">
                    <Row className="justify-content-between">
                    <div className="form-group col-md-3 mt-1">
                        <div 
                        className="form-control multi-input pointer"
                        onClick={() => myRef1?.current?.click()}
                        
                        > {fileName === "" ? "Upload lis File" : fileName}</div>

                <input
                    type="file"
                    ref={myRef1}
                    style={{ display: "none" }}
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />

                    </div>
                    {psxList.length > 0 &&
                    <div className="mr-2">

                  <button className="btn btn-primary"
                  disabled={Boolean(fileUploadingFlag)}
                  onClick={() => uplaodFilePsx(file)}>
                    {fileUploadingFlag ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Uploading...</span>
                  </>
                ) : (
                  <span>Uplaod</span>
                )}
                    </button>
                     </div>}
                    </Row>

                    {(psxList.length > 0 &&  !Loading) ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Symbol</th>
                    <th>Sector Code</th>
                    <th>Open Rate</th>
                    <th>High Rate</th>
                    <th>Low Rate</th>
                    <th>Close Rate</th>
                    <th>Volume</th>
                    <th>LDCP</th>
                    
                  </tr>
                </thead>
                <tbody>{renderPsxData()}</tbody>
              </table>
            </div>
          ) : 
            Loading ? 
            <div className="d-flex">
              <img src="assets/loader.svg" width="100" />
            </div>
            :null
          }

                  </div>
              </div>
              </Container>
              </Col>
              </Row>
        </div>
    );
};


export default DailyMarketSummary