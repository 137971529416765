const FilesByInvestorType = {
Individuals: [{label:"CNIC Front Copy", value : "cnic_copy"},
{label:"CNIC Back Copy", value : "cnic_back_copy"},
{label:"Pay Slip", value : "pay_slip"},
{label:"Employment Letter", value : "employment_letter"},
{label:"Passport Copy", value : "passport_copy"},
{label:"Undertaking", value : "undertaking"},
{label:"Signature Specimen",value:"signature_specimen"}
],
  "Joint Account": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Joint Holder 1 CNIC Front Copy", value : "joint_h_1_cnic_copy"},
    {label:"Joint Holder 1 CNIC Back Copy", value : "joint_h_1_cnic_back_copy"},
    {label:"Joint Holder 1 NTN Document", value : "joint_h_1_ntn_document"},
    {label:"Joint Holder 1 Passport Copy", value : "joint_h_1_passport_copy"},
    {label:"Joint Holder 1 Address Bill Copy", value : "joint_h_1_address_bill_copy"},
    {label:"Joint Holder 1 Pay Slip", value : "joint_h_1_pay_slip"},
    {label:"Joint Holder 1 Employment Letter", value : "joint_h_1_employment_letter"},
    {label:"Joint Holder 2 CNIC Front Copy", value : "joint_h_2_cnic_copy"},
    {label:"Joint Holder 2 CNIC Back Copy", value : "joint_h_2_cnic_back_copy"},
    {label:"Joint Holder 2 NTN Document", value : "joint_h_2_ntn_document"},
    {label:"Joint Holder 2 Passport Copy", value : "joint_h_2_passport_copy"},
    {label:"Joint Holder 2 Address Bill Copy", value : "joint_h_2_address_bill_copy"},
    {label:"Joint Holder 2 Pay Slip", value : "joint_h_2_pay_slip"},
    {label:"Joint Holder 2 Employment Letter", value : "joint_h_2_employment_letter"},
    {label:"Joint Holder 3 CNIC Front Copy", value : "joint_h_3_cnic_copy"},
    {label:"Joint Holder 3 CNIC Back Copy", value : "joint_h_3_cnic_back_copy"},
    {label:"Joint Holder 3 NTN Document", value : "joint_h_3_ntn_document"},
    {label:"Joint Holder 3 Passport Copy", value : "joint_h_3_passport_copy"},
    {label:"Joint Holder 3 Address Bill Copy", value : "joint_h_3_address_bill_copy"},
    {label:"Joint Holder 3 Pay Slip", value : "joint_h_3_pay_slip"},
    {label:"Joint Holder 3 Employment Letter", value : "joint_h_3_employment_letter"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Sole Proprietorship": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Registration Certificate", value : "registration_certificate"},
    {label:"Account Opening Request", value : "account_opening_request"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],

  Partnership: [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"All Partners Documents", value : "all_partners_documents"},
    {label:"Partnership Deed", value : "partnership_deed"},
    {label:"Partnership Registeration Document", value : "partnership_registeration_document"},
    {label:"Partnership Authority Letter", value : "partnership_authority_letter"},
    {label:"Account Opening Request", value : "account_opening_request"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Limited Liability Partnership (LLP)": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Certified LLP Agreement", value : "certified_llp_agreement"},
    {label:"LLP Form 3", value : "llp_form_3"},
    {label:"LLP Form 5", value : "llp_form_5"},
    {label:"LLP Authority Letter", value : "llp_authority_letter"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Limited Companies / Corporations": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Board Resolution Copy", value : "board_resolution_copy"},
    {label:"Memorendum Articles", value : "memorendum_articles"},
    {label:"Certified Form A", value : "certified_form_a"},
    {label:"Certified Form B", value : "certified_form_b"},
    {label:"Certified Form 2", value : "certified_form_2"},
    {label:"Certified Form C", value : "certified_form_c"},
    {label:"Certified Form 29", value : "certified_form_29"},
    {label:"All Director Documents", value : "all_director_documents"},
    {label:"All Beneficial Documents", value : "all_beneficial_documents"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Branch Office or Liaison Office of Foreign Companies": [
    {label:"Board Of Investment Letter", value : "board_of_investment_letter"},
    {label:"Signatories Passport Documents", value : "signatories_passport_documents"},
    {label:"List Of Directors", value : "list_of_directors"},
    {label:"Certified Form 2", value : "certified_form_2"},
    {label:"Certified Form 3", value : "certified_form_3"},
    {label:"Authorization Letter", value : "authorization_letter"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Trust, Clubs, Societies & Associations etc": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Certificate Of Registration", value : "certificate_of_registration"},
    {label:"By Laws Regulations", value : "by_laws_regulations"},
    {label:"Resolutions", value : "resolutions"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "NGOs/NPOs/Charities": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Certificate Of Registration", value : "certificate_of_registration"},
    {label:"By Laws Regulations", value : "by_laws_regulations"},
    {label:"Annual Accounts Documents", value : "annual_accounts_documents"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  Agents: [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Registration Certificate", value : "registration_certificate"},
    {label:"Account Opening Request", value : "account_opening_request"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"},
    {lable:"All Partners Documents", value : "all_partners_documents"},
    {label:"Partnership Deed", value : "partnership_deed"},
    {label:"Partnership Registeration Document", value : "partnership_registeration_document"},
    {label:"Partnership Authority Letter", value : "partnership_authority_letter"},
    {label:"Account Opening Request", value : "account_opening_request"},
    {label:"Certified LLP Agreement", value : "certified_llp_agreement"},
    {label:"LLP Form 3", value : "llp_form_3"},
    {label:"LLP Form 5", value : "llp_form_5"},
    {label:"LLP Authority Letter", value : "llp_authority_letter"},
    {label:"Board Resolution Copy", value : "board_resolution_copy"},
    {label:"Memorendum Articles", value : "memorendum_articles"},
    {label:"Certified Form A", value : "certified_form_a"},
    {label:"Certified Form B", value : "certified_form_b"},
    {label:"Certified Form 2", value : "certified_form_2"},
    {label:"Certified Form C", value : "certified_form_c"},
    {label:"Certified Form 29", value : "certified_form_29"},
    {label:"All Director Documents", value : "all_director_documents"},
    {label:"All Beneficial Documents", value : "all_beneficial_documents"},
    {label:"Board Of Investment Letter", value : "board_of_investment_letter"},
    {label:"Signatories Passport Documents", value : "signatories_passport_documents"},
    {label:"List Of Directors", value : "list_of_directors"},
    {label:"Certified Form 3", value : "certified_form_3"},
    {label:"Authorization Letter", value : "authorization_letter"},
    {label:"Power Of Attorney", value : "power_of_attorney"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"}, 
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
    
  ],
  "Executors & Administrators": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Letter Administration Probate", value : "letter_administration_probate"},
    {label:"Registered Business Address Evidence", value : "registered_business_address_evidence"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
  "Minor Accounts": [
    {label:"CNIC Front Copy", value : "cnic_copy"},
    {label:"CNIC Back Copy", value : "cnic_back_copy"},
    {label:"Pay Slip", value : "pay_slip"},
    {label:"Employment Letter", value : "employment_letter"},
    {label:"Passport Copy", value : "passport_copy"},
    {label:"Guardian Documents", value : "guardian_documents"},
    {label:"Undertaking", value : "undertaking"},
    {label:"Signature Specimen", value : "signature_specimen"}
  ],
};

const ImagesUploadType =["png","jpg","jpeg"]

const COUNTRIES = [
  "PAK Pakistan",
"AS American Samoa",
"GI Gibraltar",
"TH Thailand",
"GE Georgia",
"SY Syrian Arab Republic",
"SE Sweden",
"SD Sudan",
"TN Tunisia",
"ER Eritrea",
"SL Sierra Leone",
"EC Ecuador",
"DO Dominican Republic",
"DJ Djibouti",
"CY Cyprus",
"CU Cuba",
"RU Russian Federation",
"SB Solomon Islands",
"QA Qatar",
"CR Costa Rica",
"PY Paraguay",
"PE Peru",
"OM Oman",
"PG Papua New Guinea",
"NI Nicaragua",
"AN Netherlands Antilles",
"NZ New Zealand",
"KH Cambodia",
"CM Cameroon",
"BG Bulgaria",
"AF Afghanistan",
"DZ Algeria",
"KI Kiribati",
"KP Korea, North",
"AO Angola",
"LV Latvia",
"KW Kuwait",
"LA Lao Peoples Democratic Republic",
"LB Lebanon",
"LR Liberia",
"LY Libyan Arab Jamahiriya",
"LI Liechtenstein",
"LT Lithuania",
"AZ Azerbaijan",
"BS Bahamas",
"BH Bahrain",
"MK Macedonia",
"BD Bangladesh",
"MG Madagascar",
"MW Malawi",
"BB Barbados",
"MY Malaysia",
"BY Belarus",
"MV Maldives",
"MU Mauritius",
"BT Bhutan",
"MD Moldova, Republic of",
"BV Bouvet Island",
"MA Morocco",
"NA Namibia",
"CN China",
"UA Ukraine",
"GT Guatemala",
"GY Guyana",
"VE Venezuela",
"ZM Zambia",
"JM Jamaica",
"IQ Iraq",
"JO Jordan",
"CX Christmas Island",
"TD Chad",
"BF Burkina Faso",
"BE Belgium",
"AT Austria",
"AU Australia",
"AG Antigua and Barbuda",
"BJ Benin",
"CA Canada",
"CF Central African Republic",
"GA Gabon",
"CC Cocos Kelling Island",
"CG Congo - Republic of",
"CK Cook Islands",
"DM Dominica",
"FO Faroe Islands",
"FI Finland",
"MQ Martinique",
"GR Greece",
"GU Guam",
"GW Guinea-Bissau",
"NR Nauru",
"PW Palau",
"VC Saint Vi-Grenadines",
"TW Taiwan",
"TK Tokelau",
"UG Uganda",
"NO Norway",
"PH Philippines",
"PN Pitcairn",
"PT Portugal",
"RO Romania",
"LC Saint Lucia",
"SN Senegal",
"CH Switzerland",
"YE Yemen",
"VG Virgin Islands - GB",
"AE United Arab Emirates",
"TV Tuvalu",
"HN Honduras",
"TT Trinidad and Tobago",
"FJ Fiji",
"ET Ethiopia",
"HT Haiti",
"TO Tonga",
"TJ Tajikistan",
"GS South Georgia - South Sandwich Islands",
"SC Seychelles",
"SA Saudi Arabia",
"ST Sao Tome and Principe",
"TP East Timor",
"DK Denmark",
"CZ Czech Republic",
"RW Rwanda",
"EG Egypt",
"PA Panama",
"CL Chile",
"KY Cayman Islands",
"BI Burundi",
"MM Myanmar",
"MZ Mozambique",
"AL Albania",
"KG Kyrgyzstan",
"AI Anguilla",
"AR Argentina",
"AW Aruba",
"MO Macau",
"LS Lesotho",
"MT Malta",
"ML Mali",
"MH Marshall Islands",
"BZ Belize",
"BM Bermuda",
"BA Bosnia and Herzegovina",
"BW Botswana",
"MN Mongolia",
"NP Nepal",
"TR Turkey",
"GN Guinea",
"KZ Kazakhstan",
"HK Hong Kong",
"VN Vietnam",
"IL Israel",
"IR Iran Islamic Republic of",
"HU Hungary",
"ZW Zimbabwe",
"VU Vanuatu",
"JP Japan",
"AD Andorra",
"IO British Indian Ocean Territory",
"GL Greenland",
"GP Guadeloupe",
"IN India",
"ID Indonesia",
"IE Ireland",
"MC Monaco",
"FM MicronesiaFederated",
"LU Luxembourg",
"IT Italy",
"DE Germany",
"GD Grenada",
"TC Turks-Caicos Islands",
"UM US Minor Outlying",
"KE Kenya",
"YU Yugoslavia",
"FK Falkland Islands Malvinas",
"GH Ghana",
"EE Estonia",
"SZ Swaziland",
"ZA South Africa",
"HR Croatia Hrvatska",
"SO Somalia",
"CD Congo-Brazzaville",
"KM Comoros",
"PL Poland",
"NG Nigeria",
"CV Cape Verde",
"NC New Caledonia",
"AM Armenia",
"MR Mauritania",
"BO Bolivia",
"BR Brazil",
"BN Brunei Darussalam",
"SV El Salvador",
"SR Suriname",
"GM Gambia",
"IS Iceland",
"CO Colombia",
"FR France",
"YT Mayotte",
"CI Ivory Coast",
"NL Netherlands",
"RE Reunion",
"KN Saint Kitts",
"NF Norfolk Island",
"NU Niue",
"VA Vatican City State",
"VI Virgin Islands - US",
"SM San Marino",
"SI Slovenia",
"WF Wallis-Futuna Island",
"UZ Uzbekistan",
"GF French Guiana",
"TF French Southern Terr",
"PF French Polynesia",
'GQ Equatorial Guinea',
"MS Montserrat",
"HM HeardMcDonald Island",
"MX Mexico",
"NE Niger",
"SJ Svalbard-Jan Mayen",
"EH Western Sahara",
"MP North Mariana Island",
"PR Puerto Rico",
"WS Samoa",
"SG Singapore",
"ES Spain",
"LK Sri Lanka",
"TM Turkmenistan",
"TG Togo",
"TZ Tanzania",
"KR Korea, South",
"US United States",
"UY Uruguay",
"SAT SAMAO"
]

const RELATION =[
  "Spouse",
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Daughter",
  "Son"

]

const OCCUPATION = [
  "P001 Agriculturist",
"P002 Business",
"P003 Business Executive",
"P005 House Hold",
"P006 House Wife",
"P007 Industrialist",
"P012 Professional",
"P013 Retired Person",
"P014 Service",
"P015 Student",
"P019 Govt. / Public Sector",
"P999 Others"
]
const MARITAL_STATUS =[
  "Single",
  "Married",
]

const GENDER = [
  "Male",
  "Female"
]
const E_WALLET_PROVIDERS =[
  "Askari Digital Wallet",	
"Upaisa",
"Zindigi"	,
"JazzCash",
"SADAPAY"	,
"NAYAPAY",
"UBL Digital Walle",
"Easypaisa",
"Bank Aflalah Wallet"	,
"HBL Kconnect",
"OTHER"
]

const SALUTATIONS = [
  "MR",
"MRS",
"MS",
""
]

const CITIES = [
  {name : "0001 BAGH", province:"07"},
  {name : "0002 BHIMBER", province:"07"},
  {name : "0003 DHUNGI", province:"07"},
  {name : "0004 KHUIRATTA", province:"07"},
  {name : "0005 KOTLI", province:"07"},
  {name : "0006 MANGLA", province:"07"},
  {name : "0007 MIRPUR", province:"07"},
  {name : "0008 MUZAFFARABAD", province:"07"},
  {name : "0009 PLANDRI", province:"07"},
  {name : "0010 PUNCH", province:"07"},
  {name : "0011 RAWALAKOT", province:"07"},
  {name : "0012 AMIR CHAH", province:"05"},
  {name : "0013 BAZDAR" , province:"05"},
  {name : "0014 BELA", province:"05"},
  {name : "0015 BELLPAT", province:"05"},
  {name : "0016 BHAG", province:"05"},
  {name : "0017 BURJ", province:"05"},
  {name : "0018 CHAGAI", province:"05"},
  {name : "0019 CHAH SANDAN", province:"05"},
  {name : "0020 CHAKKU", province:"05"},
  {name : "0021 CHAMAN", province:"05"},
  {name : "0022 CHHATR", province:"05"},
  {name : "0023 DALBANDIN", province:"05"},
  {name : "0024 DERA BUGTI", province:"05"},
  {name : "0025 DHANA SAR", province:"05"},
  {name : "0026 DIWANA", province:"05"},
  {name : "0027 DUKI", province:"05"},
  {name : "0028 DUSHI", province:"05"},
  {name : "0029 DUZAB", province:"05"},
  {name : "0030 GAJAR", province:"05"},
  {name : "0031 GANDAVA", province:"05"},
  {name : "0032 GARHI KHAIRO", province:"05"},
  {name : "0033 GARRUCK", province:"05"},
  {name : "0034 GHAZLUNA", province:"05"},
  {name : "0035 GIRDAN", province:"05"},
  {name : "0036 GULISTAN", province:"05"},
  {name : "0037 GWADAR", province:"05"},
  {name : "0038 GWASH", province:"05"},
  {name : "0039 HAB CHAUKI", province:"05"},
  {name : "0040 HAMEEDABAD", province:"05"},
  {name : "0041 HARNAI", province:"05"},
  {name : "0042 HINGLAJ", province:"05"},
  {name : "0043 HOSHAB", province:"05"},
  {name : "0044 HUB", province:"05"},
  {name : "0045 ISPIKAN", province:"05"},
  {name : "0046 JAFFERABAD", province:"05"},
  {name : "0047 JHAL", province:"05"},
  {name : "0048 JHAL JHAO", province:"05"},
  {name : "0049 JHAL MAGSI", province:"05"},
  {name : "0050 JHATPAT", province:"05"},
  {name : "0051 JIWANI", province:"05"},
  {name : "0052 KALANDI", province:"05"},
  {name : "0053 KALAT", province:"05"},
  {name : "0054 KAMARAROD", province:"05"},
  {name : "0055 KANAK", province:"05"},
  {name : "0056 KANDI", province:"05"},
  {name : "0057 KANPUR", province:"05"},
  {name : "0058 KAPIP", province:"05"},
  {name : "0059 KAPPAR", province:"05"},
  {name : "0060 KARODI", province:"05"},
  {name : "0061 KATURI", province:"05"},
  {name : "0062 KHARAN", province:"05"},
  {name : "0063 KHUZDAR", province:"05"},
  {name : "0064 KIKKI", province:"05"},
  {name : "0065 KOHAN", province:"05"},
  {name : "0066 KOHLU", province:"05"},
  {name : "0067 KORAK", province:"05"},
  {name : "0068 LAHRI", province:"05"},
  {name : "0069 LASBELA", province:"05"},
  {name : "0070 LIARI", province:"05"},
  {name : "0071 LORALAI", province:"05"},
  {name : "0072 MACH", province:"05"},
  {name : "0073 MAND", province:"05"},
  {name : "0074 MANGUCHAR", province:"05"},
  {name : "0075 MASHKI CHAH", province:"05"},
  {name : "0076 MASLTI", province:"05"},
  {name : "0077 MASTUNG", province:"05"},
  {name : "0078 MEKHTAR", province:"05"},
  {name : "0079 MERUI", province:"05"},
  {name : "0080 MIANEZ", province:"05"},
  {name : "0081 MURGHA KIBZAI", province:"05"},
  {name : "0082 MUSA KHEL BAZAR", province:"05"},
  {name : "0083 NAGHA KALAT", province:"05"},
  {name : "0084 NAL", province:"05"},
  {name : "0085 NASEERABAD", province:"05"},
  {name : "0086 NAUROZ KALAT", province:"05"},
  {name : "0087 NUR GAMMA", province:"05"},
  {name : "0088 NUSHKI", province:"05"},
  {name : "0089 NUTTAL", province:"05"},
  {name : "0090 ORMARA", province:"05"},
  {name : "0091 PALANTUK", province:"05"},
  {name : "0092 PANJGUR", province:"05"},
  {name : "0093 PASNI", province:"05"},
  {name : "0094 PIHARAK", province:"05"},
  {name : "0095 PISHIN", province:"05"},
  {name : "0096 QAMRUDDIN KAREZ", province:"05"},
  {name : "0097 QILA ABDULLAH", province:"05"},
  {name : "0098 QILA LADGASHT", province:"05"},
  {name : "0099 QILA SAFED", province:"05"},
  {name : "0100 QILA SAIFULLAH", province:"05"},
  {name : "0101 QUETTA", province:"05"},
  {name : "0102 RAKHNI", province:"05"},
  {name : "0103 ROBAT THANA", province:"05"},
  {name : "0104 RODKHAN", province:"05"},
  {name : "0105 SAINDAK", province:"05"},
  {name : "0106 JAFFERABAD", province:"05"},
  {name : "0107 SARUNA", province:"05"},
  {name : "0108 SHABAZ KALAT", province:"05"},
  {name : "0109 SHAHPUR", province:"05"},
  {name : "0110 SHARAM JOGIZAI", province:"05"},
  {name : "0111 SHINGAR", province:"05"},
  {name : "0112 SHORAP", province:"05"},
  {name : "0113 SIBI", province:"05"},
  {name : "0114 SONMIANI", province:"05"},
  {name : "0115 SPEZAND", province:"05"},
  {name : "0116 SPINTANGI", province:"05"},
  {name : "0117 SUI", province:"05"},
  {name : "0118 SUNTSAR", province:"05"},
  {name : "0119 SURAB", province:"05"},
  {name : "0120 THALO", province:"05"},
  {name : "0121 TUMP", province:"05"},
  {name : "0122 TURBAT", province:"05"},
  {name : "0123 UMARAO", province:"05"},
  {name : "0124 UTHAL", province:"05"},
  {name : "0125 KOHAN", province:"05"},
  {name : "0126 WADH", province:"05"},
  {name : "0127 WASHAP", province:"05"},
  {name : "0128 WASJUK", province:"05"},
  {name : "0129 YAKMACH", province:"05"},
  {name : "0130 ZHOB", province:"05"},
  {name : "0131 ZIARAT", province:"05"},
  {name : "0132 ISLAMABAD", province:"06"},
  {name : "0133 ABBOTTABAD", province:"04"},
  {name : "0134 ADEZAI", province:"04"},
  {name : "0135 AYUBIA", province:"04"},
  {name : "0136 BANDA DAUD SHAH", province:"04"},
  {name : "0137 BANNU", province:"04"},
  {name : "0138 BATAGRAM", province:"04"},
  {name : "0139 BIROTE", province:"04"},
  {name : "0140 BUNER", province:"04"},
  {name : "0141 CHAKDARA", province:"04"},
  {name : "0142 CHARSADDA", province:"04"},
  {name : "0143 CHITRAL", province:"04"},
  {name : "0144 DARGAI", province:"04"},
  {name : "0145 DARYA KHAN", province:"04"},
  {name : "0146 DERA ISMAIL KHAN", province:"04"},
  {name : "0147 DRASAN", province:"04"},
  {name : "0148 DROSH", province:"04"},
  {name : "0149 HANGU", province:"04"},
  {name : "0150 HARIPUR", province:"04"},
  {name : "0151 KALAM", province:"04"},
  {name : "0152 KARAK", province:"04"},
  {name : "0153 KHANASPUR", province:"04"},
  {name : "0154 KOHAT", province:"04"},
  {name : "0155 KOHISTAN", province:"04"},
  {name : "0156 LAKKI MARWAT", province:"04"},
  {name : "0157 LATAMBER", province:"04"},
  {name : "0158 LOWER DIR", province:"04"},
  {name : "0159 MADYAN", province:"04"},
  {name : "0160 MALAKAND", province:"04"},
  {name : "0161 MANSEHRA", province:"04"},
  {name : "0162 MARDAN", province:"04"},
  {name : "0163 MASTUJ", province:"04"},
  {name : "0164 MONGORA", province:"04"},
  {name : "0165 NOWSHERA", province:"04"},
  {name : "0166 PAHARPUR", province:"04"},
  {name : "0167 PESHAWAR", province:"04"},
  {name : "0168 SAIDU SHARIF", province:"04"},
  {name : "0169 SAKESAR", province:"04"},
  {name : "0170 SHANGLA", province:"04"},
  {name : "0171 SWABI", province:"04"},
  {name : "0172 SWAT", province:"04"},
  {name : "0173 TANGI", province:"04"},
  {name : "0174 TANK", province:"04"},
  {name : "0175 THALL", province:"04"},
  {name : "0176 TIMARGARA", province:"04"},
  {name : "0177 TORDHER", province:"04"},
  {name : "0178 UPPER DIR", province:"04"},
  {name : "0179 ASTOR", province:"01"},
  {name : "0180 BAJAUR", province:"01"},
  {name : "0181 BARAMULA", province:"01"},
  {name : "0182 DIR", province:"01"},
  {name : "0183 GILGIT", province:"01"},
  {name : "0184 HANGU", province:"01"},
  {name : "0185 KHYBER", province:"01"},
  {name : "0186 KURRAM", province:"01"},
  {name : "0187 MALAKAND", province:"01"},
  {name : "0188 MIRAM SHAH", province:"01"},
  {name : "0189 MOHMAND", province:"01"},
  {name : "0190 NAGAR", province:"01"},
  {name : "0191 NORTH WAZIRISTAN", province:"01"},
  {name : "0192 PARACHINAR", province:"01"},
  {name : "0193 PEACOCK", province:"01"},
  {name : "0194 SHANDUR", province:"01"},
  {name : "0195 SHANGRILA", province:"01"},
  {name : "0196 SKARDU", province:"01"},
  {name : "0197 SOUTH WAZIRISTAN", province:"01"},
  {name : "0198 WANA", province:"01"},
  {name : "0199 AHMED NAGER CHATHA", province:"03"},
  {name : "0200 AHMEDPUR EAST", province:"03"},
  {name : "0201 ALI PUR", province:"03"},
  {name : "0202 ARIFWALA", province:"03"},
  {name : "0203 ATTOCK", province:"03"},
  {name : "0204 BAHAWALNAGAR", province:"03"},
  {name : "0205 BAHAWALPUR", province:"03"},
  {name : "0206 BASTI MALOOK", province:"03"},
  {name : "0207 BHAGALCHUR", province:"03"},
  {name : "0208 BHAIPHERU", province:"03"},
  {name : "0209 BHAKKAR", province:"03"},
  {name : "0210 BHALWAL", province:"03"},
  {name : "0211 BUREWALA", province:"03"},
  {name : "0212 CHAILIANWALA", province:"03"},
  {name : "0213 CHAKWAL", province:"03"},
  {name : "0214 CHICHAWATNI", province:"03"},
  {name : "0215 CHINIOT", province:"03"},
  {name : "0216 CHOWK AZAM", province:"03"},
  {name : "0217 CHOWK SARWAR SHAHEED", province:"03"},
  {name : "0218 DARYA KHAN", province:"03"},
  {name : "0219 DASKA", province:"03"},
  {name : "0220 DERA GHAZI KHAN", province:"03"},
  {name : "0221 DERAWAR FORT", province:"03"},
  {name : "0222 DHAULAR", province:"03"},
  {name : "0223 DINA CITY", province:"03"},
  {name : "0224 DINGA", province:"03"},
  {name : "0225 DIPALPUR", province:"03"},
  {name : "0226 FAISALABAD", province:"03"},
  {name : "0227 FATEH JANG", province:"03"},
  {name : "0228 GADAR", province:"03"},
  {name : "0229 GHAKHAR MANDI", province:"03"},
  {name : "0230 GUJAR KHAN", province:"03"},
  {name : "0231 GUJRANWALA", province:"03"},
  {name : "0232 GUJRAT", province:"03"},
  {name : "0233 HAFIZABAD", province:"03"},
  {name : "0234 HAROONABAD", province:"03"},
  {name : "0235 HASILPUR", province:"03"},
  {name : "0236 HAVELI LAKHA", province:"03"},
  {name : "0237 JAHANIA", province:"03"},
  {name : "0238 JALLA ARAAIN", province:"03"},
  {name : "0239 JAMPUR", province:"03"},
  {name : "0240 JAUHARABAD", province:"03"},
  {name : "0241 JHANG", province:"03"},
  {name : "0242 JHELUM", province:"03"},
  {name : "0243 KALABAGH", province:"03"},
  {name : "0244 KAMALIA", province:"03"},
  {name : "0245 KAMOKEY", province:"03"},
  {name : "0246 KAROR LAL ESAN", province:"03"},
  {name : "0247 KASUR", province:"03"},
  {name : "0248 KHANEWAL", province:"03"},
  {name : "0249 KHANPUR", province:"03"},
  {name : "0250 KHARIAN", province:"03"},
  {name : "0251 KHUSHAB", province:"03"},
  {name : "0252 KOT ADDU", province:"03"},
  {name : "0253 LAAR", province:"03"},
  {name : "0254 LAHORE", province:"03"},
  {name : "0255 LALAMUSA", province:"03"},
  {name : "0256 LAYYAH", province:"03"},
  {name : "0257 LODHRAN", province:"03"},
  {name : "0258 MAILSI", province:"03"},
  {name : "0259 MAKHDOOM AALI", province:"03"},
  {name : "0260 MAMOORI", province:"03"},
  {name : "0261 MANDI BAHAUDDIN", province:"03"},
  {name : "0262 MANDI WARBURTON", province:"03"},
  {name : "0263 MIAN CHANNU", province:"03"},
  {name : "0264 MIANWALI", province:"03"},
  {name : "0265 MINAWALA", province:"03"},
  {name : "0266 MULTAN", province:"03"},
  {name : "0267 MURIDKE", province:"03"},
  {name : "0268 MURREE", province:"03"},
  {name : "0269 MUZAFFARGARH", province:"03"},
  {name : "0270 NAROWAL", province:"03"},
  {name : "0271 OKARA", province:"03"},
  {name : "0272 PAK PATTAN", province:"03"},
  {name : "0273 PANJGUR", province:"03"},
  {name : "0274 PATTOKI", province:"03"},
  {name : "0275 PIRMAHAL", province:"03"},
  {name : "0276 QILA DIDAR SINGH", province:"03"},
  {name : "0277 RABWAH", province:"03"},
  {name : "0278 RAHIM YAR KHAN", province:"03"},
  {name : "0279 RAIWIND", province:"03"},
  {name : "0280 RAJAN PUR", province:"03"},
  {name : "0281 RAWALPINDI", province:"03"},
  {name : "0282 RENALA KHURD", province:"03"},
  {name : "0283 ROHRI", province:"03"},
  {name : "0284 SADIQABAD", province:"03"},
  {name : "0285 SAFDARABAD-DABANSING", province:"03"},
  {name : "0286 SAHIWAL", province:"03"},
  {name : "0287 SAMBERIAL", province:"03"},
  {name : "0288 SANGLA HILL", province:"03"},
  {name : "0289 SARAI ALAMGIR", province:"03"},
  {name : "0290 SARGODHA", province:"03"},
  {name : "0291 SHAFQAT SHAHED CHOWK", province:"03"},
  {name : "0292 SHAKARGARH", province:"03"},
  {name : "0293 SHARAQPUR", province:"03"},
  {name : "0294 SHEIKHUPURA", province:"03"},
  {name : "0295 SIALKOT", province:"03"},
  {name : "0296 SOHAWA", province:"03"},
  {name : "0297 SOOIANWALA", province:"03"},
  {name : "0298 SUNDAR", province:"03"},
  {name : "0299 TAKHTBAI", province:"03"},
  {name : "0300 TALAGANG", province:"03"},
  {name : "0301 TARBELA", province:"03"},
  {name : "0302 TAXILA", province:"03"},
  {name : "0303 TOBA TEK SINGH", province:"03"},
  {name : "0304 VEHARI", province:"03"},
  {name : "0305 WAH CANTT", province:"03"},
  {name : "0306 WAZIRABAD", province:"03"},
  {name : "0307 ALI BANDAR", province:"02"},
  {name : "0308 BADEN", province:"02"},
  {name : "0309 CHACHRO", province:"02"},
  {name : "0310 DADU", province:"02"},
  {name : "0311 DAHARKI", province:"02"},
  {name : "0312 DIGRI", province:"02"},
  {name : "0313 DIPLO", province:"02"},
  {name : "0314 DOKRI", province:"02"},
  {name : "0315 GADRA", province:"02"},
  {name : "0316 GHANIAN", province:"02"},
  {name : "0317 GHAUSPUR", province:"02"},
  {name : "0318 GHOTKI", province:"02"},
  {name : "0319 GOTH MACHI", province:"02"},
  {name : "0320 HALA (SINDH) HALA", province:"02"},
  {name : "0321 HYDERABAD", province:"02"},
  {name : "0322 ISLAMKOT", province:"02"},
  {name : "0323 JACOBABAD", province:"02"},
  {name : "0324 JAMESABAD", province:"02"},
  {name : "0325 JAMSHORO", province:"02"},
  {name : "0326 JANGHAR", province:"02"},
  {name : "0327 JATI", province:"02"},
  {name : "0328 JHUDO", province:"02"},
  {name : "0329 JUNGSHAHI", province:"02"},
  {name : "0330 KAMBER", province:"02"},
  {name : "0331 KANDIARO", province:"02"},
  {name : "0332 KARACHI", province:"02"},
  {name : "0333 KASHMOR", province:"02"},
  {name : "0334 KETI BANDAR", province:"02"},
  {name : "0335 KHAIRPUR", province:"02"},
  {name : "0336 KHOKHROPUR", province:"02"},
  {name : "0337 KHORA", province:"02"},
  {name : "0338 KLUPRO", province:"02"},
  {name : "0339 KOT SARAE", province:"02"},
  {name : "0340 KOTRI", province:"02"},
  {name : "0341 LARKANA", province:"02"},
  {name : "0342 LUND", province:"02"},
  {name : "0343 MATHI", province:"02"},
  {name : "0344 MATIARI", province:"02"},
  {name : "0345 MEHAR", province:"02"},
  {name : "0346 MIRPUR BATORO", province:"02"},
  {name : "0347 MIRPUR KHAS", province:"02"},
  {name : "0348 MIRPUR SAKRO", province:"02"},
  {name : "0349 MITHANI", province:"02"},
  {name : "0350 MITHI", province:"02"},
  {name : "0351 MORO", province:"02"},
  {name : "0352 NAGAR PARKAR", province:"02"},
  {name : "0353 NAOKOT", province:"02"},
  {name : "0354 NAUDERO", province:"02"},
  {name : "0355 NAUSHARA", province:"02"},
  {name : "0356 NAUSHERO FEROZE", province:"02"},
  {name : "0357 NAWABSHAH", province:"02"},
  {name : "0358 NAZIMABAD", province:"02"},
  {name : "0359 MOIN JO DARO", province:"02"},
  {name : "0360 PENDOO", province:"02"},
  {name : "0361 POKRAN", province:"02"},
  {name : "0362 QAMBAR", province:"02"},
  {name : "0363 QAZI AHMAD", province:"02"},
  {name : "0364 RANIPUR", province:"02"},
  {name : "0365 RATODERO", province:"02"},
  {name : "0366 ROHRI", province:"02"},
  {name : "0367 SAIDU SHARIF", province:"02"},
  {name : "0368 SAKRAND", province:"02"},
  {name : "0369 SANGHAR", province:"02"},
  {name : "0370 SHADADKHOT", province:"02"},
  {name : "0371 SHAHBANDAR", province:"02"},
  {name : "0372 SHAHDADPUR", province:"02"},
  {name : "0373 SHAHPUR CHAKAR", province:"02"},
  {name : "0374 SHIKARPUR", province:"02"},
  {name : "0375 SUJAWAL", province:"02"},
  {name : "0376 SUKKUR", province:"02"},
  {name : "0377 TANDO ADAM", province:"02"},
  {name : "0378 TANDO ALAH YAR", province:"02"},
  {name : "0379 TANDO BAGO", province:"02"},
  {name : "0380 TAR AHAMD RIND", province:"02"},
  {name : "0381 THARPARKAR", province:"02"},
  {name : "0382 THATTA", province:"02"},
  {name : "0383 TUJAL", province:"02"},
  {name : "0384 UMARKOT", province:"02"},
  {name : "0385 VEIRWARO", province:"02"},
  {name : "0386 WARAH", province:"02"},
  {name : "0387 MIRPUR MATHELO", province:"02"},
  {name : "0388 PANOAKIL", province:"02"},
  {name : "0389 NANKANA SAHIB", province:"03"},
  {name : "0390 GOJRA", province:"03"},
  {name : "0391 JARANWALA", province:"03"},
  {name : "0392 CHUNIA", province:"03"},
  {name : "0393 KANDHKOT", province:"02"},
  {name : "0394 KHAIRPUR MIRIS", province:"02"},
  {name : "0395 UBARO", province:"02"},
  {name : "0396 TANDO MUHAMMAD KHAN", province:"02"},
  {name : "0397 MATLI", province:"02"},
  {name : "0398 SUDHNOTI", province:"07"},
  {name : "0399 HASAN ABDAL", province:"04"},
  {name : "0400 HUNZA", province:"01"},
  {name : "0401 TANDLIANWALA", province:"03"},
  {name : "0402 DADYAL", province:"07"},
  {name : "0403 NEELUM", province:"07"},
  {name : "0404 HATTIAN BALA", province:"07"},




]
const PROVINCES = [{value:"01 FATA / FANA" , label:"FATA / FANA"},
  {value:"02 SINDH" , label:"SINDH"},
  {value:"03 PUNJAB" , label:"PUNJAB"},
  {value:"04 KHYBER PAKHTUNKHWA" , label:"KHYBER PAKHTUNKHWA"},
  {value:"05 BALOCHISTAN" , label:"BALOCHISTAN"},
  {value:"06 FEDERAL C" , label:"FEDERAL C"},
]



export{FilesByInvestorType, ImagesUploadType,COUNTRIES , RELATION,OCCUPATION,MARITAL_STATUS,GENDER, E_WALLET_PROVIDERS, SALUTATIONS, CITIES,PROVINCES }