
import React, { useEffect } from 'react';
// import Header from './../components/Header';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { getAmc } from './../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  getFundByAmc,
  UpdateNavOfAllFunds,
  addDailyNav,
  getDailyNavOfAllFunds
} from './../stores/services/funds.service';
import { getNavDataForCalculation } from './../stores/services/nav.service';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTable } from 'react-icons/bs';
import moment from 'moment';
import { convertToObject } from 'typescript';
import { getChartOfAccounts, getLedgerInfoByCode } from "./../stores/services/chatofaccounts.service";
import { getLedgerAccounts, getLedgerInfoByLedgerCode, numberWithCommas } from '../utils/customFunction';
import { useSelector } from 'react-redux';
import { inWords } from '../stores/services/template.service';
import NumberFormat from 'react-number-format';

const NavComponent = () => {
  let date = new Date();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const email = sessionStorage.getItem('email') || '';
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  // const [amcdata, setAmcdata] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [NAVdate, setNAVDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [allDailyNav, setAllDailyNav] = useState<any>([]);

  const [funds, setFunds] = useState<any>([]);

  let [fundFees, setFundFees] = useState<any>({});
  let [navDataForCalculation, setNavDataForCalculation] = useState<any>({});
  let [finalData, setFinalData] = useState<any>([]);
  let [trusteeFeeLedger, setTrusteeFeeLedger] = useState<any>([]);

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  // const [chartofAccountsData, setChartOfAccountsData] = useState<any>([]);

  const [feeLedgers, setFeeLedgers] = useState([]);

  const [ledgers, setLedgers] = useState<any>([
    401010101, 301020101, 401010102, 30102010101, 401010201, 301020201, 401010202, 301020202, 401010501, 301020501
  ]);

  // React.useEffect(() => { 
  //   const fetchAmc = async () => {
  //     amcdata.length = 0;
  //     setAmcdata(amcdata);
  //     //get all Amc for dropdown
  //     try {
  //       const amcResponse = await getAmc(email);
  //       setAmcdata(amcResponse.data.data);
  //     } catch (error) {}
  //   };
  //   fetchAmc(); 
  // }, []);

  const getLedgerInfo = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      // if(response.data.status==200){
      let ledgersacc = getLedgerAccounts(
        chartOfAccounts[3]?.family?.[0]?.sub_family
      );
      // setChartOfAccountsData(response.data.data.main_family);
      let trusteeLedger = ledgersacc.filter((item) => {
        return item.code === 401010201;
      });
      trusteeFeeLedger = trusteeLedger;
      setTrusteeFeeLedger(trusteeFeeLedger);
      //  getledgers for fee
      let feeArray: any = [];
      Array.isArray(ledgers) && ledgers.map((ledger: any) => {
        feeArray.push(getLedgerInfoByLedgerCode(chartOfAccounts, ledger));
      })
      setFeeLedgers(feeArray);
      // }else{
      //   toast.error(response.data.message);
      // }
    } catch (error: any) {
      console.log("error:", error)
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  }

  React.useEffect(() => {
    const fetchFunds = async () => {
      //get all Amc for dropdown
      try {
        const response = await getFundByAmc(email, amcCode, fund_code?.length > 0 ? fund_code : "");
        setFunds(response.data.data);
      } catch (error) { }
    };
    fetchFunds();

  }, []);

  useEffect(() => {
    chartOfAccounts?.length > 0 && getLedgerInfo();
  }, [chartOfAccounts])

  const getDailyNav = async () => {
    setLoading(true);
    setAccFundLoading(true);
    allDailyNav.length = 0;
    setAllDailyNav(allDailyNav);
    try {
      const response = await getDailyNavOfAllFunds(email, amcCode, NAVdate);
      let fooArray = Object.entries(response.data);
      fooArray.forEach(([key, value]) => {
        allDailyNav.push(value)

      })
      setAccFundLoading(false);
      setLoading(false);
    } catch (error) { }
    setAccFundLoading(false);
    setLoading(false);
  };


  const [code, setCode] = useState('');

  const AddNavValue = async (obj: any) => {
    setUpdateLoading(true);
    try {
      const response = await addDailyNav(email, allDailyNav);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          getDailyNav();
        }, 3000)

      } else {
        toast.error(response.data.message);
      }
      setUpdateLoading(false);
    } catch (error: any) {
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      setUpdateLoading(false);
    }
  };

  const decimalValue = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };


  const getDataForNAVFundWise = async (fund_Code) => {
    try {
      setUpdateLoading(true);
      const response = await getNavDataForCalculation(email, fund_Code, NAVdate);
      if (response.data.status == 200) {
        if (response.data.data.recordData.length > 0) {
          let findFund = funds.filter((Item: any) => {
            return Item.symbol_code == fund_Code;
          });
          if (findFund.length > 0) {
            let obj = {
              management_fee: findFund[0].management_fee_percentage,
              sst_on_management_fee: findFund[0].sst_on_management_fee_percentage,
              //  trustee_fee:findFund[0],
              //  sst_on_trustee_fee:findFund[0],
              secp_fee: findFund[0].secp_fee_percentage,
              offer_price: findFund[0].offer_price,
              redemption_price: findFund[0].redemption_price
            }
            fundFees = obj;
            setFundFees(fundFees);
          } else {
            toast.error('Funds are not loaded yet');
          }
          navDataForCalculation = response.data.data;
          setNavDataForCalculation(navDataForCalculation);
          let res = await calculateData(response.data.data.recordData, fund_Code);
          finalData = res;
          setFinalData(finalData);
        } else {
          toast.error('Data not found!');
        }

      } else {
        toast.error(response.data.message);
      }
      setUpdateLoading(false);
    } catch (error: any) {
      setUpdateLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setUpdateLoading(false);
  }


  const calculateData = (resData, fund_Code) => {
    let newArray: any = [];
    let finalArray: any = [];
    let copyArray: any = [];
    newArray.length = 0;
    finalArray.length = 0;
    copyArray.length = 0;

    let total = 0;
    let totalUnits = 0;

    Array.isArray(resData) && resData.map((item: any) => {
      total = 0;
      totalUnits = 0;
      if (item.records.length > 0) {
        Array.isArray(item.records) && item.records.map((itm: any) => {
          // if(item.type== 'Transaction'){
          //   totalUnits += +itm.units;
          // }else{
          //   total += +itm.debit_amount + +itm.credit_amount;
          // }

          // Income, Equity  Totals
          if (item.name == 'Unrealized Gain / (Loss) on Equity Investment' || item.name == 'Unrealized Gain / (Loss) on Money Market' || item.name == 'Mark-up on Government Securities'
            || item.name == 'Mark-up on Debt Securities' || item.name == 'Mark-up on TDR' || item.name == 'Mark-up on Commercial Paper' || item.name == 'Mark-up on Call Deposits'
            || item.name == 'Mark-up on Letter of Placements' || item.name == 'Mark-up on Bank Deposits' || item.name == 'Mark-up on MTS Transaction'
            || item.name == 'Capital Gains / (Loss) Equity' || item.name == 'Capital Gains / (Loss) Equity' || item.name == 'Capital Gains / (Loss) Futures'
            || item.name == 'Capital Gains / (Loss) Money Market' || item.name == 'Dividend Income on Equity' || item.name == 'Dividend Income on Futures'
            || item.name == 'Income Others' || (item.name == 'Sale of Units' && item.type == 'Ledger') || (item.name == 'Redemption of Units' && item.type == 'Ledger')) {
            // total += +itm.debit_amount - +itm.credit_amount;

            total += +itm.credit_amount - +itm.debit_amount;
          }

          // Expense Totals
          if (item.name == 'Audit Fee' || item.name == 'PSX Listing Fee' || item.name == 'Custody Fee' || item.name == 'Printing Charges'
            || item.name == 'PACRA Rating Fee' || item.name == 'Bank Charges' || item.name == 'Brokerage Expenses Equity' || item.name == 'Sales Tax on Brokerage'
            || item.name == 'Brokerage Expenses Money Market' || item.name == 'Amortization of Discount / Premium' || item.name == 'Impairment of asset'
            || item.name == 'Expense Others' || item.name == 'Management Fee Ledgers' || item.name == 'Sales Tax on Mgt. Fee Ledgers' || item.name == 'Trustee Fee Ledgers'
            || item.name == 'Sale Tax on Trustee Fee Ledgers' || item.name == 'SECP Ledgers') {
            // total += +itm.credit_amount - +itm.debit_amount;

            total += +itm.debit_amount - +itm.credit_amount;
          }

          // if(item.name=='Audit Fee' || item.name=='PSX Listing Fee' || item.name=='Custody Fee' || item.name=='Printing Charges'){
          //   total += +itm.debit_amount - +itm.credit_amount;
          // }

          if ((item.name == 'Sale of Units' && item.type == 'Transaction')) {
            totalUnits += +itm.units;
          }
          if ((item.name == 'Redemption of Units' && item.type == 'Transaction')) {
            totalUnits -= +itm.units;
          }
          // if(item.type== 'Ledger'){
          //   total += +itm.debit_amount + +itm.credit_amount;
          // }
          // if(item.name == 'Trustee Fee Ledgers'){
          //   tf += +itm.debit_amount + +itm.credit_amount;
          // }

        });
      } else {
        total = 0;
      }
      if (item.type == 'Transaction') {
        newArray.push({
          name: item.name,
          type: item.type,
          total: totalUnits == 0 ? totalUnits.toString() : totalUnits.toFixed(2).toString(),
        });
      } else {
        newArray.push({
          name: item.name,
          type: item.type,
          total: total == 0 ? total.toString() : total.toFixed(2).toString(),
        })
      }
    });

    let total_gross_income = 0;
    let subTotalName = '';

    let expenses_before_charging = 0;
    let subTotalName2 = '';

    copyArray = newArray;
    Array.isArray(copyArray) && copyArray.map((item: any) => {
      if (item.name == 'Unrealized Gain / (Loss) on Equity Investment' || item.name == 'Unrealized Gain / (Loss) on Money Market' || item.name == 'Mark-up on Government Securities'
        || item.name == 'Mark-up on Debt Securities' || item.name == 'Mark-up on TDR' || item.name == 'Mark-up on Commercial Paper' || item.name == 'Mark-up on Call Deposits'
        || item.name == 'Mark-up on Letter of Placements' || item.name == 'Mark-up on Bank Deposits' || item.name == 'Mark-up on MTS Transaction'
        || item.name == 'Capital Gains / (Loss) Equity' || item.name == 'Capital Gains / (Loss) Equity' || item.name == 'Capital Gains / (Loss) Futures'
        || item.name == 'Capital Gains / (Loss) Money Market' || item.name == 'Dividend Income on Equity' || item.name == 'Dividend Income on Futures'
        || item.name == 'Income Others') {
        total_gross_income += +item.total;
        subTotalName = "Total Gross Income";
      }

      if (item.name == 'Audit Fee' || item.name == 'PSX Listing Fee' || item.name == 'Custody Fee' || item.name == 'Printing Charges'
        || item.name == 'PACRA Rating Fee' || item.name == 'Bank Charges' || item.name == 'Brokerage Expenses Equity' ||
        item.name == 'Sales Tax on Brokerage'
        || item.name == 'Brokerage Expenses Money Market' || item.name == 'Amortization of Discount / Premium' || item.name == 'Impairment of asset'
        || item.name == 'Expense Others') {
        expenses_before_charging += +item.total;
        subTotalName2 = "Expenses Before Charging";
      }
    });
    // newArray.push({name:subTotalName, total: total_gross_income.toFixed(2).toString()}, {name:subTotalName2, total: expenses_before_charging.toFixed(2).toString()});
    let net_assets_before_charging = parseFloat(navDataForCalculation.totalNetAssets) + total_gross_income + expenses_before_charging,
      management_fee = 0, sale_tax_on_management_fee = 0, secp_fee = 0, total_fee = 0,
      trustee_fee = 0, sst_on_trustee_fee = 0, net_income_loss_per_day = 0, closing_net_assets = 0, closing_units = parseFloat(navDataForCalculation.totalFundUnits), nav_per_units = 0,
      offer_per_unit = 0, redemption_per_unit = 0, change_in_nav_per_unit_amt = 0, change_in_nav_per_unit_perce = 0, one_day_return = 0;

    if (trusteeFeeLedger.length > 0) {
      Array.isArray(trusteeFeeLedger[0].slabs) && trusteeFeeLedger[0].slabs.map((Itm: any) => {
        if (net_assets_before_charging >= Itm.amountFrom && net_assets_before_charging <= Itm.amountTo) {
          trustee_fee = ((net_assets_before_charging - Itm.amountFrom) * (Itm.rate / 100) + Itm.fixedAmmount) / 365;
        }
      })
    } else {
      trustee_fee = 0;
    }

    sst_on_trustee_fee = trustee_fee * (13 / 100);

    let managementFee_Total = 0;
    let sstManagementFee_Total = 0;
    let trusteeFee_Total = 0;
    let sstTrusteeFee_Total = 0;
    let secpFee_Total = 0;

    Array.isArray(newArray) && newArray.map((item: any) => {
      // Net Assets Before Charging
      // if((item.name=='Total Gross Income') || (item.name=='Expenses Before Charging') || (item.name=='Sale of Units' && item.type=='Ledger') || (item.name=='Redemption of Units' && item.type=='Ledger')){
      //   net_assets_before_charging += parseFloat(item.total);
      // }
      if ((item.name == 'Sale of Units' && item.type == 'Ledger') || (item.name == 'Redemption of Units' && item.type == 'Ledger')) {
        net_assets_before_charging += parseFloat(item.total);
      }
      // fee calculations
      management_fee = net_assets_before_charging * (parseFloat(fundFees.management_fee) / 100) / 365;
      sale_tax_on_management_fee = management_fee * (parseFloat(fundFees.sst_on_management_fee) / 100);
      secp_fee = net_assets_before_charging * (parseFloat(fundFees.secp_fee) / 100);
      if (item.name == 'Trustee Fee Ledgers') {
        trusteeFee_Total = trustee_fee + parseFloat(item.total);
      }
      if (item.name == 'Sale Tax on Trustee Fee Ledgers') {
        sstTrusteeFee_Total = sst_on_trustee_fee + parseFloat(item.total);
      }

      if (item.name == 'Management Fee Ledger') {
        managementFee_Total = management_fee + parseFloat(item.total);
      }

      if (item.name == 'Sales Tax on Mgt. Fee Ledgers') {
        sstManagementFee_Total = sale_tax_on_management_fee + parseFloat(item.total);
      }

      if (item.name == 'SECP Ledgers') {
        secpFee_Total = secp_fee + parseFloat(item.total);
      }
      // total_fee = management_fee + sale_tax_on_management_fee + trustee_fee + sst_on_trustee_fee + secp_fee;
      total_fee = managementFee_Total + sstManagementFee_Total + trusteeFee_Total + sstTrusteeFee_Total + secpFee_Total;

      // Net Income/Loss Per Day
      //  if(item.name=='Total Gross Income' || item.name=='Expenses Before Charging'){
      //   net_income_loss_per_day = parseFloat(item.total) + total_fee;
      // }

      // net_income_loss_per_day = total_gross_income + expenses_before_charging + total_fee;
      net_income_loss_per_day = total_gross_income - expenses_before_charging - total_fee;


      // closing_net_assets = net_assets_before_charging + total_fee;
      closing_net_assets = net_assets_before_charging - total_fee;
      if ((item.name == 'Sale of Units' && item.type == 'Transaction') || (item.name == 'Redemption of Units' && item.type == 'Transaction')) {
        closing_units += parseFloat(item.total);
      }
      nav_per_units = closing_net_assets / closing_units;
      offer_per_unit = nav_per_units + (nav_per_units * (parseFloat(fundFees.offer_price) / 100));
      redemption_per_unit = nav_per_units - offer_per_unit * (parseFloat(fundFees.redemption_price) / 100);

      change_in_nav_per_unit_amt = nav_per_units - parseFloat(navDataForCalculation.nav);
      change_in_nav_per_unit_perce = (nav_per_units - parseFloat(navDataForCalculation.nav)) / parseFloat(navDataForCalculation.nav);
      one_day_return = (nav_per_units - parseFloat(navDataForCalculation.nav)) / (parseFloat(navDataForCalculation.nav) * 365);
      //  one_day_return = one_day_return / 100;

    });

    Array.isArray(feeLedgers) && feeLedgers.map((Item: any) => {
      Item.amc_code = amcCode;
      Item.fund_code = fund_Code;

      if (Item.gl_code == 401010101) {
        Item.debit_amount = management_fee.toFixed(2).toString();
        Item.credit_amount = '0';
        Item.type = 'Debit';
        Item.description = 'NAV-';
      }

      if (Item.gl_code == 301020101) {
        Item.debit_amount = '0';
        Item.credit_amount = management_fee.toFixed(2).toString();
        Item.type = 'Credit';
        Item.description = 'NAV-';
      }

      if (Item.gl_code == 401010102) {
        Item.debit_amount = sale_tax_on_management_fee.toFixed(2).toString();
        Item.credit_amount = '0';
        Item.type = 'Debit';
        Item.description = 'NAV-';
      }

      if (Item.gl_code == 30102010101) {
        Item.debit_amount = '0';
        Item.credit_amount = sale_tax_on_management_fee.toFixed(2).toString();
        Item.type = 'Credit';
        Item.description = 'NAV-';
      }
      if (Item.gl_code == 401010201) {
        Item.debit_amount = trustee_fee.toFixed(2).toString();
        Item.credit_amount = '0';
        Item.type = 'Debit';
        Item.description = 'NAV-';
      }
      if (Item.gl_code == 301020201) {
        Item.debit_amount = '0';
        Item.credit_amount = trustee_fee.toFixed(2).toString();
        Item.type = 'Credit';
        Item.description = 'NAV-';
      }
      if (Item.gl_code == 401010202) {
        Item.debit_amount = sst_on_trustee_fee.toFixed(2).toString();
        Item.credit_amount = '0';
        Item.type = 'Debit';
        Item.description = 'NAV-';
      }

      if (Item.gl_code == 301020202) {
        Item.debit_amount = '0';
        Item.credit_amount = sst_on_trustee_fee.toFixed(2).toString();
        Item.type = 'Credit';
        Item.description = 'NAV-';
      }
      if (Item.gl_code == 401010501) {
        Item.debit_amount = secp_fee.toFixed(2).toString();
        Item.credit_amount = '0';
        Item.type = 'Debit';
        Item.description = 'NAV-';
      }
      if (Item.gl_code == 301020501) {
        Item.debit_amount = '0';
        Item.credit_amount = secp_fee.toFixed(2).toString();
        Item.type = 'Credit';
        Item.description = 'NAV-';
      }
    });

    // finalArray['opening_net_assets']= navDataForCalculation.totalNetAssets;
    // finalArray['opening_units']= navDataForCalculation.totalFundUnits;
    // finalArray['net_assets_before_charging']= net_assets_before_charging.toFixed(2).toString();
    // finalArray['total_gross_income']= total_gross_income.toFixed(2).toString();
    // finalArray['expenses_before_charging']= expenses_before_charging.toFixed(2).toString();
    // // finalArray['management_fee']= management_fee.toFixed(2).toString();
    // // finalArray['sale_tax_on_management_fee']= sale_tax_on_management_fee.toFixed(2).toString();
    // // finalArray['trustee_fee']= trustee_fee.toFixed(2).toString();
    // // finalArray['sst_on_trustee_fee']= sst_on_trustee_fee.toFixed(2).toString();
    // // finalArray['secp_fee']= secp_fee.toFixed(2).toString();
    // finalArray['management_fee']= managementFee_Total.toFixed(2).toString();
    // finalArray['sale_tax_on_management_fee']= sstManagementFee_Total.toFixed(2).toString();
    // finalArray['trustee_fee']= trusteeFee_Total.toFixed(2).toString();
    // finalArray['sst_on_trustee_fee']= sstTrusteeFee_Total.toFixed(2).toString();
    // finalArray['secp_fee']= secpFee_Total.toFixed(2).toString();
    // finalArray['total_fee']= total_fee.toFixed(2).toString();
    // finalArray['net_income_loss_per_day']= net_income_loss_per_day.toFixed(2).toString();
    // finalArray['closing_net_assets']= closing_net_assets.toFixed(4).toString();
    // finalArray['closing_units']= closing_units.toFixed(4).toString();
    // finalArray['nav_per_units']= nav_per_units.toFixed(4).toString();
    // finalArray['offer_per_unit']= offer_per_unit.toFixed(4).toString();
    // finalArray['redemption_per_unit']= redemption_per_unit.toFixed(4).toString();
    // finalArray['change_in_nav_per_unit_amt']= change_in_nav_per_unit_amt.toFixed(2).toString();
    // finalArray['change_in_nav_per_unit_perce']= change_in_nav_per_unit_perce.toFixed(2).toString();
    // finalArray['one_day_return']= one_day_return.toString();
    let obj = {};
    obj['opening_net_assets'] = navDataForCalculation.totalNetAssets;
    obj['opening_units'] = navDataForCalculation.totalFundUnits;
    obj['net_assets_before_charging'] = net_assets_before_charging.toFixed(2).toString();
    obj['total_gross_income'] = total_gross_income.toFixed(2).toString();
    obj['expenses_before_charging'] = expenses_before_charging.toFixed(2).toString();
    // finalArray['management_fee']= management_fee.toFixed(2).toString();
    // finalArray['sale_tax_on_management_fee']= sale_tax_on_management_fee.toFixed(2).toString();
    // finalArray['trustee_fee']= trustee_fee.toFixed(2).toString();
    // finalArray['sst_on_trustee_fee']= sst_on_trustee_fee.toFixed(2).toString();
    // finalArray['secp_fee']= secp_fee.toFixed(2).toString();
    obj['management_fee'] = managementFee_Total.toFixed(2).toString();
    obj['sale_tax_on_management_fee'] = sstManagementFee_Total.toFixed(2).toString();
    obj['trustee_fee'] = trusteeFee_Total.toFixed(2).toString();
    obj['sst_on_trustee_fee'] = sstTrusteeFee_Total.toFixed(2).toString();
    obj['secp_fee'] = secpFee_Total.toFixed(2).toString();
    obj['total_fee'] = total_fee.toFixed(2).toString();
    obj['net_income_loss_per_day'] = net_income_loss_per_day.toFixed(2).toString();
    obj['closing_net_assets'] = closing_net_assets.toFixed(4).toString();
    obj['closing_units'] = closing_units.toFixed(4).toString();
    obj['nav_per_units'] = nav_per_units.toFixed(4).toString();
    obj['offer_per_unit'] = offer_per_unit.toFixed(4).toString();
    obj['redemption_per_unit'] = redemption_per_unit.toFixed(4).toString();
    obj['change_in_nav_per_unit_amt'] = change_in_nav_per_unit_amt.toFixed(2).toString();
    obj['change_in_nav_per_unit_perce'] = change_in_nav_per_unit_perce.toFixed(2).toString();
    obj['one_day_return'] = (one_day_return / 100).toFixed(4).toString();
    finalArray.push({ data: newArray });
    finalArray.push({ ledgers: feeLedgers });
    finalArray.push(obj);
    return finalArray;
  }

  const renderData = () => {
    return Array.isArray(allDailyNav) && allDailyNav.map((items: any, index: any) => {
      items.offer_rate = 'sale';
      return (
        <tr
          key={index}>
          <td className="align-text-top">{items.fund}</td>
          <td className="align-text-top">
            <div className="form-group d-flex flex-column align-items-start">
              <NumberFormat
                thousandSeparator={true}
                style={{ textAlign: 'right' }}
                className="form-control"
                value={items.total_net_asset || 0}
                id={`txt-tna-${index}`}
                onChange={(e) => {
                  items.total_net_asset = e.target.value?.replaceAll(",", "") || 0;
                  items.nav = +items.total_fund_units > 0 ? (items.total_net_asset / (items.total_fund_units || 0)).toFixed(4) : 0;
                  setCode(e.target.value?.replaceAll(",", ""));
                }}
              />
              <small className="w-100 mt-2">
                {(!items.total_net_asset || items.total_net_asset?.length > 16)
                  ? <span>&nbsp;</span>
                  : inWords(items.total_net_asset || 0)}
              </small>
            </div>
          </td>
          <td className="align-text-top">
            <div className="form-group d-flex flex-column align-items-start">
              <NumberFormat
                thousandSeparator={true}
                style={{ textAlign: 'right' }}
                className="form-control"
                value={items.total_fund_units || 0}
                id={`txt-tfu-${index}`}
                onChange={(e) => {
                  items.total_fund_units = e.target.value?.replaceAll(",", "") || 0;
                  items.nav = +items.total_fund_units > 0 ? (items.total_net_asset / items.total_fund_units).toFixed(4) : 0;
                  setCode(e.target.value?.replaceAll(",", ""));
                }}
              />
              <small className="w-100 mt-2">
                {(!items.total_fund_units || items.total_fund_units?.length > 16)
                  ? <span>&nbsp;</span>
                  : inWords(items.total_fund_units || 0)}
              </small>
            </div>
          </td>
          <td className="align-text-top">
            <div className="form-group d-flex flex-column align-items-start">
              <input type="number" className="form-control" readOnly
                value={items.nav || 0}
                onChange={(e) => {
                  var a = decimalValue(e.target.value, 4);
                  items.nav = a;
                  setCode(e.target.value);
                }}
              />
              <small className="w-100 mt-2">
                {(!items.nav || items.nav?.length > 16)
                  ? <span>&nbsp;</span>
                  : inWords(items.nav || 0)}
              </small>
            </div>
          </td>
          <td className="align-text-top">
            <div className="form-group d-flex flex-column align-items-start">
              <NumberFormat
                thousandSeparator={true}
                style={{ textAlign: 'right' }}
                decimalScale={4}
                className="form-control"
                value={items.offer_price || 0}
                onChange={(e) => {
                  var a = e.target.value?.replaceAll(",", "");
                  items.offer_price = a || 0;
                  setCode(e.target.value?.replaceAll(",", ""));
                }}
              />
              <small className="w-100 mt-2">
                {(!items.offer_price || items.offer_price?.length > 16)
                  ? <span>&nbsp;</span>
                  : inWords(items.offer_price || 0)}
              </small>
            </div>
          </td>
          <td className="align-text-top">
            <div className="form-group d-flex flex-column align-items-start">
              <NumberFormat
                thousandSeparator={true}
                style={{ textAlign: 'right' }}
                decimalScale={4}
                className="form-control"
                value={items.redemption_price || 0}
                onChange={(e) => {
                  var a = e.target.value?.replaceAll(",", "");
                  items.redemption_price = a || 0;
                  setCode(e.target.value?.replaceAll(",", ""));
                }}
              />
              <small className="w-100 mt-2">
                {(!items.redemption_price || items.redemption_price?.length > 16)
                  ? <span>&nbsp;</span>
                  : inWords(items.redemption_price || 0)}
              </small>
            </div>
          </td>
          <td className='text-center align-text-top'>
            <button className='btn btn-default btn-sm'
              disabled={Boolean(updateLoading)}
              onClick={async () => {
                await getDataForNAVFundWise(items.fund);
                setCode('start');
                items.nav = finalData[2].nav_per_units;
                items.total_net_asset = finalData[2].closing_net_assets;
                items.total_fund_units = finalData[2].closing_units;
                items.offer_price = finalData[2].offer_per_unit;
                items.redemption_price = finalData[2].redemption_per_unit;
                items.calculated_nav_data = JSON.stringify(finalData);
                setCode('done');
              }}>
              {/* Calculate */}<i className="fa fa-calculator text-info" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      );
    });
  };



  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header /> */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Change NAV of Funds</h4>
                </div>
                <div className="card-body">
                  <div className="row">

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>NAV Date:</label>
                        <input type="date" className="form-control"
                          value={NAVdate}
                          onChange={(e) => {
                            setNAVDate(e.target.value);

                          }} />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <label ></label>
                      <button
                        // className="btn btn-primary btn-block"
                        className="btn btn-primary mt-4"
                        disabled={Boolean(Loading)}
                        onClick={() => getDailyNav()}>
                        <i className='fas fa-search'></i> Load
                      </button>
                    </div>

                  </div>

                  {Loading ? (
                    <div className="d-flex justify-content-center my-5">
                      <i className="fa fa-spinner fa-spin fa-3x" ></i>
                    </div>
                  ) : (
                    allDailyNav.length > 0 ? (
                      <>
                        <div className='table-responsive' style={{ overflow: 'visible' }}>
                          <table className="table" id="myTable">
                            <thead className='btn-primary' style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'primary' }} >
                              <tr>
                                <th style={{ width: '10%' }} >Fund</th>
                                <th className='text-center' style={{ width: '15%' }}>Total Net Assets</th>
                                <th className='text-center' style={{ width: '15%' }}>Total Fund Units Issued</th>
                                <th className='text-center' style={{ width: '15%' }}>Daily NAV</th>
                                <th className='text-center' style={{ width: '15%' }}>Offer Price</th>
                                <th className='text-center' style={{ width: '15%' }}>Redemption Price</th>
                                <th className='text-center' style={{ width: '10%' }}>Calculate NAV</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">{renderData()}</tbody>
                          </table>
                        </div>

                        <div className="">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              AddNavValue(allDailyNav);
                            }}
                            disabled={Boolean(updateLoading)}
                          >
                            {updateLoading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Update</span>
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">Empty</p>
                      </div>
                    )


                  )}
                </div>


              </div>
            </Container>

          </Col>
        </Row>
      </div>


    </>
  );
};

export default NavComponent;

