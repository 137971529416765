var accountTitle_AccountNumber_Regex = /^[0-9a-zA-Z -]+$/;
function numberWithCommas(amount: any) {
  if (
    amount === "NaN" ||
    isNaN(amount)||
    amount === "" ||
    amount === undefined ||
    amount == null
  ) {
    return "0";
  } else {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
}
function toProperCase(str) {
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}

function getLedgerAccounts(data: any) {
  let accountsData = data.map((item: any, index: number) => {
    let gl = item.gl;
    let glData =gl ? gl.map((gl: any) => {
      if (gl.active == true) {
        return {
          plsCode: item.code,
          plsName: item.name,
          ...gl,
        };
      } else {
        return {};
      }
    }) : [{}];
    return glData;
  });
  const results = accountsData.flat().filter((element) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });
  // return accountsData.flat();
  return results;
}

// function getLedgersOfTxn(data:any){
//  const ledgers = data.main_family.map((item:any, index:number)=>{
//   let familyData = item.family;
//    const family = familyData.map((familyF:any, ind:number)=>{
//     let subData = familyF.sub_family;
//     const subFamily = subData.map((subfamily:any, i:number)=>{
//       if(subfamily.child_family!=undefined){
//         let child_family= subfamily.child_family;
//         const childFamilyData = child_family.map((itm:any,n:number)=>{
//           let gl= itm.gl;
//           const glData = gl.map((glItem:any, j:number)=>{
//             return{
//               main_family_code:item.code,
//               main_family_name:item.name,
//               family_code:familyF.code,
//               family_name:familyF.name,
//               sub_family_code:subfamily.code,
//               sub_family_name:subfamily.name,
//               child_family_code:itm.code,
//               child_family_name:itm.name,
//               ...glItem,
//             }
//           })
//         })
//       }else{
//         let gl= subfamily.gl;
//         const glData = gl.map((glItem:any, j:number)=>{
//           return{
//             main_family_code:item.code,
//             main_family_name:item.name,
//             family_code:familyF.code,
//             family_name:familyF.name,
//             sub_family_code:subfamily.code,
//             sub_family_name:subfamily.name,
//             child_family_code:'',
//             child_family_name:'',
//             ...glItem,
//           }
//         })
//       }
//     })
//    })
//  })
// }

function getLedgersByTxn(txnCategory, txnType: any) {
  const txn = txnCategory.filter((item: any) => {
    return item.api_name === txnType || item.txn_type === txnType;
  });
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item: any) => {
    if (Item.ledger_root.length > 1) {
      return {
        amount_type: Item.amount_type,
        desc: "",
        type: "Debit",
        amc_code: "",
        fund_code: "",
        account_number: "",
        account_title: "",
        bank_code: "",
        branch: "",
        balance: "",
        counter_account_number: "",
        counter_account_title: "",
        counter_bank: "",
        counter_branch: "",
        credit_amount: "0",
        debit_amount: "0",
        description: "",
        entered_by: "",
        evidence: "",
        instruction_date: "",
        instrument_by: "",
        instrument_date: "",
        instrument_no: "",
        instrument_type: "",
        realized: "",
        realized_by: "",
        realized_date: "",
      };
    } else {
      const findLedgers = Item.ledger_root.map((itm: any) => {
        return {
          ...itm,
          amount_type: Item.amount_type,
          desc: Item.desc,
          type: Item.type,
          amc_code: "",
          fund_code: "",
          account_number: "",
          account_title: "",
          bank_code: "",
          branch: "",
          balance: "",
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          credit_amount: "0",
          debit_amount: "0",
          description: "",
          entered_by: "",
          evidence: "",
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          realized: "",
          realized_by: "",
          realized_date: "",
        };
      });
      return findLedgers;
    }
  });


  return legders.flat();
}

function getLedgersByTxnAndPaymentype(
  txnCategory,
  txnType: any,
  paymentType: string
) {

  
  const txn = txnCategory.filter((item: any) => {
    return (
      (item.api_name === txnType || item.txn_type === txnType) &&
      item.payment_type == paymentType
    );
  });
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item: any) => {
    const findLedgers = Item.ledger_root.map((itm: any) => {
      return {
        ...itm,
        amount_type: Item.amount_type,
        desc: Item.desc,
        type: Item.type,
        amc_code: "",
        fund_code: "",
        account_number: "",
        account_title: "",
        bank_code: "",
        branch: "",
        balance: "",
        counter_account_number: "",
        counter_account_title: "",
        counter_bank: "",
        counter_branch: "",
        credit_amount: "0",
        debit_amount: "0",
        description: "",
        entered_by: "",
        evidence: "",
        instruction_date: "",
        instrument_by: "",
        instrument_date: "",
        instrument_no: "",
        instrument_type: "",
        realized: "",
        realized_by: "",
        realized_date: "",
      };
    });
    return findLedgers;
  });

  return legders.flat();
}

function getGrossAmountLedgersByTxn(txnCategory, txnType: any) {
  const txn = txnCategory.filter((item: any) => {
    return item.api_name === txnType || item.txn_type === txnType;
  });
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item: any) => {
    if (Item.ledger_root.length > 1 && Item.amount_type == "Gross Amount") {
      const findLedgers = Item.ledger_root.map((itm: any) => {
        return {
          ...itm,
          amount_type: Item.amount_type,
          desc: Item.desc,
          type: Item.type,
          amc_code: "",
          fund_code: "",
          account_number: "",
          account_title: "",
          bank_code: "",
          branch: "",
          balance: "",
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          credit_amount: "0",
          debit_amount: "0",
          description: "",
          entered_by: "",
          evidence: "",
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          realized: "",
          realized_by: "",
          realized_date: "",
        };
      });
      return findLedgers;
    } else {
      return {};
    }
  });

  const results = legders.flat().filter((element) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });

  return results;
}

function getSSTAmountLedgersByTxn(txnCategory, txnType: any) {
  const txn = txnCategory.filter((item: any) => {
    return item.api_name === txnType || item.txn_type === txnType;
  });
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item: any) => {
    if (Item.ledger_root.length > 1 && Item.amount_type == "SST/PST Amount") {
      const findLedgers = Item.ledger_root.map((itm: any) => {
        return {
          ...itm,
          amount_type: Item.amount_type,
          desc: Item.desc,
          type: Item.type,
          amc_code: "",
          fund_code: "",
          account_number: "",
          account_title: "",
          bank_code: "",
          branch: "",
          balance: "",
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          credit_amount: "0",
          debit_amount: "0",
          description: "",
          entered_by: "",
          evidence: "",
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          realized: "",
          realized_by: "",
          realized_date: "",
        };
      });
      return findLedgers;
    } else {
      return {};
    }
  });

  const results = legders.flat().filter((element) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });

  return results;
}

function getAllLedgers(data: any) {
  let finalLedgers: any = [];
  const ledgers = data?.map((item: any, index: number) => {
    let familyData = item.family;
    const family = familyData.map((familyF: any, ind: number) => {
      let subData = familyF.sub_family;
      const subFamily = subData.map((subfamily: any, i: number) => {
        if (subfamily.child_family != undefined) {
          let child_family = subfamily.child_family;
          const childFamilyData = child_family.map((itm: any, n: number) => {
            let gl = itm.gl;
            const glData = gl.map((glItem: any, j: number) => {
              let obj = { ...glItem };
              if (obj.active == true) {
                finalLedgers.push({ ...glItem });
              }
            });
          });
        } else {
          let gl = subfamily.gl;
          const glData = gl.map((glItem: any, j: number) => {
            let obj = { ...glItem };
            if (obj.active == true) {
              finalLedgers.push({ ...glItem });
            }
          });
        }
      });
    });
  });
  return finalLedgers;
}

function getCounterAccountLedgers(data: any, txnType: string) {
  const ledgers = [data].map((items: any, index: number) => {
    let gl = items.gl;
    let gls = gl.map((item: any) => {
      return {
        sub_family_code: items.code,
        sub_family_name: items.name,
        ...items,
      };
    });
    return gls;
  });
  return ledgers.flat();
}

function removeEmptyLedgers(ledgers: any) {
  const nonEmptyLedgers = ledgers.filter((Item: any) => {
    delete Item.desc;
    return +Item.debit_amount > 0 || +Item.credit_amount > 0;
  });
  return nonEmptyLedgers;
}

function getReveresedValue(value) {
  if (value == "NaN" || value == undefined) {
    value = "0";
  }

  if (+value < 0) {
    value = value.toString().replace("-", "");
  }
  if (+value > 0) {
    value = "(" + value + ")";
  }
  return numberWithCommas(value);
}

function getLedgerInfoByLedgerCode(chartOfAccounts, ledgerCode) {
  let obj = {};
  const ledgers = chartOfAccounts.map((item: any, index: number) => {
    let familyData = item.family;
    const family = familyData.map((familyF: any, ind: number) => {
      let subData = familyF.sub_family;
      const subFamily = subData.map((subfamily: any, i: number) => {
        if (subfamily.child_family != undefined) {
          let child_family = subfamily.child_family;
          const childFamilyData = child_family.map((itm: any, n: number) => {
            let gl = itm.gl;
            const glData = gl.map((glItem: any, j: number) => {
              if (glItem.code == ledgerCode) {
                obj = {
                  gl_code: glItem.code,
                  gl_name: glItem.name,
                  amount_type: "",
                  desc: "",
                  type: "",
                  amc_code: "",
                  fund_code: "",
                  account_number: "",
                  account_title: "",
                  bank_code: "",
                  branch: "",
                  balance: "",
                  counter_account_number: "",
                  counter_account_title: "",
                  counter_bank: "",
                  counter_branch: "",
                  credit_amount: "0",
                  debit_amount: "0",
                  description: "",
                  entered_by: "",
                  evidence: "",
                  instruction_date: "",
                  instrument_by: "",
                  instrument_date: "",
                  instrument_no: "",
                  instrument_type: "",
                  realized: "",
                  realized_by: "",
                  realized_date: "",
                  sub_family_code: subfamily.code,
                  sub_family_name: subfamily.name,
                  main_family_code: item.code,
                  main_family_name: item.name,
                  family_code: familyF.code,
                  family_name: familyF.name,
                  child_family_code: itm.code,
                  child_family_name: itm.name,
                };
              }
              //  finalLedgers.push({...glItem});
            });
          });
        } else {
          let gl = subfamily.gl;
          const glData = gl.map((glItem: any, j: number) => {
            if (glItem.code == ledgerCode) {
              obj = {
                gl_code: glItem.code,
                gl_name:
                  glItem.name == undefined ? glItem.account_title : glItem.name,
                amount_type: "",
                desc: "",
                type: "",
                amc_code: "",
                fund_code: "",
                account_number: "",
                account_title: "",
                bank_code: "",
                branch: "",
                balance: "",
                counter_account_number: "",
                counter_account_title: "",
                counter_bank: "",
                counter_branch: "",
                credit_amount: "0",
                debit_amount: "0",
                description: "",
                entered_by: "",
                evidence: "",
                instruction_date: "",
                instrument_by: "",
                instrument_date: "",
                instrument_no: "",
                instrument_type: "",
                realized: "",
                realized_by: "",
                realized_date: "",
                sub_family_code: subfamily.code,
                sub_family_name: subfamily.name,
                main_family_code: item.code,
                main_family_name: item.name,
                family_code: familyF.code,
                family_name: familyF.name,
                child_family_code: 0,
                child_family_name: "",
              };
            }
            //  finalLedgers.push({...glItem});
          });
        }
      });
    });
  });
  return obj;
}

function getNegativeValue(value) {
  if (value == "NaN" || value == undefined) {
    value = "0";
  }
  if (+value < 0) {
    value = "(" + value.replace("-", "") + ")";
  }
  return numberWithCommas(value);
}

function validDate(date) {
  if (date == "invalid date") {
    date = "";
  }
  return date;
}

function isNumber(value) {
  if (
    value == "" ||
     isNaN(value) ||
    value == "NaN" ||
    value == undefined ||
    value == "undefined"
  ) {
    value = "0";
  }
  return value;
}

const SectorCodes = [
  {"sector_code":"0801", "sector_name":"AUTOMOBILE ASSEMBLER"},
  {"sector_code":"0802", "sector_name":"AUTOMOBILE PARTS & ACCESSORIES"},
  {"sector_code":"0803", "sector_name":"CABLE & ELECTRICAL GOODS"},
  {"sector_code":"0804", "sector_name":"CEMENT"},
  {"sector_code":"0805", "sector_name":"CHEMICAL"},
  {"sector_code":"0806", "sector_name":"CLOSE - END MUTUAL FUND"},
  {"sector_code":"0807", "sector_name":"COMMERCIAL BANKS"},
  {"sector_code":"0808", "sector_name":"ENGINEERINGR"},
  {"sector_code":"0809", "sector_name":"FERTILIZER"},
  {"sector_code":"0810", "sector_name":"FOOD & PERSONAL CARE PRODUCTS"},
  {"sector_code":"0811", "sector_name":"GLASS & CERAMICS"},
  {"sector_code":"0812", "sector_name":"INSURANCE"},
  {"sector_code":"0813", "sector_name":"INV. BANKS / INV. COS. / SECURITIES COS."},
  {"sector_code":"0814", "sector_name":"JUTE"},
  {"sector_code":"0815", "sector_name":"LEASING COMPANIES"},
  {"sector_code":"0816", "sector_name":"LEATHER & TANNERIES"},
  {"sector_code":"0818", "sector_name":"MISCELLANEOUS"},
  {"sector_code":"0819", "sector_name":"MODARABAS"},
  {"sector_code":"0820", "sector_name":"OIL & GAS EXPLORATION COMPANIES"},
  {"sector_code":"0821", "sector_name":"OIL & GAS MARKETING COMPANIES"},
  {"sector_code":"0822", "sector_name":"PAPER & BOARD"},
  {"sector_code":"0823", "sector_name":"PHARMACEUTICALS"},
  {"sector_code":"0824", "sector_name":"POWER GENERATION & DISTRIBUTION"},
  {"sector_code":"0825", "sector_name":"REFINERY"},
  {"sector_code":"0826", "sector_name":"SUGAR & ALLIED INDUSTRIES"},
  {"sector_code":"0827", "sector_name":"SYNTHETIC & RAYON"},
  {"sector_code":"0828", "sector_name":"TECHNOLOGY & COMMUNICATION"},
  {"sector_code":"0829", "sector_name":"TEXTILE COMPOSITE"},
  {"sector_code":"0830", "sector_name":"TEXTILE SPINNING"},
  {"sector_code":"0831", "sector_name":"TEXTILE WEAVING"},
  {"sector_code":"0832", "sector_name":"TOBACCO"},
  {"sector_code":"0833", "sector_name":"TRANSPORT"},
  {"sector_code":"0834", "sector_name":"VANASPATI & ALLIED INDUSTRIES"},
  {"sector_code":"0835", "sector_name":"WOOLLEN"},
  {"sector_code":"0836", "sector_name":"REAL ESTATE INVESTMENT TRUST"},
  {"sector_code":"0837", "sector_name":"EXCHANGE TRADED FUNDS"},
  {"sector_code":"0838", "sector_name":"PROPERTY"},
  {"sector_code":"0", "sector_name":"Other"}
]

const CategoryTypesFundDropdown=[
  "Aggressive Income Fund",
  "Asset Allocation Fund",
  "Income Fund",
  "Stock Fund",
  "Fund of Fund",
  "Livestock",
  "Real Estate Investment Trust (REIT) Scheme",
  "Money Market Fund",
  "Islamic Money Market Fund",
  "Fixed Rate Money Market Fund",
  "Fixed Rate Islamic Money Market Fund",
  "Pension Fund",
  "Islamic Pension Fund",
  "Private Equity",
  "Venture Capital",
  "Special Managed Account (SMA)"

]

export {
  numberWithCommas,
  toProperCase,
  accountTitle_AccountNumber_Regex,
  getLedgerAccounts,
  getLedgersByTxn,
  getAllLedgers,
  getCounterAccountLedgers,
  getGrossAmountLedgersByTxn,
  getLedgersByTxnAndPaymentype,
  getSSTAmountLedgersByTxn,
  removeEmptyLedgers,
  getReveresedValue,
  getLedgerInfoByLedgerCode,
  getNegativeValue,
  validDate,
  isNumber,
  SectorCodes,
  CategoryTypesFundDropdown
};
