import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const getAllTaxRates = async (email: string, amc_code:string) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/tax/tax-rates/?email=${email}&amc_code=${amc_code}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getAllTaxRates(email, amc_code)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
        }
  }
  };

  const addTaxRate = async (
    amc_code: string,
    email: string,
    individual_filer: string,
    individual_non_filer: string,
    individual_exempted: string,
    corporate_filer: string,
    corporate_non_filer: string,
    corporate_exempted: string,
    period: string,
    start_date: string,
    end_date: string,
    category: string,
    cgt_exempt_after:number
  ) => {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/tax/tax-rates/add`;
    try {
      const result: any = await axios.post(
        url,
        {
          amc_code,
          email,
          individual_filer,
          individual_non_filer,
          individual_exempted,
          corporate_filer,
          corporate_non_filer,
          corporate_exempted,
          period,
          start_date,
          end_date,
          category,
          cgt_exempt_after
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return result;
    } catch (err: any) {
      if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
          return await addTaxRate(
            amc_code,
            email,
            individual_filer,
            individual_non_filer,
            individual_exempted,
            corporate_filer,
            corporate_non_filer,
            corporate_exempted,
            period,
          start_date,
          end_date,
          category,
          cgt_exempt_after
          );
        } else {
          throw err;
          // return result
        }
      } else {
        throw err;
      }
    }
  };

  const updateTaxRate = async (
    amc_code: string,
    email: string,
    individual_filer: string,
    individual_non_filer: string,
    individual_exempted: string,
    corporate_filer: string,
    corporate_non_filer: string,
    corporate_exempted: string,
    period: string,
    start_date: string,
    end_date: string,
    category: string,
    cgt_exempt_after:number
  ) => {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/tax/tax-rates/update`;
    try {
      const result: any = await axios.post(
        url,
        {
          amc_code,
          email,
          individual_filer,
          individual_non_filer,
          individual_exempted,
          corporate_filer,
          corporate_non_filer,
          corporate_exempted,
          period,
          start_date,
          end_date,
          category,
          cgt_exempt_after
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return result;
    } catch (err: any) {
      if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
          return await updateTaxRate(
            amc_code,
            email,
            individual_filer,
            individual_non_filer,
            individual_exempted,
            corporate_filer,
            corporate_non_filer,
            corporate_exempted,
            period,
            start_date,
            end_date,
            category,
            cgt_exempt_after
          );
        } else {
          throw err;
          // return result
        }
      } else {
        throw err;
      }
    }
  };


  export {getAllTaxRates, addTaxRate, updateTaxRate};