import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ToggleButton from 'react-toggle-button';
import { SectorCodes } from "../../../utils/customFunction";
import { addSalesCompany } from "../../../stores/services/sales.service";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ViewSales = () => {
    const amcCode = sessionStorage.getItem('amc_code') || '';
    const email = sessionStorage.getItem('email') || ''
    const history = useHistory();
    const [salesInHouse, setSalesInHouse] = useState(false);
    const [psxListed, setPSXListed] = useState(false)
    const [companyCode, setCompanyCode] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [logo, setLogo] = useState<any | null>("")
    const [logoFileName, setLogoFileName] = useState("")
    const logoRef = useRef<HTMLInputElement>(null)
    const [symbol, setSymbol] = useState("")
    const [type, setType] = useState("")
    const [isin, setISIN] = useState("")
    const [ntn, setNTN] = useState("")
    const [incorporationNumber, setIncorporationNumber] = useState("")
    const [sectorCode, setSectorCode] = useState("")
    const [website, setWebsite] = useState("")
    const [parentCompany, setParentCompany] = useState("")
    const [teams, setTeams] = useState<any>([])
    const [persons, setPersons] = useState<any>([])
    const [bankAccounts, setBankAccounts] = useState<any>([])
   
   

    useEffect(()=>{
        const obj = JSON.parse(sessionStorage.getItem("salesCompanyObj") || '{}')
        setSalesInHouse(obj.in_house)
        setCompanyCode(obj.company_code)
        setCompanyName(obj.company_name)
        setSymbol(obj.symbol)
        setIncorporationNumber(obj.incorporation_number)
        setISIN(obj.isin)
        setLogo(obj.logo)
        setNTN(obj.ntn)
        setParentCompany(obj.parent_company)
        setPersons(obj.persons)
        setPSXListed(obj.psx_listed)
        setSectorCode(obj.sector_code)
        setTeams(obj.teams)
        setType(obj.type)
        setWebsite(obj.website)
        setBankAccounts(obj.bank_accounts)


    },[])

    
   

    const renderTeamsData = () => {
        return teams.map((item: any, index: number) => {
            return (
                <tr key={index}>
                    <td>{item.team_id}</td>
                    <td>{item.team_name}</td>
                    <td>{item.team_members?.length}</td>
                    <td>{item.team_manager}</td>
                    <td>{item.status == true ? "Active" : "Deactive"}</td>
                    

                </tr>
            )
        })

    }

   

    const renderPersonsData = () => {

        return persons.map((item: any, index: number) => {
            return (
                <tr key={index}>

                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{teams.find(data => data.team_id == item.team_id)?.team_name || ""}</td>
                    <td>{item.mobile}</td>
                    <td>{item.joining_date ? moment(item.joining_date).format("YYYY-MM-DD") : ""}</td>
                    <td>{item.leaving_date ? moment(item.leaving_date).format("YYYY-MM-DD") : ""}</td>
                    <td>{item.status == true ? "Active" : "Deactive"}</td>

                  

                </tr>
            )
        })

    }
    
    const renderBankData = () => {

        return bankAccounts.map((item: any, index: number) => {
            return (
                <tr key={index}>

                    <td>{item.account_no}</td>
                    <td>{item.account_title}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.branch_name}</td>
                    <td>{item.default == true ? "YES" : "NO"}</td>

                 
                </tr>
            )
        })

    }

   
    


    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Container fluid>
                        <ToastContainer limit={1} />
                        <div className="input-holder">
                            <h4 className="card-title">View - Sales</h4>
                            <Link
                                to="/admin/sales"
                                className="t-3 ml-auto btn btn-primary btn-sm"
                                replace
                            >
                                <i className="fa fa-eye mr-2"></i> View All
                            </Link>
                        </div>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Sales Company</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="form-group">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={salesInHouse}
                                                            readOnly
                                                        />
                                                        <span className="form-check-sign" />
                                                        In house sale
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            <div className="form-group">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={psxListed}
                                                            readOnly
                                                        />
                                                        <span className="form-check-sign" />
                                                        PSX Listed
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Code*</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={companyCode}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Name*</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={companyName}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Symbol</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={symbol}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Type</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={type}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>ISIN</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={isin}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>NTN</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={ntn}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Incorporation Number</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={incorporationNumber}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Sector Code</label>
                                            <div  >
                                            <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={sectorCode}
                                                    readOnly
                                                />
                                               

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Website</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={website}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Parent Company</label>
                                            <div  >
                                                <input
                                                    type="text"
                                                    className={`form-control  w-100 `}
                                                    value={parentCompany}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <div className="multi-input pointer"
                                                >
                                                <div className="form-group">
                                                    {/* <div className="form-control">
                                                        {logoFileName === "" ? "Upload File" : logoFileName}
                                                    </div> */}
                                                    
                                                    {logo &&
                                                    <img
                                                    src={logo}
                                                    className="rounded mt-1"
                                                    
                                                    alt="logo"
                                                    // width="100px"
                                                    // height="100px"
                                                    style={{
                                                        maxWidth:'100px',
                                                        maxHeight:'100px',
                                                        width:'auto',
                                                        height:'auto'
                                                    }}
                                                  />}
                                                </div>

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {teams?.length > 0 &&
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="d-flex justify-content-between"
                                        >
                                            <div>Sales Team</div>

                                            
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            {teams?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Name</th>
                                                                <th>Members</th>
                                                                <th>Manager</th>
                                                                <th>Status</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderTeamsData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>}
                           {persons?.length > 0 &&
                            <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle
                                            tag="h4"
                                            className="d-flex justify-content-between"
                                        >
                                            <div>Sales Person</div>
                                           
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            {persons?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>

                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Team</th>
                                                                <th>Mobile</th>
                                                                <th>Joining Date</th>
                                                                <th>Leaving Date</th>
                                                                <th>Status</th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderPersonsData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>}
                            {bankAccounts?.length > 0 &&
                              <Col md="4">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4" className="d-flex justify-content-between">
                                            <div>Bank Account</div>
                                           
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <div className="table-responsive">
                                            {bankAccounts?.length > 0 &&
                                                <div className="input-size-pos mb-3 mt-2">
                                                    <table className="table   ">
                                                        <thead>
                                                            <tr>

                                                                <th>Account No</th>
                                                                <th>Account Title</th>
                                                                <th>Bank Name</th>
                                                                <th>Branch Name</th>
                                                                <th>Default</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderBankData()}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>}
                        </Row>

                       
                    </Container>
                </Col>
            </Row>
        </div>
    );
};

export default ViewSales;
