
const txnObj = [
  {
    type: 'brokagefee',
    tax_rate: 13,
    wht_it_rate: 12,
    wht_sst_rate: 20,
    counter_account: 'Equity Broker,Money Market Broker',
    required: [
      'counter_account_title',
      'detail',
      'total_charges',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst'
    ],
  },
  {
    type: 'corporate_announcements',
    counter_account: 'Other',
    required: [
      'symbol',
      'dividend_percentage',
      'credit_date',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      // 'fund_account',
      // 'account_title',
      // 'account_no',
      // 'bank',
      // 'branch',
      // 'mode_of_payment',
      'volume',
      'par_value',
      'bonus_percentage',
      'bonus_gross_volume',
      // 'tax_on_bonus',
      // 'tax_it_bonus_amount',
      // 'net_bonus_volume',
      'bonus_credit_date',
      'right_share_percentage',
      'right_share_gross_volume',
      // 'tax_on_right',
      // 'tax_it_right_share_amount',
      // 'net_right_share_volume',
      'right_share_credit_date',
      // 'tax_on_dvidend',
      // 'tax_wht_it',
      'credit_date',
      'announcement_date',
      'payment_type',
      'gross_amount',
      'net_amount',
      'total_market_value',
      'average_cost_before_right',
      'premium',
      'cost_after_right',
      'difference_right_price',
      'cost_right_entitled',
      'cost_right',
      'right_share_symbol',
    ],
  },
  {
    type: 'equityinvestment',
    counter_account: 'NCCPL Margin,NCCPL IDS,Equity Broker',
    required: [
      'counter_account_title',
      // 'settlement_date',
      'settlement_by',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'detail',
      'average_cost_amount',
      'gain_loss_amount',
    ],
  },
  {
    type: 'pledgedin',
    counter_account: 'Other',
    required: [
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'symbol',
      'volume',
    ],
  },

  {
    type: 'pledgedout',
    counter_account: 'Other',
    required: [
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'symbol',
      'volume',
    ],
  },
  {
    type: 'fundtransfer',
    counter_account: 'Fund',
    required: [
      'counter_account_type',
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'termdeposit',
    counter_account: 'Other',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'inflow',
    counter_account: 'Other',
    required: [
      'detail',
      'type',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'outflow',
    counter_account: 'Other',
    required: [
      'counter_account_title',
      'detail',
      'type',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'funddividendpayout',
    counter_account: 'Unit Holder',
    required: [
      'counter_account_title',
      'email',
      'dividend_date',
      'txnObj',
      'current_holding',
      'dividend_rate',
      'reinvest',
      'period',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'refund_of_capital',
      'gross_amount',
      'net_amount',
      'face_value',
    ],
  },
  {
    type: 'salereturn',
    counter_account: 'Unit Holder',
    required: [
      'remain_holding',
      'current_holding',
      'return_date',
      'sale_txn_no',
      'nav',
      'units',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'marginreturn',
    counter_account: 'NCCPL Margin',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  // {
  //   type: 'saleofsecurities',
  //   counter_account: 'Other',
  //   required: [
  //     'email',
  //     'txnr_type',
  //     // 'primary_dealer',
  //     // 'security_type',
  //     // 'issue_date',
  //     // 'maturity_date',
  //     // 'coupon_rate',
  //     // 'price', 
  //     // 'yield',
  //     // 'money_market_face_value',
  //     'amc_name',
  //     'fund_name',
  //     'instruction_date',
  //     'execution_date',
  //     'fund_account',
  //     'account_title',
  //     'account_no',
  //     'bank',
  //     'branch',
  //     'mode_of_payment',
  //     'payment_type',
  //     ,
  //     'gross_amount',
  //     'net_amount',
  //   ],
  // },
  // {
  //   type: 'maturity',
  //   counter_account: 'Other',
  //   required: [
  //     // 'maturity_type',
  //     // 'primary_dealer',
  //     // 'money_market_face_value',
  //     // 'security_type',
  //     // 'issue_date',
  //     // 'maturity_date',
  //     // 'coupon_rate',
  //     // 'price', 
  //     'email',
  //     'txnr_type',
  //     'amc_name',
  //     'fund_name',
  //     'instruction_date',
  //     'execution_date',
  //     'fund_account',
  //     'account_title',
  //     'account_no',
  //     'bank',
  //     'branch',
  //     'mode_of_payment',
  //     'payment_type',
  //     ,
  //     'gross_amount',
  //     'net_amount',
  //   ],
  // },
  // {
  //   type: 'moneymarketpurchase',
  //   counter_account: 'Money Market Broker',
  //   required: [
  //     // 'primary_dealer',
  //     // 'security_type',
  //     // 'issue_date',
  //     // 'maturity_date',
  //     // 'coupon_rate',
  //     // 'yield',
  //     // 'price',
  //     // 'money_market_face_value',
  //     'email',
  //     'txnr_type',
  //     'amc_name',
  //     'fund_name',
  //     'instruction_date',
  //     'execution_date',
  //     'fund_account',
  //     'account_title',
  //     'account_no',
  //     'bank',
  //     'branch',
  //     'mode_of_payment',
  //     'payment_type',
  //     'gross_amount',
  //     'net_amount',
  //   ],
  // },
  {
    type: 'debtmarketinvestment',
    //counter_account: 'other',
    counter_account: 'Money Market Broker',
    required: [
      // 'primary_dealer',
      // 'security_type',
      // 'issue_date',
      // 'maturity_date',
      // 'coupon_rate',
      // 'yield',
      // 'price',
      // 'money_market_face_value',
      'counter_account_title',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'nccpl',
    counter_account: 'Other',
    required: [
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'bankprofit',
    counter_account: 'Other',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'bankcharges',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'Other',
    required: [
      'detail',
      // 'type',
      // 'total_charges',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      // 'tax_sst',
      // 'tax_wht_it',
      // 'tax_wht_sst',
    ],
  },
  {
    type: 'redemptionofunits',
    counter_account: 'Unit Holder',
    required: [
      'counter_account_title',
      'units',
      'nav',
      'folio_no',
      'redemption_date',
      'current_holding',
      'remain_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'redemption_load',
      'tax_cgt',
      'offer_price',
      'load_per_unit',
      'percentage_of_discount',
      'applicable_offer_price',
      'detail'
    ],
  },
  {
    type: 'refundpayment',
    counter_account: 'Other',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'feepayment',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'Other',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'cgt',
    counter_account: 'Other',
    required: [
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'saleofunit',
    counter_account: 'Unit Holder',
    required: [
      'folio_no',
      'unitholder_name',
      'units',
      'nav',
      'sale_date',
      'current_holding',
      'total_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'sale_load',
      'offer_price',
      'load_per_unit',
      'percentage_of_discount',
      'applicable_offer_price',
      'detail'
    ],
  },
  {
    type: 'transferofunits',
    // counter_account: 'Unit Holder',
    required: [
      'folio_no',
      'unitholder_name',
      'current_holding',
      'remain_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'payment_type',
    ],
  },
  {
    type: 'gainrealization',
    counter_account: 'Unit Holder',
    required: [
      'nav',
      'redemption_date',
      'current_holding',
      'units',
      'remain_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'unitconversion',
    counter_account: 'Fund',
    required: [
      'counter_account_title',
      'units',
      'nav',
      'redemption_date',
      'current_holding',
      'remain_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'redemption_load',
      'tax_cgt',
      'offer_price',
      'load_per_unit',
      'percentage_of_discount',
      'applicable_offer_price',
      'detail',
      "counter_type"
    ],
  },

  {
    type: 'conversionin',
    counter_account: 'CDC',
    required: [
      'units',
      'nav',
      'conversion_date',
      'current_holding',
      'total_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'sale_load',
      'offer_price',
      'load_per_unit',
      'percentage_of_discount',
      'applicable_offer_price',
      'detail'
    ],
  },

  {
    type: 'conversionout',
    counter_account: 'CDC',
    required: [
      'counter_account_title',
      'units',
      'nav',
      'redemption_date',
      'current_holding',
      'remain_holding',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'redemption_load',
      'tax_cgt',
      'offer_price',
      'load_per_unit',
      'percentage_of_discount',
      'applicable_offer_price',
      'detail'
    ],
  },
  {
    type: 'auditorsfee',
    tax_rate: 8,
    wht_it_rate: 10,
    wht_sst_rate: 20,
    counter_account: 'Auditor',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'redemptionload',
    counter_account: 'amc',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'advisoryfee',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'Advisor',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'cashmargin',
    counter_account: 'NCCPL Margin',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'taxpayment',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'FBR,SRB,PST',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'tax_type',
      'total_charges',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      //'tax_sst',
      //'tax_wht_it',
      //'tax_wht_sst',
    ],
  },
  {
    type: 'callback',
    counter_account: 'Other',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'cdcfee',
    tax_rate: 13,
    wht_it_rate: 8,
    wht_sst_rate: 20,
    counter_account: 'CDC',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'listingfee',
    tax_rate: 0,
    wht_it_rate: 3,
    wht_sst_rate: 20,
    counter_account: 'PSX',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'managementfee',
    tax_rate: 13,
    wht_it_rate: 3,
    wht_sst_rate: 0,
    counter_account: 'AMC',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'otherpayment',
    counter_account: 'Other',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'total_charges',
    ],
  },
  {
    type: 'printingfee',
    tax_rate: 17,
    wht_it_rate: 4.50,
    wht_sst_rate: 20,
    counter_account: 'Printing',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'professionalfee',
    tax_rate: 8,
    wht_it_rate: 10,
    wht_sst_rate: 20,
    counter_account: 'Legal / Professional',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'ratingfee',
    tax_rate: 13,
    wht_it_rate: 8,
    wht_sst_rate: 20,
    counter_account: 'PACRA,VIS Credit Rating',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'saleload',
    tax_rate: 13,
    wht_it_rate: 12,
    wht_sst_rate: 0,
    counter_account: 'amc',
    required: [
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
    ],
  },
  {
    type: 'trusteefee',
    tax_rate: 13,
    wht_it_rate: 8,
    wht_sst_rate: 20,
    counter_account: 'Trustee',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'custodyfee',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'Custody',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'shariahadvisoryfee',
    tax_rate: 13,
    wht_it_rate: 8,
    wht_sst_rate: 20,
    counter_account: 'Shariah Advisory',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
  {
    type: 'secpfee',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'SECP',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
     // 'tax_sst',
      //'tax_wht_it',
      //'tax_wht_sst',
      //'total_charges',
    ],
  },
  {
    type: 'otherpayment',
    tax_rate: 0,
    wht_it_rate: 0,
    wht_sst_rate: 0,
    counter_account: 'Other',
    required: [
      'counter_account_title',
      'detail',
      'email',
      'txnr_type',
      'amc_name',
      'fund_name',
      'instruction_date',
      'execution_date',
      'fund_account',
      'account_title',
      'account_no',
      'bank',
      'branch',
      'mode_of_payment',
      'payment_type',
      'gross_amount',
      'net_amount',
      'tax_sst',
      'tax_wht_it',
      'tax_wht_sst',
      'total_charges',
    ],
  },
];

const fieldValue = {
  email: '',
  txnr_type: '',
  amc_name: '',
  fund_name: '',
  instruction_date: '',
  execution_date: '',
  executed_date: '',
  fund_account: '',
  account_title: '',
  account_no: '',
  bank: '',
  branch: '',
  counter_account_type: '',
  counter_account_title: '',
  counter_account_number: '',
  counter_bank: '',
  counter_branch: '',
  mode_of_payment: '',
  payment_type: '',
  instrument_no: '',
  instrument_type: '',
  instrument_date: '',
  realized_date: '',
  gross_amount: '',
  net_amount: '',
  dr_amount: '',
  cr_amount: '',
  balance: '',
  folio_no: '',
  unitholder_name: '',
  units: '',
  nav: '',
  sale_date: '',
  current_holding: '',
  conversion_date: '',
  associated_transaction: '',
  total_holding: '',
  symbol: '',
  dividend_percentage: '',
  credit_date: '',
  maturity_type: '',
  security_type: '',
  issue_date: '',
  maturity_date: '',
  coupon_rate: '',
  price: '',
  face_value: '',
  money_market_face_value: '',
  primary_dealer: '',
  detail: '',
  type: '',
  redemption_date: '',
  remain_holding: '',
  settlement_by: '',
  total_charges: '',
  txn_charges: '',
  broker: '',
  market: '',
  listed_compother: '',
  quantity: '',
  rate: '',
  amount: '',
  other_tax: '',
  tax_sst:'',
  tax_wht_it:'',
  tax_wht_sst:'',
  tax_cgt:'',
  sale_load:'',
  redemption_load:''
};


const Tax_Txn = [
  'brokagefee',
  'auditorsfee',
  'cdcfee',
  'saleload',
  'listingfee',
  'managementfee',
  'printingfee',
  'professionalfee',
  'ratingfee',
  'trusteefee',
  'shariahadvisoryfee',
];

const checkTaxBoolean = (type:any) => {
  return Tax_Txn.includes(type);
};

const getTaxObj = (type:any) => {
  let tx;
  txnObj.map((item, index) => {
    if (item.type === type) {
      tx = {
        tax_rate: item.tax_rate,
        wht_it_rate: item.wht_it_rate,
        wht_sst_rate: item.wht_sst_rate,
      };
    }
  });
  return tx;
};


const fieldData = () => {
  return txnObj;
};

const transactionObj = (type :any) => {
  let tx;
  txnObj.map((item, index) => {
    if (item.type === type) {
      tx = item.required;
    }
  });
  return tx;
};

const chechFieldDisplay = (type : any, name : any) => {
  let tx;
  txnObj.map((item, index) => {
    if (item.type === type) {
      tx = item.required.includes(name);
    }
  });
  return tx;
};

const getCounterAccount = (type : any) => {
  let tx;
  txnObj.map((item, index) => {
    if (item.type === type) {
      tx = item.counter_account;
    }
  });
  return tx;
};

export {
  fieldData,
  transactionObj,
  getCounterAccount,
  fieldValue,
  chechFieldDisplay,
  checkTaxBoolean,
  getTaxObj,
};

  