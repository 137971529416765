import React from 'react';
import { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import {
  getChecklist,
  deleteCheckList,
  searchCheckListAPI,
} from '../../../stores/services/checklist.service';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';

const CheckList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  const [isSearchActive, setSearchActive] = useState(false);
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');



  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getAllChecklists = async () => {
      setLoading(true);
      try {
        const response = await getChecklist(email, '', activePage, pageSize,amc_code);
        // let pageArr = paginationArr;
        // let existingPageIndex = paginationArr.findIndex(
        //   (x) => x === response.data.page_info.next_page_number
        // );
        // if (
        //   existingPageIndex === -1 &&
        //   response.data.data.length === 10 &&
        //   10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        // ) {
        //   pageArr.push(response.data.page_info.next_page_number);
        // }
        // setPaginationArr(pageArr);
        setData(response.data.data.sort((a, b) => a.serial_no - b.serial_no));
        setLoading(false);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getAllChecklists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].serial_no;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const searchCheckListData = async () => {
    setLoading(true);
    try {
      const response = await searchCheckListAPI(email, searchValue,amc_code);
      setData(response.data.data);
    } catch (error:any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      getUpdatedChecklists()
    } else {
      setSearchValue(e.target.value);
    }
  }

  const getUpdatedChecklists = async () => {
    setLoading(true);
    try {
      const response = await getChecklist(email, '' ,activePage, pageSize,amc_code);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data.sort((a, b) => a.serial_no - b.serial_no));
    } catch (error:any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.serial_no}</td>
          <td>{items.message}</td>
          <td>{items.role}</td>
          <td className=" pointer"> 
          
            <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
            onClick={() => {
              setStatusSetPopup(true);
              setCode(items.serial_no);
            }}>
          
            <i className="fa fa-trash">
          </i>
          </button>

          <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
            onClick={() => {
              sessionStorage.setItem('checklistObj', JSON.stringify(items));
              history.replace(`/admin/view-checklist`);
            }}
            >
          
            <i className="fa fa-eye">
          </i>
          </button>
 
          <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
            onClick={() => {
              sessionStorage.setItem('checklistObj', JSON.stringify(items));
              history.replace(`/admin/edit-checklist`);
            }}
            >
          
            <i className="tim-icons icon-pencil">
          </i>
          </button>


          </td>
        </tr>
      );
    });
  };

  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [code, setCode] = useState('');

  const deleteChecklistRow = async () => {
    setDeleteLoading(true);
    try {
      const response = await deleteCheckList(email, code);
      toast.success('Check List Deleted');
      getUpdatedChecklists();
    } catch (error:any) {
      toast.error(error.response.data.message);
    }
    setDeleteLoading(false);
    setStatusSetPopup(false);
  };

  const renderComponents = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
          >
            <div className="modal-header justify-content-center">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setStatusSetPopup(false);
                  }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Action</h6>
              </div>

            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    <img src="assets/ban.svg" alt="" width="70" />
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to delete this CheckList?
                  </div>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div>
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(deleteLoading)}
                        onClick={() => {
                          deleteChecklistRow();
                        }}
                      >
                        {deleteLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          'Yes'
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className=" ">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Master - CheckList Listing
            <div
              className="btn btn-primary btn-sm float-right"
              onClick={() => {
                 history.replace('/admin/setup-checklist');
              }}
            >
              <i className="fa fa-plus mr-2"></i> Add New
            </div></h4>
          </div>
          
          {/* {Loading ? ( */}
            <div className="card-body">
              
              <Row>
                <Col md="4">
                <div className="form-group ">
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />
                  <input
                    type="search"
                    id="myInput"
                    data-tip="Enter Title"
                    className="form-control"
                    value={searchValue}
                    placeholder="Title"
                    onChange={(e) => {
                      // setSearchValue(e.target.value);
                      // myFunction(e);
                      handleSearchValue(e);
                    }}
                    onKeyPress={(e)=>{
                      let key_board_keycode = e.which || e.keyCode;
                      if(key_board_keycode == 13)
                      {
                          e.preventDefault();
                          searchCheckListData()
                      }
                    }}
                  />  
                </div>
                </Col>

                <Col md="4">
                  <div>
                    <button className="btn btn-primary mt-a" onClick={searchCheckListData}> 
                      <i className="fa fa-search"></i> 
                      Search
                    </button>
                  </div>
                  </Col>
                  </Row>

              <div className="">
                <table className="table ">
                  <thead>
                    <tr>
                      <th>Serial.# / Sequence # (Number)</th>
                      <th>Title</th>
                      <th>Role</th>
                      <th  >Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderData()}</tbody>
                </table>
                {!isSearchActive && (
                <div
                  className="row pointer pr-3"
                  style={{
                    float: 'right',
                  }}
                >
                  {/* PREVIOUS BUTTON */}
                  {activePage !== paginationArr[0] ? (
                    <i
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                      onClick={() => {
                        let pageArr = paginationArr;
                        let index = pageArr.findIndex((x) => x === activePage);
                        if (index !== -1) {
                          setActivePage(paginationArr[index - 1]);
                        }
                      }}
                    ></i>
                  ) : (
                    ''
                  )}
                  {/* NEXT BUTTON */}
                  {activePage !== paginationArr[paginationArr.length - 1] ? (
                    <i
                      className="fa ml-3 fa-arrow-right"
                      aria-hidden="true"
                      onClick={() => {
                        let pageArr = paginationArr;
                        let index = pageArr.findIndex((x) => x === activePage);
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);
                        }
                      }}
                    ></i>
                  ) : (
                    ''
                  )}
                </div>
                )}
              </div>
            </div>
          {/* ) : (
            <div className="d-flex">
              <img src="assets/loader.svg" className="m-auto" width="100" />
            </div>
          )} */}
        </div>
        {renderComponents()}
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default CheckList;
