import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';


export const addDailyFundortfolio = async (email: string, amc_code: string) => {
    const url = `${Config.baseUrl}/dailyfundportfolio/take-snap`;
    try{
    const result: any = await axios.post(
      url,
      { email, amc_code},
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
    }catch(err:any) {
      if(err.response.data.status == 401) { 
        let responseToHandle = err.response.data
        let result = await RefreshTokenHandler.handleIt(responseToHandle)
        if(result.status) {
         return await addDailyFundortfolio(email, amc_code)
        }else {
          throw err;
          // return result
        }
      }else {
            throw err
      }
    }
  };


  export const getDailyFundPortfolioByDate = async (
    email: string,
    amc_code: string,
    created_date: string
  ) => {
    const url = `${Config.baseUrl}/dailyfundportfolio/by-created-date?email=${email}&created_at=${created_date}&amc_code=${amc_code}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getDailyFundPortfolioByDate(email,amc_code,created_date)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
  };