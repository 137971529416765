import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getPaginatedInvestor = async (
  email: string,
  amc_code: string,
  investor_id: string,
  status: string,
  investor_type: string,
  cnic: string,
  ntn: string,
  passport_no: string,
  investor_email: string,
  reinvest: string,
  zakat_exempt: string,
  filer: string,
  in_house_sale: string,
  sales_company: string,
  sales_person: string,
  partnership_registered: string,
  verified: string
) => {
  const url = `${Config.baseUrl}/investor/paginate?email=${email}&amc_code=${amc_code}&investor_id=${investor_id}&status=${status}&investor_type=${investor_type}&cnic=${cnic}&ntn=${ntn}&passport_no=${passport_no}&investor_email=${investor_email}&reinvest=${reinvest}&zakat_exempt=${zakat_exempt}&filer=${filer}&in_house_sale=${in_house_sale}&sales_company=${sales_company}&sales_person=${sales_person}&partnership_registered=${partnership_registered}&verified=${verified}`;
  try {
    const result: any = await axios.get(
      url,
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedInvestor(email, amc_code, investor_id, status, investor_type, cnic, ntn, passport_no, investor_email, reinvest, zakat_exempt, filer, in_house_sale, sales_company, sales_person, partnership_registered, verified);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getAllInvestor = async (
    email: string,
    amc_code: string,
    investor_id: string,
    status: string,
    investor_type: string,
    cnic: string,
    ntn: string,
    passport_no: string,
    investor_email: string,
    reinvest: string,
    zakat_exempt: string,
    filer: string,
    in_house_sale: string,
    sales_company: string,
    sales_person: string,
    partnership_registered: string,
    verified: string
  ) => {
    const url = `${Config.baseUrl}/investor?email=${email}&amc_code=${amc_code}&investor_id=${investor_id}&status=${status}&investor_type=${investor_type}&cnic=${cnic}&ntn=${ntn}&passport_no=${passport_no}&investor_email=${investor_email}&reinvest=${reinvest}&zakat_exempt=${zakat_exempt}&filer=${filer}&in_house_sale=${in_house_sale}&sales_company=${sales_company}&sales_person=${sales_person}&partnership_registered=${partnership_registered}&verified=${verified}`;
    try {
      const result: any = await axios.get(
        url,
        {
          headers: {
            Authorization: sessionStorage.getItem("token") || "",
          },
        }
      );
      return result;
    } catch (err: any) {
      if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
          return await getAllInvestor(email, amc_code, investor_id, status, investor_type, cnic, ntn, passport_no, investor_email, reinvest, zakat_exempt, filer, in_house_sale, sales_company, sales_person, partnership_registered, verified);
        } else {
          throw err;
        }
      } else {
        throw err;
      }
    }
  };

const getInvestorByID = async (email: string, amc_code: string, investor_id: string) => {
    try {
    const url = `${Config.baseUrl}/investor/investor-id?email=${email}&amc_code=${amc_code}&investor_id=${investor_id}`;

        const result: any = await axios.get(url, {
        headers: {
            Authorization: sessionStorage.getItem("token") || "",
        },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
            return await getInvestorByID(email, amc_code, investor_id);
        } else {
            throw err;
        }
        } else {
        throw err;
        }
    }
}

const addInvestor = async (data: any) => {
    try{

        const url = `${Config.baseUrl}/investor`
        const formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          let temp = keys[i];
    
          if (
            typeof data[temp] == "object" &&
            temp != "zakat_exempt_document_file" &&
            temp != "joint_h_1_cnic_file" &&
            temp != "joint_h_1_ntn_document_file" &&
            temp != "joint_h_1_passport_copy_file" &&
            temp != "joint_h_1_address_bill_copy_file" &&
            temp != "joint_h_1_pay_slip_file" &&
            temp != "joint_h_1_employment_letter_file" &&
            temp != "joint_h_2_cnic_file" &&
            temp != "joint_h_2_ntn_document_file" &&
            temp != "joint_h_2_passport_copy_file" &&
            temp != "joint_h_2_address_bill_copy_file" &&
            temp != "joint_h_2_pay_slip_file" &&
            temp != "joint_h_2_employment_letter_file" &&
            temp != "joint_h_3_cnic_file" &&
            temp != "joint_h_3_ntn_document_file" &&
            temp != "joint_h_3_passport_copy_file" &&
            temp != "joint_h_3_address_bill_copy_file" &&
            temp != "joint_h_3_pay_slip_file" &&
            temp != "joint_h_3_employment_letter_file" &&
            temp != "cnic_copy_file" &&
            temp != "ntn_verification_file" &&
            temp != "passport_copy_file" &&
            temp != "address_bill_file" &&
            temp != "pay_slip_file" &&
            temp != "employment_letter_file" &&
            temp != "registration_certificate_file" &&
            temp != "account_opening_request_file" &&
            temp != "registered_business_address_evidence_file" &&
            temp != "all_partners_documents_file" &&
            temp != "partnership_deed_file" &&
            temp != "partnership_registeration_document_file" &&
            temp != "partnership_authority_letter_file" &&
            temp != "certified_llp_agreement_file" &&
            temp != "llp_form_5_file" &&
            temp != "llp_form_3_file" &&
            temp != "llp_authority_letter_file" &&
            temp != "board_resolution_copy_file" &&
            temp != "memorendum_articles_file" &&
            temp != "certified_form_a_file" &&
            temp != "certified_form_29_file" &&
            temp != "certified_form_b_file" &&
            temp != "certified_form_2_file" &&
            temp != "certified_form_c_file" &&
            temp != "all_director_documents_file" &&
            temp != "all_beneficial_documents_file" &&
            temp != "board_of_investment_letter_file" &&
            temp != "signatories_passport_documents_file" &&
            temp != "list_of_directors_file" &&
            temp != "certified_form_3_file" &&
            temp != "authorization_letter_file" &&
            temp != "certificate_of_registration_file" &&
            temp != "by_laws_regulations_file" &&
            temp != "resolutions_file" &&
            temp != "annual_accounts_documents_file" &&
            temp != "power_of_attorney_file" &&
            temp != "letter_administration_probate_file" &&
            temp != "guardian_documents_file" &&
            temp != "joint_h_1_cnic_back_copy_file" &&
            temp != "joint_h_2_cnic_back_copy_file" &&
            temp != "joint_h_3_cnic_back_copy_file" &&
            temp != "cnic_back_copy_file" &&
            temp != "signature_specimen_file" &&
            temp != "undertaking_file"
            
          ) {
            formData.append(temp, JSON.stringify(data[temp]));
          } else{
            formData.append(temp, data[temp]);
          }
         
        }

   
      const result: any = await axios.post(url, formData, {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      });
            return result;

    }catch (err: any) {
        if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
            return await addInvestor(data);
        } else {
            throw err;
        }
        } else {
        throw err;
        }
    }
}

const updateInvestor = async (data: any) => {
    try{

        const url = `${Config.baseUrl}/investor/update`

        const formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          let temp = keys[i];
    
          if (
            typeof data[temp] == "object" &&
            temp != "zakat_exempt_document_file" &&
            temp != "joint_h_1_cnic_file" &&
            temp != "joint_h_1_ntn_document_file" &&
            temp != "joint_h_1_passport_copy_file" &&
            temp != "joint_h_1_address_bill_copy_file" &&
            temp != "joint_h_1_pay_slip_file" &&
            temp != "joint_h_1_employment_letter_file" &&
            temp != "joint_h_2_cnic_file" &&
            temp != "joint_h_2_ntn_document_file" &&
            temp != "joint_h_2_passport_copy_file" &&
            temp != "joint_h_2_address_bill_copy_file" &&
            temp != "joint_h_2_pay_slip_file" &&
            temp != "joint_h_2_employment_letter_file" &&
            temp != "joint_h_3_cnic_file" &&
            temp != "joint_h_3_ntn_document_file" &&
            temp != "joint_h_3_passport_copy_file" &&
            temp != "joint_h_3_address_bill_copy_file" &&
            temp != "joint_h_3_pay_slip_file" &&
            temp != "joint_h_3_employment_letter_file" &&
            temp != "cnic_copy_file" &&
            temp != "ntn_verification_file" &&
            temp != "passport_copy_file" &&
            temp != "address_bill_file" &&
            temp != "pay_slip_file" &&
            temp != "employment_letter_file" &&
            temp != "registration_certificate_file" &&
            temp != "account_opening_request_file" &&
            temp != "registered_business_address_evidence_file" &&
            temp != "all_partners_documents_file" &&
            temp != "partnership_deed_file" &&
            temp != "partnership_registeration_document_file" &&
            temp != "partnership_authority_letter_file" &&
            temp != "certified_llp_agreement_file" &&
            temp != "llp_form_5_file" &&
            temp != "llp_form_3_file" &&
            temp != "llp_authority_letter_file" &&
            temp != "board_resolution_copy_file" &&
            temp != "memorendum_articles_file" &&
            temp != "certified_form_a_file" &&
            temp != "certified_form_29_file" &&
            temp != "certified_form_b_file" &&
            temp != "certified_form_2_file" &&
            temp != "certified_form_c_file" &&
            temp != "all_director_documents_file" &&
            temp != "all_beneficial_documents_file" &&
            temp != "board_of_investment_letter_file" &&
            temp != "signatories_passport_documents_file" &&
            temp != "list_of_directors_file" &&
            temp != "certified_form_3_file" &&
            temp != "authorization_letter_file" &&
            temp != "certificate_of_registration_file" &&
            temp != "by_laws_regulations_file" &&
            temp != "resolutions_file" &&
            temp != "annual_accounts_documents_file" &&
            temp != "power_of_attorney_file" &&
            temp != "letter_administration_probate_file" &&
            temp != "guardian_documents_file" &&
            temp != "joint_h_1_cnic_back_copy_file" &&
            temp != "joint_h_2_cnic_back_copy_file" &&
            temp != "joint_h_3_cnic_back_copy_file" &&
            temp != "cnic_back_copy_file" &&
            temp != "signature_specimen_file" &&
            temp != "undertaking_file"
            
          ) {
            formData.append(temp, JSON.stringify(data[temp]));
          } else{
            formData.append(temp, data[temp]);
          }
         
        }

        const result: any = await axios.post(url,formData, {
            headers: {
                Authorization: sessionStorage.getItem("token") || "",
            },
            });
            return result;

    }catch (err: any) {
        if (err.response.data.status == 401) {
        let responseToHandle = err.response.data;
        let result = await RefreshTokenHandler.handleIt(responseToHandle);
        if (result.status) {
            return await updateInvestor(data);
        } else {
            throw err;
        }
        } else {
        throw err;
        }
    }
}


const updateInvestorStatus = async (
  email: string,
  amc_code: string,
  investor_id: string,
  status: boolean
) => {
  const url = `${Config.baseUrl}/investor/status`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        investor_id,
        status,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateInvestorStatus(email, amc_code, investor_id, status);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const generateEmailOTPInvestor = async (
  email: string,
  amc_code: string,
  user_email: string
) => {
  const url = `${Config.baseUrl}/investor/generate-email-otp`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        user_email,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await generateEmailOTPInvestor(email, amc_code, user_email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const verifyEmailOTPInvestor = async (
  email: string,
  amc_code: string,
  otp,
  user_email: string
) => {
  const url = `${Config.baseUrl}/investor/verify-email-otp`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        otp,
        user_email,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await verifyEmailOTPInvestor(
          email,
          amc_code,
          otp,
          user_email
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const generateMobileOTPInvestor = async (
  email: string,
  amc_code: string,
  user_email: string
) => {
  const url = `${Config.baseUrl}/investor/generate-mobile-otp`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        user_email,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await generateMobileOTPInvestor(email, amc_code, user_email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const verifyMobileOTPInvestor = async (
  email: string,
  amc_code: string,
  otp,
  user_email: string
) => {
  const url = `${Config.baseUrl}/investor/verify-mobile-otp`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        otp,
        user_email,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await verifyMobileOTPInvestor(
          email,
          amc_code,
          otp,
          user_email
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {getPaginatedInvestor,getAllInvestor,getInvestorByID, addInvestor, updateInvestor,updateInvestorStatus,generateEmailOTPInvestor,verifyEmailOTPInvestor,generateMobileOTPInvestor, verifyMobileOTPInvestor };
