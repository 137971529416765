import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../stores/services/funds.service";
import { getChartOfAccounts } from "../stores/services/chatofaccounts.service";
import { getAllLedgers, getLedgerAccounts } from "../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { numberWithCommas } from "./../utils/customFunction";

import { useSelector } from "react-redux";
import { ThemeContext } from "../contexts/ThemeContext";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyExpense = (props: any) => {
  //for scrolling to top on submission
  const context = useContext(ThemeContext);
  const myRef = React.useRef<any>(null);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  const [date, setDate] = useState<any>(new Date());
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");
  const [deleting, setDeleting] = useState<any>(false);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "dailyexpense",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: 0,
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  React.useEffect(() => {
    setHeadLabel("dailyexpense");
    fields["txnr_type"] = "dailyexpense";
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code : "");
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    const getBankAccounts = async () => {
      try {
        // const response = await getChartOfAccounts(email);

        // if (response.data.status == 200) {
        let ledgersacc = getLedgerAccounts(
          chartOfAccounts[3].family[0].sub_family
        );

        setLegderAccounts(ledgersacc);

        let allLedgers = await getAllLedgers(chartOfAccounts);
        setAllLedgers(allLedgers);
        // await ledgerDropDown(allLedgers);
        // } else {
        //   toast.error(response.data.message);
        // }
      } catch (error) {}
    };
    getFundByAMcCode(amc_code);
    getBankAccounts();
  }, []);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  // const renderBanksDropdown = () => {
  //   return (
  //     ledgerAccounts &&
  //     ledgerAccounts.map((item: any, index: number) => {
  //       return (
  //         <option key={index} value={item.code}>
  //           {item.account_title}
  //         </option>
  //       );
  //     })
  //   );
  // };

  // const handleAdd = () => {
  //   if (fund === "") {
  //     return toast.error("Fund is required");
  //   }
  //   // if (bank === "") {
  //   //   return toast.error("Bank is required");
  //   // }
  //   if (amc_code === "") {
  //     return toast.error("Invalid user. Login Again");
  //   }

  //   if (tableData.length === 0) {
  //     setTableData([
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   } else {
  //     setTableData((prevState) => [
  //       ...prevState,
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   }

  // };

  const handleGetAccounts = async () => {
    setTableData([]);
    setLoading(true);
    setDisableProfit(false);
    setProfitRateConfirmed(false);

    if (fund === "") {
      setLoading(false);
      return toast.error("Fund is required");
    }
    // if (bank === "") {
    //   return toast.error("Bank is required");
    // }
    if (amc_code === "") {
      setLoading(false);
      return toast.error("Invalid user. Login Again");
    }

    // if (!date) {
    //   setLoading(false);
    //   return toast.error("Date is required");
    // }

    const response = await getDailyBankBalance(fund);
    let bankBalance = response.data.data;

    if (ledgerAccounts) {
      ledgerAccounts.map((item, index) => {
        let yearsDays = moment().isLeapYear() ? 366 : 365;
        let dailyQuarter = (parseFloat(item.quarterly_fee) / Math.floor(yearsDays/4)).toFixed(2);
        let dailyHalf = (parseFloat(item.half_yearly_fee) / Math.floor(yearsDays/2)).toFixed(2);
        let dailyYearly = (parseFloat(item.yearly_fee) / yearsDays).toFixed(2);

        if (index === 0) {
          if (item.daily_expense === false) {
            return;
          } else if (
            parseInt(dailyQuarter) === 0 &&
            parseInt(dailyHalf) === 0 &&
            parseInt(dailyYearly) === 0
          ) {
            return;
          } else {
            let totalExpense =
              parseFloat(dailyQuarter) +
              parseFloat(dailyHalf) +
              parseFloat(dailyYearly);

            return setTableData([
              {
                id: index,
                fund_code: fund,
                amc_code: amc_code,
                gl_code: item.code,
                gl_name: item.name,
                quarterly_fee: item.quarterly_fee,
                half_yearly_fee: item.half_yearly_fee,
                yearly_fee: item.yearly_fee,
                // annual_payment: item.annual_payment,

                daily_quarterly_expense: dailyQuarter,
                daily_half_yearly_expense: dailyHalf,
                daily_yearly_expense: dailyYearly,
                total_expense: totalExpense.toFixed(2),

                associated_ledgers: item.associated_ledgers,
              },
            ]);
          }
        } else {
          if (item.daily_expense === false) {
            return;
          } else if (
            parseInt(dailyQuarter) === 0 &&
            parseInt(dailyHalf) === 0 &&
            parseInt(dailyYearly) === 0
          ) {
            return;
          } else {
            let totalExpense =
              parseFloat(dailyQuarter) +
              parseFloat(dailyHalf) +
              parseFloat(dailyYearly);

            return setTableData((prevState) => [
              ...prevState,
              {
                id: index,
                fund_code: fund,
                amc_code: amc_code,
                gl_code: item.code,
                gl_name: item.name,
                quarterly_fee: item.quarterly_fee,
                half_yearly_fee: item.half_yearly_fee,
                yearly_fee: item.yearly_fee,
                // annual_payment: item.annual_payment,

                daily_quarterly_expense: dailyQuarter,
                daily_half_yearly_expense: dailyHalf,
                daily_yearly_expense: dailyYearly,
                total_expense: totalExpense.toFixed(2),
                associated_ledgers: item.associated_ledgers,
              },
            ]);
          }
        }
      });
    }

    // if (tableData.length === 0) {
    //   setTableData([
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // } else {
    //   setTableData((prevState) => [
    //     ...prevState,
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // }
    setLoading(false);
  };

  // const handleCreate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setDisableProfit(false);
  //   setLoading(false);
  // };

  // const handleConfirmProfitRate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setProfitRateConfirmed(true);
  //   setLoading(false);
  // };

  const scrollToTop = () => {
    return myRef?.current?.scrollIntoView();
  };

  const handleDailyExpense = async () => {
    sendingLedgers = [];
    setLoading(true);
    let email = sessionStorage.getItem("email");
    await new Promise(async (resolve) => {
      await Promise.all(
        tableData.map(async (data, index) => {
          const responseMain = await getLedgerInfo(email, data.gl_code);
          if (data.associated_ledgers) {
            await Promise.all(
              data.associated_ledgers.map(async (ledger, index) => {
                const responseAssociated = await getLedgerInfo(email, ledger);
                if (responseMain && responseAssociated) {
                  await new Promise((resolve) => {
                    sendingLedgers.push(
                      {
                        account_number: "",
                        account_title: "",
                        amc_code: amc_code,
                        balance: "",
                        bank_code: "",
                        branch: "",
                        child_family_code: responseMain.child_family_code,
                        child_family_name: responseMain.child_family_code,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: "0",
                        debit_amount: data.total_expense,
                        description: "Daily Recurring Expense",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: responseMain.family_code,
                        family_name: responseMain.family_name,
                        fund_code: fund,
                        gl_code: responseMain.gl_code,
                        gl_name: responseMain.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: responseMain.main_family_code,
                        main_family_name: responseMain.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: responseMain.sub_family_code,
                        sub_family_name: responseMain.sub_family_name,
                        period: sessionStorage.getItem("active_period") || "",
                      },
                      {
                        account_number: "",
                        account_title: "",
                        amc_code: amc_code,
                        balance: "",
                        bank_code: "",
                        branch: "",
                        child_family_code: responseAssociated.child_family_code,
                        child_family_name: responseAssociated.child_family_code,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: data.total_expense,
                        debit_amount: "0",
                        description: "Daily Recurring Expense",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: responseAssociated.family_code,
                        family_name: responseAssociated.family_name,
                        fund_code: fund,
                        gl_code: responseAssociated.gl_code,
                        gl_name: responseAssociated.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: responseAssociated.main_family_code,
                        main_family_name: responseAssociated.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: responseAssociated.sub_family_code,
                        sub_family_name: responseAssociated.sub_family_name,
                        period: sessionStorage.getItem("active_period") || "",
                      }
                    );

                    fields["net_amount"] = (
                      parseFloat(fields.net_amount) +
                      parseFloat(data.total_expense)
                    ).toFixed(2);

                    setTimeout(() => {
                      resolve("resolved");
                    }, 500);
                  });
                }
              })
            );
          }
        })
      );

      setTimeout(() => {
        resolve("resolved");
      }, 5000);
    });
    // await new Promise((resolve) => {
    //   tableData.map(async (data, index) => {
    //     const responseMain = await getLedgerInfo(email, data.gl_code);

    //     if (data.associated_ledgers) {
    //       data.associated_ledgers.map(async (ledger, index) => {
    //         const responseAssociated = await getLedgerInfo(email, ledger);

    //         if (responseMain && responseAssociated) {
    //           if (sendingLedgers.length > 0) {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 ...sendingLedgers,
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: data.total_expense,
    //                   description: "Daily Recurring Expense",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseAssociated.child_family_code,
    //                   child_family_name: responseAssociated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: data.total_expense,
    //                   debit_amount: "0",
    //                   description: "Daily Recurring Expense",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseAssociated.family_code,
    //                   family_name: responseAssociated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseAssociated.gl_code,
    //                   gl_name: responseAssociated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseAssociated.main_family_code,
    //                   main_family_name: responseAssociated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseAssociated.sub_family_code,
    //                   sub_family_name: responseAssociated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 2000);
    //             });
    //           } else {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: data.total_expense,
    //                   description: "Daily Recurring Expense",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseAssociated.child_family_code,
    //                   child_family_name: responseAssociated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: data.total_expense,
    //                   debit_amount: "0",
    //                   description: "Daily Recurring Expense",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseAssociated.family_code,
    //                   family_name: responseAssociated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseAssociated.gl_code,
    //                   gl_name: responseAssociated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseAssociated.main_family_code,
    //                   main_family_name: responseAssociated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseAssociated.sub_family_code,
    //                   sub_family_name: responseAssociated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 2000);
    //             });
    //           }
    //         }
    //       });
    //     }
    //   });

    //   setTimeout(() => {
    //     resolve("resolved");
    //   }, 20000);
    // });

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "dailyexpense";
      fields.net_amount = fields.net_amount.toString();
      if (fields.ledgers.length > 1) {
        try {
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`Data added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              // history.push("/admin/create-transaction");
              // history.replace(`/admin/transactions-accounts`);
              history.goBack()
            }, 3000);
            // toast.success(response.data.message);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    }

    setProfitRateConfirmed(true);
    // setLoading(false);
  };

  const handleDelete = (id) => {
    setDeleting(true);
    setTableData(
      tableData.filter((data) => {
        return data.id !== id;
      })
    );

    toast.info("Expense Removed!!!");
    setTimeout(() => setDeleting(false), 100);
  };

  const getLedgerInfo = async (email, ledger_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code);
    return result.data.data;
  };

  return (
    <>
      <ToastContainer limit={1} />

      <div className="content" ref={myRef}>
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        // history.replace("/admin/create-transaction");
                        // history.replace(`/admin/transactions-accounts`);
                        history.goBack()
                      }}
                    ></i>
                    Daily Expenses
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Daily Expenses</h4>
                    </div>
                    <div className="card-body d-flex align-items-center row">
                      {/* <div className="row"> */}
                      <div
                        className="form-group col-md-4"
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <select
                            className={"form-control w-100 "}
                            value={fund}
                            disabled={false}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setFund(e.target.value);
                              setLoading(false);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {fundCheck ? "No Fund Found" : "Select Fund"}
                            </option>
                            {renderFundsDropdown()}
                          </select>
                        )}
                      </div>
                      {/* 
                  <div className="form-group col-md-4" data-tip="Select Date">
                    Select Date
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        className="form-control"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        disabled={!fund}
                      />
                    )}
                  </div> */}

                      {/* <div className="form-group col-md-4" data-tip="Select Bank">
                    Bank Name*
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {fund && bankLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <select
                        className={"form-control w-100 "}
                        value={bank}
                        disabled={!fund}
                        onChange={(e) => {
                          let selectedIndex = e.target.selectedIndex;
                          setBankName(e.target[selectedIndex].textContent);
                          setBank(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {!fund
                            ? "Select fund first"
                            : bankCheck
                            ? "No Bank Found"
                            : "Select Bank Account"}
                        </option>

                        {renderBanksDropdown()}
                      </select>
                    )}
                  </div> */}
                      {/* </div> */}

                      {/* <Row> */}
                      <div className="col-md-6 mt-2">
                        <button
                          className="btn btn-primary"
                          disabled={Boolean(Loading) || !fund}
                          onClick={() => handleGetAccounts()}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Get Expenses</span>
                          )}
                        </button>
                      </div>
                      {/* </Row> */}
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : tableData && tableData.length !== 0 && !Loading ? (
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">Expense</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  Fund Code: &nbsp; &nbsp;
                                  {tableData[0]?.fund_code}
                                </td>
                                <td colSpan={5}>
                                  AMC Code: &nbsp; &nbsp;{" "}
                                  {tableData[0]?.amc_code}
                                </td>
                              </tr>
                              <tr>
                                {/* <th>Sr.</th> */}
                                <th>Code</th>
                                <th>Name</th>
                                <th className="text-right">Quarterly Fee</th>
                                <th className="text-right">Daily Expense (Quarterly)</th>
                                <th className="text-right">Half Yearly Fee</th>
                                <th className="text-right">Daily Expense (Half Yearly)</th>
                                <th className="text-right">Annual Fee</th>
                                <th className="text-right">Daily Expense (Annual)</th>
                                <th className="text-right">Total Expense</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((data, index) => {
                                // let totalExpense = parseFloat(
                                //   data.daily_quarterly_expense +
                                //     data.half_yearly_expense +
                                //     data.yearly_expense
                                // ).toFixed(2);
                                return (
                                  <tr
                                    key={index}
                                    style={
                                      deleting
                                        ? { background: context.theme ? "" : "rgba(76,88,119,0.1)" }
                                        : { background: context.theme ? "" : "rgba(37,41,61,1)" }
                                    }
                                  >
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>
                                    <td align="right">
                                      {numberWithCommas(data.quarterly_fee)}
                                    </td>

                                    <td align="right">
                                      {numberWithCommas(
                                        data.daily_quarterly_expense
                                      )}
                                    </td>
                                    <td align="right">
                                      {numberWithCommas(data.half_yearly_fee)}
                                    </td>
                                    <td align="right">
                                      {numberWithCommas(
                                        data.daily_half_yearly_expense
                                      )}
                                    </td>

                                    <td align="right">
                                      {numberWithCommas(data.yearly_fee)}
                                    </td>
                                    <td align="right">
                                      {numberWithCommas(
                                        data.daily_yearly_expense
                                      )}
                                    </td>
                                    <td align="right">
                                      {numberWithCommas(data.total_expense)}
                                    </td>
                                    <td align="center">
                                      <i
                                        className="tim-icons icon-simple-remove"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleDelete(data.id)}
                                      ></i>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              className="btn btn-primary"
                              disabled={Boolean(Loading)}
                              // onClick={() => handleCreate()}
                              onClick={() => {
                                scrollToTop();
                                handleDailyExpense();
                              }}
                            >
                              {Loading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Loading...</span>
                                </>
                              ) : (
                                <span>Save Daily Expense</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyExpense;
