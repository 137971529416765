import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getPortfolioByDate = async (
  email: string,
  amc: any,
  fund: any,
  date: any
) => {
  const url = `${Config.baseUrl}/dailyfundportfolio/by-created-date-and-fund?email=${email}&amc_code=${amc}&fund_code=${fund}&created_at=${date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status === 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPortfolioByDate(email, amc, fund, date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export { getPortfolioByDate };
