import React ,  { useState, useRef } from 'react';
import { EditFund, getFundType } from '../../../stores/services/funds.service';	
import { Container, Row, Col,FormGroup,Label,Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'; 
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';	
import { useHistory } from 'react-router-dom';	
import { IoIosClose } from 'react-icons/io';	
import { Modal } from 'react-bootstrap';	
import ReactTooltip from 'react-tooltip';	
import {	
  getAllSecurities,	
  getAllPsx,	
} from '../../../stores/services/security.service';	
import { getSecuritiesByDate } from '../../../stores/services/daily-security.service';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { CategoryTypesFundDropdown, isNumber, numberWithCommas } from '../../../utils/customFunction';
import NumberFormat from "react-number-format";
import moment from 'moment';
import converter from "number-to-words";
import { inWords } from '../../../stores/services/template.service';
import xls from "xlsx";



const EditFunds = () => {	
  const history = useHistory();	
  //hooks for getting all inputs from user	
  const [fundName, setFundName] = useState('');
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [code, setCode] = useState('');	
  const [amcName, setAmcName] = useState('');	
  const [fundType, setFundType] = useState('');
  const [fundDividendPeriod, setFundDividendPeriod] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');	
  const [revocationDate, setRevocationDate] = useState('');	
  const [psxListing, setPsxListing] = useState('false');	
  const [nav, setNav] = useState('');	
  const [faceValue, setFaceValue] = useState('');
  const [navError, setNavError] = useState('');	
  const email = sessionStorage.getItem('email') || '';
  
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);

  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');

  const [offerPrice, setOfferPrice] = useState('');
  const [redemptionPrice, setRedemptionPrice] = useState('');
  const [outstandingUnits, setOutstandingUnits] = useState('');
  const [managementFee, setManagementFee] = useState('');
  const [sstmanagementfee, setSstManagementFee] = useState('');
  const [amcReimbursementExp, setReinbursementExp] = useState('');
  const [sellingMarketingExp, setSellingMarketingExp] = useState('');
  const [secpFee, setSECPFee] = useState('');
  const [secpSupervisoryFee, setSECPSupervisoryFee] = useState('');

  const [updateStockRatesLoading, setUpdateStockRatesLoading] = useState(false);
  const [updateMoneyMarketRatesLoading, setUpdateMoneyMarketRatesLoading] = useState(false);

  const [moneyMarketSecuritiesByDate, setMoneyMarketSecuritiesByDate] = useState<any>([]);

  const [mmFieldsReadOnly, setMMFieldsReadOnly] =  useState(false);
  const [smFieldsReadOnly, setSMFieldsReadOnly] =  useState(false);

  const [slabFromError, setSlabFromError] = useState<boolean>(false);
  const [slabToError, setSlabToError] = useState<boolean>(false);
  const [slabRateError, setSlabRateError] = useState<boolean>(false);
  const [slabFixedRateError, setSlabFixedRateError] = useState<boolean>(false);

  let [slabFrom, setSlabFrom] = useState<any>();
  let [slabTo, setSlabTo] = useState<any>();
  let [slabRate, setSlabRate] = useState<any>();
  let [slabFixedRate, setSlabFixedRate] = useState<any>();

  let [slabData, setSlabData] = useState<any>([]);

  const [feeSlabModal,setFeeSlabModal] = useState(false)

  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [fileName2, setFileName2] = useState('');
  const [fileError2, setFileError2] = useState('');
  const [fileName3, setFileName3] = useState('');
  const [fileError3, setFileError3] = useState('');

  const myRef1 = useRef<HTMLInputElement>(null);
  const myRef2 = useRef<HTMLInputElement>(null);
  const myRef3 = useRef<HTMLInputElement>(null);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [editFeeSlabIndex,setEditFeeSlabIndex] = useState<number>(-1)

  const getMoneyMarketSecuritiesByDate = async()=>{
    try{ // moment(new Date()).format('YYYY-MM-DD')
      const response = await getSecuritiesByDate(email,moment(new Date()).format('YYYY-MM-DD'));
      setMoneyMarketSecuritiesByDate(response.data.data);
    }catch(err:any){

    }
  }

  React.useEffect(() => {	
    const obj = JSON.parse(sessionStorage.getItem('fundobj') || '');	
    setFundName(obj.fund_name);	
    setCode(obj.symbol_code.replace('FUND_',''));	
    setAmcName(obj.amc_code);	
    setFundType(obj.nature);	
    setFundDividendPeriod(obj.dividend_period);	
    setIncorporationDate(obj.date_of_incorporation?.toString()?.split("T")[0] || "");	
    setRevocationDate(obj.date_of_revocation?.toString()?.split("T")[0] || "");	
    setPsxListing(obj.psx_listing);	
    setNav(obj.nav);	
    setFaceValue(obj.face_value);
    setSSTTrusteeFee(obj.sst_trustee_percentage || 0)
    // setFeeSlabs(JSON.parse(obj.fee_slab || '[]'))
    setSlabData(JSON.parse(obj.fee_slab || '[]'))
    if (obj.money_market !== '' && obj.money_market !== '[]') {	
      setMoneyMarket(JSON.parse(obj.money_market));	
    }	
    if (obj.stock_market !== '' && obj.stock_market !== '[]') {	
      setStockMarket(JSON.parse(obj.stock_market));	
    }	
    if (obj.term_deposit !== '' && obj.term_deposit !== '[]') {	
      setTermDeposit(JSON.parse(obj.term_deposit));	
    }
    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);

    setOfferPrice(obj.offer_price);
    setRedemptionPrice(obj.redemption_price);
    setOutstandingUnits(obj.outstanding_units);
    setManagementFee(obj.management_fee_percentage);
    setSstManagementFee(obj.sst_on_management_fee_percentage);
    setReinbursementExp(obj.amc_reimbursement_exp_percentage);
    setSellingMarketingExp(obj.selling_and_marketing_exp_percentage);
    setSECPFee(obj.secp_fee_percentage);
    setSECPSupervisoryFee(obj.secp_supervisory_fee_percentage);
    setSaleLoad(obj.sale_load_percentage || 0)
    setBackendLoad(obj.backend_load_percentage || 0)
    setContingentLoad(obj.contingent_load_percentage || 0)
    setSSTSales(obj.sst_sale_backend_contingent_load_percentage || 0)

    // get mm securities by date
    getMoneyMarketSecuritiesByDate();
  }, []);	
  //error getting hooks	
  const [namelError, setNameError] = useState('');	
  const [codeError, setCodeError] = useState('');	
  const [amcNameError, setAmcError] = useState('');	
  const [fundTypeError, setFundTypeError] = useState('');
  const [fundDividendPeriodError, setFundDividendPeriodError] = useState('');
  const [incorporationDateError, setIncorporationDateError] = useState('');	
  const [psxListingError, setPsxListingError] = useState('');	
  const [offerPriceError, setOfferPriceError] = useState('');
  const [redemptionPriceError, setRedemptionPriceError] = useState('');
  const [outstandingUnitsError, setOutstandingUnitsError] = useState('');
  const [managementFeeError, setManagementFeeError] = useState('');
  const [sstmanagementfeeError, setSstManagementFeeError] = useState('');
  const [amcReimbursementExpError, setReinbursementExpError] = useState('');
  const [sellingMarketingExpError, setSellingMarketingExpError] = useState('');
  const [secpFeeError, setSECPFeeError] = useState('');
  const [secpSupervisoryFeeError, setSECPSupervisoryFeeError] = useState('');
  const [sstTrusteeFee,setSSTTrusteeFee] = useState("")

  

  const [Loading, setLoading] = useState(false);	
  //for dropdown data	
  const [amcdata, setAmcdata] = useState<any>([]);	
  const [fundTpedata, setFundTpedata] = useState<any>([]);	
  const [isCheckBoxSelected, setCheckBoxSelected] = useState(true);	
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);	
  const [psxList, setPsxList] = useState<any>([]); // PSX securities Hook	

  const [saleLoad,setSaleLoad] = useState("")
  const [backendLoad,setBackendLoad] = useState("")
  const [contingentLoad,setContingentLoad] = useState("")
  const [sstSales,setSSTSales] = useState("")

  const validate = () => {	
    let nameError = '';	
    let codeError = '';	
    let amcErr = '';	
    let typeErr = '';	
    let dividendPeriodErr = '';	
    let inDateerr = '';	
    let psxErr,	
      navErr ,
      offerPriceErr,
      redemptionPriceErr,
      oustandingUnitsErr,
      managementFeeErr,
      sstmanagementfeeErr,
      amcReimbursementExpErr,
      sellingMarketingExpErr,
      secpFeeErr,
      secpSupervisoryFeeErr = '';	
    fundName.trim() === '' ? (nameError = 'Required') : (nameError = '');	
    code.trim() === '' ? (codeError = 'Required') : (codeError = '');	
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');	
    fundType.trim() === '' ? (typeErr = 'Required') : (typeErr = '');
    fundDividendPeriod.trim() === '' ? (dividendPeriodErr = 'Required') : (dividendPeriodErr = '');
    // incorporationDate.trim() === '' ? inDateerr = "Required" :	
    //     inDateerr = "";	
    psxListing.trim() === '' ? (psxErr = 'Required') : (psxErr = '');	
    nav?.toString()?.trim() === '' ? (navErr = 'Required') : (navErr = '');

    offerPrice?.toString().trim() === '' ? (offerPriceErr = 'Required') : (offerPriceErr = '');
    redemptionPrice?.toString().trim() === '' ? (redemptionPriceErr = 'Required') : (redemptionPriceErr = '');
    outstandingUnits?.toString().trim() === '' ? (oustandingUnitsErr = 'Required') : (oustandingUnitsErr = '');
    managementFee?.toString().trim() === '' ? (managementFeeErr = 'Required') : (managementFeeErr = '');
    sstmanagementfee?.toString().trim() === '' ? (sstmanagementfeeErr = 'Required') : (sstmanagementfeeErr = '');
    amcReimbursementExp?.toString().trim() === '' ? (amcReimbursementExpErr = 'Required') : (amcReimbursementExpErr = '');
    sellingMarketingExp?.toString().trim() === '' ? (sellingMarketingExpErr = 'Required') : (sellingMarketingExpErr = '');
    secpFee?.toString().trim() === '' ? (secpFeeErr = 'Required') : (secpFeeErr = '');
    secpSupervisoryFee?.toString().trim() === '' ? (secpSupervisoryFeeErr = 'Required') : (secpSupervisoryFeeErr = '');
    if (	
      nameError ||	
      codeError ||	
      amcErr ||	
      typeErr ||
      dividendPeriodErr ||
      inDateerr ||	
      psxErr ||	
      navErr ||
      offerPriceErr ||
      redemptionPriceErr ||
      oustandingUnitsErr ||
      managementFeeErr ||
      sstmanagementfeeErr ||
      amcReimbursementExpErr ||
      sellingMarketingExpErr||
      secpFeeErr||
      secpSupervisoryFeeErr
    ) {	
      setNameError(nameError);	
      setCodeError(codeError);	
      setAmcError(amcErr);	
      setNavError(navErr);	
      setFundTypeError(typeErr);
      setFundDividendPeriodError(dividendPeriodErr);
      setIncorporationDateError(inDateerr);	
      setPsxListingError(psxErr);
      setOfferPriceError(offerPriceErr);
      setRedemptionPriceError(redemptionPriceErr);
      setOutstandingUnitsError(oustandingUnitsErr);
      setManagementFeeError(managementFeeErr);
      setSstManagementFeeError(sstmanagementfeeErr);
      setReinbursementExpError(amcReimbursementExpErr);
      setSellingMarketingExpError(sellingMarketingExpErr);
      setSECPFeeError(secpFeeErr);
      setSECPSupervisoryFeeError(secpSupervisoryFeeErr);
      return false;	
    } else {	
      return true;	
    }	
  };	
  const EditFundData = async () => {	
    const isValid = validate();	
    if (isValid) {	
      setLoading(true);	
      try {	
        const response = await EditFund(	
          email,	
          amcCode,	
          fundType,
          fundDividendPeriod,
          incorporationDate,	
          fundName,	
          code,	
          revocationDate,	
          psxListing,	
          nav?.toString(),	
          JSON.stringify(moneyMarket),	
          JSON.stringify(stockMarket),
          faceValue?.toString(),
          JSON.stringify(termDeposit),
          cdc_participant_id,
          cdc_account_number,
          offerPrice?.toString(),
          redemptionPrice?.toString(),
          outstandingUnits?.toString(),
          managementFee?.toString(),
          sstmanagementfee?.toString(),
          amcReimbursementExp?.toString(),
          sellingMarketingExp?.toString(),
          secpFee?.toString(),
          secpSupervisoryFee?.toString(),
          Number(sstTrusteeFee || 0),
          JSON.stringify(slabData),
          Number(saleLoad || "0"),
          Number(backendLoad || "0"),
          Number(contingentLoad || "0"),
          Number(sstSales || "0")

        );
        if(response.data.status===200){	
        // setCheckBoxSelected(true);	
        // setFundName('');	
        // setCode('');	
        // setNav('');	
        // setAmcName('');	
        // stockMarket.length = 0;	
        // setStockMarket(stockMarket);	
        // moneyMarket.length = 0;	
        // setMoneyMarket(moneyMarket);
        // termDeposit.length = 0;
        // setTermDeposit(termDeposit);	
        // setFundType('');	
        // setIncorporationDate('');	
        // setRevocationDate('');	
        
        // setPsxListing('true');	
        // setFaceValue('');
        // setOfferPrice('');
        // setRedemptionPrice('');
        // setOutstandingUnits('');
        // setManagementFee('');
        // setSstManagementFee('');
        // setReinbursementExp('');
        // setSellingMarketingExp('');
        // setSECPFee('');
        // setSECPSupervisoryFee('');
        sessionStorage.removeItem('fundobj');	
        toast.success(response.data.message);	
        setTimeout(() => {	
          history.replace(`/admin/funds`);	
        }, 2000);
      }else{
        setLoading(false);	
        toast.error(response.data.message);	
      }	
      } catch (error:any) {	
        setLoading(false);	
        toast.error(error.response.data.message);	
      }	
    
    }	
  };	
  React.useEffect(() => {	
    const fetchAmc = async () => {	
      // try {	
      //   const amcResponse = await getAmc(email);	
      //   setAmcdata(amcResponse.data.data);	
      // } catch (error) {}	
      try {	
        const response = await getFundType(email);
        setFundTpedata(response.data.data);	
      } catch (error) {}	
      try {	
        const motResponse = await getAllSecurities(email,"","",amcCode);	
        setSecurityTypeData(motResponse.data.data);	
      } catch (error) {}	
      try {	
        const response = await getAllPsx(email,amcCode);	
        let sortedData = response.data.data.sort(function(a,b){
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });
        // setPsxList(response.data.data);	
        setPsxList(sortedData);	
      } catch (error) {}	

      // get all banks
      try {
        const bankResponse = await getAllBanks(email,"","",amcCode);
        // temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(bankResponse.data.data);
      } catch (error) {}

    };	
    fetchAmc();	
  }, []);	


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name,amcCode);
      setBranchData(response.data.data);
    }catch(error){}

  };
  const renderBankDropdown = () => {
    return bankNameData.map((item: any) => {
      return (
        <option key={item.bank_code} value={item.bank_code.replace('BANK_','')}>
          {item.bank_name} ({item.bank_code.replace('BANK_','')})
        </option>
      );
    });
  };

  const renderBranchDropdown = () => {
    return branchData.map((item: any) => {
      return (
        <option key={item.branch_code} value={item.branch_name}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  const renderFeeSlabModal =() =>{
    switch (feeSlabModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setFeeSlabModal(false);
              setSlabFromError(false)
                setSlabToError(false)
                setSlabRateError(false)
                setSlabFixedRateError(false)

                setSlabFrom("");
                setSlabTo("");
                setSlabRate("");
                setSlabFixedRate("");
                setEditFeeSlabIndex(-1)
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFeeSlabModal(false);
                 
                setSlabFromError(false)
                setSlabToError(false)
                setSlabRateError(false)
                setSlabFixedRateError(false)

                setSlabFrom("");
                setSlabTo("");
                setSlabRate("");
                setSlabFixedRate("");
                setEditFeeSlabIndex(-1)
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> {editFeeSlabIndex > -1 ? "Update " : "Add "} fee Slab</h6>
            </div>

            <div className="modal-body">
              <Row>
                <div className="form-group col-md-6" data-tip="Slab Range start">
                  <div>Slab From</div>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    placeholder="Slab From"
                    value={slabFrom}
                    className={`form-control w-100 ${ErrorBorder(
                      slabFromError
                    )}`}
                    style={{ textAlign: "left" }}
                    onValueChange={(e) => {
                      setSlabFromError(false);
                      setSlabFrom(e.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-6" data-tip="Slab Range end">
                  <div>Slab To</div>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    placeholder="Slab To"
                    value={slabTo}
                    className={`form-control w-100 ${ErrorBorder(slabToError)}`}
                    style={{ textAlign: "left" }}
                    onValueChange={(e) => {
                      setSlabToError(false);
                      setSlabTo(e.value);
                    }}
                  />
                </div>

                <div className="form-group col-md-6" data-tip="Rate for trustee fee calculation">
                  <div>Slab Rate</div>
                  <input
                    type="number"
                    placeholder="Slab Rate"
                    value={slabRate}
                    className={`form-control w-100 ${ErrorBorder(
                      slabRateError
                    )}`}
                    style={{ textAlign: "left" }}
                    data-tip="Slab rate"
                    onChange={(e) => {
                      setSlabRateError(false);
                      setSlabRate(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group col-md-6" data-tip="Fixed amount to charge in case of this slab range">
                  <div>Slab Fixed Amount</div>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    placeholder="Amount to charge in case of this slab range"
                    value={slabFixedRate}
                    className={`form-control w-100 ${ErrorBorder(
                      slabFixedRateError
                    )}`}
                    style={{ textAlign: "left" }}
                    data-tip="Fixed amount to charge in case of this slab range"
                    onValueChange={(e) => {
                      setSlabFixedRateError(false);
                      setSlabFixedRate(e.value);
                    }}
                  />
                </div>
              </Row>

              <div className="form-group" data-tip="Add Slab">
                            <button
                              className="btn"
                              onClick={() => handleAddingSlab()}
                            >
                              {editFeeSlabIndex > -1 ? "Update " : "Add "} Slab
                            </button>
                          </div>
            </div>
          </Modal>
        );

      default:
        return ""

  }
}

const ErrorBorder = (value) => {
  if (value === true) {
    return "required-border";
  } else {
    return "";
  }
};

const handleAddingSlab = async () => {
  if (!slabFrom) {
    setSlabFromError(true);
    return toast.error("Slab From value is required!");
  }
  if (!slabTo) {
    setSlabToError(true);
    return toast.error("Slab To value is required!");
  }
  if (!slabRate) {
    setSlabRateError(true);
    return toast.error("Slab Rate is required!");
  }
  if (!slabFixedRate) {
    setSlabFixedRateError(true);
    return toast.error("Slab Fixed Amount is required!");
  }
  // if (!relatedLedger) {
  //   setRelatedLedgerError(true);
  //   return toast.error("Related ledger is required!");
  // }

  if(editFeeSlabIndex > -1)
    {
      setSlabData([...slabData.slice(0,editFeeSlabIndex),{
        amountFrom: slabFrom,
        amountTo: slabTo,
        rate: slabRate,
        fixedAmmount: slabFixedRate,
      },...slabData.slice(editFeeSlabIndex+1)])

    }else{

    

  setSlabData([...slabData,{
    amountFrom: slabFrom,
    amountTo: slabTo,
    rate: slabRate,
    fixedAmmount: slabFixedRate,
  }])
}

  setSlabFrom("");
  setSlabTo("");
  setSlabRate("");
  setSlabFixedRate("");
  setSlabFromError(false)
                setSlabToError(false)
                setSlabRateError(false)
                setSlabFixedRateError(false)
  setFeeSlabModal(false);
  setEditFeeSlabIndex(-1)
};



const renderFeeDataInTable = () => {
  return slabData.map((items: any, index: number) => {
    return (
      <tr key={index}>
        <td>{numberWithCommas(isNumber(items.amountFrom))}</td>
        <td>{numberWithCommas(isNumber(items.amountTo))}</td>
        <td className="text-right">{parseFloat(isNumber(items.rate)).toFixed(4)}</td>
        <td className="text-right">{numberWithCommas(isNumber(items.fixedAmmount))}</td>
        {/* <td>{items.calculatedAmount}</td> */}
        <td className="text-right">
          <div className='d-flex justify-content-end'>  

          <i style={{  cursor:"pointer" }} 
            className="tim-icons icon-pencil mr-2" 
            onClick={()=>{
              setEditFeeSlabIndex(index)
              setFeeSlabModal(true)
              setSlabFrom(items.amountFrom)
              setSlabTo(items.amountTo)
              setSlabRate(items.rate)
              setSlabFixedRate(items.fixedAmmount)
            

            }}
              />

            <i
            className='tim-icons icon-simple-remove'
            style={{  color: "red", cursor:"pointer" }}
            onClick={() => {
              setSlabData(slabData.filter((item,ind)=>index != ind))
              
            }}
          />
          </div>

        </td>
       
      </tr>
    );
  });
};

  // const renderAmcDropdown = () => {	
  //   return amcdata.map((item: any) => {	
  //     return (	
  //       <option key={item.amc_code} value={item.amc_code.replace('AMC_','')}>	
  //         {item.amc_code.replace('AMC_','')}-{item.name}	
  //       </option>	
  //     );	
  //   });	
  // };	
  //render dropdown for security data	
  const renderSecurityDataDropdown = () => {	
    return securityTypeData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.code}>	
          {item.name}-{item.code}	
        </option>	
      );	
    });	
  };	
  //render dropdown for psx data	
  const renderPsxDataDropdown = () => {	
    return psxList.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.symbol}>	
          {item.symbol}	
        </option>	
      );	
    });	
  };	

  const [securityData, setSecurityData] = useState([]);

const renderFilterSecurityDataDropdown = (security_type:string) => {
  const check= securityTypeData.filter((item: any, index: string)=>{
   return (item.security_type===security_type);
  });
  setSecurityData(check);
};

const renderSecuritiesDropDown=()=>{
  return securityData.map((item: any, index: number) => {
    return (
      <option key={index} value={item.code.replace('SECURITY_','')}>
        {item.name}
      </option>
    );
  });
}


  const [modalView, setModalView] = useState(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  const [moneyMarket, setMoneyMarket] = useState<any>([]);	
  const [moneyMarketEntity, setMoneyMarketEntity] = useState<any>({	
    code: '',	
    total_amount: '0',	
    // name: '',	
  });	
  // ADD TAX Entity inside tax Array	
  const addMoneyMarket = async () => {	
    setDisableAddBtn(true);	
    let count = 0;	
    // push data inside array	
    for (let i in moneyMarketEntity) {	
      if (moneyMarketEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableAddBtn(false);	
    } else {	
      setModalView(false);	
      // const check = moneyMarket.filter((item) => {	
      //   return item.code === moneyMarketEntity['code'];	
      // });	
      // if (check.length > 0) {	
      //   moneyMarket.map((item, index) => {	
      //     if (item.code === moneyMarketEntity['code']) {	
      //       item.total_amount = (+item.total_amount + +moneyMarketEntity['total_amount']).toString();	
      //       item.units = (+item.units + +moneyMarketEntity['units']).toString();
      //     }	
      //   });	
      // } else {	
        moneyMarket.push(moneyMarketEntity);	
        setMoneyMarket(moneyMarket);	
      // }	
      const filedEntirty = {	
        code: '',	
        total_amount: '0',	
        // name: '',	
      };	
      setMoneyMarketEntity(filedEntirty);	
      setDisableAddBtn(false);	
    }	
  };

  const updateMoneyMarket = async () => {	
    setDisableAddBtn(true);	
    let count = 0;	
    // push data inside array	
    for (let i in moneyMarketEntity) {	
      if (moneyMarketEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableAddBtn(false);	
    } else {	
      setModalView(false);	
      const check = moneyMarket.filter((item) => {	
        return item.code === moneyMarketEntity['code'] && item.security_type==moneyMarketEntity['security_type'] && item.issue_date==moneyMarketEntity['issue_date'] && item.maturity_date==moneyMarketEntity['maturity_date'];	
      });	
      if (check.length > 0) {	
       moneyMarket.map((item, index) => {	
          if (item.code === moneyMarketEntity['code'] && item.security_type==moneyMarketEntity['security_type'] && item.issue_date==moneyMarketEntity['issue_date'] && item.maturity_date==moneyMarketEntity['maturity_date']) {
            // item.coupon_rate = moneyMarketEntity.coupon_rate;
            // item.face_value = moneyMarketEntity.face_value ;
            // item.mmface_value = moneyMarketEntity.mmface_value;
            // item.price =moneyMarketEntity.price;
            // item.tenor=moneyMarketEntity.tenor;
            // item.total_amount=moneyMarketEntity.total_amount;
            // item.units=moneyMarketEntity.units;
            // item.yield=moneyMarketEntity.yield;
            // item.coupon_payment = moneyMarketEntity.coupon_payment;
            // item.investment_percentage_market_value = moneyMarketEntity.investment_percentage_market_value;
            item.coupon_rate = moneyMarketEntity.coupon_rate;
            item.yield = moneyMarketEntity.yield;
            item.coupon_payment = moneyMarketEntity.coupon_payment;
            item.coupon_days = moneyMarketEntity.coupon_days;
            item.mmface_value = moneyMarketEntity.mmface_value;
            item.total_face_value = moneyMarketEntity.total_face_value;
            item.units = moneyMarketEntity.units;
            item.average_cost_per_unit = moneyMarketEntity.average_cost_per_unit;
            item.total_amount = moneyMarketEntity.total_amount;
            item.market_rate_per_unit = moneyMarketEntity.market_rate_per_unit;
            item.market_value = moneyMarketEntity.market_value;
            item.unrealized_gain_loss = moneyMarketEntity.unrealized_gain_loss;
            item.investment_percentage_market_value = moneyMarketEntity.investment_percentage_market_value;
            item.days_of_maturity = moneyMarketEntity.days_of_maturity;
            item.unredeem_value = moneyMarketEntity.unredeem_value;
            item.principal_amount = moneyMarketEntity.principal_amount;
          }	
        });	
        // setMoneyMarket(updatedData);
      } else {	
        // moneyMarket.push(moneyMarketEntity);	
        // setMoneyMarket(moneyMarket);	
      }	
      const filedEntirty = {	
        code: '',	
        total_amount: '0',	
        // name: '',	
      };	
      setMoneyMarketEntity(filedEntirty);	
      setDisableAddBtn(false);	
      setMMFieldsReadOnly(false);
      setMoneyMarketEntity({});
      setSecurityData([])
    }	
  };

  //render Fund table data	
  const renderMoneyMarketDataInTable = () => {	
    return moneyMarket.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
         <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td> 
          <td>{items.maturity_date}</td> 
          <td>{numberWithCommas(items.coupon_rate || '')}</td> 
          <td>{numberWithCommas(items.yield || '')}</td>
          <td>{numberWithCommas(items.coupon_payment || '')}</td> 
          <td>{numberWithCommas(items.coupon_days || '')}</td>
          <td>{numberWithCommas(items.mmface_value || '')}</td> 
          <td>{numberWithCommas(items.total_face_value || '')}</td>
          {/* <td>{numberWithCommas(items.price || '')}</td> */}
          <td>{numberWithCommas(items.units || '')}</td> 
          <td>{numberWithCommas(items.average_cost_per_unit || '')}</td> 
          <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td> 
          <td> {numberWithCommas(items.market_rate_per_unit || '')} </td>
          <td> {numberWithCommas(items.market_value || '')} </td>
          <td> {numberWithCommas(items.unrealized_gain_loss || '')} </td>
          {/* <td> {numberWithCommas(items.unrealized_gain_loss || '')} </td> */}
          <td> {numberWithCommas(items.investment_percentage_market_value || '')} </td>
          <td> {numberWithCommas(items.days_of_maturity || '')} </td>
          <td> {numberWithCommas(items.unredeem_value || '')} </td>
          <td> {numberWithCommas(items.principal_amount || '')} </td>
          {/* <td>{items.calculatedAmount}</td> */}	
          <td >	

              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" 
              onClick={() => {
                renderFilterSecurityDataDropdown(items.security_type)
                setMMFieldsReadOnly(true);
                setMoneyMarketEntity(items);	
                setModalView(!modalView);
                setDisableAddBtn(false);
                setStockModal(false);	
              }}>
                <i className="tim-icons icon-pencil">
                </i></button>

            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...moneyMarket];	
                array.splice(index, 1);	
                setMoneyMarket(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	

  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
              setMMFieldsReadOnly(false);
              setMoneyMarketEntity({});
              setSecurityData([])
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false);
                  setMMFieldsReadOnly(false);
                  setMoneyMarketEntity({});
                  setSecurityData([])
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
             </div>    

            <div className="modal-body">
              <Row>
                <Col>
                <div className="form-group">
                    <div>Select Security Type</div>
                <select 
                      className='form-control'
                      value={moneyMarketEntity['security_type']}
                      disabled={mmFieldsReadOnly}
                      onChange={(e) => {
                        renderFilterSecurityDataDropdown(e.target.value)
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          security_type:e.target.value
                        });
                       
                    }}>
                       <option value="" defaultChecked hidden>
                              {' '}
                        Select Security</option>
                      <optgroup>Money Market</optgroup>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasuary bill</option>

                      <optgroup>Debt Market</optgroup>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      <option value='TFC'>Term Finance Certificates</option>
                      <option value='CP'>Commercial Papers</option>

                      <optgroup>Term Deposits</optgroup>
                      <option value='TDR'>Term Deposit Receipts</option>
                      <option value="CDR">
                      {/* Call Deposit Receipts */}
                      Certificate Of Investment
                      </option>
                      <option value='LOP'>Letter of Placement</option>
                      </select>
                    </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <div>Select Security</div>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />

                    <select
                      className="form-control"
                      value={moneyMarketEntity['code']}
                      disabled={mmFieldsReadOnly}
                      onChange={(e) => {
                        // let value = securityTypeData.filter((item: any) => {
                        //   return item.code === e.target.value;
                        // });
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          // code: e.target.value,
                          code:e.target.value,
                          name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Security
                      </option>
                      {/* {renderSecurityDataDropdown()} */}
                      {renderSecuritiesDropDown()}
                    </select>
                  </div>
                </Col>
               
              </Row>
              
              <Row>
              <Col md="6">
                  <div className="form-group">
                      <div>Issue Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['issue_date']}
                        disabled={mmFieldsReadOnly}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            issue_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>
              <Col md="6">
                  <div className="form-group">
                      <div>Maturity Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['maturity_date']}
                        disabled={mmFieldsReadOnly}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            maturity_date: e.target.value,
                          });
                        }}
                      />
                    </div>

                </Col>
             </Row>
                
             <Row>
                <Col md="6">
                   <div className="form-group">
                      <div>Coupon Rate %</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['coupon_rate']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_rate: e.value,
                          });
                        }}
                      />
                    </div>
                </Col>

                
              <Col md="6">
                  <div className="form-group">
                      <div>Yield %</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['yield']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            yield: e.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                </Row>

                <Row>
                <Col md="6">
                   <div className="form-group">
                      <div>Coupon Payment/Year</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['coupon_payment']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_payment: e.value,
                          });
                        }}
                      />
                    </div>
                </Col>

                
              <Col md="6">
                  <div className="form-group">
                      <div>Coupon Days</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['coupon_days']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_days: e.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                </Row>

                <Row>

                <Col md="6">
                  <div className="form-group">
                      <div>Money Market Face Value/Unit</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['mmface_value']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            mmface_value: value,
                          });
                        }}
                      />
                    </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                      <div>Total Face Value</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['total_face_value']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            total_face_value: value,
                          });
                        }}
                      />
                    </div>
                </Col>
                
              </Row>

              <Row>
              {/* <Col md="6">
                  <div className="form-group">
                      <div>Price</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['price']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            price: value,
                          });
                        }}
                      />
                    </div>
                  
                </Col> */}
                <Col md="6">
                  <div className="form-group">
                    <div>Units</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={moneyMarketEntity['units']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value,4);
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          units: value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                      <div>Avg Cost/Unit</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['average_cost_per_unit']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            average_cost_per_unit: value,
                          });
                        }}
                      />
                    </div>
                  
                </Col>
                </Row>
                <Row>
              <Col md="6">
                  <div className="form-group">
                    <div>Total Cost / Amount</div>
                    <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    className="form-control w-100 text-right"
                      value={moneyMarketEntity['total_amount']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value,2);
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          total_amount: value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">

                  <div className="form-group">
                      <div>Market Rate/Unit</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['market_rate_per_unit']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            market_rate_per_unit: value,
                          });
                        }}
                      />
                    </div>
                  
                </Col> 
                </Row>
                <Row>
                  <Col md="4">
                  <div className="form-group">
                      <div>Market Value</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['market_value']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            market_value: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group">
                      <div>Unrealized Gain/(Loss)</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['unrealized_gain_loss']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            unrealized_gain_loss: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group">
                      <div>Investment as % of Market Value</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['investment_percentage_market_value']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            investment_percentage_market_value: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                  <div className="form-group">
                      <div>Maturity Days</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['days_of_maturity']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            days_of_maturity: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group">
                      <div>Unredeemed Value</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['unredeem_value']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            unredeem_value: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group">
                      <div>Principal Amount</div>
                      <NumberFormat
                         thousandSeparator={true}
                         inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['principal_amount']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            principal_amount: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              
              <div className="row"> 
                <div className="col-sm-12">
                {!mmFieldsReadOnly ? (
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      addMoneyMarket();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                ):(
                  <button
                  className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                  onClick={() => {
                    updateMoneyMarket();
                  }}
                  disabled={Boolean(disableAddBtn)}
                >
                  {disableAddBtn ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
                )}
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [equityModalFlag, setEquityModalFlag] = useState(false);	
  const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);	
  const [stockMarket, setStockMarket] = useState<any>([]);	
  const [stockEntity, setStockEntity] = useState<any>({	
    code: '',
    unit: '',
    amount: '0',
    total_units:'0',
    average_cost_per_unit:'0',
    market_rate_per_unit:'0',
    market_value:'0',
    unrealized_gain_loss:'0',
    investment_percentage_market_value:'0',
  });	
  // ADD TAX Entity inside tax Array	
  const addEquityStock = async () => {	
    setDisableEquityBtn(true);	
    let count = 0;	
    //push data inside array	
    for (let i in stockEntity) {	
      if (stockEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableEquityBtn(false);	
    } else {	
      setEquityModalFlag(false);	
      const check = stockMarket.filter((item) => {	
        return item.code === stockEntity['code'];	
      });	
      if (check.length > 0) {	
        stockMarket.map((item, index) => {	
          if (item.code === stockEntity['code']) {	
            item.unit = (+item.unit + +stockEntity['unit']).toString();	
            item.amount = (parseFloat(item.unit) * parseFloat(item.close_rate)).toFixed(2);	
          }	
        });	
      } else {	
        stockMarket.push(stockEntity);	
        setStockMarket(stockMarket);	
      }	
      const filedEntirty = {	
        code: '',
        unit: '',
        amount: '0',
        total_units:'0',
        average_cost_per_unit:'0',
        market_rate_per_unit:'0',
        market_value:'0',
        unrealized_gain_loss:'0',
        investment_percentage_market_value:'0',	
      };	
      setStockEntity(filedEntirty);	
      setDisableEquityBtn(false);	
    }	
  };

  const updateEquityStock = async () => {	
    setDisableEquityBtn(true);	
    let count = 0;	
    //push data inside array	
    for (let i in stockEntity) {	
      if (stockEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableEquityBtn(false);	
    } else {	
      setEquityModalFlag(false);	
      const check = stockMarket.filter((item) => {	
        return item.code === stockEntity['code'];	
      });	
      if (check.length > 0) {	
        stockMarket.map((item, index) => {	
          if (item.code === stockEntity['code']) {	
            item.market = stockEntity['market'];
            item.total_units = stockEntity['total_units'];
            item.average_cost_per_unit = stockEntity['average_cost_per_unit'];
            item.market_rate_per_unit = stockEntity['market_rate_per_unit'];
            item.market_value = stockEntity['market_value'];
            item.unrealized_gain_loss = stockEntity['unrealized_gain_loss'];
            item.investment_percentage_market_value = stockEntity['investment_percentage_market_value'];
            item.unit = stockEntity['unit'];
            item.amount = stockEntity['amount'];
          }	
        });	
      } else {	
        stockMarket.push(stockEntity);	
        setStockMarket(stockMarket);	
      }	
      const filedEntirty = {	
        code: '',
        unit: '',
        amount: '0',
        total_units:'0',
        average_cost_per_unit:'0',
        market_rate_per_unit:'0',
        market_value:'0',
        unrealized_gain_loss:'0',
        investment_percentage_market_value:'0',	
      };	
      setStockEntity(filedEntirty);
      setSMFieldsReadOnly(false);
      setDisableEquityBtn(false);	
    }	
  };

  const [stockModal, setStockModal] = useState(false);	
  //render equity table data	
  const renderEquityInTable = () => {	
    return stockMarket.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
           <td>{items.code}</td>
          <td>{items.market}</td>
          {/* <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}</td> */}
          <td>{numberWithCommas(items.total_units || '') && numberWithCommas(items.total_units || '')}</td>
          <td>{numberWithCommas(items.unit || '') && numberWithCommas(items.unit || '')}</td>
          <td>{numberWithCommas(items.average_cost_per_unit)}</td>
          <td>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}</td>
          <td>{numberWithCommas(items.market_rate_per_unit)}</td>
          <td>{numberWithCommas(items.market_value)}</td>
          <td>{numberWithCommas(items.unrealized_gain_loss)}</td>
          <td>{numberWithCommas(items.investment_percentage_market_value)}</td>
          <td >	
          <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                setSMFieldsReadOnly(true);
                setStockEntity(items);
                setEquityModalFlag(!equityModalFlag);	
                setDisableEquityBtn(false);	
                setStockModal(true);	
              }}>
                <i className="tim-icons icon-pencil">
                </i></button>
            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...stockMarket];	
                array.splice(index, 1);	
                setStockMarket(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	
  // render Model for adding Equity	
  const renderEquityPopup = () => {
    switch (equityModalFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setEquityModalFlag(false);
              setSMFieldsReadOnly(false);
              setStockEntity({});
              setEquityModalFlag(!equityModalFlag);	
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setSMFieldsReadOnly(false);
                  setStockEntity({});
                  setEquityModalFlag(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
             </div> 


            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                        Market*
                        <select
                          className="form-control w-100"
                          value={stockEntity.market}
                          onChange={(e) => {
                            setStockEntity({
                              ...stockEntity,
                              market: e.target.value
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>Select Market</option>
                          <option value="REG">Regular</option>
                          <option value="ODL">Odd Lot</option>
                          <option value="FUT">Future</option>
                          <option value="BNB">Bills and Bonds</option>
                          <option value="IPO">Initial Public Offering</option>
                          <option value="BKB">Book Building</option>
                          <option value="CSF">Cash Settled Futures</option>
                          <option value="DSF">Direct Settled Futures</option>
                          <option value="SIF">Stock Index Futures</option>
                          <option value="NDM">Negotiable Deal Market</option>
                          <option value="FRO">Future Rollover Contract</option>
                          <option value="MTS">Margin Trading System</option>
                          <option value="SQR">Square Up</option> 
                        </select>
                      </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Select Security*
                      {(smFieldsReadOnly==true) ? (
                      <input type="text"
                      className="form-control w-100"
                      value={stockEntity['code']}
                      readOnly={smFieldsReadOnly}
                      />
                      ):(
                        <select
                        className="form-control w-100"
                        value={stockEntity['code']}
                        disabled={smFieldsReadOnly}
                        onChange={(e) => {
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          setStockEntity({
                            ...stockEntity,
                            code: e.target.value,
                            // close_rate: value[0].close_rate,
                            // amount: (
                            //   parseFloat(e.target.value) *
                            //   parseFloat(stockEntity.Unit)
                            // ).toFixed(2),
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {renderPsxDataDropdown()}
                      </select>
                      )}
                      {/* <select
                        className="form-control w-100"
                        value={stockEntity['code']}
                        disabled={smFieldsReadOnly}
                        onChange={(e) => {
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          setStockEntity({
                            ...stockEntity,
                            code: e.target.value,
                            // close_rate: value[0].close_rate,
                            // amount: (
                            //   parseFloat(e.target.value) *
                            //   parseFloat(stockEntity.Unit)
                            // ).toFixed(2),
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {renderPsxDataDropdown()}
                      </select> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                      Total Units
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['total_units']}
                        onValueChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            total_units: e.value,
                            amount: (
                              parseFloat(e.value) *
                              parseFloat(stockEntity.average_cost_per_unit)
                            ).toFixed(2),
                            market_value : (parseFloat(e.value) * parseFloat(stockEntity.market_rate_per_unit)).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>

                <Col md="6">
                  <div className="form-group">
                      Available Units
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['unit']}
                        onValueChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            unit: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                    Aveage Cost/Unit
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right "
                        value={stockEntity['average_cost_per_unit']}
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            average_cost_per_unit: e.value,
                            amount: (
                              parseFloat(stockEntity.total_units) *
                              parseFloat(e.value)
                            ).toFixed(2),
                          });
                        }}
                      />
                    </div>

                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Total Cost / Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['amount']}
                        readOnly
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            amount: e.value,
                            unrealized_gain_loss : (parseFloat(stockEntity.market_value) - parseFloat(e.value)).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                    Market Rate/Unit
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right "
                        value={stockEntity['market_rate_per_unit']}
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            market_rate_per_unit: e.value,
                            market_value : (parseFloat(stockEntity.total_units) * parseFloat(e.value)).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col> 

                  <Col md="6">
                  <div className="form-group">
                    Market Value
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right "
                        value={stockEntity['market_value']}
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            market_value: e.value,
                            unrealized_gain_loss : (parseFloat(e.value) - parseFloat(stockEntity.amount)).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col md="6">
                  <div className="form-group">
                    Unrealized Gain/(Loss)
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right "
                        value={stockEntity['unrealized_gain_loss']}
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            unrealized_gain_loss: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group">
                    Investment as % of Market Value
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right "
                        value={stockEntity['investment_percentage_market_value']}
                        onValueChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            investment_percentage_market_value: e.value,
                          });
                        }}
                      />
                    </div>
                    </Col>
                </Row>
               
               
                <div className="">
                  {!smFieldsReadOnly ? (
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addEquityStock();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                   ):(
                    <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      updateEquityStock();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const updateMoneyMarketRates = async()=>{
    setUpdateMoneyMarketRatesLoading(true);
    let updatedData:any=[];
    if(moneyMarketSecuritiesByDate.length==0){
      setUpdateMoneyMarketRatesLoading(false);
      return toast.error(`Money Market Securities of (${moment(new Date()).format('YYYY-MM-DD')}) is not found.`)
    }

    try{
      moneyMarketSecuritiesByDate.map((security:any)=>{
        updatedData= moneyMarket.map((item:any)=>{
          if(security.code.replace('SECURITY_','')==item.code && security.issue_date==item.issue_date && security.maturity_date==item.maturity_date){
            let marketValue = (parseFloat(item.units) * parseFloat(security.market_rate_per_unit)).toFixed(2);
            let unRealizedGainLoss = (parseFloat(marketValue) - parseFloat(item.total_amount)).toFixed(2);
            let investment_percentage_market_value = (parseFloat(marketValue) / 1).toFixed(2);
            item.market_rate_per_unit= security.market_rate_per_unit;
            item.market_value = marketValue;
            item.unrealized_gain_loss = unRealizedGainLoss;
            item.investment_percentage_market_value = investment_percentage_market_value;
          }
         
          return item;
        })
      })

      setMoneyMarket(updatedData);
  setUpdateMoneyMarketRatesLoading(false);
  }catch(err:any){
    setUpdateMoneyMarketRatesLoading(false);
  }
    setUpdateMoneyMarketRatesLoading(false);
  }


  const updateEquityRates = async()=>{
    setUpdateStockRatesLoading(true);
    let updatedData:any=[];
    if(psxList.length==0){
      setUpdateStockRatesLoading(false);
      return toast.error(`PSX data is empty, Rates can't be update.`)
    }
    try{
        psxList.map((security:any)=>{
          updatedData= stockMarket.map((item:any)=>{
            if(security.symbol==item.code){
              let marketValue = (parseFloat(item.total_units) * parseFloat(security.close_rate)).toFixed(2);
              let unRealizedGainLoss = (parseFloat(marketValue) - parseFloat(item.amount)).toFixed(2);
              item.market_rate_per_unit= security.close_rate;
              item.market_value = marketValue;
              item.unrealized_gain_loss = unRealizedGainLoss;
              // updatedData.push(item);
            }
           
            return item;
          })
        })
  
    setStockMarket(updatedData);
    setUpdateStockRatesLoading(false);
    }catch(err:any){
      setUpdateStockRatesLoading(false);
    }
    setUpdateStockRatesLoading(false);
  }

  const [termDepositModal, setTermDepositModal] = useState(false);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code:'',
    name:'',
    principal_amount:'',
    interest_rate: '',
    from_date: '',
    to_date: '',
    bank:'',
    branch:'',
  });
  const addTermDeposit = async () => {
    // setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in termDepositEntity) {
      if (termDepositEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      // setDisableEquityBtn(false);
    } else {
      setTermDepositModal(false);
      // const check = termDeposit.filter((item) => {
      //   return item.interest_rate === termDepositEntity['interest_rate'];
      // });
      // if (check.length > 0) {
      //   termDeposit.map((item, index) => {
      //     if (item.code === termDepositEntity['code']) {
      //       item.interest_rate = (+item.interest_rate + +termDepositEntity['interest_rate']).toString();
      //     }
      //   });
      // } else {
        termDeposit.push(termDepositEntity);
        setTermDeposit(termDeposit);
      // }
      const filedEntirty = {
        code:'',
        name:'',
        principal_amount:'',
        interest_rate: '',
        from_date: '',
        to_date: '',
        bank:'',
        branch:'',
      };
      setTermDepositEntity(filedEntirty);
      // setDisableEquityBtn(false);
    }
  }

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || '')}</td>
          <td>{numberWithCommas(items.interest_rate || '')}</td>
          <td>{items.from_date}</td>
          <td>{items.to_date}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...termDeposit];
                array.splice(index, 1);
                setTermDeposit(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTermDepositPopup = () => {
    switch (termDepositModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTermDepositModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTermDepositModal(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Term Deposit</h6>
             </div> 


            <div className="modal-body ">
              <div className="">

              <Row>
                <Col>
                <div className="form-group">
                  Type
                  <select className='form-control w100'
                            onChange={(e)=>{
                              termDepositEntity['code']=e.target.value;
                              termDepositEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            >
                            <option value='' hidden> Select Type</option>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value="CDR">
                      {/* Call Deposit Receipts */}
                      Certificate Of Investment
                      </option>
                            <option value='LOP'>Letter of Placement</option>
                    </select>
                </div>
                </Col>
                  <Col>
                  <div className="form-group">
                     Principal Amount
                     <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        // value={stockEntity['close_rate']}
                        onValueChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            principal_amount: e.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                        Interest Rate
                        <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        onValueChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            interest_rate: e.value
                          });
                        }}
                        />
                      </div>
                  </Col>
                </Row>
            
                <Row>
                  <Col>
                  <div className="form-group">
                      From Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            from_date: e.target.value
                          });
                        }}
                      />
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div className="form-group">
                  To Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            to_date: e.target.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row> 
               
               <Row>
                 <Col>
                    <div className="form-group">
                      Bank
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          bank: e.target.value
                        });
                        getCounterBranchByName(e.target.value);
                      }}>

                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankDropdown()}
                      </select>
                    </div>
                     </Col>
                    <Col>
                      <div className="form-group">
                      Branch
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          branch: e.target.value
                        });
                      }}>
                        
                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Branch
                        </option>
                      {renderBranchDropdown()}
                      </select>
                    </div>
                 </Col>
               </Row>
               
                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addTermDeposit();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [codeChange, setCodeChange] = useState('');	
  const decimalValue = (value="0", len) => {	
    let startingValue = value?.split('.')[0];	
    let decimalVal = value?.split('.')[1];	
    let temp = '';	
    if (decimalVal !== undefined) {	
      let decimalString = decimalVal.toString();	
      if (decimalString.length <= len) {	
        temp = startingValue + '.' + decimalString;	
      } else {	
        temp = startingValue + '.' + decimalString.substr(0, len);	
      }	
    } else {	
      temp = startingValue;	
    }	
    return temp;	
  };	

  const uploadMoneyMarketPortfolio = async (e: any) => {
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if(type==="xlsx" || type === 'csv'){
      setFile(file);
      setFileName(file.name);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data:any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD',});
      /* Update state */
    
     if(data){
      if(data.length>0){
        let temp:any =[]
        for (var i=0;i<data.length;i++){
          if(data[i]['issue_date']!==undefined){
            data[i]['issue_date'] = data[i].issue_date==='' ? '' : moment(data[i].issue_date).format('YYYY-MM-DD');
          }
          if(data[i]['maturity_date']!==undefined){
            data[i]['maturity_date'] = data[i].maturity_date==='' ? '' : moment(data[i].maturity_date).format('YYYY-MM-DD');
          }

          if(!data[i]["security"]?.trim() || !data[i]["security_type"]?.trim() || !data[i]["total_cost_amount"]?.trim() )
          {
            setFile("")
            setFileName("")
          
            return toast.error("Securty, security Type and Total Cost Amount must be enerted in all row")
          }

          if(data[i]["security_type"]?.trim() != "PIB" && 
          data[i]["security_type"]?.trim() != "TBILL" && 
          data[i]["security_type"]?.trim() != "SUKUK" && 
          data[i]["security_type"]?.trim() != "TFC" &&
          data[i]["security_type"]?.trim() != "CP" &&
           data[i]["security_type"]?.trim() != "TDR" && 
           data[i]["security_type"]?.trim() != "CDR" && 
           data[i]["security_type"]?.trim() != "LOP")
           {
            setFile("")
            setFileName("")
          
            return toast.error("Invalid Security type")

           }

          temp.push({
            code:data[i]["security"],
            security_type:data[i]["security_type"],
            name:data[i]["name"],
            issue_date:data[i]['issue_date'],
            maturity_date:data[i]['maturity_date'],
            coupon_rate:data[i]["coupon_rate_ percentage"],
            yield:data[i]["yield_percentage"],
            coupon_payment:data[i]["coupon_payment_year"],
            coupon_days:data[i]["coupon_days"],
            mmface_value:data[i]["money_market_face_value_unit"],
            total_face_value:data[i]["total_face_value"],
            units:data[i]["units"],
            average_cost_per_unit:data[i]["avg_cost_unit"],
            total_amount:data[i]["total_cost_amount"],
            market_rate_per_unit:data[i]["market_rate_unit"],
            market_value:data[i]["market_value"],
            unrealized_gain_loss:data[i]["unrealized_gain_loss"],
            investment_percentage_market_value:data[i]["investment_as_percentage_of_market_value"],
            days_of_maturity:data[i]["maturity_days"],
            unredeem_value:data[i]["unredeemed_value"],
            principal_amount:data[i]["principal_amount"]

          })
         
        }

        setMoneyMarket([...moneyMarket,...temp]);
      }
     
     
      //setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const uploadStockMarketPortfolio = async (e: any) => {
    setFileError2('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if(type==="txt"){ 
      setFile(file);
      setFileName2(file.name);
    processLineByLine(file);
  
    }else if(type==="xlsx" || type === 'csv'){
      setFile(file);
      setFileName2(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data:any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
      /* Update state */
    
     if(data){
      if(data.length>0){
        let temp:any =[]
        for (var i=0;i<data.length;i++){
          if(!data[i]["security"]?.trim() || !data[i]["market"]?.trim())
          {
            setFile("")
            setFileName2("")
          
            return toast.error("Securty and market must be enerted in all row")
          }
            temp.push({
              code:data[i]["security"],
              market:data[i]["market"],
              total_units:data[i]["total_units"],
              unit:data[i]["available_units"],
              average_cost_per_unit:data[i]["average_cost_unit"],
              amount:data[i]["total_cost_amount"],
              market_rate_per_unit:data[i]["market_rate_unit"],
              market_value:data[i]["market_value"],
              unrealized_gain_loss:data[i]["unrealized_gain_loss"],
              investment_percentage_market_value:data[i]["investment_as_percentage_of_market_value"]
            })
    }
      setStockMarket([...stockMarket,...temp]);
  }
      //setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const uploadTermDepositPortfolio = async (e: any) => {
    setFileError2('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if(type==="xlsx"){
      setFile(file);
      setFileName3(file.name);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data:any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
      /* Update state */
    
     if(data){
      if(data.length>0){
        let temp:any=[]
        for (var i=0;i<data.length;i++){
          if(data[i]['from_date']!==undefined){
            data[i]['from_date'] = data[i].from_Date==='' ? '':moment(data[i].from_Date).format('YYYY-MM-DD');
          }
          if(data[i]['to_date']!==undefined){
            data[i]['to_date'] = data[i].to_date==='' ? '' :moment(data[i].to_date).format('YYYY-MM-DD');
          }
          if(!data[i]["code"]?.trim() || 
          !data[i]["type"]?.trim() || 
          !data[i]["principal_amount"]?.trim() ||  
          !data[i]["interest_rate"]?.trim() ||  
          !data[i]["from_date"]?.trim()  || 
          !data[i]["to_date"]?.trim()  || 
          !data[i]["bank"]?.trim()  || 
          !data[i]["branch"]?.trim()   )
          {
            setFile("")
            setFileName3("")
          
            return toast.error("Kinldly Fill all details in all row")
          }
         
          temp.push({
            code:data[i]["code"],
            name:data[i]["type"],
            principal_amount:data[i]["principal_amount"],
            interest_rate:data[i]["interest_rate"],
            from_date:data[i]['from_date'],
            to_date:data[i]['to_date'],
            bank:data[i]["bank"],
            branch:data[i]["branch"]
          })
        }

        setTermDeposit([...termDeposit,...temp]);
      }
    
      //setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  async function processLineByLine(fileObj:any) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content:any=[];
    reader.onload = function(fileObj) {
     content = reader.result;
     content = content?.split('\n');
     let headings = content[0].split(':')
     let line1 = headings[0].replace(/"/g, '').split(',');
     let code =line1[1];
    //  let date = headings[1].replace(/"/g, '');
    //  moment.defaultFormat = "DD/MM/YYYY";
     if(code===cdc_participant_id){
     let column = [] as any;
     var data = [] as any;
     var i = 0;
     let TotalLine= content.length-1;
     for (i=0;i<TotalLine; i++){
     content[i] = content[i].replaceAll('"','').trim();
    //  let str = content[i].split(':');
     let str = content[i].split(',');
    if(i>=1){
      let obj :any;
      if(str[0].toString().includes('-')){
        obj = {
          ['code']: str[0],
          ['market']: 'Future',
          ['unit']: str[4],
          ['close_rate']: '0',
          ['amount']: '0',
          ['pledged']: str[8],
        };
      }else{
        obj = {
        ['code']: str[0].toString().trim(),
        ['market']: 'Regular',
        ['unit']: str[4],
        ['close_rate']: '0',
        ['amount']: '0',
        ['pledged']: str[8],
      };
    }
      data.push(obj);
  }
}
setStockMarket(data);
}else{
  setFileName2('');
  setFile('');
  toast.error("Invalid file.")
}
}
}
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		

        <div className="input-holder">	
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/funds')
                  }}></i>Edit - Funds</h4>	 
            <Link to="/admin/funds" className="t-3 ml-auto btn btn-primary btn-sm" replace>	
              <i className="fa fa-eye mr-2"></i> View All	
            </Link>	 
        </div>

        <Row>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Fund Details
                  </CardTitle>
                </CardHeader>
                <CardBody>

                <div className="form-group  ">	
                    <label>Fund Name</label>	
                    <div className=" ">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          namelError ? 'required-border' : ''	
                        }`}	
                        value={fundName}	
                        onChange={(e) => {	
                          setFundName(e.target.value);	
                          setNameError('');	
                        }}	
                      />	
                      {/* {namelError ? <p className="error-labels error-message2">{namelError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group  ">	
                    <label>Fund Code</label>	
                    <div className="  disable">	
                      <input	
                        type="text"	
                        readOnly	
                        className={`form-control  w-100 ${	
                          codeError ? 'required-border' : ''	
                        }`}	
                        value={code}	
                        onChange={(e) => {	
                          setCode(e.target.value.toUpperCase());	
                          setCodeError('');	
                        }}	
                      />	
                      {/* {codeError ? <p className="error-labels error-message">{codeError}</p> : ''} */}	
                    </div>	
                  </div>

                  {/* <div className="form-group  ">	
                    <label>AMC Name</label>	
                    <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          amcNameError ? 'required-border' : ''	
                        }`}	
                        value={amcName}	
                        onChange={(e) => {	
                          setAmcError('');	
                          setAmcName(e.target.value);	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          {' '}	
                          Select An AMC	
                        </option>	
                        {renderAmcDropdown()}	
                      </select>	
                    </div>	
                  </div> */}

                  <div className="form-group  ">	
                    <label>Category / Type of Fund</label>	
                    <div className="">	
                      {/* <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          fundTypeError ? 'required-border' : ''	
                        }`}	
                        value={fundType}	
                        onChange={(e) => {	
                          setFundType(e.target.value);	
                          setFundTypeError('');	
                        }}	
                      />	 */}
                       <select
                        className={`form-control input-broker  w-100 ${
                          fundTypeError ? 'required-border' : ''
                        }`}
                        value={fundType}
                        onChange={(e) => {
                          setFundType(e.target.value);
                          setFundTypeError('');
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select
                        </option>
                        {CategoryTypesFundDropdown.map(item=>{
                          return(
                            <option value={item} key={item}>
                          {item}
                        </option>

                          )
                        })}
                        {/* <option value="Agressive Income Fund">
                          Agressive Income Fund
                        </option>
                        <option value="Asset Allocation Fund">
                        Asset Allocation Fund
                        </option>
                        <option value="Income Fund">Income Fund</option>
                        <option value="Money Market Fund">
                          Money Market Fund
                        </option>
                        <option value="Stock Fund">Stock Fund</option>
                        <option value="Private Fund Managment Co">
                          Private Fund Managment Co
                        </option>
                        <option value="Fund of Fund">Fund of Fund</option>
                        <option value="Special Managed Account">Special Managed Account (SMA)</option>
                        <option value="Private Equity">Private Equity</option>
                        <option value="Venture Capital">Venture Capital</option> */}
                      </select>
                      {/* {fundTypeError ? <p className="error-labels error-message">{fundTypeError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group  ">
                    <label>
                      Dividend Period{' '}
                      <span className="color-amber">*</span>
                    </label>
                    <div>
                      <select
                        className={`form-control input-broker  w-100 ${
                          fundDividendPeriodError ? 'required-border' : ''
                        }`}
                        value={fundDividendPeriod}
                        onChange={(e) => {
                          setFundDividendPeriod(e.target.value);
                          setFundDividendPeriodError('');
                        }}
                      >
                        <option value="" defaultChecked hidden> Select </option>
                        <option value="None"> None </option>
                        <option value="Daily"> Daily </option>
                        <option value="Monthly"> Monthly </option>
                        <option value="Quarterly"> Quarterly </option>
                        <option value="Half Yearly"> Half Yearly </option>
                        <option value="Yearly"> Yearly </option>
                      </select>
                    </div>
                  </div>

                </CardBody>
              </Card>
          </Col>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Incorporation
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group  ">	
                    <label>Date of Incorporation</label>	
                    <div className="">	
                      <input	
                        type="date"	
                        className={`form-control  w-100 ${	
                          incorporationDateError ? 'required-border' : ''	
                        }`}	
                        value={incorporationDate}	
                        onChange={(e) => {	
                          setIncorporationDate(e.target.value);	
                          setIncorporationDateError('');	
                        }}	
                      />	
                      {/* {incorporationDateError ? <p className="error-labels error-message2">{incorporationDateError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group  ">	
                    <label>Date of Revocation</label>	
                    <div className="">	
                      <input	
                        type="date"	
                        className="form-control"	
                        value={revocationDate}	
                        onChange={(e) => {	
                          setRevocationDate(e.target.value);	
                        }}	
                      />	
                    </div>	
                  </div>	

                  <Row>
                    <Col md ="6">
                      <div className="form-group  ">	
                        <label>Opening NAV</label>	
                        <div className="">	
                          <input	
                            type="text"	
                            className={`form-control  w-100 ${	
                              navError ? 'required-border' : ''	
                            }`}	
                            value={nav}	
                            onChange={(e) => {	
                              let value = decimalValue(e.target.value, 4);	
                              setNav(value);	
                              setNavError('');	
                              setCodeChange(value);	
                            }}	
                          />	
                          {/* {navError ? <p className="error-labels error-message2">{navError}</p> : ''} */}	
                        </div>	
                    </div>
                    </Col>
                    <Col md ="6">
                      <div className="form-group">
                        <label htmlFor="">Face Value <span className="color-amber">*</span></label>
                        <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={faceValue}
                          onValueChange={(e) => {
                            setFaceValue(e.value); 
                            // setNameError('');
                          }}
                          />
                        </div>
                    </Col>
                  </Row>

                  <Row>
                   <Col md="6">
                    <div className="form-group">
                      <label>
                        CDC Participant ID 
                      </label>
                      <div  >
                        <input
                          type="text"
                          className={`form-control  w-100 `}
                          value={cdc_participant_id}
                          onChange={(e) => {
                            setCDCParticipantID(e.target.value);
                          }}
                          
                        /> 
                      </div>
                    </div>  
                   </Col>
                   <Col md="6">
                     <div className="form-group">
                       <label htmlFor="">CDC Account Number </label>
                       <input type="text" className="form-control"  
                       value={cdc_account_number}
                        onChange={(e) => {
                          setCDCAccountNumber(e.target.value); 
                        }}
                        />
                      </div>
                   </Col>
                 </Row>
                  

                  <div>	
                    <label>PSX Listing</label>	
                    <div>	
                      <div>	
                        <form action=""> 	
 	                    <FormGroup check className="form-check-radio d-inline mr-3">	
                        <Label check>	
                          <Input	
                           type="radio"	
                             id="listed"	
                             name="listed"	
                             value="true"	
                             checked={isCheckBoxSelected}	
                             onChange={(e) => {	
                               setPsxListingError('');	
                               setPsxListing(e.target.value);	
                               setCheckBoxSelected(true);	
                             }}	
                          />	
                          <span className="form-check-sign" />	
                          Listed	
                        </Label>	
                      </FormGroup>	
 	
                    <FormGroup check className="form-check-radio d-inline mr-3">	
                        <Label check>	
                          <Input	
                           id="unlisted"	
                           name="listed"	
                           value="false"	
                           onChange={(e) => {	
                             setPsxListingError('');	
                             setPsxListing(e.target.value);	
                             setCheckBoxSelected(false);	
                           }}	
                            type="radio"	
                          />	
                          <span className="form-check-sign" />	
                          Unlisted	
                        </Label>	
                      </FormGroup>	
                          <br />	
                        </form>	
                        {psxListingError ? (	
                          <p className="error-labels error-mes">	
                            {psxListingError}	
                          </p>	
                        ) : (	
                          ''	
                        )}	
                      </div>	
                    </div>	
                  </div> 
                </CardBody>
              </Card>
          </Col>
        </Row>

        <Row>
          <Col md>
          <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Detail
                </CardTitle>
              </CardHeader>
              <CardBody>

                <Row>
                  <Col md="4">
                  <div className="form-group  ">
                    <label>Offer Price</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          offerPriceError ? 'required-border' : ''
                        }`}
                        value={offerPrice}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 4)
                          setOfferPrice(value);
                          setOfferPriceError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>Redemption Price</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          redemptionPriceError ? 'required-border' : ''
                        }`}
                        value={redemptionPrice}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value,2)
                          setRedemptionPrice(value);
                          setRedemptionPriceError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>OutStanding Units</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          outstandingUnitsError ? 'required-border' : ''
                        }`}
                        value={outstandingUnits}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 4)
                          setOutstandingUnits(value);
                          setOutstandingUnitsError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

               
                <Col md="4">
                  <div className="form-group  ">
                    <label>Management Fee%</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          managementFeeError ? 'required-border' : ''
                        }`}
                        value={managementFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setManagementFee(value);
                          setManagementFeeError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group  ">
                    <label>SST on Management Fee %</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          sstmanagementfeeError ? 'required-border' : ''
                        }`}
                        value={sstmanagementfee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSstManagementFee(value);
                          setSstManagementFeeError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>AMC Reimbursement Exp % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          amcReimbursementExpError ? 'required-border' : ''
                        }`}
                        value={amcReimbursementExp}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setReinbursementExp(value);
                          setReinbursementExpError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>
 
                  
                  <Col md="4">
                  <div className="form-group  ">
                    <label>Selling {'&'} Marketing Exp % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          sellingMarketingExpError ? 'required-border' : ''
                        }`}
                        value={sellingMarketingExp}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSellingMarketingExp(value);
                          setSellingMarketingExpError('');
                        }}
                      />

                    </div>
                  </div>
                    
                  </Col>
                    <Col md="4">
                    <div className="form-group  ">
                    <label>SECP Fee % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          secpFeeError ? 'required-border' : ''
                        }`}
                        value={secpFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSECPFee(value);
                          setSECPFeeError('');
                        }}
                      />

                    </div>
                  </div>
                    </Col>

                    <Col md="4">
                    <div className="form-group  ">
                    <label>SECP Supervisory Fee %  </label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          secpSupervisoryFeeError ? 'required-border' : ''
                        }`}
                        value={secpSupervisoryFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSECPSupervisoryFee(value);
                          setSECPSupervisoryFeeError('');
                        }}
                      />

                    </div>
                  </div>
                    </Col>
                    <Col md="4">
                    <div className="form-group  ">
                    <label>SST on Trustee Fee %</label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 `}
                        value={sstTrusteeFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSSTTrusteeFee(value)
                        }}
                        
                      />

                    </div>
                  </div>
                    </Col>


                    <Col md="4">
                    <div className="form-group  ">
                    <label>Sales Load %</label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 `}
                        value={saleLoad}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSaleLoad(value)
                        }}
                        
                      />

                    </div>
                  </div>
                    </Col>

                    <Col md="4">
                    <div className="form-group  ">
                    <label>Backend Load %</label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 `}
                        value={backendLoad}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setBackendLoad(value)
                        }}
                        
                      />

                    </div>
                  </div>
                    </Col>

                    <Col md="4">
                    <div className="form-group  ">
                    <label>Contingent Load %</label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 `}
                        value={contingentLoad}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setContingentLoad(value)
                        }}
                        
                      />

                    </div>
                  </div>
                    </Col>

                    <Col md="4">
                    <div className="form-group  ">
                    <label>SST on Sales/Backend/Contingent Load %</label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 `}
                        value={sstSales}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSSTSales(value)
                        }}
                        
                      />

                    </div>
                  </div>
                    </Col>
                  </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">
                  Trustee Fee Slab
                </CardTitle>
              </CardHeader>
              <CardBody>
              <Col>
                <label>Add Fee Slab</label> 
                <div>
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <button	
                    data-tip="Add Fee Slab"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      setFeeSlabModal(true)
                    }}	
                  > <i className="fa fa-plus"></i> </button>	
                </div>
                </Col>
              <div className="table-responsive" >
                      {slabData?.length > 0 &&  <table  className="table   ">
                          <thead>
                            <tr>
                              <th>Slab From</th>
                              <th>Slab To</th>
                              <th className="text-right">Rate %</th>
                                  <th className="text-right">Fix Amount</th>
                                  <th className="text-right">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {renderFeeDataInTable()}
                          </tbody>
                       
                      
                        </table>}
                      </div>

                </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Money Market Portfolio

                    <a className='pt-1 float-right'  href='../../../../Uploader-Sample/MONEY MARKET PORTFOLIO SAMPLE.xlsx'  download>Download Sample</a>

                    {moneyMarket.length>0 && (
                  <button className='btn btn-primary btn-sm float-right mr-3'
                  onClick={(e)=>{
                    updateMoneyMarketRates();
                  }}
                  
                  disabled={Boolean(updateMoneyMarketRatesLoading)}	
                  >	
                    {updateMoneyMarketRatesLoading ? (	
                      <>	
                        <span	
                          className="spinner-border login-txt spinner-border-sm"	
                          role="status"	
                          aria-hidden="true"	
                        ></span>	
                        <span className="login-txt"> Loading...</span>	
                      </>	
                    ) : (	
                      <span>Update Money Market Rates</span>	
                    )}
                  </button>
                  )}
                  </CardTitle>
                </CardHeader>
                <CardBody>

                <div className="input-size-pos">	
                <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <button	
                    data-tip="Add Money Maket"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      setModalView(!modalView);
                      setDisableAddBtn(false);
                      setStockModal(false);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>	
                </div>
                </Col>
                <Col>
                  <label>Upload Portfolio</label> 
                              <div onClick={() => myRef1?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName === '' ? 'Choose File' : fileName}
                          </div> 
                          <input
                            type="file"
                            ref={myRef1}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadMoneyMarketPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">{fileError}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </Col>
                </Row>
                </div>

            <div className="table-responsive">
                {moneyMarket.length > 0 && (	
                <div className="input-size-pos mb-3 mt-2 ">	 
                  <table className="table ">	
                    <thead>	
                      <tr>	
                      <th>Symbol</th>
                        <th>Security Type</th>
                        <th>Name</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th>Coupon Rate %</th>
                        <th>Yield %</th>
                        <th>Coupon Payment/Year</th>
                        <th>Coupon Days</th>
                        <th>Money Market Face Value/Unit</th>
                        <th>Total Face Value</th>
                        <th>Units</th>
                        <th>Avg Cost/Unit</th>
                        <th>Total Cost / Amount</th>
                        <th>Market Rate/Unit</th>
                        <th>Market Value</th>
                        <th>Unrealized Gain/(Loss)</th>
                        <th>Investment as % of Market Value</th>
                        <th>Maturity Days</th>
                        <th>Unredeemed Value</th>
                        <th>Principal Amount</th>
                        <th>Action</th>
                      </tr>	
                    </thead>	
                    <tbody>{renderMoneyMarketDataInTable()}</tbody>	
                  </table>	
                </div>	
              )}
              </div>	  
                </CardBody>
              </Card>
          </Col>
          <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Stock Market Portfolio  


                  <a className='pt-1 float-right'  href='../../../../Uploader-Sample/STOCK MARKET PORTFOLIO SAMPLE.xlsx'  download>Download Sample</a>


                  {stockMarket.length>0 && (
                  <button className='btn btn-primary btn-sm float-right mr-3'
                  onClick={(e)=>{
                    updateEquityRates();
                  }}
                  
                  disabled={Boolean(updateStockRatesLoading)}	
                  >	
                    {updateStockRatesLoading ? (	
                      <>	
                        <span	
                          className="spinner-border login-txt spinner-border-sm"	
                          role="status"	
                          aria-hidden="true"	
                        ></span>	
                        <span className="login-txt"> Loading...</span>	
                      </>	
                    ) : (	
                      <span>Update Stock Rates</span>	
                    )}
                  </button>
                  )}

                  </CardTitle>
                </CardHeader>
                <CardBody> 
               	
                 <div className="input-size-pos ">	
                 <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                   <ReactTooltip	 
                     textColor="white"	
                     backgroundColor="black"	
                     effect="float"	
                   />	
                   <button	
                     data-tip="Add Stock Market"	
                     className="btn-round btn-icon btn btn-primary"	
                     onClick={() => {	
                       setEquityModalFlag(!equityModalFlag);	
                       setDisableEquityBtn(false);	
                       setStockModal(true);	
                       const filedEntirty = {	
                        code: '',
                        unit: '',
                        amount: '0',
                        total_units:'0',
                        average_cost_per_unit:'0',
                        market_rate_per_unit:'0',
                        market_value:'0',
                        unrealized_gain_loss:'0',
                        investment_percentage_market_value:'0',	
                       };	
                       setStockEntity(filedEntirty);	
                     }}	
                   > <i className="fa fa-plus"> </i> </button>	
                 </div>	 
                 </Col>
                 <Col>
                
                    <label>Upload Portfolio</label> 
                    <div onClick={() => myRef2?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName2 === '' ? 'Choose File' : fileName2}
                          </div> 
                          <input
                            type="file"
                            ref={myRef2}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadStockMarketPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError2 ? (
                            <p className="error-labels error-message2">{fileError2}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      
                      </Col>

                 </Row>
                 </div>

              <div className="table-responsive">
                 {stockMarket.length > 0 && (
                <div className="input-size-pos mb-3 mt-2">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Market</th>
                        <th>Total Units</th>
                        <th>Available Units</th>
                        <th>Average Cost / Unit</th>
                        <th>Total Cost / Amount</th>
                        <th>Market Rate/Unit</th>
                        <th>Market Value</th>
                        <th>Unrealzed Gain/(Loss)</th>
                        <th>Investment as %  Market Value</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderEquityInTable()}</tbody>
                  </table>
                </div>
              )}	
            </div>
                </CardBody>
              </Card>
          </Col>
        </Row>
 
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Term Deposit Portfolio
                  <a className=' pt-1 float-right' href='../../../../Uploader-Sample/TERM DEPOSIT PORTFOLIO SAMPLE.xlsx'  download>Download Sample</a>
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="input-size-pos">

                <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />  
                  <button	
                    data-tip="Add Term Deposit"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      // setModalView(!modalView);
                      // setTermDepositModal(!termDepositModal)
                      setDisableAddBtn(false);
                      setTermDepositModal(true);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>
                  </div>
                  </Col>   
                  <Col>
  <label>Upload Portfolio</label> 
              <div onClick={() => myRef3?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName3 === '' ? 'Choose File' : fileName3}
                          </div> 
                          <input
                            type="file"
                            ref={myRef3}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadTermDepositPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">{fileError}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </Col> 
             </Row>
                </div>

                <div className="table-responsive">
                {termDeposit.length > 0 && (
                <div className="input-size-pos mb-3 mt-2">
                  {/* <h3>Money Market Portfolio</h3> */}
                  <table className="table   ">
                    <thead>
                      <tr>
                      <th>Code</th>
                        <th>Name</th>
                        <th>Principal Amount</th>
                        <th>Interest Rate</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Bank</th>
                        <th>Branch</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTermDepositInTable()}</tbody>
                  </table>
                </div>
              )}
</div>
              </CardBody>
            </Card>
          </Col>
          </Row>

          <button	
            className="btn btn-primary"	
            onClick={EditFundData}	
            disabled={Boolean(Loading)}	
          >	
            {Loading ? (	
              <>	
                <span	
                  className="spinner-border login-txt spinner-border-sm"	
                  role="status"	
                  aria-hidden="true"	
                ></span>	
                <span className="login-txt"> Loading...</span>	
              </>	
            ) : (	
              <span>Update</span>	
            )}	
          </button>	 

          <button	
            className="btn btn-default mr-2"	
            onClick={() => {	
              history.replace('/admin/funds');	
            }}	
          >	
            <span>Cancel</span>	
          </button>	 
  	
  	
            {renderModalPopup()}	
            {renderEquityPopup()}	 
            {renderTermDepositPopup()}
            {renderFeeSlabModal()}
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default EditFunds;	
