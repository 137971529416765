import React, { useState } from "react";
import ToggleButton from "react-toggle-button";
import Header from "./../components/Header";
import {
  getTwoFACode,
  enableDisableTwoFA,
} from "./../stores/services/auth.service";
import { Container, Row, Col } from "reactstrap";
import { changePassword } from "./../stores/services/user.service";
import { useHistory } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import loaderIcon from "./../assets/loader.svg";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
const UserManage = () => {
  const history = useHistory();
  let [changePass, setChangePAssword] = useState(false);
  let [toggleState, setToggleState] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  let [oldPassword, setOldPassword] = useState("");
  let [newPassword, setNewPassword] = useState("");
  let [repeatPassword, setRepeatPassword] = useState("");
  let [twoFaCode, setTwoFaCode] = useState(-1);
  let [userObj, setUserObj] = useState(
    JSON.parse(sessionStorage.getItem("loggedInUser") || "")
  );

  React.useEffect(() => {
    if (userObj.two_fa_enabled === "true") {
      setToggleState(true);
    } else {
      setToggleState(false);
    }
  }, []);

  const [inputTypeOldPass, setInputTypeOldPass] = useState("password");

  const handleClickOldPass = () => {
    inputTypeOldPass === "text"
      ? setInputTypeOldPass("password")
      : setInputTypeOldPass("text");
  };

  const [inputTypeNew, setinputTypeNew] = useState("password");

  const handleClickNewPass = () => {
    inputTypeNew === "text"
      ? setinputTypeNew("password")
      : setinputTypeNew("text");
  };

  const [inputTypeRepeatPass, setinputTypeRepeatPass] = useState("password");

  const handleClickRepeatPass = () => {
    inputTypeRepeatPass === "text"
      ? setinputTypeRepeatPass("password")
      : setinputTypeRepeatPass("text");
  };

  const getTwoFACodeFn = () => {
    getTwoFACode(userObj.email)
      .then((response) => {
        toast.success(response.data.message);
        setMainLoader(false);
      })
      .catch((err) => {
        setMainLoader(false);
      });
  };

  const enableDisableTwoFAFn = () => {
    setLoading(true);
    if (twoFaCode === -1) {
      setLoading(false);
      return;
    }
    enableDisableTwoFA(
      userObj.email,
      twoFaCode.toString(),
      toggleState.toString()
    )
      .then((response) => {
        toast.success(response.data.message);
        userObj.two_fa_enabled = toggleState.toString();
        sessionStorage.setItem("loggedInUser", JSON.stringify(userObj));
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
  const [oldPassError, setOldPassError] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [repPassError, setRepPassError] = useState("");
  const validate = () => {
    let oldpasErr,
      newPassErr,
      RepPassErr = "";
    oldPassword.trim() === "" ? (oldpasErr = "Required") : (oldpasErr = "");
    newPassword.trim() === ""
      ? (newPassErr = "Required")
      : passRegex.test(newPassword) !== true
      ? (newPassErr = "Password Invalid")
      : (newPassErr = "");
    repeatPassword.trim() === ""
      ? (RepPassErr = "Required")
      : newPassword !== repeatPassword
      ? (RepPassErr = "Password Not Match")
      : (RepPassErr = "");
    if (oldpasErr || newPassErr || RepPassErr) {
      setOldPassError(oldpasErr);
      setNewPassError(newPassErr);
      setRepPassError(RepPassErr);
      return false;
    } else {
      return true;
    }
  };
  const changePasswordBtn = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await changePassword(
          sessionStorage.getItem("email") || "",
          oldPassword,
          newPassword
        );
        sessionStorage.clear();
        history.replace("/");
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };
  const renderContent = () => {
    const borderRadiusStyle = { borderRadius: 2 };
    // if (!changePass) {
    //   return (
    //     <div className="mt-5">
    //       <ToggleButton
    //         value={toggleState || false}
    //         thumbStyle={borderRadiusStyle}
    //         trackStyle={borderRadiusStyle}
    //         onToggle={() => {
    //           setMainLoader(true);
    //           setToggleState(!toggleState);
    //           getTwoFACodeFn();
    //         }}
    //       />
    //       {mainLoader === false ? (
    //         <>
    //           <p className="setting-title">2FA Authentication</p>
    //           <Row>
    //             <Col md="6">
    //               <div className="form-group">
    //                 <label>Enter 6-Digit Code</label>
    //                 <input
    //                   className="form-control"
    //                   onChange={(e) => {
    //                     setTwoFaCode(parseInt(e.target.value));
    //                   }}
    //                 />
    //               </div>
    //             </Col>
    //           </Row>
    //           <button
    //             className="btn btn-primary"
    //             onClick={enableDisableTwoFAFn}
    //             disabled={Boolean(Loading)}
    //           >
    //             {Loading ? (
    //               <>
    //                 <span
    //                   className="spinner-border login-txt spinner-border-sm"
    //                   role="status"
    //                   aria-hidden="true"
    //                 ></span>
    //                 <span className="login-txt"> Loading...</span>
    //               </>
    //             ) : (
    //               <span>Update</span>
    //             )}
    //           </button>
    //         </>
    //       ) : (
    //         <img src={loaderIcon} alt="icon" />
    //       )}
    //     </div>
    //   );
    // } else {
    return (
      <div className="">
        {/* <p className="setting-title mb-2">Change Password</p>  */}
        <Row>
          <Col md="6">
            <div className="form-group">
              <label>Old Password</label>
              <div className="">
                <input
                  type={inputTypeOldPass}
                  className="form-control"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setOldPassError("");
                  }}
                />
                {oldPassError ? (
                  <p className="error-labels error-pass-change">
                    {oldPassError}
                  </p>
                ) : (
                  ""
                )}
                {inputTypeOldPass === "text" ? (
                  <MdVisibility
                    className="visibility-icon-pass"
                    onClick={() => handleClickOldPass()}
                  />
                ) : (
                  <MdVisibilityOff
                    className="visibility-icon-pass"
                    onClick={() => handleClickOldPass()}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <ReactTooltip
              textColor="white"
              backgroundColor="black"
              effect="float"
              html={true}
            />
            <div
              className=""
              data-tip="Password Should be at least 8 characters <br/> including special characters"
            >
              <label>New Password</label>
              <div className="form-group">
                <input
                  type={inputTypeNew}
                  className="form-control"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setNewPassError("");
                  }}
                />
                {newPassError ? (
                  <p className="error-labels error-pass-change">
                    {newPassError}
                  </p>
                ) : (
                  ""
                )}
                {inputTypeNew === "text" ? (
                  <MdVisibility
                    className="visibility-icon-pass icon-pass"
                    onClick={() => handleClickNewPass()}
                  />
                ) : (
                  <MdVisibilityOff
                    className="visibility-icon-pass icon-pass"
                    onClick={() => handleClickNewPass()}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="">
              <label>Repeat Password</label>
              <div className="form-group">
                <input
                  type={inputTypeRepeatPass}
                  className="form-control"
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                    setRepPassError("");
                  }}
                />
                {repPassError ? (
                  <p className="error-labels error-pass-change">
                    {repPassError}
                  </p>
                ) : (
                  ""
                )}
                {inputTypeRepeatPass === "text" ? (
                  <MdVisibility
                    className="visibility-icon-pass icon-pass"
                    onClick={() => handleClickRepeatPass()}
                  />
                ) : (
                  <MdVisibilityOff
                    className="visibility-icon-pass icon-pass"
                    onClick={() => handleClickRepeatPass()}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-4">
          <button
            className="btn btn-primary"
            onClick={changePasswordBtn}
            disabled={Boolean(Loading)}
          >
            {Loading ? (
              <>
                <span
                  className="spinner-border login-txt spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="login-txt"> Loading...</span>
              </>
            ) : (
              <span>Update Password</span>
            )}
          </button>
        </div>
      </div>
    );
    // }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              {/* <Header /> */}
              <ToastContainer />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {/* Backoffice 2.0 */}
                    {/* User Settings  */}
                    <span className="text-primary">Change Password</span>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="w-100 d-flex">
                    {/* <div
                onClick={() => {
                  setChangePAssword(false);
                }}
                className={`user-header card-title ${changePass ? '' : 'selected-option'}`}
              >
                <span>2FA Authentication</span>
              </div> */}
                    {/* <div
                onClick={() => {
                  setChangePAssword(true);
                }}
                // className={`user-header card-title ${changePass ? 'selected-option' : ''}`}
                className={`user-header card-title`}
              >
                <span></span>
              </div> */}
                  </div>

                  {renderContent()}
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserManage;
