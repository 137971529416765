import { ActionTypes } from "../contants/action-types";

const initialState = {
    chartOfAccounts:[]
}

export const chartOfAccountsReducer = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.GET_CHART_OF_ACCOUNTS:
        //   return {...state, chartofAcconts:payload};
          return payload;
        default :
          return state;
    }

}