import React, { useContext, useState } from "react";
import {
    Container,
    Row,
    Col,
    Label,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Button,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemText, } from "@mui/material";
import {  useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../../../contexts/ThemeContext";

const HomePage = () => {
    const features = JSON.parse(sessionStorage.getItem("allRoutes") || '[]')
    const history = useHistory()
    const context = useContext(ThemeContext)
    
 const  txnTypeCatagory = useSelector((state:any)=>state.txnTypeCategories);
 const [createTransactionModal,setCreateTransactionModal] = useState(false)
 const [PATH,setPATH] = useState("")


    const IconsArray = {
        "Funds Management":   {
            "Dashboard": "tim-icons icon-app ",
            "Transactions": "tim-icons icon-bank ",
            "Create Transactions": "tim-icons icon-coins ",
            "Funds": "tim-icons icon-chart-pie-36 ",
            "Bank Accounts": "tim-icons icon-bank ",
            "Bank Statement": "tim-icons icon-attach-87 "
        },
        "Unit Management" :{
            "Dashboard": "tim-icons icon-app ",
            "Transactions": "tim-icons icon-bank ",
            "Create Transactions": "tim-icons icon-coins ",
            "Unit Holders": "tim-icons icon-atom",
            "Unit Holder Statement": "tim-icons icon-attach-87",
            "Potential Investors": "tim-icons icon-single-02",

        },
        "Trade Settlement":{
            "Dashboard": "tim-icons icon-app ",
            "Transactions": "tim-icons icon-bank ",
            "Create Transactions": "tim-icons icon-coins ",
            "Daily Settlements": "tim-icons icon-chart-bar-32",
            "Transactions Report": "tim-icons icon-single-copy-04"
        },
        "Portfolios":{
            "Dashboard": "tim-icons icon-app ",
            "Portfolio Summary": "tim-icons icon-single-copy-04",
            "Securities": "tim-icons icon-key-25",
            "Corporate Actions": "tim-icons icon-paper ",
            "Dividend Announcements": "tim-icons icon-alert-circle-exc ",
            "Capital Gain Tax": "tim-icons icon-money-coins"

        },
        "Accounts":{
            "Dashboard": "tim-icons icon-app ",
            "Transactions": "tim-icons icon-bank ",
            "Create Transactions": "tim-icons icon-coins ",
            "Chart of Accounts": "tim-icons icon-chart-bar-32",
            "Financial Periods": "tim-icons icon-compass-05",
            "Payment Beneficiaries": "tim-icons icon-money-coins",
            "Payment Receipt Register": "tim-icons icon-bank ",
            "Trial balance": "tim-icons icon-money-coins",
            "Voucher Report": "tim-icons icon-paper",
            "Trial Balance" : "tim-icons icon-money-coins",
            "Bank Accounts": "tim-icons icon-money-coins",
            "Banks": "tim-icons icon-bank ",
            "Bank Branches": "tim-icons icon-bell-55 ",

        },
       "Transactions": {
            "Dashboard": "tim-icons icon-app ",
            "Create Transactions": "tim-icons icon-bank ",
            "Authorizer": "tim-icons icon-check-2",
            "Pending Approval": "tim-icons icon-bell-55",
            "Recent Transactions": "tim-icons icon-badge",
            "Adjustments": "tim-icons icon-coins"
        },
        "Net Asset Value":{
            "Dashboard": "tim-icons icon-app ",
            "Daily Nav Update": "tim-icons icon-chart-bar-32",
            "Daily MM Securities": "tim-icons icon-key-25",
            "Daily Fund Portfolio": "tim-icons icon-chart-pie-36",
            "Fund Portfolio Report": "tim-icons icon-paper",
            "NAV Analysis Report": "tim-icons icon-notes",
            "Daily Market Summary" : "tim-icons icon-paper "
            
        },
        "Configurations": {
            "Banks": "tim-icons icon-bank",
            "Sales": "tim-icons icon-money-coins",
            "Tax Rates": "tim-icons  icon-coins",
            "Approving Checklists": "tim-icons icon-check-2",
            "Role Management": "tim-icons icon-pencil",
            "User Management": "tim-icons icon-single-02"
        }
    }

    const renderTransactionTypes = ()=>{
        switch (createTransactionModal) {
          case true:
            return (
              <Modal className="" dialogClassName="modal90w" show={true} size="xl"  onHide={() => {
                setCreateTransactionModal(false);
                setPATH("")
              }} >
                 <div className={context.theme} >
                <div className="modal-header ">
                  <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      setCreateTransactionModal(false);
                      setPATH("")
                    }}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                  <h6 className="title title-up">Create new transaction</h6>
                </div>
                  <Row className="mt-4">
                <Col md="12">
                <Container fluid>
                {txnTypeCatagory.length>0 ? (
                  PATH == "transactions-unit-management" ?
                  <Row>
                  
                     <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                            Unit Management
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[0].unitManagementTxn?.filter(item=>item.dis_name != "Conversion In" && item.dis_name != "Conversion Out").map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  key={index}
                                  className="box-element"
                                  onClick={() => {
                                    if (item.api_name === "unitconversion") {
                                      sessionStorage.setItem(
                                        "payment_type",
                                        "Payment"
                                      );
                                    } else {
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                    }
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/unit-management-transaction/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span className={item.api_name + " ico"}></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                    </Row>
                    :
                    PATH == "transactions-accounts"
                    ?
                    <Row>
                      
                        <Col>
                          <div className="card" style={{ minWidth: "105%" }}>
                            <div className="card-header">
                              <h4 className="card-title">
                                <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                                Accounting
                              </h4>
                            </div>
                            <div className="card-body">
                              {txnTypeCatagory[4].vouchersTxn.map(
                                (item: any, index: number) => {
                                  return (
                                    <MenuItem
                                      style={{
                                        minHeight: "0px", // mobile view
                                      }}
                                      className="box-element"
                                      key={index}
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "payment_type",
                                          `${item.payment_type}`
                                        );
                                        clearTransactiionHistory();
                                        history.push(
                                          `/admin/vouchers/${item.api_name}`
                                        );
                                        
                                      }}
                                    >
                                      <span className="text-hover text-silver">
                                        <span
                                          className={item.api_name + " ico"}
                                        ></span>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        {item.dis_name}
                                      </span>
                                    </MenuItem>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Col>
                     
                    </Row>
                    :
                    PATH == "transactions-trade-settlement" ?
                    <Row>
                        <Col>
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title">
                                {" "}
                                <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                                Investments
                              </h4>
                            </div>
                            <div className="card-body">
                              {txnTypeCatagory[2].investmentTxn.map(
                                (item: any, index: number) => {
                                  if (item.api_name == "right_share_subscription") {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                         
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/right-share-subscription/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  } else {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/investment-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
    
                    :
                    PATH == "transactions-funds-management" ?
                    <Row>
                      <Col>
                      <Row>
                      <Col>
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title">
                                {" "}
                                <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                                Fund Management
                              </h4>
                            </div>
                            <div className="card-body">
                              {txnTypeCatagory[1].fundManagementTxn.map(
                                (item: any, index: number) => {
                                  return (
                                    <MenuItem
                                      style={{
                                        minHeight: "0px", // mobile view
                                      }}
                                      className="box-element"
                                      key={index}
                                      onClick={() => {
                                      
                                        sessionStorage.setItem(
                                          "payment_type",
                                          `${item.payment_type}`
                                        );
                                        clearTransactiionHistory();
                                        history.push(
                                          `/admin/fund-management-transaction/${item.api_name}`
                                        );
                                      }}
                                    >
                                      <span className="text-hover text-silver">
                                        <span
                                          className={item.api_name + " ico"}
                                        ></span>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        {item.dis_name}
                                      </span>
                                    </MenuItem>
                                  );
                                }
                              )}
                              {/* <MenuItem
                            style={{
                              minHeight: "0px", // mobile view
                            }}
                            className="box-element"
                            key="4"
                            onClick={() => {
                              history.push(`/admin/fund-dividend-payout`);
                              // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              // clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={" ico"}></span>
                              <i className="fa fa-angle-right"></i>{" "}
                              {"Fund Dividend Payout"}
                            </span>
                          </MenuItem> */}
                            </div>
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                          <div className="card" >
                            <div className="card-header">
                              <h4 className="card-title">
                                <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                                Portfolio
                              </h4>
                            </div>
                            <div className="card-body">
                              {txnTypeCatagory[5].portfolio.map(
                                (item: any, index: number) => {
                                  return (
                                    <MenuItem
                                      style={{
                                        minHeight: "0px", // mobile view
                                      }}
                                      className="box-element"
                                      key={index}
                                      onClick={() => {
                                      
                                        sessionStorage.setItem(
                                          "payment_type",
                                          `${item.payment_type}`
                                        );
                                        clearTransactiionHistory();
                                        history.push(
                                          `/admin/portfolio/${item.api_name}`
                                        );
                                      }}
                                    >
                                      <span className="text-hover text-silver">
                                        <span
                                          className={item.api_name + " ico"}
                                        ></span>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        {item.dis_name}
                                      </span>
                                    </MenuItem>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                        </Col>
                        <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                            Fee Payments
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[3].feePaymentTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/fee-payment-transaction/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span className={item.api_name + " ico"}></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
    
                    </Row>
    
                    :

                    PATH == "transactions-transactions"
                    ?
                    <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                        Unit Management
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[0].unitManagementTxn?.filter(item=>item.dis_name != "Conversion In" && item.dis_name != "Conversion Out").map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              key={index}
                              className="box-element"
                              onClick={() => {
                                if (item.api_name === "unitconversion") {
                                  sessionStorage.setItem(
                                    "payment_type",
                                    "Payment"
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "payment_type",
                                    `${item.payment_type}`
                                  );
                                }
                                clearTransactiionHistory();
                                history.push(
                                  `/admin/unit-management-transaction/${item.api_name}`
                                );
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>

                <Col>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                            Investments
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[2].investmentTxn.map(
                            (item: any, index: number) => {
                              if (item.api_name == "right_share_subscription") {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    key={index}
                                    className="box-element"
                                    onClick={() => {
                                     
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/right-share-subscription/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              } else {
                                return (
                                  <MenuItem
                                    style={{
                                      minHeight: "0px", // mobile view
                                    }}
                                    key={index}
                                    className="box-element"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "payment_type",
                                        `${item.payment_type}`
                                      );
                                      clearTransactiionHistory();
                                      history.push(
                                        `/admin/investment-transaction/${item.api_name}`
                                      );
                                    }}
                                  >
                                    <span className="text-hover text-silver">
                                      <span
                                        className={item.api_name + " ico"}
                                      ></span>
                                      <i className="fa fa-angle-right"></i>{" "}
                                      {item.dis_name}
                                    </span>
                                  </MenuItem>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {" "}
                            <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                            Fund Management
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[1].fundManagementTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                  
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/fund-management-transaction/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                          {/* <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.push(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                        Fee Payments
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[3].feePaymentTxn.map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              className="box-element"
                              key={index}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "payment_type",
                                  `${item.payment_type}`
                                );
                                clearTransactiionHistory();
                                history.push(
                                  `/admin/fee-payment-transaction/${item.api_name}`
                                );
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>

                <Col>
                  <Row>
                    <Col>
                      <div className="card" style={{ minWidth: "105%" }}>
                        <div className="card-header">
                          <h4 className="card-title">
                            <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                            Accounting
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[4].vouchersTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/vouchers/${item.api_name}`
                                    );
                                    
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card" style={{ minWidth: "105%" }}>
                        <div className="card-header">
                          <h4 className="card-title">
                            <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                            Portfolio
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[5].portfolio.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                  
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                    history.push(
                                      `/admin/portfolio/${item.api_name}`
                                    );
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
    :
                    <Row></Row>
                    ):(
                      ''
                    )}
    
                </Container>
                </Col>
                </Row>
                </div>
                
              </Modal>
            );
          default:
            return null;
        }
    
       }

       const clearTransactiionHistory = () => {
        sessionStorage.removeItem("rejectedTxObj");
        sessionStorage.removeItem("rejectedTxName");
        sessionStorage.removeItem("rejectedFlag");
        sessionStorage.removeItem("rejectedAmc");
      };
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Container fluid>
                        <ToastContainer limit={1} />
                        {features.filter(item => item.name != "Home")?.map((item, index) => {
                            return (<Row key={index}>

                                <Col>
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">

                                                <i className={item.icon + " mr-2 text-primary"}></i>{" "}
                                                {item.name}
                                            </h4>
                                        </div>
                                        <div className="card-body row justify-content-start">
                                            {item?.views?.map((item2, index2) => {
                                                return (
                                                    <div key={index2}  className="col-lg-1 col-md-2 col-sm-4 col-6 mb-3">
                                                        <ListItemText 
                                                            onClick={()=>{
                                                                if(item2.name == "Create Transactions")
                                                                {
                                                                  sessionStorage.removeItem("Txn1");
                                                                  sessionStorage.removeItem("Txn2");
                                                                    setPATH(item2.path?.replace("/create-",""))
                                                                    setCreateTransactionModal(true)
                                                                }else{
                                                               
                                                            history.push(
                                                                `/admin${item2.path}`
                                                              );
                                                            }

                                                        }}
                                                     
                                                        primary={<i className={" text-primary mb-3 " + (IconsArray?.[item.name]?.[item2.name] || " ")} 
                                                        style={{ fontSize: '24px' }} />} 
                                                        secondary={item2.name}
                                                       
                                                        sx={{cursor:"pointer", textAlign:"center" }}
                                                         />
                                                    </div>)
                                            })}

                                        </div>
                                    </div>
                                </Col>
                            </Row>)
                        })
                        }

                    </Container>
                </Col>
            </Row>

            {renderTransactionTypes()}
        </div>
    )
}

export default HomePage