import React,{useState,useEffect} from "react";
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getPaginatedSalesCompany, updateStatusSalesCompany } from "../../../stores/services/sales.service";
import moment from "moment";
import { Modal } from 'react-bootstrap';

const SalesListing = () =>{
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [selectedCompany,setSelectedCompany] = useState<any>({})
  const [updateStatusLoading,setUpdateStatusLoading] = useState(false)

  useEffect(()=>{

    
    getSalesCompanyList();

  },[activePage])

  const getSalesCompanyList = async () => {
    setLoading(true);
    try {
      const response = await getPaginatedSalesCompany(email, amc_code,"","","","","",activePage,"10");
      if(response.data.data.length>0){
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 
        // &&
        // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    }else{
      setData([]);
      toast.error(response.data.message);
    }
    } catch (err:any) {
      // toast.error(error.response.data.message[0]);
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error("Request Failed!")
      }
    }
    setLoading(false);
  };


  useEffect(()=>{
    sessionStorage.removeItem("salesCompanyObj")
  },[])


  const renderData = () => {	
    return data.map((items: any, index: any) => {	
      return (	
        <tr key={index}>	
        <td>{items.company_name}</td>	
        <td>{items.company_code}</td>	
        <td>{items.symbol}</td>	
      <td>{items.incorporation_number}</td>
      <td>{items.sector_code}</td>
        <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>	
        <td>{items.status == true ? "Active" : "Deactive"}</td>	
           <td	
            className="d-flex pointer"	
            onClick={() => {	
             
            }}	
          >	
            {items.status == false ? (	 
              <button type="button" 
              className="btn-icon btn-link like btn btn-danger btn-sm"
              onClick={()=>{
                setStatusSetPopup(true);	
                setSelectedCompany({status:!items.status, company_code:items.company_code})	
              }}>
                <i className="tim-icons icon-simple-remove">
                  </i></button>
            ) : (	 
            
              <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
              onClick={()=>{
                setStatusSetPopup(true);	
                setSelectedCompany({status:!items.status, company_code:items.company_code})	
              }}>
                <i className="fa fa-check">
                  </i></button>
            )}	 
            <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"  
            onClick={() => {	
              sessionStorage.setItem('salesCompanyObj', JSON.stringify(items));	
                  history.replace('/admin/view-sales');	
                 
                }}	>
                <i className="fa fa-eye">
                  </i></button>
 

            {
              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" 
              onClick={() => {	
                sessionStorage.setItem('salesCompanyObj', JSON.stringify(items));	
                    history.replace('/admin/edit-sales');	
                   
                  }}
              >
                <i className="tim-icons icon-pencil">
               </i></button>
}
          </td>	
         	
       	
      </tr>	
      );	
    });	
    
  };	


  const renderStatusUpdateModal = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
             <div className="modal-header justify-content-center">	
                <button aria-hidden="true" className="close"  onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	 
                        data-dismiss="modal" type="button">	
                    <i className="tim-icons icon-simple-remove"></i>	
                </button>	
            <h6 className="title title-up">Action</h6>	
        </div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {selectedCompany?.status == true ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {selectedCompany?.status == true	
                      ? 'Deactivate'	
                      : 'Activate'}{' '}	
                    this Sale Company?	
                  </div>	
                </Row>	
                <Row className="mt-4">	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary"	
                        disabled={Boolean(updateStatusLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {updateStatusLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        disabled={Boolean(updateStatusLoading)}	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };
    

  const updateStatus = async () => {
    setUpdateStatusLoading(true);
    
    try {
      const response = await updateStatusSalesCompany(email, selectedCompany?.company_code, selectedCompany?.status);
      if(response.data.status == 200)
      {
        toast.success(response.data.message);
      setStatusSetPopup(false);
      setUpdateStatusLoading(false);
      getSalesCompanyList()
      }else{
        setUpdateStatusLoading(false);
        toast.error(response.data.message?.toString());	

      }
    } catch (error:any) {
      setUpdateStatusLoading(false);
      if(error.response !== undefined ) {
        toast.error(error.response.data.message?.toString());	
      }else {
        toast.error("Request Failed!")
      }

    }
   
  };

    return(
        <div className="content">
            <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />

        <div className="card">
        <div className="d-flex align-items-center"></div>
        <div>
        <div className="card-header">	
              <div className="card-title">
                <h4>Sales Listing
                  <button	
                  className="btn btn-primary btn-sm float-right"	
                  onClick={() => {	
                    history.replace('/admin/setup-sales');	
                  }}	
                >	
                  <i className="fa fa-plus mr-2"></i> Sales Company	
                </button></h4>

                </div>

              </div>	
              <div className="card-body">
                {Loading  ?
                <div className="d-flex justify-content-center my-5">	
                <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
             </div>
             :
             <div className="table-responsive">	
                <table className="table">	
                  <thead>	
                    <th>Company Name</th>	
                    <th>Company Code</th>	
                    <th>Symbol</th>
                    <th>Incorporation Number</th>	
                    <th>Sector Code</th>
                    <th>Created At</th>	
                    <th>Status</th>	
                    <th>Action</th>	
                  </thead>	
                  <tbody>{renderData()}</tbody>	
                </table>	
               
                <div	
                  className="row p-3 pointer"	
                  style={{	
                    float: 'right',	
                  }}	
                >	
                  {/* PREVIOUS BUTTON */}	
                  {activePage !== paginationArr[0] ? (	
                    <i	
                      className="fa fa-arrow-left"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index - 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                  {/* NEXT BUTTON */}	
                  {activePage !== paginationArr[paginationArr.length - 1] ? (	
                    <i	
                      className="fa ml-3 fa-arrow-right"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                </div>
                
              </div>	
                }
              </div>
        </div>
            
            </div>	

            {renderStatusUpdateModal()}
        </Container>
        </Col>
        </Row>
           
        </div>
    )

}

export default SalesListing