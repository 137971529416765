import React from 'react';
// import Header from './../components/Header';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container,Row, Col } from 'reactstrap';
import { getCoListing } from './../stores/services/transactions.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

const AuthorizerListing = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const obj = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  React.useEffect(() => {
    const getAuthList = async () => {
      setLoading(true);
      try {
       
        const response = await getCoListing(email, obj.amc_code);
        if(response.data.status == 200) {
        setData(response.data.data.signatories);
        setSearchValue('');
        setLoading(false);

        }else {
          setLoading(false);
          toast.error(response.data.message?.toString());
        }
      } catch (err:any) {
        setLoading(false);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message?.toString());
        }else {
          toast.error("Request Failed!")
        }
      }
      
    };
    getAuthList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }
  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{items.email}</td>
        </tr>
      );
    });
  };

  return (
    <>

<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <div className="card">
          <div className="card-header">
            {/* <Link to="/setup"><img src="assets/arrow-left.svg" alt="" width="24" /></Link> */}
            <h4 className="card-title">Authorizer Listing</h4>
          </div>
          {/* {!Loading ? ( */}
            <div className="card-body">
              <div className="input-holder">
                <div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />
                  <div className="form-group">
                  <input
                    type="search"
                    id="myInput"
                    className="form-control"
                    data-tip="Name"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      myFunction(e);
                    }}
                  /> 
                  </div>
                </div>
              </div>

              {!Loading ? (
                <div className='table-responsive'>
              <table className="table " id="myTable">
                <thead>
                  <tr>
                    <th className="w-50">Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody id="myUL">{renderData()}</tbody>
              </table>
              </div>
              ):(
                <div className="d-flex justify-content-center my-4">
                <i className="fa fa-spinner fa-spin fa-3x" ></i> 
                </div>
              )}
            </div>
           {/* ) : (
             <div className="d-flex justify-content-center my-4">
              <i className="fa fa-spinner fa-spin fa-3x" ></i> 
             </div>
           )} */}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    
    </>
  );
};

export default AuthorizerListing;
