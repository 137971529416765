import React, { useState, useEffect, useContext } from "react";
import { getAllBanks } from "../../../stores/services/bank.service";
import { getBranchByBankName } from "../../../stores/services/branch.service";
import { EditAccount } from "../../../stores/services/account.service";
import { getFundByAmc } from "../../../stores/services/funds.service";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import NumberFormat from "react-number-format";
import { replaceChartOfAccountsFile } from "../../../stores/services/chatofaccounts.service";
import { GetChartOfAccount } from "../../../redux/actions/chartOfAccount-Actions";
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { getAllLedgers, numberWithCommas } from "../../../utils/customFunction";

const ViewAccountComponent = () => {
  const history = useHistory();
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const obj = JSON.parse(sessionStorage.getItem("accountObj") || "{}");
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  //hooks for getting all inputs from user
  const [bankname, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [amcName, setAmcName] = useState("");
  const [city, setCity] = useState("");
  const [fund, setFund] = useState("");
  const [currency, setCurrency] = useState("PKR");
  const [txNature, setTxNature] = useState("");
  const [iban, setIban] = useState("");
  const [opHeadEmail, setHeadEmail] = useState("");
  const [accType, setAccType] = useState("");
  const [accPurpose, setAccPurpose] = useState("");
  const [amount, setAmount] = useState("");
  const email = sessionStorage.getItem("email") || "";
  const [smaCheck, setSmaCheck] = useState<boolean>(false);
  const [acc_code, setAccCode] = useState("");
  useEffect(() => {
    setBranchHeadEmail(obj.bm_email);
    setFolioNo(obj.folio_no);
    setAccCode(obj.account_code);
    setBankName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    if (obj.sma === "true") {
      setSmaCheck(true);
    } else {
      setSmaCheck(false);
    }
    // setSmaCheck(true);
    setFund(obj.fund_code);
    setCurrency(obj.currency);
    setBranchHeadMobile(obj.bm_phone);
    setTxNature(obj.nature_of_account);
    setIban(obj.account_no);
    setHeadPhone(obj.operation_head_phone);
    setAccType(obj.account_title);
    setAccPurpose(obj.product_purpose);
    setHeadEmail(obj.operation_head_email);
    setAmount(obj.balance_amount);
    setCity(obj.bank_city);
  }, []);

  const [headPhone, setHeadPhone] = useState("");
  const [branchHeadEmail, setBranchHeadEmail] = useState("");
  const [branchHeadMobile, setBranchHeadMobile] = useState("");
  const [folioNo, setFolioNo] = useState("");

  const [currentBalance, setCurrentBalance] = useState(
    obj?.balance_amount?.toFixed(2) || "0"
  );
  const [currentBalanceError, setCurrentBalanceError] =
    useState<boolean>(false);

  const [interestRate, setInterestRate] = useState<any>("");
  const [debitBankProfitLedger, setDebitBankProfitLedger] = useState<any>();
  const [creditBankProfitLedger, setCreditBankProfitLedger] = useState<any>();
  const [associatedLedgerExpense, setAssociatedLedgerExpense] = useState<any>();
  const [interestRateError, setInterestRateError] = useState<boolean>(false);
  const [debitBankProfitLedgerError, setDebitBankProfitLedgerError] =
    useState<boolean>(false);
  const [creditBankProfitLedgerError, setCreditBankProfitLedgerError] =
    useState<boolean>(false);
  const [associatedLedgerExpenseError, setAssociatedLedgerExpenseError] =
    useState<boolean>(false);

  const [ledgerOption, setLedgerOption] = useState<any>([]);
  const [allLedgerData, setAllLedgerData] = useState<any>([]);

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [ledgersOptionFlag, setLedgersOptionFlag] = useState(false);

  useEffect(() => {
    if (chartOfAccounts?.length > 0) {
      setLedgersData();
    }
  }, [chartOfAccounts]);

  const setLedgersData = async () => {
    let temp = await getAllLedgers(chartOfAccounts);
    setAllLedgerData([...temp]);
  };

  useEffect(() => {
    setLedgersOptionFlag(false);
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
              value: item.code,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
              value: item.code,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
            value: item.code,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
            value: item.code,
          },
        ]);
      }
    });

    allLedgerData.length > 0 && setLedgersOptionFlag(true);
  }, [allLedgerData]);

  useEffect(() => {
    if (obj.gl_code && ledgersOptionFlag == true) {
      let findAccountsDetails = chartOfAccounts[0].family[3].sub_family
        ?.find(
          (item) =>
            item.name ==
            (obj.nature_of_account == "Current"
              ? "CURRENT ACCOUNTS"
              : "PLS ACCOUNTS")
        )
        ?.gl.find((item) => item.code == obj.gl_code);
      setInterestRate(findAccountsDetails?.interest_rate || "");
      // setCurrentBalance(findAccountsDetails?.balance || "0");

      if (
        ledgerOption.find(
          (item) => item.value == findAccountsDetails.related_ledgers[0]
        )
      ) {
        setDebitBankProfitLedger(
          ledgerOption.find(
            (item) => item.value == findAccountsDetails.related_ledgers[0]
          )
        );
      }
      if (
        ledgerOption.find(
          (item) => item.value == findAccountsDetails.related_ledgers[1]
        )
      ) {
        setCreditBankProfitLedger(
          ledgerOption.find(
            (item) => item.value == findAccountsDetails.related_ledgers[1]
          )
        );
      }
    }
  }, [ledgersOptionFlag]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      sessionStorage.removeItem("accountObj");
                      history.replace("/admin/bank-accounts");
                    }}
                  ></i>
                  View - Bank Account
                </h4>
                <Link
                  to="/admin/bank-accounts"
                  className="btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fund Detail</h4>
                    </div>
                    <div className="card-body">
                      {/* <div className="form-group  ">
                    <label>AMC Name</label>
                    <div className="  disable">
                      <input
                        type="text"
                        className="form-control "
                        style={{ opacity: '0.6' }}
                        value={amcName}
                        readOnly
                      />
                    </div>
                  </div> */}

                      <div className="form-group  ">
                        <label>
                          Fund Selection <span className="color-amber">*</span>
                        </label>
                        <div>
                          <input
                            className={`form-control  w-100 `}
                            value={fund}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Bank Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>Bank Name</label>
                        <div>
                          <input
                            className={`form-control  w-100 `}
                            value={bankname}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group  ">
                            <label>Branch Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={branchName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              City <span className="color-amber">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={city}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Special Managed Account</h4>
                    </div>
                    <div className="card-body">
                      <FormGroup check className="mt-4">
                        <Label check>
                          <Input type="checkbox" checked={smaCheck} />
                          <span className="form-check-sign" />
                          SMA (Optional)
                        </Label>
                      </FormGroup>

                      {smaCheck && (
                        <div className="form-group mt-4">
                          <label>Main Folio No</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={folioNo}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Contact Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>Operation Manager Email</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={opHeadEmail}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Operation Manager Phone</label>
                        <div>
                          <PhoneInput
                            specialLabel=""
                            inputClass={`form-control  w-100 `}
                            country={"pk"}
                            placeholder="+92 123-1234567"
                            value={headPhone}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Branch Manager Phone</label>
                        <div>
                          <PhoneInput
                            specialLabel=""
                            inputClass={`form-control  w-100`}
                            country={"pk"}
                            placeholder="+92 123-1234567"
                            value={branchHeadMobile}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Branch Manager Email</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={branchHeadEmail}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Account Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>Bank Account Title</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={accType}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>IBAN / Account No</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={iban}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Nature of Account</label>
                        <div>
                          <input
                            className={`form-control  w-100 `}
                            value={txNature}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Currency</label>
                        <div>
                          <input
                            className={`form-control  w-100 `}
                            value={currency}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Current Balance</label>
                        <div className="  disable-input">
                          <input
                            type="number"
                            value={numberWithCommas(currentBalance)}
                            className={`form-control w-100 `}
                            style={{ textAlign: "right" }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Interest Rate % </label>
                        <input
                          type="number"
                          value={interestRate}
                          className={`form-control w-100 `}
                          style={{ textAlign: "right" }}
                        />
                      </div>

                      <div
                        className="form-group"
                        data-tip="Ledger Codes to Debit/Credit in case of bank profit"
                      >
                        <div>Associated Ledger Codes</div>

                        <Autocomplete
                          className={`mt-2 autocomplete`}
                          readOnly
                          data-tip="Ledger Code to associate in case of Expense"
                          disablePortal
                          id="combo-box-demo"
                          options={ledgerOption}
                          style={{ width: "100%" }}
                          PaperComponent={({ children }) => (
                            <Paper
                              style={{
                                background: context.theme ? "" : "#1E1E2F",
                                color: "white",
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                          sx={{
                            "& .MuiAutocomplete-input": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },

                            "& .MuiIconButton-root": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },
                          }}
                          value={debitBankProfitLedger?.label || ""}
                          onChange={(event: any, newValue: string | null) => {
                            setDebitBankProfitLedger(newValue);
                            setDebitBankProfitLedgerError(false);
                          }}
                          inputValue={
                            debitBankProfitLedger?.label ||
                            debitBankProfitLedger ||
                            ""
                          }
                          onInputChange={(event, newInputValue, reason) => {
                            if (reason == "input") {
                              setDebitBankProfitLedgerError(false);
                              setDebitBankProfitLedger(newInputValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Ledger for debit"
                              variant="standard"
                            />
                          )}
                        />

                        <Autocomplete
                          className={`mt-2 autocomplete`}
                          readOnly
                          data-tip="Ledger Code to associate in case of Expense"
                          disablePortal
                          id="combo-box-demo"
                          options={ledgerOption}
                          style={{ width: "100%" }}
                          PaperComponent={({ children }) => (
                            <Paper
                              style={{
                                background: context.theme ? "" : "#1E1E2F",
                                color: "white",
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                          sx={{
                            "& .MuiAutocomplete-input": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },

                            "& .MuiIconButton-root": {
                              color: context.theme
                                ? "black !important"
                                : "rgba(255, 255, 255, 0.5) !important",
                            },
                          }}
                          value={creditBankProfitLedger?.label || ""}
                          onChange={(event: any, newValue: string | null) => {
                            setCreditBankProfitLedger(newValue);
                            setCreditBankProfitLedgerError(false);
                          }}
                          inputValue={
                            creditBankProfitLedger?.label ||
                            creditBankProfitLedger ||
                            ""
                          }
                          onInputChange={(event, newInputValue, reason) => {
                            if (reason == "input") {
                              setCreditBankProfitLedgerError(false);
                              setCreditBankProfitLedger(newInputValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Ledger for credit"
                              variant="standard"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex ">
                <div className=" ">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      sessionStorage.removeItem("accountObj");
                      history.replace("/admin/bank-accounts");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewAccountComponent;
