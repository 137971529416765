

import React from 'react';
import { useState } from 'react';
import { searchAPI } from './../stores/services/amc.service';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  getFundByAmc,
  getInstrumentType,
} from './../stores/services/funds.service';
import {
  getModeOfPayments,
} from './../stores/services/transactions.service';
import {
  getAccountByFund,
} from './../stores/services/account.service';
import { getAllBanks } from './../stores/services/bank.service';
import { getBranchByBankName } from './../stores/services/branch.service';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from './field';
import {
  addMegaTransaction,
  EditTransaction,
} from './../stores/services/beta-transaction.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllPsx,
  getAllSecurities,
} from './../stores/services/security.service';
import {
  inWords
} from './../stores/services/template.service';
import { IoIosClose } from 'react-icons/io';
import { getBrokerByType } from './../stores/services/broker.service';
import { getTaxAmount, getTaxType } from './../stores/services/tax.service';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  numberWithCommas, accountTitle_AccountNumber_Regex, getLedgerAccounts,
  getLedgersByTxn, getAllLedgers, getLedgersByTxnAndPaymentype
} from './../utils/customFunction';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import './../css/autocomplete.css';
import xls from "xlsx";
import { ImTwitter } from 'react-icons/im';

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type: string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized: string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst: string;
  tax_wht_it: string;
  tax_wht_sst: string;
  tax_cgt: string;
  sale_load: string;
  redemption_load: string;
  low_balance_agree: string;
  offer_price: string;
  load_per_unit: string;
  percentage_of_discount: string;
  applicable_offer_price: string;
  sales_tax_collector: string;
  volume: string;
  par_value: string;
  announcement_date: string;
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend: string;
  tax_on_bonus: string;
  tax_on_right: string;
  zakat_amount: string;
  ledgers: string;
  average_cost_amount: string;
  gain_loss_amount: string;
  total_face_value: string;
  accrued_amount: string;
  premium_discount: string;
  brokerage: string;
  principal_amount: string;
}

const RightShareSubscription = (props: any) => {
  const history = useHistory();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [headLabel, setHeadLabel] = React.useState('');
  const tx = sessionStorage.getItem('rejectedTxName') || '';
  const amcEdit = sessionStorage.getItem('rejectedAmc') || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState('');
  const [accLoading, setAccLoading] = useState(false);
  const paymentType = sessionStorage.getItem('payment_type') || '';

  const [psxList, setPsxList] = useState<any>([]);
  const [stockMarketDataFund, setStockMarketDataFund] = useState<any>([]); // PSX data of fund
  const [moneyMarketDataFund, setMoneyMarketDataFund] = useState<any>([]); // money market data of fund
  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const amcName = sessionStorage.getItem('amc_name') || '';
  // type
  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response
  const [primaryDealerData, setPrimaryDealerData] = useState<any>([]); // Money Maket Securities Hook - primaryDealerData

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data
  const [reinest, setReinvest] = useState<boolean>(false);

  const [fundCDCParticipantID, setCDCParticipantID] = useState('');
  const [pledged_holding, setPledgedHolding] = useState('');
  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);


  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === 'Fund') {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code : "");
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) { return 1; }
        if (x < y) { return -1; }
        return 0;
      });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if (val.nature === 'Special Managed Account') {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
            value: val.symbol_code,
          };
        } else {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code})`,
            value: val.symbol_code,
          };
        }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) { }

  };


  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item: any) => {
      if (item.symbol_code === fund_code) {
        fields['nav'] = item.nav;
        fields['face_value'] = item.face_value;

        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== '' &&
          item.stock_market !== '[]' &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code, amc_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) { }
    setAccLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem('rejectedFlag') || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields['txnr_type'] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === 'Other') {
      fields['counter_account_type'] = 'Other';
      setFields(fields);
    }
    if (props.match.params.type === 'debtmarketinvestment' || props.match.params.type === 'equityinvestment') {
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates = moment(date).format('YYYY-MM-DD');
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: '',
    amc_code: loggedInUser.amc_code,
    dividend_date: '',
    dividend_rate: '',
    fund_code: '',
    reinvest: 'false',
    instruction_date: moment(date).format('YYYY-MM-DD'),
    execution_date: moment(date).format('YYYY-MM-DD'),
    executed_date: moment(date).format('YYYY-MM-DD'),
    fund_account: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: '',
    counter_account_type: '',
    counter_type: '',
    counter_account_title: '',
    counter_account_number: '',
    counter_bank: '',
    counter_branch: '',
    mode_of_payment: '',
    payment_type: '',
    instrument_no: '',
    instrument_type: '',
    instrument_date: moment(date).format('YYYY-MM-DD'),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format('YYYY-MM-DD'),
    realized: 'true',
    gross_amount: '',
    net_amount: '',
    dr_amount: '',
    period: '',
    cr_amount: '',
    balance: '',
    folio_no: '',
    unitholder_name: '',
    units: '',
    nav: '',
    sale_date: moment(date).format('YYYY-MM-DD'),
    current_holding: '0',
    total_holding: '',
    symbol: '',
    dividend_percentage: '',
    credit_date: '',
    maturity_type: '',
    security_type: '',
    issue_date: moment(date).format('YYYY-MM-DD'),
    conversion_date: moment(date).format('YYYY-MM-DD'),
    associated_transaction: '',
    maturity_date: moment(date).format('YYYY-MM-DD'),
    coupon_rate: '',
    price: '',
    face_value: '',
    money_market_face_value: '',
    detail: '',
    type: '',
    redemption_date: moment(date).format('YYYY-MM-DD'),
    remain_holding: '0',
    settlement_by: '',
    tax_type: '',
    settlement_date: moment(date).format('YYYY-MM-DD'),
    total_charges: '0',
    txn_charges: '',
    sale_txn_no: '',
    return_date: '',
    txn_id: '',
    last_coupon_date: moment(date).format('YYYY-MM-DD'),
    next_coupon_date: moment(date).format('YYYY-MM-DD'),
    yield: '',
    tenor: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    system_tax: '',
    primary_dealer: '',
    broker_commission: '',
    tax_sst: '',
    tax_wht_it: '',
    tax_wht_sst: '',
    tax_cgt: '0',
    sale_load: '',
    redemption_load: '0',
    low_balance_agree: '',
    offer_price: '',
    load_per_unit: '',
    percentage_of_discount: '0',
    applicable_offer_price: '',
    sales_tax_collector: 'SRB', // default value SRB
    volume: '',
    par_value: '',
    announcement_date: '',     // Dividend on Investment
    bonus_percentage: '',
    bonus_credit_date: '',
    bonus_gross_volume: '',
    tax_it_bonus_amount: '',
    net_bonus_volume: '',
    right_share_percentage: '',
    right_share_credit_date: '',
    right_share_gross_volume: '',
    tax_it_right_share_amount: '',
    net_right_share_volume: '',
    tax_on_dvidend: '',
    tax_on_bonus: '',
    tax_on_right: '',
    zakat_amount: '0',
    ledgers: '',
    average_cost_amount: '0',
    gain_loss_amount: '0',
    total_face_value: '0',
    accrued_amount: '0',
    premium_discount: '0',
    brokerage: '0',
    principal_amount: '0',
  });

  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [ledgeroptions, setLedgerOptions] = useState<any>([]);

  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [ledgerAccountCode, setLegderAccountCode] = useState('');
  const [ledgerAccountName, setLegderAccountName] = useState('');
  const [allTransactionLedgers, setAllTransactionLedgers] = useState<any>([]);

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{
    amc_code: amc_code,
    fund_code: fields['fund_code'],
    account_number: '',
    account_title: '',
    bank_code: '',
    branch: '',
    child_family_code: '',
    child_family_name: '',
    counter_account_number: '',
    counter_account_title: '',
    counter_bank: '',
    counter_branch: '',
    credit_amount: '',
    debit_amount: '',
    description: '',
    entered_by: '',
    evidence: '',
    family_code: '',
    family_name: '',
    gl_code: '',
    gl_name: '',
    instruction_date: '',
    instrument_by: '',
    instrument_date: '',
    instrument_no: '',
    instrument_type: '',
    main_family_code: '',
    main_family_name: '',
    realized: '',
    realized_by: '',
    realized_date: '',
    sub_family_code: '',
    sub_family_name: '',
  }]);

  const [totalDebit, setTotalDebit] = useState('0');
  const [totalCredit, setTotalCredit] = useState('0');
  const [balance, setBalance] = useState('0');

  const [selectedLedgerCode, setSelectedLedgerCode] = useState('');
  const [selectedInputLedgerCode, setSelectedInputLedgerCode] = useState('');
  const [selectedLedgerName, setSelectedLedgerName] = useState('');
  const [ledgerType, setLedgerType] = useState('');
  const [ledgerAmount, setLedgerAmount] = useState('');
  const [ledgerDescription, setLedgerDescription] = useState('');
  const [ledgerInvoice, setLedgerInvoice] = useState('');
  const [ledgerAmountType, setLedgerAmountType] = useState('');

  //code for edit transaction
  let [count, setCount] = useState(0);
  const [PIB_fields, setPIBFields] = useState(false);
  const [securityCheck, setSecurityCheck] = useState('');

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem('rejectedTxObj') || ' ');
      getFundByAMcCode(obj.amc_code);
      fields['payment_type'] = obj.payment_type || '';
      fields['txnr_type'] = obj.txnr_type || '';
      fields['amc_code'] = obj.amc_code || '';
      getAccountByFundName(obj.fund_code);
      fields['fund_code'] = obj.fund_code || '';
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields['instruction_date'] = obj.instruction_date || '';
      fields['execution_date'] = obj.execution_date || '';
      fields['executed_date'] = obj.executed_date || '';
      fields['fund_account'] = obj.fund_account || '';
      fields['dividend_date'] =
        obj.dividend_date || '';
      fields['dividend_rate'] = obj.dividend_rate || '';
      fields['reinvest'] = obj.reinvest || '';
      fields['period'] = obj.period || '';
      fields['account_title'] = obj.account_title || '';
      fields['account_no'] = obj.account_number || '';
      fields['bank'] = obj.bank || '';
      fields['branch'] = obj.branch || '';
      fields['counter_account_type'] = obj.counter_account_type || '';
      fields['counter_type'] = obj.counter_type || '';
      fields['counter_account_title'] = obj.counter_account_title || '';
      fields['counter_account_number'] = obj.counter_account_number || '';
      fields['counter_bank'] = obj.counter_bank || '';
      fields['counter_branch'] = obj.counter_branch || '';
      fields['mode_of_payment'] = obj.mode_of_payment || '';
      fields['instrument_no'] = obj.instrument_no || '';
      fields['instrument_type'] = obj.instrument_type || '';
      fields['instrument_date'] = obj.instrument_date || '';
      fields['realized_date'] = obj.realized_date || '';
      fields['realized'] = obj.realized || '';
      fields['gross_amount'] = obj.gross_amount.toString() || '';
      fields['net_amount'] = obj.net_amount.toString() || '';
      fields['dr_amount'] = '0';
      fields['cr_amount'] = '0';
      fields['balance'] = obj.balance.toString() || '';
      fields['folio_no'] = obj.folio_no || '';
      fields['unitholder_name'] = obj.unitholder_name || '';
      fields['units'] = obj.units.toString() || '';
      fields['nav'] = obj.nav.toString() || '';
      fields['sale_date'] = obj.sale_date || '';
      fields['current_holding'] = obj.current_holding.toString() || '';
      fields['total_holding'] = obj.total_holding.toString() || '';
      fields['symbol'] = obj.symbol || '';
      fields['dividend_percentage'] = obj.dividend_percentage.toString() || '';
      fields['credit_date'] = obj.credit_date || '';
      fields['maturity_type'] = obj.maturity_type || '';
      fields['security_type'] = obj.security_type || '';
      fields['issue_date'] = obj.issue_date || '';
      fields['conversion_date'] = obj.conversion_date || '';
      fields['associated_transaction'] = obj.associated_transaction || '';
      fields['maturity_date'] = obj.maturity_date || '';
      fields['coupon_rate'] = obj.coupon_rate || '';
      fields['price'] = obj.price.toString() || '';
      fields['face_value'] = obj.face_value.toString() || '';
      fields['money_market_face_value'] = obj.money_market_face_value.toString() || '';
      fields['detail'] = obj.detail || '';
      fields['type'] = obj.type || '';
      if (obj.reinvest === 'true') {
        setReinvest(true);
      } else {
        setReinvest(false);
      }
      fields['redemption_date'] = obj.redemption_date || '';
      fields['remain_holding'] = obj.remain_holding.toString() || '';
      fields['settlement_by'] = obj.settlement_by || '';
      fields['tax_type'] = obj.tax_type || '';
      fields['settlement_date'] = obj.settlement_date || '';
      fields['total_charges'] = obj.total_charges.toString() || '';
      fields['sale_txn_no'] = obj.sale_txn_no || '';
      fields['return_date'] = obj.return_date || '';
      fields['trx_id'] = obj.txn_id || '';
      fields['primary_dealer'] = obj.primary_dealer;
      fields['yield'] = obj.yield || '';
      if (headLabel === 'advisoryfee' || headLabel === 'auditorsfee' || headLabel === 'managementfee' || headLabel === 'trusteefee' || headLabel === 'custodyfee'
        || headLabel === 'shariahadvisoryfee' || headLabel === 'cdcfee' || headLabel === 'listingfee' || headLabel === "listingfee" || headLabel === "brokagefee" ||
        headLabel === 'printingfee' || headLabel === 'professionalfee' || headLabel === 'ratingfee' || headLabel === 'taxpayment' || headLabel === 'secpfee' || headLabel === 'otherpayment') {
        fields['tax_sst'] = obj.tax_sst || '';
        fields['tax_wht_it'] = obj.tax_sst || '';
        fields['tax_wht_sst'] = obj.tax_sst || '';
        fields['sales_tax_collector'] = obj.sales_tax_collector || '';
      }

      if (headLabel === '' || headLabel === '') {
        fields['sale_load'] = obj.sale_load || '';
      }

      if (headLabel === 'equityinvestment') {
        fields['broker_commission'] = obj.broker_commission || '';
        fields['tax_sst'] = obj.tax_sst || '';
        fields['sales_tax_collector'] = obj.sales_tax_collector || '';
      }

      if (headLabel === '' || headLabel === '') {
        fields['redemption_load'] = obj.redemption_load || '';
        fields['tax_cgt'] = obj.tax_cgt || '';
      }
      if (headLabel === 'moneymarketpurchase' || headLabel === 'saleofsecurities') {
        if (obj.security_type === 'PIB') {
          setPIBFields(true);
          fields['last_coupon_date'] = obj.last_coupon_date || '';
          fields['next_coupon_date'] = obj.next_coupon_date || '';
          fields['tenor'] = obj.tenor || '';
        }
      }
      fields['zakat_amount'] = obj.zakat_amount;
      fields['txn_status'] = 'CREATED';

      setSecurites(obj.securities);
      // Dividend On Investment
      fields['volume'] = obj.volume;
      fields['par_value'] = obj.par_value;
      fields['announcement_date '] = obj.announcement_date;
      fields['bonus_percentage'] = obj.bonus_percentage;
      fields['bonus_credit_date'] = obj.bonus_credit_date;
      fields['bonus_gross_volume'] = obj.bonus_gross_volume;
      fields['tax_it_bonus_amount'] = obj.tax_it_bonus_amount;
      fields['net_bonus_volume'] = obj.net_bonus_volume;
      fields['right_share_percentage'] = obj.right_share_percentage;
      fields['right_share_credit_date'] = obj.right_share_credit_date;
      fields['right_share_gross_volume'] = obj.right_share_gross_volume;
      fields['tax_it_right_share_amount'] = obj.tax_it_right_share_amount;
      fields['net_right_share_volume'] = obj.net_right_share_volume;
      fields['tax_on_dvidend'] = obj.tax_on_dvidend;
      fields['tax_on_bonus'] = obj.tax_on_bonus;
      fields['tax_on_right'] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields['fund_ips_account'] = obj.fund_ips_account || '';
      fields['counter_party_ips_account'] = obj.counter_party_ips_account || '';
      fields['low_balance_agree'] = ''
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  React.useEffect(() => {
    if (!flag) {
      fields['payment_type'] = paymentType;
      fields['email'] = email;
      fields['amc_code'] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);

      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {

      }

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: 'Other',
          label: 'Other',
        });
        setAmcdataCounter(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }

      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email, "", "", amc_code);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace('BANK_', '')} (${val.bank_name})`,
            value: val.bank_code.replace('BANK_', ''),
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(temp);
      } catch (error) { }
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) { }
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) { }

      if (props.match.params.type === 'equityinvestment') {
        //get Broker data
        try {
          const response = await getAllPsx(email, amc_code);
          let sortedData = response.data.data.sort(function (a, b) {
            let x = a.company_name.toLowerCase();
            let y = b.company_name.toLowerCase();
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
          });
          // setPsxList(response.data.data);
          setPsxList(sortedData);
        } catch (error) { }
      }

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter, amc_code);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) { }


      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === 'Special Managed Account') {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {'SMA'}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const ledgerDropDown = (allLedgerData) => {
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }

  const renderFundAccounts = () => {
    return (
      ledgerAccounts && ledgerAccounts.map((item: any, index: number) => {
        //   if(item.plsName==='PLS ACCOUNTS'){
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }} label="PLS ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );
        // }else{
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }}  label="CURRENT ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );

        // }
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };


  const getFundMoneyMarketPortfolioData = (fund_code, security_type) => {
    const s = allFunds.filter((item: any, index: number) => {
      return (fund_code === item.symbol_code)
    });
    let data = JSON.parse(s[0].money_market);
    let securitiesData = data.filter((item: any, index: number) => {
      return security_type === item.security_type;
    });
    setMoneyMarketDataFund(securitiesData);
  };

  const getFundStockMarketData = (code) => {
    const s = allFunds.filter((item: any, index: number) => {
      return (code === item.symbol_code)
    });
    setStockMarketDataFund(JSON.parse(s[0].stock_market))
  };


  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for primary dealer data
  const renderPrimaryDealerDropdown = () => {
    return primaryDealerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.company_name}>
          {/* {item.name} */}
          {`${item.company_name} (${item.company_code}) `}
        </option>
      );
    });
  };

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.company_name} ({item.symbol})
        </option>
      );
    });
  };

  //render dropdown for money market securities data of fund
  const renderMoneyMarketSecurityData = () => {
    return moneyMarketDataFund.map((item: any, index: string) => {
      return (
        <option
          key={index}
          value={item.code}>{item.name}</option>
      );
    });
  };

  //render dropdown for psx data of fund
  const renderStockMarketData = () => {
    return stockMarketDataFund.map((item: any, index: string) => {
      return (
        <option
          key={index}
          value={item.code}
        >{item.code}</option>
      );
    });
  };

  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_', '')}>
          {item.name.replace('SECURITY_', '')}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);
  // filter securities data based on security_type selection
  const renderFilterSecurityDataDropdown = (security_type: string) => {
    const check = securityTypeData.filter((item: any, index: string) => {
      return (item.security_type === security_type);
    });
    setSecurityData(check);
  };

  const renderSecuritiesDropDown = () => {
    return securityData.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_', '')}>
          {item.name}
        </option>
      );
    });
  }

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
  };


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
  };

  const [inputType, setInputType] = useState(false);

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState('');


  //Create Transaction Api Call
  const createTransaction = async () => {
    if (!chechFieldDisplay(headLabel, 'nav')) {
      fields['nav'] = '';
    }
    if (headLabel === 'unitconversion') {
      let conversionAmount = +fields['net_amount'] / +unitNav;
      fields['total_holding'] = conversionAmount.toFixed(2);
    } else {
      fields['counter_type'] = counterType !== '' ? counterType : counterAccounter;
    }
    if (headLabel === 'debtmarketinvestment') {
      fields['securities'] = JSON.stringify(moneyMarketSecurities);
    }

    // else if(headLabel==='termdeposit'){
    //   termDeposit.push(termDepositEntity);
    //   setTermDeposit(termDeposit);
    //   fields['securities'] = JSON.stringify(termDeposit);
    // }
    else {
      fields['securities'] = JSON.stringify(securites);
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (
        headLabel === 'fundtransfer' &&
        fields['counter_account_type'] === fields['fund_account']
      ) {
        toast.error('Both account should be not same');
      } else {
        setLoading(true);
        if (flag) {
          if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0) {
            toast.error("Amounts are negative");
          }
          else {
            if (headLabel === 'pledgedin' || headLabel === 'pledgedout') {
              fields['fund_account'] = 'None';
              fields['account_title'] = 'None';
              fields['account_no'] = 'None';
              fields['bank'] = 'None';
              fields['branch'] = 'None';
              fields['mode_of_payment'] = 'None';
              fields['gross_amount'] = '0';
              fields['net_amount'] = '0';
              setFields(fields);
            }
            try {
              const response = await EditTransaction(fields); // for Edit existed transaction
              if (response.data.status === 400) {
                setViewBankBalanceModal(true);
                toast.error(response.data.message);
              } else if (response.data.status === 417) {
                toast.warning(response.data.message);
              } else {
                setUnderStand(false);
                setViewBankBalanceModal(false);
                toast.success(response.data.message);
                setTimeout(() => {
                  history.replace(`/admin/create-transaction`);
                }, 3000);
              }
            } catch (error: any) {
              setLoading(false);
              if (error.response !== undefined) {
                toast.error(error.response.data.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }
        } else {
          if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0) {
            toast.error("Amounts are negative");
          }
          else {
            if (headLabel === 'pledgedin' || headLabel === 'pledgedout') {
              fields['fund_account'] = 'None';
              fields['account_title'] = 'None';
              fields['account_no'] = 'None';
              fields['bank'] = 'None';
              fields['branch'] = 'None';
              fields['mode_of_payment'] = 'None';
              fields['gross_amount'] = '0';
              fields['net_amount'] = '0';
              setFields(fields);
            }

            try {
              const response = await addMegaTransaction(fields); // for add new transaction
              if (response.data.status === 400) {
                setViewBankBalanceModal(true);
                toast.warning(response.data.message);
              } else if (response.data.status === 417) {
                toast.warning(response.data.message);
              } else {
                setUnderStand(false);
                setViewBankBalanceModal(false);
                sessionStorage.setItem('last_id', response.data.txn_id);
                toast.success(`${response.data.message} - ${response.data.txn_id}`)
                // toast.success(response.data.message);
                setTimeout(() => {
                  history.replace(`/admin/create-transaction`);
                }, 3000);
              }
            } catch (error: any) {
              setLoading(false);
              if (error.response !== undefined) {
                toast.error(error.response.data.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  };

  const [accTilteName, setAccTiltleName] = useState('');
  const [bankBalance, setBankBalance] = useState('');

  const clearFundAccountFields = () => {
    setAccTiltleName('Unset');
    fields['fund_account'] = '';
    fields['account_title'] = '';
    fields['account_no'] = '';
    fields['bank'] = '';
    fields['branch'] = '';
    fields['nav'] = '';
    setFields(fields);
  };


  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields['current_holding'] + 'change');
    fields['counter_account_type'] = '';
    fields['counter_account_title'] = '';
    fields['counter_account_number'] = '';
    fields['counter_bank'] = '';
    fields['counter_branch'] = '';
    fields['current_holding'] = '';
    fields['folio_no'] = '';
    setFields(fields);
  };

  const [moneyMarketSecurities, setMoneyMarketSecurities] = useState<any>([]);


  const [securites, setSecurites] = useState<any>([]);


  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === '') || (value.substring(0, 1) === '-')) {
      return 'required-border';
    } else {
      return '';
    }
  };
  //

  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false)
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input type="number"
                      value={bankBalance || '0'}
                      readOnly
                      className="form-control w-100" />

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields['detail']}
                      className={
                        'form-control w-100 '
                      }
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand)
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: 'Yes',
                          });
                          createTransaction();

                        }}>
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button className="btn btn-info btn-block" onClick={() => {
                        setUnderStand(false);
                        setViewBankBalanceModal(false);
                      }}>
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              <div className="">
                <div className="input-holder">
                  <div className="row">
                    <h4 className=" card-title">
                      <i className="fa fa-angle-left mr-2 ml-3 mt-1 pointer" onClick={() => {
                        history.replace('/admin/create-transaction')
                      }}></i>
                      {CapTxnType(headLabel)} Transaction</h4>
                  </div>

                  <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                    <i className="fa fa-upload mr-2"></i> Bulk Upload
                  </Link>

                </div>
                <div className=" ">

                  <Row >
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instruction</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            {/* fund dropdown , itx value depend on amc value  */}
                            <div
                              className="form-group "
                              data-tip="Select Fund"
                            >
                              Fund Name*
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                </div>
                              ) : (
                                <select
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['fund_code'])
                                  }
                                  value={fields['fund_code']}
                                  onChange={(e) => {
                                    fields['fund_code'] = e.target.value;
                                    setFields(fields);

                                    clearFundAccountFields();
                                    getAccountByFundName(e.target.value);
                                    if (headLabel === 'unitconversion') {
                                      let filterToAcc = allFunds.filter(
                                        (acc) => acc.symbol_code !== e.target.value
                                      );
                                      let temp = filterToAcc.map((val) => {
                                        if (val.nature === 'Special Managed Account') {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
                                            value: val.symbol_code,
                                          };
                                        } else {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${val.symbol_code})`,
                                            value: val.symbol_code,
                                          };
                                        }
                                      });
                                      setAllCounterFunds(temp);
                                    }

                                    if (headLabel === 'equityinvestment') {
                                      let filter_CDC_Participant_ID = allFunds.filter(
                                        (acc) => acc.symbol_code == e.target.value
                                      );
                                      setCDCParticipantID(filter_CDC_Participant_ID[0].cdc_participant_id)
                                    }

                                    if (headLabel === 'corporate_announcements' || headLabel === 'pledgedin' || headLabel === 'pledgedout') {
                                      {
                                        getFundStockMarketData(e.target.value)
                                      }
                                    }
                                    // // clear counter account
                                    // if(headLabel)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck ? 'No Fund Found' : 'Select Fund'}
                                  </option>
                                  {renderFundsDropdown()}
                                </select>
                              )}
                            </div>
                            <div className="form-group">
                              <div>Instruction Date</div>
                              <input
                                type="date"
                                value={fields['instruction_date']}
                                readOnly
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(fields['instruction_date'])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instruction_date: e.target.value,
                                    //realized_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">{CapTxnType(headLabel)}</h4>
                        </div>
                        <div className="card-body">
                          <div>

                            {/* {chechFieldDisplay(headLabel, 'symbol') && ( */}
                            <div className="form-group">
                              <div>Symbol*</div>
                              <select
                                className={
                                  'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                }
                                defaultValue={fields['symbol']}
                                onChange={(e) => {
                                  if (headLabel === 'pledgedin') {
                                    let vol = stockMarketDataFund.filter((item, index) => {
                                      return (item.code === e.target.value);
                                    });
                                    let plegged = +vol[0].unit - +vol[0].pledged
                                    setPledgedHolding(plegged.toString());
                                    setFields({
                                      ...fields,
                                      symbol: e.target.value,
                                    });

                                  } else if (headLabel === 'pledgedout') {
                                    let vol = stockMarketDataFund.filter((item, index) => {
                                      return (item.code === e.target.value);
                                    });
                                    setPledgedHolding(vol[0].pledged);
                                    setFields({
                                      ...fields,
                                      symbol: e.target.value,
                                    });
                                  } else {
                                    let vol = stockMarketDataFund.filter((item, index) => {
                                      return (item.code === e.target.value);
                                    })
                                    setFields({
                                      ...fields,
                                      volume: vol[0].unit,
                                      symbol: e.target.value,
                                    });
                                  }
                                  // let vol= stockMarketDataFund.filter((item, index)=>{
                                  // return (item.code===e.target.value);
                                  // })
                                  // setVolume(vol[0].unit);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                {renderStockMarketData()}
                              </select>
                            </div>
                            {/* )} */}

                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Right Share Symbol*</div>
                                  <input
                                    type="text"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    value={fields['symbol']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        symbol: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                {/* {chechFieldDisplay(headLabel, 'volume') && ( */}
                                <div className="form-group">
                                  <div>Holding</div>
                                  <input
                                    type="number"
                                    value={fields['volume']}
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['volume'])
                                    }
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setFields({
                                        ...fields,
                                        volume: value,
                                      });

                                    }}
                                  />
                                </div>
                                {/* )} */}
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Right Share %</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    defaultValue={fields['symbol']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        symbol: e.target.value,
                                      });
                                    }}
                                  />

                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Gross Right Share</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    defaultValue={fields['symbol']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        symbol: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Total Market Value</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    defaultValue={fields['symbol']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        symbol: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Average Cost before right</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    defaultValue={fields['symbol']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        symbol: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>


                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Par Value</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Premium</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Cost After Right</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Difference Between Right Price</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Cost of Right Entitled</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <div>Cost of Right</div>
                                  <input
                                    type="number"
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                    }
                                    defaultValue={fields['par_value']}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        par_value: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                          </div>
                        </div>
                      </div>
                    </Col>

                  </Row>

                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          createTransaction();
                        }}
                        disabled={Boolean(Loading)}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>{tx === 'corporate_announcements' ? 'Update' : 'Create'}</span>
                        )}
                      </button>
                    </div>
                  </div>

                </div>
                {renderModalForBankBalance()}
              </div>
              <div style={{ display: 'none' }}>{accTilteName}</div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RightShareSubscription;

