import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { BsTable } from "react-icons/bs";
import { getFundByAmc } from "../../stores/services/funds.service";
// import { getBankStatement,getBankStatementOpeningBalance } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { numberWithCommas } from "../../utils/customFunction";

const AnalysisNavReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  let date = new Date();
  const [analysisNavData, setAnalysisNavData] = useState<any>([
    {
      nav_today: "149.1700",
      nav_back: "147.4900",
      change_nav: "1.6800",
      change_nav_per: "1.14",
      change_kse_100_per: "1.18",
      change_kse_30_per: "1.32",
    },
    {
      nav_today: "84.5200",
      nav_back: "82.8100",
      change_nav: "1.7100",
      change_nav_per: "2.06",
      change_kse_100_per: "1.18",
      change_kse_30_per: "1.32",
    },
    {
      nav_today: "96.3800",
      nav_back: "96.3300",
      change_nav: "0.0500",
      change_nav_per: "0.05",
      change_kse_100_per: "1.18",
      change_kse_30_per: "1.32",
    },
    {
      nav_today: "108.0000",
      nav_back: "106.1800",
      change_nav: "1.8200",
      change_nav_per: "1.71",
      change_kse_100_per: "1.18",
      change_kse_30_per: "1.32",
    },
  ]);
  const [psxData, setPsxData] = useState<any>([
    {
      index: "KSE-1OO INDEX",
      current: "36618.57",
      previous: "36190.40",
      pts_change: "428.17",
    },
    {
      index: "KSE 30 INDEX",
      current: "15901.05",
      previous: "15693.71",
      pts_change: "207.34",
    },
    {
      index: "KMI30 INDEX",
      current: "58926.68",
      previous: "57654.37",
      pts_change: "1272.31",
    },
  ]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCode, setFundCode] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [navDate, setNavDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  const [resNavDate, setResNavDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );

  useEffect(() => {
    const getAllAmcFunds = async () => {
      try {
        allFunds.length = 0;
        setAllFunds(allFunds);
        const response = await getFundByAmc(email, amc_code,fund_code?.length > 0 ? fund_code:"");
        setAllFunds(response.data.data);
      } catch (error) {}
    };
    getAllAmcFunds();
  }, []);

  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  // const CapTxnType = (type: any) => {
  //     let i, txType;
  //     for (i = 0; i < txnTypes.length; i++) {
  //     if (txnTypes[i].api_name === type) {
  //         txType = txnTypes[i].dis_name;
  //         break;
  //     }
  //     }
  //     return txType;
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      setLoading(true);

      setResAmc(amc_name);
      setResFund(fund);
      setResNavDate(navDate);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    {/* <h4 className="card-title">Voucher Report</h4> */}
                    <h4 className="card-title">NAV Analysis Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFund(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                      .text
                              );
                              setFundCode(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select Date</label>
                          <input
                            type="date"
                            defaultValue={navDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setNavDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={"Analysic-Nav-Report-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {analysisNavData.length > 0 ? (
                          <>
                            <ReportHeader
                              title={`Analysis NAV Report As of ${moment(
                                resNavDate
                              ).format("DD-MM-YYYY")}`}
                            />

                            <Row>
                              {/* <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resAmc}
                                    </span>
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFund}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Nav Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resNavDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Report Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(date).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th>Index</th>
                                    <th className="text-right">Current</th>
                                    <th className="text-right">Previous</th>
                                    <th className="text-right">Pts. Change</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {psxData.map((item: any, index: number) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{item.index}</td>
                                          <td className="text-right">
                                            {numberWithCommas(item.current)}
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(item.previous)}
                                          </td>
                                          {+item.pts_change > 0 ? (
                                            <td className="text-right">
                                              <span className="text-success">
                                                {numberWithCommas(
                                                  item.pts_change
                                                )}
                                              </span>
                                            </td>
                                          ) : +item.pts_change < 0 ? (
                                            <td className="text-right text-danger">
                                              <span className="text-danger">
                                                {numberWithCommas(
                                                  item.pts_change
                                                )}
                                              </span>
                                            </td>
                                          ) : (
                                            <td className="text-right">
                                              {numberWithCommas(
                                                item.pts_change
                                              )}
                                            </td>
                                          )}
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>

                              <hr />

                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th className="text-right">{`NAV As of ${moment(
                                      resNavDate
                                    ).format("DD-MM-YYYY")}`}</th>
                                    <th className="text-right">NAV As of</th>
                                    <th className="text-right">
                                      Chane in NAV(Rs)
                                    </th>
                                    <th className="text-right">
                                      % Change in NAV
                                    </th>
                                    <th className="text-right">
                                      % Change in KSE-100
                                    </th>
                                    <th className="text-right">
                                      % Change in KSE-30
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {analysisNavData.map(
                                    (item: any, index: number) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            <td className="text-right">
                                              {numberWithCommas(item.nav_today)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(item.nav_back)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(
                                                item.change_nav
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {item.change_nav_per}
                                            </td>
                                            <td className="text-right">
                                              {item.change_kse_100_per}
                                            </td>
                                            <td className="text-right">
                                              {item.change_kse_30_per}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnalysisNavReport;
