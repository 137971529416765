import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { addEquitySecurity } from "../../../stores/services/security.service";
import { useHistory, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import NumberFormat from "react-number-format";
import moment from "moment";

const SetupEquitySecurity = () => {
  const history = useHistory();
  const amc_code = sessionStorage.getItem('amc_code') || "";

  /////
  const [companyName, setCompanyName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [sectorCode, setSectorCode] = useState("");
  const [openRate, setOpenRate] = useState("");
  const [highRate, setHighRate] = useState("");
  const [lowRate, setLowRate] = useState("");
  const [closeRate, setCloseRate] = useState("");
  const [volume, setVolume] = useState("");
  const [ldcp, setLDCP] = useState("");
  const [paidUpCapital, setPaidUpCapital] = useState("");
  const [facevalue, setFaceValue] = useState("");
  const [date, setDate] = useState("");

  //// Error
  const [companyNameError, setCompanyNameError] = useState("");
  const [symbolError, setSymbolError] = useState("");

  const [Loading, setLoading] = useState(false);
  const createSecurity = async () => {
    if (!companyName && !symbol) {
      setCompanyNameError("Required");
      setSymbolError("Required");
      return;
    }
    if (!companyName) {
      setCompanyNameError("Required");
      return;
    }
    if (!symbol) {
      setSymbolError("Required");
      return;
    }
    setLoading(true);
    await addEquitySecurity(
      sessionStorage.getItem("email") || "",
      symbol,
      date,
      sectorCode,
      companyName,
      openRate,
      highRate,
      lowRate,
      closeRate,
      volume,
      ldcp,
      paidUpCapital,
      facevalue,
      amc_code
    )
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.message);
          setTimeout(function () {
            history.goBack();
          }, 3000);
        } else if (response.data.status == 500) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
      });
    setLoading(false);
  };

  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card ">
                <div className="">
                  <div className="card-header title-row">
                    <h4 className="card-title">
                      <i
                        className="fa fa-angle-left mr-1 pointer"
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>
                      Setup - Equity Securities
                    </h4>
                    <Link
                      to="/admin/securities"
                      className="t-3 ml-auto btn btn-primary btn-sm"
                      replace
                    >
                      {" "}
                      <i className="fa fa-eye mr-2"></i> View All
                    </Link>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>
                            Company Name: <span className="color-amber">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              companyNameError ? " required-border" : ""
                            }`}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                              setCompanyNameError("");
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>
                            Symbol: <span className="color-amber">*</span>
                          </label>

                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              symbolError ? " required-border" : ""
                            }`}
                            value={symbol}
                            onChange={(e) => {
                              setSymbol(e.target.value);
                              setSymbolError("");
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>Sector</label>
                          <input
                            type="text"
                            className={`form-control  w-100`}
                            value={sectorCode}
                            onChange={(e) => {
                              setSectorCode(e.target.value.toUpperCase());
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>Open Rate</label>
                          <div className="">
                            <input
                              className={`form-control  w-100 `}
                              value={openRate}
                              onChange={(e) => {
                                setOpenRate(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>High Rate</label>

                          <input
                            type="number"
                            className="form-control"
                            value={highRate}
                            onChange={(e) => {
                              setHighRate(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>Low Rate</label>

                          <input
                            type="number"
                            className="form-control"
                            value={lowRate}
                            onChange={(e) => {
                              setLowRate(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>Close Rate</label>
                          <input
                            type="number"
                            value={closeRate}
                            className="form-control"
                            onChange={(e) => {
                              setCloseRate(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>Volume</label>
                          <input
                            type="number"
                            value={volume}
                            className="form-control"
                            onChange={(e) => {
                              setVolume(e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="form-group">
                          <label>LDCP</label>
                          <input
                            type="number"
                            value={ldcp}
                            className="form-control"
                            onChange={(e) => {
                              setLDCP(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>Paid up Capital</label>
                          <input
                            type="number"
                            value={paidUpCapital}
                            className="form-control"
                            onChange={(e) => {
                              setPaidUpCapital(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>Face Value</label>
                          <input
                            type="number"
                            value={facevalue}
                            className="form-control"
                            onChange={(e) => {
                              setFaceValue(e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="form-group">
                          <label>Date</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "DDMMMYYYY"
                              );
                              setDate(date.toUpperCase());
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          createSecurity();
                        }}
                        disabled={Boolean(Loading)}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Create</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SetupEquitySecurity;
