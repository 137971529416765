
import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {getDailyFundPortfolioByDate, addDailyFundortfolio} from '../../stores/services/daily-fund-portfolio.service';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTable } from 'react-icons/bs';
import moment from 'moment';
import { numberWithCommas } from '../../utils/customFunction';

const DailyFundPortfolio = () => {
  let date = new Date();

  const email = sessionStorage.getItem('email') || '';
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [dailPortfolio, setDailyPortfolio] = useState<any>([]);
  const history = useHistory();

  // modals
  const [viewMoneyMaketModal, setViewMoneyMarketModal] = useState(false);
  const [viewStockMarketModal, setViewStockMarketModal] = useState(false);

  const [moneyMarketPortfolio, setMoneyMarketPortfolio] = useState<any>([]);
  const [stockMarketPortfolio, setStockMarketPortfolio] = useState<any>([]);

  const [saveDailyFundPortfolioLoading, setDailyFundPortfolioLoading] = useState(false);


  React.useEffect(() => { 
    getDailyPortfolioByDate();
  }, []);
 
  const getDailyPortfolioByDate = async () => {
    setLoading(true);
    dailPortfolio.length = 0;
    setDailyPortfolio(dailPortfolio); 
    try {
      const response = await getDailyFundPortfolioByDate(email, amcCode, createdDate);
      if(response.data.status==200){
        setDailyPortfolio(response.data.data);
        toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
      setLoading(false);
    } catch (error:any) {
      setLoading(false);
    }
      setLoading(false);
  };

  const addFundPortfolio = async()=>{
    setDailyFundPortfolioLoading(true);
    try{
        const response = await addDailyFundortfolio(email, amcCode);
        if(response.data.status==200){
            toast.success(response.data.message);
        }else{
            toast.error(response.data.message);
        }
        setDailyFundPortfolioLoading(false);
    }catch(err:any){
      setDailyFundPortfolioLoading(false);
    }
    setDailyFundPortfolioLoading(false);
}

  const decimalValue = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const renderData = () => {
    return dailPortfolio.map((items: any, index: any) => {
      return (
        <tr key={index}> 
          <td>{items.fund_code}</td>
          <td>
            <div className= "fa fa-eye text-primary pointer" onClick={()=>{
              moneyMarketPortfolio.length=0;
              setMoneyMarketPortfolio(moneyMarketPortfolio);

              setMoneyMarketPortfolio(JSON.parse(items.money_market));
              setViewMoneyMarketModal(true);
            }}>
          
            </div></td>
          <td>
          <div className= "fa fa-eye text-primary pointer" onClick={()=>{
              stockMarketPortfolio.length=0;
              setStockMarketPortfolio(stockMarketPortfolio);

              setStockMarketPortfolio(JSON.parse(items.stock_market));
              setViewStockMarketModal(true);
            }}>
          
            </div>
          </td>
        </tr>
      );
    });
  };

  // render Money Market Portfolio
  const renderMoneyMarketModalPopup = () => {
    switch (viewMoneyMaketModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewMoneyMarketModal(false);
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewMoneyMarketModal(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Money Market Portfolio </h6>
             </div>    

            <div className="modal-body">
            <div className="table-responsive">
                {moneyMarketPortfolio.length > 0 ? (
                <div className="input-size-pos mb-3 mt-2">
                  {/* <h3>Money Market Portfolio</h3> */}
                  <table className="table   ">
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Security Type</th>
                        <th>Name</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th>Coupon Rate %</th>
                        <th>Yield %</th>
                        <th>Money Market Face Value/Unit</th>
                        <th>Total Face Value</th>
                        <th>Units</th>
                        <th>Avg Cost/Unit</th>
                        <th>Total Cost / Amount</th>
                        <th>Market Rate/Unit</th>
                        <th>Market Value</th>
                        <th>Unrealized Gain/(Loss)</th>
                        <th>Investment as % of Market Value</th>
                      </tr>
                    </thead>
                    <tbody>{renderMoneyMarketPortfolioDataInTable()}</tbody>
                  </table>
                </div>
              ):(
                <div className='text-center'>No Money Market Portfolio Found</div>
              )}

            </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const renderMoneyMarketPortfolioDataInTable = () => {
    return moneyMarketPortfolio.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td> 
          <td>{items.maturity_date}</td> 
          <td>{numberWithCommas(items.coupon_rate || '')}</td> 
          <td>{numberWithCommas(items.yield || '')}</td> 
          <td>{numberWithCommas(items.mmface_value || '')}</td> 
          <td>{numberWithCommas(items.total_face_value || '')}</td>
          {/* <td>{numberWithCommas(items.price || '')}</td> */}
          <td>{numberWithCommas(items.units || '')}</td> 
          <td>{numberWithCommas(items.average_cost_per_unit || '')}</td> 
          <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td> 
          <td> {numberWithCommas(items.market_rate_per_unit || '')} </td>
          <td> {numberWithCommas(items.market_value || '')} </td>
          <td> {numberWithCommas(items.unrealized_gain_loss || '')} </td>
          <td> {numberWithCommas(items.investment_percentage_market_value || '')} </td>
        </tr>
      );
    });
  };


  // render Stock Market Portfolio
  const renderStockMarketPortfolioModal = () => {
    switch (viewStockMarketModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setViewStockMarketModal(false);
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewStockMarketModal(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Stock Market Portfolio </h6>
             </div> 


            <div className="modal-body ">
            <div className="table-responsive">
                {stockMarketPortfolio.length > 0 ? (
                <div className="input-size-pos mb-3 mt-2 ">
                  {/* <h3>Stock Market Portfolio</h3> */}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Market</th>
                        <th>Total Units</th>
                        <th>Available Units</th>
                        <th>Average Cost / Unit</th>
                        <th>Total Cost / Amount</th>
                        <th>Market Rate/Unit</th>
                        <th>Market Value</th>
                        <th>Unrealzed Gain/(Loss)</th>
                        <th>Investment as %  Market Value</th>
                      </tr>
                    </thead>
                    <tbody>{renderStockMarketPortfolioInTable()}</tbody>
                  </table>
                </div>
              ):(
                <div className='text-center'>No Stock Market Portfolio Found</div>
              )}
            </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //render equity table data
  const renderStockMarketPortfolioInTable = () => {
    return stockMarketPortfolio.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.market}</td>
          <td>{numberWithCommas(items.total_units || '') && numberWithCommas(items.total_units || '')}</td>
          <td>{numberWithCommas(items.unit || '') && numberWithCommas(items.unit || '')}</td>
          <td>{numberWithCommas(items.average_cost_per_unit)}</td>
          <td>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}</td>
          <td>{numberWithCommas(items.market_rate_per_unit)}</td>
          <td>{numberWithCommas(items.market_value)}</td>
          <td>{numberWithCommas(items.unrealized_gain_loss)}</td>
          <td>{numberWithCommas(items.investment_percentage_market_value)}</td>
        </tr>
      );
    });
  };

  return (
    <>
<div className="content">
  <Row>
    <Col md="12">
    <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Daily Fund Portfolio
            <button className='btn btn-primary btn-sm float-right'
            disabled={Boolean(saveDailyFundPortfolioLoading)}
            onClick={()=>{
              addFundPortfolio();
            }}> 
            {saveDailyFundPortfolioLoading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Save Daily Fund Portfolio</span>
                )}</button></h4>
          </div>
            <div className="card-body">
              <div className="row">

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Date:</label>
                      <input type="date" className="form-control"
                        value={createdDate}		
                        onChange={(e) => {		
                          setCreatedDate(e.target.value);	
                          	 
                        }}	 />
                  </div>
                </div>

                <div className="col-md-2">
                  <label ></label>
                  <button className="btn btn-primary btn-block"
                  disabled={Boolean(Loading)}
                  onClick={() => getDailyPortfolioByDate()}>
                    {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Load</span>
                )}
                    </button>
                </div>

              </div>

              {Loading ? (
              <div className="d-flex justify-content-center my-5">
              <i className="fa fa-spinner fa-spin fa-3x" ></i> 
              </div>
              ):(
                dailPortfolio.length > 0 ? (
                <>
                 <div className='table-responsive'>
                <table className="table" id="myTable">
                  <thead>
                    <tr>
                      <th>Fund</th> 
                      <th>View Money Market Portfolio</th>
                      <th>View Stock Market Portfolio</th>
                    </tr>
                  </thead>
                  <tbody id="myUL">{renderData()}</tbody>
                </table>
                </div>
                
              </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                    <p className="text-center empty-text">Empty</p>              
                </div>
              )


          )}
            </div>
            
          
        </div>
      </Container>
   
    </Col>
  </Row>
  {renderMoneyMarketModalPopup()}
  {renderStockMarketPortfolioModal()}
</div>
    </>
  );
};

export default DailyFundPortfolio;

