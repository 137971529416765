import React from "react";
import { useState } from "react";
import { searchAPI } from "./../stores/services/amc.service";
import { Modal } from "react-bootstrap";
import { Container, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  getFundByAmc,
  getInstrumentType,
} from "./../stores/services/funds.service";
import { getModeOfPayments } from "./../stores/services/transactions.service";
import { getAccountByFund } from "./../stores/services/account.service";
import { getAllBanks } from "./../stores/services/bank.service";
import { getBranchByBankName } from "./../stores/services/branch.service";
import { getAllUnitHoldersByAmc } from "./../stores/services/unit-holder.service";
import { RiArrowGoForwardFill } from "react-icons/ri";
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from "./field";
import {
  addMegaTransaction,
  EditTransaction,
  getFlow,
} from "./../stores/services/beta-transaction.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { inWords } from "./../stores/services/template.service";
import { getBrokerByType } from "./../stores/services/broker.service";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  numberWithCommas,
  accountTitle_AccountNumber_Regex,
  getLedgerAccounts,
  getLedgersByTxn,
} from "./../utils/customFunction";
import { Link } from "react-router-dom";

import AddUnitHolder from "./add-new-unitholder-txn";
import NumberFormat from "react-number-format";

import {
  getChartOfAccounts,
  getTransactionsLedgers,
  getLedgerInfoByCode,
} from "./../stores/services/chatofaccounts.service";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getAllActiveUnpaidDividendAnnouncementsByFund } from "./../stores/services/dividend-announcement.service";
import {
  getAllDividendWorkingForCurrentDate,
  updateDividendWorkingAfterPayout,
} from "../stores/services/fund-dividend-payout.service";
// import { numberWithCommas } from "./../utils/customFunction";
import { useSelector } from "react-redux";

let NavConfig = require("./nav-config.json");

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type: string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized: string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst: string;
  tax_wht_it: string;
  tax_wht_sst: string;
  tax_cgt: string;
  sale_load: string;
  redemption_load: string;
  low_balance_agree: string;
  offer_price: string;
  load_per_unit: string;
  percentage_of_discount: string;
  applicable_offer_price: string;
  sales_tax_collector: string;
  volume: string;
  par_value: string;
  announcement_date: string;
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend: string;
  tax_on_bonus: string;
  tax_on_right: string;
  zakat_amount: string;
  ledgers: string;
}

const FundDividendPayout = () => {
  const history = useHistory();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [headLabel, setHeadLabel] = React.useState("");
  const tx = sessionStorage.getItem("rejectedTxName") || "";
  const amcEdit = sessionStorage.getItem("rejectedAmc") || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  // let txnTypes = JSON.parse(sessionStorage.getItem("txnType") || "");
  let txnTypes = useSelector((state: any) => state.txnTypes);
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState("");
  const [accLoading, setAccLoading] = useState(false);
  const paymentType = sessionStorage.getItem("payment_type") || "";

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const amcName = sessionStorage.getItem("amc_name") || "";

  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response

  //for form fields
  const [fund, setFund] = useState<any>();
  const [fundError, setFundError] = useState<boolean>(false);
  //for date
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);

  const [instructionDate, setInstructionDate] = useState<any>(today);
  const [instructionDateError, setInstructionDateError] =
    useState<boolean>(false);

  const [executionDate, setExecutionDate] = useState<any>(today);
  const [executionDateError, setExecutionDateError] = useState<boolean>(false);

  const [fundAccount, setFundAccount] = useState<any>();
  const [fundAccountError, setFundAccountError] = useState<boolean>(false);

  const [bankAccountTitle, setBankAccountTitle] = useState<any>();
  const [bankAccountTitleError, setBankAccountTitleError] =
    useState<boolean>(false);

  const [bankAccountNumber, setBankAccountNumber] = useState<any>();
  const [bankAccountNumberError, setBankAccountNumberError] =
    useState<boolean>(false);

  const [bankName, setBankName] = useState<any>();
  const [bankNameEbrror, setBankNameError] = useState<boolean>(false);

  const [bankBranch, setBankBranch] = useState<any>();
  const [bankBranchError, setBankBranchError] = useState<boolean>(false);

  const [dividendPeriodStart, setDividendPeriodStart] = useState<any>(today);
  const [dividendPeriodStartError, setDividendPeriodStartError] =
    useState<boolean>(false);

  const [dividendPeriodEnd, setDividendPeriodEnd] = useState<any>(today);
  const [dividendPeriodEndError, setDividendPeriodEndError] =
    useState<boolean>(false);

  const [dividendIssueDate, setDividendIssueDate] = useState<any>(today);
  const [dividendIssueDateError, setDividendIssueDateError] =
    useState<boolean>(false);

  const [dividendIssueNumber, setDividendIssueNumber] = useState<any>();
  const [dividendIssueNumberError, setDividendIssueNumberError] =
    useState<boolean>(false);

  // const [fundFaceValue, setFundFaceValue] = useState<any>();
  // const [fundFaceValueError, setFundFaceValueError] = useState<boolean>(false);

  const [openingExNav, setOpeningExNav] = useState<any>();
  const [openingExNavError, setOpeningExNavError] = useState<boolean>(false);

  // const [currentNav, setCurrentNav] = useState<any>();
  // const [currentNavError, setCurrentNavError] = useState<boolean>(false);

  const [closingNavBeforeDistribution, setClosingNavBeforeDistribution] =
    useState<any>();
  const [
    closingNavBeforeDistributionError,
    setClosingNavBeforeDistributionError,
  ] = useState<boolean>(false);

  const [dividendPerUnit, setDividendPerUnit] = useState<any>();
  const [dividendPerUnitError, setDividendPerUnitError] = useState<any>();

  const [dividendPercentage, setDividendPercentage] = useState<any>();
  const [dividendPercentageError, setDividendPercentageError] =
    useState<boolean>(false);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  let fundFilteringForValues: any = "";

  const [unitHolderList, setUnitHolderList] = useState<any>();
  let [unitHolderListSelected, setUnitHolderListSelected] = useState<any>();

  let [tableData, setTableData] = useState<any>();

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data

  const [viewModalAddNewHolder, setViewModalAddNewUnitHolder] = useState(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [propsState, setPropsState] = useState({
    amc_code: "",
    folioNo: "",
    unitholder: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
  });

  // for legders states
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [counterLedgerAccounts, setCounterLegderAccounts] = useState<any>([]);

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{}]);

  const [totalDebit, setTotalDebit] = useState("0");
  const [totalCredit, setTotalCredit] = useState("0");
  const [balance, setBalance] = useState("0");

  const [checkboxState, setCheckboxState] = useState<boolean>(false);
  const [generateDisable, setGenerateDisable] = useState<boolean>(false);

  const [dividendAnnouncementData, setDividendAnnouncementData] =
    useState<any>();

  const [dividendAnnouncementSelected, setDividendAnnouncementSelected] =
    useState<any>();
  const [
    dividendAnnouncementSelectedError,
    setDividendAnnouncementSelectedError,
  ] = useState<boolean>(false);

  const [exNavAfterDistribution, setExNavAfterDistribution] = useState<any>();
  const [exNavAfterDistributionError, setExNavAfterDistributionError] =
    useState<boolean>(false);

  const [redemptionDate, setRedemptionDate] = useState<any>();
  const [redemptionDateError, setRedemptionDateError] =
    useState<boolean>(false);

  let [dividendRecordsToUpdate, setDividendRecordsToUpdate] = useState<any>([]);
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);

  React.useEffect(() => {
    getUnitHolders();
  }, []);

  React.useEffect(() => {
    if (unitHolderList && fund) {
      if (unitHolderList?.length > 0 && fund.length > 0) {
        unitHolderList.map((holder, index) => {
          let balance = JSON.parse(holder.balance_unit)[0];
          if (balance !== undefined) {
            if (balance.fund_code === fund) {
              unitHolderListSelected = [...unitHolderListSelected, holder];
            }

            return;
          }
        });
      }
    }
    setUnitHolderListSelected(unitHolderListSelected);
  }, [unitHolderList, fund]);

  React.useEffect(() => {
    if (fund) {
      getDividendAnnouncements();
    }
  }, [fund]);

  React.useEffect(() => {
    if (dividendAnnouncementSelected) {
      setDividendIssueNumber(dividendAnnouncementSelected.announcement_id);
    }
  }, [dividendAnnouncementSelected]);

  // React.useEffect(() => {

  // }, []);

  const getDividendAnnouncements = async () => {
    setDividendAnnouncementData("");
    let paid = false;
    const response = await getAllActiveUnpaidDividendAnnouncementsByFund(
      email,
      fund,
      paid,
      amc_code
    );
    if (response.data.status === 404) {
      return toast.error("No dividend announcements for this fund!");
    } else {
      setDividendAnnouncementData(response?.data?.data);
    }
  };

  const getUnitHolders = async () => {
    const response = await getAllUnitHoldersByAmc(email, amc_code);
    setUnitHolderList(response.data.data);
  };

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === "Fund") {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code:"");
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) {
          return 1;
        }
        if (x < y) {
          return -1;
        }
        return 0;
      });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if (val.nature === "Special Managed Account") {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${"SMA"} `,
            value: val.symbol_code,
          };
        } else {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code})`,
            value: val.symbol_code,
          };
        }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
  };

  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item: any) => {
      if (item.symbol_code === fund_code) {
        fields["nav"] = item.nav;
        fields["face_value"] = item.face_value;

        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== "" &&
          item.stock_market !== "[]" &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code,amc_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem("rejectedFlag") || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const handleCheckboxChange = () => {
    setCheckboxState(!checkboxState);
  };

  // filed Object Hook For geting data from fields / object for transaction creation

  let disableDates = moment(date).format("YYYY-MM-DD");
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "0",
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: "",
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  //code for edit transaction
  let [count, setCount] = useState(0);

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem("rejectedTxObj") || " ");
      getFundByAMcCode(obj.amc_code);
      fields["payment_type"] = obj.payment_type || "";
      fields["txnr_type"] = obj.txnr_type || "";
      fields["amc_code"] = obj.amc_code || "";
      getAccountByFundName(obj.fund_code);
      fields["fund_code"] = obj.fund_code || "";
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields["instruction_date"] = obj.instruction_date || "";
      fields["execution_date"] = obj.execution_date || "";
      fields["executed_date"] = obj.executed_date || "";
      fields["fund_account"] = obj.fund_account || "";
      fields["dividend_date"] = obj.dividend_date || "";
      fields["dividend_rate"] = obj.dividend_rate || "";
      fields["reinvest"] = obj.reinvest || "";
      fields["period"] = obj.period || "";
      fields["account_title"] = obj.account_title || "";
      fields["account_no"] = obj.account_number || "";
      fields["bank"] = obj.bank || "";
      fields["branch"] = obj.branch || "";
      fields["counter_account_type"] = obj.counter_account_type || "";
      fields["counter_type"] = obj.counter_type || "";
      fields["counter_account_title"] = obj.counter_account_title || "";
      fields["counter_account_number"] = obj.counter_account_number || "";
      fields["counter_bank"] = obj.counter_bank || "";
      fields["counter_branch"] = obj.counter_branch || "";
      fields["mode_of_payment"] = obj.mode_of_payment || "";
      fields["instrument_no"] = obj.instrument_no || "";
      fields["instrument_type"] = obj.instrument_type || "";
      fields["instrument_date"] = obj.instrument_date || "";
      fields["realized_date"] = obj.realized_date || "";
      fields["realized"] = obj.realized || "";
      fields["gross_amount"] = obj.gross_amount.toString() || "";
      fields["net_amount"] = obj.net_amount.toString() || "";
      fields["dr_amount"] = "0";
      fields["cr_amount"] = "0";
      fields["balance"] = obj.balance.toString() || "";
      fields["folio_no"] = obj.folio_no || "";
      fields["unitholder_name"] = obj.unitholder_name || "";
      fields["units"] = obj.units.toString() || "";
      fields["nav"] = obj.nav.toString() || "";
      fields["sale_date"] = obj.sale_date || "";
      fields["current_holding"] = obj.current_holding.toString() || "";
      fields["total_holding"] = obj.total_holding.toString() || "";
      fields["symbol"] = obj.symbol || "";
      fields["dividend_percentage"] = obj.dividend_percentage.toString() || "";
      fields["credit_date"] = obj.credit_date || "";
      fields["maturity_type"] = obj.maturity_type || "";
      fields["security_type"] = obj.security_type || "";
      fields["issue_date"] = obj.issue_date || "";
      fields["conversion_date"] = obj.conversion_date || "";
      fields["associated_transaction"] = obj.associated_transaction || "";
      fields["maturity_date"] = obj.maturity_date || "";
      fields["coupon_rate"] = obj.coupon_rate || "";
      fields["price"] = obj.price.toString() || "";
      fields["face_value"] = obj.face_value.toString() || "";
      fields["money_market_face_value"] =
        obj.money_market_face_value.toString() || "";
      fields["detail"] = obj.detail || "";
      fields["type"] = obj.type || "";

      fields["redemption_date"] = obj.redemption_date || "";
      fields["remain_holding"] = obj.remain_holding.toString() || "";
      fields["settlement_by"] = obj.settlement_by || "";
      fields["tax_type"] = obj.tax_type || "";
      fields["settlement_date"] = obj.settlement_date || "";
      fields["total_charges"] = obj.total_charges.toString() || "";
      fields["sale_txn_no"] = obj.sale_txn_no || "";
      fields["return_date"] = obj.return_date || "";
      fields["trx_id"] = obj.txn_id || "";
      fields["primary_dealer"] = obj.primary_dealer;
      fields["yield"] = obj.yield || "";

      if (headLabel === "" || headLabel === "") {
        fields["sale_load"] = obj.sale_load || "";
      }

      if (headLabel === "" || headLabel === "") {
        fields["redemption_load"] = obj.redemption_load || "";
        fields["tax_cgt"] = obj.tax_cgt || "";
      }

      fields["zakat_amount"] = obj.zakat_amount;
      fields["txn_status"] = "CREATED";
      // Dividend On Investment
      fields["volume"] = obj.volume;
      fields["par_value"] = obj.par_value;
      fields["announcement_date "] = obj.announcement_date;
      fields["bonus_percentage"] = obj.bonus_percentage;
      fields["bonus_credit_date"] = obj.bonus_credit_date;
      fields["bonus_gross_volume"] = obj.bonus_gross_volume;
      fields["tax_it_bonus_amount"] = obj.tax_it_bonus_amount;
      fields["net_bonus_volume"] = obj.net_bonus_volume;
      fields["right_share_percentage"] = obj.right_share_percentage;
      fields["right_share_credit_date"] = obj.right_share_credit_date;
      fields["right_share_gross_volume"] = obj.right_share_gross_volume;
      fields["tax_it_right_share_amount"] = obj.tax_it_right_share_amount;
      fields["net_right_share_volume"] = obj.net_right_share_volume;
      fields["tax_on_dvidend"] = obj.tax_on_dvidend;
      fields["tax_on_bonus"] = obj.tax_on_bonus;
      fields["tax_on_right"] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields["fund_ips_account"] = obj.fund_ips_account || "";
      fields["counter_party_ips_account"] = obj.counter_party_ips_account || "";
      fields["low_balance_agree"] = "";
    }

    NavConfig.NavConfig.map((item) => {});
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  React.useEffect(() => {
    if (!flag) {
      fields["payment_type"] = paymentType;
      fields["email"] = email;
      fields["amc_code"] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);

      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(
          sessionStorage.getItem("loggedInUser") || ""
        );
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {}

      try {
        // const response = await getChartOfAccounts(email);
        // if (response.data.status == 200) {
        let ledgersacc = await getLedgerAccounts(
          chartOfAccounts[0].family[3].sub_family
        );
        setLegderAccounts(ledgersacc);
        setCounterLegderAccounts(ledgersacc);
        //  renderCounterLedgersAccounts();
        // } else {
        //   toast.error(response.data.message);
        // }
      } catch (error) {}

      // get all transaction ledgers
      try {
        const response = await getTransactionsLedgers(email);
        if (response.data.status == 200) {
          let fundManagement =
            response.data.data.txn_catagory[1].fundTransferTxn;
          let txnLegders = await getLedgersByTxn(
            fundManagement,
            "funddividendpayout"
          );
          setTransactionLedgers(txnLegders);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: "Other",
          label: "Other",
        });
        setAmcdataCounter(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }

      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email,"","",amc_code);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace("BANK_", "")} (${val.bank_name})`,
            value: val.bank_code.replace("BANK_", ""),
          };
        });
        temp.unshift({ value: "Other", label: "Other" });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter,amc_code);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: "Other", label: "Other" });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  //   const renderCounterLedgersAccounts=(data)=>{
  //     let temp = data.map((val) => {
  //         return {
  //           ...val,
  //           label: val.name,
  //           value: val.code
  //         };
  //       });
  //       setCounterLegderAccounts(temp);
  //   }

  const renderCounterLedgersAccounts = () => {
    return (
      counterLedgerAccounts &&
      counterLedgerAccounts.map((item: any, index: number) => {
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  const renderFundAccounts = (type) => {
    return (
      ledgerAccounts &&
      ledgerAccounts.filter(item=>item.plsName == type).map((item: any, index: number) => {
        //   if(item.plsName==='PLS ACCOUNTS'){
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }} label="PLS ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );
        // }else{
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }}  label="CURRENT ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );

        // }
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const renderDividendAnnouncementDropdown = () => {
    if (dividendAnnouncementData && dividendAnnouncementData !== "") {
      return dividendAnnouncementData.map((item: any, index: number) => {
        return (
          <>
            <option key={item.announcement_id} value={item.announcement_id}>
              {item.announcement_id} - {item.description}
            </option>
          </>
        );
      });
    } else return <option value="">No Dividend Announcement Found</option>;
  };

  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name,amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name,amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === "Other") {
      setFields({
        ...fields,
        counter_branch: "",
      });
      setInputType(true);
    }
  };

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState("");

  const createLegders = () => {
    if (headLabel == "fundtransfer") {
      transactionLedgers.map((item: any) => {
        if (item.type == "Credit") {
          item.amc_code = amc_code;
          item.fund_code = fields["fund_code"];

          item.balance = "";
          item.counter_account_number = fields["counter_account_number"];
          item.counter_account_title = fields["counter_account_title"];
          item.counter_bank = fields["counter_bank"];
          item.counter_branch = fields["counter_branch"];

          item.description = "Gross Amount & " + fields["detail"];
          item.instruction_date = fields["instruction_date"];
          item.instrument_by = "";
          item.instrument_date = fields["instrument_date"];
          item.instrument_no = fields["instrument_no"];
          item.instrument_type = fields["instrument_type"];
          item.realized = fields["realized"];
          item.realized_by = "";
          item.realized_date = fields["realized_date"];
          item.entered_by = "";
          item.evidence = "";
          item.period = sessionStorage.getItem("active_period") || "";
        }
        if (item.type == "Debit") {
          item.amc_code = amc_code;
          item.fund_code = fields["fund_code"];

          item.account_number = fields["counter_account_number"];
          item.account_title = fields["counter_account_title"];
          item.bank_code = fields["counter_bank"];
          item.branch = fields["counter_branch"];
          item.balance = "";
          item.counter_account_number = fields["account_no"];
          item.counter_account_title = fields["account_title"];
          item.counter_bank = fields["bank"];
          item.counter_branch = fields["branch"];

          item.description = "Gross Amount & " + fields["detail"];
          item.instruction_date = fields["instruction_date"];
          item.instrument_by = "";
          item.instrument_date = fields["instrument_date"];
          item.instrument_no = fields["instrument_no"];
          item.instrument_type = fields["instrument_type"];
          item.realized = fields["realized"];
          item.realized_by = "";
          item.realized_date = fields["realized_date"];
          item.entered_by = "";
          item.evidence = "";
          item.period = sessionStorage.getItem("active_period") || "";
        }
      });
      return transactionLedgers;
    }
  };

  //Create Transaction Api Call
  const createTransaction = async () => {
    if (!chechFieldDisplay(headLabel, "nav")) {
      fields["nav"] = "";
    }
    if (headLabel === "unitconversion") {
      let conversionAmount = +fields["net_amount"] / +unitNav;
      fields["total_holding"] = conversionAmount.toFixed(2);
    } else {
      fields["counter_type"] =
        counterType !== "" ? counterType : counterAccounter;
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (
        headLabel === "fundtransfer" &&
        fields["counter_account_type"] === fields["fund_account"]
      ) {
        toast.error("Both account should be not same");
      } else {
        setLoading(true);
        // if (flag) {
        //   if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
        //     fields['counter_account_type']=fields['counter_account_type'].replaceAll(fields['amc_code']+'-','')
        //     fields['counter_account_type']=fields['amc_code']+'-'+fields['counter_account_type'];
        //   }
        //   if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
        //     fields['folio_no']=fields['folio_no'].replaceAll(fields['amc_code']+'-','');
        //     fields['folio_no']=fields['amc_code']+'-'+fields['folio_no'];
        //   }
        //   if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0 ){
        //     toast.error("Amounts or units or remaining holding are negative");
        //   }else if((headLabel==='redemptionofunits' && +fields['units']===0) || (headLabel==='conversionout' && +fields['units']===0) || (headLabel==='saleofunit' && +fields['units']===0)
        //   || (headLabel==='conversionin' && +fields['units']===0)){
        //     toast.error(" Units cannot be zero.");
        //   }
        //   else{
        //   try {
        //     const response = await EditTransaction(fields); // for Edit existed transaction
        //     if(response.data.status===400){
        //       setViewBankBalanceModal(true);
        //       toast.error(response.data.message);
        //     }else if(response.data.status===417){
        //       toast.warning(response.data.message);
        //     }else{
        //       setUnderStand(false);
        //       setViewBankBalanceModal(false);
        //       toast.success(response.data.message);
        //       setTimeout(() => {
        //         history.replace(`/admin/create-transaction`);
        //       }, 3000);
        //   }
        //   } catch (error:any) {
        //     setLoading(false);
        //     if(error.response!==undefined){
        //       toast.error(error.response.data.message);
        //       }else{
        //         toast.error('Something went wrong!');
        //       }
        //   }
        // }
        // } else {
        if (
          parseFloat(fields["net_amount"]) < 0 ||
          parseFloat(fields["gross_amount"]) < 0 ||
          parseFloat(fields["units"]) < 0 ||
          parseFloat(fields["remain_holding"]) < 0
        ) {
          toast.error("Amounts or units or remaining holding are negative");
        } else if (
          (headLabel === "redemptionofunits" && fields["units"] === "0") ||
          (headLabel === "conversionout" && fields["units"] === "0") ||
          (headLabel === "saleofunit" &&
            (fields["units"] === "0" || fields["units"] === "0.0000")) ||
          (headLabel === "conversionin" &&
            (fields["units"] === "0" || fields["units"] === "0.0000"))
        ) {
          toast.error(" Units cannot be zero.");
        } else {
          if (headLabel === "saleofunit" || headLabel === "redemptionofunits") {
            fields["counter_account_type"] = fields[
              "counter_account_type"
            ].replaceAll(fields["amc_code"] + "-", "");
            fields["counter_account_type"] =
              fields["amc_code"] + "-" + fields["counter_account_type"];
          }
          if (
            headLabel === "conversionin" ||
            headLabel === "conversionout" ||
            headLabel === "unitconversion"
          ) {
            fields["folio_no"] = fields["folio_no"].replaceAll(
              fields["amc_code"] + "-",
              ""
            );
            fields["folio_no"] = fields["amc_code"] + "-" + fields["folio_no"];
          }
          if (+balance != 0) {
            toast.error(
              `Balance should be 0, Other wise you can't create transaction`
            );
            return;
          }
          let finalLedgers = createLegders();
          fields.ledgers = JSON.stringify(finalLedgers);
          try {
            const response = await addMegaTransaction(fields); // for add new transaction
            if (response.data.status === 400) {
              setViewBankBalanceModal(true);
              toast.warning(response.data.message);
            } else if (response.data.status === 417) {
              toast.warning(response.data.message);
            } else {
              setUnderStand(false);
              setViewBankBalanceModal(false);
              sessionStorage.setItem("last_id", response.data.txn_id);
              toast.success(
                `${response.data.message} - ${response.data.txn_id}`
              );
              // toast.success(response.data.message);
              setTimeout(() => {
                // history.replace(`/admin/create-transaction`);
                // history.replace(`/admin/transactions-funds-management`);
                history.goBack()
              }, 3000);
            }
          } catch (error: any) {
            
            setLoading(false);
            if (error.response !== undefined) {
              toast.error(error.response.data.message);
            } else {
              
              toast.error("Something went wrong!");
            }
          }
        }
        // }
        // setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error("Kindly Fill All the Fields");
    }
  };

  const [accTilteName, setAccTiltleName] = useState("");
  const [bankBalance, setBankBalance] = useState("");

  const setFundAccountFieldsData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      // setAccTiltleName(selected);
      // fields["account_title"] = "";
      setBankAccountTitle("");
      // fields["account_no"] = "";
      setBankAccountNumber("");
      // fields["bank"] = "";
      setBankName("");
      // fields["branch"] = "";
      setBankBranch("");
      // fields["fund_account"] = "other";
      setFundAccount("Other");
      // setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          // setAccTiltleName(item.account_title);

          setBankAccountTitle(item.account_title);
          setBankAccountNumber(item.account_no);
          setBankName(item.bank);
          setBankBranch(item.branch);
          // setFields({
          //   ...fields,
          //   fund_account: selected,
          //   account_title: item.account_title,
          //   account_no: item.account_no,
          //   bank: item.bank,
          //   branch: item.branch,
          // });

          transactionLedgers.map((items: any) => {
            if (items.type == "Credit") {
              items.gl_code = +item.code;
              items.gl_name = item.bank.toString();
              items.sub_family_code = +item.plsCode;
              items.sub_family_name = item.plsName.toString();
            }
          });
        }
      });
    }
  };

  const setCounterFundAccountFieldsData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["counter_account_type"] = "other";
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
            counter_account_type: selected,
            counter_account_title: item.account_title,
            counter_account_number: item.account_no,
            counter_bank: item.bank,
            counter_branch: item.branch,
          });
          transactionLedgers.map((items: any) => {
            if (items.type == "Debit") {
              items.gl_code = +item.code;
              items.gl_name = item.bank.toString();
              items.sub_family_code = +item.plsCode;
              items.sub_family_name = item.plsName.toString();
            }
          });
        }
      });
    }
  };

  const clearFundAccountFields = () => {
    setAccTiltleName("Unset");
    fields["fund_account"] = "";
    fields["account_title"] = "";
    fields["account_no"] = "";
    fields["bank"] = "";
    fields["branch"] = "";
    fields["nav"] = "";
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + "changes");
          fields["counter_account_type"] = item.company_code;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.iban;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch;
          fields["sales_tax_collector"] = item.tax_collected;
          setFields(fields);
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      amcdata.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + "changes");
          getCounterBranchByName(item.bank);
          fields["counter_account_type"] = item.account_no;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank;
          fields["counter_branch"] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields["current_holding"] + "change");
    fields["counter_account_type"] = "";
    fields["counter_account_title"] = "";
    fields["counter_account_number"] = "";
    fields["counter_bank"] = "";
    fields["counter_branch"] = "";
    fields["current_holding"] = "";
    fields["folio_no"] = "";
    setFields(fields);
  };

  const [grossAmountInWords, setGrossAmountInWords] = useState("");
  const [netAmountInWords, setNetAmountInWords] = useState("");
  const [netAmountSeparator, setNetAmountSeparator] = useState("");
  const [grossAmountSeparator, setGrossAmountSeparator] = useState("");
  const [unitsSeparator, setUnitsSeparator] = useState("");
  const [unitsInWords, setUnitsInWord] = useState("");

  //retrun error border class
  const ErrorBorder = (value) => {
    if (value === true) {
      return "required-border";
    } else {
      return "";
    }
  };

  //function for tableGeneration

  const handleTableGeneration = async () => {
    try{
    setTableLoading(true);
    tableData = [];
    setTableData(tableData);
    setGenerateDisable(true);
    if (!fund || fund.length === 0) {
      setFundError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Fund selection is required!");
    }

    if (!instructionDate || instructionDate < today) {
      setInstructionDateError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error(
        "There is problem with instruction date. Try Refreshing!"
      );
    }

    if (!executionDate || executionDate < instructionDate) {
      setExecutionDateError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error(
        "Execution date is must and should be greater then instruction date!"
      );
    }

    if (!fundAccount || fundAccount.length === 0) {
      setFundAccountError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Select Fund Account!");
    }

    if (!dividendPeriodStart) {
      setDividendPeriodStartError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Dividend Period Start is required");
    }
    if (!dividendPeriodEnd) {
      setDividendPeriodEndError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Dividend Period End is required");
    }
    if (!dividendIssueDate) {
      setDividendIssueDateError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Dividend Issue Date is required");
    }
    if (!dividendIssueNumber || dividendIssueNumber.length === 0) {
      setDividendIssueNumberError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Dividend Issue Number is required");
    }
    if (!dividendPercentage || dividendPercentage.length === 0) {
      setDividendPercentageError(true);
      setTableLoading(false);
      setGenerateDisable(false);
      return toast.error("Dividend Percentage is required");
    }

    if (unitHolderListSelected) {
      if (unitHolderListSelected.length === 0) {
        setTableLoading(false);
        setGenerateDisable(false);
        return toast.error("No Unit Holders for selected fund");
      } else if (unitHolderListSelected.length > 0) {
        //first we get all gividend working for selected fund
        const dividendResponse = await getAllDividendWorkingForCurrentDate(
          email,
          fund,
          dividendPeriodEnd
        );

        if (dividendResponse) {
          if (
            dividendResponse.data.status === 404 ||
            dividendResponse.data.status === 400
          ) {
            setGenerateDisable(false);
            setTableLoading(false);
            return toast.error(
              "No record found for dividend of unit holders for this fund!"
            );
          }
        } else {
          setGenerateDisable(false);
          setTableLoading(false);
          return toast.error(
            "No dividend record found for Unit Holders in this date range!"
          );
        }

        //formulas for calculations

        //logic here to map selected list and add fields to table data based on conditions
        unitHolderListSelected.map((holder, holderIndex) => {
          let balance = JSON.parse(holder.balance_unit)[0];
          dividendResponse?.data?.data?.map(
            (dividendRecord, dividendRecordIndex) => {
              if (dividendRecord.folio_no === holder.folio_no) {
                dividendRecordsToUpdate.push({ ...dividendRecord });
                if (dividendRecord.opening_units > 0) {
                  if (tableData) {
                    let totalElementContributed =
                      parseFloat(dividendRecord.opening_nav) -
                      parseFloat(openingExNav);
                    if (totalElementContributed > dividendPerUnit) {
                      totalElementContributed = dividendPerUnit;
                    } else if (
                      totalElementContributed > 0 &&
                      totalElementContributed < dividendPerUnit
                    ) {
                      totalElementContributed = totalElementContributed;
                    } else if (totalElementContributed < 0) {
                      totalElementContributed = 0;
                    }

                    let capitalEOI =
                      totalElementContributed *
                      (parseFloat(dividendPercentage) / 100);
                    let cashDividendPerUnit =
                      parseFloat(dividendPerUnit) - capitalEOI;
                    let refundCapitalAmount =
                      capitalEOI * parseFloat(dividendRecord.opening_units);
                    let dividendAmount =
                      cashDividendPerUnit *
                      parseFloat(dividendRecord.opening_units);
                    let grossDividend = dividendAmount + refundCapitalAmount;
                    let unitsAgainstRefundCapital =
                      refundCapitalAmount /
                      (parseFloat(closingNavBeforeDistribution) -
                        parseFloat(dividendPerUnit));
                    let taxRate = holder.tax_status === "Filer" ? 15 : 30;
                    let whtTaxDeducted = dividendAmount * (taxRate / 100);
                    let netAmount = dividendAmount - whtTaxDeducted;
                    let reInvestUnits =
                      netAmount /
                      (parseFloat(closingNavBeforeDistribution) -
                        parseFloat(dividendPerUnit));
                    let reInvestAmount =
                      reInvestUnits *
                      (parseFloat(closingNavBeforeDistribution) -
                        parseFloat(dividendPerUnit));
                    let closingUnits =
                      holder.reinvest === "false"
                        ? parseFloat(dividendRecord.opening_units) +
                          unitsAgainstRefundCapital
                        : parseFloat(dividendRecord.opening_units) +
                          reInvestUnits +
                          unitsAgainstRefundCapital;

                    // if(tableData.length > 0) {
                    setTableData((prevState) => [
                      ...prevState,
                      {
                        folio: holder.folio_no,
                        name: holder.name,
                        reinvest: holder.reinvest,
                        filer: holder.tax_status,
                        unitHolding: dividendRecord.opening_units,
                        accountNumber: holder.account_no,
                        accountTitle: holder.account_title,
                        bank: holder.bank_name,
                        branch: holder.branch_name,
                        taxRate: "",
                        tax: "",
                        jhTax: "",
                        zakat: "",
                        netDividend: "",
                        capitalRefund: "",
                        newUnits: "",
                        cnic: holder.cnic || "N/A",
                        unitDate: moment(holder.created_at).format(
                          "YYYY-MM-DD"
                        ),

                        inPrice: dividendRecord.opening_nav,
                        purchaseAmount:
                          parseFloat(dividendRecord.opening_units) *
                          parseFloat(dividendRecord.opening_nav),
                        dividendAnnouncementPerUnit: dividendPerUnit,
                        openingExNav: openingExNav,
                        closingNav: closingNavBeforeDistribution,
                        closingExNav:
                          parseFloat(closingNavBeforeDistribution) -
                          parseFloat(dividendPerUnit),
                        dividendPercentage: dividendPercentage,
                        applicableTaxRate:
                          holder.tax_status === "Filer" ? 15 : 30,
                        category: holder.type,
                        totalElementContributed: totalElementContributed,
                        capitalEOI: capitalEOI,
                        cashDividendPerUnit: cashDividendPerUnit,
                        refundCapitalAmount: refundCapitalAmount,
                        dividendAmount: dividendAmount,
                        grossDividend: grossDividend,
                        unitsAgainstRefundCapital: unitsAgainstRefundCapital,
                        whtTaxDeducted: whtTaxDeducted,
                        netAmount: netAmount,
                        reInvestUnits: reInvestUnits,
                        reInvestAmount: reInvestAmount,
                        closingUnits: closingUnits,
                      },
                    ]);

                    // } }
                    // else {
                    //   setTableData([
                    //     {
                    //       folio: holder.folio_no,
                    //       name: holder.name,
                    //       reinvest: holder.reinvest,
                    //       filer: holder.tax_status,
                    //       unitHolding: balance.fund_unit,
                    //       accountNumber: holder.account_no,
                    //       accountTitle: holder.account_title,
                    //       bank: holder.bank_name,
                    //       branch: holder.branch_name,
                    //       grossDividend: "",
                    //       taxRate: "",
                    //       tax:"",
                    //       jhTax: "",
                    //       zakat: "",
                    //       netDividend: "",
                    //       capitalRefund: "",
                    //       newUnits: "",
                    //     }
                    //   ])

                    // }
                  }
                }
                const dividendWorking = JSON.parse(
                  dividendRecord.dividend_table
                );

                dividendWorking?.map(
                  (dividendTransaction, dividendTransactionIndex) => {
                    if (
                      dividendTransaction.dividend_given === false &&
                      dividendTransaction.remaining_units > 0
                    ) {
                      if (tableData) {
                        let totalElementContributed =
                          parseFloat(dividendTransaction.nav) -
                          parseFloat(openingExNav);
                        if (totalElementContributed > dividendPerUnit) {
                          totalElementContributed = dividendPerUnit;
                        } else if (
                          totalElementContributed > 0 &&
                          totalElementContributed < dividendPerUnit
                        ) {
                          totalElementContributed = totalElementContributed;
                        } else if (totalElementContributed < 0) {
                          totalElementContributed = 0;
                        }
                        let capitalEOI =
                          totalElementContributed *
                          (parseFloat(dividendPercentage) / 100);
                        let cashDividendPerUnit =
                          parseFloat(dividendPerUnit) - capitalEOI;
                        let refundCapitalAmount =
                          capitalEOI *
                          parseFloat(dividendTransaction.remaining_units);
                        let dividendAmount =
                          cashDividendPerUnit *
                          parseFloat(dividendTransaction.remaining_units);
                        let grossDividend =
                          dividendAmount + refundCapitalAmount;
                        let unitsAgainstRefundCapital =
                          refundCapitalAmount /
                          (parseFloat(closingNavBeforeDistribution) -
                            parseFloat(dividendPerUnit));
                        let taxRate = holder.tax_status === "Filer" ? 15 : 30;
                        let whtTaxDeducted = dividendAmount * (taxRate / 100);
                        let netAmount = dividendAmount - whtTaxDeducted;
                        let reInvestUnits =
                          netAmount /
                          (parseFloat(closingNavBeforeDistribution) -
                            parseFloat(dividendPerUnit));
                        let reInvestAmount =
                          reInvestUnits *
                          (parseFloat(closingNavBeforeDistribution) -
                            parseFloat(dividendPerUnit));
                        let closingUnits =
                          holder.reinvest === "false"
                            ? parseFloat(dividendTransaction.remaining_units) +
                              unitsAgainstRefundCapital
                            : parseFloat(dividendTransaction.remaining_units) +
                              reInvestUnits +
                              unitsAgainstRefundCapital;
                        // if(tableData.length > 0) {
                        setTableData((prevState) => [
                          ...prevState,
                          {
                            folio: holder.folio_no,
                            name: holder.name,
                            reinvest: holder.reinvest,
                            filer: holder.tax_status,
                            unitHolding: dividendTransaction.remaining_units,
                            accountNumber: holder.account_no,
                            accountTitle: holder.account_title,
                            bank: holder.bank_name,
                            branch: holder.branch_name,
                            taxRate: "",
                            tax: "",
                            jhTax: "",
                            zakat: "",
                            netDividend: "",
                            capitalRefund: "",
                            newUnits: "",
                            cnic: holder.cnic || "N/A",
                            unitDate: dividendTransaction.date,
                            inPrice: dividendTransaction.nav,
                            purchaseAmount:
                              parseFloat(dividendTransaction.remaining_units) *
                              parseFloat(dividendTransaction.nav),
                            dividendAnnouncementPerUnit: dividendPerUnit,
                            openingExNav: openingExNav,
                            closingNav: closingNavBeforeDistribution,
                            closingExNav:
                              parseFloat(closingNavBeforeDistribution) -
                              parseFloat(dividendPerUnit),
                            dividendPercentage: dividendPercentage,
                            applicableTaxRate:
                              holder.tax_status === "Filer" ? 15 : 30,
                            category: holder.type,
                            totalElementContributed: totalElementContributed,
                            capitalEOI: capitalEOI,
                            cashDividendPerUnit: cashDividendPerUnit,
                            refundCapitalAmount: refundCapitalAmount,
                            dividendAmount: dividendAmount,
                            grossDividend: grossDividend,
                            unitsAgainstRefundCapital:
                              unitsAgainstRefundCapital,
                            whtTaxDeducted: whtTaxDeducted,
                            netAmount: netAmount,
                            reInvestUnits: reInvestUnits,
                            reInvestAmount: reInvestAmount,
                            closingUnits: closingUnits,
                          },
                        ]);

                        // } }
                        // else {
                        //   setTableData([
                        //     {
                        //       folio: holder.folio_no,
                        //       name: holder.name,
                        //       reinvest: holder.reinvest,
                        //       filer: holder.tax_status,
                        //       unitHolding: balance.fund_unit,
                        //       accountNumber: holder.account_no,
                        //       accountTitle: holder.account_title,
                        //       bank: holder.bank_name,
                        //       branch: holder.branch_name,
                        //       grossDividend: "",
                        //       taxRate: "",
                        //       tax:"",
                        //       jhTax: "",
                        //       zakat: "",
                        //       netDividend: "",
                        //       capitalRefund: "",
                        //       newUnits: "",
                        //     }
                        //   ])

                        // }
                      }
                    }
                  }
                );
              }
            }
          );
        });

        if (tableData.length === 0) {
          setGenerateDisable(false);
          setTableLoading(false);
          return toast.error(
            "No unit holders meeting criteria for dividend payout in selected fund/announcement"
          );
        }
        if (dividendRecordsToUpdate.length > 0) {
          dividendRecordsToUpdate.map((record, index) => {
            record.opening_units = "0";
            record.last_dividend_date = moment()
              .format("YYYY-MM-DD")
              .toString();
            let currentDividendTable = JSON.parse(record.dividend_table);
            if (currentDividendTable) {
              currentDividendTable.map((dividendRecord, dividendIndex) => {
                dividendRecord.remaining_units = 0;
                dividendRecord.dividend_given = true;
              });
            }
            record.dividend_table = JSON.stringify(currentDividendTable);
          });
        }
        setDividendRecordsToUpdate(dividendRecordsToUpdate);
      }
    }

    setGenerateDisable(false);
    setTableLoading(false);
  }catch(err:any){
    setGenerateDisable(false);
    setTableLoading(false);
    toast.error(err.response ? err.response.data.message?.toString() : err.message ? err.message :"Something went wrong!")

  }
  };

  const handleFundChange = (e) => {
    setFundError(false);
    setUnitHolderListSelected([]);
    setTableData([]);
    setDividendIssueNumber(0);
    setDividendPeriodStart(today);
    setDividendPeriodEnd(today);

    setOpeningExNav(0);
    setClosingNavBeforeDistribution(0);
    setDividendPerUnit(0);
    setExNavAfterDistribution(0);
    setDividendPercentage(0);
    setRedemptionDate(today);

    setDividendAnnouncementSelectedError(false);
    setDividendAnnouncementSelected("");
    setFund(e.target.value);
    getAccountByFundName(e.target.value);

    fundFilteringForValues = allFunds.find((item, index) => {
      return item.symbol_code === e.target.value;
    });
    // setFundFaceValue(fundFilteringForValues.face_value);
    // setCurrentNav(fundFilteringForValues.nav);
  };

  const handleDividendAnnouncementChange = (e) => {
    if (dividendAnnouncementData && dividendAnnouncementData !== "") {
      let selectedAnnouncement = dividendAnnouncementData.find(
        (item, index) => {
          return item.announcement_id === e.target.value;
        }
      );
      setDividendIssueNumber(selectedAnnouncement.announcement_id);
      setDividendPeriodStart(selectedAnnouncement.fromt_date_time ? moment(selectedAnnouncement.fromt_date_time).format("YYYY-MM-DD") : "");
      setDividendPeriodEnd(selectedAnnouncement.to_date_time ? moment(selectedAnnouncement.to_date_time).format("YYYY-MM-DD") : "") ;

      setOpeningExNav(selectedAnnouncement.opening_ex_nav);
      setClosingNavBeforeDistribution(
        selectedAnnouncement.closing_nav_before_dist
      );
      setDividendPerUnit(selectedAnnouncement.dividend_per_unit);
      setExNavAfterDistribution(selectedAnnouncement.ex_nav_after_dist);
      setDividendPercentage(selectedAnnouncement.payout_ratio_percent);
      setRedemptionDate(selectedAnnouncement.redemption_time ? moment(selectedAnnouncement.redemption_time).format("YYYY-MM-DD") : "");

      setDividendAnnouncementSelectedError(false);
      setDividendAnnouncementSelected(selectedAnnouncement);
      setDividendIssueDate(moment(selectedAnnouncement.created_at_time).format("YYYY-MM-DD"));
      setGenerateDisable(false);
    } else {
      setDividendIssueNumber("");
      setDividendPeriodStart(today);
      setDividendPeriodEnd(today);

      setOpeningExNav(0);
      setClosingNavBeforeDistribution(0);
      setDividendPerUnit(0);
      setExNavAfterDistribution(0);
      setDividendPercentage(0);
      setRedemptionDate(today);

      setDividendAnnouncementSelectedError(true);
      setDividendAnnouncementSelected("");
      setGenerateDisable(true);
    }
  };

  const handleDividendPayout = async (e) => {
    setTableLoading(true);
    let dividendPaymentDebitLedgerCode = 201030101;
    let dividendPaymentDebitLedgerInfo: any = null;

    let dividendPaymentCreditLedgerCode = 301030201;
    let dividendPaymentCreditLedgerInfo: any = null;

    let taxDividendCreditLedgerCode = 301030502;
    let taxDividendCreditLedgerInfo: any = null;

    let taxDividendPaid: boolean = false;

    let dividendReinvestDebitLedgerCode = 301030201;
    let dividendReinvestDebitLedgerInfo: any = null;

    let dividendReinvestCreditLedgerCode = 201010101;
    let dividendReinvestCreditLedgerInfo: any = null;
    const fetchLedgerInfo = async () => {
      if (dividendPaymentDebitLedgerCode) {
        try {
          let response = await getLedgerInfoByCode(
            email,
            dividendPaymentDebitLedgerCode
          );
          if (response.status === 200) {
            dividendPaymentDebitLedgerInfo = response.data.data;
          }
        } catch (error) {}
      }
      if (dividendPaymentCreditLedgerCode) {
        try {
          let response = await getLedgerInfoByCode(
            email,
            dividendPaymentCreditLedgerCode
          );
          if (response.status === 200) {
            dividendPaymentCreditLedgerInfo = response.data.data;
          }
        } catch (error) {}
      }
      if (taxDividendCreditLedgerCode) {
        try {
          let response = await getLedgerInfoByCode(
            email,
            taxDividendCreditLedgerCode
          );
          if (response.status === 200) {
            taxDividendCreditLedgerInfo = response.data.data;
          }
        } catch (error) {}
      }
      if (dividendReinvestDebitLedgerCode) {
        try {
          let response = await getLedgerInfoByCode(
            email,
            dividendReinvestDebitLedgerCode
          );
          if (response.status === 200) {
            dividendReinvestDebitLedgerInfo = response.data.data;
          }
        } catch (error) {}
        if (dividendReinvestCreditLedgerCode) {
          try {
            let response = await getLedgerInfoByCode(
              email,
              dividendReinvestCreditLedgerCode
            );
            if (response.status === 200) {
              dividendReinvestCreditLedgerInfo = response.data.data;
            }
          } catch (error) {}
        }
      }
    };
    await fetchLedgerInfo();

    e.preventDefault();
    sendingLedgers = [];
    setSendingLedgers(sendingLedgers);

    let dividendPayment: any = 0;
    let taxOnDividend: any = 0;
    let reinvestDividend: any = 0;
    let dividendTableUpdateCheck: any = 0;

    if (tableData && tableData.length > 0) {
      tableData.map((item, index) => {
        if (item.reinvest.toLowerCase() !== "false") {
          reinvestDividend = reinvestDividend + item.reInvestAmount;
        } else if (item.reinvest.toLowerCase() === "false") {
          dividendPayment = dividendPayment + item.dividendAmount;
        }
        taxOnDividend = taxOnDividend + item.whtTaxDeducted;
      });
    }
    if (dividendPayment === 0 && reinvestDividend === 0) {
      return toast.error(
        "After calculations there is nothing to commit! Recheck table or select another announcement!"
      );
    }

    if (dividendPayment > 0) {
      if (
        dividendPaymentDebitLedgerInfo === null ||
        dividendPaymentCreditLedgerInfo === null
      ) {
        return toast.error(
          "Error getting payment ledger info! Reload page & try again!"
        );
      }
      if (!taxDividendCreditLedgerInfo) {
        return toast.error(
          "Error getting tax ledger info! Reload page & try again!"
        );
      }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: dividendPaymentDebitLedgerInfo.child_family_code,
          child_family_name: dividendPaymentDebitLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: dividendPayment.toString(),
          description: "Fund Dividend Payout",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: dividendPaymentDebitLedgerInfo.family_code,
          family_name: dividendPaymentDebitLedgerInfo.family_name,
          fund_code: fund,
          gl_code: dividendPaymentDebitLedgerInfo.gl_code,
          gl_name: dividendPaymentDebitLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: dividendPaymentDebitLedgerInfo.main_family_code,
          main_family_name: dividendPaymentDebitLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: dividendPaymentDebitLedgerInfo.sub_family_code,
          sub_family_name: dividendPaymentDebitLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: dividendPaymentCreditLedgerInfo.child_family_code,
          child_family_name: dividendPaymentCreditLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: dividendPayment.toString(),
          debit_amount: "0",
          description: "Fund Dividend Payout",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: dividendPaymentCreditLedgerInfo.family_code,
          family_name: dividendPaymentCreditLedgerInfo.family_name,
          fund_code: fund,
          gl_code: dividendPaymentCreditLedgerInfo.gl_code,
          gl_name: dividendPaymentCreditLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: dividendPaymentCreditLedgerInfo.main_family_code,
          main_family_name: dividendPaymentCreditLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: dividendPaymentCreditLedgerInfo.sub_family_code,
          sub_family_name: dividendPaymentCreditLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
      fields["net_amount"] = (
        parseFloat(fields.net_amount) + dividendPayment
      ).toFixed(2);

      if (!taxDividendPaid && taxOnDividend > 0) {
        sendingLedgers.push({
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: taxDividendCreditLedgerInfo.child_family_code,
          child_family_name: taxDividendCreditLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: taxOnDividend.toString(),
          debit_amount: "0",
          description: "Fund Dividend Payout Tax",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: taxDividendCreditLedgerInfo.family_code,
          family_name: taxDividendCreditLedgerInfo.family_name,
          fund_code: fund,
          gl_code: taxDividendCreditLedgerInfo.gl_code,
          gl_name: taxDividendCreditLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: taxDividendCreditLedgerInfo.main_family_code,
          main_family_name: taxDividendCreditLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: taxDividendCreditLedgerInfo.sub_family_code,
          sub_family_name: taxDividendCreditLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        });
        taxDividendPaid = true;

        fields["net_amount"] = (
          parseFloat(fields.net_amount) + taxOnDividend
        ).toFixed(2);
      }
    }

    if (reinvestDividend > 0) {
      if (
        !dividendReinvestDebitLedgerInfo ||
        !dividendReinvestCreditLedgerInfo
      ) {
        return toast.error(
          "Error getting reinvest ledger info! Reload page & try again!"
        );
      }
      if (!taxDividendCreditLedgerInfo) {
        return toast.error(
          "Error getting tax ledger info! Reload page & try again!"
        );
      }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: dividendReinvestDebitLedgerInfo.child_family_code,
          child_family_name: dividendReinvestDebitLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: reinvestDividend.toString(),
          description: "Fund Dividend Reinvest",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: dividendReinvestDebitLedgerInfo.family_code,
          family_name: dividendReinvestDebitLedgerInfo.family_name,
          fund_code: fund,
          gl_code: dividendReinvestDebitLedgerInfo.gl_code,
          gl_name: dividendReinvestDebitLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: dividendReinvestDebitLedgerInfo.main_family_code,
          main_family_name: dividendReinvestDebitLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: dividendReinvestDebitLedgerInfo.sub_family_code,
          sub_family_name: dividendReinvestDebitLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: dividendReinvestCreditLedgerInfo.child_family_code,
          child_family_name: dividendReinvestCreditLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: reinvestDividend.toString(),
          debit_amount: "0",
          description: "Fund Dividend Reinvest",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: dividendReinvestCreditLedgerInfo.family_code,
          family_name: dividendReinvestCreditLedgerInfo.family_name,
          fund_code: fund,
          gl_code: dividendReinvestCreditLedgerInfo.gl_code,
          gl_name: dividendReinvestCreditLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: dividendReinvestCreditLedgerInfo.main_family_code,
          main_family_name: dividendReinvestCreditLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: dividendReinvestCreditLedgerInfo.sub_family_code,
          sub_family_name: dividendReinvestCreditLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      fields["net_amount"] = (
        parseFloat(fields.net_amount) + reinvestDividend
      ).toFixed(2);

      if (!taxDividendPaid && taxOnDividend > 0) {
        sendingLedgers.push({
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: taxDividendCreditLedgerInfo.child_family_code,
          child_family_name: taxDividendCreditLedgerInfo.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: taxOnDividend.toString(),
          debit_amount: "0",
          description: "Fund Dividend Payout Tax",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: taxDividendCreditLedgerInfo.family_code,
          family_name: taxDividendCreditLedgerInfo.family_name,
          fund_code: fund,
          gl_code: taxDividendCreditLedgerInfo.gl_code,
          gl_name: taxDividendCreditLedgerInfo.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: taxDividendCreditLedgerInfo.main_family_code,
          main_family_name: taxDividendCreditLedgerInfo.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: taxDividendCreditLedgerInfo.sub_family_code,
          sub_family_name: taxDividendCreditLedgerInfo.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        });
        taxDividendPaid = true;

        fields["net_amount"] = (
          parseFloat(fields.net_amount) + taxOnDividend
        ).toFixed(2);
      }
    }

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "funddividendpayout";
      fields.net_amount = fields.net_amount.toString();
      if (fields.ledgers.length > 0) {
        try {
          const result = await addMegaTransaction(fields);
          if (result.data.status !== 200) {
            return toast.warning(result.data.message);
          } else if (result.data.status === 200) {
            if (dividendRecordsToUpdate.length > 0) {
              for (let i = 0; i < dividendRecordsToUpdate.length; i++) {
                const updateResponse = await updateDividendWorkingAfterPayout(
                  dividendRecordsToUpdate[i]
                );

                if (updateResponse.status === 200) {
                  dividendTableUpdateCheck = dividendTableUpdateCheck + 1;
                }
              }
            }
            if (dividendTableUpdateCheck !== dividendRecordsToUpdate.length) {
              toast.error("Problem updating dividend table");
            }
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`Data added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              // history.push("/admin/create-transaction");
              // history.replace(`/admin/transactions-funds-management`);
              history.goBack()
            }, 3000);
          }
        } catch (error: any) {
          return toast.error(error.data.message);
        }
      }
    }
  };

  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields["detail"]}
                      className={"form-control w-100 "}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: "Yes",
                          });
                          createTransaction();
                        }}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderModalAddNewUnitHolder = () => {
    switch (viewModalAddNewHolder) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewModalAddNewUnitHolder(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewModalAddNewUnitHolder(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add UnitHolder</h6>
            </div>
            <div className="modal-body">
              <>
                <AddUnitHolder setPropState={setPropsState} />
              </>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              <div className="">
                <div className="input-holder">
                  <div className="row">
                    <h4 className=" card-title">
                      <i
                        className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                        onClick={() => {
                          // history.replace("/admin/create-transaction");
                          // history.replace(`/admin/transactions-funds-management`);
                          history.goBack()
                        }}
                      ></i>
                      Fund Dividend Payout
                    </h4>
                  </div>
                </div>
                <div className=" ">
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instruction</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group " data-tip="Select Fund">
                              Fund Name*
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : (
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fundError)
                                  }
                                  value={fund}
                                  onChange={(e) => {
                                    handleFundChange(e);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck
                                      ? "No Fund Found"
                                      : "Select Fund"}
                                  </option>
                                  {renderFundsDropdown()}
                                </select>
                              )}
                            </div>
                            <div className="form-group">
                              <div>Instruction Date</div>
                              <input
                                type="date"
                                value={instructionDate}
                                readOnly
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(instructionDateError)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <div>Execution Date</div>
                              <input
                                type="date"
                                value={executionDate}
                                min={instructionDate}
                                className={
                                  "form-control w-100" +
                                  ErrorBorder(executionDateError)
                                }
                                onChange={(e) => {
                                  if (e.target.value < instructionDate) {
                                    return;
                                  }
                                  setExecutionDateError(false);
                                  setExecutionDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Fund Account</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <div>Fund Account*</div>
                              {accLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : !fund || fund?.trim() === "" ? (
                                <div className="form-control    w-100">
                                  Kindly First Select Fund
                                </div>
                              ) : (
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fundAccountError)
                                  }
                                  value={fundAccount}
                                  onChange={(e) => {
                                    setFundAccountError(false);
                                    setFundAccount(e.target.value);

                                    setFundAccountFieldsData(e.target.value);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select Fund Account
                                  </option>
                                  <optgroup style={{color:"black"}} label="PLS Account"></optgroup>
                                      {renderFundAccounts("PLS ACCOUNTS")}
                                      <optgroup style={{color:"black"}} label="Current Account"></optgroup>
                                      {renderFundAccounts("CURRENT ACCOUNTS")}
                                </select>
                              )}
                            </div>

                            <div className="form-group">
                              <div>Account Title*</div>
                              <input
                                type="text"
                                value={bankAccountTitle}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(bankAccountTitleError)
                                }
                                readOnly
                              />
                            </div>
                            <div className="form-group">
                              <div>Account Number*</div>
                              <input
                                type="text"
                                value={bankAccountNumber}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(bankAccountNumberError)
                                }
                                readOnly
                              />
                            </div>
                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Bank</div>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    value={bankName}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="w-50 flex">
                                <div>Branch</div>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    value={bankBranch}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Calculations</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <div>Dividend Announcement*</div>
                              {accLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : !fund || fund?.trim() === "" ? (
                                <div className="form-control    w-100">
                                  Kindly First Select Fund
                                </div>
                              ) : (
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(
                                      dividendAnnouncementSelectedError
                                    )
                                  }
                                  value={
                                    dividendAnnouncementSelected.announcement_id
                                  }
                                  onChange={(e) => {
                                    // setDividendAnnouncementSelectedError(false);
                                    // setDividendAnnouncementSelected(
                                    //   dividendAnnouncementData.filter(
                                    //     (item, index) =>
                                    //       item.announcement_id ===
                                    //       e.target.value
                                    //   )
                                    // );
                                    handleDividendAnnouncementChange(e);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select Dividend Announcement
                                  </option>
                                  {renderDividendAnnouncementDropdown()}
                                </select>
                              )}
                            </div>

                            <div
                              className="form-group "
                              data-tip="Select Dividend Period"
                            >
                              Dividend Period
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              <span className="d-flex justify-content-between">
                                <input
                                  type="date"
                                  value={dividendPeriodStart}
                                  className={
                                    "form-control w-50 " +
                                    ErrorBorder(dividendPeriodStartError)
                                  }
                                  // onChange={(e) => {
                                  //   setDividendPeriodStartError(false);
                                  //   setDividendPeriodStart(e.target.value);
                                  // }}
                                  readOnly
                                />
                                <input
                                  type="date"
                                  value={dividendPeriodEnd}
                                  className={
                                    "form-control w-50 " +
                                    ErrorBorder(dividendPeriodEndError)
                                  }
                                  // onChange={(e) => {
                                  //   setDividendPeriodEndError(false);
                                  //   setDividendPeriodEnd(e.target.value);
                                  // }}
                                  readOnly
                                />
                              </span>
                            </div>

                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Dividend Issue Date</div>
                                <input
                                  type="date"
                                  value={dividendIssueDate}
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(dividendIssueDateError)
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="w-50 flex mr-2">
                                <div>Dividend Issue Number</div>
                                <input
                                  type="text"
                                  placeholder="Issuing number"
                                  value={dividendIssueNumber}
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(dividendIssueNumberError)
                                  }
                                  readOnly
                                />
                              </div>
                            </div>

                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Opening Ex Nav</div>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    value={openingExNav}
                                    placeholder="Opening Ex Nav Value"
                                    readOnly
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(openingExNavError)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="w-50 flex mr-2">
                                <div>Closing NAV before distribution</div>
                                <div className="form-group">
                                  <input
                                    value={closingNavBeforeDistribution}
                                    type="number"
                                    placeholder="Current NAV Value"
                                    readOnly
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(
                                        closingNavBeforeDistributionError
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Dividend Per Unit</div>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    value={dividendPerUnit}
                                    placeholder="Dividend Per Unit Rate"
                                    readOnly
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(dividendPerUnitError)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="w-50 flex mr-2">
                                <div>Ex NAV after distribution</div>
                                <div className="form-group">
                                  <input
                                    value={exNavAfterDistribution}
                                    type="number"
                                    placeholder="Ex NAV after distribution"
                                    readOnly
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(exNavAfterDistributionError)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Dividend Percentage</div>

                                <div className="form-group">
                                  <input
                                    type="number"
                                    value={dividendPercentage}
                                    // onChange={(e) => {
                                    //   if (
                                    //     parseInt(e.target.value) < 0.0 ||
                                    //     parseInt(e.target.value) > 100.0
                                    //   ) {
                                    //     return;
                                    //   }
                                    //   setDividendPercentageError(false);
                                    //   setDividendPercentage(e.target.value);
                                    // }}
                                    readOnly
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(dividendPercentageError)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="w-50 flex mr-2">
                                <div>Redemption Date</div>
                                <input
                                  type="date"
                                  value={redemptionDate}
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(redemptionDateError)
                                  }
                                  readOnly
                                />
                              </div>
                            </div>

                            {/* <div className="form-group">
                              <div>Dividend Percentage</div>
                              <input
                                type="number"
                                value={dividendPercentage}
                                // onChange={(e) => {
                                //   if (
                                //     parseInt(e.target.value) < 0.0 ||
                                //     parseInt(e.target.value) > 100.0
                                //   ) {
                                //     return;
                                //   }
                                //   setDividendPercentageError(false);
                                //   setDividendPercentage(e.target.value);
                                // }}
                                readOnly
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(dividendPercentageError)
                                }
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="5">
                      <div className="card hidden"></div>
                    </Col>
                    <Col md="5">
                      <div className="card hidden"></div>
                    </Col>

                    <Col>
                      <div className="d-flex justify-content-end pb-5">
                        <button
                          className="btn"
                          onClick={() => {
                            handleTableGeneration();
                          }}
                          disabled={generateDisable}
                        >
                          {generateDisable
                            ? !dividendAnnouncementData &&
                              dividendAnnouncementData === ""
                              ? "Generate"
                              : "Generating..."
                            : "Generate"}
                        </button>
                      </div>
                    </Col>
                  </Row>

                  {!tableLoading ? (
                    tableData ? (
                      tableData.length > 0 ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title text-primary">
                                  Dividend Payout Details ({fund})
                                  {/* <button className="btn-round btn-icon btn btn-success float-right" onClick={() => {
                              setViewModalForLedgers(!viewModalForLedgers);
                          }}> <i className="fa fa-plus"></i> </button> */}
                                </h4>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Folio</th>
                                        <th>Name</th>
                                        <th>CNIC</th>
                                        <th>Filer</th>
                                        <th>Tax Rate</th>
                                        <th>Category</th>
                                        <th>Reinvest Units</th>
                                        <th>Unit Holding</th>
                                        <th>Unit In-Date</th>
                                        <th>Unit In-Price</th>
                                        <th>Unit Purchase Amount</th>
                                        <th>Dividend Announcement Per Unit</th>
                                        <th>Opening Ex Nav</th>
                                        <th>Closing Nav</th>
                                        <th>Closing Ex Nav</th>
                                        <th>% of Distribution</th>
                                        <th>
                                          Total Element Contributed On Purchase
                                          / Unit
                                        </th>
                                        <th>
                                          Capital Eoi As Per Payout Ratio / Unit
                                        </th>
                                        <th>Cash Dividend Per Unit</th>
                                        <th>Refund Of Capital Amount</th>
                                        <th>Dividend Amount</th>
                                        <th>Gross Dividend</th>
                                        <th>Units Against Refund Of Capital</th>
                                        <th>Wht Tax Deduced</th>
                                        <th>Net Amount</th>
                                        <th>Re Invest Units</th>
                                        <th>Re Invest Amount</th>
                                        <th>Closing Units</th>
                                        <th>Account Title</th>
                                        <th>Account No.</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tableData.map(
                                        (item: any, index: number) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{item.folio}</td>
                                              <td>{item.name}</td>
                                              <td>{item.cnic}</td>
                                              <td>{item.filer}</td>
                                              <td>{item.applicableTaxRate}%</td>
                                              <td>{item.category}</td>
                                              <td>
                                                {item.reinvest.toUpperCase()}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.unitHolding
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>{item.unitDate}</td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.inPrice
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.purchaseAmount
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.dividendAnnouncementPerUnit
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.openingExNav
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.closingNav
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.closingExNav
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>{item.dividendPercentage}</td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.totalElementContributed
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.capitalEOI
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.cashDividendPerUnit
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.refundCapitalAmount
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.dividendAmount
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.grossDividend
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.unitsAgainstRefundCapital
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.whtTaxDeducted
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.netAmount
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.reInvestUnits
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.reInvestAmount
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>
                                                {numberWithCommas(
                                                  parseFloat(
                                                    item.closingUnits
                                                  ).toFixed(4)
                                                )}
                                              </td>
                                              <td>{item.accountTitle}</td>
                                              <td>{item.accountNumber}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : null
                    ) : null
                  ) : (
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-center my-5">
                          <i
                            className="fa fa-spinner fa-spin fa-3x"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {!tableLoading && tableData?.length > 0 ? (
                    <div className="row m-2">
                      <div className=" col-md-12 d-flex justify-content-between align-items-center">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <FormGroup style={{ color: "white" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  color: "white",
                                }}
                                color="success"
                                checked={checkboxState}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="I have vetted the generated values and these are correct as per my knowledge and record."
                          />
                        </FormGroup>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            handleDividendPayout(e);
                          }}
                          disabled={Boolean(Loading) || !checkboxState}
                          data-tip={
                            checkboxState
                              ? "Proceed to save dividends"
                              : "Kindly confirm generated values first"
                          }
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Save Dividends</span>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {renderModalForBankBalance()}
                {renderModalAddNewUnitHolder()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FundDividendPayout;
