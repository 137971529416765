import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getFundByAmc } from '../../stores/services/funds.service';
// import { getBankStatement,getBankStatementOpeningBalance } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { numberWithCommas } from '../../utils/customFunction';

const VoucherReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const amc_name = sessionStorage.getItem('amc_name') || '';
  let date = new Date();
  const [voucherData, setVoucherData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCode, setFundCode] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

        useEffect(()=>{
          const getAllAmcFunds=async()=>{
            try{
              allFunds.length=0;
              setAllFunds(allFunds);
                const response = await getFundByAmc(email, amc_code,fund_code?.length > 0 ? fund_code:"");
                setAllFunds(response.data.data);
            }catch(error){

            }
          }
          getAllAmcFunds();
        },[])

        // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        // const CapTxnType = (type: any) => {
        //     let i, txType;
        //     for (i = 0; i < txnTypes.length; i++) {
        //     if (txnTypes[i].api_name === type) {
        //         txType = txnTypes[i].dis_name;
        //         break;
        //     }
        //     }
        //     return txType;
        // };

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }


  //code for search data for Reports

  const searchData = async () => {  
    try {
      setLoading(true);

      setResAmc(amc_name);
      setResFund(fund);
      setResFromDate(fromDate);
      setResToDate(toDate);

      setLoading(false);
    } catch (error:any) {
        setLoading(false);
        if(error.response!=undefined){
          toast.error(error.response.data.message);
        }else{
          toast.error(error.message);
        }
    }
  }


  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Voucher Report</h4>
            </div>
            <div className="card-body">
            <Row>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                        setFundCode(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group  ">
                  <label>Date From</label>
                  <input
                    type="date"
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromDate(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Date To</label>
                  <input
                    type="date"
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToDate(date);
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Voucher-Report-"+date+".pdf"} ref={pdfExportComponent}>
              {voucherData.length > 0 ? (
                <>
                <ReportHeader title="Voucher Report"/>

                  <Row>
                    {/* <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{resAmc}</span>
                        </Col>
                      </Row>
                    </Col> */}

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Fund:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap" >{resFund}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              From Date:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row>
                  
                  <div style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}>
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                            <th>Date</th>
                            <th>Ledger Code</th>
                            <th>Ledger Descirption</th>
                            <th>Narration</th>
                            <th>Debit Amount</th>
                            <th>Credit Amount</th>
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                        {voucherData.map((item: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                               <td>{moment(new Date()).format('DD-MM-YYYY')}</td>
                               <td></td>
                               <td></td>
                               <td></td>
                               <td className="text-right">{numberWithCommas(item.Record.debit_amount)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.credit_amount)}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
             Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default VoucherReport;
