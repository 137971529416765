import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param folio_no
 * @param account_name
 * @param account_no
 * @param bank_name
 * @param client_name
 * @param balance_update
 * @param cnic
 * @param mobile
 * @param city
 * @param registration_date
 * @param account_title
 * @param nature
 * @param branch_name
 * @param amc_name
 * @param client_code
 * @param type
 * @param ntn
 * @param address
 * @param country
 */
const addUnitHolder = async (
  email: string,
  amc_code: string,
  folio_no: string,
  account_no: string,
  bank_name: string,
  balance_unit: string,
  cnic: string,
  mobile: string,
  city: string,
  account_title: string,
  branch_name: string,
  amc_name: string,
  ntn: string,
  address: string,
  name: string,
  main_folio_no: string,
  phone: string,
  cnic_expiry: string,
  reinvest: string,
  client_email: string,
  father_spouse: string,
  tax_exempt: string,
  zakat_exempt: string,
  type: string,
  guardian_name: string,
  guardian_relation: string,
  guardian_cnic: string,
  nominee_name: string,
  nominee_relation: string,
  nominee_cnic: string,
  joint_h_1_name: string,
  joint_h_1_father_spouse: string,
  joint_h_1_cnic: string,
  joint_h_2_name: string,
  joint_h_2_father_spouse: string,
  joint_h_2_cnic: string,
  joint_h_3_name: string,
  joint_h_3_father_spouse: string,
  joint_h_3_cnic: string,
  portfolio_management: string,
  // filer: string,
  province: string,
  tax_status: string,
  joint_h1_tax_status: string,
  joint_h2_tax_status: string,
  joint_h3_tax_status: string,
  cdc_ias: string,
  cdc_sub_account: string,
  cdc_participant_id: string,
  in_house_sale:boolean=false,
  sales_company:string="",
  sales_person:string="",
  investor_id:string="",
) => {
  const url = `${Config.baseUrl}/unit-holder/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        folio_no,
        account_no,
        bank_name,
        balance_unit,
        cnic,
        mobile,
        city,
        account_title,
        branch_name,
        amc_name,
        ntn,
        address,
        name,
        main_folio_no,
        phone,
        cnic_expiry,
        reinvest,
        client_email,
        father_spouse,
        tax_exempt,
        zakat_exempt,
        type,
        guardian_name,
        guardian_relation,
        guardian_cnic,
        nominee_name,
        nominee_relation,
        nominee_cnic,
        joint_h_1_name,
        joint_h_1_father_spouse,
        joint_h_1_cnic,
        joint_h_2_name,
        joint_h_2_father_spouse,
        joint_h_2_cnic,
        joint_h_3_name,
        joint_h_3_father_spouse,
        joint_h_3_cnic,
        portfolio_management,
        // filer,
        province,
        tax_status,
        joint_h1_tax_status,
        joint_h2_tax_status,
        joint_h3_tax_status,
        cdc_ias,
        cdc_sub_account,
        cdc_participant_id,
        in_house_sale,
        sales_company,
        sales_person,
        investor_id
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if (result.status) {
      return await addUnitHolder(email,
        amc_code,
        folio_no,
        account_no,
        bank_name,
        balance_unit,
        cnic,
        mobile,
        city,
        account_title,
        branch_name,
        amc_name,
        ntn,
        address,
        name,
        main_folio_no,
        phone,
        cnic_expiry,
        reinvest,
        client_email,
        father_spouse,
        tax_exempt,
        zakat_exempt,
        type,
        guardian_name,
        guardian_relation,
        guardian_cnic,
        nominee_name,
        nominee_relation,
        nominee_cnic,
        joint_h_1_name,
        joint_h_1_father_spouse,
        joint_h_1_cnic,
        joint_h_2_name,
        joint_h_2_father_spouse,
        joint_h_2_cnic,
        joint_h_3_name,
        joint_h_3_father_spouse,
        joint_h_3_cnic,
        portfolio_management,
        // filer,
        province,
        tax_status,
        joint_h1_tax_status,
        joint_h2_tax_status,
        joint_h3_tax_status,
        cdc_ias,
        cdc_sub_account,
        cdc_participant_id,
        in_house_sale,
        sales_company,
        sales_person,
        investor_id
        )
    } else {
      throw err;
      // return result
    }
  }else {
    throw err
  }
  }
};

/**
 *
 * @param email
 */
const getAllUnitHolders = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/unit-holder?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUnitHolders(email, page_number, page_size)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getAllUnitHoldersByAmc = async (email: string, amc_code: string) => {
  const url = `${Config.baseUrl}/unit-holder/by-amc?email=${email}&amc_code=${amc_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUnitHoldersByAmc(email, amc_code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param folio_no
 * @param status
 */
const updateUnitHolderStatus = async (
  email: string,
  folio_no: string,
  status: string
) => {
  const url = `${Config.baseUrl}/unit-holder?email=${email}&folio_no=${folio_no}&status=${status}`;
  try {
    const result: any = await axios.put(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateUnitHolderStatus(email, folio_no, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};


const getAllUnitHolderByFolioNo = async (email: string, folioNo: string) => {
  const url = `${Config.baseUrl}/unit-holder/by-folio?email=${email}&folio_no=${folioNo}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUnitHolderByFolioNo(email, folioNo)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 *
 * @param search_value
 */
const searchUnitHolderAPI = async (email: string, search_value: string) => {
  const url = `${Config.baseUrl}/unit-holder?email=${email}&search_value=${search_value}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await searchUnitHolderAPI(email, search_value)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const searchUnitHolderAPIModified = async (email: string) => {
  const url = `${Config.baseUrl}/unit-holder?email=${email}&search_value=`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await searchUnitHolderAPIModified(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const uploadUnitHolder = async (email: string, dataObj: any) => {
  const url = `${Config.baseUrl}/unit-holder/bulk-upload`;
  try {
    let data = JSON.stringify(dataObj);
    const result: any = await axios.post(
      url,
      { email, data },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await uploadUnitHolder(email, dataObj)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const uploadUnitHolding = async (email: string, dataObj: any) => {
  const url = `${Config.baseUrl}/unit-holder/update-unit-holding`;
  try {
    let data = JSON.stringify(dataObj);
    const result: any = await axios.post(
      url,
      { email, data },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await uploadUnitHolding(email, dataObj)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const editUnitHolder = async (
  email: string,
  folio_no: string,
  account_no: string,
  bank_name: string,
  balance_unit: string,
  cnic: string,
  mobile: string,
  city: string,
  account_title: string,
  branch_name: string,
  amc_name: string,
  ntn: string,
  address: string,
  name: string,
  main_folio_no: string,
  phone: string,
  cnic_expiry: string,
  reinvest: string,
  client_email: string,
  father_spouse: string,
  tax_exempt: string,
  zakat_exempt: string,
  type: string,
  guardian_name: string,
  guardian_relation: string,
  guardian_cnic: string,
  nominee_name: string,
  nominee_relation: string,
  nominee_cnic: string,
  joint_h_1_name: string,
  joint_h_1_father_spouse: string,
  joint_h_1_cnic: string,
  joint_h_2_name: string,
  joint_h_2_father_spouse: string,
  joint_h_2_cnic: string,
  joint_h_3_name: string,
  joint_h_3_father_spouse: string,
  joint_h_3_cnic: string,
  portfolio_management: string,
  // filer: string,
  province: string,
  tax_status: string,
  joint_h1_tax_status: string,
  joint_h2_tax_status: string,
  joint_h3_tax_status: string,
  cdc_ias: string,
  cdc_sub_account: string,
  cdc_participant_id: string,
  in_house_sale:boolean=false,
  sales_company:string="",
  sales_person:string=""


) => {
  const url = `${Config.baseUrl}/unit-holder/update`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        folio_no,
        account_no,
        bank_name,
        balance_unit,
        cnic,
        mobile,
        city,
        account_title,
        branch_name,
        amc_name,
        ntn,
        address,
        name,
        main_folio_no,
        phone,
        cnic_expiry,
        reinvest,
        client_email,
        father_spouse,
        tax_exempt,
        zakat_exempt,
        type,
        guardian_name,
        guardian_relation,
        guardian_cnic,
        nominee_name,
        nominee_relation,
        nominee_cnic,
        joint_h_1_name,
        joint_h_1_father_spouse,
        joint_h_1_cnic,
        joint_h_2_name,
        joint_h_2_father_spouse,
        joint_h_2_cnic,
        joint_h_3_name,
        joint_h_3_father_spouse,
        joint_h_3_cnic,
        portfolio_management,
        // filer,
        province,
        tax_status,
        joint_h1_tax_status,
        joint_h2_tax_status,
        joint_h3_tax_status,
        cdc_ias,
        cdc_sub_account,
        cdc_participant_id,
        in_house_sale,
        sales_company,
        sales_person

      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await editUnitHolder(email,folio_no,account_no,bank_name,balance_unit,cnic,mobile,city,account_title,branch_name,amc_name,ntn,address,name,main_folio_no,phone,cnic_expiry,reinvest,client_email,father_spouse,tax_exempt,zakat_exempt,type,guardian_name,guardian_relation,guardian_cnic,nominee_name,nominee_relation,nominee_cnic,joint_h_1_name,joint_h_1_father_spouse,joint_h_1_cnic,joint_h_2_name,joint_h_2_father_spouse,joint_h_2_cnic,joint_h_3_name,joint_h_3_father_spouse,joint_h_3_cnic,portfolio_management,/*filer*/province,tax_status,joint_h1_tax_status,joint_h2_tax_status,joint_h3_tax_status,cdc_ias,cdc_sub_account,cdc_participant_id,in_house_sale,sales_company,sales_person)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getUnitHoldersByAmcAndFolioNo = async (email: string, amc_code: string, folio_no) => {
  const url = `${Config.baseUrl}/unit-holder/by-amc-code-and-folio-no?email=${email}&amc_code=${amc_code}&folio_no=${folio_no}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getUnitHoldersByAmcAndFolioNo(email,amc_code, folio_no)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

export {
  getAllUnitHoldersByAmc,
  addUnitHolder,
  editUnitHolder,
  getAllUnitHolders,
  getAllUnitHolderByFolioNo,
  searchUnitHolderAPI,
  uploadUnitHolder,
  uploadUnitHolding,
  updateUnitHolderStatus,
  searchUnitHolderAPIModified,
  getUnitHoldersByAmcAndFolioNo,
  
};
