import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";

const addSalesCompany = async (
  email: string,
  amc_code: string,
  company_code: string,
  company_name: string,
  logo: string,
  symbol: string,
  type: string,
  psx_listed: boolean,
  isin: string,
  ntn: string,
  incorporation_number: string,
  sector_code: string,
  website: string,
  parent_company: string,
  status: boolean,
  teams: string[],
  persons: string[],
  bank_accounts: string[],
  in_house: boolean
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sales-company/`;

  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        company_code,
        company_name,
        logo,
        symbol,
        type,
        psx_listed,
        isin,
        ntn,
        incorporation_number,
        sector_code,
        website,
        parent_company,
        status,
        teams,
        persons,
        bank_accounts,
        in_house,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addSalesCompany(
          email,
          amc_code,
          company_code,
          company_name,
          logo,
          symbol,
          type,
          psx_listed,
          isin,
          ntn,
          incorporation_number,
          sector_code,
          website,
          parent_company,
          status,
          teams,
          persons,
          bank_accounts,
          in_house
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getPaginatedSalesCompany = async (
  email: string = "",
  amc_code: string = "",
  company_code: string = "",
  company_name: string,
  ntn: string = "",
  status: string = "",
  in_house: string = "",
  page_number: string = "",
  page_size: string = "10"
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/paginate?email=${email}&amc_code=${amc_code}&company_code=${company_code}&company_name=${company_name}&ntn=${ntn}&status=${status}&in_house=${in_house}&page_number=${page_number}&page_size=${page_size}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedSalesCompany(
          email,
          amc_code,
          company_code,
          company_name,
          ntn,
          status,
          in_house,
          page_number,
          page_size
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const updateStatusSalesCompany = async(email:string,company_code:string="",status:boolean=false)=>{
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/update/status`;
    const result: any = await axios.post(
      url,
      {
        email,company_code,status
      },
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }
  catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateStatusSalesCompany(
          email,
         company_code,
         status
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }

}

const updateSalesCompany = async (
  email: string,
  amc_code: string,
  company_code: string,
  company_name: string,
  logo: string,
  symbol: string,
  type: string,
  psx_listed: boolean,
  isin: string,
  ntn: string,
  incorporation_number: string,
  sector_code: string,
  website: string,
  parent_company: string,
  status: boolean,
  teams: string[],
  persons: string[],
  bank_accounts: string[],
  in_house: boolean
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sales-company/update`;

  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        company_code,
        company_name,
        logo,
        symbol,
        type,
        psx_listed,
        isin,
        ntn,
        incorporation_number,
        sector_code,
        website,
        parent_company,
        status,
        teams,
        persons,
        bank_accounts,
        in_house,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addSalesCompany(
          email,
          amc_code,
          company_code,
          company_name,
          logo,
          symbol,
          type,
          psx_listed,
          isin,
          ntn,
          incorporation_number,
          sector_code,
          website,
          parent_company,
          status,
          teams,
          persons,
          bank_accounts,
          in_house
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getAllSalesCompany = async(email:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company?email=${email}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllSalesCompany(
          email,
          
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getSalesCompanyByCode= async(email:string="",company_code:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/by-code?email=${email}&company_code=${company_code}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getSalesCompanyByCode(
          email,
          company_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getAllActiveSalesCompany = async(email:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/active?email=${email}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllActiveSalesCompany(
          email,
          
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getAllInActiveSalesCompany = async(email:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/inactive?email=${email}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllInActiveSalesCompany(
          email,
          
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getSalesCompanyByAMCCode= async(email:string="",amc_code:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/by-amc-code?email=${email}&amc_code=${amc_code}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getSalesCompanyByAMCCode(
          email,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getActiveSalesCompanyByAMCCode= async(email:string="",amc_code:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/by-amc-code/active?email=${email}&amc_code=${amc_code}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getActiveSalesCompanyByAMCCode(
          email,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

const getInActiveSalesCompanyByAMCCode= async(email:string="",amc_code:string="") =>
{
  try{
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/sales-company/by-amc-code/inactive?email=${email}&amc_code=${amc_code}`;
    const result: any = await axios.get(
      url,
      
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return result;

  }catch(err:any)
  {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getInActiveSalesCompanyByAMCCode(
          email,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }

  }
}

export { 
  addSalesCompany,
  getPaginatedSalesCompany,
  updateStatusSalesCompany,
  updateSalesCompany ,
  getAllSalesCompany,
  getAllActiveSalesCompany,
  getAllInActiveSalesCompany,
  getSalesCompanyByAMCCode,
  getActiveSalesCompanyByAMCCode,
  getInActiveSalesCompanyByAMCCode,
  getSalesCompanyByCode



};
