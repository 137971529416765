import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { BsTable } from "react-icons/bs";
import { getAmc } from "../../stores/services/amc.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import { getReceiptPaymentRegisterReport } from "../../stores/services/report.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { useSelector } from "react-redux";

const RecieptPaymentRegisterReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  let date = new Date();
  const [allData, setAllData] = useState<any>([]);
  const [allDataCount, setAllDataCount] = useState(0);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCode, setFundCode] = useState("");
  const [fundName, setFundName] = useState("");
  const [type, setType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [fromDate, setFromDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [resAmc, setResAmc] = useState("");
  const [resFundName, setResFundName] = useState("");
  const [resPaymentType, setResPaymentType] = useState("");
  const [resFromDate, setResFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resToDate, setResToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [totalDebit, setTotalDebit] = useState("");
  const [totalCredit, setTotalCredit] = useState("");

  useEffect(() => {
    const getAllAmcFunds = async () => {
      try {
        const response = await getFundByAmc(email, amc_code, fund_code?.length > 0 ? fund_code : "");
        setAllFunds(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
    };
    getAllAmcFunds();
  }, []);

  let txnTypes = useSelector((state: any) => state.txnTypes);
  const CapTxnType = (type: any) => {

    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code : "");
      setAllFunds(response.data.data);
    } catch (error) { }
  };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      setLoading(true);
      allData.length = 0;
      setAllData(allData);
      setAllDataCount(allData);
      // let fromMonths = new Date(fromDate);
      // let toMonths = new Date(toDate);
      // var premonths;
      // premonths = (toMonths.getFullYear() - fromMonths.getFullYear()) * 12;
      // premonths -= fromMonths.getMonth();
      // premonths += toMonths.getMonth();
      // if(premonths<6){
      const response = await getReceiptPaymentRegisterReport(
        email,
        fundCode,
        paymentType,
        type,
        fromDate,
        toDate
      );
      if(response.data.status == 200)
        {
      let array = Object.entries(response.data.data);
      setResAmc(amc_name);
      setResFundName(fundName);
      setResPaymentType(paymentType);
      setResFromDate(fromDate);
      setResToDate(toDate);
      let data: any = [];
      array.forEach(([key, value]) => {
        data = value;
        let record = [];
        // record = data.Record;
        console.log("Data => ", data);
        console.log("Data Key => ", data.key);
        record = data;
        record["legder_id"] = data.Key;
        allData.push(record);
      });
      setAllDataCount(allData.length);
      let totaldebit = 0,
        totalcredit = 0;
      allData.map((item: any, index: number) => {
        totaldebit += parseFloat(item.debit_amount);
        totalcredit += parseFloat(item.credit_amount);
      });
      setTotalDebit(totaldebit.toFixed(2));
      setTotalCredit(totalcredit.toFixed(2));
      setLoading(false);
    }else{
      setLoading(false);
      toast.error(response.data.message);

    }
      // }else{
      //   toast.error('You cannot generate report upto 6 months')
      // setLoading(false);
      // }
    } catch (error:any) {
      setLoading(false);
      toast.error(error?.response?.data?.message?.toString() || error?.message || "Something went wrong" );

    }
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Receipt/Payment Register Report
                    </h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFundName(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text
                              );
                              setFundCode(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Payment Type</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setPaymentType(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Payment Type
                            </option>
                            <option value="Receipt">Receipt</option>
                            <option value="Payment">Payment</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Type</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Type
                            </option>
                            <option value="false">Posting</option>
                            <option value="true">Realized</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={
                          resPaymentType + "-Register-Report-" + date + ".pdf"
                        }
                        ref={pdfExportComponent}
                      >
                        {allDataCount > 0 ? (
                          <>
                            <ReportHeader
                              title={resPaymentType + " Register Report"}
                            />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">{resPaymentType} Register Report</h3>
                  </div> */}

                            <Row>
                              {/* <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resAmc}
                                    </span>
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFundName}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resFromDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      To Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resToDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Report Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(date).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th>Ledger ID</th>
                                    <th>Txn Type</th>
                                    <th>Description</th>
                                    <th>Instruction Date</th>
                                    <th>Instrument Date</th>
                                    <th>Instrument No</th>
                                    <th>Instrument Type</th>
                                    <th>Bank/Branch</th>
                                    <th>Account Number</th>
                                    <th>Credit Amount</th>
                                    <th>Debit Amount</th>
                                    <th>Realized</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {allData.map((item: any, index: number) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>
                                            {/* {item?.legder_id?.split("_")[1]} */}
                                            {item?._id?.split("_")[1]}
                                          </td>
                                          <td>
                                            {CapTxnType(item?.txnr_type)}
                                          </td>
                                          <td>
                                            {item.description.split("-")[0]}
                                          </td>
                                          <td>
                                            {moment(
                                              item.instruction_date
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td>
                                            {moment(
                                              item.instrument_date
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td>{item.instrument_no || "-"}</td>
                                          <td>{item.instrument_type || "-"}</td>
                                          <td>
                                            {item.bank_code || ""}-
                                            {item.branch || ""}
                                          </td>
                                          <td>{item.account_number || "-"}</td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              item.credit_amount
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              item.debit_amount
                                            )}
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="ml-2"
                                              readOnly
                                              checked={
                                                item.realized === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <th colSpan={9}>Total</th>
                                    <th className="text-right text-nowrap">
                                      {numberWithCommas(totalCredit)}
                                    </th>
                                    <th className="text-right text-nowrap">
                                      {numberWithCommas(totalDebit)}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RecieptPaymentRegisterReport;
