import React from 'react';	
import { useState } from 'react';		
import { Container, Row, Col } from 'reactstrap';	
import { addBank } from '../../../stores/services/bank.service';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';	
const SetupBank = () => {	
  const history = useHistory();
  const [bankName, setBankName] = useState('');	
  const [bankCode, setBankCode] = useState('');	
  const email = sessionStorage.getItem('email') || '';	
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [bankNamelError, setBankNameError] = useState('');	
  const [bankCodeError, setBankCodeError] = useState('');	
  const [Loading, setLoading] = useState(false);	
  const bankCodeRegex = new RegExp('^[A-Z]{1,4}$');	
  const validate = () => {	
    let nameError = '';	
    let codeError = '';	
    bankName.trim() === '' ? (nameError = 'Required') : (nameError = '');	
    bankCode.trim() === ''	
      ? (codeError = 'Required')	
      : bankCodeRegex.test(bankCode) !== true	
      ? (codeError = 'Invalid')	
      : (codeError = '');	
    if (codeError || nameError) {	
      setBankNameError(nameError);	
      setBankCodeError(codeError);	
      return false;	
    } else {	
      return true;	
    }	
  };	
  const changeCodeValue = (value: any) => {	
    setBankCode(value);	
    if (value.trim() === '') {	
      setBankCodeError('Required');	
    } else {	
      bankCodeRegex.test(value) !== true	
        ? setBankCodeError('Invalid')	
        : setBankCodeError('');	
    }	
  };	
  const AddBank = async () => {	
    const isValid = validate();	
    if (isValid) {	
      setLoading(true);	
      try {	
        const response = await addBank(bankName, bankCode, email, amc_code);
        if(response.data.status===200){	
        // setBankName('');	
        // setBankCode('');	
        toast.success(response.data.message);
        setTimeout(() => {
          history.replace(`/admin/banks`);
        }, 2000);
        }else{
          setLoading(false);	
          toast.error(response.data.message);
        }	
      } catch (error:any) {	
        setLoading(false);	
        if (error.response.data.statusCode) {	
          toast.error(error.response.data.message[0]);	
        } else {	
          toast.error(error.response.data.message?.toString());	
        }	
      }	
     
    }	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header title-row">	
            <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              history.replace('/admin/banks')
          }}></i>Setup - Bank</h4>	

            <Link to="/admin/banks" className="btn btn-primary btn-sm ml-auto" replace>	
            <i className="fa fa-eye mr-2"></i>View All	
              </Link>	
          </div>	
         
          <div className="card-body">	
            <Row>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Bank Name <span className="color-amber">*</span></label>	
                  <input	
                    type="text"	
                    placeholder="Bank Name"	
                    className={`form-control  w-100 ${bankNamelError ? 'required-border' : ''	
                      }`}	
                    value={bankName}	
                    onChange={(e) => {	
                      setBankName(e.target.value.replace(/[^a-z^A-Z ]/, ''));	
                      setBankNameError('');	
                    }}	
                    onKeyPress={(event) => {	
                      if (event.key === 'Enter') {	
                        AddBank();	
                      }	
                    }}	
                  />	
                  {/* {	
                      bankNamelError ?	
                        <p className="error-labels error-message2">{bankNamelError}</p>	
                        : ''	
                    } */}	
                </div>	
              </Col>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Bank Code  <span className="color-amber">*</span></label>	
                  <input	
                    type="text"	
                    placeholder="Bank Code"	
                    className={`form-control  w-100 ${bankCodeError ? 'required-border' : ''	
                      }`}	
                    value={bankCode}	
                    onChange={(e) => {	
                      var cleaned = e.target.value.replace(/[^a-z^A-Z]/, '');
                      if(cleaned.length > 4){	
                        cleaned = cleaned.substring(0,4);	
                      }	
                      changeCodeValue(cleaned.toUpperCase());	
                    }}	
                    onKeyPress={(event) => {	
                      if (event.key === 'Enter') {	
                        AddBank();	
                      }	
                    }}	
                  />	
                </div>	
                <div className="d-flex">	
                <p style={{ fontSize: '11px' }}>Code Pattern (AAAA)</p>
                  {bankCodeError ? (	
                    <span	
                      className="ml-auto"	
                      style={{ fontSize: '11px' }}	
                    >	
                      {bankCodeError}	
                    </span>	
                  ) : (	
                    ''	
                  )}	
                </div>	
              </Col>	
            </Row>	
          </div>	
          <div className="form-group">	
            <button	
              className="btn btn-primary ml-3"	
              onClick={AddBank}	
              disabled={Boolean(Loading)}	
            >	
              {Loading ? (	
                <>	
                  <span	
                    className="spinner-border login-txt spinner-border-sm"	
                    role="status"	
                    aria-hidden="true"	
                  ></span>	
                  <span className="login-txt"> Loading...</span>	
                </>	
              ) : (	
                <span>Create</span>	
              )}	
            </button>	
          </div>	
        </div>	
      </Container >	
      
 </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default SetupBank;